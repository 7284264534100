import { Box, Chip, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  labelHeading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  labelSubheading: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
    marginBottom: '3px',
  },
  subHeadingValue: {
    fontSize: '16px',
    fontWeight: 500,
  },
}));

const developerJsonConfig = isExternalDeveloper => {
  let config = {
    heading: 'Developer details',
    details: [
      {
        title: 'First name',
        propName: 'firstName',
      },
      {
        title: 'Last name',
        propName: 'lastName',
      },
      {
        title: 'Email id',
        propName: 'email',
      },
    ],
  };
  if (isExternalDeveloper)
    config.details.push({
      title: 'External organization name',
      propName: 'externalOrgName',
    });
  return config;
};

const ORGANIZATION_JSON = {
  heading: 'Organization details',
  details: [
    {
      title: 'Organization name',
      propName: 'orgName',
    },
    {
      title: 'Organization type',
      propName: 'orgType',
    },
  ],
};

const DeveloperOrganizationDetails = ({ type, data }) => {
  const classes = useStyles();
  const isExternal = data?.signUpType === 'signup';
  const config =
    type === 'developer' ? developerJsonConfig(isExternal) : ORGANIZATION_JSON;

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          margin: '25px 40px',
          marginTop: '0px',
          backgroundColor: '#fff',
          borderRadius: '8px',
        }}
      >
        <Typography className={classes.labelHeading}>
          {config.heading}{' '}
          {type === 'developer' && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label={isExternal ? 'EXTERNAL' : 'INTERNAL'}
              style={{
                color: '#005DB9',
                fontWeight: 500,
                fontSize: '11px',
                textTransform: 'uppercase',
                marginLeft: '5px',
              }}
            />
          )}
        </Typography>
        <Box
          display={'flex'}
          alignItems={'center'}
          gridGap={'70px'}
          marginTop={'10px'}
        >
          {config.details.map(detail => (
            <Box key={detail.title}>
              <Typography className={classes.labelSubheading}>
                {detail.title}
              </Typography>
              <Typography className={classes.subHeadingValue}>
                {data?.[detail.propName]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default DeveloperOrganizationDetails;
