import React, { useEffect, useMemo, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Box, CircularProgress } from '@material-ui/core';
import CriteriaDropdown from 'containers/Rules/formElements/criteriaDropdown';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import RulesEngineApi from 'services/api/RuleEngineApi';
import RulesApi from 'services/api/RulesApi';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';

let OPERATOR_OPTIONS = ['AND', 'OR'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 1px 16px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#F5F7F8',
    padding: '10px',
    borderRadius: '8px',
  },
  criteriaGrpContainer: {
    backgroundColor: '#E4E7EC',
    width: '95%',
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '10px',
    padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    padding: '0px 16px',
    width: '100%',
  },
  criteriaSummaryContainer: {
    backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
    flexFlow: 'wrap',
  },
  criteriaGrpSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
    flexFlow: 'wrap',
  },
}));

const CreateCriteria = ({
  allCriteriaValues,
  criteriaOptions,
  criteriaValue,
  idx,
  onAddCriteria,
  onCriteriaChange,
  onDeleteCriteria,
  showAddIcon,
  showDeleteIcon,
  criteriaGrpIdx,
}) => {
  const classes = useStyles();
  const { criteriaId, criteriaCondition } = criteriaValue;
  let options = [];
  const getCriteriaOptions = () => {
    if (!criteriaOptions) return;
    let prevSelectedValues = {};
    allCriteriaValues?.forEach(val => {
      if (val.criteriaId !== 'placeholder') {
        prevSelectedValues[val.criteriaId] = true;
      }
    });

    options = criteriaOptions.filter(
      criteria => criteria.id === criteriaId || !prevSelectedValues[criteria.id]
    );
  };
  getCriteriaOptions();
  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{ gap: '5px', marginRight: '2px' }}>
          <Box style={{ minWidth: '288px' }}>
            <CriteriaDropdown
              id={`criteria-dropdown-${criteriaGrpIdx}-${idx}`}
              placeHolderText="Choose a criteria"
              options={options}
              value={criteriaId}
              optionLabelKey={'name'}
              optionValueKey={'id'}
              handleChange={({ target }) =>
                onCriteriaChange(idx, target.value, 'CRITERIA')
              }
              dataTestId={'criteria-dropdown'}
            />
          </Box>
          {showDeleteIcon && (
            <DeleteIcon
              style={{
                color: 'rgba(102, 112, 133, 1)',
                fontSize: '14px',
                cursor: 'pointer',
              }}
              onClick={() => onDeleteCriteria(idx)}
              data-testid="delete-criteria-icon"
            />
          )}
        </Box>
      </Box>
      {showAddIcon ? (
        <Box className={classes.addIconContainer} onClick={onAddCriteria}>
          <AddIcon
            style={{ fontSize: '20px', cursor: 'pointer' }}
            data-testid="add-criteria-icon"
          />
        </Box>
      ) : (
        <Box style={{ minWidth: '80px' }}>
          <CriteriaDropdown
            options={OPERATOR_OPTIONS}
            value={criteriaCondition}
            handleChange={({ target }) =>
              onCriteriaChange(idx, target.value, 'OPERATOR')
            }
            dataTestId={'criteria-operator-dropdown'}
          />
        </Box>
      )}
    </>
  );
};

const CriteriaGroup = ({
  criteriaOptions,
  criteriaValues,
  onAddCriteria,
  onDeleteCriteria,
  onCriteriaChange,
  criteriaGrpIdx,
  deleteCriteriaGroup,
  onDeleteCriteriaGroup,
}) => {
  const classes = useStyles();
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <Box className={classes.criteriaGrpContainer}>
        {criteriaValues?.map((criteriaVal, idx, arr) => (
          <CreateCriteria
            allCriteriaValues={criteriaValues}
            criteriaOptions={criteriaOptions}
            criteriaValue={criteriaVal}
            idx={idx}
            criteriaGrpIdx={criteriaGrpIdx}
            key={criteriaVal.key || idx}
            onAddCriteria={onAddCriteria}
            onCriteriaChange={onCriteriaChange}
            onDeleteCriteria={onDeleteCriteria}
            showAddIcon={arr.length === idx + 1}
            showDeleteIcon={arr.length > 1}
          />
        ))}
      </Box>
      {deleteCriteriaGroup && (
        <IconButton onClick={onDeleteCriteriaGroup}>
          <DeleteIcon data-testid="delete-criteria-grp-btn" />
        </IconButton>
      )}
    </Box>
  );
};

const handleAddRemoveKeys = (obj, type) => {
  let clonedObject = JSON.parse(JSON.stringify(obj));

  clonedObject.forEach(criteriaGrp => {
    criteriaGrp.criteriaGroup.forEach(criteria => {
      if (type === 'DELETE') {
        delete criteria.key;
      } else if (type === 'ADD') {
        criteria.key = Math.random(0, 1);
      }
    });
    if (type === 'DELETE') {
      delete criteriaGrp.criteriaGrpKey;
    } else if (type === 'ADD') {
      criteriaGrp.criteriaGrpKey = Math.random(0, 1);
    }
  });

  return clonedObject;
};

export default function CriteriaForRules({
  ruleValues,
  setSavedCriteria,
  setEditCriteria,
  tabStatus,
  setRule,
  category,
  hasCreatePermissions
}) {
  
  const classes = useStyles();
  const [categoryType,setCategoryType]= useState(category);
  const { enqueueSnackbar } = useSnackbar();
  const orgId = sessionStorage.getItem("orgId");
  const [criteriaOptions, setCriteriaOptions] = useState();
  const [expanded, setExpanded] = useState(false);
  const criteriaIdMapping = useRef({});
  const [criteriaGrpsValue, setCriteriaGrpsValue] = useState(
    ruleValues?.ruleDefn?.criteria?.[0]?.criteriaGroup?.[0]?.criteriaId
      ? ruleValues?.ruleDefn?.criteria
      : [
          {
            criteriaGroup: [
              {
                criteriaId: 'placeholder',
                source: '',
                // attributeId: '',
                key: Math.random(0, 1),
              },
            ],
            criteriaGrpKey: Math.random(0, 1),
          },
        ]
  );

  const prevValRef = useRef(structuredClone(ruleValues?.ruleDefn?.criteria));
  const [disable, setDisable] = useState(true);

  const [loading, setLoading] = useState(false);
  const isCriteriaSavedBefore = useRef(
    ruleValues?.ruleDefn?.criteria?.[0]?.criteriaGroup?.[0]?.criteriaId
      ? true
      : false
  );
  const [saveCriteriaStatus, setSaveCriteriaStatus] = useState(
    ruleValues?.ruleDefn?.criteria?.[0]?.criteriaGroup?.[0]?.criteriaId
      ? tabStatus?.SAVED
      : tabStatus?.NOT_SAVED
  );

  const handleCriteriaStatus = () => {
    if (saveCriteriaStatus === tabStatus?.SAVED) {
      setSaveCriteriaStatus(tabStatus?.NOT_SAVED);
      setSavedCriteria(false);
    }
  };

  const handleAddCriteria = criteriaGrpIdx => {
    handleCriteriaStatus();
    setDisable(false);
    setEditCriteria(true);
    let prevValues = [...criteriaGrpsValue];
    let currentCriteriaGrp = prevValues[criteriaGrpIdx].criteriaGroup;
    let criteriaGrpLength = currentCriteriaGrp.length;
    currentCriteriaGrp[criteriaGrpLength - 1].criteriaCondition =
      currentCriteriaGrp[0]?.criteriaCondition || OPERATOR_OPTIONS[0];
    currentCriteriaGrp.push({
      criteriaId: 'placeholder',
      source: '',
      criteriaCondition: null,
      // attributeId: '',
      key: Math.random(0, 1),
    });
    setCriteriaGrpsValue(prevValues);
  };

  const handleDeleteCriteria = (criteriaGrpIdx, idx) => {
    handleCriteriaStatus();
    setEditCriteria(true);
    setDisable(false);
    let prevValues = [...criteriaGrpsValue];
    if (
      prevValues[criteriaGrpIdx].criteriaGroup[idx - 1]?.criteriaCondition &&
      (idx === 0 ||
        idx === prevValues[criteriaGrpIdx].criteriaGroup?.length - 1)
    ) {
      prevValues[criteriaGrpIdx].criteriaGroup[idx - 1].criteriaCondition =
        null;
    }
    prevValues[criteriaGrpIdx].criteriaGroup.splice(idx, 1);
    setCriteriaGrpsValue(prevValues);
  };

  const handleChangeCriteria = (criteriaGrpIdx, idx, val, type) => {
    handleCriteriaStatus();
    setEditCriteria(true);
    setDisable(false);
    let currentValue = [...criteriaGrpsValue];
    let currentCriteriaGrp = currentValue[criteriaGrpIdx].criteriaGroup;
    if (type === 'CRITERIA') {
      let selectedCriteria = criteriaIdMapping.current[val];
      currentCriteriaGrp[idx] = {
        ...currentCriteriaGrp[idx],
        criteriaId: val,
        // attributeId: selectedCriteria.attrId,
        source: selectedCriteria.attrType,
      };
    } else if (type === 'OPERATOR') {
      currentCriteriaGrp.forEach(criteria => {
        if (criteria.criteriaCondition !== null) {
          criteria.criteriaCondition = val;
        }
      });
    }
    setCriteriaGrpsValue(currentValue);
  };

  const handleAddCriteriaGrp = () => {
    handleCriteriaStatus();
    setEditCriteria(true);
    setDisable(false);
    let prevValues = [...criteriaGrpsValue];
    let criteriaGrpLength = prevValues.length;
    prevValues[criteriaGrpLength - 1].criteriaGroupCondition =
      OPERATOR_OPTIONS[0];
    prevValues.push({
      criteriaGroup: [
        {
          criteriaId: 'placeholder',
          source: '',
          // attributeId: '',
          key: Math.random(0, 1),
        },
      ],
      criteriaGroupCondition: null,
      criteriaGrpKey: Math.random(0, 1),
    });
    setCriteriaGrpsValue(prevValues);
  };

  const handleCriteriaGrpsOperatorChange = (criteriaGrpIdx, value) => {
    handleCriteriaStatus();
    setDisable(false);
    let prevValues = [...criteriaGrpsValue];
    prevValues[criteriaGrpIdx].criteriaGroupCondition = value;
    setCriteriaGrpsValue(prevValues);
  };

  const handleClearCriteria = () => {
    setEditCriteria(false);
    setExpanded(false);
    setCriteriaGrpsValue([
      {
        criteriaGroup: [
          {
            criteriaId: 'placeholder',
            source: '',
            // attributeId: '',
            key: Math.random(0, 1),
          },
        ],
        criteriaGrpKey: Math.random(0, 1),
      },
    ]);
  };

  const handleCloseWithoutEditing = () => {
    setSaveCriteriaStatus(tabStatus?.SAVED);
    setDisable(true);
    setSavedCriteria(true);
    setEditCriteria(false);
    let tempVar = structuredClone(prevValRef.current);
    setCriteriaGrpsValue(tempVar);
    setExpanded(prevValue => !prevValue);
  };

  const handleEditCriteria = () => {
    setSaveCriteriaStatus(tabStatus?.NOT_SAVED);
    setSavedCriteria(false);
    setEditCriteria(true);
    setExpanded(prevValue => !prevValue);
  };

  const handleCriteriaValidations = () => {
    let criteriaGrpIdx = 0;
    for (let { criteriaGroup } of criteriaGrpsValue) {
      let criteriaIdx = 0;
      for (let criteria of criteriaGroup) {
        if (criteria.criteriaId === 'placeholder') {
          let criteriaDropdown = document.getElementById(
            `criteria-dropdown-${criteriaGrpIdx}-${criteriaIdx}`
          );
          if (criteriaDropdown) {
            criteriaDropdown.focus();
          }
          enqueueSnackbar('Please select criteria', {
            variant: 'error',
            autoHideDuration: 1500,
          });
          return false;
        }
        criteriaIdx++;
      }
      criteriaGrpIdx++;
    }

    return true;
  };
  const handleSaveCriteria = async () => {
    if (!handleCriteriaValidations()) return;

    setLoading(true);
    setSaveCriteriaStatus(tabStatus?.SAVING);

    // delete react keys before saving criteria
    let criteriaPayloadWithoutKeys = handleAddRemoveKeys(
      criteriaGrpsValue,
      'DELETE'
    );

    let checkForEdit = ruleValues.id;

    let payload = {
      ...ruleValues,
      editBeforeExec: checkForEdit ? true : false,
      ruleDefn: {
        ...ruleValues.ruleDefn,
        criteria: criteriaPayloadWithoutKeys,
      },
    };

    try {
      let results = await RulesApi.saveRules({ orgId, payload });
      setRule(results);
      setExpanded(false);
      setLoading(false);
      isCriteriaSavedBefore.current = true;
      prevValRef.current = criteriaPayloadWithoutKeys;
      setSaveCriteriaStatus(tabStatus?.SAVED);
      setSavedCriteria(true);
      setEditCriteria(false);
    } catch {
      setSaveCriteriaStatus(tabStatus?.REJECTED);
      setSavedCriteria(false);
      setEditCriteria(true);
      setLoading(false);
    }
  };

  const handleDeleteCriteriaGroup = idx => {
    setDisable(false);
    let prevValues = [...criteriaGrpsValue];
    prevValues.splice(idx, 1);
    if (idx == prevValues.length || prevValues.length == idx - 1) {
      prevValues[idx - 1].criteriaGroupCondition = null;
    }
    setCriteriaGrpsValue(prevValues);
  };

  const getAllCriterias = async (orgId,typeValue) => {
    try {
      const results = await RulesEngineApi.getCriteria({
        orgId,
        searchName: '',
        searchType: '',
        attributeType: typeValue,
        limit: 600, //* Passing max limit due to API limitations
        offset: 0,
      });
      let mappedVal = {};
      results.data.forEach(val => (mappedVal[val.id] = val));
      criteriaIdMapping.current = mappedVal;
      setCriteriaOptions(results.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    getAllCriterias(orgId,categoryType);
  }, [orgId,categoryType]);

  const savedStatusStatus = useMemo(() => {
    if (saveCriteriaStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
          title="saving"
        />
      );
    } else if (saveCriteriaStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
          titleAccess="saved"
        />
      );
    } else if (saveCriteriaStatus === tabStatus?.REJECTED) {
      return (
        <HighlightOffIcon
          style={{ color: 'red', fontSize: '22px' }}
          titleAccess="rejected"
        />
      );
    } else {
      return (
        <CheckCircleOutlineIcon style={{ color: 'grey', fontSize: '22px' }} />
      );
    }
  }, [saveCriteriaStatus]);

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.containerBox}
        expanded={expanded}
        // onChange={() => setExpanded(prevValue => !prevValue)}
        data-testid="criteria-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              data-testid="criteria-status-icon">
              {savedStatusStatus}
              <Box>
                <Typography
                  className={classes.heading}
                  data-testid="criteria-title">
                  Criteria
                </Typography>
                {expanded && (
                  <Typography style={{ fontWeight: 400, fontSize: '12px',color:'#667085' }}>
                    You can only choose one operator within a criteria group
                  </Typography>
                )}
              </Box>
              {!expanded && (
                <Box style={{ marginLeft: 'auto' }}>
                  {ruleValues?.status === 'DRAFT' ? (
                    isCriteriaSavedBefore.current ? (
                      <SecondaryButton onClick={handleEditCriteria} hasPermissions={hasCreatePermissions}>
                        Edit
                      </SecondaryButton>
                    ) : (
                      <SecondaryButton
                        onClick={() => setExpanded(prevValue => !prevValue)} hasPermissions={hasCreatePermissions}>
                        Add criteria
                      </SecondaryButton>
                    )
                  ) : (
                    ''
                  )}
                </Box>
              )}
            </Box>
            {saveCriteriaStatus === 'SAVED' && !expanded && (
              <Box
                className={classes.criteriaGrpSummaryContainer}
                data-testid="saved-criteria-values">
                {criteriaGrpsValue?.map(criteria => {
                  return (
                    <Box display={'flex'} alignItems={'center'}>
                      <Box className={classes.criteriaSummaryContainer}>
                        {criteria.criteriaGroup?.map(val => (
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ gap: '10px' }}>
                            <Typography variant="h6">
                              ({criteriaIdMapping.current[val.criteriaId]?.name}
                              )
                            </Typography>
                            {val.criteriaCondition && (
                              <Typography
                                variant="h6"
                                style={{ color: '#A65E21' }}>
                                {val.criteriaCondition}
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                      {criteria.criteriaGroupCondition && (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          margin={'0px 10px'}>
                          <Typography variant="h6" style={{ color: '#A65E21' }}>
                            {criteria.criteriaGroupCondition}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            {criteriaGrpsValue.map((criteriaGrpVal, idx, arr) => (
              <Box key={criteriaGrpVal.criteriaGrpKey || idx}>
                <CriteriaGroup
                  criteriaOptions={criteriaOptions}
                  criteriaValues={criteriaGrpVal.criteriaGroup}
                  criteriaGrpIdx={idx}
                  onAddCriteria={handleAddCriteria.bind(null, idx)}
                  onDeleteCriteria={handleDeleteCriteria.bind(null, idx)}
                  onCriteriaChange={handleChangeCriteria.bind(null, idx)}
                  onDeleteCriteriaGroup={handleDeleteCriteriaGroup.bind(
                    null,
                    idx
                  )}
                  deleteCriteriaGroup={arr.length > 1}
                />
                {criteriaGrpVal.criteriaGroupCondition && (
                  <Box style={{ maxWidth: '80px', margin: '10px 0px' }}>
                    <CriteriaDropdown
                      options={OPERATOR_OPTIONS}
                      value={criteriaGrpVal.criteriaGroupCondition}
                      handleChange={({ target }) =>
                        handleCriteriaGrpsOperatorChange(idx, target.value)
                      }
                      dataTestId={'criteria-grp-operator-dropdown'}
                    />
                  </Box>
                )}
              </Box>
            ))}

            <Box style={{ margin: '10px 0px' }}>
              <SecondaryButton
                onClick={handleAddCriteriaGrp}
                data-testid="add-criteria-grp-btn">
                Add new criteria group
              </SecondaryButton>

              <Box style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                {/* {!isCriteriaSavedBefore.current && ( */}
                <SecondaryButton
                  onClick={
                    isCriteriaSavedBefore.current
                      ? handleCloseWithoutEditing
                      : handleClearCriteria
                  }
                  disabled={
                    saveCriteriaStatus === tabStatus?.SAVING ||
                    saveCriteriaStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel-btn">
                  Cancel
                </SecondaryButton>
                {/* )} */}
                <PrimaryButton
                  disabled={
                    criteriaGrpsValue?.[0]?.criteriaGroup?.[0]?.criteriaId ===
                      'placeholder' ||
                    saveCriteriaStatus === tabStatus?.SAVING ||
                    saveCriteriaStatus === tabStatus?.SAVED ||
                    disable
                  }
                  onClick={handleSaveCriteria}
                  data-testid="save-criteria">
                  {saveCriteriaStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SimpleBackdrop open={loading} />
    </div>
  );
}
