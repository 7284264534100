import React, { useEffect, useCallback, useRef } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import DataPartnersApi from 'services/api/DataPartnersApi';
import OrganizationApi from 'services/api/OrganizationApi';
import SignUpStep1 from './SignUpStep1';
import SelectSolutions from './SelectSolutions';
import mainLogo from './../../assets/logo.png';
import SignUp from 'services/api/SignUp';
import * as actions from 'store/actions';
import { useSelector, useDispatch } from 'react-redux';
import ContactPage from './ContactPage';

const Index = ({
  refetchData,
  handleClose,
  fetchedData,
  trackUnsavedChangesRef,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [Step1, setStep1] = useState(1);
  const [Step1Data, setStep1Data] = useState({
    title: fetchedData?.name || null,
    description: fetchedData?.description || null,
    type: fetchedData?.type || null,
    affiliate: fetchedData?.affiliateIds || null,
    affiliateName: null,
    category: fetchedData?.category || null,
  });
  const shouldRefetechData = useRef(false);
  const [orgLogo, setOrgLogo] = useState([]);
  const [signUpValues, setSignUpValues] = useState({
    organization: {
      name: '',
      db: '',
      ein: '',
      typeCode: '',
      addresses: [
        {
          city: '',
          country: '',
          line1: '',
          state: '',
          type: '',
          zip: '',
          contacts: [
            {
              fax: '',
              phone: '',
            },
          ],
          line2: '',
        },
      ],
      userId: '',
    },
    orgAdminDetails: {
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
    },
    solutionIds: [],
  });

  const tabStatus = {
    SAVED: 'SAVED',
    NOT_SAVED: 'NOT_SAVED',
    REJECTED: 'REJECTED',
    SAVING: 'SAVING',
  };

  useEffect(() => {
    dispatch(actions.fetchLocationStates('US'));
    dispatch(actions.fetchAddressTypes());
  }, []);

  const createOrg = async payload => {
    const formData = new FormData();
    formData.append('file', orgLogo);
    formData.append('organizationRequest', JSON.stringify(payload));
    const otherHeaders = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'content-type': 'multipart/form-data',
    };
    try {
      let results = await SignUp.createOrg(formData, otherHeaders);
      setStep1(3)
      
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.details, {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
  };

  const handleStep = (val, payload) => {
    if (val !== 3) {
      setStep1(val);
    } else {
      createOrg(payload);
    }
  };

  const setSignUpPayloadValues = data => {
    setSignUpValues(data);
  };
  return (
    <div>
      <img
        src={mainLogo}
        style={{ margin: '2% 0% 0% 2%' }}
        alt="company-logo"
      />
      {Step1 === 1 ? (
        <SignUpStep1
          changeStep={handleStep}
          tabStatus={tabStatus}
          signUpValues={signUpValues}
          setOrgLogo={setOrgLogo}
          setSignUp={setSignUpPayloadValues}
          shouldRefetechData={shouldRefetechData}
          trackUnsavedChangesRef={trackUnsavedChangesRef}
          logoDetails={orgLogo?.name ? [orgLogo] : ""}
        />
      ) : (
        Step1 === 2 && (
          <SelectSolutions
            changeStep={handleStep}
            signUpValues={signUpValues}
            setSignUp={setSignUpPayloadValues}
          />
        )
      )}
      {Step1 === 3 && <ContactPage />}
    </div>
  );
};

export default Index;
