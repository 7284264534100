import React, { useEffect } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Container, InputAdornment, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Event } from '@mui/icons-material';
import colors from '../../colors';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    maxWidth: '100%',
  },
  textFieldLabel: {
    fontFamily: 'Inter',
    fontSize: "18px",
    fontWeight: 400,
    marginBottom: '12px',
    color: colors.PRIMARY_GRAY,
    lineHeight: '16px',
    boxSizing: 'border-box',
    height: theme.spacing(2),
  },
  error: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.RED} !important`,
      borderRadius: theme.spacing(0.5),
    }
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      color: colors.PRIMARY_GRAY,
      fontFamily: 'Inter',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      padding: '0px 12px',
      opacity: 0.87,
      boxSizing: 'border-box',
      height: "45px",
    },
    '& .MuiIconButton-root': { 
      padding: theme.spacing(0), 
      marginRight: theme.spacing(0),
      color: colors.TITLE_GRAY,
      fontFamily: 'Material Icons Round',
     },
     '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.SUBTLE_GRAY}`,
    },
  },
  required: {
    color: colors.RED,
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginLeft: -theme.spacing(0.25),
  },
}));

const SelfReportingDatePicker = (props) => {
  const classes = useStyles();
  const {
    fullWidth = true,
    focused,
    placeholder,
    variant = 'outlined',
    inputVariant = 'outlined',
    dateformat = 'MM/dd/yyyy',
    name,
    valueKey,
    color = 'secondary',
    value,
    label,
    required = false,
    allowFutureDates = false,
    changeHandler,
    labelClass,
    error,
  } = props;
  const [pickerValue, setValue] = React.useState(value || null);
  useEffect(() => {
    if (value) {
      setValue(new Date(value));
    }
  }, [value]);

  const onChangeHandler = (res = null) => {
    setValue(res);
    if (changeHandler) {
      changeHandler(res);
    }
  };

  return (
    <Container className={classes.root}>
      {label && (
        <p className={clsx(classes.textFieldLabel, labelClass)}>
          {label} <span className={classes.required}>{required ? '*' : ''}</span>
        </p>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          fullWidth={fullWidth}
          focused={focused}
          className={clsx(classes.datePicker, error ? classes.error : null)}
          variant={variant}
          inputVariant={inputVariant}
          format={dateformat}
          name={name || valueKey || 'date'}
          color={color}
          value={pickerValue}
          maxDate={!allowFutureDates ? new Date() : undefined}
          placeholder={placeholder}
          onChange={onChangeHandler}
          renderInput={(props) => (
            <TextField {...props} sx={{ width: fullWidth ? '100%' : 'unset' }} />
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <Event />
              </InputAdornment>
            ),
          }}
        />
      </LocalizationProvider>
    </Container>
  );
};

export default SelfReportingDatePicker;
