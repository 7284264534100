import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Grid,
  Paper,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import CampaignApi from 'services/api/CampaignApi';
import { useParams } from 'react-router-dom';
import { PaperContainer } from 'components/widgets/PaperContainer';
import { MembersCount } from '../MembersCount';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 0px 1px 0px',
    marginTop: '24px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#fffff',
    padding: '8px',
    marginBottom: '-7px',
    boxShadow:
      '0px 4px 8px -2px rgba(16,24,40,0.1),0px 2px 4px -2px rgba(16,24,40,0.06)',
  },
  campaignsBox: {
    backgroundColor: '#E4E7EC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '132px',
    borderRadius: '4px',
    height: '28px',
    padding: '4px',
    marginRight: '8px',
  },
  root1: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#E4E7EC',
  },
}));

const EditRender = ({ onEdit }) => {
  return (
    <SecondaryButton onClick={onEdit} data-testid="edit-btn">
      Edit
    </SecondaryButton>
  );
};

const DownloadFile = ({ cohortId }) => {
  const orgId = sessionStorage.getItem("orgId");
  const uploadFile = async () => {
    try {
      const file = await CampaignApi.getUploadedCohortFile(orgId, cohortId);
      if (file) {
        const link = document.createElement('a');
        link.setAttribute('href', file);
        link.setAttribute('download', 'file.csv');
        link.click();
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <GetAppIcon
      data-testid="download"
      onClick={uploadFile}
      style={{
        color: '#036FCB',
        cursor: 'pointer',
        marginLeft: '4px',
        fontSize: '16px',
      }}
    />
  );
};

const fetchMemberCount = ({
  cohortValue,
  setMemberCount,
  setMemberCountLoading,
  orgId,
  shouldRefetch,
}) => {
  if (
    cohortValue.memberCount >= 0 &&
    cohortValue.memberCount !== null &&
    !shouldRefetch &&
    cohortValue.fileName !== null
  ) {
    setMemberCount(cohortValue.memberCount);
  } else {
    setMemberCountLoading(true);
    CampaignApi.getMemberCountByID({ orgId, cohortId: cohortValue.id })
      .then(res => {
        setMemberCount(res?.patientCount);
        setMemberCountLoading(false);
      })
      .catch(err => {
        setMemberCountLoading(false);
        setMemberCount(null);
      });
  }
};

const CohortGrid = ({
  onEditClick,
  fileName,
  name,
  labelNames,
  operator,
  lastElementObserver,
  isLastElement,
  cohortId,
  cohortValue,
  orgId,
  hasEditPermission
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [memberCount, setMemberCount] = useState();
  const [memberCountLoading, setMemberCountLoading] = useState(false);

  const updateMemberCount = () => {
    fetchMemberCount({
      cohortValue,
      setMemberCountLoading,
      setMemberCount,
      orgId,
      shouldRefetch: !cohortValue.fileName,
    });
  };

  useEffect(() => {
    if (expanded && memberCount === undefined && !memberCountLoading) {
      fetchMemberCount({
        cohortValue,
        setMemberCountLoading,
        setMemberCount,
        orgId,
      });
    }
  }, [expanded, cohortValue, orgId, memberCount, memberCountLoading]);

  return (
    <div
      className={classes.root}
      ref={isLastElement ? lastElementObserver : null}>
      <Accordion
        elevation={1}
        expanded={expanded}
        data-testid="accordion-click"
        className={classes.containerBox}
        style={{ borderRadius: '15px' }}
        onChange={() => setExpanded(prevValue => !prevValue)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box style={{ display: 'flex' }}>
              <Typography variant="h5" data-testid="cohort-name">
                {name}
              </Typography>
            </Box>
           {
            hasEditPermission &&  <Box style={{ display: 'flex', height: '24px' }}>
            <EditRender onEdit={() => onEditClick(cohortId)}/>
          </Box>
           }
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root1}>
            <Grid container spacing={2}>
              <Grid container xs={12} spacing={2}>
                <Grid item>
                  <MembersCount
                    memberCountValue={memberCount}
                    memberCountLoading={memberCountLoading}
                    onRefresh={updateMemberCount}
                  />
                </Grid>

                {!fileName ? (
                  <>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                      }}>
                      {labelNames?.map(labelvalue => (
                        <PaperContainer title="Label :" value={labelvalue} />
                      ))}
                    </Grid>
                    {labelNames?.length > 1 && (
                      <Grid item style={{ display: 'flex', gap: '10px' }}>
                        <PaperContainer
                          title="Operator :"
                          value={
                            operator === 'OR'
                              ? `${operator} (part of any of these labels)`
                              : `${operator} (part of all these labels)`
                          }
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid item>
                    <Paper
                      className={classes.paper}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Typography
                        style={{ marginLeft: '4px', marginRight: '4px' }}>
                        <b data-testid="link">File :</b>
                      </Typography>{' '}
                      <Typography data-testid="cohort-details">
                        {fileName}
                      </Typography>
                      <DownloadFile cohortId={cohortId} />
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CohortGrid;
