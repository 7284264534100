import { memo } from 'react';
import { PrimaryButton } from '..';

const ButtonCellRenderer = memo(function ButtonCellRenderer({
  value,
  data,
  onClick,
  buttonText,
}) {
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return <PrimaryButton onClick={onClickHandler}>{buttonText}</PrimaryButton>;
});

export default ButtonCellRenderer;
