import React, { useEffect, useMemo, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
} from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
  AlertDialog,
} from 'components/widgets';
import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import SessionService from '../../../../src/services/SessionService';
import { STORAGE_KEYS } from 'shared/constants';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CampaignApi from 'services/api/CampaignApi';
import { apiPath, apiHost } from 'config/apiConfig';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as htmlToImage from 'html-to-image';
import DescriptionIcon from '@material-ui/icons/Description';
import { useDropzone } from 'react-dropzone';
import templateImage from '../../../assets/template3.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 1px 16px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#F5F7F8',
    padding: '10px',
    borderRadius: '8px',
  },
  cohortGrpContainer: {
    backgroundColor: '#E4E7EC',
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '10px',
    padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    padding: '0px 16px',
    width: '100%',
  },
  contentSummaryContainer: {
    backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
  },
  cohortGrpSummaryContainer: {
    // display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
    background: 'white',
    marginBottom: '2%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#344054',
  },
  dialogPaper: {
    minHeight: '85vh',
    maxHeight: '85vh',
    borderRadius: '24px',
    minWidth: '60vw',
    maxWidth: '60vw',
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    border: '1px dashed #8C8C8C',
    borderRadius: '8px',
    height: '200px',
    textAlign: 'center',
    padding: '15px',
    justifyContent: 'center',
  },
  templateRadio: {
    background: 'white',
    width: '80%',
    borderRadius: '8px',
  },
  templatePreview: {
    '& p': {
      marginBottom: '3%!important',
    },
  },
  imgContainer: {
    '& ::focus': {
      outline: 'none',
    },
  },
}));

const ASSESSMENT_HYPERLINK_TITLE = 'Take assessment here'

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: '10px',
    borderTop: 'none',
    marginRight: '10px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '10px 20px',
  },
}))(MuiDialogActions);

const DialogTitle = props => {
  const { children, onClose } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2" style={{ fontSize: '24px', fontWeight: 600 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const TEN_MB_IN_BYTES = 10485760;

const Dropzone = ({ myFiles, setMyFiles, deltEmailFiles }) => {
  const filesList = useRef({});
  const totalFileSize = useRef(0);
  if (Object.keys(filesList.current).length === 0 && myFiles.length) {
    myFiles.forEach(file => {
      if (typeof file === 'string') {
        let fileName = file.split('/')?.at(-1);
        filesList.current = {
          ...filesList.current,
          [fileName]: 'exists',
        };
      }
    });
  }
  const onDrop = acceptedFiles => {
    let uniqueFiles = [];
    acceptedFiles.forEach(file => {
      if (totalFileSize.current < TEN_MB_IN_BYTES) {
        if (!filesList.current[file.name]) {
          uniqueFiles.push(file);
          totalFileSize.current += file.size;
        }
        filesList.current = {
          ...filesList.current,
          [file.name]: 'exists',
        };
      }
    });
    setMyFiles([...myFiles, ...uniqueFiles]);
  };

  const remove = (idx, file) => {
    deltEmailFiles(file);
    const prevFiles = [...myFiles];
    if (typeof file === 'string') {
      let fileName = file.split('/')?.at(-1);
      delete filesList.current[fileName];
    } else {
      delete filesList.current[file.name];
    }
    totalFileSize.current -= file.size || 0;
    prevFiles.splice(idx, 1);
    setMyFiles(prevFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: true,
    maxFiles: 10,
    maxSize: TEN_MB_IN_BYTES,
  });

  let BYTES_TO_MB = 0.00000095367432;
  const files = myFiles?.map((file, index) => {
    let sizeInMB = file.size * BYTES_TO_MB;
    return (
      <Box
        key={file.name}
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        style={{ gap: '10px', margin: '0px 5px' }}>
        <DescriptionIcon style={{ color: '#DB0032' }} />
        <Typography
          key={file.path}
          style={{ fontWeight: 400, fontSize: '14px', color: '#101828' }}>
          {typeof file === 'string'
            ? `${file.split('/')?.at(-1) || file}`
            : `${file.name} (
              ${
                sizeInMB > 0.1
                  ? `${sizeInMB.toFixed(2)} MB`
                  : `${file.size} bytes`
              })`}
        </Typography>
        <CloseIcon
          style={{ fontSize: '22px', cursor: 'pointer' }}
          onClick={() => remove(index, file)}
        />
      </Box>
    );
  });
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.dropzoneContainer}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} data-testid="bulk-upload-file-upload" />

          <Typography
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#667085',
              marginBottom: '10px',
            }}>
            JPG, PNG or PDF smaller than 10MB
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#344054',
              marginBottom: '5px',
            }}>
            Drag and drop your files here or
          </Typography>
          <SecondaryButton onClick={open}>Choose file</SecondaryButton>
        </div>
      </Box>
      {myFiles?.length > 0 && (
        <Box
          style={{
            border: '1px solid #8C8C8C',
            borderRadius: '4px',
            display: 'flex',
            marginTop: '10px',
            padding: '10px',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px',
          }}
          data-testid="list-uploaded-file">
          {files}
        </Box>
      )}
    </Box>
  );
};

const MPH_LOGO_URL =
  'https://mph-dev-campaign-email-assets.s3.amazonaws.com/default/mphlogo.png';

export default function ContentForCampaign({
  campaignValues,
  setSavedContent,
  setEditContent,
  tabStatus,
  setCampaign,
  assessmentId,
  hasCreatePermissions
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [contentSubject, setContentSubject] = useState(
    campaignValues?.emailTemplate?.subject || ''
  );
  const [emailAttribute, setEmailAttribute] = useState([]);
  const [htmlData, setHTMLdata] = useState('');
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const orgId = sessionStorage.getItem("orgId");
  const [files, setFiles] = useState(
    campaignValues?.emailTemplate?.attachmentUrls || []
  );
  const [openPreview, setOpenPreview] = useState(false);
  const [finalHtml, setFinalHtml] = useState('');
  const RADIO_OPTIONS = ['Default template', 'Create new'];
  const [selectTemplate, setSelectTemplate] = useState(
    campaignValues?.emailTemplate?.subject ? 'Default template' : 'Create new'
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [uploadFilesLoading, setUploadFilesLoading] = useState(false);
  const [testEmailLoading, setTestEmailLoading] = useState(false);
  const editorRef = useRef();
  const templateIdRef = useRef(
    campaignValues?.emailTemplate?.id ? campaignValues.emailTemplate.id : null
  );
  const isContentSavedBefore = useRef(
    campaignValues?.emailTemplate?.subject ? true : false
  );

  const embeddedDeletedFilesID = useRef([]);
  const mentionID = useRef([]);
  const userAddedFilesRef = useRef(new Map());
  const unsavedImageRef = useRef('');

  const [saveContentStatus, setSaveContentStatus] = useState(
    campaignValues?.emailTemplate?.subject
      ? tabStatus?.SAVED
      : tabStatus?.NOT_SAVED
  );
  const EmptyGridForUI = () => <Grid item sm={9} xs={6} />;
  const [contentGrpValue, setcontentGrpValue] = useState([
    {
      emailTemplate: {
        id: null,
        template: null,
        subject: null,
        attachmentUrls: [],
        attributesUsed: [],
      },
    },
  ]);

  const getAllEmailAttributeList = useCallback(() => {
    async function getAttribute() {
      try {
        const results = await CampaignApi.getEmailAttribute({ orgId });
        const arrayValue = [];
        results?.map(a => {
          arrayValue.push({
            id: a.id,
            name: a.name,
            description: a.description,
            text: a.name === "assessment_url" ? ASSESSMENT_HYPERLINK_TITLE : a.name,
            value: a.name === "assessment_url" ? ASSESSMENT_HYPERLINK_TITLE : `{{${a.name}}}`,
            url: a.name === "assessment_url" ? `http://localhost:8080/` : "",
          });
        });
        setEmailAttribute(arrayValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    }
    getAttribute();
  });

  useEffect(() => {
    getAllEmailAttributeList();
  }, []);
  const [e, setE] = useState(null);
  const [p, setP] = useState(false);

  useEffect(() => {
    if (campaignValues?.emailTemplate?.id) {
      templateIdRef.current = campaignValues?.emailTemplate?.id;
    }
  }, [campaignValues]);

  useEffect(() => {
    handleOnchange();
  }, [contentSubject, finalHtml]);

  useEffect(() => {
    if(assessmentId)
    {
    setFinalHtml(`<!DOCTYPE html>
    <html>
    <body>
    ${draftToHtml(convertToRaw(editorState.getCurrentContent()))}   
    </body>
    </html>
    `);
    }
    else{
      setFinalHtml(`<!DOCTYPE html>
    <html>
    <body>
    ${draftToHtml(convertToRaw(editorState.getCurrentContent()))}  
    </body>
    </html>
    `);

    }
  }, [selectTemplate]);

  const htmlTemplate =
    campaignValues?.emailTemplate?.template ? campaignValues?.emailTemplate?.template :
    assessmentId ?
    `<!DOCTYPE html>
  <html>
  <head>
  <style>
  h1 {
    margin-top: 10px;
  }
  p {
    margin-top:20px;
  }
  </style>
  </head>
  <body id="del" ref={ref}>
  <p style="text-align:left;"><img src="https://mph-dev-campaign-email-assets.s3.amazonaws.com/default/mphlogo.png" alt="undefined"/></p>
  <p>Hi <a class="wysiwyg-mention" data-mention data-value="{{first_name}}">@{{first_name}}</a> <a class="wysiwyg-mention" data-mention data-value="{{last_name}}">@{{last_name}}</a>&nbsp;</p>
  <h3 style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 1.38316rem;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;">It is extensible and testable</span></h3>
  <p style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">React DnD uses the HTML5 drag and drop under the hood, but it also lets you supply a custom “backend”. You can create a custom DnD backend based on the touch events, the mouse events, or something else entirely. For example, a built-in simulation backend lets you test drag and drop interaction of your components in a Node environment.</span></p>
  <h2 style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 1.62671rem;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;">Touch Support</span></h2>
  <p style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">For touch support, use React DnD with</span> <a href="https://npmjs.com/package/react-dnd-touch-backend" target="_self"><span style="color: rgb(64,120,192);background-color: transparent;font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">the touch backend</span></a> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">instead of the HTML5 backend.</span></p>
  <h2 style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 1.62671rem;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;">Non-Goals</span></h2>
  <p style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">React DnD gives you a set of powerful primitives, but it does not contain any readymade components. It's lower level than</span> <a href="https://jqueryui.com/" target="_self"><span style="color: rgb(64,120,192);background-color: transparent;font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">jQuery UI</span></a> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">or</span> <a href="http://interactjs.io/" target="_self"><span style="color: rgb(64,120,192);background-color: transparent;font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">interact.js</span></a> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">and is focused on getting the drag and drop interaction right, leaving its visual aspects such as axis constraints or snapping to you. For example, React DnD doesn't plan to provide a</span> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;"><code>Sortable</code>component. Instead it gives you the tools you need to build your own with any rendering customizations that you need.</span>&nbsp;</p>
  <p></p>
  
  <p><a href="http://localhost:8080" class="wysiwyg-mention" data-mention data-value="${ASSESSMENT_HYPERLINK_TITLE}">@${ASSESSMENT_HYPERLINK_TITLE}</a>&nbsp;</p><p></p>
  <p></p>
  <p>Thanks</p>
  <p>MpoweredHealth</p>
  
  </body>
  </html>`
  :
  `<!DOCTYPE html>
  <html>
  <head>
  <style>
  h1 {
    margin-top: 10px;
  }
  p {
    margin-top:20px;
  }
  </style>
  </head>
  <body id="del" ref={ref}>
  <p style="text-align:left;"><img src="https://mph-dev-campaign-email-assets.s3.amazonaws.com/default/mphlogo.png" alt="undefined"/></p>
  <p>Hi <a class="wysiwyg-mention" data-mention data-value="{{first_name}}">@{{first_name}}</a> <a class="wysiwyg-mention" data-mention data-value="{{last_name}}">@{{last_name}}</a>&nbsp;</p>
  <h3 style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 1.38316rem;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;">It is extensible and testable</span></h3>
  <p style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">React DnD uses the HTML5 drag and drop under the hood, but it also lets you supply a custom “backend”. You can create a custom DnD backend based on the touch events, the mouse events, or something else entirely. For example, a built-in simulation backend lets you test drag and drop interaction of your components in a Node environment.</span></p>
  <h2 style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 1.62671rem;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;">Touch Support</span></h2>
  <p style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">For touch support, use React DnD with</span> <a href="https://npmjs.com/package/react-dnd-touch-backend" target="_self"><span style="color: rgb(64,120,192);background-color: transparent;font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">the touch backend</span></a> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">instead of the HTML5 backend.</span></p>
  <h2 style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 1.62671rem;font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;">Non-Goals</span></h2>
  <p style="text-align:start;"><span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">React DnD gives you a set of powerful primitives, but it does not contain any readymade components. It's lower level than</span> <a href="https://jqueryui.com/" target="_self"><span style="color: rgb(64,120,192);background-color: transparent;font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">jQuery UI</span></a> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">or</span> <a href="http://interactjs.io/" target="_self"><span style="color: rgb(64,120,192);background-color: transparent;font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">interact.js</span></a> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;">and is focused on getting the drag and drop interaction right, leaving its visual aspects such as axis constraints or snapping to you. For example, React DnD doesn't plan to provide a</span> <span style="color: rgba(0,0,0,0.8);background-color: rgb(255,255,255);font-size: 18px;font-family: Helvetica Neue", Helvetica, sans-serif;"><code>Sortable</code>component. Instead it gives you the tools you need to build your own with any rendering customizations that you need.</span>&nbsp;</p>
  <p></p>
  <p></p>
  <p>Thanks</p>
  <p>MpoweredHealth</p>
  
  </body>
  </html>`;
  const blocksFromHtml = htmlToDraft(htmlTemplate);
  // if (contentBlock) {
  // const contentStatevalue = ContentState.createFromBlockArray(contentBlock.contentBlocks);

  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentStatevalue = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  // const editorState = EditorState.createWithContent(contentState);

  // const editorStateValue = EditorState.createWithContent(contentState);
  // setE(contentStatevalue);
  // setP(true);

  // }
  const setEmptyState = ()=>{
    const htmlTemplate1 =
    `<!DOCTYPE html>
  <html>
  <body id="del" ref={ref}>
  <p></p>
  <p></p>
  </body>
  </html>`;
    const blocksFromHtml1 = htmlToDraft(htmlTemplate1);
    const { contentBlocks, entityMap } = blocksFromHtml1;
    const contentStatevalue1 = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return (contentStatevalue1);
  }

  

  const [editorState, setEditorState] = useState(
    campaignValues?.emailTemplate?.template
      ? EditorState.createWithContent(contentStatevalue)
      : selectTemplate === 'Create new'
      ? assessmentId ?  EditorState.createWithContent(setEmptyState()) : EditorState.createEmpty()
      : EditorState.createWithContent(contentStatevalue)
  );

  const onTemplateSelection = value => {
    if (value === 'Create new') {
      if(assessmentId)
      {
        
        setEditorState(EditorState.createWithContent(setEmptyState()));
      }
      else
      {
        setEditorState(EditorState.createEmpty());
      }
      
    } else {
      setEditorState(EditorState.createWithContent(contentStatevalue));
    }
  };

  const onEditorStateChange = editorValue => {
    setFinalHtml(`<!DOCTYPE html>
    <html>
    <body>
    ${draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    </body>
    </html>
    `);
    setEditorState(editorValue);
    handlecontentStatus();
    setEditContent(true);
    handleOnchange();
  };

  const uploadImageCallBack = file => {
    let token = SessionService.getItem(STORAGE_KEYS.AUTH_TOKEN);
    let payload = new FormData();
    payload.append('isAttachment', false);
    if (templateIdRef.current !== null)
      payload.append('templateId', templateIdRef.current);
    payload.append('files', file);

    let config = {
      url: `${apiHost}${apiPath}/organization/${orgId}/campaign/s3/upload`,
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    let prevCampaignValues = structuredClone(campaignValues);

    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          templateIdRef.current = res.data.templateId;
          prevCampaignValues.emailTemplate.id = res.data.templateId;
          // setCampaign(prevCampaignValues);
          // handleOnchange();
          unsavedImageRef.current = res.data.uploads[0].url;
          userAddedFilesRef.current.set(res.data.uploads[0].url, 1);
          resolve({ data: { link: res.data.uploads[0].url } });
        })
        .catch(err => reject(err));
    });
  };

  const handleTypeSelection = e => {
    handlecontentStatus();
    setContentSubject(e.target.value);
  };

  const handleClearContent = () => {
    setContentSubject('');
    setHTMLdata('');
    setEditorState(EditorState.createEmpty());
    setEditContent(false);
    setExpanded(false);
    handleDeltAttachmentsOnCancelBtn()
    if (unsavedImageRef.current !== '') {
      CampaignApi.deltEmailFiles({
        orgId,
        payload: [unsavedImageRef.current.split('cloudfront.net/')?.[1]],
        templateID: campaignValues?.emailTemplate?.id || false,
      });
      unsavedImageRef.current = '';
    }
    setcontentGrpValue([
      {
        emailTemplate: {
          id: null,
          template: null,
          subject: null,
          attachmentUrls: [],
          attributesUsed: [],
        },
      },
    ]);
  };

  const handlecontentStatus = () => {
    if (saveContentStatus === tabStatus?.SAVED) {
      setSaveContentStatus(tabStatus?.NOT_SAVED);
      setSavedContent(false);
    }
  };

  const handleOnchange = val => {
    setHTMLdata(draftToHtml(convertToRaw(editorState.getCurrentContent())));

    // handlecontentStatus();
    // setEditContent(true);
    setcontentGrpValue([
      {
        emailTemplate: {
          id: templateIdRef.current,
          template: finalHtml,
          subject: contentSubject,
          attachmentUrls: [],
          attributesUsed: [],
        },
      },
    ]);
  };

  const handleEditCohort = () => {
    setSaveContentStatus(tabStatus?.NOT_SAVED);
    setSavedContent(false);
    setEditContent(true);
    setExpanded(prevValue => !prevValue);
  };

  const closeWithoutEdit = () => {
    setSaveContentStatus(tabStatus?.SAVED);
    setSavedContent(true);
    setEditContent(false);
    setContentSubject(campaignValues?.emailTemplate?.subject || '');
    setEditorState(EditorState.createWithContent(contentStatevalue));
    setExpanded(prevValue => !prevValue);
    handleDeltAttachmentsOnCancelBtn()
    if (unsavedImageRef.current !== '') {
      CampaignApi.deltEmailFiles({
        orgId,
        payload: [unsavedImageRef.current.split('cloudfront.net/')?.[1]],
        templateID: campaignValues?.emailTemplate?.id || false,
      });
      unsavedImageRef.current = '';
    }
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handleContentValidations = () => {
    if (!contentSubject && !htmlData) {
      enqueueSnackbar('Please enter value', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!htmlData) {
      enqueueSnackbar('Please create a template', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!contentSubject) {
      enqueueSnackbar('Please enter subject', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else {
      return true;
    }
    return false;
  };

  const handleDeltFilesAndAttributeId = () => {
    embeddedDeletedFilesID.current = [];
    const mentionIds = [];
    let prevSelectedFiles;

    if (
      selectTemplate !== 'Default template' &&
      !campaignValues?.emailTemplate?.template
    ) {
      prevSelectedFiles = {};
    } else {
      prevSelectedFiles = convertToRaw(contentStatevalue)?.entityMap;
    }

    if (prevSelectedFiles) {
      for (const image in prevSelectedFiles) {
        const { type, data } = prevSelectedFiles[image] || {};
        if (type === 'IMAGE' && data?.src?.includes('cloudfront.net')) {
          let prevImgCount = userAddedFilesRef.current.get(data.src) || 0;
          userAddedFilesRef.current.set(data.src, prevImgCount + 1);
        }
      }
    }

    const currentSelectedFiles = convertToRaw(
      editorState?.getCurrentContent()
    )?.entityMap;
    const currentSelectedFilesHash = new Map();
    if (currentSelectedFiles) {
      for (const image in currentSelectedFiles) {
        const { type, data } = currentSelectedFiles[image] || {};
        if (type === 'IMAGE' && data?.src?.includes('cloudfront.net')) {
          currentSelectedFilesHash.set(data.src, true);
        } else if (type === 'MENTION') {
          let mentionValue = emailAttribute?.find(
            val => val?.value === data?.value
          );
          if (!mentionIds.includes(mentionValue?.id) && mentionValue) {
            mentionIds.push(mentionValue?.id);
          }
        }
      }
    }

    mentionID.current = mentionIds;

    userAddedFilesRef.current.forEach((key, val) => {
      if (!currentSelectedFilesHash.get(val) && val !== MPH_LOGO_URL) {
        embeddedDeletedFilesID.current.push(val?.split('cloudfront.net/')?.[1]);
      }
    });
  };

  const handleDeltAttachments = file => {
    let prevUploadedFiles = campaignValues?.emailTemplate?.attachmentUrls || [];
    const deleteFile = selectedFile => {
      CampaignApi.deltEmailFiles({
        orgId,
        payload: [selectedFile?.split('cloudfront.net/')?.[1]],
        templateID: campaignValues?.emailTemplate?.id || false,
      });
    };
    let searchTerm = typeof file === 'string' ? file : file.name;
    let selectedFileIndex = prevUploadedFiles.findIndex(attchment =>
      attchment.endsWith(searchTerm)
    );
    if (selectedFileIndex > -1) {
      deleteFile(prevUploadedFiles[selectedFileIndex]);
      const prevFiles = structuredClone(campaignValues);
      prevFiles?.emailTemplate?.attachmentUrls?.splice(selectedFileIndex, 1);
      setCampaign(prevFiles);
    }
  };

  const handleDeltAttachmentsOnCancelBtn = () => {
    if (!files.length) return;
    let prevUploadedFiles = campaignValues?.emailTemplate?.attachmentUrls || [];
    files.forEach(file => {
      if (typeof file === 'string' && !prevUploadedFiles.includes(file)) {
        CampaignApi.deltEmailFiles({
          orgId,
          payload: [file.split('cloudfront.net/')?.[1]],
          templateID: campaignValues?.emailTemplate?.id || false,
        });
      }
    });
    setFiles(prevUploadedFiles);
  }

  const handleSaveAttachments = async () => {
    if (files.length === 0) return [];
    let attachmentUrls = [];
    let payload = new FormData();
    payload.append('isAttachment', true);
    if (templateIdRef.current !== null)
      payload.append('templateId', templateIdRef.current);
    let prevAttachments = campaignValues?.emailTemplate?.attachmentUrls || [];
    files.forEach(file => {
      if (typeof file !== 'string') {
        if (!prevAttachments.some(attchment => attchment.endsWith(file.name))) {
          payload.append('files', file);
        }
      } else if (typeof file === 'string' && !prevAttachments.includes(file)) {
        prevAttachments.push(file);
      }
    });

    if (payload.get('files') === null) {
      return [];
    }

    let prevCampaignValues = structuredClone(campaignValues);
    setUploadFilesLoading(true)

    await CampaignApi.filesUpload({ orgId, payload })
      .then(res => {
        templateIdRef.current = res.templateId;
        prevCampaignValues.emailTemplate.id = res.templateId;
        setCampaign(prevCampaignValues);
        res.uploads.forEach(file => {
          attachmentUrls.push(file.url);
        });

        setFiles([...prevAttachments, ...attachmentUrls]);
        setUploadFilesLoading(false)
      })
      .catch(err => {
        setUploadFilesLoading(false)
        console.error(err);
      });

    return [...prevAttachments, ...attachmentUrls];
  };

  const addAttachmentsWithEmail = attachmentUrls => {
    let attachmentUrlsTemplate = '';

    attachmentUrls.forEach(
      URL =>
        (attachmentUrlsTemplate += `<a href=${encodeURI(
          URL
        )} target="_self" style="margin-right: 5px">${
          URL?.split('/')?.at(-1) || ''
        }</a>`)
    );

    let emailTemplateWithAttachments = `<!DOCTYPE html>
    <html>
    <body>
    ${draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    <p></p>
    <div id='attachments-block'>
    <p><strong>Attachment URLs:</strong></p>    
    <p>
    ${attachmentUrlsTemplate}
    </p>
    </div>      
    </body>
    </html>
    `;

    return emailTemplateWithAttachments;
  };
  const handleSaveContent = async () => {
    if (!handleContentValidations()) return;
    setLoading(true);
    setSaveContentStatus(tabStatus?.SAVING);
    handleDeltFilesAndAttributeId();
    handleOnchange();
    let checkForEdit = campaignValues.id;
    let attachmentUrls = await handleSaveAttachments();
    let payload = {
      ...campaignValues,
      emailTemplate: contentGrpValue?.[0]?.emailTemplate,
    };
    payload.emailTemplate.attachmentUrls =
      attachmentUrls.length !== 0 ? attachmentUrls : files;

    payload.emailTemplate.attributesUsed = mentionID.current;

    if (payload.emailTemplate.attachmentUrls?.length) {
      payload.emailTemplate.template = addAttachmentsWithEmail(
        payload.emailTemplate.attachmentUrls
      );
    }

    let campaignId = payload.id;
    // todo Add Save API
    try {
      let results = await CampaignApi.saveCampaign({
        orgId,
        payload,
        campaignId,
      });
      if (embeddedDeletedFilesID.current.length) {
        CampaignApi.deltEmailFiles({
          orgId,
          payload: embeddedDeletedFilesID.current,
          templateID: campaignValues?.emailTemplate?.id || false,
        });
      }
      results.emailTemplate.template = results.emailTemplate.template?.replace(
        /<div id='attachments-block'>[\s\S]*?<\/div>/g,
        ''
      );
      setFiles(results.emailTemplate?.attachmentUrls);
      setCampaign(results);
      setExpanded(false);
      setLoading(false);
      isContentSavedBefore.current = true;
      setSaveContentStatus(tabStatus?.SAVED);
      setSavedContent(true);
      setEditContent(false);
    } catch {
      setSaveContentStatus(tabStatus?.REJECTED);
      setSavedContent(false);
      setEditContent(true);
      setLoading(false);
    }
  };

  const savedStatusStatus = useMemo(() => {
    if (saveContentStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
        />
      );
    } else if (saveContentStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
        />
      );
    } else if (saveContentStatus === tabStatus?.REJECTED) {
      return <HighlightOffIcon style={{ color: 'red', fontSize: '22px' }} />;
    } else {
      return (
        <CheckCircleOutlineIcon style={{ color: 'grey', fontSize: '22px' }} />
      );
    }
  }, [saveContentStatus]);

  const domEl = useRef(null);
  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(domEl.current);

    // download image
    const link = document.createElement('a');
    link.download = 'html-to-img.png';
    link.href = dataUrl;
    link.click();
  };

  const sendTestMail = async () => {
    if (!contentSubject) {
      return enqueueSnackbar('Please enter email subject', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }

    if (validateEmail(email)) {
      const mentionAttrName = [];
      const currentSelectedFiles = convertToRaw(
        editorState?.getCurrentContent()
      )?.entityMap;
      if (currentSelectedFiles) {
        for (const image in currentSelectedFiles) {
          const { type, data } = currentSelectedFiles[image] || {};
          if (type === 'MENTION') {
            let mentionValue = emailAttribute?.find(
              val => val?.value === data?.value || val?.value === ASSESSMENT_HYPERLINK_TITLE
            );
            if (!mentionAttrName.includes(mentionValue?.name) && mentionValue) {
              mentionAttrName.push(mentionValue?.name);
            }
          }
        }
      }
      let attachmentUrls = await handleSaveAttachments();

      let emailAttachments =
        attachmentUrls.length !== 0 ? attachmentUrls : files;

      let payload = {
        from: null,
        to: [email],
        templateName: campaignValues.name,
        template: emailAttachments?.length
          ? addAttachmentsWithEmail(emailAttachments)
          : finalHtml,
        subject: contentSubject,
        attributesUsed: mentionAttrName,
      };

      try {
        setTestEmailLoading(true)
        let results = await CampaignApi.sendTestMail({ orgId, payload });
        if (results?.[0]?.status !== 'Success') {
          enqueueSnackbar(results?.[0]?.error, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar('Email sent successfully', {
            variant: 'success',
            autoHideDuration: 1500,
          });
        }
        setTestEmailLoading(false)
        setOpenDialog(false);
        setEmail('');
      } catch {
        setTestEmailLoading(false)
        console.log('error');
      }
    } else {
      setEmail('');
      enqueueSnackbar('Please enter a valid email id', { variant: 'error' });
    }
  };

  const handleSendMailClick = () => {
    setOpenDialog(true);
  };

  const validateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  };

  const mediaBlockRenderer = block => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: false,
      };
    }

    return null;
  };

  const Image = ({ src, height, width, alt, ...props }) => {
    const [alignment, setAlignment] = useState('center');
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = event => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const handleAlignment = (value, e) => {
      setAlignment(value);
      const { block, contentState } = props;
      const entityKey = block.getEntityAt(0);
      contentState.mergeEntityData(entityKey, { alignment: value });
      e.stopPropagation();
    };

    const imageRef = useRef();

    const retainFocus = event => {
      event.stopPropagation();

      imageRef.current.focus();
    };

    if (!!src) {
      return (
        <div
          onClick={retainFocus}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={`rdw-image-alignment rdw-image-${alignment}`}
          style={{ width: '100%' }}>
          <span className="rdw-image-imagewrapper">
            <img
              src={src}
              alt={alt}
              ref={imageRef}
              style={{
                height,
                width,
                textAlign: alignment === 'center' ? 'none' : alignment,
              }}
              className={classes.imgContainer}
              tabindex="0"
            />

            {anchorEl && (
              <div style={{ display: 'flex', gap: '5px' }}>
                <button onClick={e => setAlignment('left', e)}>L</button>
                <button onClick={e => handleAlignment('center', e)}>C</button>
                <button onClick={e => handleAlignment('right', e)}>R</button>
              </div>
            )}
          </span>
        </div>
      );
    }
    return null;
  };

  const Media = props => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src, height, width, alt, alignment } = entity.getData();
    const type = entity.getType();

    let media = null;

    if (type === 'IMAGE') {
      media = (
        <Image
          src={src}
          height={height}
          width={width}
          alt={alt}
          alignment={alignment}
          {...props}
        />
      );
    }

    return media;
  };

  return (
    <div className={classes.root}>
      <Accordion
        id="domEl"
        className={classes.containerBox}
        expanded={expanded}
        // onChange={() => setExpanded(prevValue => !prevValue)}
        data-testid="cohort-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {savedStatusStatus}
              <Typography
                data-testid="cohort-title"
                className={classes.heading}>
                Content
              </Typography>
              {!expanded && (
                <Box style={{ marginLeft: 'auto' }}>
                  {campaignValues?.status === 'DRAFT' ? (
                    isContentSavedBefore.current ? (
                      <SecondaryButton onClick={handleEditCohort}  hasPermissions={hasCreatePermissions}>
                        Edit
                      </SecondaryButton>
                    ) : (
                      <SecondaryButton
                        onClick={() => setExpanded(prevValue => !prevValue)} hasPermissions={hasCreatePermissions}>
                        Add content
                      </SecondaryButton>
                    )
                  ) : (
                    ''
                  )}
                </Box>
              )}
            </Box>
            <span style={{ marginLeft: '34px', fontSize: '14px' }}>
              What do you want to send?
            </span>
            {saveContentStatus === 'SAVED' && !expanded && (
              <Box
                className={classes.cohortGrpSummaryContainer}
                style={{ marginLeft: '30px' }}>
                {contentGrpValue?.map(action => {
                  return (
                    <>
                      <Box display={'flex'} alignItems={'center'}>
                        <Box className={classes.contentSummaryContainer}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ gap: '10px' }}>
                            <img
                              src={templateImage}
                              alt="Option 1"
                              width="110px"></img>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        style={{ marginTop: '1%' }}>
                        <SecondaryButton
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            setOpenPreview(true);
                          }}>
                          Preview
                        </SecondaryButton>
                      </Box>
                    </>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            {/* radio button for template selection */}
            <div style={{ marginLeft: '3%' }}>
              <Grid item sm={12} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    style={{ display: 'flex', flexDirection: 'row' }}
                    aria-label="selectTemplate"
                    name="selectTemplate"
                    value={selectTemplate}
                    onChange={(e, value) => {
                      setSelectTemplate(value);
                      onTemplateSelection(value);
                    }}>
                    {RADIO_OPTIONS.map(val => (
                      <div>
                        <div className={classes.templateRadio}>
                          {val === 'Default template' ? (
                            <img
                              src={templateImage}
                              alt="Option 1"
                              width="110px"></img>
                          ) : (
                            <div
                              style={{ width: '150px', height: '130px' }}></div>
                          )}
                        </div>
                        <FormControlLabel
                          // disabled={isEdit}
                          key={val}
                          value={val}
                          control={<Radio color="primary" />}
                          label={
                            val === 'Default template' &&
                            isContentSavedBefore.current
                              ? 'Saved template'
                              : val
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </div>

            <Box
              className={classes.cohortGrpContainer}
              style={{ margin: '10px 0px', display: 'flex' }}>
              <Grid item sm={12} xs={12} className={classes.mt}>
                <Grid item sm={12} xs={12} className={classes.mt}>
                  <Typography className={classes.formTitle}>Subject</Typography>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ maxWidth: '100%' }}>
                    <TextField
                      required
                      id="content-subject"
                      margin="dense"
                      variant="outlined"
                      className={classes.formTextField}
                      value={contentSubject}
                      onChange={e => {
                        setContentSubject(e.target.value);
                        handlecontentStatus();
                        handleOnchange();
                        setEditContent(true);
                      }}
                      autoComplete="off"
                      autoFocus
                    />
                  </FormControl>
                </Grid>
                <EmptyGridForUI />

                {/* text editor code start here */}
                <Grid item sm={12} xs={12} style={{ background: 'white' }}>
                  <Editor
                    ref={editorRef}
                    style={{ height: '600px' }}
                    customBlockRenderFunc={mediaBlockRenderer}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      image: {
                        uploadCallback: uploadImageCallBack,
                        previewImage: true,
                        alt: { present: true, mandatory: false },
                      },
                    }}
                    mention={{
                      separator: ' ',
                      trigger: '@',
                      suggestions: emailAttribute,
                    }}
                    handleKeyCommand={command => {
                      const newState = RichUtils.handleKeyCommand(
                        editorState,
                        command
                      );

                      if (newState) {
                        onEditorStateChange(newState);
                        return 'handled';
                      }

                      return 'not-handled';
                    }}
                    // hashtag={{}}
                  />
                </Grid>
                <EmptyGridForUI />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ background: 'white', marginTop: '2%' }}>
                  <Dropzone
                    myFiles={files}
                    setMyFiles={setFiles}
                    deltEmailFiles={handleDeltAttachments}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                className={classes.mt}
                style={{ textAlign: '-webkit-center', alignSelf: 'center' }}>
                <div style={{ display: 'flex' }}>
                  <SecondaryButton onClick={handleSendMailClick}>
                    Send a test mail
                  </SecondaryButton>

                  <SecondaryButton
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      setOpenPreview(true);
                    }}>
                    Preview
                  </SecondaryButton>
                  {/* <SecondaryButton onClick={downloadImage}>
                    download
                  </SecondaryButton> */}
                </div>
                {/* <textarea
                ref={domEl}
                  disabled
                  value={draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  )}
                /> */}
              </Grid>
            </Box>
            <Box style={{ margin: '10px 0px' }}>
              <Box style={{ margin: '20px 0px', display: 'flex', gap: '10px' }}>
                <SecondaryButton
                  onClick={
                    isContentSavedBefore.current
                      ? closeWithoutEdit
                      : handleClearContent
                  }
                  disabled={
                    saveContentStatus === tabStatus?.SAVING ||
                    saveContentStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    contentGrpValue[0]?.emailTemplate?.template ===
                      'placeholder' ||
                    saveContentStatus === tabStatus?.SAVING ||
                    saveContentStatus === tabStatus?.SAVED
                  }
                  onClick={handleSaveContent}
                  data-testid="save-criteria">
                  {saveContentStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* modal pop up to show preview */}
      <Dialog
        customStyle
        classes={{ paper: classes.dialogPaper }}
        onClose={handlePreviewClose}
        aria-labelledby="customized-dialog-title"
        open={openPreview}>
        <Box style={{ padding: '10px', flex: 1 }}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handlePreviewClose}>
            Template preview
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <div
                className="templatePreview"
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  ),
                }}></div>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
      {openDialog && (
        <AlertDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setEmail('');
          }}
          onConfirm={sendTestMail}
          emailAlert={true}
          email={email}
          setEmail={setEmail}
          description="Please enter the email id to send a test email"
          title="Enter email id"
          loading={uploadFilesLoading || testEmailLoading}
        />
      )}

      <SimpleBackdrop open={loading} />
    </div>
  );
}
