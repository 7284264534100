import React, { useState, useEffect, useCallback } from 'react';
import LOBGridPriceList from './LOBGridPriceList';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';

const PriceListPagination = props => {
  const { numberOfRowsPerPage, rowData, onViewPriceList } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState(rowData);

  const onPageClick = useCallback(
    pageNumber => {
      const lastIndex = numberOfRowsPerPage * pageNumber;
      const initialIndex = lastIndex - numberOfRowsPerPage;
      const tableInput = rowData.slice(initialIndex, lastIndex);
      const paginatedData = tableInput ? tableInput : rowData;
      setTableData(paginatedData);
      setPageNumber(pageNumber - 1);
    },
    [numberOfRowsPerPage, rowData]
  );

  useEffect(() => {
    onPageClick(1);
  }, [onPageClick]);

  return (
    <>
      <LOBGridPriceList rowData={tableData} onViewPriceList={onViewPriceList}/>
      {rowData.length > numberOfRowsPerPage && (
        <PaginationComponent
          numberOfRowsPerPage={numberOfRowsPerPage}
          count={rowData.length}
          onPageClick={onPageClick}
          page={pageNumber}
        />
      )}
    </>
  );
};

export default PriceListPagination;
