import { Component } from 'react';
import { DataGrid } from 'components/widgets';
import moment from 'moment';
import ConfigurationsActionCellTemplate from './ConfigurationsActionCellTemplate';

class DocGrid extends Component {
  state = {
    columnDefs: [
      {
        field: 'documentName',
        headerName: 'Document name',
      },
      {
        field: 'documentType',
        headerName: 'Document type',
        cellRenderer: 'TypeRenderer',
      },
      {
        field: 'createdAt',
        headerName: 'Upload date',
        cellRenderer: 'DateFormatter',
      },
      {
        field: 'category',
        headerName: 'Category',
        cellRenderer: 'CategoryRenderer',
      },
      {
        field: 'actions',
        headerName: '',
        width: 100,
        maxWidth: 100,
        cellRenderer: 'ActionsRenderer',
        resizable: false,
        sortable: false,
        filter: false,
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 180,
      resizable: true,
      sortable: true,
      filter: false,
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        let formattedDate = moment(rowData.value).format('MMM DD YYYY hh:mm A');
        return formattedDate;
      },
      ActionsRenderer: props => (
        <ConfigurationsActionCellTemplate
          {...props}
          onDownload={item => this.onItemDownload(item)}
          allowDownload={true}
        />
      ),
      TypeRenderer: rowData => {
        return !rowData.value ? '' : (rowData.value?.split('/')?.[1] || rowData.value);
      },
      CategoryRenderer: rowData => {
        return !rowData.value ? '' : 'Financial';
      },
    },
    context: { componentParent: this },
  };

  onItemDownload(data) {
    this.props.onDownload(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            // rowSelection="single"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh' }}
            hideHeaderActions
          />
        </div>
      </div>
    );
  }
}

export default DocGrid;
