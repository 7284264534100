import * as actionTypes from '../actions/actionTypes';

const initialState = {
  context: {
    organization: {
      show: false,
      orgId: null,
      triggerUpdate: false,
    },
    alerts: {
      show: false,
      orgId: null,
      triggerUpdate: false,
    },
    application: {
      show: false,
      orgId: null,
      affiliateId: null,
      data: null,
      triggerUpdate: false,
      isViewMode: false,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SETUP_ORG:
      return {
        ...state,
        context: {
          ...state.context,
          organization: {
            show: true,
            orgId: action.orgId,
            triggerUpdate: false,
          },
        },
      };
    case actionTypes.HIDE_SETUP_ORG:
      return {
        ...state,
        context: {
          ...state.context,
          organization: {
            show: false,
            orgId: null,
            triggerUpdate: action.triggerUpdate,
          },
        },
      };
    case actionTypes.SHOW_ORG_ALERTS:
      return {
        ...state,
        context: {
          ...state.context,
          alerts: {
            show: true,
            orgId: action.orgId,
            triggerUpdate: false,
          },
        },
      };
    case actionTypes.HIDE_ORG_ALERTS:
      return {
        ...state,
        context: {
          ...state.context,
          alerts: {
            show: false,
            orgId: null,
            triggerUpdate: action.triggerUpdate,
          },
        },
      };

    case actionTypes.SHOW_SAVE_APPLICATION:
      return {
        ...state,
        context: {
          ...state.context,
          application: {
            show: true,
            orgId: action.orgId,
            affiliateId: action.affiliateId,
            data: action.data,
            triggerUpdate: false,
            isViewMode: action.isViewMode,
            sandboxStatus: action.sandboxStatus,
          },
        },
      };
    case actionTypes.HIDE_SAVE_APPLICATION:
      return {
        ...state,
        context: {
          ...state.context,
          application: {
            show: false,
            orgId: null,
            data: null,
            affiliateId: null,
            triggerUpdate: action.triggerUpdate,
            isViewMode: false,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
