import React from 'react';
import { Component, memo } from 'react';
import { DataGrid } from 'components/widgets';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  attributeType: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
    borderRadius: "16px",
    backgroundColor: "#E2F1FC",
    alignItems: "center",
    width: 'fit-content',
    maxWidth: "120px",
    height: "24px",
    border: "1px solid #036FCB",
    padding: "10px",
    fontWeight: "500",
    color: "#036FCB",
  },
}));

const AttributeType = memo(function AttributeType({ data }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.attributeType}>{data.type || '-'}</div>
    </>
  );
});

class AttributeMainGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Attributes ',
        field: 'name',
        colId: 'NameID',
      },
      {
        headerName: 'Type',
        field: 'type',
        cellRenderer: 'TypeofAttribute',
      },
      {
        headerName: 'Description',
        field: 'description',
        cellRenderer: 'Destriptionattribute',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      SetRenderer: rowData => {
        return !rowData?.value
          ? ''
          : rowData?.value?.map(item => item.name).join(', ');
      },
      TypeofAttribute: rowData => (
        <AttributeType {...rowData} onClick={item => this.onItemView(item)} />
      ),
      AppRenderer: rowData => {
        if (!rowData?.value || rowData?.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData?.value?.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            selectTableData={selectTableData}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default AttributeMainGrid;
