import { useState, useEffect, useCallback } from 'react';
import {
  SecondaryButton,
  SimpleBackdrop,
  AlertDialog,
  SearchTextField,
  SelectInput,
  PageHeaderTitle,
} from 'components/widgets';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { makeStyles } from '@material-ui/core/styles';
import AddEditUser from './AddEditUser';
import NewUserFlyout from './NewUserFlyout';
import UserPagination from './UserPagination';
import { useParams } from 'react-router-dom';
import { Grid, LinearProgress } from '@material-ui/core';
import DataPartnersApi from 'services/api/DataPartnersApi';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import MasterDataApi from 'services/api/MasterDataApi';
import { useSnackbar } from 'notistack';
import {
  USER_ROLES,
  INVITE_USER_ROLES,
  DISPLAY_USER_ROLES,
} from 'shared/constants';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  addBtn: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  container: {
    // justifyContent: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

export default function ManageUsers() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [lobs, setLOBs] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allAvailableRoles, setAllAvailableRoles] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    affiliate: '',
    role: '',
    lob: '',
    name: '',
  });
  const [flyout, setFlyout] = useState({ open: false, title: '' });
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [selectedUser, setSelectedUser] = useState(null);
  // const [filterText, setFilterText] = useState('');
  // const [searchValue, setSearchValue] = useState('');

  const { userType } = useParams();
  const orgId = sessionStorage.getItem("orgId");
  const isInternalUser = userType === 'internal';
  const numberOfRowsPerPage = 10;

  const { enqueueSnackbar } = useSnackbar();
  const {
    info: { userId },
  } = useSelector(state => state.userInfo);

  // const searchUsers = useCallback(
  //   async (_orgId, _userType, _name) => {
  //     setSelectedFilter({
  //       affiliate: '',
  //       role: '',
  //       lob: '',
  //       name: '',
  //     });
  //     setLoading(true);

  //     try {
  //       const results = await OrganizationUsersApi.searchUsers(_orgId, _name);
  //       const data = results
  //         .filter(item => item.isDeleted !== 1)
  //         .filter(
  //           item =>
  //             item.userRoles.findIndex(
  //               uRole => uRole.roleName === USER_ROLES.Org_Owner
  //             ) === -1
  //         )
  //         .filter(
  //           item =>
  //             (_userType === 'internal' &&
  //               item.userRoles.findIndex(
  //                 uRole => uRole.roleName !== USER_ROLES.Third_Party_Developer
  //               ) !== -1) ||
  //             (_userType === 'external' &&
  //               !item.userRoles.some(
  //                 item => item.roleName === USER_ROLES.IT_Admin
  //               ) &&
  //               item.userRoles.findIndex(
  //                 uRole => uRole.roleName === USER_ROLES.Third_Party_Developer
  //               ) !== -1)
  //         )
  //         .filter(item => item.id !== userId)
  //         .map(item => {
  //           let uniqueAffiliates = [];

  //           const userRoles = item.userRoles
  //             ? item.userRoles
  //                 .filter(uRole => uRole.orgId + '' === _orgId)
  //                 .map(rItem => ({
  //                   ...rItem,
  //                   roleDisplayName:
  //                     DISPLAY_USER_ROLES[rItem.roleName] || rItem.roleName,
  //                 }))
  //             : [];

  //           if (userRoles && userRoles.length > 0) {
  //             userRoles.forEach(userRole => {
  //               if (
  //                 uniqueAffiliates.length === 0 ||
  //                 uniqueAffiliates.findIndex(
  //                   ua => ua.id === userRole.affiliateId
  //                 ) === -1
  //               ) {
  //                 uniqueAffiliates.push({
  //                   id: userRole.affiliateId,
  //                   name: userRole.affiliateName,
  //                 });
  //               }
  //             });
  //           }

  //           return {
  //             ...item,
  //             userRoles,
  //             affiliateIds: uniqueAffiliates
  //               ? uniqueAffiliates.map(aItem => aItem.id)
  //               : [],
  //             affiliates: [...uniqueAffiliates],
  //             planIds: item.plans ? item.plans.map(pItem => pItem.id) : [],
  //             roleIds: item.userRoles.map(rItem => rItem.roleId),

  //             hasNonAllowedRoles:
  //               userRoles.findIndex(
  //                 item => !INVITE_USER_ROLES.includes(item.roleName)
  //               ) !== -1,
  //           };
  //         });
  //       setUsers(data);

  //       setFilteredUsers(data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log('error: ', error);
  //       setLoading(false);
  //       enqueueSnackbar('Something went wrong fetching users', {
  //         variant: 'error',
  //       });
  //     }
  //   },
  //   [enqueueSnackbar, userId]
  // );

  const getAllUsers = useCallback(
    async (_orgId, _userType) => {
      setSelectedFilter({
        affiliate: '',
        role: '',
        lob: '',
        name: '',
      });
      setLoading(true);

      try {
        const results = await OrganizationUsersApi.getAllUsers(_orgId);
        const data = results
          .filter(item => item.isDeleted !== 1)
          .filter(
            item =>
              item.userRoles.findIndex(
                uRole => uRole.roleName === USER_ROLES.Org_Owner
              ) === -1
          )
          .filter(
            item =>
              (_userType === 'internal' &&
                item.userRoles.findIndex(
                  uRole => uRole.roleName !== USER_ROLES.Third_Party_Developer
                ) !== -1) ||
              (_userType === 'external' &&
                !item.userRoles.some(
                  item => item.roleName === USER_ROLES.IT_Admin || USER_ROLES.ORGANIZATION_ADMIN
                ) &&
                item.userRoles.findIndex(
                  uRole => uRole.roleName === USER_ROLES.Third_Party_Developer
                ) !== -1)
          )
          .filter(item => item.id !== userId)
          .map(item => {
            let uniqueAffiliates = [];

            const userRoles = item.userRoles
              ? item.userRoles
                  .filter(uRole => uRole.orgId + '' === _orgId)
                  .map(rItem => ({
                    ...rItem,
                    roleDisplayName:
                      DISPLAY_USER_ROLES[rItem.roleName] || rItem.roleName,
                  }))
              : [];

            if (userRoles && userRoles.length > 0) {
              userRoles.forEach(userRole => {
                if (
                  uniqueAffiliates.length === 0 ||
                  uniqueAffiliates.findIndex(
                    ua => ua.id === userRole.affiliateId
                  ) === -1
                ) {
                  uniqueAffiliates.push({
                    id: userRole.affiliateId,
                    name: userRole.affiliateName,
                  });
                }
              });
            }

            return {
              ...item,
              userRoles,
              affiliateIds: uniqueAffiliates
                ? uniqueAffiliates.map(aItem => aItem.id)
                : [],
              affiliates: [...uniqueAffiliates],
              planIds: item.plans ? item.plans.map(pItem => pItem.id) : [],
              roleIds: item.userRoles.map(rItem => rItem.roleId),

              hasNonAllowedRoles:
                userRoles.findIndex(
                  item => !INVITE_USER_ROLES.includes(item.roleName)
                ) !== -1,
            };
          });
        setUsers(data);

        setFilteredUsers(data);
        setLoading(false);
      } catch (error) {
        console.log('error: ', error);
        setLoading(false);
        enqueueSnackbar('Something went wrong fetching users', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, userId]
  );

  const getAllPartners = useCallback(
    async _orgId => {
      try {
        const results = await DataPartnersApi.getAllPartners(_orgId);
        const data = results
          .filter(item => item.isDeleted !== 1)
          .map(fItem => ({ code: fItem.id, name: fItem.name }));
        setAffiliates(data);
        // setLoading(false);
      } catch (error) {
        // console.log('error: ', error);
        // setLoading(false);
        enqueueSnackbar('Something went wrong fetching affiliates', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const getAllLOBs = useCallback(
    async (_orgId, type) => {
      try {
        const results = await PartnerLOBApi.getAll(_orgId);
        const data = results
          .filter(item => item.isDeleted !== 1)
          .map(fItem => ({
            id: fItem.id,
            name: fItem.name,
            orgId: fItem.orgId,
            affiliateIds: fItem.affiliates
              ? fItem.affiliates.map(aff => aff.id)
              : [],
          }));
        setLOBs(data);
      } catch (error) {
        enqueueSnackbar('Something went wrong fetching LOBs', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const getAllRoles = useCallback(async () => {
    try {
      const results = await MasterDataApi.getRoles();

      const data = results
        .sort((el1, el2) => el1.rank - el2.rank)
        // .filter(item => item.name !== USER_ROLES.Org_Owner)
        .map(item => ({
          roleId: item.id,
          roleName: item.name,
          roleDisplayName: DISPLAY_USER_ROLES[item.name] || item.name,
        }));

      const availableRoles = data.filter(item =>
        INVITE_USER_ROLES.includes(item.roleName)
      );
      setAllAvailableRoles(availableRoles);

      setAllRoles(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong fetching Roles', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  useEffect(() => {
    if (orgId && userType) {
      getAllUsers(orgId, userType);
      getAllPartners(orgId, userType);
      getAllLOBs(orgId);
    }
  }, [orgId, userType, getAllUsers, getAllPartners, getAllLOBs]);

  // useEffect(() => {
  //   if(orgId && userType && searchValue) {
  //     searchUsers(orgId, userType, searchValue);
  //   }
  // }, [orgId, userType, searchValue, searchUsers])

  const onAppleFilter = (key, value) => {
    setSelectedFilter({ ...selectedFilter, [key]: value });
  };

  const { name, affiliate, lob, role } = selectedFilter;

  useEffect(() => {
    const data = users
      .filter(
        fItem =>
          (fItem.firstName + ' ' + fItem.lastName)
            .toLowerCase()
            .indexOf(name.trim().toLowerCase()) !== -1
      )
      .filter(aItem => !affiliate || aItem.affiliateIds.includes(affiliate))
      .filter(pItem => !lob || pItem.planIds.includes(lob))
      .filter(rItem => !role || rItem.roleIds.includes(role));

    setFilteredUsers(data);
  }, [name, affiliate, lob, role, users]);

  // const emptyAddress = {
  //   city: '',
  //   country: 'US',
  //   line1: '',
  //   line2: '',
  //   name: '',
  //   state: '',
  //   type: '',
  //   zip: '',
  //   contacts: [],
  // };

  const onAddUser = () => {
    let emptyUser = {
      firstName: '',
      lastName: '',
      emailAddress: '',
      role: '',
      organizationName: '',
      affiliate: '',
      lob: '',
    };

    if (!isInternalUser) {
      const externalDevRoleIndex = allRoles.findIndex(
        item => item.roleName === USER_ROLES.Third_Party_Developer
      );

      emptyUser.userRoles.push({
        ...allRoles[externalDevRoleIndex],
      });
    }

    setFlyout({
      open: true,
      user: emptyUser,
      title: isInternalUser ? 'Invite user' : 'Invite developer',
    });
  };

  const onEditUser = user => {
    setSelectedUser(user);
    setFlyout({
      open: true,
      user: user,
      title: 'Edit User Details',
    });
  };

  const onUserUpdatedHandler = async () => {
    getAllUsers(orgId, userType);
    setFlyout({ open: false, title: '' });
    setSelectedUser(null);
  };

  const onUserToggle = () => {
    setFlyout({ open: false, title: '' });
  };

  const onDeleteUser = user => {
    setSelectedUser(user);
    setAlertDialog({
      open: true,
      title: 'Delete user',
      description: 'Are you sure, you want to delete this user?',
    });
  };

  const onDialogConfirm = async () => {
    setLoading(true);
    try {
      await OrganizationUsersApi.deleteUser(orgId, selectedUser.id);
      await getAllUsers(orgId, userType);
      setAlertDialog({ open: false });
      setSelectedUser(null);
      enqueueSnackbar('User deleted successfully', { variant: 'success' });

      setLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      setAlertDialog({ open: false });
      enqueueSnackbar('Something went wrong while deleting user', {
        variant: 'error',
      });
    }
  };

  const onDialogClose = () => {
    setSelectedUser(null);
    setAlertDialog({ open: false });
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        style={{ marginBottom: 16, marginTop: 16 }}
      >
        <Grid item xs={6} md={6}>
          <PageHeaderTitle>
            {`Manage ${isInternalUser ? 'Users' : 'Developers'}`}
          </PageHeaderTitle>
        </Grid>
        <Grid item xs={6} md={6}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: 16,
            }}
          >
            <SecondaryButton onClick={onAddUser} size="large" color="primary">
              {isInternalUser ? 'Invite user' : 'Invite developer'}
            </SecondaryButton>
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <div>
                <SearchTextField
                  label={`Search ${isInternalUser ? 'users' : 'developers'}`}
                  onChangeText={val => onAppleFilter('name', val)}
                  // onChangeText={value => setSearchValue(value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SelectInput
                // label,
                value={selectedFilter['affiliate']}
                onChange={val => onAppleFilter('affiliate', val)}
                options={affiliates}
                emptyLabel="Affiliate: All"
                optionValueKey="code"
              />
            </Grid>
            {isInternalUser && (
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <SelectInput
                  value={selectedFilter['role']}
                  onChange={val => onAppleFilter('role', val)}
                  options={allAvailableRoles}
                  emptyLabel="Role: All"
                  optionValueKey="roleId"
                  optionLabelKey="roleDisplayName"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SelectInput
                value={selectedFilter['lob']}
                onChange={val => onAppleFilter('lob', val)}
                options={lobs}
                emptyLabel="LOB: All"
                optionValueKey="id"
                optionLabelKey="name"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <UserPagination
            rowData={filteredUsers}
            numberOfRowsPerPage={numberOfRowsPerPage}
            onEdit={onEditUser}
            onDelete={onDeleteUser}
            isInternalUser={isInternalUser}
          />

          <NewUserFlyout
            onToggle={onUserToggle}
            loading={loading}
            affiliates={affiliates}
            allLobs={lobs}
            allRoles={allRoles}
            allAvailableRoles={allAvailableRoles}
            isInternalUser={isInternalUser}
            onUserUpdated={onUserUpdatedHandler}
            orgId={orgId}
            {...flyout}
          />
        </Grid>
      </Grid>
      {alertDialog.open && (
        <AlertDialog
          onClose={onDialogClose}
          onConfirm={onDialogConfirm}
          loading={loading}
          {...alertDialog}
        />
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
}
