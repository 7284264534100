import React, { useEffect } from 'react';
import ContactLogo from '../../assets/signup-logo.svg';
import { makeStyles, Box, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 100px)',
    backgroundColor: '#F5F7F8',
  },
  detailsContainer: {
    backgroundColor: '#FFFFFF',
    minHeight: '352px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    flexDirection: 'column',
    padding: '40px',
    gap: '20px',
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const redirectPage = () => {
    window.location.href = 'https://www.mpoweredhealth.com/';
  };

  useEffect(() => {
    let redirectTimer = setTimeout(() => {
      redirectPage();
    }, 5000);

    return () => clearTimeout(redirectTimer);
  }, []);
  return (
    <Box className={classes.contactContainer}>
      <Box className={classes.detailsContainer}>
        <img src={ContactLogo} alt="contact-logo" />
        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
          We will contact you soon!
        </Typography>
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: 400,
            color: '#667085',
            lineHeight: '20px',
            textAlign: 'center',
          }}>
          We have registered your request. You will receive an email when it gets
          approved by the admin
        </Typography>
        <Button
          variant="text"
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={redirectPage}>
          Go to homepage
        </Button>
      </Box>
    </Box>
  );
};

export default ContactPage;
