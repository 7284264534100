import React, { useEffect } from 'react';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Container, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { convertToUTCDate } from '../../helper';
import colors from '../../colors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    maxWidth: '100%',
  },
  textFieldLabel: { 
    fontFamily: 'Inter',
    fontSize: "18px", 
    fontWeight: 400, 
    marginBottom: '16px',
    color: colors.PRIMARY_GRAY,
    lineHeight: '16px',
    boxSizing: 'border-box',
    height: theme.spacing(2),
  },
  timePicker: {
    '& .MuiOutlinedInput-input': {
      color: colors.PRIMARY_GRAY,
      fontFamily: 'Inter',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      padding: '0px 12px',
      opacity: 0.87,
      boxSizing: 'border-box',
      height: theme.spacing(5),
    },
    '& .MuiIconButton-root': { 
      padding: theme.spacing(0), 
      marginRight: theme.spacing(0),
      color: colors.TITLE_GRAY,
      fontFamily: 'Material Icons Round',
     },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.GRAY_SECONDARY}`,
    },

    '& .MuiOutlinedInput-root.Mui-disabled':{
      backgroundColor: colors.SUBTLE_GRAY,
    },
    '& .MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.BLUE}`, 
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.SUBTLE_GRAY}`,
    },
  },
  required: { 
    color: colors.RED, 
    fontSize: theme.spacing(2), 
    fontWeight: 500, 
    marginLeft: -theme.spacing(0.25)
  },
}));

const SelfReportingTimePicker = (props) => {
  const classes = useStyles();

  const {
    fullWidth = true,
    focused,
    variant,
    inputVariant,
    name,
    valueKey,
    color,
    placeholder,
    value,
    changeHandler,
    required = false,
    label,
    error,
  } = props;

  const [pickerValue, setValue] = React.useState(value || null);

  useEffect(() => {
    if (value) {
      setValue(new Date(value));
    }
  }, [value]);

  const convertToDefaultPara = (res) => {
    const result = res && res.toString();
    setValue(result);
    return res;
  };

  return (
    <Container className={classes.root}>
      <p className={classes.textFieldLabel}>
        {label} <span className={classes.required}>{required ? '*' : ''}</span>
      </p>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          fullWidth={fullWidth}
          focused={focused}
          className={classes.timePicker}
          variant={variant || 'outlined'}
          inputVariant={inputVariant || 'outlined'}
          name={name || valueKey || 'time'}
          color={color || 'secondary'}
          value={pickerValue}
          placeholder={placeholder}
          onChange={(date) => changeHandler(convertToDefaultPara(date))}
          error={error}
          renderInput={(params) => (
            <TextField {...params} sx={{ width: fullWidth ? '100%' : 'unset' }} />
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <KeyboardArrowDown />
              </InputAdornment>
            ),
          }}
        />
      </LocalizationProvider>
    </Container>
  );
};

export default SelfReportingTimePicker;
