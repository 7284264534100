import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M14.1924 22.8799V17.8076H18.3076V22.8799C18.3076 23.4541 18.7383 23.9326 19.3125 23.9326H22.375C22.9492 23.9326 23.3799 23.4541 23.3799 22.8799V15.75H25.1504C25.5811 15.75 25.8203 15.1758 25.4854 14.8408L16.9199 7.18457C16.5371 6.84961 15.9629 6.84961 15.5801 7.18457L7.01465 14.8408C6.67969 15.1758 6.91895 15.75 7.34961 15.75H9.12012V22.8799C9.12012 23.4541 9.55078 23.9326 10.125 23.9326H13.1875C13.7617 23.9326 14.1924 23.4541 14.1924 22.8799Z" />
    </SvgIcon>
  );
};

export default Icon;
