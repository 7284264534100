import BaseApiService from './BaseApiService';

function _getAllCohorts({ orgId, offset, limit, searchText }) {
  return BaseApiService.get(
    `/organization/${orgId}/cohort?offset=${offset}&limit=${limit}&searchText=${searchText}`
  );
}

function _getAllCampaigns({ orgId, offset, searchStatus, searchName, limit }) {
  return BaseApiService.get(
    `/organization/${orgId}/campaign?offset=${offset}&limit=10&searchStatus=${searchStatus}&searchName=${searchName}`
  );
}
function _checkExistingCohort(orgId, cohortName) {
  return BaseApiService.get(
    `/organization/${orgId}/cohort/check/${cohortName}`
  );
}

function _createCohort(orgId, payload) {
  return BaseApiService.post(`/organization/${orgId}/cohort`, null, payload);
}

function _editCohort(orgId, cohortId, payload) {
  return BaseApiService.put(
    `/organization/${orgId}/cohort/${cohortId}`,
    null,
    payload
  );
}

function _deleteCohort(orgId, cohortId) {
  return BaseApiService.delete(`/organization/${orgId}/cohort/${cohortId}`);
}

function _getUploadedCohortFile(orgId, cohortId) {
  return BaseApiService.get(
    `/organization/${orgId}/cohort/${cohortId}/file-link`
  );
}

function _getLabels(orgId) {
  return BaseApiService.get(`/organization/${orgId}/label/all`);
}

function _getEmailAttribute({ orgId }) {
  return BaseApiService.get(`/organization/${orgId}/campaign/email-attr`);
}

function _saveCampaign({ orgId, payload, campaignId }) {
  return campaignId
    ? BaseApiService.put(
        `/organization/${orgId}/campaign/${campaignId}`,
        null,
        payload
      )
    : BaseApiService.post(`/organization/${orgId}/campaign`, null, payload);
}

function _getCampaignDetails({ orgId, campaignId }) {
  return BaseApiService.get(`/organization/${orgId}/campaign/${campaignId}`);
}

function _checkForDuplicate({ orgId, campaignTitle }) {
  return BaseApiService.get(
    `/organization/${orgId}/campaign/check?searchName=${campaignTitle}`
  );
}

function _filesUpload({ orgId, payload }) {
  return BaseApiService.post(
    `/organization/${orgId}/campaign/s3/upload`,
    null,
    payload
  );
}

function _campaignVersionHistory({ orgId, campaignId }) {
  return BaseApiService.get(
    `/organization/${orgId}/campaign/history/${campaignId}`
  );
}

function _getMemberCount({ orgId, payload }) {
  return BaseApiService.post(
    `/organization/${orgId}/label/get-custom-member-count`,
    null,
    payload
  );
}

function _getMemberCountByID({ orgId, cohortId }) {
  return BaseApiService.get(
    `/organization/${orgId}/cohort/get-member-count/${cohortId}`
  );
}

function _deleteEmailFiles({ orgId, payload, templateID }) {
  return BaseApiService.delete(
    `/organization/${orgId}/campaign/s3/delete${
      templateID ? `?templateId=${templateID}` : ''
    }`,
    null,
    payload
  );
}

function _sendTestMail({ orgId, payload }) {
  return BaseApiService.post(
    `/organization/${orgId}/email/sendTestMail`,
    null,
    payload
  );
}

function _failedReport({ orgId, transactionId }) {
  return BaseApiService.get(
    `/organization/${orgId}/campaign/failed-report/${transactionId}`
  );
}

function _nextSchedule(campaignId) {
  return BaseApiService.get(
    `/organization/schedules/CampainManager/CampainManager${campaignId}`
  );
}

const CampaignApi = {
  getAllCohorts: _getAllCohorts,
  checkExistingCohort: _checkExistingCohort,
  getEmailAttribute: _getEmailAttribute,
  createCohort: _createCohort,
  editCohort: _editCohort,
  deleteCohort: _deleteCohort,
  getUploadedCohortFile: _getUploadedCohortFile,
  getLabels: _getLabels,
  saveCampaign: _saveCampaign,
  checkForDuplicate: _checkForDuplicate,
  getAllCampaigns: _getAllCampaigns,
  filesUpload: _filesUpload,
  campaignDetails: _getCampaignDetails,
  campaignVersionHistory: _campaignVersionHistory,
  getMemberCount: _getMemberCount,
  deltEmailFiles: _deleteEmailFiles,
  sendTestMail:  _sendTestMail,
  failedReport: _failedReport,
  getMemberCountByID: _getMemberCountByID,
  nextSchedule : _nextSchedule,
};

export default CampaignApi;
