import React, { useState, useEffect, useMemo } from 'react';
import { PageHeaderTitle, SimpleBackdrop } from 'components/widgets';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FilterDropDown from 'components/FilterDropDown';
import LogsGrid from './LogsGrid';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import TransactionApi from 'services/api/TransactionApi';
import NotFoundPage from 'components/NotFoundPage';
import {
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
} from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    borderRadius: '40px',
    color: '#036FCB',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
    marginRight: '12px',
  },
}));

const Logs = () => {
  const classes = useStyles();
  const orgId = sessionStorage.getItem("orgId");

  const [loading, setLoading] = useState(false);
  const [moduleFilter, setModuleFilter] = useState('all');
  const [allTransaction, setAllTransaction] = useState([]);
  const [offset, setOffset] = useState(0);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [OnloadData, setOnloadData] = useState(false);
  const [moduleCode, setModuleCode] = useState([]);

  const formattedModuleCode = moduleCode?.map(moduleCode => {
    return {
      label: moduleCode.moduleName,
      value: moduleCode.moduleCode,
      id: moduleCode.id,
    };
  });

  const listofModuleCode = useMemo(() => {
    return [{ label: 'All', value: 'all' }, ...formattedModuleCode];
  }, [formattedModuleCode]);

  const getModuleCode = async () => {
    await TransactionApi.getAllModuleCode()
      .then(res => {
        setModuleCode(res);
      })
      .catch(err => {
        console.error(err);
      });
  };
  useEffect(() => {
    getModuleCode();
  }, []);

  const getAllTranscations = async () => {
    setLoading(true);
    try {
      const results = await TransactionApi.getTransactions({
        orgId: orgId,
        module: moduleFilter === 'all' ? '' : moduleFilter ?? '',
        offset: offset,
      });
      const response = {
        data: results,
      };
      setAllTransaction(results?.data);
      setTotalCount(response?.data?.totalItems);
      setOnloadData(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setOnloadData(true);
      console.error(err);
    }
  };
  useEffect(() => {
    getAllTranscations();
  }, [moduleFilter, offset]);

  const statusFilterOptions = {
    options: listofModuleCode,
    value: moduleFilter,
    changeHandler: ev => {
      setIsInitialRender(false);
      setModuleFilter(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        style={{ marginBottom: 16, marginTop: 16 }}
      >
        <Grid item>
          <PageHeaderTitle>Logs</PageHeaderTitle>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {(!isInitialRender || allTransaction?.length > 0) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                <FilterDropDown
                  {...statusFilterOptions}
                  filterType="Module code:"
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
      {allTransaction?.length > 0 && (
        <Grid item xs={12} style={{ marginBottom: '10px', margin: '20px' }}>
          <LogsGrid apiLoading={loading} rowData={allTransaction} />
          <PaginationComponent
            numberOfRowsPerPage={10}
            count={totalCount}
            onPageClick={paginationClick}
            page={offset}
          />
        </Grid>
      )}
      {allTransaction?.length === 0 && isInitialRender && OnloadData && (
        <NotFoundPage
          title={INITIAL_RENDER_NO_DATA_PAGE.LOGS_TITLE}
          titleContent={INITIAL_RENDER_NO_DATA_PAGE.LOGS_TITLE_CONTENT}
          content={INITIAL_RENDER_NO_DATA_PAGE.LOGS_CONTENT}
        />
      )}{' '}
      {allTransaction?.length === 0 && !isInitialRender && OnloadData && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.LOGS_TITLE}
            content={EMPTY_STATE_PAGE.LOGS_CONTENT}
            showButton={false}
          />
        </div>
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default Logs;
