import React from 'react';
import { Component } from 'react';
import { DataGrid } from 'components/widgets';

class ActionGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Action name',
        field: 'name',
      },
      {
        headerName: 'Parameters',
        field: 'parameters',
      },
      {
        headerName: 'Description',
        field: 'description',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh'}}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default ActionGrid;
