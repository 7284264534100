import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { FlyoutPanel } from 'components/widgets';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    paddingTop: '10px',
  },
  paper: {
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    background: '#f5f5f5',
    width: '100%',
    marginBottom: '19px',
  },
  header: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    wordWrap: 'break-word',
  },
  details: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#344054',
    wordWrap: 'break-word',
  },
  statusDetails: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#7B68EE',
  },
  typo: {
    fontWeight: 600,
    marginLeft: '-10px',
  },
}));

const MemberViewDetail = ({ open, onToggle, CMDEDetailsData }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onClose = () => {
    onToggle();
  };
  return (
    <FlyoutPanel
      title={'Member details'}
      open={open}
      onToggle={onClose}
      isMember={true}
      style={{ backgroundColor: 'gray', height: '90vh' }}
    >
      <Accordion
        style={{ marginTop: '3%', marginBottom: '2%' }}
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} variant="h5">
            Demographics & Coverage
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={2} className={classes.paper}>
            <Grid spacing={3} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.header}>First name</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.firstName || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Last name</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.lastName || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>
                  Subscriber id
                </Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.subscriberId || 'N/A'}{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={3} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.header}>Plan type</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.planType || 'N/A'}{' '}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Start date</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData.startDate
                    ? moment(new Date(CMDEDetailsData.startDate)).format(
                        'MM-DD-YYYY'
                      )
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Group name</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.groupName || 'N/A'}{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={3} className={classes.container}>
              {' '}
              <Grid item xs={4}>
                <Typography className={classes.header}>Group number</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.groupNumber || 'N/A'}{' '}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>
                  Primary care provider
                </Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.primaryCareProvider || 'N/A'}{' '}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>
                  Emergency contact
                </Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.emergencyContact || 'N/A'}{' '}
                </Typography>
              </Grid>{' '}
            </Grid>
            <Grid
              spacing={3}
              className={classes.container}
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={4}>
                <Typography className={classes.header}>Deductible</Typography>
                <Typography
                  className={classes.details}
                  style={{ fontWeight: 900 }}
                  variant="h6"
                >
                  {CMDEDetailsData?.deductible || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Co insurance</Typography>
                <Typography
                  className={classes.details}
                  style={{ fontWeight: 900 }}
                  variant="h6"
                >
                  {CMDEDetailsData?.coInsurance || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Co payment</Typography>
                <Typography
                  className={classes.details}
                  style={{ fontWeight: 900 }}
                  variant="h6"
                >
                  {CMDEDetailsData?.copayment || 'N/A'}{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={3} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.header}>
                  Out of pocket max
                </Typography>
                <Typography
                  className={classes.details}
                  style={{ fontWeight: 900 }}
                  variant="h6"
                >
                  {CMDEDetailsData?.outOfPocketMax || 'N/A'}{' '}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>{' '}
      </Accordion>

      <Accordion style={{ marginTop: '3%', marginBottom: '2%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} variant="h5">
            Dependents
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} className={classes.paper}>
            <Grid spacing={3} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.header}>
                  Dependent name
                </Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.dependentName || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Relation</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.relation || 'N/A'}{' '}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header}>Plan name</Typography>
                <Typography className={classes.details} variant="h6">
                  {CMDEDetailsData?.planName || 'N/A'}{' '}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ marginTop: '3%', marginBottom: '2%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} variant="h5">
            Care programs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} className={classes.paper}>
            <Grid spacing={3} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.header}>
                  Clincal notes
                </Typography>
                <Typography className={classes.details} variant="h6">
                  {/* {CMDEDetailsData?.dependentName || 'N/A'} */}
                  Cancer care
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ marginTop: '3%', marginBottom: '2%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} variant="h5">
            Activities
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} className={classes.paper}>
            <Grid spacing={3} className={classes.container}>
              
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ marginTop: '3%', marginBottom: '2%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} variant="h5">
            Documents
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3} className={classes.paper}>
            <Grid spacing={3} className={classes.container}>
              
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} variant="h5">
            User engagement{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{ backgroundColor: '#FFFFFF' }}
          >
            <Grid spacing={3} className={classes.container}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  3 December 2023
                </Typography>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>01.30 PM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#C70039' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        LOG
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member logged out
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>01.00 PM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        UPDATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has requested for plan change
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>12.00 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        CREATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has raised a support request
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>11.30 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        ADD
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has added a depedented
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>11.15 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <span className={classes.statusDetails}></span>

                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        ASSIGN{' '}
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has been assigned "HRA assesment"
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.45 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        VIEW
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has Fetched the records medication Request
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.30 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        UPDATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has updated the the self report record
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.00 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        UPDATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has updated their Profile name
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>09.45 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        UPDATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has updated their Profile password
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>09.30 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        LOG
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member logged in
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid
              spacing={3}
              className={classes.container}
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  2 December 2023
                </Typography>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.00 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#DC143C' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        LOG
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member logged out
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>09.30 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        LOG
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member logged in
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>

            <Grid
              spacing={3}
              className={classes.container}
              style={{ marginTop: '10px' }}
            >
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  1 December 2023
                </Typography>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>12.00 PM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#DC143C' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        LOG
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member logged out
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.45 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        DELETE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has deactivated their profile
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.30 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        UPDATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has updated their profile{' '}
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>10.00 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        CREATE
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has created the self report record
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>09.45 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7B68EE' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        VIEW
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member has fetched the records medication Request
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.container}>
                  {' '}
                  <Grid item xs={2}>
                    <Typography>09.30 AM</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                        <div
                          className={classes.statusDetails}
                          style={{ backgroundColor: '#7FFFD4' }}
                        ></div>
                      </div>
                      <Typography variant="h6" className={classes.typo}>
                        LOG
                      </Typography>{' '}
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" style={{ color: '#696969' }}>
                      Member logged in
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{ border: '0.5px solid #E4E7EC', marginTop: '10px' }}
                />
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </FlyoutPanel>
  );
};

export default MemberViewDetail;
