import { Component } from 'react';
import { DataGrid } from 'components/widgets';
import ButtonCellRenderer from 'components/widgets/DataGrid/ButtonCellRenderer';
import { PRICELIST_PAGE, LABELS_FIELDS_BUTTONS } from 'shared/constants';

class UsersGrid extends Component {
  state = {
    columnDefs: [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'name',
        headerName: `${LABELS_FIELDS_BUTTONS.LOB_NAME}`,
      },
      {
        field: 'planId',
        headerName: 'Plan id',
      },
      {
        field: 'planType',
        headerName: `${LABELS_FIELDS_BUTTONS.PLAN_TYPE}`,
      },
      {
        field: 'more',
        headerName: 'More',
        cellRenderer: 'ButtonRenderer',
        resizable: false,
        sortable: false,
        filter: false,
      },

      // {
      //   field: 'actions',
      //   headerName: '',
      //   width: 110,
      //   maxWidth: 110,
      //   pinned: 'right',
      //   cellRenderer: 'ActionsRenderer',
      //   resizable: false,
      //   sortable: false,
      //   filter: false,
      // },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: true,
    },
    frameworkComponents: {
      // ActionsRenderer: props => (
      //   <ActionsCellTemplate
      //     {...props}
      //     onEdit={item => this.onItemEdit(item)}
      //     onDelete={item => this.onItemDelete(item)}
      //   />
      // )
      ButtonRenderer: rowData => (
        <ButtonCellRenderer
          {...rowData}
          buttonText={PRICELIST_PAGE.BUTTON_TEXT}
          onClick={item => this.onViewPriceList(item)}
        />
      ),
    },
    context: { componentParent: this },
  };

  onViewPriceList(data) {
    this.props.onViewPriceList(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
            // rowSelection="single"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh' }}
            hideHeaderActions
          />
        </div>
      </div>
    );
  }
}

export default UsersGrid;
