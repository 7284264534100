import { Component, memo } from 'react';
import {
  DataGrid,
  Card,
  SecondaryButton,
  PrimaryButton,
} from 'components/widgets';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import ActionsCellTemplate from 'components/widgets/DataGrid/ActionsCellTemplate';
import RecordClickCellRenderer from 'components/widgets/DataGrid/RecordClickCellRenderer';
import { makeStyles, Box } from '@material-ui/core';
import ActiveIcon from '../../components/icons/ActivityIcon';
import { convertStrToTitleCase, statusColorMap } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
  editbtn: {
    width: '58px',
    height: '24px',
    fontSize: '12px',
  },
}));

const ViewButtonCellRenderer = function ViewButtonCellRenderer({
  data,
  onClick,
  onEdit,
  isPending,
  readAccess,
  editAccess,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  const onEditClickHandler = event => {
    event.preventDefault();
    onEdit(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-evenly',
          marginTop: '12px',
        }}
      >
        <SecondaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
          hasPermissions={readAccess}
        >
          View details
        </SecondaryButton>
        {isPending && (
          <PrimaryButton
            onClick={onEditClickHandler}
            className={classes.editbtn}
            hasPermissions={editAccess}
          >
            Edit
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

class PendingApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      columnDefs: [
        {
          field: 'name',
          headerName: 'Application name',
          cellRenderer: 'AppNameRenderer',
        },
        {
          field: 'sandboxStatus',
          headerName: 'Sandbox',
          cellRenderer: 'SandBoxRender',
        },
        {
          field: 'productionStatus',
          headerName: 'Production',
          cellRenderer: 'StatusRenderer',
        },
        {
          field: 'affiliateName',
          headerName: 'Affiliate name',
          cellRenderer: 'affiliateName',
        },
        {
          field: '',
          cellRenderer: 'DetailsRenderer',
        },
      ],
      rowData: [],
      defaultColDef: {
        flex: 1,
        minWidth: 185,
        resizable: true,
        sortable: true,
        filter: true,
      },
      context: { componentParent: this },
      frameworkComponents: {
        categoryCellRenderer: props => {},
        DateFormatter: rowData => {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        },
        EnvironmentRenderer: rowData => {
          let category = rowData.data.appCategory;
          if (category === null) {
            return '-';
          } else if (category === 'sandbox') {
            return 'Sandbox';
          } else {
            return 'Production';
          }
        },
        ActionsRenderer: actionProps => (
          <ActionsCellTemplate
            {...actionProps}
            onEdit={item => this.onItemEdit(item)}
            onDelete={item => this.onItemDelete(item)}
            allowEdit={props.type === 0 && actionProps.data.isOwner}
            allowDelete={actionProps.data.isOwner}
          />
        ),
        StatusRenderer: rowData => {
          const isMPHAdmin = props.isMPHAdmin;
          const statusProp = isMPHAdmin
            ? rowData?.data?.mphApprovalStatus
            : rowData?.data?.productionStatus;
          return (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {Object.keys(statusColorMap).includes(
                rowData.data.productionStatus
              ) ? (
                <>
                  <span
                    style={{
                      backgroundColor: statusColorMap[statusProp || '-'],
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      padding: 4,
                    }}
                  ></span>
                  <span style={{ marginLeft: 8 }}>
                    {statusProp === 'Denied' ? 'Declined' : statusProp || '-'}
                  </span>
                </>
              ) : (
                <span>
                  {statusProp === 'Denied' ? 'Declined' : statusProp || '-'}
                </span>
              )}
            </Box>
          );
        },
        SandBoxRender: rowData => {
          return (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {Object.keys(statusColorMap).includes(
                rowData.data.sandboxStatus
              ) ? (
                <>
                  <span
                    style={{
                      backgroundColor:
                        statusColorMap[rowData.data.sandboxStatus || '-'],
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      padding: 4,
                    }}
                  ></span>
                  <span style={{ marginLeft: 8 }}>
                    {rowData.data.sandboxStatus || '-'}
                  </span>
                </>
              ) : (
                <span>{rowData.data.sandboxStatus || '-'}</span>
              )}
            </Box>
          );
        },
        affiliateName: rowData => {
          if (rowData.value) {
            return (
              <div>
                <span>{rowData.value}</span>
              </div>
            );
          }
        },
        AppNameRenderer: rowData => {
          if (rowData.value) {
            return (
              <div style={{ display: 'flex' }}>
                <span style={{ margin: '8px 4px 0px 4px' }}>
                  <ActiveIcon />
                </span>
                <span>{rowData.value}</span>
              </div>
            );
          }

          <RecordClickCellRenderer
            {...rowData}
            onClick={item => this.onItemView(item)}
            actionIcon={<ActiveIcon />}
          />;
        },
        DetailsRenderer: rowData => (
          <ViewButtonCellRenderer
            {...rowData}
            onClick={item => this.onItemView(item)}
            onDeleteClick={item => this.onDeleteButton(item)}
            onEdit={item => this.onItemEdit(item)}
            isPending={this.props.isPending}
            readAccess={this.props?.readAccess}
            editAccess={this.props?.editAccess}
          />
        ),
      },
    };
  }

  componentWillUnmount() {
    this._getTemplatesRequest = null;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps?.readAccess !== this.props.readAccess ||
      nextProps?.editAccess !== this.props.editAccess
    ) {
      this.setState({
        ...this.state,
        frameworkComponents: {
          ...this.state.frameworkComponents,
          DetailsRenderer: rowData => (
            <ViewButtonCellRenderer
              {...rowData}
              onClick={item => this.onItemView(item)}
              onDeleteClick={item => this.onDeleteButton(item)}
              onEdit={item => this.onItemEdit(item)}
              isPending={this.props.isPending}
              readAccess={nextProps?.readAccess}
              editAccess={nextProps?.editAccess}
            />
          ),
        },
      });
    }
  }

  navigateTo = url => {
    this.props.history.push(url);
  };

  onItemEdit = data => {
    this.props.onEdit(data);
  };

  onItemDelete = data => {
    this.props.onDelete(data);
  };

  onItemView = data => {
    this.props.onView(data);
  };

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;

    const { loading, data } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 300,
        }}
      >
        {loading ? (
          <Card
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              height: '72vh',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Card>
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <DataGrid
              hideHeaderActions
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={data}
              frameworkComponents={frameworkComponents}
              context={this.state.context}
              style={{ height: 'calc(100vh - 280px)', minHeight: 500 }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PendingApplications;
