import { makeStyles, CircularProgress, ButtonBase } from '@material-ui/core';
import { Card as CustomCard } from 'components/widgets';
import PendingActionIcon from 'components/icons/PendingActionIcon';
import CheckCircleIcon from 'components/icons/CheckCircleIcon';
import CloseCircleIcon from 'components/icons/CloseCircleIcon';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  statusCard: {
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  statusText: {
    opacity: 0.87,
    fontSize: 12,
    color: '#626262',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
  },
  approved: {
    color: '#219653',
    fontSize: 32,
  },
  denied: {
    color: '#DB0032',
    fontSize: 32,
  },
  pending: {
    color: '#F2994A',
    fontSize: 32,
  },
}));

const StatusIconMap = {
  pending: <PendingActionIcon style={{ color: '#F2994A', fontSize: 32 }} />,
  approved: <CheckCircleIcon style={{ color: '#219653', fontSize: 32 }} />,
  denied: <CloseCircleIcon style={{ color: '#DB0032', fontSize: 32 }} />,
};

function StatusCard({ loading, count, statusText, status, onClick }) {
  const classes = useStyles();

  return (
    <CustomCard className={classes.statusCard}>
      <ButtonBase disabled={loading} onClick={onClick}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <div
            style={{
              display: 'flex',

              alignItems: 'center',
            }}>
            {StatusIconMap[status]}
            <span style={{ fontSize: 28, paddingLeft: 10 }}>
              {loading ? (
                <CircularProgress size={18} color="secondary" />
              ) : (
                count
              )}
            </span>
          </div>
          <div className={classes.statusText}>
            <span>{statusText}</span>
            <KeyboardArrowRight fontSize="small" />
          </div>
        </div>
      </ButtonBase>
    </CustomCard>
  );
}

export default StatusCard;
