import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';


const IconPaper = styled(Paper)({
    bgcolor: '#FFF',
    height: '120px',
    width: '200px',
    borderRadius: '12px',
    padding: '8px',
    position: 'relative',
    border: 'none',
  })

const IconBox = styled(Box)({
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: '8px',
    paddingTop: '8px',
    flexDirection: 'column',
  });

const ImageBox = styled(Box)({
    height: 40,
    width: 40,
  });

const CustomTypography = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    marginTop: '12px',
  });


export const IconContainer = ({
  route,
  imgUrl,
  caption,
}) => {
  const history = useHistory();

  const navigateToPage = () => {
      history.push(`${window.location.pathname}${route}`);
  };

  return (
    <Box
      onClick={() => navigateToPage()}
      style={{ textDecoration: 'none', cursor: 'pointer' }}
    >
      <IconPaper
      >
        <Box style={{ position: 'absolute', top: 12, right: 12 }}>
          <KeyboardArrowRight />
        </Box>
        <IconBox
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImageBox
              component="img"
              src={imgUrl}
              alt={caption}
            />
          </Box>
          <Box style={{ mt: { sm: 1, xs: 0.5 } }}>
            <CustomTypography
            >
              {caption}
            </CustomTypography>
          </Box>
        </IconBox>
      </IconPaper>
    </Box>
  );
};
