import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import { STORAGE_KEYS } from './shared/constants';
import SessionService from './services/SessionService';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import AuthApiService from 'services/api/AuthApiService'

const eventLogger = (event, error) => {
  if(event === 'onAuthRefreshError'){
    AuthApiService.logout()
    keycloak.logout()
    localStorage.clear()
  }
};

const tokenLogger = tokens => {
  // console.log('onKeycloakTokens', tokens);
};

let authInfo = SessionService.getItem(STORAGE_KEYS.AUTH_INFO);

authInfo = authInfo ? JSON.parse(authInfo) : authInfo;

// const userInfo = SessionService.getItem(STORAGE_KEYS.USER_INFO);
// const userInfoObj = userInfo ? JSON.parse(userInfo) : userInfo;

const initialState = {
  auth: {
    authInfo: authInfo,
  },
};

const mpoweredApp = (
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}>
    <Root initialState={initialState}>
      <App />
    </Root>
  </ReactKeycloakProvider>
);

ReactDOM.render(
  // <React.StrictMode>
  mpoweredApp,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
