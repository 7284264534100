import React, { useEffect, useState } from 'react';
import { AssessmentModule } from '@premkumarmph/assessment-library';
import { useSelector } from 'react-redux';
import { PERMISSION_CODES } from 'shared/constants';

const AllAssessments = props => {
  const redirectURL = window.location.pathname;
  const orgId = sessionStorage.getItem('orgId');
  const CampaignURL = `/organization/${orgId}/campaigns`;
  const [token, setToken] = useState(
    localStorage.getItem('MPOWERED_AUTH_TOKEN')
  );
  const [refreshFlag, setRefreshFlag] = useState(false);

  const { solutionPermissions } = useSelector(state => state.userInfo);
  const [assessmentPermissions, setAssessmentPermissions] = useState();

  useEffect(() => {
    const selectedSolution = solutionPermissions?.get(
      localStorage.getItem('selectedSolutionId')
    );

    const selectedAssessment = selectedSolution?.features?.find(
      feature => feature?.featureName === 'Assessments'
    )?.subFeatures;

    const tempAssessmentPermission = new Map();

    selectedAssessment?.forEach(subFeature => {
      const subFeaturePermission = {
        READ: false,
        CREATE: false,
        DELETE: false,
        APPROVE: false,
      };
      const availablePermissionsMap = new Set(subFeature.selectedPermissions);
      Object.keys(PERMISSION_CODES).forEach(permissionKey => {
        if (availablePermissionsMap.has(PERMISSION_CODES[permissionKey])) {
          subFeaturePermission[permissionKey] = true;
        }
      });

      tempAssessmentPermission.set(
        subFeature.subFeatureName,
        subFeaturePermission
      );
    });

    const selectedCampaign = selectedSolution?.features
      ?.find(feature => feature?.featureName === 'Campaigns')
      ?.subFeatures?.find(
        subFeature => subFeature?.subFeatureName === 'Campaigns'
      )?.selectedPermissions;

    const tempCampaignPermissions = {
      READ: selectedCampaign?.includes(PERMISSION_CODES.READ) ?? false,
      CREATE: selectedCampaign?.includes(PERMISSION_CODES.CREATE) ?? false,
    };

    tempAssessmentPermission.set('Campaigns', tempCampaignPermissions);
    setAssessmentPermissions(tempAssessmentPermission || []);
  }, [solutionPermissions]);

  useEffect(() => {
    setToken(localStorage.getItem('MPOWERED_AUTH_TOKEN'));
  }, [
    JSON.stringify(localStorage.getItem('MPOWERED_AUTH_TOKEN')),
    refreshFlag,
  ]);

  return (
    // <AssessmentListingPage />
    <AssessmentModule
      token={token}
      orgId={orgId}
      redirectUrl={redirectURL}
      CampaignURL={CampaignURL}
      accessModule={'assessmentListing'}
      assessmentPermissions={assessmentPermissions}
      // solutionId={window.localStorage.getItem('selectedSolutionId')}
      setRefreshFlag={setRefreshFlag}
    />
  );
};

export default AllAssessments;
