import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
  anchorButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    paddingLeft: 5,
    paddingRight: 5,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const NotFoundView = ({ history }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center">
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Box textAlign="center">
            <Typography
              align="center"
              color="textPrimary"
              variant="subtitle2"
              component="div"
              className={classes.anchorButton}
              onClick={() => history.push('/')}>
              Click here to go to home page
            </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/not_found.png"
            />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
