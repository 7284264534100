import React from 'react';
import { Field } from 'react-final-form';
import { required, phoneNumber, composeValidators } from 'shared/validators';
import { TextField } from 'components/widgets';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function MaskedTextField(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+1 (###) ###-####"
      mask="_"
    />
  );
}

MaskedTextField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormPhoneField({
  name,
  label,
  value,
  disabled = false,
}) {
  return (
    <Field name={name} validate={composeValidators(required, phoneNumber)}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          required
          label={label}
          value={value}
          {...input}
          touched={touched}
          error={error}
          disabled={disabled}
          InputProps={{
            inputComponent: MaskedTextField,
          }}
        ></TextField>
      )}
    </Field>
  );
}
