import {
  makeStyles,
  Divider,
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Page from 'components/Page';
import faker from 'faker';
import dateFormat from 'dateformat';
import { DataGrid, PageHeaderTitle } from 'components/widgets';
import TopAPIs from 'containers/APIOverview/TopAPIs';
import ResponseCodes from 'containers/APIOverview/ResponseCodes';
import Insights from 'containers/APIOverview/Insights';
import { useParams } from 'react-router-dom';
import DataPartnersApi from 'services/api/DataPartnersApi';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: theme.spacing(2),
  },
  apiCard: {
    marginBottom: theme.spacing(2),
    minHeight: 160,
  },
}));

const APIOverviewPage = ({ title, history }) => {
  const classes = useStyles();
  const [affiliates, setAffiliates] = useState(null);
  const [developerAffiliateId, setDeveloperAffiliateId] = useState(null);

  const handleChange = event => {
    setDeveloperAffiliateId(event.target.value);
  };

  const orgId = sessionStorage.getItem('orgId');
  const affiliateId = sessionStorage.getItem('affiliateId') ?? 'DEV';

  const colDefs = [
    {
      field: 'name',
    },
    {
      field: 'callsConsumed',
    },
    {
      field: 'endpointsUsed',
    },
    {
      field: 'status',
    },
    {
      field: 'testDate',
    },
  ];

  const getAllAffiliates = useCallback(() => {
    async function getAffiliates() {
      try {
        const results = await DataPartnersApi.getAllPartners(orgId);
        const response = results.filter(item => item.isDeleted !== 1);
        const data = response.map(fItem => ({
          id: fItem.id,
          name: fItem.name,
        }));
        setAffiliates(data);
        setDeveloperAffiliateId(data[0].id);
      } catch (error) {
        console.log(error);
      }
    }
    getAffiliates();
  }, [orgId]);

  useEffect(() => {
    if (orgId && affiliateId === 'DEV') {
      getAllAffiliates();
    }
  }, [orgId, affiliateId, getAllAffiliates]);

  const getRandomRowData = () => {
    let array = Array.apply(null, { length: 15 }).map(() => {
      return {
        name: faker.name.findName(),
        callsConsumed: faker.datatype.number(1000),
        endpointsUsed: faker.datatype.number(2000),
        status: faker.datatype.boolean() ? 'Available' : 'Offline',
        testDate: dateFormat(faker.date.past(1), 'mmm dd, yyyy'),
      };
    });
    return array.sort(function (a, b) {
      return new Date(a.testDate) - new Date(b.testDate);
    });
  };
  const [rowData] = useState(getRandomRowData);

  // const onGridReady = params => {};

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
  };

  return (
    <Page className={classes.root} title={title}>
      <PageHeaderTitle>Insights</PageHeaderTitle>
      <div className={classes.content}>
        <Grid container spacing={1}>
          {affiliateId === 'DEV' && developerAffiliateId !== null && (
            <Grid item xs={12}>
              <Box sx={{ marginLeft: '20px' }}>
                <Typography variant="h6" style={{ marginBottom: '5px' }}>
                  Select an affiliate
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ backgroundColor: '#FFF' }}
                >
                  <Select
                    style={{ backgroundColor: '#FFF' }}
                    value={developerAffiliateId}
                    onChange={handleChange}
                  >
                    {affiliates.map((item, index) => (
                      <MenuItem value={item.id} index={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} lg={5}>
            <TopAPIs
              history={history}
              orgId={orgId}
              affiliateId={
                affiliateId === 'DEV' ? developerAffiliateId : affiliateId
              }
              devFlag={affiliateId === 'DEV'}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <ResponseCodes
              history={history}
              orgId={orgId}
              affiliateId={
                affiliateId === 'DEV' ? developerAffiliateId : affiliateId
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Insights
              history={history}
              orgId={orgId}
              affiliateId={
                affiliateId === 'DEV' ? developerAffiliateId : affiliateId
              }
            />
          </Grid>
        </Grid>

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h4" component="h2">
              APIs used
            </Typography>
            <Typography gutterBottom variant="caption" component="h2">
              Showing 50 APIs
            </Typography>
            <Divider />
            <br />
            <DataGrid
              defaultColDef={gridOptions.defaultColDef}
              columnDefs={colDefs}
              rowData={rowData}
              // style={{ minHeight: '50vh' }}
              hideHeaderActions
            />
          </CardContent>
        </Card>
      </div>
    </Page>
  );
};

export default APIOverviewPage;
