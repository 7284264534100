import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import clsx from 'classnames';
// import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {},
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function FabButton({
  onClick,
  children,
  className,
  ...otherProps
}) {
  const classes = useStyles();

  return (
    <Fab
      color="primary"
      size="small"
      variant="extended"
      onClick={onClick}
      className={clsx(classes.root, className)}
      {...otherProps}>
      {children}
    </Fab>
  );
}
