// import axiosProfInstance from './axiosProfInstance';
// import LogService from '../LogService';
import axiosInstance from './axiosInstance';
import SessionService from '../SessionService';
// import DispatcherService from '../DispatcherService';
import { STORAGE_KEYS } from 'shared/constants';
// import TimerService from '../TimerService';
// import * as actions from '../../store/actions';
import { apiPath } from 'config/apiConfig';

const get = async (url, params, useBaseApiPath, otherConfig, noToken) => {
  return _makeRequest('GET', url, params, null, useBaseApiPath, otherConfig, {}, noToken);
};
const post = async (
  url,
  params,
  data,
  useBaseApiPath,
  otherConfig,
  otherHeaders,
  noToken
) => {
  return _makeRequest(
    'POST',
    url,
    params,
    data,
    useBaseApiPath,
    otherConfig,
    otherHeaders,
    noToken
  );
};

const put = async (url, params, data, useBaseApiPath, otherConfig) => {
  return _makeRequest('PUT', url, params, data, useBaseApiPath, otherConfig);
};

const remove = async (url, params, data, useBaseApiPath, noToken) => {
  return _makeRequest('DELETE', url, params, data, useBaseApiPath, {}, {}, noToken);
};

const _makeRequest = async (
  type,
  url,
  params,
  data,
  useBaseApiPath = true,
  otherConfig = {},
  otherHeaders = {},
  noToken = false,
) => {
  const requestType = type ? type.toUpperCase() : 'GET';

  const extraConfig = otherConfig ? otherConfig : {};

  const apiUrl = useBaseApiPath ? apiPath + url : url;

  let config = {
    url: apiUrl,
    params,
    method: requestType,
    ...extraConfig,
  };

  if (requestType !== 'GET') {
    config.data = data;
  }

  let requestHeaders = {};

  const token = SessionService.getItem(STORAGE_KEYS.AUTH_TOKEN);
  const solutionId = localStorage.getItem('manageOrgId') || localStorage.getItem('selectedSolutionId');  

  if (token && !noToken) {
    requestHeaders.authorization = `Bearer ${token}`;
  }

  if(solutionId) {
    requestHeaders.solutionId = solutionId;
  }

  config['headers'] = {
    ...requestHeaders,
    ...otherHeaders,
  };

  //   const displayableRequest =
  //     config.method + ': ' + (config && config.url ? config.url : url) + ' ';
  //   const isDevModeOn = isDevMode();

  //   const timerName = 'API-' + displayableRequest;
  //   if (isDevModeOn) {
  //     TimerService.setMarkStart(timerName);
  //   }

  return new Promise((resolve, reject) => {
    axiosInstance(config)
      .then(response => {
        if (config.responseType === 'blob') resolve(response);
        else resolve(response.data);

        // if (isDevModeOn) {
        //   TimerService.setMarkEnd(timerName);

        //   logRequestInDevMode(
        //     displayableRequest,
        //     config.headers,
        //     params,
        //     data,
        //     response.data
        //   );
        // }
      })
      .catch(error => {
        if (error && error.response) {
          const status = error.response.status;
          const errCode =
            error.response.data && error.response.data.error
              ? error.response.data.error.code
              : '';

          if (status === 404 && errCode === 'BE-304') {
            // const navigation = SessionService.getNavigationInstance();
            // if (navigation && navigation.navigate) {
            //   _signOutUser(navigation);
            // }

            _signOutUser();
          }
        }

        // if (isDevModeOn) {
        //   TimerService.setMarkEnd(timerName);
        // }

        reject(error);
      });
  });
};

const _signOutUser = () => {
  //   SessionService.clear();
  //   DispatcherService.dispatchAction(actions.invalidateSession());
};

// function logRequestInDevMode(
//   displayableRequest,
//   headers,
//   params,
//   body,
//   responseData
// ) {
//   LogService.groupCollapsed(
//     'REST Service: ' + displayableRequest,
//     'color: blue;'
//   );

//   // Request
//   LogService.group('REQUEST:');
//   LogService.logDev('HEADER: ' + JSON.stringify(headers));
//   if (params && !isObjectEmpty(params)) {
//     LogService.logDev('QUERY PARAMS: ' + JSON.stringify(params));
//   }
//   if (body) {
//     LogService.logDev('BODY:');
//     LogService.dir(body);
//     LogService.logDev(JSON.stringify(body));
//   }
//   LogService.groupEnd();

//   // Response
//   LogService.group('RESPONSE:');
//   LogService.dir(responseData);
//   LogService.logDev(JSON.stringify(responseData));
//   LogService.groupEnd();

//   LogService.groupEnd();
// }

const BaseApiService = {
  get,
  post,
  put,
  delete: remove,
};

export default BaseApiService;
