import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dropDownWrapper: {
    minWidth: 160,
    height: '40px',
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    paddingRight: '10px',
    color: '#4B4B4B',
    borderRadius: '8px',
    backgroundColor: '#fff',
    flexDirections: 'row',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
    marginTop: '8px',
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '40px',
    paddingLeft: '12px',

    flexDirection: 'row',

    fontWeight: 600,
    minWidth: '64px',
  },
  label: {
    height: '24px',
    padding: '0px',
    paddingLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  listTag: { flex: 1, height: 40, paddingLeft: '6px', paddingRight: '8px' },
  filterTypes: {
    lineHeight: '0px',
    paddingLeft: '8px',
    minWidth: 'max-content',
    fontSize: '14px',
    fontWeight: 600,
    color: '#737373',
  },
  img: {
    width: '8px',
    height: '8px',
  },
}));

const FilterDropDown = ({
  changeHandler,
  rootContainerStyle,
  filterType,
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.value);
  const onChange = val => {
    setValue(val);
    changeHandler(val);
  };

  return (
    <FormControl className={classes.dropDownWrapper}>
      {props.inputLabel && (
        <InputLabel id="inputLabel">{props.inputLabel}</InputLabel>
      )}

      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.filterTypes}>
          {filterType || ''}
        </Typography>

        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            MenuListProps: {
              disablePadding: true,
            },
            getContentAnchorEl: null,
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue="10"
          disableUnderline
          value={value}
          onChange={onChange}
          {...props}>
          {props?.options?.map((option, index) => {
            return (
              <MenuItem
                key={index}
                value={option.value}
                className={classes.listTag}>
                <Box className={classes.labelWrapper}>
                  {option.icon && (
                    <Box style={{ marginRight: '8px' }}>
                      <img
                        src={option.icon}
                        alt="icon"
                        className={classes.img}
                      />
                    </Box>
                  )}
                  <Box className={classes.label}>
                    {option.label || option.value}
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
};

export default FilterDropDown;
