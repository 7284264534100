import BaseApiService from './BaseApiService';

const _getMenuItems = (orgId) => {
  return BaseApiService.get(
    `/menu/${orgId}`
  );
};

const _getConfigurations = (orgDnsName) => {
    return BaseApiService.get(
        `/unauth/configuration/dev-signup/${orgDnsName}`,
        null,
    )
}

const _developerSignup = (orgDnsName, data) => {
  return BaseApiService.post(`/unauth/signup/${orgDnsName}/user`, null, data);
}

const _newDevSignup = (payload) => {
  return BaseApiService.post(`/unauth/signup/v2/user`, null, payload);
}

const _partnerConfiguration = (partnerName) => {
  return BaseApiService.get(`/unauth/configuration/${partnerName}`)
}

const PartnerApi = {
    getMenuItems : _getMenuItems,
    getConfigurations : _getConfigurations,
    developerSignup : _developerSignup,
    newDevSignup : _newDevSignup,
    partnerConfiguration : _partnerConfiguration
}

export default PartnerApi;