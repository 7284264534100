import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useParams, useLocation } from 'react-router-dom';
import { AssessmentModule } from '@premkumarmph/assessment-library';
import { useSelector } from 'react-redux';
import { PERMISSION_CODES } from 'shared/constants';

export const AssessmentLibrary = () => {
  const redirectURL = window.location.pathname;
  const orgId = sessionStorage.getItem('orgId');
  const CampaignURL = `/organization/${orgId}/campaigns`;

  const { solutionPermissions } = useSelector(state => state.userInfo);
  const [assessmentPermissions, setAssessmentPermissions] = useState();
  const [token, setToken] = useState(
    localStorage.getItem('MPOWERED_AUTH_TOKEN')
  );
  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    const selectedSolution = solutionPermissions?.get(
      localStorage.getItem('selectedSolutionId')
    );

    const selectedAssessment = selectedSolution?.features?.find(
      feature => feature?.featureName === 'Assessments'
    )?.subFeatures;

    const tempAssessmentPermission = new Map();

    selectedAssessment?.forEach(subFeature => {
      const subFeaturePermission = {
        READ: false,
        CREATE: false,
        DELETE: false,
        APPROVE: false,
      };
      const availablePermissionsMap = new Set(subFeature.selectedPermissions);
      Object.keys(PERMISSION_CODES).forEach(permissionKey => {
        if (availablePermissionsMap.has(PERMISSION_CODES[permissionKey])) {
          subFeaturePermission[permissionKey] = true;
        }
      });

      tempAssessmentPermission.set(
        subFeature.subFeatureName,
        subFeaturePermission
      );
    });

    setAssessmentPermissions(tempAssessmentPermission || []);
  }, [solutionPermissions]);

  useEffect(() => {
    setToken(localStorage.getItem('MPOWERED_AUTH_TOKEN'));
  }, [
    JSON.stringify(localStorage.getItem('MPOWERED_AUTH_TOKEN')),
    refreshFlag,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: { xs: '100%', sm: '100%' },
      }}
    >
      <AssessmentModule
        token={token}
        orgId={orgId}
        redirectUrl={redirectURL}
        CampaignURL={CampaignURL}
        accessModule={'questionBank'}
        assessmentPermissions={assessmentPermissions}
        // solutionId={window.localStorage.getItem('selectedSolutionId')}
        setRefreshFlag={setRefreshFlag}
      />
    </Box>
  );
};
