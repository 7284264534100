import {Button as ButtonMui } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

const baseBlue = {
  color: "#ffffff",
  backgroundColor: '#036FCB',
  hoverColor: '#389DEF',
  lineHeight: '24px',
  '&:hover': {
    backgroundColor: '#389DEF',
  },
};

const variantStyles = {
  wideBlue: {
    ...baseBlue,
    padding: '8px 24px',
  },
  modalSubmit: {
    ...baseBlue,
    height: 44,
    padding: '0 32px',
    letterSpacing: 'normal',
  },
  borderedWhite: {
    letterSpacing: 'normal',
    color: '#036FCB',
    backgroundColor: '#fff',
    border: '1px solid #A0A4A6',
    minWidth: 120,
    height: 40,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#036FCB',
      color: '#fff',
    },
  },
};

const Button = styled(ButtonMui)((props) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: 16,
  borderRadius: 40,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  letterSpacing: 0,
  ...variantStyles[props.variant],
}));

Button.propTypes = {
  variant: PropTypes.oneOf(['wideBlue', 'borderedWhite']),
};

Button.defaultProps = {
  variant: 'borderedWhite',
};

export default Button;
