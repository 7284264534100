import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const LineGraph = ({ data = {} }) => {
  const [lineData, setLineData] = useState({});

  useEffect(() => {
    setLineData(data);
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={lineData} />;
};

export default LineGraph;
