import { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 32,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      fill: '#A9A9A9',
    },
  },
}));

const IconCellRenderer = memo(function IconCellRenderer({
  data,
  onClick,
  icon: Icon,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return <Icon className={classes.icon} onClick={onClickHandler} />;
});

export default IconCellRenderer;
