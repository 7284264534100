import { Component, memo } from 'react';
import { DataGrid, SecondaryButton } from 'components/widgets';
import moment from 'moment';
import ConfigurationsActionCellTemplate from './ConfigurationsActionCellTemplate';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
    marginRight: '5px',
  },
  editbtn: {
    width: '58px',
    height: '24px',
    fontSize: '12px',
  },
}));

const ViewButtonCellRenderer = ({
  data,
  hasDeletePermission,
  hasEditPermission,
  onDelete,
  onEdit,
}) => {
  const classes = useStyles();

  const onEditClickHandler = event => {
    event.preventDefault();
    onEdit(data);
  };

  const onDeleteClickHandler = event => {
    event.preventDefault();
    onDelete(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-between',
          marginTop: '12px',
        }}
      >
        <SecondaryButton
          onClick={onEditClickHandler}
          className={classes.editbtn}
          hasPermissions={hasEditPermission}
        >
          Edit
        </SecondaryButton>
        <IconButton
          style={{ height: '20px', width: '20px' }}
          onClick={onDeleteClickHandler}
        >
          <DeleteIcon color="action" />
        </IconButton>
      </div>
    </>
  );
};

class ImagesGrid extends Component {
  state = {
    columnDefs: [
      {
        field: 'title',
        headerName: 'Title',
        width: '350',
      },
      {
        field: 'imageUrl',
        headerName: 'Category',
        cellRenderer: 'CategoryRenderer',
        maxWidth: 200,
      },
      {
        field: 'imageUrl',
        headerName: 'Uploaded Image',
        cellRenderer: 'ImageRenderer',
        width: '450',
      },
      {
        field: 'webRoute',
        headerName: 'Redirect URL',
        cellRenderer: 'RedirectRenderer',
        width: '350',
      },
      {
        field: 'actions',
        headerName: '',
        width: 100,
        maxWidth: 150,
        cellRenderer: 'DetailsRenderer',
        resizable: false,
        sortable: false,
        filter: false,
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 180,
      resizable: true,
      sortable: true,
      filter: false,
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        let formattedDate = moment(rowData.value).format('MMM DD YYYY hh:mm A');
        return formattedDate;
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onEdit={this.props.onEdit}
          onDelete={this.props.onDelete}
          hasEditPermission={this.props.hasEditPermission}
          hasDeletePermission={this.props.hasDeletePermission}
        />
      ),
      TypeRenderer: rowData => {
        return !rowData.value ? '' : rowData.value.split('/')[1];
      },
      CategoryRenderer: rowData => {
        return rowData?.value ? 'Home Page Banner' : 'Login-page banner';
      },
      ImageRenderer: rowData =>
        rowData?.value ? (
          <img
            src={rowData.value}
            alt={'uploaded img'}
            style={{ minHeight: '100%', width: '100%' }}
            className={this.props.imgStyles}
          />
        ) : (
          '-'
        ),
      RedirectRenderer: rowData => {
        return rowData?.value || '-';
      },
    },
    context: { componentParent: this },
  };

  onItemDownload(data) {
    const URL = data.imageUrl;
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            // rowSelection="single"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh' }}
            hideHeaderActions
          />
        </div>
      </div>
    );
  }
}

export default ImagesGrid;
