/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { PageHeaderTitle, SimpleBackdrop } from 'components/widgets';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button } from '@material-ui/core';
import CarePlanGrid from './CarePlanGrid';
// import CreateCarePlanPopUp from './CreateCarePlanPopUp';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import DemoApis from 'services/api/DemoApis';
// import CarePlanForm from './CarePlanForm';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    //  padding:16
  },
  addButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: '40px',
    color: '#fff',
    backgroundColor: '#036FCB',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px',
    '&:hover': {
        backgroundColor: '#508ae6',
      },
  },
  carePlanContainer: {
    backgroundColor: '#F5F5F5',
    position: 'relative',
    marginLeft: 32,
    padding: 16,
  },
}));

const CarePlan = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [carePlans, setCarePlans] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  // const [open, setOpen] = useState(false);
  const orgId = sessionStorage.getItem("orgId");
  // const history = useHistory();

  const getCarePlans = async () => {
    setLoading(true);
    try {
      const response = await DemoApis.getAllCarePlans({ orgId: orgId,offset: offset});
      setCarePlans(response);
      setTotalCount(response.length);
    } catch (error) {
      console.error('Error fetching care plans:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCarePlans();
  }, [offset]);

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  // const handleAddCarePlanClick = () => {
  //   setOpen(true);
  //   history.push(`/organization/${orgId}/createCarePlan`)
  // };

  // const handleClosePopUp = () => {
  //   setOpen(false);
  // };

  return (
    <Box sx={{ width: '95%' }} className={classes.carePlanContainer}>
      <Box style={{ marginLeft: '-16px' }}>
        <PageHeaderTitle>CarePlans</PageHeaderTitle>
      </Box>
      {/* <Button className={classes.addButton} onClick={handleAddCarePlanClick}>
        Add Care Plan
      </Button> */}
      <Grid container className={classes.container}>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <CarePlanGrid
            apiLoading={loading}
            rowData={carePlans}
          />
          <PaginationComponent
            numberOfRowsPerPage={10}
            count={totalCount}
            onPageClick={paginationClick}
            page={offset} 
          />
        </Grid>
      </Grid>
      <SimpleBackdrop open={loading} />
    </Box>
  );
};

export default CarePlan;
