import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  BackendSearchTextField,
  PageHeaderTitle,
  PrimaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { makeStyles } from '@material-ui/core/styles';
import MembersDataGrid from './MembersDataGrid';
import { useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import MembersFilter from '../../components/MembersFilter';
import MemberChip from 'components/MembersFilter/MemberChip';
import DataPartnersApi from 'services/api/DataPartnersApi';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import { useSnackbar } from 'notistack';
import { formattedDate, singleMemberDownload } from 'shared/utility';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import ConsentTrigger from './ConsentForm/ConsentTrigger';
import ConsentInfoModal from './ConsentInfoModal';
import { PERMISSION_CODES } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    borderRadius: '40px',
    color: '#036FCB',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
    marginRight: '12px',
  },
}));

const MembersPage = ({ title, history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [name, setName] = useState('');
  const [affiliateIds, setAffiliateIds] = useState([]);
  const [lobIds, setLobIds] = useState([]);
  const [consentArray, setConsentArray] = useState([]);
  const [expiryDate, setExpiryDate] = useState('');
  const [affiliates, setAffiliates] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fetchMemberData, setFetchMemberData] = useState([]);
  const [showChip, setShowChip] = useState(false);
  const [showConsentInfoModal, setShowConsentInfoModal] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [confirmationCount, setconfirmationCount] = useState('');
  const [checkForFilter, setCheckForFilter] = useState(false);
  const [allAffiliates, setAllAffiliates] = useState([]);

  const [membersPermissions, setMembersPermissions] = useState(new Map());
  const { solutionPermissions } = useSelector(state => state.userInfo);

  const dispatch = useDispatch();

  const [allFiltersValues, setAllFiltersValues] = React.useState({
    affiliateStatus: [],
    consentStatus: [],
    expireOn: [],
    lobStatus: [],
  });

  const { affiliateStatus, consentStatus, expireOn, lobStatus } =
    allFiltersValues;

  const consentIsChecked = consentStatus?.filter((data, i) => data.isChecked);

  const consentStatusFilterValues = consentIsChecked?.map(
    (item, i) => item.value
  );

  const affiliateIsChecked = affiliateStatus?.filter(
    (data, i) => data.isChecked
  );

  const affiliateFilterValues = affiliateIsChecked?.map(
    (item, i) => item.value
  );

  const lobIsChecked = lobStatus?.filter((data, i) => data.isChecked);
  const selectedAll = useRef(false);
  const lobFilterValues = lobIsChecked?.map((item, i) => item.value);

  const expireOnIsChecked = expireOn?.filter((data, i) => data.isChecked);

  const expireOnFilterValues = expireOnIsChecked?.map((item, i) => item.value);

  const consentValues = consentStatus
    ?.filter(data => data.isChecked)
    .map(item => item.value);
  const affiliateValues = affiliateStatus
    ?.filter(data => data.isChecked)
    .map(item => item.key);
  const lobsValues = lobStatus
    ?.filter(data => data.isChecked)
    .map(item => item.key);
  const expiresOnValue = expireOn?.filter(data => data.isChecked)[0]?.value;

  const handleSelectedData = data => {
    const { type, values } = data;

    if (type === 'affiliateStatus') {
      setAllFiltersValues(prev => ({
        ...prev,
        affiliateStatus: [...values],
      }));
    } else if (type === 'consentStatus') {
      setAllFiltersValues(prev => ({
        ...prev,
        consentStatus: [...values],
      }));
    } else if (type === 'expireOn') {
      setAllFiltersValues(prev => ({
        ...prev,
        expireOn: [...values],
      }));
    } else if (type === 'lobStatus') {
      setAllFiltersValues(prev => ({
        ...prev,
        lobStatus: [...values],
      }));
    }
  };

  const onRowSelected = (event, nextPageSelected, type) => {
    if (nextPageSelected) return;
    if (type === 'DESELECT_ALL') {
      setSelectedRows([]);
      selectedAll.current = false;
      return;
    }
    if (event.node?.selected) {
      setSelectedRows(prevSelectedNodes => [
        ...prevSelectedNodes,
        event.node.data,
      ]);
    } else if (type === 'SELECT_ALL') {
      selectedAll.current = true;
      setSelectedRows(event);
    } else {
      selectedAll.current = false;
      setSelectedRows(prevSelectedNodes =>
        prevSelectedNodes.filter(
          node => node.patientId !== event.data.patientId
        )
      );
    }
  };

  const affiliateId = sessionStorage.getItem('affiliateId');
  const sessionAffiliates = sessionStorage.getItem('allAffiliates');
  const orgId = sessionStorage.getItem('orgId');

  useEffect(() => {
    if(sessionAffiliates) {
      setAllAffiliates(sessionAffiliates.split(',').map(item => Number(item)))
    }
  }, [sessionAffiliates])

  useEffect(() => {
    const selectedSolution = solutionPermissions?.get(
      localStorage.getItem('selectedSolutionId')
    );

    const selectedAssessment = selectedSolution?.features?.find(
      feature => feature?.featureName === 'CMDE'
    )?.subFeatures;

    const tempMembersPermissions = new Map();

    selectedAssessment?.forEach(subFeature => {
      const subFeaturePermission = {
        READ: false,
        CREATE: false,
        DELETE: false,
        APPROVE: false,
      };
      const availablePermissionsMap = new Set(subFeature.selectedPermissions);
      Object.keys(PERMISSION_CODES).forEach(permissionKey => {
        if (availablePermissionsMap.has(PERMISSION_CODES[permissionKey])) {
          subFeaturePermission[permissionKey] = true;
        }
      });

      tempMembersPermissions.set(
        subFeature.subFeatureName,
        subFeaturePermission
      );
    });

    setMembersPermissions(tempMembersPermissions || []);
  }, [solutionPermissions]);

  const getAllPartners = useCallback(
    async _orgId => {
      try {
        const results = await DataPartnersApi.getAllPartners(_orgId);
        const data = results
          .filter(item => item.isDeleted !== 1)
          .map(fItem => ({
            key: fItem.id,
            value: fItem.name,
            isChecked: false,
          }));
        let filteredList = [];
        for (let x of data) {
          if(allAffiliates.includes(x.key)){
            filteredList.push(x)
          }
        }
        setAffiliates(filteredList);
      } catch (error) {
        enqueueSnackbar('Something went wrong fetching affiliates', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, allAffiliates]
  );

  const getAllLobs = useCallback(
    async _orgId => {
      try {
        const results = await PartnerLOBApi.getAll(_orgId);
        const data = results.map(item => ({
          key: item.id,
          value: item.name,
          isChecked: false,
        }));
        setLobs(data);
      } catch (error) {
        enqueueSnackbar('Something went wrong fetching all LOBs', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const getAllMembersData = useCallback(
    async orgId => {
      setLoading(true);
      const reqBody = {
        statusList: consentArray,
        name: name,
        orgId: Number(orgId),
        affiliateId: affiliateIds.length !== 0 ? affiliateIds : allAffiliates,
        lobId: lobIds,
        expiryFrom: expiryDate,
        offSet: offset,
        limit: 10,
      };

      try {
        const results = await OrganizationUsersApi.getAllMembersData(reqBody);
        setTotalItems(results.totalItems);
        setFilteredMembers(results.data);
        if (selectedAll.current && checkForFilter) {
          setSelectedRows(results.data);
          setCheckForFilter(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    },
    [offset, name, consentArray, affiliateIds, expiryDate, lobIds, allAffiliates, checkForFilter]
  );

  const exportBulkRecords = async () => {
    const reqBody = {
      statusList: consentArray,
      name: name,
      orgId: Number(orgId),
      affiliateId: affiliateIds?.length !== 0 ? affiliateIds : allAffiliates,
      lobId: lobIds,
      expiryFrom: expiryDate,
    };

    try {
      await OrganizationUsersApi.bulkExport(reqBody);
      enqueueSnackbar(
        `Exporting data, you'll receive an email upon completion`,
        { variant: 'info' }
      );
    } catch {
      enqueueSnackbar('Something went wrong exporting data', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (orgId) {
      getAllMembersData(orgId);
    }
  }, [orgId, getAllMembersData]);

  useEffect(() => {
    if (orgId) {
      getAllPartners(orgId);
      getAllLobs(orgId);
    }
  }, [orgId, getAllLobs, getAllPartners]);

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setName(searchValue);
  };

  const onPageClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    offset !== 0 && setOffset(0);
    setName('');
  };

  const onFilterSubmit = () => {
    offset !== 0 && setOffset(0);

    if (consentValues && consentValues.length) {
      setConsentArray(consentValues);
    }
    if (affiliateValues && affiliateValues.length) {
      setAffiliateIds(affiliateValues);
    }
    if (expiresOnValue) {
      setExpiryDate(formattedDate(expiresOnValue));
    }
    if (lobsValues && lobsValues.length) {
      setLobIds(lobsValues);
    }
    setCheckForFilter(true);
    setShowChip(true);
    setActive(false);
  };

  useEffect(() => {
    if (consentArray?.length && !consentValues?.length && active === false) {
      offset !== 0 && setOffset(0);
      setConsentArray([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentArray, consentValues]);

  useEffect(() => {
    if (affiliateIds?.length && !affiliateValues?.length && active === false) {
      offset !== 0 && setOffset(0);
      setAffiliateIds([]);
    }
    if (active) {
      setShowChip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateIds, affiliateValues, active]);

  useEffect(() => {
    if (lobIds?.length && !lobsValues?.length && active === false) {
      offset !== 0 && setOffset(0);
      setLobIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lobIds, lobsValues]);

  useEffect(() => {
    if (expiryDate !== '' && !expiresOnValue && active === false) {
      offset !== 0 && setOffset(0);
      setExpiryDate('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresOnValue, expiryDate]);

  useEffect(() => {
    localStorage.removeItem('memberData');
  }, []);

  const onViewClick = data => {
    localStorage.setItem('memberData', JSON.stringify(data));
    dispatch(actions.saveMemberDetails(data));

    history.push(
      data.affiliateId
        ? `/organization/${orgId}/affiliate/${data.affiliateId}/members/member/${data.patientId}/${data.fullName}`
        : `/organization/${orgId}/members/member/${data.patientId}/${data.fullName}`
    );
  };

  const onDownload = data => {
    singleMemberDownload(
      data.affiliateId.toString(),
      data.universalId.toString(),
      `${data.fullName.split(' ').join('_')}.json`,
      enqueueSnackbar
    );
  };

  const enterDDUI = () => {
    const filteredValue = `{"statusList":[${consentValues.map(i => {
      return `"${i}"`;
    })}],"name":"${name}","orgId":${orgId},"affiliateId":[${affiliateValues}],"lobId":[${lobsValues}],"expiryFrom":"${expiryDate}","offSet":${0},"limit":${10}}`;
    fetchMemberData.push({
      data: selectedRows,
      filters: filteredValue,
      totalCount: totalItems,
      fetchedRows: filteredMembers,
      selectedAll: selectedAll.current,
    });

    if (selectedRows?.length == filteredMembers?.length) {
      setconfirmationCount(totalItems);
    } else {
      setconfirmationCount(selectedRows.length);
    }
    setOpenDrawer(true);
  };

  function isJsonString(item) {
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === 'object' && item !== null) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    function handleMessage(event) {
      if (isJsonString(event.data)) {
        const messageObject = JSON.parse(event.data);
        if (messageObject.flowTypeValue === 'DDUI') {
          exitDDUI();
          setMessageVariant(messageObject.variant);
          setMessageValue(messageObject.message);
          setShowConsentInfoModal(true);
          //Handle Error or Success message based on messageObject.variant
        }
      }
    }
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const exitDDUI = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      {showConsentInfoModal && (
        <ConsentInfoModal
          open={showConsentInfoModal}
          handleClose={() => {
            setShowConsentInfoModal(false);
            setMessageValue('');
            setMessageVariant('');
            getAllMembersData(orgId);
          }}
          membersDetails={selectedRows}
          totalCount={confirmationCount}
          messageVariant={messageVariant}
          message={messageValue}
          type="bulk"
        />
      )}
      <Grid
        container
        alignItems="center"
        style={{ marginBottom: 16, marginTop: 16 }}
      >
        <Grid item>
          <PageHeaderTitle>Consumer mediated data exchange</PageHeaderTitle>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: '280px',
                marginRight: '16px',
                height: '40px',
              }}
            >
              <BackendSearchTextField
                label="Search by name"
                onChangeText={onChangeText}
                value={searchValue}
                clearData={onClearData}
                onSearchClick={onSearchClick}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={onSearchClick}
              className={classes.searchButton}
            >
              Search
            </Button>
            <MembersFilter
              allFiltersValues={allFiltersValues}
              setAllFiltersValues={setAllFiltersValues}
              affiliateStatus={affiliateStatus}
              lobStatus={lobStatus}
              consentStatus={consentStatus}
              expireOn={expireOn}
              handleSelectedData={handleSelectedData}
              affiliates={affiliates}
              lobs={lobs}
              onFilterSubmit={onFilterSubmit}
              active={active}
              setActive={setActive}
              consentStatusFilterValues={consentStatusFilterValues}
              affiliateFilterValues={affiliateFilterValues}
              lobFilterValues={lobFilterValues}
              expireOnFilterValues={expireOnFilterValues}
              setShowChip={setShowChip}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
            {selectedRows.length >= 1 &&
              selectedRows.length === filteredMembers.length && (
                <PrimaryButton
                  variant="contained"
                  onClick={exportBulkRecords}
                  className={classes.exportButton}
                  hasPermissions={
                    membersPermissions?.get('Download/Export Records')?.READ
                  }
                >
                  Export Records
                </PrimaryButton>
              )}
            {selectedRows.length >= 1 && (
              <PrimaryButton
                variant="contained"
                color="primary"
                className={classes.searchButton}
                onClick={enterDDUI}
                hasPermissions={
                  membersPermissions?.get('Initiate consent')?.CREATE
                }
              >
                Initiate consent request
              </PrimaryButton>
            )}
          </div>
        </div>

        {openDrawer && (
          <ConsentTrigger data={fetchMemberData} exitDDUI={exitDDUI} />
        )}
        {showChip && (
          <Grid item xs={12}>
            <MemberChip
              allFiltersValues={allFiltersValues}
              setAllFiltersValues={setAllFiltersValues}
              consentStatusFilterValues={consentStatusFilterValues}
              affiliateFilterValues={affiliateFilterValues}
              lobFilterValues={lobFilterValues}
              expireOnFilterValues={expireOnFilterValues}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <MembersDataGrid
            apiLoading={loading}
            rowData={filteredMembers}
            onView={onViewClick}
            onDownload={onDownload}
            onRowSelected={onRowSelected}
            selectedRows={selectedRows}
            hasDownloadPermission={
              membersPermissions?.get('Download/Export Records')?.READ
            }
          />
          <PaginationComponent
            numberOfRowsPerPage={10}
            count={totalItems}
            onPageClick={onPageClick}
            page={offset}
          />
        </Grid>
      </Grid>

      <SimpleBackdrop open={loading} />
    </>
  );
};

export default MembersPage;
