import React from 'react';
import Chip from '@material-ui/core/Chip';

const MemberChip = ({
  allFiltersValues,
  setAllFiltersValues,
  consentStatusFilterValues,
  affiliateFilterValues,
  lobFilterValues,
}) => {
  const { affiliateStatus, consentStatus, expireOn, lobStatus } =
    allFiltersValues;
  const expireOnLabel = expireOn?.find((data, i) => data.isChecked);

  const consentLabel = consentStatusFilterValues?.join(', ');

  const affiliateLabel = affiliateFilterValues?.join(', ');

  const lobLabel = lobFilterValues?.join(', ');

  const handleDelete = selectedChip => {
    if (selectedChip === 'consent') {
      const deleteConsent = consentStatus?.map((data, i) => ({
        ...data,
        isChecked: false,
      }));
      setAllFiltersValues(prev => {
        return { ...prev, consentStatus: deleteConsent };
      });
    }
    if (selectedChip === 'affiliate') {
      const deleteAffiliate = affiliateStatus?.map((data, i) => ({
        ...data,
        isChecked: false,
      }));
      setAllFiltersValues(prev => {
        return { ...prev, affiliateStatus: deleteAffiliate };
      });
    }
    if (selectedChip === 'expireOn') {
      const deleteExpireOn = expireOn?.map((data, i) => ({
        ...data,
        isChecked: false,
      }));
      setAllFiltersValues(prev => {
        return { ...prev, expireOn: deleteExpireOn };
      });
    }
    if (selectedChip === 'lob') {
      const deleteLob = lobStatus?.map((data, i) => ({
        ...data,
        isChecked: false,
      }));
      setAllFiltersValues(prev => {
        return { ...prev, lobStatus: deleteLob };
      });
    }
  };

  return (
    <div style={{ marginTop: '24px' }}>
      {expireOnLabel && (
        <Chip
          style={{
            backgroundColor: '#FAFDFF',
            borderColor: '#036FCB',
            margin: '5px',
          }}
          variant="outlined"
          size="medium"
          label={`Expires on: ${expireOnLabel?.value || ''}`}
          onDelete={() => {
            handleDelete('expireOn');
          }}
        />
      )}
      {affiliateLabel && (
        <Chip
          style={{
            backgroundColor: '#FAFDFF',
            borderColor: '#036FCB',
            marginLeft: '10px',
          }}
          variant="outlined"
          size="medium"
          label={`Affiliates: ${affiliateLabel || ''}`}
          onDelete={() => {
            handleDelete('affiliate');
          }}
        />
      )}
      {consentLabel && (
        <Chip
          style={{
            backgroundColor: '#FAFDFF',
            borderColor: '#036FCB',
            marginLeft: '10px',
          }}
          variant="outlined"
          size="medium"
          label={`Consent status: ${consentLabel || ''}`}
          onDelete={() => {
            handleDelete('consent');
          }}
        />
      )}
      {lobLabel && (
        <Chip
          style={{
            backgroundColor: '#FAFDFF',
            borderColor: '#036FCB',
            marginLeft: '10px',
          }}
          variant="outlined"
          size="medium"
          label={`LOB: ${lobLabel || ''}`}
          onDelete={() => {
            handleDelete('lob');
          }}
        />
      )}
    </div>
  );
};

export default MemberChip;
