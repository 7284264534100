import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M16.25 12.6875H20.3174C20.8916 12.6875 21.3701 12.209 21.3701 11.6826C21.3701 11.1084 20.8916 10.6299 20.3174 10.6299H16.25C15.6758 10.6299 15.2451 11.1084 15.2451 11.6826C15.2451 12.209 15.6758 12.6875 16.25 12.6875ZM16.25 16.7549H20.3174C20.8916 16.7549 21.3701 16.3242 21.3701 15.75C21.3701 15.1758 20.8916 14.7451 20.3174 14.7451H16.25C15.6758 14.7451 15.2451 15.1758 15.2451 15.75C15.2451 16.3242 15.6758 16.7549 16.25 16.7549ZM16.25 20.8701H20.3174C20.8916 20.8701 21.3701 20.3916 21.3701 19.8174C21.3701 19.291 20.8916 18.8125 20.3174 18.8125H16.25C15.6758 18.8125 15.2451 19.291 15.2451 19.8174C15.2451 20.3916 15.6758 20.8701 16.25 20.8701ZM11.1299 10.6299H13.1875V12.6875H11.1299V10.6299ZM11.1299 14.7451H13.1875V16.7549H11.1299V14.7451ZM11.1299 18.8125H13.1875V20.8701H11.1299V18.8125ZM24.4326 6.5625H8.06738C7.54102 6.5625 7.0625 7.04102 7.0625 7.56738V23.9326C7.0625 24.459 7.54102 24.9375 8.06738 24.9375H24.4326C24.959 24.9375 25.4375 24.459 25.4375 23.9326V7.56738C25.4375 7.04102 24.959 6.5625 24.4326 6.5625ZM23.3799 22.8799H9.12012V8.62012H23.3799V22.8799Z" />
    </SvgIcon>
  );
};

export default Icon;
