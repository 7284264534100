import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField } from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  row: {
    paddingBottom: theme.spacing(3),
  },
  check: {
    fontSize: 48,
    color: '#27AE60',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  body: {
    textAlign: 'center',
  },
  dialogPaper: {
    borderRadius: theme.spacing(3),
  },
}));

export default function AlertDialog({
  open,
  onClose,
  onConfirm,
  title,
  description,
  email,
  setEmail,
  loading = false,
  confirmBtnTxt = 'Confirm',
  closeBtnTxt = 'Cancel',
  emailAlert = false,
  customTitleStyle
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className={classes.closeContainer}>
          <div
            id="alert-dialog-title"
            style={customTitleStyle || { fontSize: 20, fontWeight: 'bold', padding: 20 }}>
            {title}
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: '#4B4B4B' }}>
            {description}
          </DialogContentText>
          {emailAlert && (
            <TextField
              margin="dense"
              id="name"
              placeholder='Enter email id'
              value={email}
              onChange={event => setEmail(event.target.value)}
              type="email"
              fullWidth
              variant="outlined"
            />
          )}
        </DialogContent>
        <DialogActions style={{ margin: 20 }}>
          {onClose && (
            <SecondaryButton onClick={onClose}>{closeBtnTxt}</SecondaryButton>
          )}
          <PrimaryButton onClick={onConfirm} autoFocus>
            {emailAlert ? 'Send a test mail' : confirmBtnTxt}
          </PrimaryButton>
        </DialogActions>
        <SimpleBackdrop open={loading} />
      </Dialog>
    </div>
  );
}
