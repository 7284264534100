import React, { useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SecondaryButton, SimpleBackdrop } from 'components/widgets';
import { formatInTimeZone } from 'date-fns-tz';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  TextField,
  Tooltip,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import CriteriaDropdown from 'containers/Rules/formElements/criteriaDropdown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CSVIcon from '../../../../assets/consent/CSV.svg';
import CriteriaApi from 'services/api/CriteriaApi';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { DownloadRawFile } from 'shared/utility';
import { CRITERIA_OPERATOR_OPTIONS } from 'shared/constants';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import NonFhirAttributeCriteria from './NonFhirAttributeCriteria';

const FIVE_MB_IN_BYTES = 5242880;

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#344054',
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
    borderRadius: '24px',
    minWidth: '60vw',
    maxWidth: '60vw',
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    border: '1px dashed #8C8C8C',
    borderRadius: '8px',
    minheight: '144px',
    textAlign: 'center',
    padding: '15px',
  },
  accordionSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    gap: '10px',
  },
  accordionContent: {
    flexGrow: 0,
  },
  accordionContainer: {
    boxShadow: 'none',
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 30px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
}));

const REFERENCE_COLUMN = [
  'Code',
  'Zip code',
  'NPI',
  'Address',
  'Phone',
  'Email',
  'Condition Name',
  'Category',
  'generalPractitioner',
];

const ATTRIBUTE_TYPES = [
  {
    value: 'FHIR',
    label: 'FHIR',
  },
  {
    value: 'Non-FHIR',
    label: 'Non-FHIR',
  },
];

// Styled Components

const DialogTitle = props => {
  const { children, onClose } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2" style={{ fontSize: '24px', fontWeight: 600 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: '10px',
    borderTop: 'none',
    marginRight: '10px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '10px 20px',
  },
}))(MuiDialogActions);

// Drag and Drop Component

const Dropzone = ({ myFiles, setMyFiles, setCriteriaValue }) => {
  const onDrop = acceptedFiles => {
    setMyFiles(acceptedFiles);
    setCriteriaValue('');
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: '.csv',
    onDrop,
    maxFiles: 1,
    maxSize: FIVE_MB_IN_BYTES, //Max Size allowed is 5MB
  });

  const files = myFiles?.map(file => {
    let BYTES_TO_MB = 0.00000095367432;
    let sizeInMB = file.size * BYTES_TO_MB;
    return (
      <Box
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        style={{ gap: '10px' }}>
        <img src={CSVIcon} alt="csv-icon" />
        <Typography
          key={file.path}
          style={{ fontWeight: 400, fontSize: '14px', color: '#101828' }}>
          {file.name} (
          {sizeInMB > 0.1 ? `${sizeInMB.toFixed(2)} MB` : `${file.size} bytes`})
        </Typography>
      </Box>
    );
  });
  const classes = useStyles();
  const remove = () => {
    setMyFiles([]);
  };
  return (
    <Box>
      <Box className={classes.dropzoneContainer}>
        <Link
          href="https://mpowered-assets.s3.us-east-2.amazonaws.com/partner-portal-assets/criteria/Criteria+Sample+File.csv"
          style={{
            marginLeft: '10px',
            paddingBottom: '10px',
            fontWeight: 500,
            fontSize: '16px',
          }}>
          Download criteria sample file
        </Link>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} data-testid="bulk-upload-file-upload" />

          <Typography
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#667085',
              marginBottom: '10px',
            }}>
            CSV
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#667085',
              marginBottom: '5px',
            }}>
            Drag and drop your scan here or
          </Typography>
          <SecondaryButton onClick={open}>Choose file</SecondaryButton>
        </div>
      </Box>
      {myFiles?.length > 0 && (
        <Box
          style={{
            border: '1px solid #8C8C8C',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            padding: '10px',
            alignItems: 'center',
          }}
          data-testid="list-uploaded-file">
          {files}
          <CloseIcon
            style={{ fontSize: '22px', cursor: 'pointer' }}
            onClick={remove}
          />
        </Box>
      )}
    </Box>
  );
};

const CreateCriteriaModal = ({
  open,
  handleClose,
  handleSaveAndClose,
  isEdit,
  criteriaDetails,
  getAllCriterias,
  setAlertFlag,
  alertFlag,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const initLoad = useRef(true);
  const [criteriaTitle, setCriteriaTitle] = useState(
    criteriaDetails?.name || ''
  );
  const [operatorValue, setOperatorValue] = useState(
    criteriaDetails?.criteriaDefinitionDto?.operator || ''
  );

  const [attributeId, setAttributeId] = useState(
    criteriaDetails?.criteriaDefinitionDto?.attribute || ''
  );
  const [criteriaValue, setCriteriaValue] = useState(
    criteriaDetails?.criteriaDefinitionDto?.value || ''
  );
  const [bulkUploadFile, setBulkUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attributeOptions, setAttributeOptions] = useState();
  const [attributeValueOptions, setAttributeValueOptions] = useState([]);
  const [selectedAttrValue, setSelectedAttrValue] = useState([]);
  const [tempValue, setTempValue] = useState(
    criteriaDetails?.criteriaDefinitionDto?.value?.split(',') || []
  );
  const [disable, setDisable] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [referenceColumnValue, setReferenceColumnValue] = useState(
    criteriaDetails?.criteriaDefinitionDto?.value
      ? 'placeholder'
      : criteriaDetails?.referenceColumn || 'placeholder'
  );
  const [attributeType, setAttributeType] = useState(
    criteriaDetails?.attrType || ATTRIBUTE_TYPES[0].value
  );
  // Non-FHIR States
  const [nonFhirAttributeOptions, setNonFhirAttributeOptions] = useState();
  const getNonFHIRValues = useRef();
  const allSelected = attributeValueOptions?.length === tempValue?.length;
  const applyFilter = createFilterOptions();

  const [isOpen, setIsOpen] = useState(false);
  const orgId = sessionStorage.getItem("orgId");

  const selectedAttributeDetails = useRef();

  useEffect(() => {
    if (
      criteriaDetails?.criteriaDefinitionDto?.value &&
      attributeValueOptions?.length !== 0 &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      let idArray = [];
      for (let x of criteriaDetails.criteriaDefinitionDto.value.split(',')) {
        let comp = attributeValueOptions.map(item => item.code).indexOf(x);
        idArray.push(comp);
      }
      let defaultValueArray = idArray.map(item => attributeValueOptions[item]);
      setSelectedAttrValue(prev => [...prev, ...defaultValueArray]);
    }
  }, [
    criteriaDetails?.criteriaDefinitionDto?.value,
    attributeValueOptions,
    attributeType,
  ]);

  useEffect(() => {
    if (criteriaDetails?.criteriaDefinitionDto?.value) {
      if (
        JSON.stringify(criteriaValue) ===
          JSON.stringify(criteriaDetails?.criteriaDefinitionDto?.value) &&
        JSON.stringify(tempValue) ===
          JSON.stringify(
            criteriaDetails?.criteriaDefinitionDto?.value?.split(',')
          )
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [criteriaValue, selectedAttrValue]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const {
    info: { firstName, lastName },
  } = useSelector(state => state.userInfo);

  const handleClickClose = () => {
    handleClose();
  };

  const getLink = async () => {
    try {
      const results = await CriteriaApi.getDownloadLink(
        orgId,
        criteriaDetails.id,
        criteriaDetails.version
      );
      DownloadRawFile(results, criteriaDetails.id);
    } catch (err) {
      enqueueSnackbar('Something went wrong while downloading the file', {
        variant: 'error',
      });
    }
  };

  // const resetValue = () => {
  //   setBulkUploadFile([]);
  //   setCriteriaTitle('');
  //   setOperatorValue('eq');
  //   setReferenceColumnValue('');
  //   setAttributeId('');
  //   setCriteriaValue('');
  //   setAttributeOptions();
  //   setSelectedAttrValue([]);
  // };

  const handleCreateCriteriaAPI = async () => {
    if (
      (referenceColumnValue === 'placeholder' || referenceColumnValue === '') &&
      bulkUploadFile[0] &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      enqueueSnackbar('Please select reference column', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    } else if (
      criteriaValue === '' &&
      bulkUploadFile?.length === 0 &&
      attributeValueOptions?.length === 0 &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      enqueueSnackbar('Please enter criterion value', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    } else if (
      attributeValueOptions?.length !== 0 &&
      selectedAttrValue.length === 0 &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      enqueueSnackbar('Please select at least one value from the dropdown', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    } else if (attributeType === ATTRIBUTE_TYPES[1].value) {
      const { isValid, errorMsg } = getNonFHIRValues.current();
      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return;
      }
    }
    setLoading(true);
    await CriteriaApi.getCriteriaTitle({ orgId, criteriaTitle })
      .then(val => {
        if (val.length !== 0) {
          enqueueSnackbar(
            'This criterion already exists in the system. Please enter different title',
            {
              variant: 'error',
              autoHideDuration: 1500,
            }
          );
          setLoading(false);
          return;
        }

        let payloadJSON = {
          name: criteriaTitle.trim(),
          orgId: orgId,
          isActive: true,
        };
        let payload = new FormData();

        // FHIR Payload
        if (attributeType === ATTRIBUTE_TYPES[0].value) {
          let customFilterComponentDisplay = '';
          if (selectedAttrValue.length) {
            let displayValueCount = 0;
            selectedAttrValue.forEach(val => {
              if (!displayValueCount) {
                displayValueCount += 1;
                customFilterComponentDisplay += val.displayValue;
              } else {
                customFilterComponentDisplay += `, ${val.displayValue}`;
              }
            });
          }

          payloadJSON = {
            ...payloadJSON,
            attributeId: attributeId,
            type: 'FHIR',
            operator: operatorValue,
            fileName: bulkUploadFile[0]?.name || '',
            customFilterComponentValue: selectedAttrValue?.length
              ? selectedAttrValue.map(item => item.code).join(',')
              : criteriaValue,
            customFilterComponentDisplay: customFilterComponentDisplay,
            refColumn:
              referenceColumnValue !== 'placeholder'
                ? referenceColumnValue
                : '',
          };

          payload.append('criteriajson', JSON.stringify(payloadJSON));
          if (bulkUploadFile[0]) {
            payload.append('file', bulkUploadFile[0]);
          }
        } else {
          // NON-FHIR Payload

          const { attributeId, parameters } = getNonFHIRValues.current();
          payloadJSON = {
            ...payloadJSON,
            type: 'NON-FHIR',
            attributeId,
            parameters,
            // TODO Should remove below properties post BE changes
            operator: 'null',
            customFilterComponentValue: '',
            customFilterComponentDisplay: '',
            refColumn: '',
          };
          payload.append('criteriajson', JSON.stringify(payloadJSON));
        }

        CriteriaApi.createCriteria({ payload, orgId })
          .then(res => {
            enqueueSnackbar('criterion is successfully created', {
              variant: 'success',
              autoHideDuration: 1500,
            });
            handleSaveAndClose();
            getAllCriterias();
          })
          .catch(err => {
            enqueueSnackbar(
              err.response.data.details || 'Something went wrong',
              { variant: 'error' }
            );
            setLoading(false);
          });
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });

    return;
  };
  const handleUpdateCriteriaAPI = async () => {
    if (
      (referenceColumnValue === 'placeholder' || referenceColumnValue === '') &&
      bulkUploadFile[0] &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      enqueueSnackbar('Please select reference column', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    } else if (
      !criteriaDetails.fileUrl &&
      !bulkUploadFile[0] &&
      criteriaValue === '' &&
      (attributeValueOptions === undefined || []) &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      enqueueSnackbar('Please enter criterion title', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    } else if (
      attributeValueOptions?.length !== 0 &&
      selectedAttrValue.length === 0 &&
      attributeType !== ATTRIBUTE_TYPES[1].value
    ) {
      enqueueSnackbar('Please select at least one value from the dropdown', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return;
    } else if (attributeType === ATTRIBUTE_TYPES[1].value) {
      const { isValid, errorMsg, parameters } = getNonFHIRValues.current();
      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return;
      } else if (
        isEdit &&
        criteriaTitle === criteriaDetails.name &&
        !parameters?.some(
          (param, index) =>
            param.value !==
            criteriaDetails.criteriaDefinitionDto.parameters[index].value
        )
      ) {
        enqueueSnackbar('Please update any criteria value', {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return;
      }
    }
    setLoading(true);
    await CriteriaApi.getCriteriaTitle({ orgId, criteriaTitle })
      .then(val => {
        if (criteriaTitle !== criteriaDetails.name && val.length !== 0) {
          enqueueSnackbar(
            'This criterion already exists in the system. Please enter different title',
            {
              variant: 'error',
              autoHideDuration: 1500,
            }
          );
          setLoading(false);
          return;
        }
        let payloadJSON = {
          id: criteriaDetails.id,
          name: criteriaTitle.trim(),
          version: criteriaDetails.version,
          orgId: orgId,
          isActive: true,
          createdBy: criteriaDetails.createdBy,
          updatedBy: criteriaDetails.updatedBy,
        };
        let payload = new FormData();
        // FHIR Payload
        if (attributeType === ATTRIBUTE_TYPES[0].value) {
          let customFilterComponentDisplay = '';
          if (selectedAttrValue.length) {
            let displayValueCount = 0;
            selectedAttrValue.forEach(val => {
              if (!displayValueCount) {
                displayValueCount += 1;
                customFilterComponentDisplay += val.displayValue;
              } else {
                customFilterComponentDisplay += `, ${val.displayValue}`;
              }
            });
          }
          payloadJSON = {
            ...payloadJSON,
            type: 'FHIR',
            attributeId: attributeId,
            operator: operatorValue,
            fileName: bulkUploadFile[0]?.name || '',
            customFilterComponentValue: selectedAttrValue?.length
              ? selectedAttrValue.map(item => item.code).join(',')
              : criteriaValue,
            customFilterComponentDisplay: customFilterComponentDisplay,
            refColumn: criteriaValue
              ? ''
              : referenceColumnValue !== 'placeholder'
              ? referenceColumnValue
              : '',

            fileUrl: criteriaValue ? null : criteriaDetails.fileUrl,
          };

          payload.append('criteriajson', JSON.stringify(payloadJSON));
          if (bulkUploadFile[0]) {
            payload.append('file', bulkUploadFile[0]);
          }
        } else {
          // NON-FHIR Payload

          const { attributeId, parameters } = getNonFHIRValues.current();
          payloadJSON = {
            ...payloadJSON,
            type: 'NON-FHIR',
            attributeId,
            parameters,
            // TODO Should remove below properties post BE changes
            operator: 'null',
            customFilterComponentValue: '',
            customFilterComponentDisplay: '',
            refColumn: '',
          };
          payload.append('criteriajson', JSON.stringify(payloadJSON));
        }

        CriteriaApi.editCriteria({ payload, orgId })
          .then(res => {
            enqueueSnackbar('Criteria updated successfully', {
              variant: 'success',
              autoHideDuration: 1500,
            });
            handleSaveAndClose();
            getAllCriterias();
          })
          .catch(err => {
            enqueueSnackbar(
              err.response.data.details || 'Something went wrong',
              { variant: 'error' }
            );
            setLoading(false);
          });
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });

    return;
  };

  const onAttributeChange = (value, attrOptions) => {
    if (isEdit && initLoad.current) {
      let selectedValue = attrOptions.filter(
        item => item.id === attributeId
      )?.[0];

      selectedAttributeDetails.current = selectedValue;

      setShowDatePicker(selectedValue?.isDatePicker);

      setAttributeValueOptions(
        selectedValue?.resourceValue?.value || undefined
      );
      initLoad.current = false;
      return;
    }
    let selectedValue = attrOptions.filter(item => item.id === value)?.[0];
    selectedAttributeDetails.current = selectedValue;
    setShowDatePicker(selectedValue?.isDatePicker);
    setAttributeId(value);

    setBulkUploadFile([]);
    setOperatorValue('eq');
    if (
      selectedValue &&
      selectedValue?.resourceValue?.value?.length > 0 &&
      selectedValue?.resourceValue?.value?.[0] !== ''
    ) {
      setAttributeValueOptions(
        selectedValue?.resourceValue?.value || undefined
      );
      // setSelectedAttrValue([selectedValue?.resourceValue?.value?.[0]]);
    } else {
      setCriteriaValue('');
      setAttributeValueOptions([]);
      setSelectedAttrValue([]);
    }
  };

  const handleToggleOption = selectedOps => {
    setSelectedAttrValue(selectedOps);
    setTempValue(selectedOps.map(item => item.code));
  };

  const handleClearOptions = () => {
    setSelectedAttrValue([]);
    setTempValue([]);
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedAttrValue(attributeValueOptions);
      setTempValue(attributeValueOptions.map(item => item.code));
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected);
  };

  const handleChange = (event, value, reason) => {
    if (value?.length !== 0) {
      setSelectedAttrValue(value);
      setTempValue(value.map(item => item.code));

      if (reason === 'select-option' || reason === 'remove-option') {
        if (value.find(option => option.displayValue === 'Select all')) {
          handleToggleSelectAll();
        } else {
          handleToggleOption(value);
        }
      } else if (reason === 'clear') {
        handleClearOptions();
      }
    } else {
      setSelectedAttrValue([]);
      setTempValue([]);
    }
  };

  const handleDateChange = date => {
    const d = new Date(date);
    const mint = d.getUTCMinutes();
    const hr = d.getUTCHours();
    const day = d.getUTCDate();
    d.setDate(day);
    d.setHours(hr);
    d.setMinutes(mint);
    setCriteriaValue(d);
    setIsOpen(false);

    if (bulkUploadFile) {
      setBulkUploadFile([]);
    }
  };

  useEffect(() => {
    if (
      open &&
      ((!attributeOptions && attributeType === ATTRIBUTE_TYPES[0].value) ||
        (!nonFhirAttributeOptions &&
          attributeType === ATTRIBUTE_TYPES[1].value &&
          !isEdit))
    ) {
      setLoading(true);
      CriteriaApi.getAllCriteriaWithAttrType(orgId, attributeType)
        .then(res => {
          if (attributeType === ATTRIBUTE_TYPES[0].value) {
            setAttributeOptions(res.data);
            onAttributeChange(res.data[0].id, res.data);
          } else {
            setNonFhirAttributeOptions(res.data);
          }
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, open, attributeOptions, attributeType]);

  useEffect(() => {
    if (criteriaDetails?.name) {
      if (
        criteriaDetails?.name === criteriaTitle &&
        (criteriaDetails?.fileUrl !== null
          ? !bulkUploadFile[0] && criteriaValue === ''
          : disable) &&
        attributeType === ATTRIBUTE_TYPES[0].value
      ) {
        setAlertFlag(false);
      } else {
        setAlertFlag(true);
      }
    } else if (criteriaTitle !== '') {
      setAlertFlag(true);
    }
  }, [criteriaDetails, criteriaTitle, bulkUploadFile, criteriaValue, disable]);

  const handleDisableButton = () => {
    if (isEdit && attributeType === ATTRIBUTE_TYPES[0].value) {
      return (
        criteriaDetails?.name === criteriaTitle &&
        (criteriaDetails?.fileUrl !== null
          ? !bulkUploadFile[0] && criteriaValue === ''
          : disable)
      );
    } else if (isEdit && attributeType === ATTRIBUTE_TYPES[1].value) {
      if (alertFlag) return false;
      else if (criteriaDetails?.name === criteriaTitle) return true;
    } else if (criteriaTitle === '') return true;
    else return false;
  };

  return (
    <div>
      <Dialog
        customStyle
        classes={{ paper: classes.dialogPaper }}
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <Box style={{ padding: '10px', flex: 1 }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClickClose}>
            {isEdit ? 'Edit a criterion' : 'Create a criterion'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.formTitle}>
                  Criterion name <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  required
                  id="outlined-basic"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  value={criteriaTitle}
                  onChange={e => setCriteriaTitle(e.target.value)}
                  placeholder="Enter name of your criterion"
                  inputProps={{ 'data-testid': 'criteria-title-textbox' }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" disabled={isEdit}>
                  <FormLabel component="legend" style={{ color: '#000' }}>
                    <Typography className={classes.formTitle}>
                      Type of attribute <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    style={{ display: 'flex', flexDirection: 'row' }}
                    aria-label="attribute-type"
                    name="attribute-type"
                    value={attributeType}
                    onChange={(e, value) => {
                      setAttributeType(value);
                    }}>
                    {ATTRIBUTE_TYPES.map((type, index) => {
                      if(sessionStorage.getItem('MPH_ORG') !== 'true' && index === 1) return null;
                      return (
                        <FormControlLabel
                          key={type.value}
                          value={type.value}
                          control={<Radio color="primary" />}
                          label={type.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>

              {attributeType === ATTRIBUTE_TYPES[1].value ? (
                <NonFhirAttributeCriteria
                  criteriaDetails={criteriaDetails}
                  customStyles={classes}
                  getNonFHIRValues={getNonFHIRValues}
                  isEdit={isEdit}
                  nonFhirAttributeOptions={
                    isEdit ? [criteriaDetails] : nonFhirAttributeOptions
                  }
                  setAlertFlag={setAlertFlag}
                  orgId={orgId}
                />
              ) : (
                <>
                  {/* FHIR Component */}
                  <Grid item xs={6}>
                    <CriteriaDropdown
                      title={'Attribute'}
                      required={!isEdit}
                      value={attributeId}
                      options={attributeOptions}
                      disabled={isEdit}
                      handleChange={e =>
                        onAttributeChange(e.target.value, attributeOptions)
                      }
                      dataTestIdForOptions="attribute-dropdown"
                      dataTestId="attribute-dropdown"
                      optionLabelKey="name"
                      optionValueKey="id"
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={6}>
                    <CriteriaDropdown
                      title={'Operator'}
                      required={!isEdit}
                      handleChange={e => setOperatorValue(e.target.value)}
                      options={CRITERIA_OPERATOR_OPTIONS}
                      value={operatorValue}
                      disabled={isEdit}
                      dataTestId="operator-dropdown"
                      optionLabelKey="label"
                      optionValueKey="value"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {attributeValueOptions &&
                    attributeValueOptions?.length > 0 ? (
                      <>
                        <Typography className={classes.formTitle}>
                          Value <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Autocomplete
                          multiple
                          size="small"
                          id="checkboxes-tags-demo"
                          onChange={handleChange}
                          options={attributeValueOptions}
                          value={selectedAttrValue}
                          disableCloseOnSelect
                          getOptionLabel={option => option?.displayValue}
                          filterOptions={(options, params) => {
                            const filtered = applyFilter(options, params);
                            return [
                              {
                                code: 'selectAll',
                                displayValue: 'Select all',
                              },
                              ...filtered,
                            ];
                          }}
                          renderOption={(option, { selected }) => {
                            const selectAllProps =
                              option.displayValue === 'Select all'
                                ? { checked: allSelected }
                                : {};
                            return (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                  {...selectAllProps}
                                />
                                {option.displayValue}
                              </React.Fragment>
                            );
                          }}
                          style={{ width: 400 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select value"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <Typography className={classes.formTitle}>
                          Value{' '}
                          {isEdit
                            ? !criteriaDetails.fileUrl && (
                                <span style={{ color: 'red' }}>*</span>
                              )
                            : !bulkUploadFile?.length && (
                                <span style={{ color: 'red' }}>*</span>
                              )}
                        </Typography>
                        {showDatePicker ? (
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            style={{ width: '100%' }}>
                            <KeyboardDatePicker
                              style={{
                                width: '100%',
                                background: 'white',
                                marginTop: '1px',
                              }}
                              // disableToolbar
                              size="small"
                              // variant="inline"
                              inputVariant="outlined"
                              format="yyyy-MM-dd"
                              margin="normal"
                              placeholder="Select date"
                              id="date-picker-inline"
                              value={
                                criteriaValue ? new Date(criteriaValue) : null
                              }
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                                // onFocus: e => {
                                //   setIsOpen(true);
                                // },
                              }}
                              // PopoverProps={{
                              //   disableRestoreFocus: true,
                              //   onClose: () => {
                              //     setIsOpen(false);
                              //   },
                              // }}
                              // InputProps={{
                              //   onFocus: () => {
                              //     setIsOpen(true);
                              //   },
                              // }}
                              // open={isOpen}
                            />
                          </MuiPickersUtilsProvider>
                        ) : (
                          <TextField
                            required
                            id="outlined-basic"
                            margin="dense"
                            variant="outlined"
                            className={classes.formTextField}
                            placeholder="Enter the value"
                            autoComplete="off"
                            value={criteriaValue}
                            onChange={e => setCriteriaValue(e.target.value)}
                            disabled={bulkUploadFile?.length > 0}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                  {criteriaDetails?.fileUrl && !bulkUploadFile[0] ? (
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: 500, color: 'grey' }}>
                        Uploaded file:{' '}
                        <Tooltip
                          title="Click to download the file"
                          placement="bottom"
                          arrow>
                          <Link
                            component="button"
                            variant="body2"
                            onClick={getLink}>
                            {criteriaDetails.fileUrl.split('/').slice(-1)[0]}
                          </Link>
                        </Tooltip>
                      </Typography>
                    </Grid>
                  ) : null}
                  {!(
                    attributeValueOptions && attributeValueOptions?.length > 0
                  ) &&
                    selectedAttributeDetails.current?.dataType !== 'DATE' && (
                      <Grid item xs={6}>
                        <Accordion className={classes.accordionContainer}>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: '#036FCB' }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                              justifyContent: 'flex-start',
                              padding: '0',
                            }}
                            classes={{ content: classes.accordionContent }}
                            data-testid="bulk-upload-accordion">
                            <Typography
                              variant="h5"
                              style={{ color: '#036FCB' }}>
                              Upload
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            className={classes.accordionSummaryContainer}
                            data-testid="bulk-upload-details">
                            <Typography className={classes.formTitle}>
                              Reference table{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Dropzone
                              myFiles={bulkUploadFile}
                              setMyFiles={setBulkUploadFile}
                              setCriteriaValue={setCriteriaValue}
                            />
                            <CriteriaDropdown
                              placeHolderText="Please select reference column"
                              title={'Reference column'}
                              required={true}
                              options={REFERENCE_COLUMN}
                              value={referenceColumnValue}
                              handleChange={e =>
                                setReferenceColumnValue(e.target.value)
                              }
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                </>
              )}

              <Grid item xs={6}></Grid>
            </Grid>
          </DialogContent>
        </Box>
        <DialogActions className={classes.footer}>
          <SecondaryButton onClick={handleClickClose}>Cancel</SecondaryButton>
          <Button
            onClick={isEdit ? handleUpdateCriteriaAPI : handleCreateCriteriaAPI}
            color="primary"
            variant="contained"
            disabled={handleDisableButton()}
            className={classes.searchButton}>
            {!isEdit ? 'Create criterion' : 'Save changes'}
          </Button>
        </DialogActions>
        <SimpleBackdrop open={loading} />
      </Dialog>
    </div>
  );
};

export default CreateCriteriaModal;
