import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Paper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserIcon from '../../../../components/icons/UserIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { convertStrToTitleCase, statusColorMap } from 'shared/utility';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 36px 1px 16px',
    margin: '36px 0px 36px 0px',
  },
  containerBox: {
    backgroundColor: '#F5F7F8',
    borderRadius: '4px',
  },
  table: {
    minWidth: 650,
  },
  tableCell: { fontWeight: 600 },
}));

const CustomTable = ({ executionInfo }) => {
  const classes = useStyles();

  const rowsData = executionInfo?.map(item => {
    return {
      ruleTransactionId: item.ruleTransactionId,
      affiliateName: item.affiliateName,
      tenantMemberCount: item.tenantMemberCount,
      tenantStatus: item.tenantStatus,
    };
  });

  return (
    <TableContainer
      component={Paper}
      style={{ maxWidth: 740, borderRadius: '16px' }}
      elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Affiliate</TableCell>
            <TableCell className={classes.tableCell} align="left">
              Members selected
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData?.map(row => (
            <TableRow key={row.ruleTransactionId}>
              <TableCell component="th" scope="row">
                {row.affiliateName}
              </TableCell>
              <TableCell align="left">{row.tenantMemberCount}</TableCell>
              <TableCell align="left">
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  {Object.keys(statusColorMap).includes(row.tenantStatus) ? (
                    <>
                      <span
                        style={{
                          backgroundColor: statusColorMap[row.tenantStatus],
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          padding: 2,
                        }}></span>
                      <span style={{ marginLeft: 8 }}>
                        {convertStrToTitleCase(row.tenantStatus)}
                      </span>
                    </>
                  ) : (
                    <span>{row.tenantStatus}</span>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MembersActionableDetail = ({ executionInfo }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [memberCountData, setMemberCount] = useState(0);

  useEffect(() => {
    let count=0;
    if (executionInfo) {
      executionInfo.map(c => {
       count=count + c.tenantMemberCount;
        setMemberCount(count);
      });
    }
  }, [executionInfo]);

  return (
    <div className={classes.root}>
      <Accordion
        elevation={1}
        expanded={expanded}
        className={classes.containerBox}
        style={{ borderRadius: '6px' }}
        onChange={() => setExpanded(prevValue => !prevValue)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Box
            data-testid="accordion-click"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box style={{ display: 'flex' }}>
              <UserIcon />
              <Typography
                variant="h6"
                style={{
                  marginLeft: '4px',
                  marginRight: '4px',
                  fontWeight: 600,
                }}>
                {memberCountData}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                members selected
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTable executionInfo={executionInfo} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MembersActionableDetail;
