import { useEffect } from 'react';

function DocumentTitle({ isAuthenticated, title }) {
  useEffect(() => {
    if (isAuthenticated) {
      if (title && title !== '') {
        document.title = title;
      } else {
        document.title = 'mPowered Health Partners';
      }
    } else {
      document.title = 'mPowered Health Partners';
    }
  }, [isAuthenticated, title]);

  return null;
}

export default DocumentTitle;
