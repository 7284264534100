import React, { useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { SecondaryButton, SimpleBackdrop } from 'components/widgets';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import CriteriaDropdown from 'containers/Rules/formElements/criteriaDropdown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import geometricShapes from '../../../../assets/geometricShapes.svg';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import RulesApi from 'services/api/RulesApi';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '40px',
    marginBottom: '5%',
  },
  mt: {
    marginTop: '3%',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#344054',
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
    borderRadius: '24px',
    minWidth: '60vw',
    maxWidth: '60vw',
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '24px',
    fontStyle: 'normal',
    // fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
}));

const RuleStep1 = ({
  changeStep,
  step1Data,
  options,
  data,
  logo,
  ruleValues,
  shouldRefetechData,
  trackUnsavedChangesRef,
}) => {
  const [ruleTitle, setruleTitle] = useState(step1Data?.title || '');
  const [description, setDescription] = useState(step1Data?.description || '');
  const [selectedType, setSelectedType] = useState(step1Data?.type || '');
  const [tempState, setTempState] = useState(step1Data?.affiliateName || []);
  const allSelected = options.length === tempState.length;
  const [selectedaffiliate, setSelectedAffiliate] = useState(
    step1Data?.affiliate || []
  );
  const [attrType, setAttrType] = useState(step1Data?.category || 'FHIR');
  const [loading, setLoading] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();
  const EmptyGridForUI = () => <Grid item sm={9} xs={6} />;
  const { enqueueSnackbar } = useSnackbar();
  const orgId = sessionStorage.getItem('orgId');
  const FHIR_OPTIONS = ['FHIR', 'Non-FHIR'];

  let setdata = {
    title: ruleTitle?.trim(),
    description: description,
    type: selectedType,
    category: attrType,
    affiliate: selectedaffiliate,
    affiliateName: tempState,
  };
  useEffect(() => {
    if (step1Data.affiliateName === null) {
      setTempState(
        options?.filter(val =>
          step1Data.affiliate?.includes(val.id?.toString())
        ) || []
      );
    }
  }, [options, step1Data]);

  const handleToggleOption = selectedOps => setTempState(selectedOps);
  const handleClearOptions = () => setTempState([]);

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setTempState(options);
      let temp = options.map(item => item.id);
      setSelectedAffiliate(temp);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleTypeSelection = e => {
    setSelectedType(e.target.value);
  };

  const checkValidation = () => {
    if (
      ruleTitle &&
      description &&
      selectedType &&
      selectedaffiliate.length >= 1
    ) {
      return true;
    } else {
      enqueueSnackbar('Please enter all details', {
        variant: 'error',
        autoHideDuration: 1500,
      });

      return false;
    }
  };

  const titleValidation = async () => {
    let currentRuleTitle = ruleTitle.trim();
    if (ruleValues.name === '' || ruleValues.name !== currentRuleTitle) {
      setLoading(true);
      return await RulesApi.checkForDuplicateTitle({
        orgId,
        ruleTitle: currentRuleTitle,
      })
        .then(results => {
          setLoading(false);
          if (results?.id) {
            enqueueSnackbar(
              'Rule title is already used please give different title',
              {
                variant: 'error',
                autoHideDuration: 1500,
              }
            );

            return true;
          }
        })
        .catch(err => {
          setLoading(false);
          enqueueSnackbar(
            'Rule title is already used please give different title',
            {
              variant: 'error',
              autoHideDuration: 1500,
            }
          );
          return true;
        });
    }

    return false;
  };

  const handleSubmit = async () => {
    if (!checkValidation()) return;
    if (await titleValidation()) return;
    data(setdata);
    if (ruleValues.id !== null) {
      handleSaveRuleDetails(setdata);
    } else {
      shouldRefetechData.current = true;
    }
    changeStep(false);
  };

  const compareRuleDetailsData = newData => {
    let prevData = { ...step1Data };
    let currentData = { ...newData };
    delete prevData['affiliateName'];
    delete currentData['affiliateName'];

    if (JSON.stringify(prevData) === JSON.stringify(currentData)) return false; //check if data is edited

    return true;
  };

  useEffect(() => {
    trackUnsavedChangesRef.current = () => {
      if (
        ruleTitle ||
        description ||
        selectedType ||
        selectedaffiliate.length
      ) {
        return compareRuleDetailsData(setdata);
      }

      return false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackUnsavedChangesRef, compareRuleDetailsData, step1Data]);

  const handleSaveRuleDetails = val => {
    if (!compareRuleDetailsData(val)) return;
    let payload = {
      ...ruleValues,
      name: val?.title?.trim() || '',
      description: val?.description || null,
      type: val?.type || '',
      affiliateIds: val?.affiliate,
      editBeforeExec: true,
    };
    shouldRefetechData.current = true;
    try {
      RulesApi.saveRules({ orgId, payload });
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (event, selectedOps, reason) => {
    if (selectedOps.length > 0) {
      let temp = selectedOps.map(item => item.id);
      setSelectedAffiliate(temp);
      if (reason === 'select-option' || reason === 'remove-option') {
        if (selectedOps.find(option => option.name === 'Select all')) {
          handleToggleSelectAll();
          const result = options.filter(el => el.name !== 'Select all');
          // return onChange(result);
        } else {
          handleToggleOption && handleToggleOption(selectedOps);
          // return onChange(selectedOps);
        }
      } else if (reason === 'clear') {
        handleClearOptions && handleClearOptions();
      }
    } else {
      setTempState([]);
      setSelectedAffiliate([]);
    }
  };

  const filter = createFilterOptions();
  return (
    <div style={{ overflow: 'hidden', marginTop: '-4%' }}>
      <Grid container spacing={2}>
        <Grid xs={5}>
          <div style={{ background: '#e5f5fc', height: '102vh' }}>
            {logo && (
              <img style={{ height: 40, margin: 40 }} alt={'Logo'} src={logo} />
            )}

            <img
              alt="Logo"
              src={geometricShapes}
              style={{ marginTop: '40%' }}
            />
          </div>
        </Grid>
        <Grid item xs={7} style={{ marginTop: '5%' }}>
          <div style={{ margin: 'auto', width: '70%' }}>
            <Typography className={classes.heading} data-testid="rule-heading">
              Create a rule
            </Typography>
            <Grid item sm={8} xs={8} className={classes.mt}>
              <Typography className={classes.formTitle}>
                Rule title <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                required
                id="rule-title"
                margin="dense"
                variant="outlined"
                className={classes.formTextField}
                value={ruleTitle}
                onChange={e => setruleTitle(e.target.value)}
                autoComplete="off"
                autoFocus
                inputProps={{
                  'data-testid': 'rule-title-textbox',
                }}
              />
            </Grid>
            <EmptyGridForUI />
            <Grid item sm={8} xs={8} className={classes.mt}>
              <Typography className={classes.formTitle}>
                Description <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                required
                id="outlined-basic"
                margin="dense"
                variant="outlined"
                className={classes.formTextField}
                placeholder="Enter description of the rule"
                multiline
                value={description}
                onChange={e => setDescription(e.target.value)}
                minRows={4}
                // disabled={isEdit}
                inputProps={{
                  maxLength: 100,
                  'data-testid': 'rule-description-textbox',
                }}
                helperText={`${description.length}/100`}
                FormHelperTextProps={{
                  style: {
                    // textAlign: 'center',
                    position: 'absolute',
                    bottom: '0%',
                    right: '0%',
                  },
                }}
              />
            </Grid>
            <EmptyGridForUI />

            <Grid item sm={8} xs={8} className={classes.mt}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ maxWidth: '100%' }}
              >
                <Typography className={classes.formTitle}>
                  Type <span style={{ color: 'red' }}>*</span>
                </Typography>

                <Select
                  style={{ backgroundColor: 'white' }}
                  value={selectedType}
                  onChange={e => handleTypeSelection(e)}
                  className={classes.formTextField}
                  margin="dense"
                  IconComponent={ExpandMoreIcon}
                  defaultValue=""
                  data-testid="rules-type-dropdown"
                >
                  <MenuItem key="Batch" value="Batch">
                    Batch
                  </MenuItem>
                  {/* <MenuItem key="Real" value="Real">
                    Realtime
                  </MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <EmptyGridForUI />
            {(sessionStorage.getItem('MPH_ORG') === 'true' ||
              sessionStorage.getItem('MPH_ORG') === true) && (
              <>
                <Grid item sm={8} xs={8} className={classes.mt}>
                  <FormControl component="fieldset">
                    <Typography className={classes.formTitle}>
                      Type of attribute <span style={{ color: 'red' }}>*</span>
                    </Typography>

                    <RadioGroup
                      style={{ display: 'flex', flexDirection: 'row' }}
                      aria-label="attributeType"
                      name="attributeType"
                      value={attrType}
                      onChange={(e, value) => setAttrType(value)}
                    >
                      {FHIR_OPTIONS.map(val => (
                        <FormControlLabel
                          // disabled={isEdit}
                          key={val}
                          value={val}
                          control={
                            <Radio
                              color="primary"
                              inputProps={{
                                'data-testid': `radio-button-${val}`,
                              }}
                            />
                          }
                          label={val}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <EmptyGridForUI />
              </>
            )}
            <Grid item sm={8} xs={8} className={classes.mt}>
              <Typography className={classes.formTitle}>
                Affiliate <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                data-testid="rules-affiliate-dropdown"
                onChange={handleChange}
                options={options}
                value={tempState}
                disableCloseOnSelect
                getOptionLabel={option => option.name}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return [{ id: 0, name: 'Select all' }, ...filtered];
                }}
                renderOption={(option, { selected }) => {
                  // To control the state of 'select-all' checkbox
                  const selectAllProps =
                    option.name === 'Select all'
                      ? { checked: allSelected }
                      : {};
                  return (
                    <li>
                      <Checkbox checked={selected} {...selectAllProps} />
                      {option.name}
                    </li>
                  );
                }}
                // style={{ width: 500 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search by affiliate"
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'rules-affiliate-dropdown-textbox',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={8} xs={8} className={classes.mt}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                className={classes.searchButton}
                data-testid="proceed-btn"
              >
                Proceed
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <SimpleBackdrop open={loading} />
    </div>
  );
};

export default RuleStep1;
