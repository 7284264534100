import { Component } from 'react';
import { DataGrid } from 'components/widgets';
import IconCellRenderer from 'components/widgets/DataGrid/IconCellRenderer';
import DownloadIcon from 'components/icons/DownloadIcon';
import './ag-grid-custom.css';

class UsersGrid extends Component {
  state = {
    columnDefs: [
      {
        field: 'arnId',
        hide: true,
      },
      {
        field: 'fileDisplayName',
        headerName: 'File name',
      },
      {
        field: 'fileDisplayType',
        headerName: 'File type',
        resizable: false,
      },
      {
        field: 'more',
        headerName: 'Download',
        cellRenderer: 'IconRenderer',
        resizable: false,
        sortable: false,
        filter: false,
        cellStyle: { 'text-align': 'center', top: '15%' },
        headerClass: 'text-center',
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: true,
    },
    frameworkComponents: {
      IconRenderer: rowData => (
        <IconCellRenderer
          {...rowData}
          onClick={item => this.onDownloadFile(item)}
          icon={DownloadIcon}
        />
      ),
    },
    context: { componentParent: this },
  };

  onDownloadFile(data) {
    this.props.onDownloadFile(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div>
        <div>
          <DataGrid
            // rowSelection="single"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh' }}
            hideHeaderActions
          />
        </div>
      </div>
    );
  }
}

export default UsersGrid;
