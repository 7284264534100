import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Paper,
  Slider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import theme from 'theme';
import SelectBoxWithUploadFile from '../formElements/selectWithUploadFile';

const useStyles = makeStyles({
  paperRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: 'none',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#FAFDFF',
    borderColor: '#036FCB',
  },
  accordingContainer: {
    marginBottom: '10px',
    borderRadius: '4px !important',
    '&:before': {
      display: 'none',
    },
  },
  accordingSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  accordingDetails: {
    width: '300px',
  },
  heading: {
    fontWeight: '600',
  },
});

const CustomAccordionType = ({
  data,
  type,
  handleChange,
  selectedVal,
  ...props
}) => {
  switch (type) {
    case 'slider':
      return (
        <Slider
          value={data || [0, 100]}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby={props.ariaLabel || ''}
        />
      );

    case 'select':
      return <SelectBoxWithUploadFile data={data} onChange={handleChange} />;
    default:
      return (
        <Box>
          <FormGroup
            style={{ flexDirection: props.detailsFlexDirection || 'column' }}>
            {data &&
              data.map(val => {
                return (
                  <FormControlLabel
                    key={val.id}
                    control={
                      <Checkbox
                        checked={selectedVal?.[val.label] || false}
                        onChange={handleChange.bind(this, val)}
                        color="primary"
                      />
                    }
                    label={val.label}
                  />
                );
              })}
          </FormGroup>
        </Box>
      );
  }
};

const CustomAccordionDetails = props => {
  const classes = useStyles();

  return (
    <AccordionDetails className={classes.accordingDetails}>
      <CustomAccordionType {...props} />
    </AccordionDetails>
  );
};

const CustomSingleChip = ({ data, label, handleDelete }) => {
  const classes = useStyles();
  return (
    data && (
      <Chip
        variant="outlined"
        size="medium"
        label={label}
        onDelete={handleDelete}
        className={classes.chip}
      />
    )
  );
};

const CustomMultipleChip = ({ handleDelete, chipData }) => {
  const classes = useStyles();
  let val;
  if (chipData) {
    val = Object.values(chipData);
  }
  if (!val) {
    return null;
  }
  return (
    chipData && (
      <Paper component="ul" className={classes.paperRoot}>
        {val?.map(data => {
          let icon;

          return (
            <li key={data.id}>
              <Chip
                variant="outlined"
                size="medium"
                icon={icon}
                label={data.label}
                onDelete={handleDelete.bind(this, data)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </Paper>
    )
  );
};

const CohortAccordion = props => {
  const { title, children, ChipComponent } = props;
  const classes = useStyles();
  const [expandedRef, setExpandedRef] = useState(true);
  return (
    <>
      <Accordion className={classes.accordingContainer} expanded={expandedRef}>
        <AccordionSummary
          onClick={() => setExpandedRef(prev => !prev)}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <Box className={classes.accordingSummaryContent}>
            <Typography className={classes.heading}>{title}</Typography>
            {!props.noChip && ChipComponent}
          </Box>
        </AccordionSummary>
        {children}
      </Accordion>
    </>
  );
};

export default Object.assign(CohortAccordion, {
  AccordionDetails: CustomAccordionDetails,
  MultipleChip: CustomMultipleChip,
  SingleChip: CustomSingleChip,
});

export const AccordionSelection = ({
  title,
  data,
  id,
  onChange,
  selectedCriteriaValue,
  ...props
}) => {
  return (
    <CohortAccordion
      title={title}
      ChipComponent={
        props.singleChip ? (
          <CohortAccordion.SingleChip
            data={data}
            handleDelete={props.onChipDelete || onChange}
            label={props.chipLabel}
          />
        ) : (
          <CohortAccordion.MultipleChip
            data={data}
            handleDelete={props.onChipDelete || onChange}
            chipData={selectedCriteriaValue}
          />
        )
      }>
      <CohortAccordion.AccordionDetails
        data={data}
        handleChange={onChange}
        type={props.detailsType}
        ariaLabel={props.ariaLabel}
        selectedVal={selectedCriteriaValue}
        {...props}
      />
    </CohortAccordion>
  );
};
