import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 33 38" {...props}>
      <path d="M25.5 4.25H19.9219C19.1836 2.19922 17.2969 0.722656 15 0.722656C12.7031 0.722656 10.8164 2.19922 10.0781 4.25H4.5C2.61328 4.25 0.972656 5.80859 0.972656 7.77734V34.0273C0.972656 35.9141 2.61328 37.4727 4.5 37.4727H15.1641C14.1797 36.4883 13.3594 35.3398 12.7031 34.0273H4.5V7.77734H8.02734V9.5C8.02734 11.3867 9.58594 13.0273 11.4727 13.0273H18.5273C20.4141 13.0273 21.9727 11.3867 21.9727 9.5V7.77734H25.5V16.6367C26.7305 16.8008 27.8789 17.2109 29.0273 17.7031V7.77734C29.0273 5.80859 27.3867 4.25 25.5 4.25ZM15 7.77734C14.0156 7.77734 13.2773 6.95703 13.2773 5.97266C13.2773 5.07031 14.0156 4.25 15 4.25C15.9844 4.25 16.7227 5.07031 16.7227 5.97266C16.7227 6.95703 15.9844 7.77734 15 7.77734ZM23.7773 20C18.9375 20 15 23.9375 15 28.7773C15 33.6172 18.9375 37.4727 23.7773 37.4727C28.6172 37.4727 32.4727 33.6172 32.4727 28.7773C32.4727 23.9375 28.6172 20 23.7773 20ZM25.9922 32.2227L23.1211 29.3516C22.957 29.1875 22.875 28.9414 22.875 28.7773V24.4297C22.875 23.9375 23.2031 23.5273 23.6953 23.5273C24.1875 23.5273 24.5977 23.9375 24.5977 24.4297V28.3672L27.2227 30.9922C27.5508 31.4023 27.5508 31.8945 27.2227 32.3047C26.8945 32.6328 26.3203 32.6328 25.9922 32.2227Z" />
    </SvgIcon>
  );
};

export default Icon;
