import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
const SelectBoxWithUploadFile = ({ data, onChange, ...props }) => {
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [selectedICDCode, setSelectedICDCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = event => {
    setShowUploadButton(true);
    setSelectedICDCode(event.target.value);
    onChange(event.target.value, '');
  };

  const handleFileUpload = event => {
    const file = event.target.files?.[0];
    if (file?.type !== 'text/csv') {
      event.target.value = null;
      enqueueSnackbar('Please provide .csv file', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
      onChange(selectedICDCode, '');
      return;
    }
    onChange(selectedICDCode, event.target.files?.[0]);
  };
  return (
    <>
      <div style={{ width: 'inherit' }}>
        <Select
          style={{ width: 'inherit' }}
          displayEmpty
          label={'ICD'}
          value={selectedICDCode}
          onChange={handleChange}>
          {data?.map(item => (
            <MenuItem key={item.label} value={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      {showUploadButton && (
        <>
          <div style={{ marginLeft: '15%' }}>
            <input accept=".csv" type="file" onChange={handleFileUpload} />
            <a href="https://mpowered-assets.s3.us-east-2.amazonaws.com/partner-portal-assets/criteria-rules-cohorts/icd_code_sample.csv">
              <span style={{ fontSize: '14px', padding: '10px' }}>
                Sample ICD file
              </span>
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default SelectBoxWithUploadFile;
