import BaseApiService from './BaseApiService';
import axios from 'axios';
import { apiPath, apiHost } from 'config/apiConfig';

let _masterData = {
  States: {},
};

const masterDataTypes = {
  LoadType: 'LoadType',
  Frequency: 'Frequency',
  OrgTypes: 'OrgTypes',
  AddressTypes: 'AddressTypes',
  PlanTypes: 'PlanTypes',
  Roles: 'Roles',
  States: 'States',
};

function _getLoadType() {
  return new Promise((resolve, reject) => {
    if (_masterData[masterDataTypes.LoadType]) {
      resolve(_masterData[masterDataTypes.LoadType]);
    } else {
      BaseApiService.get(`/master/data/loadtype`)
        .then(response => {
          _masterData[masterDataTypes.LoadType] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}

function _getFrequency() {
  return new Promise((resolve, reject) => {
    if (_masterData[masterDataTypes.Frequency]) {
      resolve(_masterData[masterDataTypes.Frequency]);
    } else {
      BaseApiService.get(`/master/data/frequency`)
        .then(response => {
          _masterData[masterDataTypes.Frequency] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}

function _getOrgTypes() {
  return new Promise((resolve, reject) => {
    if (_masterData[masterDataTypes.OrgTypes]) {
      resolve(_masterData[masterDataTypes.OrgTypes]);
    } else {
      BaseApiService.get(`/master/data/orgTypes`)
        .then(response => {
          _masterData[masterDataTypes.OrgTypes] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}

function _getAddressTypes() {
  let config = {
    url: `${apiHost}${apiPath}/master/data/addressTypes`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    if (_masterData[masterDataTypes.AddressTypes]) {
      resolve(_masterData[masterDataTypes.AddressTypes]);
    } else {
      BaseApiService.get(`/master/data/addressTypes`)
        .then(response => {
          _masterData[masterDataTypes.AddressTypes] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });

      // return axios(config)
      //   .then(response => {
      //     _masterData[masterDataTypes.AddressTypes] = response.data;
      //     resolve(response.data);
      //   })
      //   .catch(err => reject(err));
    }
  });
}
function _getStates(countryCode) {
  let config = {
    url: `${apiHost}${apiPath}/master/data/${countryCode}/states`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    if (_masterData[masterDataTypes.States][countryCode]) {
      resolve(_masterData[masterDataTypes.States][countryCode]);
    } else {
      BaseApiService.get(`/master/data/${countryCode}/states`)
        .then(response => {
          _masterData[masterDataTypes.States][countryCode] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
      // return axios(config)
      //   .then(response => {
      //     _masterData[masterDataTypes.States][countryCode] = response.data;
      //     resolve(response.data);
      //   })
      //   .catch(err => reject(err));
    }
  });
}
function _getPlanTypes() {
  return new Promise((resolve, reject) => {
    if (_masterData[masterDataTypes.PlanTypes]) {
      resolve(_masterData[masterDataTypes.PlanTypes]);
    } else {
      BaseApiService.get(`/master/data/planTypes`)
        .then(response => {
          _masterData[masterDataTypes.PlanTypes] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}

function _getRoles() {
  return new Promise((resolve, reject) => {
    if (_masterData[masterDataTypes.Roles]) {
      resolve(_masterData[masterDataTypes.Roles]);
    } else {
      BaseApiService.get(`/master/data/roles`)
        .then(response => {
          _masterData[masterDataTypes.Roles] = response;
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}
// PartnerLOBApi.getPriceListByLOB(orgId, lobId);

// function _getPriceListByLOB(orgId, lobId) {
//   const data = {
//     files: [
//       { fileName: 'In-Network Rate', fileType: 'JSON' },
//       { fileName: 'Out of Network', fileType: 'XML' },
//       { fileName: 'Prescription Drug', fileType: 'CSV' },
//     ],
//   };
//   return new Promise((resolve, reject) => {
//     resolve(data);
//   });
// }

const MasterDataApi = {
  getLoadType: _getLoadType,
  getFrequency: _getFrequency,
  getOrgTypes: _getOrgTypes,
  getAddressTypes: _getAddressTypes,
  getStates: _getStates,
  getPlanTypes: _getPlanTypes,
  getRoles: _getRoles,
  // getPriceListByLOB: _getPriceListByLOB,
};

export default MasterDataApi;
