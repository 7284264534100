import React from 'react';
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import './CampaignModal.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { AssessmentAPI } from 'services/api/Assessment.api';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment/moment';

export const CampaignModal = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const searchText = useRef('');
  const loaderRef = useRef();
  const params = useParams();
  const payloadRef = useRef({ offset: 0, totalItems: 1 }); //initially totalItem is 1 to show spinner before api call, which eventually will change after getting api response
  let timer = useRef();
  const options = {
    root: null,
    rootMargin: '20px',
    threshold: 0.25,
  };

  const observer = new IntersectionObserver(
    entries => handleDebounce(entries, handleObserver),
    options
  );

  const handleDebounce = (entries, fn) => {
    if (loaderRef?.current) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => fn(entries), 1000);
    }
  };

  const handleObserver = e => {
    if (e?.[0]?.isIntersecting) {
      addData();
    }
  };

  const handleSearch = e => {
    setData([]);
    setLoading(true);
    // setSearchText(e.target.value);
    searchText.current = e.target.value;
    payloadRef.current.offset = 0;
    payloadRef.current.totalItems = 1;
    handleDebounce(e.target.value, addData);
  };

  const addData = async text => {
    try {
      let payload = {
        searchText: text ?? searchText?.current,
        limit: 10,
        offset: payloadRef?.current?.offset,
        status: ['PUBLISHED', 'ACTIVE'],
      };
      const response = await AssessmentAPI.getAllAssessment({
        orgId: sessionStorage.getItem('orgId'),
        payload,
      });
      setData(prevData => {
        if (prevData?.length < response?.totalItems)
          return [...prevData, ...response?.data];
        else return prevData;
      });
      payloadRef.current.offset += 1;
      payloadRef.current.totalItems = response?.totalItems;
      if (
        response?.data?.length === 0 ||
        response?.totalItems === data.length ||
        response?.totalItems === response?.data?.length
      ) {
        setLoading(false);
      }
    } catch (err) {
      console.error('Error', err);
    }
  };

  const handleSelection = item => {
    props?.handleClose(item);
  };

  useEffect(() => {
    observer.observe(loaderRef?.current);
  }, []);

  return (
    <div
      id="campaignAssessmentModalRoot"
      className="campaignAssessmentModalRoot"
    >
      <div className="campaignModalContainer">
        <CloseIcon className="closeIcon" onClick={props?.handleClose} />
        <div>
          <div className="header">Add assessment with this campaign</div>
          <div className="subheader">Choose from the assessments</div>
        </div>
        <div>
          <TextField
            id="search-box"
            className="searchBox"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            //   sx={{
            //     '& .MuiInputBase-root': {
            //       height: '40px',
            //     },
            //   }}
            placeholder="Search by assessment name "
            fullWidth
            onChange={e => handleSearch(e)}
          />
        </div>
        <div className="data-list">
          {data?.map((item, index) => {
            return (
              <div
                className="item-container"
                onClick={() => handleSelection(item)}
              >
                <div className="item-header">{item?.name}</div>
                <div className="item-dates">
                  <div className="created-date">
                    Created on:
                    <span className="date">
                      {' '}
                      {moment(item?.createdAt).format('MMM DD, YYYY - hh:mm A')}
                    </span>
                  </div>
                  <div className="questions">
                    Question(s):
                    <span className="count"> {item?.numberOfQuestions}</span>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="spinner" ref={loaderRef}>
            {loading && <CircularProgress />}
          </div>
        </div>
      </div>
    </div>
  );
};
