import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import {
  AlertDialog,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import EmailIcon from '@material-ui/icons/Email';
import {
  convertStrToTitleCase,
  formatTimeInHHMMWithComma,
  formatterDate,
} from 'shared/utility';
import CampaignApi from 'services/api/CampaignApi';
import { useParams } from 'react-router-dom';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import CreateCampaignModal from './CreateCampaignModal';
import { PaperContainer } from 'components/widgets/PaperContainer';
import {
  CLOSE_ALERT_DESCRIPTION,
  COLOR_LIST_FOR_STATUS,
} from 'shared/constants';
import { MembersCount } from '../MembersCount';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 0px 1px 0px',
    marginTop: '24px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#fffff',
    padding: '10px',
    marginBottom: '-7px',
    boxShadow:
      '0px 4px 8px -2px rgba(16,24,40,0.1),0px 2px 4px -2px rgba(16,24,40,0.06)',
  },
  campaignsBox: {
    backgroundColor: '#E4E7EC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '132px',
    borderRadius: '4px',
    height: '28px',
    padding: '4px',
    marginRight: '8px',
  },
  root1: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#E4E7EC',
  },
}));

const fetchMemberCount = ({
  cohortValue,
  setMemberCount,
  setMemberCountLoading,
  orgId,
}) => {
  setMemberCountLoading(true);
  CampaignApi.getMemberCountByID({ orgId, cohortId: cohortValue.cohortId })
    .then(res => {
      setMemberCount(res?.patientCount);
      setMemberCountLoading(false);
    })
    .catch(err => {
      setMemberCountLoading(false);
      setMemberCount(null);
    });
};

const CampainsGrid = ({
  id,
  cohortName,
  status,
  schedule,
  ruleMemberCount,
  name,
  createdAt,
  filteredData,
  lastElementObserver,
  isLastElement,
  refetchCampaigns,
  campaignId,
  setCampaignId,
  cohortValue,
  hasCreatePermissions
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const orgId = sessionStorage.getItem("orgId");
  const [loadingcheck, setLoadingcheck] = useState(false);
  const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState(false);
  const [fetchedData, setFetchedData] = useState('');
  const [isCreateCampaign, setIsCreateCampaign] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const trackUnsavedChangesRef = useRef(false);

  const [memberCount, setMemberCount] = useState();
  const [memberCountLoading, setMemberCountLoading] = useState(false);

  const handleClickView = async id => {
    setLoadingcheck(true);
    const selectedCampaign = filteredData.filter(item => item.id === id)?.[0];

    try {
      const results = await CampaignApi.campaignDetails({
        orgId,
        campaignId: selectedCampaign?.emailTemplateId,
      });
      results.template = results.template?.replace(
        /<div id='attachments-block'>[\s\S]*?<\/div>/g,
        ''
      );
      selectedCampaign.emailTemplate = results;
      setLoadingcheck(false);
      setCampaignId(id);
      setFetchedData(selectedCampaign);
      setOpenCreateCampaignModal(true);
    } catch (err) {
      console.log(err);
      setLoadingcheck(false);
    }
  };

  const ViewDetailsRender = ({ id }) => {
    return (
      <SecondaryButton onClick={() => handleClickView(id)}>
        View details
      </SecondaryButton>
    );
  };

  const handleCreateCampaignModal = val => {
    setOpenCreateCampaignModal(val);
  };

  const handleUnsavedChanges = () => {
    if (trackUnsavedChangesRef.current()) {
      setOpenAlertDialog(true);
    } else {
      setOpenCreateCampaignModal(false);
    }
  };

  const handleClose = () => {
    setOpenCreateCampaignModal(false);
  };

  const updateMemberCount = () => {
    if (cohortValue.cohortId) {
      fetchMemberCount({
        cohortValue,
        setMemberCountLoading,
        setMemberCount,
        orgId,
      });
    }
  };

  useEffect(() => {
    if (
      expanded &&
      memberCount === undefined &&
      !memberCountLoading &&
      cohortValue.cohortId
    ) {
      fetchMemberCount({
        cohortValue,
        setMemberCountLoading,
        setMemberCount,
        orgId,
      });
    }
  }, [expanded, cohortValue, orgId, memberCount, memberCountLoading]);
  return (
    <div
      className={classes.root}
      ref={isLastElement ? lastElementObserver : null}>
      <Accordion
        elevation={1}
        expanded={expanded}
        className={classes.containerBox}
        style={{ borderRadius: '15px' }}
        onChange={() => setExpanded(prevValue => !prevValue)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Box
            data-testid="accordion-click"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box style={{ display: 'flex' }}>
              <EmailIcon />
              <Typography
                variant="h5"
                style={{ marginLeft: '8px', marginRight: '8px' }}>
                {name || '-'}
              </Typography>
              <span
                style={{
                  backgroundColor: COLOR_LIST_FOR_STATUS[status],
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  padding: 2,
                  marginTop: '5px',
                }}></span>
              <span style={{ marginLeft: 4, marginTop: '-3px' }}>
                {convertStrToTitleCase(status) || '-'}
              </span>{' '}
            </Box>
            <Box style={{ display: 'flex', height: '24px' }}>
              <ViewDetailsRender id={id} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root1}>
            <Grid container spacing={2}>
              <Grid container xs={12} spacing={2}>
                <Grid item>
                  <MembersCount
                    memberCountLoading={memberCountLoading}
                    memberCountValue={memberCount}
                    onRefresh={updateMemberCount}
                    hideRefreshBtn={!cohortValue.cohortId}
                  />
                </Grid>
                <Grid item>
                  <PaperContainer title="Cohort:" value={cohortName || '-'} />
                </Grid>
              </Grid>{' '}
              <Grid container xs={12} spacing={2} style={{ marginTop: '5px' }}>
                {!schedule.oneTime && (
                  <Grid item>
                    <PaperContainer
                      title="Frequency:"
                      value={schedule.frequency || '-'}
                    />
                  </Grid>
                )}
                <Grid item>
                  <PaperContainer
                    title="Start date:"
                    value={
                      schedule.startDate
                        ? moment(new Date(schedule.startDate)).format(
                            'MM-DD-YYYY'
                          )
                        : '-'
                    }
                  />
                </Grid>
                <Grid item>
                  <PaperContainer
                    title="Time:"
                    value={
                      schedule.startTime
                        ? formatTimeInHHMMWithComma(
                            new Date(`2021-06-10T${schedule.startTime}`)
                          )
                        : '-'
                    }
                  />
                </Grid>
                <Grid item>
                  <PaperContainer
                    title="Timezone:"
                    value={schedule.timeZone || '-'}
                  />
                </Grid>
                {schedule.oneTime && cohortValue.assessmentId &&
                <Grid item>
                <PaperContainer
                  title="Assessment expiry date:"
                  value={
                    cohortValue.assessmentExpiry
                      ? moment(new Date(cohortValue.assessmentExpiry)).format(
                          'MM-DD-YYYY'
                        )
                      : '-'
                  }
                />
              </Grid>
                }
                {!schedule.oneTime && (
                  <Grid item>
                    <PaperContainer
                      title="End date:"
                      value={
                        schedule.endDate
                          ? moment(new Date(schedule.endDate)).format(
                              'MM-DD-YYYY'
                            )
                          : '-'
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container xs={12} spacing={2}>
                {schedule.successMailCount && (
                  <Grid item>
                    <PaperContainer
                      title="Successfully delivered emails:"
                      value={schedule.successMailCount || '-'}
                    />
                  </Grid>
                )}
                {schedule.failureMailCount && (
                  <Grid item>
                    <PaperContainer
                      title="Failed emails :"
                      value={schedule.failureMailCount || '-'}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>

        {/* code for campaign pop up screen */}
        {openCreateCampaignModal && (
          <FullScreenDialog
            open={openCreateCampaignModal}
            handleClose={handleUnsavedChanges}>
            <CreateCampaignModal
              handleCreateCampaignModal={handleCreateCampaignModal}
              handleClose={handleClose}
              fetchedData={fetchedData}
              isCreateCampaign={isCreateCampaign}
              setIsCreateCampaign={setIsCreateCampaign}
              orgId={orgId}
              campaignId={campaignId}
              refetchCampaigns={refetchCampaigns}
              trackUnsavedChangesRef={trackUnsavedChangesRef}
              hasCreatePermissions={hasCreatePermissions}
            />
          </FullScreenDialog>
        )}

        {openAlertDialog && (
          <AlertDialog
            open={openAlertDialog}
            description={CLOSE_ALERT_DESCRIPTION}
            title="Alert"
            onClose={() => setOpenAlertDialog(false)}
            onConfirm={() => {
              setOpenCreateCampaignModal(false);
              setOpenAlertDialog(false);
            }}
          />
        )}

        <SimpleBackdrop open={loadingcheck} />
      </Accordion>
    </div>
  );
};

export default CampainsGrid;
