import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import './UserDetailsForm.scss';
import { Autocomplete } from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import { PrimaryButton } from 'components/widgets';
import DeleteIcon from '@material-ui/icons/Delete';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import OrganizationApi from 'services/api/OrganizationApi';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DataPartnersApi from 'services/api/DataPartnersApi';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Close } from '@material-ui/icons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    // padding: theme.spacing(0.5),
    margin: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  chip: {
    margin: theme.spacing(0.5),
    background: 'white',
    border: '1px solid #036FCB',
  },
}));

const UserDetailsForm = props => {
  const [affiliateOptions, setAffiliateOptions] = useState([]);
  const [domainList, setDomainList] = useState(null);
  const [orgName, setOrgName] = useState('');
  const [lobs, setLOBs] = useState(null);
  const [linkedLob, setLinkedLob] = useState([]);
  const [checkAllField, setCheckAllField] = useState(false);
  const classes = useStyles();

  const handleInputChange = (type, value, eventType) => {
    const tempUserData = props?.userData;
    if (!value) {
      tempUserData[type].value = value;
      tempUserData[type].error = true;
      tempUserData[type].errorType = '';
      setCheckAllField(false);
    } else {
      tempUserData[type].value = value;
      if (type === 'emailId' && value) {
        if (!props?.externalUser) {
          let internalList = domainList
            ?.filter(item => item?.internalDomain)
            ?.filter(item => item?.domain === `@${value?.split('@')?.[1]}`);
          if (internalList?.length === 0) {
            tempUserData[type].error = true;
            tempUserData[type].errorType = 'domain';
            tempUserData['organizationName'].value = '';
            setOrgName('');
          } else {
            tempUserData[type].error = false;
          }
        } else {
          let externalList = domainList
            ?.filter(item => !item?.internalDomain)
            ?.filter(item => item?.domain === `@${value?.split('@')?.[1]}`);
          if (externalList?.length === 0) {
            tempUserData[type].error = true;
            tempUserData[type].errorType = 'domain';
            tempUserData['organizationName'].value = '';
            setOrgName('');
          } else {
            tempUserData[type].error = false;
            tempUserData['organizationName'].value = externalList?.[0]?.orgName;
            tempUserData['organizationName'].error = false;
            setOrgName(externalList?.[0]?.orgName);
          }
        }
      } else {
        tempUserData[type].error = false;
        tempUserData[type].errorType = null;
      }
      checkFieldValue(tempUserData);
    }
    props?.setUserData({ ...tempUserData });
  };

  const checkFieldValue = tempdata => {
    const data = tempdata || props?.userData;
    if (
      data?.['emailId']?.value !== '' &&
      data?.['emailId']?.value !== null &&
      data?.['emailId'].error === false &&
      data?.['firstName']?.value !== '' &&
      data?.['firstName']?.value !== null &&
      data?.['lastName']?.value !== '' &&
      data?.['lastName']?.value !== null &&
      data?.['selectedAffiliate']?.length
    ) {
      setCheckAllField(true);
    } else {
      setCheckAllField(false);
    }
  };

  const fetchAllDomains = () => {
    OrganizationApi.getAllDomains({
      orgId: sessionStorage.getItem('orgId'),
    }).then(res => {
      setDomainList(res);
    });
  };

  const handleAffiliateSelection = (value, checked) => {
    let tempData = props?.affiliateMapping;
    let tempLOB;
    if (lobs?.length) {
      tempLOB = lobs?.filter(item => item?.affiliates?.[0]?.name === value);
      setLinkedLob(tempLOB);
    }
    if (checked) {
      if (tempData?.length <= 0) tempData = [];
      tempData.push({ name: value, lob: tempLOB, selectedLob: [] });
    } else {
      tempData = tempData.filter(item => (item.name !== value ? true : false));
    }
    const tempUserData = { ...props?.userData, selectedAffiliate: tempData };
    props?.setUserData(prev => {
      return { ...prev, selectedAffiliate: tempData };
    });

    checkFieldValue(tempUserData);

    props?.setAffiliateMapping(tempData);
  };

  const handleLobSelection = (value, checked, affiliateName) => {
    let tempData = [...props?.affiliateMapping];
    if (checked) {
      tempData?.map(data => {
        if (data.name === affiliateName) {
          data?.selectedLob?.push(value);
        }
      });
    } else {
      tempData?.map(data => {
        if (data.name === affiliateName) {
          data.selectedLob = data?.selectedLob?.filter(item => {
            return item !== value;
          });
        }
      });
    }
    props?.setAffiliateMapping(tempData);
  };

  const handleDelete = (value, affiliateName) => () => {
    let tempData = [...props?.affiliateMapping];
    tempData?.map(data => {
      if (data.name === affiliateName) {
        data.selectedLob = data?.selectedLob?.filter(item => {
          return item !== value;
        });
      }
    });
    props?.setAffiliateMapping(tempData);
  };

  const getSelectedLob = key => {
    const data = [];
    props?.affiliateMapping?.map(item => {
      item.selectedLob.map(item1 => {
        item1 === key && data.push(item1);
      });
    });
    if (data?.length > 0) {
      return data;
    } else {
      return '';
    }
  };

  const getLobList = useCallback(async () => {
    try {
      const results = await OrganizationUsersApi.getAllLOB({
        orgId: sessionStorage.getItem('orgId'),
      });
      const data = results.filter(item => item.isDeleted !== 1);
      setLOBs(data);
    } catch (err) {
      console.log(err);
    }
  }, [sessionStorage.getItem('orgId')]);

  const getAllAffiliates = useCallback(() => {
    async function getAffiliates() {
      try {
        const results = await DataPartnersApi.getAllPartners(
          sessionStorage.getItem('orgId')
        );
        const response = results.filter(item => item.isDeleted !== 1);
        const data = response.map(fItem => ({
          id: fItem.id,
          name: fItem.name,
        }));
        setAffiliateOptions(data);
      } catch (error) {
        console.log(error);
      }
    }
    getAffiliates();
  }, [sessionStorage.getItem('orgId')]);

  useEffect(() => {
    if (sessionStorage.getItem('orgId')) fetchAllDomains();
    getAllAffiliates();
    getLobList();
    checkFieldValue();
  }, []);

  const handleClick = () => {
    const affiliateArray = [];
    props?.affiliateMapping?.map(data => {
      let affiliatebject = {
        affiliateId: '',
        lobIds: [],
      };
      affiliateOptions?.map(list => {
        if (data.name === list.name) {
          affiliatebject = { ...affiliatebject, affiliateId: list.id };
        }
      });

      if (data?.lob?.length > 0) {
        data?.lob?.map(item => {
          if (data?.selectedLob?.includes(item.name)) {
            affiliatebject.lobIds.push(item.id);
          }
        });
      }

      affiliateArray.push(affiliatebject);
    });
    let payload = { ...props.payload };
    payload = {
      ...payload,
      firstName: props?.userData?.firstName?.value,
      lastName: props?.userData?.lastName?.value,
      emailAddress: props?.userData?.emailId?.value,
      affiliateLobMap: affiliateArray,
    };

    if (props?.externalUser) {
      payload = {
        ...payload,
        organizationName: props?.userData?.organizationName?.value,
      };
    }
    props?.setPayload(payload);
    props?.incrementStep();
  };

  return (
    <div className="user-details-form-root">
      <div className="container">
        <div className="title">
          {props?.isEdit ? (
            <div className="header">Edit user details</div>
          ) : (
            <>
              <div className="header">Enter user details</div>
              <div className="subheader">
                Please provide all the user details below and proceed to the
                next step of defining roles.
              </div>
            </>
          )}
        </div>
        <div className="fields">
          <div className="firstName">
            <div className="label">
              First name <span style={{ color: 'red' }}>*</span>
            </div>
            <OutlinedInput
              className="inputbox firstName-input"
              fullWidth
              placeholder="Enter the first name"
              required={true}
              error={props?.userData?.firstName?.error}
              value={props?.userData?.firstName?.value}
              onChange={e => handleInputChange('firstName', e?.target?.value)}
              onBlur={e => handleInputChange('firstName', e?.target?.value)}
            />
            {props?.userData?.firstName?.error && (
              <FormHelperText error id="accountId-error">
                This field is required
              </FormHelperText>
            )}
          </div>
          <div className="lastName">
            <div className="label">
              Last name <span style={{ color: 'red' }}>*</span>
            </div>
            <OutlinedInput
              className="inputbox lastName-input"
              fullWidth
              placeholder="Enter the last name"
              required={true}
              error={props?.userData?.lastName?.error}
              value={props?.userData?.lastName?.value}
              onChange={e => handleInputChange('lastName', e?.target?.value)}
              onBlur={e => handleInputChange('lastName', e?.target?.value)}
            />
            {props?.userData?.lastName?.error && (
              <FormHelperText error id="accountId-error">
                This field is required
              </FormHelperText>
            )}
          </div>
          <div className="emailId">
            <div className="label">
              Email id <span style={{ color: 'red' }}>*</span>
            </div>
            <OutlinedInput
              className="inputbox email-input"
              fullWidth
              placeholder="Enter the email id"
              required={true}
              error={props?.userData?.emailId?.error}
              value={props?.userData?.emailId?.value}
              onChange={e => handleInputChange('emailId', e?.target?.value)}
              onBlur={e =>
                handleInputChange('emailId', e?.target?.value, 'blur')
              }
            />
            {props?.userData?.emailId?.error && (
              <FormHelperText error id="accountId-error">
                {props?.userData?.emailId?.errorType !== 'domain'
                  ? `This field is required`
                  : `This domain is not part of your organization. Please update your domain.`}
              </FormHelperText>
            )}
          </div>
          {props?.externalUser && (
            <div className="organization">
              <div className="label">
                Organization name <span style={{ color: 'red' }}>*</span>
              </div>
              <OutlinedInput
                className="inputbox organization-input"
                fullWidth
                value={orgName || props?.userData?.organizationName?.value}
                disabled
              />
              {props?.userData?.organization?.error && (
                <FormHelperText error id="accountId-error">
                  This field is required
                </FormHelperText>
              )}
            </div>
          )}
          <div className="affiliate">
            <div className="label">
              Affiliate <span style={{ color: 'red' }}>*</span>
            </div>
            <Autocomplete
              id="combo-box-demo"
              className="affiliate-autocomplete"
              options={affiliateOptions}
              getOptionLabel={option => option.name}
              style={{ height: 40 }}
              renderInput={params => (
                <>
                  <span className="searchIcon">
                    <SearchIcon />
                  </span>
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search affiliate"
                    style={{ height: 40 }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <SearchIcon />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    value={props?.affiliateMapping?.[0]?.name}
                  />
                </>
              )}
              renderOption={(option, { selected }) => (
                <div>
                  <Checkbox
                    icon={icon}
                    value={option?.key}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={props?.affiliateMapping?.find(
                      item => item.name === option?.key
                    )}
                    onChange={e =>
                      handleAffiliateSelection(e.target.value, e.target.checked)
                    }
                  />
                  {option?.key}
                </div>
              )}
            />
            <div className="affiliateContainer">
              {props?.affiliateMapping?.map(item => {
                return (
                  <div className="selectedAffiliate">
                    <div className="affiliateName">
                      <div>
                        <span className="label">Affiliate:</span>
                        {item?.name}
                      </div>
                      <div className="delete-icon">
                        <DeleteIcon
                          onClick={() =>
                            handleAffiliateSelection(item?.name, false)
                          }
                        />
                      </div>
                    </div>
                    {
                      item?.lob && <Autocomplete
                      id="lob-box-demo"
                      className="affiliate-autocomplete"
                      options={item?.lob}
                      getOptionLabel={option => option.name}
                      style={{ height: 40, width: '100%' }}
                      renderInput={params => (
                        <>
                          <span className="searchIcon">
                            <SearchIcon />
                          </span>
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search LOB"
                            style={{ height: 40, backgroundColor: 'white' }}
                            // InputProps={{
                            //   startAdornment: (
                            //     <InputAdornment position="start">
                            //       <SearchIcon />
                            //     </InputAdornment>
                            //   ),
                            // }}
                          />
                        </>
                      )}
                      renderOption={(option, { selected }) => (
                        <div>
                          <Checkbox
                            icon={icon}
                            value={option?.key}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={getSelectedLob(option?.key)}
                            onChange={e =>
                              handleLobSelection(
                                e.target.value,
                                e.target.checked,
                                item?.name
                              )
                            }
                          />
                          {option?.key}
                        </div>
                      )}
                    />
                    }
                    <Paper component="ul" className={classes.root}>
                      {props?.affiliateMapping?.map(item1 => {
                        return (
                          <>
                            {item1?.name === item?.name &&
                              item1?.selectedLob.map(subitem => {
                                return (
                                  <li key={subitem}>
                                    <Chip
                                      deleteIcon={<Close />}
                                      label={subitem}
                                      onDelete={handleDelete(
                                        subitem,
                                        item?.name
                                      )}
                                      className={classes.chip}
                                    />
                                  </li>
                                );
                              })}
                          </>
                        );
                      })}
                    </Paper>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="proceed-btn">
          <PrimaryButton
            disabled={checkAllField ? false : true}
            onClick={() => handleClick()}
          >
            Proceed
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsForm;
