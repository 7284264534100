import {
  makeStyles,
  Container,
  IconButton,
  Tooltip,
  Chip,
  Box,
  Popper,
  Paper,
  Typography,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Page from 'components/Page';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import OrganizationApi from 'services/api/OrganizationApi';
import ManageAddresses from './ManageAddresses';
import CheckIcon from '@material-ui/icons/Check';
import * as actions from 'store/actions';
import { AlertDialog, Card, SecondaryButton } from 'components/widgets';
import LoadingOrgDetails from './LoadingOrgDetails';
import PartnersDetailsPageSection from './ManagePartners/PartnersDetailsPageSection';
import { useKeycloak } from '@react-keycloak/web';
import { useUserPermissions } from 'utils/userPermissions';
import { ORG_TYPE, RESOURCE_NAMES } from 'shared/constants';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';
import ApplicationFullScreenDialog from 'components/widgets/FullScreenDialog/ApplicationFullScreenDialog';
import SelectSolutions from 'containers/SignUp/SelectSolutions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OrganizationDomain from './OrganizationDomain';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    margin: theme.spacing(2),
    fontSize: 28,
  },
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  solutionContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    padding: theme.spacing(1),
  },
  primaryDetails: {
    backgroundColor: '#F5F7F8',
    borderRadius: '8px',
    padding: '8px 12px',
  },
  domainPopup: {
    minHeight: '303px',
    minWidth: '732px',
  },
}));

function ReadonlyField({ label, value, valueStyle }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 8,
        marginRight: 24,
        gap: '4px',
      }}
    >
      {label && (
        <span style={{ color: '#344054', fontWeight: '600', fontSize: 16 }}>
          {label}
          {':'}
        </span>
      )}
      <span style={valueStyle}>{value}</span>
    </div>
  );
}

const CustomPopper = ({ anchorEl, open, handlePopOverClose, ...props }) => {
  return (
    <Popper open={open} anchorEl={anchorEl} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handlePopOverClose}>
              <MenuList autoFocusItem={open} id="menu-list">
                {props.children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const OrganizationDetailPage = ({ title }) => {
  const [updating, setUpdating] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [orgData, setOrgData] = useState({
  //   name: '',
  //   db: '',
  //   ein: '',
  //   typeCode: 'test',
  //   addresses: [],
  //   contacts: [],
  // });

  // const [selectedOrg, setSelectedOrg] = useState(null);

  const [alertDialog, setAlertDialog] = useState({ open: false });
  const classes = useStyles();
  const orgId = sessionStorage.getItem('orgId');
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();
  const {
    checkAdminPermission,
    checkITAdminPermission,
    checkSuperAdminPermission,
  } = useUserPermissions();
  const [openPopover, setOpenPopover] = useState(null);
  const [popOverAnchorEl, setPopOverAnchorEl] = useState(null);
  const [domainList, setDomaiList] = useState([]);
  const { info } = useSelector(state => state.userInfo);

  const selectedOrg = useSelector(state => state.selectedOrganization);

  const { loading: loadingOrg, details } = selectedOrg;

  const loading = updating || loadingOrg;

  const isITAdmin = checkITAdminPermission();
  const isSuperAdmin = checkSuperAdminPermission();

  const permissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'Organization Details',
    manageOrg: true,
  });

  const affiliatePermissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'Affiliate',
    manageOrg: true,
  });

  let readaffiliateAccesss = affiliatePermissions?.READ;
  let createaffiliateAccess = affiliatePermissions?.CREATE;
  let deleteaffiliateAccess = affiliatePermissions?.DELETE;

  useEffect(() => {
    dispatch(actions.fetchLocationStates('US'));
    dispatch(actions.fetchAddressTypes());
  }, [dispatch]);

  useEffect(() => {
    if (orgId) {
      const access = checkAdminPermission(orgId, RESOURCE_NAMES.ORG_APPROVER);
      setCanApprove(access);
    }
  }, [orgId, checkAdminPermission]);

  const fetchAllDomains = () => {
    OrganizationApi.getAllDomains({ orgId }).then(res => {
      setDomaiList(res);
    });
  };

  useEffect(() => {
    async function fetchDetails() {
      if (orgId) {
        fetchAllDomains();
        try {
          // setLoading(true);
          const response = await OrganizationApi.mphOrgCheck(orgId);
          if (response === true) {
            sessionStorage.setItem('MPH_ORG', true);
          } else {
            sessionStorage.setItem('MPH_ORG', isSuperAdmin ? true : false);
          }
        } catch (error) {
          console.log('error: ', error);
        }
      }
    }
    fetchDetails();
  }, [orgId]);

  const getOrgById = useCallback(
    id => {
      if (id) {
        dispatch(actions.fetchSelectedOrganization(id));
      }
    },
    [dispatch]
  );

  const onEditOrganization = org => {
    dispatch(actions.showSetupOrganization(org.id));
  };

  const onOrgDelete = () => {
    setAlertDialog({
      open: true,
      title: 'Delete organization',
      description: 'Are you sure, you want to delete this organization?',
      action: 'delete',
    });
  };

  const onOrgApprove = () => {
    setAlertDialog({
      open: true,
      title: 'Approve Organization',
      description: 'Are you sure, you want to approve this organization?',
      action: 'approve',
    });
  };

  const onDeleteOrgConfirm = async () => {
    try {
      // setLoading(true);
      await OrganizationApi.deleteOrganization(details.id);

      enqueueSnackbar('Organization deleted successfully', {
        variant: 'success',
      });

      dispatch(actions.fetchUserInfo(keycloak));

      history.push('/');
    } catch (error) {
      console.log('error: ', error);
      enqueueSnackbar('Something went wrong while deleting organization', {
        variant: 'error',
      });

      setAlertDialog({ open: false });
    }
  };

  const onApproveOrgConfirm = async () => {
    try {
      // setLoading(true);
      await OrganizationApi.approveOrganization(details.id);

      enqueueSnackbar('Organization approved successfully', {
        variant: 'success',
      });

      dispatch(actions.fetchUserInfo(keycloak));

      history.push('/');
    } catch (error) {
      console.log('error: ', error);
      enqueueSnackbar('Something went wrong while approving organization', {
        variant: 'error',
      });

      setAlertDialog({ open: false });
    }
  };

  const onDialogConfirm = () => {
    const { action } = alertDialog;

    if (action === 'delete') {
      onDeleteOrgConfirm();
    } else if (action === 'approve') {
      onApproveOrgConfirm();
    }
  };

  const onDialogClose = () => {
    // setSelectedOrg(null);
    setAlertDialog({ open: false });
  };

  const isOrgApproved = details.isApproved === 1;

  const handleClosePopup = () => {
    setDrawerOpen(false);
  };

  const handlePopover = type => event => {
    if (type === openPopover) {
      setOpenPopover();
      return;
    }
    setOpenPopover(type);
    setPopOverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setOpenPopover(null);

  let readAccess = permissions?.READ;
  let createAccess = permissions?.CREATE;
  let deleteAccess = permissions?.DELETE;
  return (
    <>
      <Page className={classes.root} title={title}>
        <Container>
          {loading ? (
            <LoadingOrgDetails />
          ) : (
            <>
              {(readAccess ||
                isSuperAdmin) && (
                <div className={classes.section}>
                  <div className={classes.sectionTitle}>
                    <b>Organization</b>
                  </div>
                  <Card className={classes.card}>
                    <Box className={classes.primaryDetails}>
                      <div className={classes.cardContent}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <ReadonlyField
                            value={details.name}
                            valueStyle={{ fontWeight: 'bold', fontSize: 20 }}
                          />
                        </div>

                        {(readAccess ||
                          isSuperAdmin) && (
                          <div>
                            <IconButton onClick={handlePopover('organization')}>
                              <MoreVertIcon />
                            </IconButton>
                            <CustomPopper
                              open={openPopover === 'organization'}
                              anchorEl={popOverAnchorEl}
                              handlePopOverClose={() => setOpenPopover(null)}
                            >
                              {(createAccess ||
                                isSuperAdmin) && (
                                <MenuItem
                                  onClick={() => {
                                    onEditOrganization(details);
                                    handlePopoverClose();
                                  }}
                                >
                                  Edit
                                </MenuItem>
                              )}
                              {(deleteAccess ||
                                isSuperAdmin) && (
                                <MenuItem
                                  onClick={() => {
                                    onOrgDelete();
                                    handlePopoverClose();
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              )}
                            </CustomPopper>
                          </div>
                        )}
                      </div>
                      <div style={{ display: 'flex' }}>
                        <ReadonlyField
                          label={`D&B`}
                          value={`${details.db.substr(
                            0,
                            2
                          )}-${details.db.substr(2, 3)}-${details.db.substr(
                            5
                          )}`}
                          valueStyle={{
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#667085',
                          }}
                        />
                        <ReadonlyField
                          label={LABELS_FIELDS_BUTTONS.EIN_NUMBER}
                          value={`${details.ein.substr(
                            0,
                            2
                          )}-${details.ein.substr(2)}`}
                          valueStyle={{
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#667085',
                          }}
                        />
                        <ReadonlyField
                          label={LABELS_FIELDS_BUTTONS.ORGANIZATION}
                          value={`${
                            ORG_TYPE.find(org => org.code === details.typeCode)
                              ?.name
                          }`}
                          valueStyle={{
                            fontWeight: 500,
                            fontSize: '16px',
                            color: '#667085',
                          }}
                        />
                      </div>
                    </Box>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: '18px',
                        color: '#344054',
                        marginTop: '15px',
                      }}
                    >
                      Domain details
                    </Typography>
                    <Box
                      display={'flex'}
                      style={{ margin: '12px 0px', gap: '10px' }}
                      flexWrap={'wrap'}
                    >
                      <OrganizationDomain
                        domainList={domainList}
                        orgId={orgId}
                        fetchAllDomains={fetchAllDomains}
                        isITAdmin={isITAdmin}
                        isSuperAdmin={isSuperAdmin}
                        hasDeletePermissions={permissions?.DELETE}
                        hasCreatePermissions={permissions?.CREATE}
                      />
                    </Box>
                  </Card>
                </div>
              )}

              {(readAccess ||
                isSuperAdmin) && (
                <Card className={classes.card}>
                  <ManageAddresses
                    orgId={orgId}
                    getOrgById={getOrgById}
                    loading={loading}
                    setLoading={setUpdating}
                    orgData={details}
                    createAccess={createAccess}
                  />
                </Card>
              )}

              {(readAccess ||
                isSuperAdmin) && (
                <div className={classes.section}>
                  <div
                    className={classes.sectionTitle}
                    style={{ fontSize: 22 }}
                  >
                    <b>Solutions</b>
                  </div>
                  <Card className={classes.card}>
                    <div className={classes.solutionContent}>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {details?.solutionNames
                          ?.filter(item => item !== '-')
                          ?.map(val => (
                            <Chip
                              size="medium"
                              label={val}
                              style={{
                                backgroundColor: '#FAFDFF',
                                borderColor: '#036FCB',
                                margin: '0px 5px 0px 5px',
                              }}
                              variant="outlined"
                            />
                          ))}
                      </div>

                      {(!isITAdmin || isSuperAdmin) && (
                        <div>
                          <Tooltip
                            title={
                              details?.solutionNames?.length > 0
                                ? 'Edit solutions'
                                : 'Add solutions'
                            }
                            placement="top"
                            arrow
                          >
                            {details?.solutionNames?.length > 0 ? (
                              <IconButton onClick={() => setDrawerOpen(true)}>
                                <EditIcon />
                              </IconButton>
                            ) : (
                              <Chip
                                variant="outlined"
                                color="primary"
                                size="small"
                                label="Add"
                                onClick={() => setDrawerOpen(true)}
                              />
                            )}
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              )}
            </>
          )}

          {(isOrgApproved &&
            (readaffiliateAccesss ||
            isSuperAdmin)) && (
            <div className={classes.section}>
              <div className={classes.sectionTitle} style={{ fontSize: 22 }}>
                <b>Affiliates</b>
              </div>
              <PartnersDetailsPageSection
                orgId={orgId}
                createaffiliateAccess={createaffiliateAccess}
                readaffiliateAccesss={readaffiliateAccesss}
                deleteaffiliateAccess={deleteaffiliateAccess}
              />
            </div>
          )}

          {!loading && (!isITAdmin || isSuperAdmin) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 24,
              }}
            >
              {!isOrgApproved && canApprove && (
                <SecondaryButton
                  startIcon={<CheckIcon />}
                  // size="small"
                  style={{ fontWeight: 'bold', marginLeft: 16 }}
                  onClick={() => onOrgApprove()}
                >
                  Approve
                </SecondaryButton>
              )}
            </div>
          )}

          {alertDialog.open && (
            <AlertDialog
              onClose={onDialogClose}
              onConfirm={onDialogConfirm}
              loading={loading}
              {...alertDialog}
            />
          )}
        </Container>
      </Page>

      {drawerOpen && (
        <ApplicationFullScreenDialog
          open={drawerOpen}
          onToggle={handleClosePopup}
        >
          <ApplicationFullScreenDialog.Content solutionBox={true}>
            <SelectSolutions
              edit={true}
              solutionNames={details?.solutionNames}
              handleClosePopup={handleClosePopup}
              refetchData={getOrgById}
              orgId={orgId}
              orgView={true}
            />
          </ApplicationFullScreenDialog.Content>
        </ApplicationFullScreenDialog>
      )}
    </>
  );
};

export default OrganizationDetailPage;
