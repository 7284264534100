import React from 'react';
import { Field } from 'react-final-form';
import {
  required as requiredField,
  composeValidators,
} from 'shared/validators';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    height: 90,
  },
}));

export default function FormSelectField({
  name,
  label,
  options,
  multiple = false,
  required,
  onValueChange,
  placeholder,
  disabled,
}) {
  const classes = useStyles();
  return (
    <Field
      name={name}
      validate={composeValidators(requiredField)}
      component="select">
      {({
        input: { name, value, onChange, ...otherProps },
        meta: { error, touched },
      }) => (
        <FormControl
          disabled={disabled}
          variant="outlined"
          size="small"
          required={required}
          error={error && touched}
          className={classes.formControl}>
          {label && <InputLabel id={name}>{label}</InputLabel>}
          <Select
            style={{color: (!value && '#949494' )}}
            labelId={name}
            id={`${name}-select`}
            value={value}
            displayEmpty
            label={label}
            multiple={multiple}
            onChange={ev => {
              if (onValueChange) {
                onValueChange(ev);
              }

              onChange(ev);
            }}
            {...otherProps}>
              <MenuItem disabled value='' style={{color: 'grey'}}>{placeholder}</MenuItem>
            {options.map(item => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {error && touched && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}
