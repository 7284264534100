import { useState, useCallback, useEffect } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
  Grid,
} from '@material-ui/core';
import Page from 'components/Page';
import { SecondaryButton, PrimaryButton } from 'components/widgets';
import SignUp from 'services/api/SignUp';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
  },

  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },

  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 40px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
}));

const SelectSolutions = ({
  signUpValues,
  setSignUp,
  changeStep,
  solutionNames,
  edit = false,
  handleClosePopup,
  refetchData,
  orgView = false,
  orgId,
  refetchGridData,
  setOffset,
}) => {
  const classes = useStyles();
  const [response, setResponse] = useState([]);
  const [editedSolutions, setEditedSolutions] = useState(solutionNames);
  const { enqueueSnackbar } = useSnackbar();
  const getSolutions = useCallback(() => {
    async function getSolutions() {
      try {
        const data = await SignUp.getAllSolutions();
        setResponse(data);
      } catch (e) {
        enqueueSnackbar('Something went wrong while fetching solutions', {
          variant: 'error',
        });
      }
    }
    getSolutions();
  }, [enqueueSnackbar]);

  useEffect(() => {
    getSolutions();
  }, [getSolutions]);

  const uniqueCategories = [
    ...new Set(response.map(item => item.category)),
  ].filter(category => category);

  const handleChange = event => {
    if (edit) {
      const index = editedSolutions?.indexOf(event.target.value);

      if (index === -1) {
        let payload = [...editedSolutions, event.target.value];
        setEditedSolutions(payload);
      } else {
        let payload = editedSolutions.filter(
          item => item !== event.target.value
        );
        setEditedSolutions(payload);
      }
    } else {
      const index = signUpValues?.solutionIds?.indexOf(event.target.value);

      if (index === -1) {
        let payload = {
          ...signUpValues,
          solutionIds: [...signUpValues?.solutionIds, event.target.value],
        };
        setSignUp(payload);
      } else {
        let payload = {
          ...signUpValues,
          solutionIds: signUpValues?.solutionIds?.filter(
            item => item !== event.target.value
          ),
        };
        setSignUp(payload);
      }
    }
  };

  const buttonClick = val => {
    let payload = {
      ...signUpValues,
    };
    setSignUp(payload);
    changeStep(val, payload);
  };

  const updateGridAndFlyoutData = () => {
    refetchData(null, orgId);
    refetchGridData('', 0);
    setOffset(0);
  };

  const saveEditedSolutions = async () => {
    const ids = response
      .filter(item => editedSolutions.includes(item?.solutionName))
      .map(item => item?.solutionId);
    try {
      await SignUp.editSolutions(orgId, ids);
      handleClosePopup();
      orgView ? refetchData(orgId) : updateGridAndFlyoutData();
    } catch {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Page className={classes.root}>
        <Typography style={{ margin: '24px 0px' }} variant="h5">
          Select the solution that you are interested in:
        </Typography>
        {uniqueCategories?.map(data => (
          <Box sx={{ mt: '20px' }}>
            <Typography variant="h5">{data}</Typography>
            <Grid spacing={3} style={{ display: 'flex', flexDirection: 'row' }}>
              {response?.length !== 0 &&
                response.filter(item => item.solutionName !== '-').map(item => {
                  if (item?.category === data) {
                    const parsedDescription = JSON.parse(item?.description);
                    const isSelected = edit
                      ? editedSolutions?.includes(item?.solutionName)
                      : signUpValues?.solutionIds?.includes(
                          item?.solutionId?.toString()
                        );
                    return (
                      <Grid item xs={4}>
                        <Box
                          key={item.id}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            // width: '100%',
                            height: '250px',
                            backgroundColor: isSelected ? '#F2F8FC' : '#fff',
                            border: isSelected ? '1px solid #036FCB' : '',
                            borderRadius: '12px',
                            // boxShadow: '2px 3px #E5E4E2',
                            padding: '16px 24px',
                            margin: '30px 30px 10px 0px',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              maxWidth: '900px',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={
                                        edit
                                          ? item?.solutionName
                                          : item?.solutionId
                                      }
                                      checked={isSelected}
                                      onChange={handleChange}
                                    />
                                  }
                                />
                                <img
                                  src={parsedDescription?.logo}
                                  height="20px"
                                  alt={parsedDescription?.logoAltText}
                                />
                                <Typography
                                  variant="h3"
                                  style={{
                                    marginLeft: '10px',
                                    fontSize: '20px',
                                  }}
                                >
                                  {item?.solutionName}
                                </Typography>
                              </div>
                              <SecondaryButton
                                target="_blank"
                                href={parsedDescription?.redirectionLink}
                              >
                                Learn more
                              </SecondaryButton>
                            </Box>
                            <div style={{ marginLeft: '45px' }}>
                              <Typography
                                style={{
                                  color: '#344054',
                                  fontSize: '15px',
                                  fontWeight: 400,
                                  marginTop: '5px',
                                  marginBottom: '20px',
                                }}
                              >
                                {parsedDescription.productDescription}
                              </Typography>
                              {(item?.features !== null ||
                                item?.features !== undefined) && (
                                <Box className={classes.chip}>
                                  {item?.features.map(item => (
                                    <Chip
                                      label={item?.featureName}
                                      variant="outlined"
                                      style={{
                                        backgroundColor: '#E2F1FC',
                                        border: '1px solid #036FCB',
                                        color: '#005DB9',
                                      }}
                                    />
                                  ))}
                                </Box>
                              )}
                            </div>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </Box>
        ))}
      </Page>
      <Box className={classes.footer}>
        {!edit && (
          <SecondaryButton onClick={() => buttonClick(1)}>Back</SecondaryButton>
        )}
        <PrimaryButton
          disabled={
            edit
              ? JSON.stringify(solutionNames.sort()) ===
                  JSON.stringify(editedSolutions.sort()) ||
                editedSolutions.length === 0
              : signUpValues?.solutionIds?.length === 0
          }
          onClick={() => {
            edit ? saveEditedSolutions() : buttonClick(3);
          }}
        >
          {edit ? 'Save' : 'Proceed'}
        </PrimaryButton>
      </Box>
    </>
  );
};

export default SelectSolutions;
