import { Drawer } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const style = theme => ({
  container: {
    zIndex: 1300,
  },
  innerContainer: {
    marginTop: '32px',
    backgroundColor: '#f8f8f8',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    top: '36px',
    right: '32px',
    cursor: 'pointer',
  },
  margin: {
    marginTop: '9px',
  },
  contentWrapper: {
    marginTop: '32px',
    width: '650px',
    backgroundColor: '#fff',
    borderRadius: '24px',
    position: 'relative',
  },
  onCloseWrapper: {
    position: 'absolute',
    top: '16px',
    right: '32px',
    cursor: 'pointer',
  },
  childrenWrapper: {
    marginTop: '48px',
    marginLeft: '32px',
    marginRight: '32px',
  },
});

const BottomDrawerComponent = ({ open, onClose, children, ...props }) => {
  const cls = style();
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
      onClose={onClose}
      sx={{ height: '600px' }}>
      <div style={cls.contentWrapper} {...props}>
        <div onClick={() => onClose()} style={cls.onCloseWrapper}>
          <CloseIcon />
        </div>
        <div style={cls.childrenWrapper}>{children}</div>
      </div>
    </Drawer>
  );
};

export default BottomDrawerComponent;
