import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuCard from './MenuCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const styles = {
  TabContainer: {
    backgroundColor: '#F5F5F5',
    minHeight: '100%',
    width: '95%',
    position: 'relative',
  },

  TabHeading: {
    fontSize: 18,
    fontWeight: 600,
    width: '100%',
    textTransform: 'none',
    letterSpacing: '0px',
    color: '#667085',
  },
  cardContainer: {
    minHeight: '100%',
    flex: 1,
    flexWrap: 'wrap',
  },
  TabsWrapper: { borderBottom: '2px solid #D9D9D9', height: 62 },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 0, md: 3 } }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  // getTabIndexClicked,
  // defaultTabValue = 0,
}) {
  const [value, setValue] = useState(0);
  const history = useHistory(); 
  
  // useEffect(() => {
  //   setValue(defaultTabValue);
  // }, [defaultTabValue]);

  const TabsVal = [
    {
      services: [
        {
          title: "Conditions",
          pwaIcon: "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/conditions/conditions-web.svg",
          link: "/conditions",
          type: "MEDICAL"
        },
        {
          title: "Procedures",
          pwaIcon: "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/procedures/procedures-web.svg",
          link: "/procedures",
          type: "MEDICAL"
        },
        {
          "title": "Lab results",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/labresults/labresults-web.svg",
          "link": "/labs",
          "type": "MEDICAL"
        },
        {
          "title": "Medications",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/medications/medications-web.svg",
          "link": "/medications",
          "type": "MEDICAL"
        },
        {
          "title": "Allergies",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/allergies/allergies-web.svg",
          "link": "/allergies",
          "type": "MEDICAL"
        },
        {
          "title": "Immunizations",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/immunizations/immunizations-web.svg",
          "link": "/immunizations",
          "type": "MEDICAL"
        },
        // {
        //   "title": "Vitals",
        //   "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/vitals/vitals-web.svg",
        //   "link": "/vitals",
        //   "type": "MEDICAL"
        // },
        {
          "title": "Visits",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/visits/visits-web.svg",
          "link": "/visits",
          "type": "MEDICAL"
        },
        {
          "title": "Social history",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/socialhistory/socialhistory-web.svg",
          "link": "/smoking_status",
          "type": "MEDICAL"
        },
        {
          "title": "Family history",
          "pwaIcon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/familymemberhistory/familymemberhistory-web.svg",
          "link": "/family_member_history",
          "type": "MEDICAL"
        }
      ]
    },
    {
      services: [
        {
          title: "Claims",
          pwaIcon: "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/claims/claims-web.svg",
          link: "/claims",
          type: "FINANCIAL"
        },
      ]
    },
    {
      services: [
        {
          title: "Care plans",
          pwaIcon: "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careplan/careplan-web.svg",
          link: "/care_plan",
          type: "CARE"
        },
        {
          title: "Care teams",
          pwaIcon: "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careteam/careteam-web.svg",
          link: "/care_team",
          type: "CARE"
        }
      ]
    },
    {
      services: [
        {
          title: "Documents",
          pwaIcon: "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/assessments/assessment-web.svg",
          link: "/documents",
          type: "DOCUMENTS"
        }
      ]
    }
  ];

  const TabHeading = ["Medical", "Financial", "Care", "Documents"];

  const checkHeaderIndex = (indexOf) => {
    let indexOfCat = null;
    for (const a in TabsVal) {
      if (TabsVal[a]?.category?.toLowerCase() === indexOf) {
        indexOfCat = a;
        break;
      }
    }
    if (!indexOfCat && (indexOf === 'financial')) {
      indexOfCat = 1;
    }
    if (!indexOfCat && (indexOf === 'medical')) {
      indexOfCat = 0;
    }
    if (!indexOfCat && (indexOf === 'care')) {
      indexOfCat = 2;
    }
    if (!indexOfCat && indexOf === 'documents') {
      indexOfCat = 3;
    }
    return { indexOfCat };
  };

  const handleChange = (event, newValue) => {
    switch (event.target.innerText) {
      case 'Medical':
        localStorage.setItem('tabIndex', 0);
        setValue(0);
        break;
      case 'Financial':
        const { indexOfCat: indexFin } = checkHeaderIndex('financial');
        setValue(Number(indexFin));
        localStorage.setItem('tabIndex', indexFin);
        break;
      case 'Care':
        const { indexOfCat: indexCare } = checkHeaderIndex('care');
        localStorage.setItem('tabIndex', indexCare);
        setValue(Number(indexCare));
        break;
      case 'Legal':
        const { indexOfCat: indexLegal } = checkHeaderIndex('legal');
        localStorage.setItem('tabIndex', indexLegal);
        setValue(Number(indexLegal));
        break;
      case 'Documents':
        const { indexOfCat: indexDocuments } = checkHeaderIndex('documents');
        localStorage.setItem('tabIndex', indexDocuments);
        setValue(Number(indexDocuments));
        break;
    }
  };

  const orgId = sessionStorage.getItem("orgId")

  const onServiceClick = (link) => {
    const fullPath = `/organization/${orgId}/members/records${link}`;
    history.push(fullPath);
  };

  return (
    <Box style={styles.TabContainer} sx={{ marginLeft: { xs: 0, md: '32px' } }}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
        style={styles.TabsWrapper}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          // textColor="default"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#00A0DF',
              height: 4,
              borderRadius: '100px 100px 0px 0px',
              width: 70,
            },
          }}
          style={{ paddingTop: 15, marginLeft: 0 }}
        >
          {TabHeading?.map((heading, index) => {
            return (
              <Tab
                key={index}
                sx={{ width: { xs: '20px', md: '160px' }, paddingLeft: 0, textAlign: 'unset' }}
                // onClick={() => getTabIndexClicked && getTabIndexClicked(value)}
                label={
                  <span
                    style={
                      index === value
                        ? { ...styles.TabHeading, color: ' #101828' }
                        : { ...styles.TabHeading, fontWeight: 500 }
                    }
                  >
                    {heading}
                  </span>
                }
                {...a11yProps(value)}
              />
            );
          })}
        </Tabs>
      </Box>
      {TabsVal?.map((content, index) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            <Box
              style={styles.cardContainer}
              sx={{ paddingTop: { xs: '24px', md: '40px' }, display: { sm: 'block', md: 'flex' } }}
            >
              {content.services.map(
                ({ title, pwaIcon, link, type,/* ingestionStatus, isButton, buttonText*/ }, i) => (
                  <Box
                    key={i}
                    sx={{
                      marginBottom: { xs: '16px', md: '32px' },
                      marginRight: { sm: '16px', md: '32px' },
                    }}
                  >
                    <MenuCard
                      caption={title}
                      icon={pwaIcon}
                      link={link}
                      onServiceClick={() => onServiceClick(link)}
                      serviceType={type}
                      // ingestionStatus={ingestionStatus}
                      // isButton={isButton ?? false}
                      // buttonText={buttonText ?? ''}
                    />
                  </Box>
                ),
              )}
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
}
