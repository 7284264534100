import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    // border: '1px solid #ccc',
    borderBottom: 'none',
    height: 42,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: '#f8f8f8',
  },
}));

export default function GridHeaderActions({ children, ...otherProps }) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...otherProps}>
      {children}
    </div>
  );
}
