import { Form } from 'react-final-form';
import {
  FooterActions,
  FormTextField,
  FormTextAreaField,
  FormEmailField,
  FormQueryLimitField,
  SimpleBackdrop,
  FormTextFieldWithAddDelt,
} from 'components/widgets';
import { useEffect, useState, useRef, useCallback } from 'react';
import ApplicationApi from 'services/api/ApplicationApi';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as actions from 'store/actions';
import arrayMutators from 'final-form-arrays';
import ApplicationFullScreenDialog from 'components/widgets/FullScreenDialog/ApplicationFullScreenDialog';
import { makeStyles } from '@material-ui/styles';
import AddResource from './AddResource';
import DataPartnersApi from 'services/api/DataPartnersApi';
import StepperCustom from 'components/widgets/StepperComponent/NewStepper';
import { Questionnaire } from './Questionnaire';
import PartnerApi from 'services/api/PartnerApi';
import { getDomainName, useGetAppTypeBasedOnSolution } from 'shared/utility';

const initialValues = {
  name: '',
  desc: '',
  supportEmail: '',
  privacyLink: '',
  termsOfServiceLink: '',
  appQueryLimit: '',
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  removeHeight: {
    height: 'auto !important',
    borderRadius: '4px',
    marginTop: '0px!important',
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 30px',
    marginTop: 'auto',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  resourceContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  customInputField: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
  },
  customAreaField: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    padding: '10px !important',
  },
  removePadding: {
    '& :nth-child(1)': {
      padding: '0px',
    },
  },
  URLContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'baseline',
  },
  appEnvContainer: {
    backgroundColor: '#E2F1FC',
    borderRadius: '4px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    gap: '5px',
    padding: '4px 8px',
    width: 'fit-content',
    marginTop: '10px',
  },
  environmentTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  environment: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054',
  },
  stepperContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  activeStep: {
    '& .MuiStepLabel-active': {
      color: '#036FCB',
    },
  },
}));

const APPLICATION_ENV_OPTIONS = [
  {
    label: 'Sandbox',
    value: 'sandbox',
  },
  {
    label: 'Production',
    value: 'production',
  },
];

const PROD_APP_STEPS = ['Application details', 'Questionnaire'];

function SaveApplicationFlyoutContent({
  onToggle,
  orgId,
  affiliateId,
  applicationData,
  onCreateSuccess,
  userId,
  onCloseApplicationCreation,
  isProd,
  isProdUpdate,
  getViewDetails,
}) {
  const [data, setData] = useState({
    ...initialValues,
    userId,
    orgId: Number(orgId),
    sandboxStatus: 'pending',
    productionStatus: null,
    redirectUrl: [null],
    domainUrl: [null],
    env: APPLICATION_ENV_OPTIONS[0].value,
    questionAnsJson: null,
    isActive: 1,
    isDeleted: 0,
    affiliateId: null,
  });

  const [affiliates, setAffiliates] = useState();
  const [appType, setAppType] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [checkAppType, setCheckAppType] = useState(false);
  const [disableAffSelection, setDisableAffSelection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const questionnaireRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const handleSaveResourceRef = useRef();
  const appTypeObj = useGetAppTypeBasedOnSolution();

  const getApplicationTypes = useCallback(() => {
    async function getAppTypes() {
      try {
        const details = await ApplicationApi.getApplicationTypes(orgId);
        const data = details.map(item => ({ id: item.id, name: item.name }));
        setAppType(data);
        setCheckAppType(true);
      } catch (e) {
        enqueueSnackbar(
          'Something went wrong while fetching application types',
          { variant: 'error' }
        );
      }
    }
    getAppTypes();
  }, [orgId, enqueueSnackbar]);

  const getAllAffiliates = useCallback(() => {
    async function getAffiliates() {
      try {
        const results = await DataPartnersApi.getAllPartners(orgId);
        const response = results.filter(item => item.isDeleted !== 1);
        const data = response.map(fItem => ({
          id: fItem.id,
          name: fItem.name,
        }));
        setAffiliates(data);
      } catch (error) {
        enqueueSnackbar('Something went wrong while fetching affiliates', {
          variant: 'error',
        });
      }
    }
    getAffiliates();
  }, [enqueueSnackbar, orgId]);

  const getPartnerConfigs = useCallback(() => {
    async function getDetails() {
      try {
        const response = await PartnerApi.partnerConfiguration(
          getDomainName(window.location.href)
        );
        setSolutions(response.configs.listOfSolutionNames);
        setCheckAppType(true);
      } catch (e) {
        console.log(e);
      }
    }
    getDetails();
  }, []);

  useEffect(() => {
    if (orgId) {
      getApplicationTypes();
      getAllAffiliates();
    }
  }, [orgId, getApplicationTypes, getAllAffiliates]);

  useEffect(() => {
    getPartnerConfigs();
  }, [getPartnerConfigs]);

  useEffect(() => {
    if (applicationData) {
      const tempApplicationData = structuredClone(applicationData);
      if (typeof tempApplicationData?.domainUrl === 'string') {
        tempApplicationData.domainUrl = [tempApplicationData?.domainUrl];
      }
      if (
        tempApplicationData?.redirectUrl === null ||
        tempApplicationData?.redirectUrl?.length === 0
      ) {
        tempApplicationData.redirectUrl = [null];
      }
      if (tempApplicationData?.affiliate?.id >= 0) {
        tempApplicationData.affiliateId = tempApplicationData.affiliate.id;
      }
      if (
        !isProd &&
        tempApplicationData?.sandboxStatus?.toLowerCase() === 'denied'
      ) {
        tempApplicationData.sandboxStatus = 'pending';
      }
      tempApplicationData.productionStatus = isProd ? 'pending' : null;

      tempApplicationData.env = isProd
        ? APPLICATION_ENV_OPTIONS[1].value
        : APPLICATION_ENV_OPTIONS[0].value;
      setData(tempApplicationData);
    }
  }, [applicationData]);

  const onSave = values => {
    let resourceIDs =
      typeof handleSaveResourceRef.current === 'function' &&
      handleSaveResourceRef.current();

    if (resourceIDs === false) return;

    if (activeStep === 0 && isProd) {
      // to move to questionnare step for production app
      setActiveStep(1);
      return;
    }

    setLoading(true);

    let payload = {
      ...values,
      affiliate: { id: values.affiliateId },
      resourceIds: resourceIDs || [''],
      questionAnsJson: questionnaireRef?.current ?? null,
    };
    delete payload.affiliateId;
    disableAffSelection && delete payload.affiliate;
    if (
      payload.domainUrl?.[0] === null ||
      typeof payload.domainUrl?.[0] === 'undefined'
    ) {
      payload.domainUrl = [''];
    }

    let savePromise =
      applicationData && !isProd
        ? ApplicationApi.updateApplication
        : ApplicationApi.createApplication;

    if (isProd) {
      if (isProdUpdate) {
        savePromise = ApplicationApi.updateApplication;
      } else {
        payload.applicationEnvId = null;
      }
    }

    savePromise(orgId, payload)
      .then(response => {
        if (typeof getViewDetails === 'function') {
          getViewDetails(applicationData?.orgId, applicationData?.id);
        }
        dispatch(
          actions.fetchApplications({
            _orgId: orgId,
            offset: 0,
            limit: 10,
            search: '',
            sandboxStatus: '',
            productionStatus: '',
          })
        );

        if (!applicationData) {
          onCreateSuccess(orgId, affiliateId);
        }

        onToggle(true);

        const message = applicationData
          ? 'Application updated successfully'
          : 'Application has been created';
        enqueueSnackbar(message, {
          variant: 'success',
        });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar(
          err.response?.data?.details
            ? err.response?.data?.details
            : 'Something went wrong while saving application',
          {
            variant: 'error',
          }
        );
      });
  };

  const filterAppTypes = () => {
    let newAppListing = [];

      if (appTypeObj.xchangeAccess) {
        newAppListing.push(
          ...appType.filter(item => item.name === 'bulk export')
        );
      }
      if (appTypeObj.complyAccess) {
        newAppListing.push(
          ...appType.filter(item => item.name === 'patient access')
        );
      }
      if (appTypeObj.connectAccess) {
        newAppListing.push(...appType.filter(item => item.name === 'mph fhir'));
      }
      if (
        appTypeObj.connectAccess === false &&
        appTypeObj.nexusAccess === true
      ) {
        newAppListing.push(...appType.filter(item => item.name === 'mph fhir'));
      }

      // for (const x of solutions) {
      //   if (x === 'FHIR Open API') {
      //     newAppListing.push(...appType.filter(item => item.name === 'mph fhir'));
      //   } else if (x === 'comply') {
      //     newAppListing.push(
      //       ...appType.filter(item => item.name === 'patient access')
      //     );
      //   } else if (x === 'xchange') {
      //     newAppListing.push(
      //       ...appType.filter(item => item.name === 'bulk export')
      //     );
      //   }
      // }

      setAppType(newAppListing);
      setCheckAppType(false);
    }; 

  useEffect(() => {
    if (checkAppType) {
      filterAppTypes();
    }
  }, [checkAppType]);

  // const onAppTypeChange = event => {
  //   let selectedType = appType.filter(item => item.id === event.target.value)[0]
  //     .name;
  //   if (selectedType === 'mph fhir') {
  //     setDisableAffSelection(true);
  //   } else {
  //     setDisableAffSelection(false);
  //   }
  // };

  return (
    <>
      <Form
        onSubmit={onSave}
        initialValues={data}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          pristine,
          form: {
            mutators: { push },
          },
        }) => (
          <form noValidate onSubmit={handleSubmit} autoComplete="off">
            <ApplicationFullScreenDialog.Content>
              <Grid container spacing={2}>
                {isProd && (
                  <>
                    <Grid item xs={6}>
                      <Box className={classes.stepperContainer}>
                        <StepperCustom
                          activeStep={activeStep}
                          steps={PROD_APP_STEPS}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          activeLabelStyle={classes.activeStep}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </>
                )}
                {activeStep === 1 ? (
                  <Questionnaire questionnaireRef={questionnaireRef} />
                ) : (
                  <>
                    <Grid item xs={6}>
                      <Box className={classes.appEnvContainer}>
                        <Typography className={classes.environmentTitle}>
                          Environment:
                        </Typography>
                        <Typography className={classes.environment}>
                          {isProd ? 'Production' : 'Sandbox'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.formTitle}>
                        Application type
                      </Typography>
                      <FormQueryLimitField
                        name="applicationTypeId"
                        placeholderText="Choose application type"
                        required={true}
                        options={appType}
                        disabled={isProd}
                        // onValueChange={onAppTypeChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {disableAffSelection ? (
                        <>
                          <Typography className={classes.formTitle}>
                            Query limit
                          </Typography>
                          <FormQueryLimitField
                            name="appQueryLimit"
                            label="Application Query Limit (Calls per day)"
                            placeholderText="Select query limit"
                            required={false}
                          />
                        </>
                      ) : (
                        <>
                          <Typography className={classes.formTitle}>
                            Choose affiliate
                          </Typography>
                          <FormQueryLimitField
                            name="affiliateId"
                            placeholderText="Choose an affiliate"
                            required={true}
                            options={affiliates || null}
                            disabled={isProd}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.formTitle}>
                        Application name
                      </Typography>
                      <FormTextField
                        name="name"
                        placeholder="Application name"
                        dataTestId="app-name"
                        customClass={classes.removeHeight}
                        customInputFieldStyles={classes.customInputField}
                        disabled={isProd}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.formTitle}>
                        Terms of service link
                      </Typography>
                      <FormTextField
                        name="termsOfServiceLink"
                        placeholder="Add terms of service link"
                        trim
                        dataTestId={'app-service-link'}
                        customClass={classes.removeHeight}
                        customInputFieldStyles={classes.customInputField}
                        disabled={isProd}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.formTitle}>
                        Email id
                      </Typography>
                      <FormEmailField
                        name="supportEmail"
                        placeholder="Enter email id"
                        dataTestId={'app-support-email'}
                        customClass={classes.removeHeight}
                        customInputFieldStyles={classes.customInputField}
                        disabled={isProd}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.formTitle}>
                        Privacy link
                      </Typography>
                      <FormTextField
                        name="privacyLink"
                        placeholder="Add privacy link"
                        trim
                        dataTestId={'app-privacy-link'}
                        customClass={classes.removeHeight}
                        customInputFieldStyles={classes.customInputField}
                        disabled={isProd}
                      />
                    </Grid>
                    {!disableAffSelection && (
                      <Grid item xs={6}>
                        <Typography className={classes.formTitle}>
                          Query limit
                        </Typography>
                        <FormQueryLimitField
                          name="appQueryLimit"
                          label="Application Query Limit (Calls per day)"
                          placeholderText="Select query limit"
                          required={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography className={classes.formTitle}>
                        Domain URL
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        className={classes.URLContainer}
                      >
                        <FormTextFieldWithAddDelt
                          addElement={push}
                          fieldName="domainUrl"
                          placeholder="Ex: https://www.domain.com"
                          required={true}
                          dataTestId={'authorized-domain'}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.formTitle}>
                        Re directional URL
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        className={classes.URLContainer}
                      >
                        <FormTextFieldWithAddDelt
                          addElement={push}
                          fieldName="redirectUrl"
                          multiplePlaceholder={`Ex: https://www.domain.com`}
                          required={true}
                          dataTestId={'redirection-url'}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.formTitle}>
                        Description
                      </Typography>
                      <FormTextAreaField
                        name="desc"
                        placeholder="Add a description"
                        dataTestId={'app-description'}
                        maxLength={100}
                        customInputFieldStyles={classes.customAreaField}
                        customClass={classes.removePadding}
                        minRows={6}
                        disabled={isProd}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.resourceContainer}>
                        <AddResource
                          appID={applicationData?.applicationEnvId}
                          onSaveResource={handleSaveResourceRef}
                          readOnly={isProd}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </ApplicationFullScreenDialog.Content>

            <ApplicationFullScreenDialog.Footer
            // styles={
            //   activeStep === 1 ? { position: 'absolute', bottom: 0 } : {}
            // }
            >
              <FooterActions
                saveLabel={
                  isProd && activeStep === 0
                    ? 'Proceed'
                    : applicationData
                    ? 'Update'
                    : 'Create'
                }
                type="submit"
                onToggle={onCloseApplicationCreation}
                disabled={applicationData ? false : pristine || loading}
              />
            </ApplicationFullScreenDialog.Footer>
          </form>
        )}
      />
      <SimpleBackdrop open={loading} />
    </>
  );
}

export default SaveApplicationFlyoutContent;
