import { Component } from 'react';
import { DataGrid } from 'components/widgets';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

class LogsGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Email',
        field: 'userName',
        width: 210,
        maxWidth: 210,
      },

      { headerName: 'User activity', field: 'log', cellRenderer: 'Email'},

      {
        headerName: 'Activity name',
        field: 'moduleName',
        width: 200,
        maxWidth: 200
      },
      // {
      //   headerName: 'Module code',
      //   field: 'moduleCode',
      //   cellRenderer: 'Email',
      // },

      {
        headerName: 'Activity type',
        field: 'userActivity',
        width: 140,
        maxWidth: 140
      },
      {
        headerName: 'Logged at',
        field: 'CreatedAt',
        cellRenderer: 'DateFormatter',
        width: 140,
        maxWidth: 140
      },
    ],

    defaultColDef: {
      flex: 1,
      sortable: false,
      resizable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        let formattedDate = moment(rowData.value).format('MM/DD/YYYY');
        return formattedDate;
      },
      Email: rowData => {
        if (rowData.value) {
          return (
            <Tooltip title={rowData.value} arrow placement="bottom">
              <span>{rowData.value}</span>
            </Tooltip>
          );
        } else {
          return '-';
        }
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.props.onView(data);
  }

  onDeleteButton(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default LogsGrid;
