import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles({
  redDay: {
    color: 'red',
  },
  datePickerWrapper: {
    position: 'relative',
    width: '100%',
    marginTop: '15px'
  },
  textField: {
    width: '100%',
  },
  input: {
    padding: '10.5px 14px', 
  },
  calendarIcon: {
    padding: 0,
  },
});

const CustomDatePicker = ({ selectedDate, onChange, minDate, maxDate }) => {
  const classes = useStyles();

  const isDateOutsideRange = (date) => {
    return date < new Date(minDate) || date > new Date(maxDate);
  };

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom": (date) =>
        isDateOutsideRange(date),
    },
  ];

  return (
    <div className={classes.datePickerWrapper}>
      <DatePicker
        selected={selectedDate}
        placeholderText="mm/dd/yyyy"
        onChange={(date) => onChange(date)}
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        highlightDates={highlightWithRanges}
        dayClassName={(date) =>
          isDateOutsideRange(date) ? classes.redDay : undefined
        }
        customInput={
          <TextField
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.input },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton className={classes.calendarIcon}>
                    <CalendarTodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      />
    </div>
  );
};

export default CustomDatePicker;
