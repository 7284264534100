import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <rect width="32" height="32" rx="8" fill="#F64254" />
      <path
        d="M16.0013 9.00977C12.3138 9.00977 9.34505 11.9785 9.34505 15.666C9.34505 19.3535 12.3138 22.3223 16.0013 22.3223C19.6888 22.3223 22.6576 19.3535 22.6576 15.666C22.6576 11.9785 19.6888 9.00977 16.0013 9.00977ZM16.0013 16.3223C15.6263 16.3223 15.3451 16.041 15.3451 15.666V13.0098C15.3451 12.6348 15.6263 12.3223 16.0013 12.3223C16.3763 12.3223 16.6576 12.6348 16.6576 13.0098V15.666C16.6576 16.041 16.3763 16.3223 16.0013 16.3223ZM16.6576 19.0098H15.3451V17.666H16.6576V19.0098Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Icon;
