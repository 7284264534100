import { Component } from 'react';
import { Card, DataGrid, SecondaryButton } from 'components/widgets';
import ActionsCellTemplate from 'components/widgets/DataGrid/ActionsCellTemplate';
import RecordClickCellRenderer from 'components/widgets/DataGrid/RecordClickCellRenderer';
import { Typography, Box } from '@material-ui/core';

class PriceGrid extends Component {
  state = {
    columnDefs: [
      {
        field: 'arnId',
        hide: true,
      },
      {
        field: 'fileDisplayName',
        headerName: 'File name',
        cellRenderer: 'NameRenderer',
      },
      {
        field: 'fileDisplayType',
        headerName: 'File type',
      },
      // {
      //   field: 'file',
      //   headerName: 'File',
      //   cellRenderer: 'NameRenderer',
      // },
      {
        field: 'fileUploadedDate',
        headerName: 'Uploaded date',
      },
      {
        field: 'actions',
        headerName: '',
        width: 110,
        maxWidth: 110,
        pinned: 'right',
        cellRenderer: 'ActionsRenderer',
        resizable: false,
        sortable: false,
        filter: false,
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: true,
    },
    frameworkComponents: {
      ActionsRenderer: props => (
        <ActionsCellTemplate
          {...props}
          onEdit={item => this.onItemEdit(item)}
          onDelete={item => this.onItemDelete(item)}
          allowEdit
        />
      ),
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      NameRenderer: rowData => (
        <RecordClickCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
        />
      ),
    },
    context: { componentParent: this },
  };

  onItemEdit(data) {
    this.props.onEdit(data);
  }

  onItemDelete(data) {
    this.props.onDelete(data);
  }

  onItemView(data) {
    this.props.onDownload(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div style={{ margin: 16, marginTop: 40 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            margin: 16,
            marginTop: 40,
            marginLeft: 16,
            marginRight: 16,
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            style={{ marginBottom: 8, fontWeight: 'bold' }}
          >
            {this.props.gridType}
          </Typography>
            <SecondaryButton
              onClick={this.props.PriceListOnClick}
              hasPermissions={this.props.createAccess || this.props.isSuperAdmin}
            >
              {this.props.buttonTitle}
            </SecondaryButton>
        </Box>
        {rowData.length > 0 ? (
          <Box style={{ marginLeft: '16px', marginRight: '16px' }}>
            <DataGrid
              // rowSelection="single"
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={rowData}
              frameworkComponents={frameworkComponents}
              context={this.state.context}
              style={{
                height:
                  rowData.length > 0
                    ? 'calc(100vh - 500px)'
                    : 'calc(100vh - 600px)',
              }}
              hideHeaderActions
              headerHeight={rowData.length > 0 ? 46 : 0}
            />
          </Box>
        ) : (
          <Card
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: '16px',
              justifyContent: 'center',
              height: '100px',
            }}
          >
            No rows to show
          </Card>
        )}
      </div>
    );
  }
}

export default PriceGrid;
