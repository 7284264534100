import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { AttributeNameInfo } from './attributeDetails/AttributeNameInfo';
import OrgListDetail from './attributeDetails/OrgListDetail';
import { makeStyles } from '@material-ui/core/styles';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import { Typography, Paper, Box } from '@material-ui/core';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  typo: {
    color: '#344054',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  icon: {
    height: '12px',
    width: '8px',
    marginRight: 1,
    marginLeft: 2,
    marginTop: '5px',
  },
  table: {
    minWidth: 650,
  },
  tableCell: { fontWeight: 600 },
}));

const ResourceDetail = ({ attriDetails }) => {
  const classes = useStyles();

  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Box style={{ marginRight: '120px' }}>
            <Typography style={{ color: '#667085' }}>
              Type of attribute
            </Typography>
            <Typography style={{ marginTop: '7px', fontWeight: 500 }}>
              {attriDetails?.type || '-'}
            </Typography>
          </Box>
          {attriDetails?.type === 'Non-FHIR' ? (
            <Box>
              <Typography style={{ color: '#667085' }}>Data source</Typography>
              <Typography style={{ marginTop: '7px', fontWeight: 500 }}>
                {attriDetails?.details?.dataSource || '-'}
              </Typography>
            </Box>
          ) : (
            <Box style={{ marginRight: '120px' }}>
              <Typography style={{ color: '#667085' }}>
                FHIR resource
              </Typography>
              <Typography style={{ marginTop: '7px', fontWeight: 500 }}>
                {attriDetails?.details?.resource || '-'}
              </Typography>
            </Box>
          )}
          {attriDetails?.type !== 'Non-FHIR' && (
            <Box>
              <Typography style={{ color: '#667085' }}>
                FHIR component
              </Typography>

              <Typography style={{ marginTop: '7px', fontWeight: 500 }}>
                {attriDetails?.details?.component || '-'}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </DrawerBoxComponent>
  );
};

const GenderDetail = ({ attriDetails }) => {
  const classes = useStyles();

  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box>
          <Typography
            style={{ marginBottom: '10px', fontWeight: 600 }}
            variant="h5">
            List of values
          </Typography>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {attriDetails?.details?.value.map(val => {
              return (
                <Box
                  style={{
                    borderRadius: '4px',
                    padding: '4px 8px',
                    backgroundColor: '#E4E7EC',
                    marginRight: '16px',
                    marginBottom: '8px',
                  }}>
                  <Typography style={{ fontWeight: 500 }}>
                    {val.displayValue}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </DrawerBoxComponent>
  );
};

const QueryDetails = ({ attriDetails }) => {
  const classes = useStyles();

  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box>
          <Typography
            style={{ marginBottom: '10px', fontWeight: 600 }}
            variant="h5">
            Query
          </Typography>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography style={{ fontWeight: 400, color: '#344054' }}>
              {attriDetails?.details?.query}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </DrawerBoxComponent>
  );
};

const ParameterDetails = ({ attriDetails }) => {
  const classes = useStyles();

  return (
    <DrawerBoxComponent>
      {attriDetails?.details?.parameters?.length !== 0 && (
        <Paper
          elevation={0}
          className={classes.paper}
          style={{
            display: 'flex',
            borderRadius: '8px',
            margin: 16,
            marginRight: '40px',
          }}>
          <Box>
            <Typography
              style={{ marginBottom: '10px', fontWeight: 600 }}
              variant="h5">
              Parameter
            </Typography>
            <Box>
              <TableContainer
                component={Paper}
                style={{
                  maxWidth: 740,
                  borderRadius: '16px',
                  backgroundColor: '#F5F5F5',
                }}
                elevation={0}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Parameter</TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Data type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attriDetails.details.parameters.map(val => (
                      <TableRow>
                        {' '}
                        <TableCell component="th" scope="row">
                          {val.name}{' '}
                        </TableCell>
                        <TableCell align="left">{val.dataType} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Paper>
      )}
    </DrawerBoxComponent>
  );
};

const AttributeDetail = ({ attriDetails, orgList, handleEdit }) => {
  return (
    <>
      <Grid
        container
        direction="column"
        style={{ backgroundColor: '#F5F5F5' }}
        data-testid="attributeDetail">
        <Grid item>
          <AttributeNameInfo
            attriDetails={attriDetails}
            handleEdit={handleEdit}
          />
        </Grid>
        <Grid item>
          <ResourceDetail attriDetails={attriDetails} />
        </Grid>
        {attriDetails?.type !== 'Non-FHIR' &&
          attriDetails?.details?.value.length !== 0 && (
            <Grid item>
              <GenderDetail attriDetails={attriDetails} />
            </Grid>
          )}
        {attriDetails?.type !== 'Non-FHIR' && (
          <Grid item>
            <OrgListDetail orgList={orgList} />
          </Grid>
        )}
        {attriDetails?.type === 'Non-FHIR' && (
          <Grid item>
            <QueryDetails attriDetails={attriDetails} />
          </Grid>
        )}
        {attriDetails?.type === 'Non-FHIR' && (
          <Grid item>
            <ParameterDetails attriDetails={attriDetails} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AttributeDetail;
