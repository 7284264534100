import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  IconButton,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink, useParams } from 'react-router-dom';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SandboxPageApi from 'services/api/SandboxPageApi';
import DataPartnersApi from 'services/api/DataPartnersApi';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '24px',
    fontWeight: 600,
    color: 'black',
  },
  subHeading: {},
  contentLink: {
    fontSize: '18px',
    fontWeight: 400,
    listStyle: 'disc',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  contentDetails: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#667085',
  },
  contentListHeading: {
    fontSize: '20px',
    fontWeight: 500,
    listStyle: 'decimal',
    color: 'black',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '10px 0px',
  },
  contentSummary: {
    lineHeight: 1.6,
    fontWeight: 400,
    margin: '5px',
    fontSize: '16px',
    color: '#667085',
  },
  scrollToTop: {
    position: 'absolute',
    bottom: '2%',
    right: '2%',
  },
  imgContainer: {
    border: '1px solid black',
    maxWidth: '50%',
    maxHeight: '50%',
    margin: '5px',
  },
  codeBlock: {
    padding: '16px',
    overflow: 'auto',
    direction: 'ltr',
    maxWidth: 'calc(100vw - 50px)',
    borderRadius: '4px',
    backgroundColor: '#272c34',
    margin: '20px 20px 20px 0px',
    '& code': {
      backgroundColor: '#272c34',
      color: 'white',
      fontSize: '0.9em',
      maxWidth: '100%',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      whiteSpace: 'break-spaces',
      hyphens: 'auto',
      display: 'inline-block',
      '& span': {
        display: 'block',
      },
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#036fcb',
  },
}));

const TABLE_OF_CONTENTS = [
  {
    label: 'Overview',
    href: 'developer-guide-overview',
  },
  {
    label: 'Data',
    href: 'developer-guide-data',
  },
  {
    label: 'Technical Specifications and Standards',
    href: 'developer-guide-tech-specs-and-standards',
  },
  {
    label: 'Try the API',
    href: 'developer-guide-try-api',
  },
  {
    label: 'Configuration',
    href: 'developer-guide-config',
  },
];

const FHIR_RESOURCES = [
  {
    heading: 'Demographics',
    subResources: ['Demographic information'],
  },
  {
    heading: 'Medical',
    subResources: [
      'Social history',
      ' Vitals',
      'Family history',
      'Procedures',
      'Lab results',
      'Immunizations',
      'Conditions',
      'Medications',
      'Visits',
      'Allergies',
    ],
  },
  {
    heading: 'Sensitive records',
    subResources: [
      'Behavioral diagnosis',
      'STD (including HIV)',
      ' Pregnancy related diagnosis',
      ' Recreational drugs',
    ],
  },
];

const TECH_STANDARDS = [
  {
    label: 'HL7 FHIR Standard',
    href: 'http://www.hl7.org/fhir/index.html',
  },
  {
    label: 'CMS 9115-F Interoperability and Patient Access Guide',
    href: 'http://www.hl7.org/fhir/us/carin-bb/index.html',
  },
  {
    label: 'PKCE Auth flow',
    href: 'https://auth0.com/docs/get-started/authentication-and-authorization-flow/authorization-code-flow-with-proof-key-for-code-exchange-pkce',
  },
  {
    label: 'RESTful API Overview',
    href: 'https://restfulapi.net/',
  },
];

const Section = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.contentSummary}>{children}</Typography>;
};

function ListItemLink(props) {
  return (
    <ListItem
      style={{ width: 'fit-content', color: '#036FCB', cursor: 'pointer' }}
      component="a"
      {...props}
    />
  );
}

const DeveloperGuide = ({ tabChange }) => {
  const classes = useStyles();
  const [envLink, setEnvLink] = useState('sandbox');
  const affiliateId = sessionStorage.getItem('affiliateId');
  const orgId = sessionStorage.getItem('orgId');
  const scrollIntoView = id => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Box>
      {/* Table Of Contents */}
      <Box>
        <Typography
          className={classes.heading}
          id="developer-guide-table-of-contents"
        >
          Table of Contents
        </Typography>
        <Box>
          <List component="nav" aria-label="table of contents">
            {TABLE_OF_CONTENTS?.map(content => (
              <ListItemLink
                onClick={() => scrollIntoView(content.href)}
                key={content.label}
              >
                <Typography component={'li'} className={classes.contentLink}>
                  {content.label}
                </Typography>
              </ListItemLink>
            ))}
          </List>
        </Box>
      </Box>
      {/* Overview */}
      <Box
        id="developer-guide-overview"
        component={'section'}
        className={classes.section}
      >
        <Typography className={classes.heading}>Overview</Typography>
        <Section>
          MPH Open API is a solution that will help other third-party apps to
          connect with MPH ecosystem, where the user /individuals can access
          their health information in a digital format, which can be through
          mobile apps, web portals or other HIE tools from their respective
          partners.
        </Section>
        <Section>
          Open API is designed to empower patients and enable them to have
          greater control and access to their health information and to promote
          interoperability and data exchange between different healthcare
          systems securely. This solution is built on the principles of the Fast
          Healthcare Interoperability Resources (FHIR) standard, which is widely
          used across the United states and is the standard format used for
          health data exchange.
        </Section>
      </Box>

      {/* Data */}
      <Box
        id="developer-guide-data"
        component={'section'}
        className={classes.section}
      >
        <Typography className={classes.heading}>Data</Typography>
        <Section>
          Users can access health records such as patient demographic
          information, medication history, allergy information, lab results and
          other resources.
        </Section>
        <Section>Below are the FHIR resources MPH has exposed:</Section>
        <Box marginLeft={'15px'}>
          {FHIR_RESOURCES.map(fhirResource => (
            <React.Fragment key={fhirResource.heading}>
              <Typography
                className={classes.contentListHeading}
                component={'li'}
              >
                {fhirResource.heading}
              </Typography>
              <Box component={'ol'} marginLeft={'30px'}>
                {fhirResource.subResources &&
                  fhirResource.subResources.map(subResource => (
                    <Typography
                      style={{
                        listStyle: 'lower-alpha',
                        margin: '5px',
                        fontSize: '15px',
                        color: '#667085',
                      }}
                      component={'li'}
                    >
                      {subResource}
                    </Typography>
                  ))}
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>

      {/* Tech Specs and Standards */}
      <Box
        id="developer-guide-tech-specs-and-standards"
        component={'section'}
        className={classes.section}
      >
        <Typography className={classes.heading}>
          Technical Specifications and Standards
        </Typography>
        <Section>
          MPH Open API is a Restful API, based on the HL7 FHIR standard and the
          <Link
            href="http://www.hl7.org/fhir/us/carin-bb/index.html"
            target="_blank"
          >
            {' '}
            CMS 9115-F Interoperability and Patient Access Guide
          </Link>
          . It supplies data in JSON format and uses the OAuth protocol for
          authorization.
        </Section>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gridGap={'10px'}
          margin={'5px'}
        >
          {TECH_STANDARDS.map(techStandard => (
            <Link href={techStandard.href} target="_blank">
              {techStandard.label}
            </Link>
          ))}
        </Box>

        <Box>
          <Typography className={classes.contentListHeading} component={'h2'}>
            Version
          </Typography>
          <Typography className={classes.contentSummary}>
            FHIR open API 1.0
          </Typography>
          <Typography className={classes.contentListHeading} component={'h2'}>
            Environments
          </Typography>
          <Typography className={classes.contentSummary}>
            We currently maintain production and sandbox environments for
            developing with the FHIR open API 1.0
          </Typography>
          <Typography className={classes.contentListHeading} component={'h2'}>
            Sandbox
          </Typography>
          {[
            'This development Sandbox helps Application developers and vendors register one or multiple applications and provide information about the application for system administrators and patients who use the apps.Third party apps can also track the status of their application registrations.',
            'We have also exposed the resources that mpowered health recommends for internal or third-party developers to test out their application developed with the Mpowered health APIs in a simulated sandbox environment. Developers should also review the RESTful API documentation for FHIR',
            'It’s helpful to know that:',
            'Sandbox credentials will not work in production.',
            'While we strive to provide a synthetic data set relevant to most use cases, our synthetic data set is not as comprehensive as production data. We have provided a few samples of Patient ID’s as well for testing purposes.',
          ].map(text => (
            <Typography className={classes.contentSummary}>{text}</Typography>
          ))}
          <Typography className={classes.contentSummary}>
            To get started in the developer sandbox -{' '}
            <Link onClick={tabChange} style={{ cursor: 'pointer' }}>
              Click here
            </Link>
          </Typography>

          <Typography className={classes.contentListHeading} component={'h2'}>
            Production
          </Typography>
          <Typography className={classes.contentSummary}>
            Once your development is completed, get access to live data in our
            production environment. After you complete the prerequisites for the
            production, raise a similar request for the application. Once your
            app is approved, we’ll give you credentials and configurations for
            the production environment.
          </Typography>
        </Box>
      </Box>

      {/* Try the API */}
      <Box
        id="developer-guide-try-api"
        component={'section'}
        className={classes.section}
      >
        <Typography className={classes.heading}>Try the API</Typography>
        <Section>
          You can start using the API right away by following these steps:
        </Section>
        {[
          'Create an account & register a Sandbox application',
          'Generate a sample client key',
          'Call the API to retrieve synthetic data for the sample Patient ID’s',
          'Review the response data',
        ].map(guide => (
          <Typography
            style={{
              listStyle: 'decimal',
              margin: '5px',
              fontSize: '15px',
              color: '#667085',
            }}
            component={'li'}
          >
            {guide}
          </Typography>
        ))}
        <Typography className={classes.contentListHeading} component={'h2'}>
          Step 1: Join the developer sandbox and register a sandbox application
        </Typography>
        <Typography className={classes.contentSummary}>
          Once you have verified your account, log in and click{' '}
          <RouterLink
            to={{
              pathname: `/organization/${orgId}/affiliate/${affiliateId}/applications`,
              state: { openCreateApp: true },
            }}
          >
            <span className={classes.link}>Create Application</span>
          </RouterLink>{' '}
          from the Developer homepage.
        </Typography>

        <Typography className={classes.contentSummary}>
          Register a new Sandbox application to get a Client ID.
        </Typography>
        <Typography className={classes.contentSummary}>
          Fill all the below details before you create an application
        </Typography>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-1.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />

        <Typography className={classes.contentListHeading} component={'h2'}>
          Step 2: Generate a sample token
        </Typography>
        <Typography className={classes.contentSummary}>
          To test out the FHIR open API, you must first generate a client ID
          token that represents granting consent to access to MPH data.
        </Typography>
        <ol style={{ marginLeft: '20px' }}>
          {[
            'Select the affiliate and application type',
            'Once the application request is created, wait till it’s approved',
            'From the Sandbox homepage, click on “Approved” Tab',
            'After the application is approved, Client key will be generated as shown below',
          ].map(guide => (
            <Typography
              style={{
                listStyle: 'decimal',
                margin: '5px',
                fontSize: '15px',
                color: '#667085',
              }}
              component={'li'}
            >
              {guide}
            </Typography>
          ))}
        </ol>

        <Typography className={classes.contentListHeading} component={'h3'}>
          Before approval
        </Typography>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-2.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />
        <Typography className={classes.contentListHeading} component={'h3'}>
          After approval
        </Typography>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-3.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />

        <Typography className={classes.contentListHeading} component={'h2'}>
          Step 3: Call the API
        </Typography>
        <Typography className={classes.contentSummary}>
          After you obtain your Access Token, you can call the API using cURL.
        </Typography>
        <ol style={{ marginLeft: '20px' }}>
          {[
            'Click on “sandbox” menu',
            'Click on “Authorize”',
            'Please copy the Client key from the application and authorize as shown below',
          ].map(guide => (
            <Typography
              style={{
                listStyle: 'decimal',
                margin: '5px',
                fontSize: '15px',
                color: '#667085',
              }}
              component={'li'}
            >
              {guide}
            </Typography>
          ))}
        </ol>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-4.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />

        <ol start={4} style={{ marginLeft: '20px' }}>
          <Typography
            style={{
              listStyle: 'decimal',
              margin: '5px',
              fontSize: '15px',
              color: '#667085',
            }}
            component={'li'}
          >
            Once you authorize, click on anyone of the “Patient access API” and
            provide the values for tenant as shown below
          </Typography>
        </ol>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-5.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />

        <Typography className={classes.contentListHeading} component={'h2'}>
          Step 4: View the API Response as shown below
        </Typography>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-6.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />

        <Typography className={classes.contentListHeading} component={'h2'}>
          Step 5: Requesting production access for the same application
        </Typography>
        <ol style={{ marginLeft: '20px' }}>
          {[
            'In order to request for production key, sandbox has to be approved prior',
            'Fill the form while raising a request for production key',
            'Follow the same process as you did for sandbox application',
          ].map(guide => (
            <Typography
              style={{
                listStyle: 'decimal',
                margin: '5px',
                fontSize: '15px',
                color: '#667085',
              }}
              component={'li'}
            >
              {guide}
            </Typography>
          ))}
        </ol>
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-7.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />
        <img
          src={
            'https://mpowered-assets.s3.us-east-2.amazonaws.com/developer-guide-8.png'
          }
          alt="create-app"
          className={classes.imgContainer}
        />
      </Box>

      {/* Configuration */}

      <Box
        id="developer-guide-config"
        component={'section'}
        className={classes.section}
        marginTop={'20px !important'}
      >
        <Typography className={classes.heading}>Configuration</Typography>

        <Box>
          <Typography variant="h6" style={{ marginBottom: '5px' }}>
            Select an Environment
          </Typography>
          <FormControl
            variant="outlined"
            size="small"
            style={{ backgroundColor: '#FFF' }}
          >
            <Select
              style={{ backgroundColor: '#FFF' }}
              onChange={e => setEnvLink(e.target.value)}
              defaultValue={envLink}
            >
              <MenuItem value={'sandbox'}>Sandbox</MenuItem>
              <MenuItem value={'prod'}>Production</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Section>
          Below are the 3 API any Third party needs to configure on their end.
        </Section>
        <Typography className={classes.contentListHeading} component={'h2'}>
          MPH user consent URL
        </Typography>
        <pre className={classes.codeBlock}>
          <code>
            <span>curl --location --request GET</span>
            <span>
              {`"https://${
                envLink === 'sandbox' ? 'sandbox-openapi' : 'open-api'
              }.mpoweredhealth.com/token-service/open-api/code-challenge?response_type=code&client_id=test&redirect_uri=https://www.clientdomain.com&code_challenge=y9r2JHvmK1zMWycf9cWmwwbvihqe8XRmXqTWoQHU2NY&code_challenge_method=S256&state=test"`}{' '}
              \
            </span>
            <span>
              --header 'Content-Type: application/json' \ --data {`'{}'`}
            </span>
          </code>
        </pre>

        <Typography className={classes.contentListHeading} component={'h2'}>
          Access token request
        </Typography>
        <pre className={classes.codeBlock}>
          <code>
            <span>curl --location</span>
            <span>
              {`"https://${
                envLink === 'sandbox' ? 'sandbox-openapi' : 'open-api'
              }.mpoweredhealth.com/token-service/open-api/token"`}{' '}
              \
            </span>
            <span>
              --header 'Content-Type: application/x-www-form-urlencoded' \
              --data-urlencode
              'code=7628e4bc-3261-4755-9fd2-df2130d0d444.cf4c5898-21c7-4f40-a068-8ec2916a6868.9b2e987e-e449-4211-a9cc-589e28c6642f'
              \ --data-urlencode
              'code_verifier=2175bc8cc7797dd48b8ed8af8e157273e92be4fef5959903c4131776'
              \ --data-urlencode 'client_id=test'
            </span>
          </code>
        </pre>

        <Typography className={classes.contentListHeading} component={'h2'}>
          Refresh token request
        </Typography>
        <pre className={classes.codeBlock}>
          <code>
            <span>curl --location</span>
            <span>
              {`"https://${
                envLink === 'sandbox' ? 'sandbox-openapi' : 'open-api'
              }.mpoweredhealth.com/token-service/open-api/token"`}{' '}
              \
            </span>
            <span>
              --header 'Content-Type: application/x-www-form-urlencoded' \
              --data-urlencode 'grant_type=refresh_token' \ --data-urlencode
              'refresh_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
            </span>
          </code>
        </pre>

        <Typography className={classes.contentListHeading} component={'h2'}>
          Fetch the data request
        </Typography>
        <Section>
          Note: follow the fhir standards for fetching the data, we dont allow{' '}
          <span style={{ color: 'black' }}>_filter, _include, _has</span>
        </Section>
        <pre className={classes.codeBlock}>
          <code>
            <span>curl --location</span>
            <span>
              {`"https://${
                envLink === 'sandbox' ? 'sandbox-openapi' : 'open-api'
              }.mpowered-health.com/api/v1/R4/public/fhir/Encounter?patient=23482609"`}
              \
            </span>
            <span>--header 'Authorization: Bearer token</span>
          </code>
        </pre>
      </Box>
      <IconButton
        onClick={() => scrollIntoView('developer-guide-table-of-contents')}
        className={classes.scrollToTop}
      >
        <ArrowUpwardIcon
          style={{
            fontSize: 25,
            border: '1px solid black',
            borderRadius: '50%',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default DeveloperGuide;
