import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, } from '@material-ui/styles';
import clsx from 'clsx';
import { Container, Grid, Step, StepConnector, StepLabel, Stepper, Typography } from '@material-ui/core';
import colors from '../colors';
import ActiveStep from '../../../assets/ActiveStep.svg';
import InActiveStep from '../../../assets/InActiveStep.svg';
import CompletedStep from '../../../assets/CompletedStep.svg';

const QontoConnector = withStyles({
  alternativeLabel: {
    left: 'calc( -100% + 30px )',
    right: 'calc( 100% + 8px)',
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    position: 'relative',
    top: 3,
    left: 5,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#C4C4C4',
    display: 'flex',
    height: 22,
    fontSize: theme.spacing(4),
    fontSize: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  stepNumber: {
    color: colors.WHITE,
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: theme.spacing(2),
    position: 'absolute',

  }
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, iconProps } = props;
  return (
    <div
      className={clsx(classes.root, {
      })}
    >
      {completed ? <img src={CompletedStep} /> : <img src={active ? ActiveStep : InActiveStep} />}
      {!completed ? <Typography className={classes.stepNumber}>{iconProps + 1}</Typography> : ''}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  iconProps: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 1
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    color: colors.WHITE,
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    letterSpacing: theme.spacing(0),
    '&.MuiStepLabel-active': {
      color: colors.WHITE,
    },
  },
  stepper: {
    padding: theme.spacing(0),
  },
  stepperContainer: {
    position: 'relative',
    marginBottom: theme.spacing(5),
  },
  horizontal: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  alternativeLabel: {
    fontSize: 14,
    opacity: 1,
  },
  active: {
    color: `${colors.BLUE} !important`,
    letterSpacing: theme.spacing(0),
  }
}));

const CustomizedSteppers = (props) => {
  const classes = useStyles();
  const { activeStep, steps, ...rest } = props;

  return (
    <Container className={classes.root}>
      <Grid container className={classes.stepperContainer}>
        <Grid item sm={6}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            className={classes.stepper}
            {...rest}
          >
            {steps.map((step, index) => (
              <Step key={index} classes={{ horizontal: classes.horizontal }}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  StepIconProps={{ iconProps: index}}
                  classes={{
                    root: classes.iconRoot,
                    label: classes.label,
                    alternativeLabel: classes.alternativeLabel,
                    active: classes.active,
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomizedSteppers;
