import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ReadonlyField } from 'components/widgets';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  labelHeading: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '4px',
    marginBottom: '4px',
  },
  labelSubheading: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
  },
  gridContainer: {
    margin: '0px 40px',
    borderTop: '2px solid #E4E7EC',
    padding: '24px 0px !important',
  },
  accordion: {
    margin: '16px',
  },
}));

export const DomainDetails = props => {
  const classes = useStyles();
  const [openAccordion, setOpenAccordion] = useState(true);

  return (
    <Grid item xs={12} className={classes.gridContainer}>
      <Accordion
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prevState => !prevState)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.labelHeading}>
            Domain details{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12} style={{ marginBottom: '10px' }} spacing={4}>
            <Grid item xs={12} sm={3}>
              <ReadonlyField
                label="Support email"
                value={props?.supportEmail}
                isValueBold={true}
                customLabelStyle={classes.labelSubheading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ReadonlyField
                label="Domain URL"
                value={props?.domainUrl}
                isLink
                isValueBold={true}
                listValues={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ReadonlyField
                label="Privacy policy "
                value={props?.privacyLink}
                isLink
                isValueBold={true}
                customLabelStyle={classes.labelSubheading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ReadonlyField
                label="Terms of service"
                value={props?.termsOfServiceLink}
                isLink
                isValueBold={true}
                customLabelStyle={classes.labelSubheading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ReadonlyField
                label="Re directional URL"
                value={props?.redirectUrl?.[0]}
                isLink
                isValueBold={true}
                customLabelStyle={classes.labelSubheading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ReadonlyField
                label="Query limit"
                value={props?.appQueryLimit}
                isValueBold={true}
                customLabelStyle={classes.labelSubheading}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
