import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 200,
    backgroundColor: '#fff',
  },
}));

export default function SelectInput({
  id,
  label,
  value,
  onChange,
  options,
  optionValueKey = 'id',
  optionLabelKey = 'name',
  required,
  error,
  style,
  hasError,
  emptyLabel,
  ...otherProps
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleChange = event => {
    // setAge(event.target.value);
    onChange(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl
        className={classes.formControl}
        required={required}
        error={hasError}
        size="small"
        style={style}
        fullWidth>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          displayEmpty
          variant="outlined"
          labelId={`${id}-label`}
          id={id}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          onChange={handleChange}
          {...otherProps}
          fullWidth>
          <MenuItem value="">
            <em>{emptyLabel ? emptyLabel : 'None'}</em>
          </MenuItem>
          {options.map((item, index) => (
            <MenuItem key={index} value={item[optionValueKey]}>
              {item[optionLabelKey]}
            </MenuItem>
          ))}
        </Select>
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
}
