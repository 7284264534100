import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import APIChart from 'containers/APIOverview/APIChart';
import { Card as CustomCard, SecondaryButton } from 'components/widgets';
import { useCallback, useEffect, useState } from 'react';
import TelemetryApi from 'services/api/TelemetryApi';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: theme.spacing(2),
  },
  card: {
    width: 345,
  },
  cardContent: {
    minHeight: 160,
  },
  btn: {
    textTransform: 'none',
  },
  listItem: {
    margin: '8px 0',
  },
  numIcons: {
    display: 'inline',
    padding: '4px',
    marginRight: '5px',
    borderRadius: '50%',
    background: '#ffa400',
  },
  apiCard: {
    marginTop: theme.spacing(2),
    minHeight: 240,
  },
  name: {
    fontWeight: '600',
    fontSize: 28,
    paddingLeft: theme.spacing(2),
  },
  circularProgress: {
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function TopAPIs({ history, orgId, affiliateId, devFlag = false}) {
  const classes = useStyles();
  const [data, setData] = useState();

  const today = new Date();
  const startDate = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    today.getDate()
  )
    .toLocaleString('en-US')
    .split(',')
    .join('')
    .slice(0, -3);

  let top5apis = [];

  const topFiveAPI = useCallback(() => {
    async function getResponse() {
      try {
        const details = await TelemetryApi.getTopFive(
          orgId,
          affiliateId,
          startDate
        );
        setData(details);
      } catch (e) {
        console.log(e);
      }
    }
    getResponse();
  }, [startDate, affiliateId, orgId]);

  useEffect(() => topFiveAPI(), [topFiveAPI]);

  if (data) {
    for (let item in data) {
      top5apis.push({ name: item.split('_')[0], calls: data[item] });
      top5apis = top5apis.sort((l1, l2) => l2.calls - l1.calls).slice(0, 5);
    }
  }

  return (
    <>
      <CustomCard className={classes.apiCard}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography component="b" variant="h4">
              Top 5 API calls by service
            </Typography>
            <SecondaryButton
              size="small"
              color="primary"
              onClick={() =>
                devFlag ? history.push(
                  `/organization/${orgId}/api-overview`
                ) :
                history.push(
                  `/organization/${orgId}/affiliate/${affiliateId}/api-overview`
                )
              }>
              View all APIs
            </SecondaryButton>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              {data ? (
                <APIChart data={top5apis} />
              ) : (
                <div className={classes.circularProgress}>
                  <CircularProgress
                    color='secondary'
                    size={70}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </CustomCard>
    </>
  );
}

export default TopAPIs;
