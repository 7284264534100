import React, { useState, useEffect } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import { Typography, Paper, Box } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { AlertDialog, SecondaryButton } from 'components/widgets';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { Link } from '@material-ui/core';
import { DownloadRawFile, useHasPermissions } from 'shared/utility';
import CriteriaApi from 'services/api/CriteriaApi';
import { useSnackbar } from 'notistack';
import { CRITERIA_OPERATOR_OPTIONS } from 'shared/constants';
import { convertStrToTitleCase } from 'shared/utility';
import { statusColorMap, getDateWithLocalTimeZone } from 'shared/utility';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import CreateRuleModal from '../RulesTabComponent/CreateRuleModal';
import RulesEngineApi from 'services/api/RuleEngineApi';
import VersionHistory from 'containers/VersionHistory';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  typo: {
    color: '#344054',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  icon: {
    height: '12px',
    width: '8px',
    marginRight: 1,
    marginLeft: 2,
    marginTop: '5px',
  },
  boxWidth: {
    width: '33%',
  },
  fieldValue: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    overflowWrap: 'break-word',
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },
  attributeType: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '16px',
    backgroundColor: '#E2F1FC',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '120px',
    height: '24px',
    border: '1px solid #036FCB',
    padding: '10px',
    fontWeight: '500',
    color: '#036FCB',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    fontSize: '12px',
    color: '#002534',
    height: '25px',
    width: '25px',
  },
  viewButton: {
    padding: '0px',
    fontSize: '12px',
    margin: '14px 20px 10px 30px',
  },
}));

const CriteriaInfo = ({ criteriaDetails, handleEdit, orgId,hasCreatePermission }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const getLink = async () => {
    try {
      const results = await CriteriaApi.getDownloadLink(
        orgId,
        criteriaDetails.id,
        criteriaDetails.version
      );
      DownloadRawFile(results, criteriaDetails.id);
    } catch (err) {
      enqueueSnackbar('Something went wrong while downloading the file', {
        variant: 'error',
      });
    }
  };
  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ marginBottom: '20px' }}>
            <Typography
              style={{ marginBottom: '7px', fontWeight: 600 }}
              variant="h4">
              Criterion details
            </Typography>
            <Box className={classes.editButton}>
              <SecondaryButton
                onClick={() => handleEdit()}
                style={{ padding: '2px' }}
                hasPermissions={hasCreatePermission}
                >
                Edit
              </SecondaryButton>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'start',
              marginBottom: '16px',
            }}>
            <Box className={classes.boxWidth}>
              <Typography style={{ color: '#667085' }}>
                Criterion title
              </Typography>
              <Typography variant="h5">
                <Typography className={classes.fieldValue}>
                  {criteriaDetails?.name || '-'}
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.boxWidth}>
              <Typography style={{ color: '#667085' }}>
                {criteriaDetails?.attrType === 'Non-FHIR'
                  ? 'Attribute type'
                  : 'Attribute'}
              </Typography>
              <Typography variant="h5">
                <Typography
                  className={
                    criteriaDetails?.attrType === 'Non-FHIR'
                      ? classes.attributeType
                      : classes.fieldValue
                  }>
                  {criteriaDetails?.attrType === 'Non-FHIR'
                    ? criteriaDetails?.attrType || '-'
                    : criteriaDetails?.attrName || '-'}
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.boxWidth}>
              <Typography style={{ color: '#667085' }}>
                {criteriaDetails?.attrType === 'Non-FHIR'
                  ? 'Attribute name'
                  : 'Attribute type'}
              </Typography>
              <Typography variant="h5">
                <Box>
                  <Typography
                    className={
                      criteriaDetails?.attrType === 'Non-FHIR'
                        ? classes.fieldValue
                        : classes.attributeType
                    }>
                    {criteriaDetails?.attrType === 'Non-FHIR'
                      ? criteriaDetails?.attrName || '-'
                      : criteriaDetails?.attrType || '-'}
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Box>
          {criteriaDetails?.attrType !== 'Non-FHIR' && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'start',
                marginBottom: '24px',
              }}>
              <Box className={classes.boxWidth}>
                <Typography style={{ color: '#667085' }}>Operator</Typography>
                <Typography variant="h5">
                  <Box>
                    <Typography className={classes.fieldValue}>
                      {CRITERIA_OPERATOR_OPTIONS.find(
                        val =>
                          val.value ===
                          criteriaDetails?.criteriaDefinitionDto?.operator
                      )?.label || '-'}
                    </Typography>
                  </Box>
                </Typography>
              </Box>
              {criteriaDetails.fileUrl && (
                <Box className={classes.boxWidth}>
                  <Typography style={{ color: '#667085' }}>
                    Reference columns
                  </Typography>
                  <Typography variant="h5">
                    <Box>
                      <Typography className={classes.fieldValue}>
                        {criteriaDetails?.referenceColumn
                          ? criteriaDetails.referenceColumn
                          : '-'}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              )}

              {criteriaDetails?.referenceColumn !== null && (
                <Box className={classes.boxWidth}>
                  <Typography style={{ color: '#667085' }}>Value</Typography>
                  <Typography variant="h5">
                    <Box>
                      <Typography className={classes.fieldValue}>
                        {criteriaDetails?.criteriaDefinitionDto?.value ? (
                          !isNaN(
                            new Date(
                              new Date(
                                criteriaDetails?.criteriaDefinitionDto?.value
                              ).getDate()
                            )
                          ) ? (
                            criteriaDetails?.criteriaDefinitionDto?.value.includes(
                              '-'
                            ) ? (
                              getDateWithLocalTimeZone(
                                criteriaDetails.criteriaDefinitionDto
                                  .displayValue ||
                                  criteriaDetails.criteriaDefinitionDto.value
                              )
                            ) : (
                              criteriaDetails.criteriaDefinitionDto.value
                            )
                          ) : (
                            criteriaDetails.criteriaDefinitionDto
                              .displayValue ||
                            criteriaDetails.criteriaDefinitionDto.value ||
                            '-'
                          )
                        ) : criteriaDetails.fileUrl ? (
                          <Tooltip
                            title="Click to download the file"
                            placement="bottom"
                            arrow>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={getLink}>
                              Download file
                            </Link>
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {criteriaDetails?.attrType === 'Non-FHIR' && criteriaDetails?.criteriaDefinitionDto.parameters?.length > 0 && (
            <Box
              sx={{
                margin: '24px 0',
                borderRadius: '16px',
                backgroundColor: '#F5F7F8',
                border: '1px solid #E4E7EC',
                boxShadow: 'none',
                // p: '8px',
                width: '80%',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  padding: '16px',
                }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Parameter</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Data type</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Value</Typography>
                </Box>
              </Box>
              <hr style={{ border: '0.5px solid #E4E7EC' }} />
              {criteriaDetails?.criteriaDefinitionDto?.parameters?.map(
                (val, index, arr) => (
                  <Box
                    sx={{
                      display: 'flex',
                      wrap: 'flex-wrap',
                      padding: '16px',
                      justifyContent: 'space-between',
                      ...(index !== arr.length - 1 && {
                        borderBottom: '1px solid #E4E7EC',
                      }),
                      width: '100%',
                    }}>
                    <Box component="span" sx={{ flex: 1, fontSize: '14px' }}>
                      {val?.name || '-'}
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        flex: 1,
                        wrap: 'flex-wrap',
                        fontSize: '14px',
                      }}>
                      {val?.dataType || '-'}
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        flex: 1,
                        wrap: 'flex-wrap',
                        fontSize: '14px',
                      }}>
                      {val?.value || '-'}
                    </Box>
                  </Box>
                )
              )}
            </Box>
          )}
          {criteriaDetails?.attrType !== 'Non-FHIR' && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'start',
                marginTop: '3%',
              }}>
              <Box className={classes.boxWidth}>
                <Typography style={{ color: '#667085' }}>Created on</Typography>
                <Typography variant="h5">
                  <Box>
                    <Typography className={classes.fieldValue}>
                      {moment(criteriaDetails?.createdAt).format(
                        'MMM DD, YYYY'
                      ) || '-'}
                    </Typography>
                  </Box>
                </Typography>
              </Box>

              <Box className={classes.boxWidth}>
                <Typography style={{ color: '#667085' }}>
                  Created by :{' '}
                </Typography>
                <Typography variant="h5">
                  <Box>
                    <Typography className={classes.fieldValue}>
                      {criteriaDetails?.createdBy || '-'}
                    </Typography>
                  </Box>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </DrawerBoxComponent>
  );
};

const RulesLinked = ({
  criteriaDetails,
  orgId,
  setIsLinkedRulesPublished,
  setIsLinkedRulesInProgress,
  rulesPermissions
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ruleLinkedData, setRuleLinkedData] = useState('');
  const [openCreateRuleModal, setOpenCreateRuleModal] = useState(false);
  const [fetchedData, setFetchedData] = useState('');
  const [rulesId, setRulesId] = useState();
  const [ruleTransactionId, setRuleTransactionId] = useState('');
  const [executionStatus, setExecutionStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCreateRule, setIsCreateRule] = useState(false);

  const {CREATE,READ} = useHasPermissions({availablePermissions:rulesPermissions})

  const handleCreateRuleModal = val => {
    setOpenCreateRuleModal(val);
  };

  const handleClose = () => {
    setOpenCreateRuleModal(false);
  };

  const getRulesLinked = async () => {
    try {
      const results = await CriteriaApi.getLinkedRules(
        orgId,
        criteriaDetails.id
      );
      let isLinkedRulePublished = false;
      let isLinkedRulesInProgress = {
        value: false,
        ruleNames: [],
      };
      for (let rule of results) {
        if (rule.status === 'IN_PROGRESS') {
          isLinkedRulesInProgress.value = true;
          isLinkedRulesInProgress.ruleNames.push(rule.name);
        } else if (rule.status === 'DRAFT') {
          isLinkedRulePublished = true;
        }
      }
      setIsLinkedRulesPublished(isLinkedRulePublished);
      setIsLinkedRulesInProgress(isLinkedRulesInProgress);

      setRuleLinkedData(results);
    } catch (err) {
      enqueueSnackbar('Something went wrong while downloading the file', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (criteriaDetails.id) {
      getRulesLinked();
    }
  }, [criteriaDetails.id]);

  useEffect(() => {}, [rulesId]);

  const refetchData = () => {};

  const onViewClick = async data => {
    setLoading(true);
    try {
      const results = await RulesEngineApi.getRuleDetails({
        orgId,
        ruleId: data.id,
      });
      setRulesId(data.id);
      setRuleTransactionId(data.ruleTransactionId);
      setExecutionStatus(data.status);
      setFetchedData(results);
      setLoading(false);
      handleCreateRuleModal(true);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box>
          <Typography
            style={{ marginBottom: '7px', fontWeight: 600 }}
            variant="h5">
            Rules linked
          </Typography>
          {ruleLinkedData && ruleLinkedData.length > 0 ? (
            <>
              <div style={{ width: '100%' }}>
                <table>
                  {ruleLinkedData?.map(data => {
                    return (
                      <tr>
                        <td>{data.name}</td>
                        <td>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '20px',
                            }}>
                            {Object.keys(statusColorMap).includes(
                              data.status
                            ) ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor:
                                      statusColorMap[data.status],
                                    width: 8,
                                    height: 8,
                                    borderRadius: 4,
                                    padding: 2,
                                  }}></span>
                                <span style={{ marginLeft: 8 }}>
                                  {convertStrToTitleCase(data.status)}
                                </span>
                              </>
                            ) : (
                              <span>{data.status}</span>
                            )}
                          </Box>
                        </td>
                        <td>
                          <SecondaryButton
                            className={classes.viewButton}
                            onClick={() => {
                              onViewClick(data);
                            }}
                            hasPermissions={READ}
                            >
                            View
                          </SecondaryButton>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          ) : (
            <Typography
              className={classes.fieldValue}
              style={{ marginTop: '8px' }}>
              No rules linked
            </Typography>
          )}
        </Box>
      </Paper>
      {openCreateRuleModal && (
        <FullScreenDialog open={openCreateRuleModal} handleClose={handleClose}>
          <CreateRuleModal
            handleCreateRuleModal={handleCreateRuleModal}
            handleClose={handleClose}
            fetchedData={fetchedData}
            isCreateRule={isCreateRule}
            setIsCreateRule={setIsCreateRule}
            orgId={orgId}
            rulesId={rulesId}
            refetchData={refetchData}
            ruleTransactionId={ruleTransactionId}
            executionStatus={executionStatus}
            hasCreatePermissions={CREATE}
          />
        </FullScreenDialog>
      )}
    </DrawerBoxComponent>
  );
};

const History = ({ criteriaDetails, criteriaHistoryDetails }) => {
  const classes = useStyles();

  const avatarInitials = name => {
    return name
      .split(' ')
      .map(item => item[0])
      .join('');
  };

  return (
    <DrawerBoxComponent style={{ width: '70%' }}>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Typography
          style={{ marginBottom: '7px', fontWeight: 600 }}
          variant="h5">
          Version history
        </Typography>

        {criteriaHistoryDetails.length ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'column',
            }}>
            {criteriaHistoryDetails?.map(item => (
              <Box style={{ marginBottom: '15px' }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <HistoryIcon fontSize="small" style={{ fontWeight: 400 }} />
                  <Typography style={{ marginLeft: '5px', fontWeight: 400 }}>
                    {item.version === 1 ? 'Created on' : 'Updated on'}{' '}
                    {item.version === 1
                      ? moment(item.createdAt).format('MMM DD, YYYY')
                      : moment(item.updatedAt).format('MMM DD, YYYY')}
                  </Typography>
                </Box>
                <Typography
                  variant="h5"
                  style={{ marginLeft: '24px', marginTop: '4px' }}>
                  <Typography className={classes.fieldValue}>
                    <div className={classes.nameContainer}>
                      <Avatar className={classes.avatar}>
                        {item.version === 1
                          ? avatarInitials(item.createdBy)
                          : avatarInitials(item.updatedBy)}
                      </Avatar>
                      <div style={{ fontWeight: 400 }}>
                        {item.version === 1 ? item.createdBy : item.updatedBy}
                      </div>
                    </div>
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>
        ) : null}
      </Paper>
    </DrawerBoxComponent>
  );
};

const CriteriaViewDetailsPage = ({
  criteriaDetails,
  criteriaHistoryDetails,
  handleEdit,
  orgId,
  hasCreatePermission,
  rulesPermissions
}) => {
  const [isLinkedRulesPublished, setIsLinkedRulesPublished] = useState(false);
  const [isLinkedRulesInProgress, setIsLinkedRulesInProgress] = useState({
    value: false,
    ruleNames: [],
  });
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const onEdit = () => {
    if (isLinkedRulesInProgress.value) {
      enqueueSnackbar(
        `${
          Array.isArray(isLinkedRulesInProgress.ruleNames)
            ? isLinkedRulesInProgress.ruleNames.join(', ')
            : ''
        } rules is in progress, edit once the execution is completed`,
        {
          variant: 'error',
          autoHideDuration: 2000,
        }
      );
      return;
    }
    if (isLinkedRulesPublished) {
      setOpenAlertDialog(true);
      return;
    }

    handleEdit();
  };
  return (
    <>
      <div style={{ backgroundColor: '#F5F5F5' }}>
        <div>
          <CriteriaInfo
            criteriaDetails={criteriaDetails}
            handleEdit={onEdit}
            orgId={orgId}
            hasCreatePermission={hasCreatePermission}
          />
        </div>
        <div>
          <RulesLinked
            criteriaDetails={criteriaDetails}
            orgId={orgId}
            setIsLinkedRulesPublished={setIsLinkedRulesPublished}
            setIsLinkedRulesInProgress={setIsLinkedRulesInProgress}
            rulesPermissions={rulesPermissions}
          />
        </div>
        <div>
          <VersionHistory
            isCriteria={true}
            historyDetails={criteriaHistoryDetails}
          />
        </div>
      </div>

      {isLinkedRulesPublished && (
        <AlertDialog
          open={openAlertDialog}
          description={
            'Since this rule was published in production and may have an impact on the users. Are you sure you want to edit the criteria?'
          }
          title="Edit criterion"
          onClose={() => setOpenAlertDialog(false)}
          onConfirm={() => {
            setOpenAlertDialog(false);
            handleEdit();
          }}
          closeBtnTxt="No"
          confirmBtnTxt="Yes"
          customTitleStyle={{ fontSize: 24, fontWeight: 600, padding: 20 }}
        />
      )}
    </>
  );
};

export default CriteriaViewDetailsPage;
