import { Component } from 'react';
import {
  Card,
  DataGrid,
  ReadonlyField,
  SecondaryButton,
} from 'components/widgets';
import { Typography, Box, Grid } from '@material-ui/core';
import moment from 'moment';

const statusColorMap = {
  Success: '#27AE60',
  Failed: '#DB0032',
  'In Progress': '#00A0DF',
  'Not Started': '#00A0DF',
};

class DataLoadConfigGrid extends Component {
  constructor(props) {
    super(props);
    // const rowData = props.rowData.map(item => ({
    //   ...item,
    //   status: faker.random.arrayElement(['Success', 'Failed', 'In Progress']),
    //   lastRun: faker.date.past(),
    // }));
    this.state = {
      defaultColDef: {
        flex: 1,
        minWidth: 200,
        resizable: true,
        sortable: true,
        filter: false,
      },
      frameworkComponents: {
        StatusRenderer: props => {
          return (
            <Box display="flex" alignItems="center">
              <span
                style={{
                  backgroundColor: statusColorMap[props.value],
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  padding: 2,
                }}
              ></span>
              <span style={{ marginLeft: 5 }}>{props.value}</span>
            </Box>
          );
        },
        DateFormatter: rowData => {
          let formattedDate = rowData.value
            ? moment(rowData.value).format('MMM DD, YYYY')
            : 'N/A';
          return formattedDate;
        },
      },
      context: { componentParent: this },
    };
  }

  render() {
    const { defaultColDef, frameworkComponents } = this.state;

    const gridReady = e => {
      console.log(e);
      const allColumnIds = [];
      e.columnApi.getAllColumns().forEach(column => {
        allColumnIds.push(column.colId);
      });
      e.columnApi.autoSizeColumns(allColumnIds, false);
    };

    return (
      <div style={{ margin: 32, marginTop: -10 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ margin: 16, marginTop: 40, marginRight: 0, marginLeft: 0 }}
        >
          <Typography variant="h4" component="h2" style={{ marginBottom: 8 }}>
            {this.props.gridType}
          </Typography>
          {this.props.isSuperAdmin && (
            <SecondaryButton
              hasPermissions={this.props.createAccess}
              onClick={this.props.DataLoadConfigOnClick}
            >
              {this.props.buttonTitle}
            </SecondaryButton>
          )}
        </Box>
        {this.props.data.tableData.length > 0 ? (
          <Card
            style={{ padding: '0px', margin: 0, backgroundColor: '#FFFFFF' }}
          >
            {this.props.data.headerData.length > 0 && (
              <Card
                style={{
                  paddingLeft: '22px',
                  paddingRight: '32px',
                  paddingTop: '22px',
                  margin: '10px',
                  backgroundColor: '#FFFFFF',
                  boxShadow: 'none',
                }}
              >
                <Grid container spacing={8}>
                  {this.props.data.headerData.map(field => (
                    <Grid item>
                      <ReadonlyField label={field.label} value={field.value} />
                    </Grid>
                  ))}
                </Grid>
              </Card>
            )}
            <DataGrid
              defaultColDef={defaultColDef}
              columnDefs={this.props.data.tableColumnDefs}
              rowData={this.props.data.tableData}
              frameworkComponents={frameworkComponents}
              context={this.state.context}
              enableCellTextSelection
              suppressColumnVirtualisation
              style={{
                // height:
                //   this.props.data.tableData.length > 0
                //     ? 'calc(100vh - 400px)'
                //     : 'calc(100vh - 600px)',
                height: '150px',
              }}
              hideHeaderActions
              onGridReady={gridReady}
            />
          </Card>
        ) : (
          <Card
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: '0px',
              justifyContent: 'center',
              height: '100px',
            }}
          >
            No records found
          </Card>
        )}
      </div>
    );
  }
}

export default DataLoadConfigGrid;
