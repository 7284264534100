import React from 'react';
import moment from 'moment';
import { Component, memo } from 'react';
import { DataGrid } from 'components/widgets';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import AvatarImg from './attributeDetails/AvatarImg';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
  attributeType: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    borderRadius: '16px',
    backgroundColor: '#E2F1FC',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '120px',
    height: '24px',
    border: '1px solid #036FCB',
    padding: '10px',
    fontWeight: '500',
    color: '#036FCB',
  },
}));

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
  onDeleteClick,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  const onDelteClickHandler = event => {
    event.preventDefault();
    onDeleteClick(data);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          // justifyContent: 'space-evenly',
          float:"right",
          marginTop: '12px',
        }}>
           <IconButton style={{height: '25px', width: '25px',background: "#E4E4E4",marginRight:"20px"}} onClick={onDelteClickHandler}>
          <DeleteIcon color='action' style={{fontSize:"18px"}}/>
          </IconButton>
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}>
          View details
        </PrimaryButton>
         
      </div>
    </>
  );
});

const AttributeType = memo(function AttributeType({ data }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.attributeType}>{data.type || '-'}</div>
    </>
  );
});

const AttributeCreatedBy = memo(function AttributeCreatedBy({ data }) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ marginTop: '10px', marginRight: '5px' }}>
          <AvatarImg userName={`${data.createdBy || ''} `} />
        </div>
        <div style={{ marginBottom: '5px' }}>{data.createdBy || '-'}</div>
      </div>
    </>
  );
});

class AttributeGrid extends Component {
  constructor(props) {
    super(props);
   }
  state = {
    
    columnDefs: [
      {
        headerName: 'Attribute name',
        field: 'name',
        colId: 'NameID',
      },
      {
        headerName: 'Type',
        field: 'type',
        cellRenderer: 'TypeofAttribute',
      },
      { headerName: 'FHIR resource', field: 'resourceName' },
      { headerName: 'FHIR component', field: 'resourceComponent' },
      // {
      //   headerName: 'Created on',
      //   field: 'createdAt',
      //   cellRenderer: 'DateFormatter',
      // },
      // {
      //   headerName: 'Created by',
      //   field: 'createdBy',
      //   cellRenderer: 'CreatedBy',
      // },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],
    nonFHIRColumnDefs: [
      {
        headerName: 'Attribute name',
        field: 'name',
        colId: 'NameID',
      },
      {
        headerName: 'Created on',
        field: 'createdAt',
        cellRenderer: 'DateFormatter',
      },
      {
        headerName: 'Data source',
        field: 'dataSource',

      },
      {
        field: ' ',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA?.toLowerCase()?.localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? '-'
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDeleteClick={item => this.onItemDelete(item)}
        />
      ),
      TypeofAttribute: rowData => (
        <AttributeType {...rowData} onClick={item => this.onItemView(item)} />
      ),
      CreatedBy: rowData => (
        <AttributeCreatedBy
          {...rowData}
          onClick={item => this.onItemView(item)}
        />
      ),
      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.props.onView(data);
  }

  onItemDelete(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, nonFHIRColumnDefs,frameworkComponents } = this.state;
    const { rowData, selectTableData,tabValue } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
          hideHeaderActions
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={tabValue === 0 ? columnDefs : nonFHIRColumnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh'}}
          
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default AttributeGrid;
