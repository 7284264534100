import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  root: {
    backgroundColor: theme.palette.background.dark,

    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      // paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#f5f5f5',
  },
}));

const SolutionDashboardLayout = ({
  children,
  history,
  onLogout,
  orgId,
  affiliateId,
  userLayoutType,
}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesktopNavMinified, setDesktopNavMinified] = useState(false);

  return (
    <div className={classes.root}>
        {/* <TopBar
          history={history}
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isDesktopNavMinified={isDesktopNavMinified}
          onLogout={onLogout}
          orgId={orgId}
          affiliateId={affiliateId}
          userLayoutType={userLayoutType}
        /> */}

        <div className={classes.wrapper}>
          <div className={classes.offset} />
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
    </div>
  );
};

export default SolutionDashboardLayout;
