import * as actionTypes from './actionTypes';
import MasterDataApi from 'services/api/MasterDataApi';

const locationStatesFetchSuccess = payload => {
  return {
    type: actionTypes.STATES_FETCH_SUCCESS,
    payload,
  };
};

const locationStatesFetchFailure = error => {
  return {
    type: actionTypes.STATES_FETCH_FAILURE,
    error: error,
  };
};

const locationStatesFetchStart = () => {
  return {
    type: actionTypes.STATES_FETCH_START,
  };
};

export const fetchLocationStates = countryCode => {
  return dispatch => {
    dispatch(locationStatesFetchStart());

    MasterDataApi.getStates(countryCode)
      .then(response => {
        dispatch(locationStatesFetchSuccess(response));
      })
      .catch(error => {
        dispatch(locationStatesFetchFailure(error));
      });
  };
};
