import React, { Component, useState } from 'react';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import htmlToReact from 'html-to-react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

const TextEditor = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = editorValue => {
    // console.log("editorValue",editorValue)
    setEditorState(editorValue);
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  return (
    <Grid container spacing={2} style={{ marginTop: '10px' }}>
      <Grid item sm={6} xs={6} style={{background:"white"}}>
        <Editor
        style={{height:"300px"}}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            image: { uploadCallback: uploadImageCallBack, previewImage: true },
          }}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: [
              { text: 'firstname', value: 'firstname', url: 'firstname' },
              { text: 'lastname', value: 'Lastname', url: 'Lastname' },
              { text: 'middlename', value: 'middlename', url: 'middlename' },
              { text: 'emailid', value: 'emailid', url: 'emailid' },
              { text: 'Sendername', value: 'Sendername', url: 'Sendername' },
            ],
          }}
          hashtag={{}}
        />
      </Grid>
      <Grid item sm={2} xs={6} style={{background:"white"}}></Grid>
      <Grid item sm={4} xs={6} style={{background:"white"}}>
      <span dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(editorState.getCurrentContent()))}} ></span>
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </Grid>
    </Grid>
  );
};

export default TextEditor;
