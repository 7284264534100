import BaseApiService from './BaseApiService';

const _getApplication = (orgId, applicationId) => {
  return BaseApiService.get(
    `/organization/${orgId}/application/${applicationId}`
  );
};

const _createApplication = (orgId, payload) => {
  return BaseApiService.post(
    `/organization/${orgId}/application`,
    null,
    payload
  );
};

const _updateApplication = (orgId, payload) => {
  return BaseApiService.put(
    `/organization/${orgId}/application`,
    null,
    payload
  );
};

const _getAllApplications = orgId => {
  return BaseApiService.get(`/organization/${orgId}/application`);
};

const _getApprovedApplications = (
  orgId,
  offset = 0,
  limit = 10,
  searchText = ''
) => {
  return BaseApiService.get(
    `/organization/${orgId}/application?offset=${offset}&limit=${limit}&status=Approved&search_text=${searchText}`
  );
};

const _getDeniedApplications = (
  orgId,
  offset = 0,
  limit = 10,
  searchText = '',
  appStatus = 'Denied,Revoked'
) => {
  return BaseApiService.get(
    `/organization/${orgId}/application?offset=${offset}&limit=${limit}&status=${appStatus}&search_text=${searchText}`
  );
};

const _getPendingApplications = (
  orgId,
  offset = 0,
  limit = 10,
  searchText = ''
) => {
  return BaseApiService.get(
    `/organization/412/application?offset=${offset}&limit=${limit}&status=applied&search_text=${searchText}`
  );
};

const _approveApplication = (orgId, applicationId, data, status) => {
  return BaseApiService.post(
    `/organization/${orgId}/application/${applicationId}/${status}`,
    null,
    data
  );
};

const _mphApproveApplication = (orgId, payload) => {
  return BaseApiService.post(
    `/organization/${orgId}/application/mphApproval`,
    null,
    payload
  );
};

const _deleteApplication = (orgId, applicationId) => {
  return BaseApiService.delete(
    `/organization/${orgId}/application/${applicationId}`
  );
};

const _fetchAllResources = (mphSupport = true) => {
  return BaseApiService.get(`/fhir/openapi/resource?mphSupport=${mphSupport}`);
};

const _getAllSavedResource = appId => {
  return BaseApiService.get(
    `/fhir/openapi/resource/application?appId=${appId}`
  );
};

const _getApplicationTypes = orgId => {
  return BaseApiService.get(
    `/organization/${orgId}/application/applicationTypes`
  );
};

const _getApplicationCount = orgId => {
  return BaseApiService.get(`/organization/${orgId}/application/applicationCount`);
}

const _getApplicationListing = ({
  _orgId,
  offset,
  limit,
  search,
  sandboxStatus,
  productionStatus,
}) => {
  const isMphAdmin = JSON.parse(sessionStorage.getItem('MPH_ADMIN'))
  return BaseApiService.get(
    `/organization/${_orgId}/application/${isMphAdmin ? 'mphApplicationList' : 'applicationList'}?offset=${offset}&limit=${limit}&sandboxStatus=${sandboxStatus}&productionStatus=${productionStatus}&searchText=${search}`
  );
};

const _viewApplicationDetails = (orgId, appId) => {
  return BaseApiService.get(`/organization/${orgId}/application/${appId}`);
};

const _getDeclinedReason = (orgId, appId, status) => {
  return BaseApiService.get(
    `/organization/${orgId}/application/reasonData/${appId}/${status}`
  );
};

const ApplicationApi = {
  getApplicationListing: _getApplicationListing,
  approveApplication: _approveApplication,
  mphApproveApplication:_mphApproveApplication,
  deleteApplication: _deleteApplication,
  getAllApplications: _getAllApplications,
  getApprovedApplications: _getApprovedApplications,
  getDeniedApplications: _getDeniedApplications,
  getPendingApplications: _getPendingApplications,
  getApplication: _getApplication,
  createApplication: _createApplication,
  updateApplication: _updateApplication,
  fetchAllResources: _fetchAllResources,
  getAllSavedResource: _getAllSavedResource,
  getApplicationTypes: _getApplicationTypes,
  viewApplicationDetails: _viewApplicationDetails,
  getDeclinedReason: _getDeclinedReason,
  getApplicationCount: _getApplicationCount
};

export default ApplicationApi;
