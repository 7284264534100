import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';

const withRouter = (WrappedComponent) => (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const params = useParams();
  const location = useLocation();

  const navigate = (path) => {
    setRedirectTo(path);
  };

  useEffect(() => {
    if (redirectTo) {
      setRedirectTo(null);
    }
  }, [redirectTo]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <WrappedComponent
      {...props}
      params={params}
      location={location}
      navigate={navigate}
    />
  );
};

export default withRouter;

