import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  title: {
    style: { display: 'none' },
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: 'API calls',
      data: [],
    },
  ],
  credits: {
    enabled: false,
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
  },
  colors: ['#2D9CDB'],
};

const InsightChart = ({ data, timeInterval}) => {
    const [chartOptions, setChartOptions] = useState(options);

    useEffect(() => {

      let xAxis = {
          type: 'datetime',
          dateTimeLabelFormats: {},
        }
      
      if(timeInterval === 'MONTH') {
        xAxis.tickInterval = null
        xAxis.dateTimeLabelFormats.month = '%b/%y'
      }
      if(timeInterval === 'WEEK') {
        xAxis.tickInterval = null
        xAxis.dateTimeLabelFormats.week = '%d %b'
      }
      if(timeInterval === 'DAY') {
        xAxis.tickInterval = 1000*60*60*24
        xAxis.dateTimeLabelFormats.day = '%d %b'
      }

      const updatedOptions = {
        ...options,
        series: [
          {
            ...options.series[0],
            data: data,
          },
        ],
        xAxis: xAxis,
      };

      setChartOptions(updatedOptions);
    }, [data, timeInterval]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default InsightChart;
