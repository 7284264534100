import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import { Field, Form } from 'react-final-form';
import { required } from 'shared/validators';
import { PrimaryButton, TextField } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  formContainer: {
    margin: theme.spacing(2),
    minWidth: 300,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function AddValuePopover({
  onSave,
  value,
  anchorEl,
  handleClose,
}) {
  const classes = useStyles();

  const onSaveValueHandler = values => {
    onSave(values);

    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'add-value-popover' : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {open && (
          <div className={classes.formContainer}>
            <Form
              initialValues={{
                ...value,
              }}
              onSubmit={onSaveValueHandler}
              render={({ handleSubmit, form, pristine, values }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Field name="id" validate={required}>
                    {({ input, meta: { error, touched } }) => (
                      <TextField
                        required
                        label="Enter the ID"
                        {...input}
                        touched={touched}
                        error={error}
                      />
                    )}
                  </Field>
                  <Field name="name" validate={required}>
                    {({ input, meta: { error, touched } }) => (
                      <div>
                        <TextField
                          required
                          label="Enter the display value"
                          {...input}
                          touched={touched}
                          error={error}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title="Copy from ID field"
                                  arrow
                                  placement="top">
                                  <IconButton
                                    aria-label="copy from ID"
                                    onClick={() => input.onChange(values.id)}>
                                    <FileCopyIcon color="secondary" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </Field>
                  <div>
                    <PrimaryButton disabled={pristine} type="submit">
                      Save
                    </PrimaryButton>
                    <PrimaryButton
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        form.reset();
                      }}
                      style={{ marginLeft: 10 }}>
                      Reset
                    </PrimaryButton>
                  </div>
                </form>
              )}></Form>
          </div>
        )}
      </Popover>
    </>
  );
}
