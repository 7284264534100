import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from './DocumentTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const Page = forwardRef(
  (
    { children, title = '', showHeader = false, headerText = '', ...rest },
    ref
  ) => {
    return (
      <div ref={ref} {...rest}>
        <DocumentTitle title={title} />
        {showHeader && (
          <AppBar position="static">
            <Toolbar variant="dense">
              <Typography variant="h6" color="inherit">
                {headerText}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
