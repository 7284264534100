import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CriteriaDropdown from 'containers/Rules/formElements/criteriaDropdown';
import AttributeApi from 'services/api/AttributeApi';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 36px 1px 16px',
    margin: '36px 0px 36px 0px',
  },
  containerBox: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
  },
  table: {
    minWidth: 510,
  },
  tableCell: { fontWeight: 600 },
}));

const QueryDetailTable = ({
  paramList,
  setParamList,
  isEdit,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dataTypeOptionS, setDataTypeOptions] = useState([]);

  const getDataTypes = dataSourceName => {
    setLoading(true);
    AttributeApi.dataTypes(dataSourceName)
      .then(response => {
        setDataTypeOptions([...response]);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataTypes();
  }, []);


  const rowsData = paramList?.map(item => {
    return {
      parameter: item.name,
      dataType: item.dataType,
    };
  });

  const onDataSourceChange = (dataType, id) => {
    const prevValue = [...paramList];
    prevValue[id].dataType = dataType;
    setParamList(prevValue);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          maxWidth: 540,
          borderRadius: '8px',
          border: '1px solid #F5F7F8',
          boxShadow: '0px 1px 12px #e3e2e2',
          p: '12px 12px',
          m: '10px 0px 10px 10px',
        }}>
        <TableContainer
          component={Paper}
          style={{ maxWidth: 510 }}
          elevation={0}
          className={classes.containerBox}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Parameters</TableCell>
                <TableCell className={classes.tableCell} align="left">
                  Data type <span style={{ color: 'red' }}>*</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData?.map((row, index) => (
                <TableRow key={index * 10}>
                  <TableCell component="th" scope="row">
                    {row.parameter}
                  </TableCell>
                  <TableCell align="left">
                    <CriteriaDropdown
                      placeHolderText={'Select the data type'}
                      required={true}
                      value={
                        paramList?.[index]?.dataType ||
                        'placeholder'
                      }
                      options={dataTypeOptionS}
                      optionValueKey={'name'}
                      optionLabelKey={'name'}
                      disabled={isEdit}
                      handleChange={e =>
                        onDataSourceChange(e.target.value, index)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default QueryDetailTable;
