import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import ReusableCategoriesContainer from './ReusableCategoriesContainer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // paddingLeft: 16,
    paddingRight: 16,
  },
}));

const Records = () => {
  const classes = useStyles();

  return (
    <>
        <div>
          <Grid className={classes.container}>
            <ReusableCategoriesContainer />
          </Grid>
        </div>
    </>
  );
};

export default Records;
