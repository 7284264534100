import React, { useState, useEffect } from 'react';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import { Typography, Paper, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/widgets';
import { useSnackbar } from 'notistack';
import CohortForCampaign from './CohortForCampaign';
import ContentForCampaign from './ContentForCampaign';
import ScheduleForCampaign from './scheduleForCampaign';
import VersionHistory from 'containers/VersionHistory';
import CampaignApi from 'services/api/CampaignApi';
import ReportForCamapign from './ReportForCamapign';
import { convertStrToTitleCase } from 'shared/utility';
import Rocketlaunch from '../../../components/icons/RocketLaunch';
import { formatTimeInHHMMWithComma, statusColorMap } from 'shared/utility';
import moment from 'moment';
import LaunchIcon from '@material-ui/icons/Launch';
import { AssessmentAPI } from 'services/api/Assessment.api';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  executeButton: {
    borderRadius: '30px',
    textTransform: 'none',
  },
  abortButton: {
    color: 'red',
    borderColor: 'red!important',
  },
  editButton: {
    // display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },

  cancelButton: {
    fontSize: '16px',
    borderRadius: '40px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
  },
  footer: {
    zIndex: '1',
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 40px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
  lastRunBox: {
    background: '#E2F1FC',
    width: 'fit-content',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4%',
  },
  assessmentDetailsBox: {
    background: '#E2F1FC',
    width: 'fit-content',
    padding: '4px',
    // display: 'flex',
    alignItems: 'center',
    borderRadius:"10px",
    padding:"1%",
    marginBottom: '4%',
    borderRadius:"10px"
  },
  assessmentCreatedBox: {
    background: '#ffffff',
    width: 'fit-content',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    borderRadius:"6px",
  },
}));

const CampaignStep2 = ({
  changeStep,
  step1Data,
  tabStatus,
  campaignValues,
  setCampaign,
  refetchCampaigns,
  handleClose,
  isCreateCampaign,
  setIsCreateCampaign,
  orgId,
  campaignId,
  shouldRefetechData,
  trackUnsavedChangesRef,
  fetchedData,
  hasCreatePermissions
}) => {
  const classes = useStyles();
  const [assessmentDetails,setAssessmentDetails] = useState(null);
  const [savedCohort, setSavedCohort] = useState(false);
  const [editCohort, setEditCohort] = useState(false);
  const [savedContent, setSavedContent] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [savedScheduler, setSavedSchedule] = useState(false);
  const [editScheduler, setEditSchedule] = useState(false);
  const [executeStatus, setExecuteStatus] = useState(
    campaignValues?.status === 'DRAFT' ? false : true
  );
  const [campaignHistoryDetails, setCamapignHistoryDetails] = useState([]);
  const [publishedClick, setPublishedClick] = useState(false);
  const [nextRun, setNextRun] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const checkStatus = () => {
    if (!editCohort && !editContent && !editScheduler) {
      handleClose(true);
      shouldRefetechData.current = false;
      refetchCampaigns();
    } else if (editCohort) {
      enqueueSnackbar('Please save criteria details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    } else if (editContent) {
      enqueueSnackbar('Please save action details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    } else if (editScheduler) {
      enqueueSnackbar('Please save schedule details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    }
  };
  
  const getAssessmentDetails = async ()=>{
    const assessmentId=step1Data.assessmentId;
    try {
      const results = await AssessmentAPI.getAssessmentDetails({
        orgId,
        assessmentId,
      });
      setAssessmentDetails(results);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const getHistoryList = async (orgId, campaignId) => {
    try {
      const results = await CampaignApi.campaignVersionHistory({
        orgId,
        campaignId,
      });
      setCamapignHistoryDetails(results);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getNextSchedule = async campaignId => {
    try {
      let results = await CampaignApi.nextSchedule(campaignId);
      setNextRun(results);
    } catch (err) {
      console.log('error', err);
    }
  };

  useEffect(() => {
    getNextSchedule(campaignId);
    if (!isCreateCampaign) {
      getHistoryList(orgId, campaignId);
    }
    return () => {
      setIsCreateCampaign(false);
    };
  }, [orgId, campaignId]);

  useEffect(() => {
    if(step1Data.assessmentId)
  {
    getAssessmentDetails();

  }
},[]);

  useEffect(() => {
    trackUnsavedChangesRef.current = () => {
      if (editCohort || editContent || editScheduler) {
        return true;
      } else {
        return false;
      }
    };
  }, [editCohort, editContent, editScheduler, trackUnsavedChangesRef]);

  useEffect(() => {
    if (campaignValues) {
      if (
        campaignValues?.schedule?.startDate &&
        campaignValues.emailTemplate.subject &&
        campaignValues?.cohortId
      ) {
        setSavedCohort(true);
        setSavedContent(true);
        setSavedSchedule(true);
      }
    }
  }, [campaignValues]);

  const publishCampaign = async orgId => {
    let payload = {
      ...campaignValues,
      status: 'PUBLISHED',
    };

    let campaignId = payload.id;
    try {
      let results = await CampaignApi.saveCampaign({
        orgId,
        payload,
        campaignId,
      });
      setExecuteStatus(true);
      setPublishedClick(true);
      handleClose(true);
      shouldRefetechData.current = false;
      refetchCampaigns();
      
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const assessmentRedirect =(orgId,assessmentId)=>{
    window.location.pathname =  `/viewDetails/${orgId}/${assessmentId}?redirectURL=campaign`;
  }

  return (
    <>
      <DrawerBoxComponent style={{ minHeight: '78vh' }}>
        <Paper elevation={0} className={classes.paper}>
          <Box className={classes.container}>
            <Box style={{ display: 'flex' }}>
              <Box style={{ marginRight: '30px' }}>
                <Typography style={{ marginBottom: '15px' }} variant="h3">
                  <b>{step1Data.title}</b>
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '6%',
                  }}>
                  {Object.keys(statusColorMap).includes(
                    campaignValues?.status
                  ) ? (
                    <>
                      <span
                        style={{
                          backgroundColor:
                            statusColorMap[campaignValues?.status],
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          padding: 2,
                        }}></span>
                      <span style={{ marginLeft: 8 }}>
                        {convertStrToTitleCase(campaignValues?.status)}
                      </span>
                    </>
                  ) : (
                    <span>{campaignValues?.status}</span>
                  )}
                </Box>

                <Typography style={{ marginBottom: '7px' }} variant="h6">
                  <b>Description : </b> {step1Data.description}
                </Typography>

                <Typography style={{ marginBottom: '7px' }} variant="h6">
                  <b>Mode : </b> {step1Data.mode}
                </Typography>
                {nextRun && (
                  <Box className={classes.lastRunBox}>
                    <Rocketlaunch />
                    <Typography>
                      Next run date:{' '}
                      {moment(nextRun).format('MMM DD, YYYY -') +
                        ' ' +
                        formatTimeInHHMMWithComma(nextRun)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.editButton}>
              {campaignValues?.status === 'DRAFT' && (
                <SecondaryButton
                  onClick={() => {
                    changeStep(true);
                  }}
                  hasPermissions={hasCreatePermissions}
                  >
                  Edit
                </SecondaryButton>
              )}
            </Box>
          </Box>
          {step1Data.assessmentId &&
          <Box className={classes.assessmentDetailsBox}>
                <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <Typography>
                      {assessmentDetails?.name} 
                    </Typography>
                    <LaunchIcon  onClick={()=> assessmentRedirect(orgId, step1Data.assessmentId)} style={{color:"#036FCB",fontSize:"20px",marginRight:"3px"}}/>
                    </Box>
                  <Box sx={{display:"flex",marginTop:"10px"}}>
                  <Box  className={classes.assessmentCreatedBox}>
                    
                    <Typography>
                      Created on:{' '}
                      {moment(assessmentDetails?.createdAt).format('MMM DD, YYYY -') +
                        ' ' +
                        formatTimeInHHMMWithComma(assessmentDetails?.createdAt)}
                    </Typography>
                  </Box>
                  <Box className={classes.assessmentCreatedBox} sx={{marginLeft:"10px"}}>
                    
                    <Typography>
                      Questions:{' '} {assessmentDetails?.numberOfQuestions}
                      
                    </Typography>
                  </Box>
                  </Box>
                  </Box>
            }
        </Paper>
        {/* report section */}
        {(campaignValues.status !== 'DRAFT' && campaignValues.status !== 'PUBLISHED' &&  campaignValues.status !== 'SCHEDULED') && (
          <ReportForCamapign fetchedData={fetchedData} />
        )}
        {/* cohort section  */}
        <CohortForCampaign
          className={classes.paper}
          setEditCohort={setEditCohort}
          setSavedCohort={setSavedCohort}
          tabStatus={tabStatus}
          campaignValues={campaignValues}
          setCampaign={setCampaign}
          hasCreatePermissions={hasCreatePermissions}
        />
        {/* content section  */}
        <ContentForCampaign
          className={classes.paper}
          setEditContent={setEditContent}
          setSavedContent={setSavedContent}
          tabStatus={tabStatus}
          campaignValues={campaignValues}
          setCampaign={setCampaign}
          assessmentId={step1Data.assessmentId}
          hasCreatePermissions={hasCreatePermissions}
        />
        {/* schedule section  */}
        <ScheduleForCampaign
          className={classes.paper}
          setEditSchedule={setEditSchedule}
          setSavedSchedule={setSavedSchedule}
          tabStatus={tabStatus}
          campaignValues={campaignValues}
          setCampaign={setCampaign}
          assessmentId={step1Data.assessmentId}
          hasCreatePermissions={hasCreatePermissions}
        />
        {/* history section */}
        {!isCreateCampaign && (
          <VersionHistory
            historyDetails={campaignHistoryDetails}
          />
        )}
      </DrawerBoxComponent>
      {/* Footer */}
      <Box className={classes?.footer || ''}>
        <Box className={classes.editButton}>
          <SecondaryButton onClick={() => checkStatus()}>Close</SecondaryButton>
        </Box>
        {!executeStatus && hasCreatePermissions && (
          <Button
            // onClick={onProceed}
            color="primary"
            className={classes.executeButton}
            variant="contained"
            data-test-id="add-organization-button"
            onClick={() => {
              publishCampaign(orgId);
            }}
            disabled={
              savedCohort && savedContent && savedScheduler ? false : true
            }>
            {/* {isEdit ? 'Proceed' : 'Add organization'} */}
            Publish
          </Button>
        )}
        {/* <Box className={classes.editButton}>
            <SecondaryButton
              className={classes.abortButton}
              onClick={() => handleClose(true)}>
              Abort
            </SecondaryButton>
          </Box> */}
      </Box>
    </>
  );
};

export default CampaignStep2;
