import * as actionTypes from './actionTypes';

export const showSetupOrganization = (orgId = null) => {
  return {
    type: actionTypes.SHOW_SETUP_ORG,
    orgId,
  };
};

export const hideSetupOrganization = (triggerUpdate = false) => {
  return {
    type: actionTypes.HIDE_SETUP_ORG,
    triggerUpdate,
  };
};

export const showManageAlerts = (orgId = null) => {
  return {
    type: actionTypes.SHOW_ORG_ALERTS,
    orgId,
  };
};

export const hideManageAlerts = (triggerUpdate = false) => {
  return {
    type: actionTypes.HIDE_ORG_ALERTS,
    triggerUpdate,
  };
};

export const showSaveApplication = (
  orgId = null,
  affiliateId = null,
  data = null,
  action = '',
  sandboxStatus
) => {
  return {
    type: actionTypes.SHOW_SAVE_APPLICATION,
    orgId,
    data,
    affiliateId,
    isViewMode: action === 'view',
    sandboxStatus,
  };
};

export const hideSaveApplication = (triggerUpdate = false) => {
  return {
    type: actionTypes.HIDE_SAVE_APPLICATION,
    triggerUpdate,
  };
};
