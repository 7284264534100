import BaseApiService from './BaseApiService';

function _getAllPartners(orgId) {
  return BaseApiService.get(`/organization/${orgId}/affiliate`);
}

function _getLabelOptions(orgId){
  return BaseApiService.get(`/organization/${orgId}/label/all`)
}

function _getAllMembers(orgId, searchParam = '', status, labelFilter) {
  const searchParams = searchParam !== '' ? `?nameFilter=${searchParam}` : '';
  const statusFilter =
    searchParam && status
      ? `&status=${status}`
      : status
      ? `?status=${status}`
      : '';
  const labelFilterParam =
    (searchParam || status) && labelFilter
      ? `&labelFilter=${labelFilter}`
      : labelFilter
      ? `?labelFilter=${labelFilter}`
      : '';

  return BaseApiService.get(
    `/member/${orgId}/all${searchParams}${statusFilter}${labelFilterParam}`
  );
}

function _getPartnerById(orgId, partnerId) {
  return BaseApiService.get(`/organization/${orgId}/affiliate/${partnerId}`);
}

function _createPartner(orgId, data) {
  return BaseApiService.post(`/organization/${orgId}/affiliate`, null, data);
}

function _updatePartner(orgId, data) {
  return BaseApiService.put(`/organization/${orgId}/affiliate`, null, data);
}

function _deletePartner(orgId, partnerId) {
  return BaseApiService.delete(`/organization/${orgId}/affiliate/${partnerId}`);
}

function _getPartnerAddresses(orgId, partnerId) {
  return BaseApiService.get(
    `/organization/${orgId}/affiliate/${partnerId}/addresses`
  );
}

function _createPartnerAddress(orgId, partnerId, data) {
  return BaseApiService.post(
    `/organization/${orgId}/affiliate/${partnerId}/address`,
    null,
    data
  );
}

function _updatePartnerAddress(orgId, partnerId, data) {
  return BaseApiService.put(
    `/organization/${orgId}/affiliate/${partnerId}/address`,
    null,
    data
  );
}

function _deletePartnerAddress(orgId, partnerId, addressId) {
  return BaseApiService.delete(
    `/organization/${orgId}/affiliate/${partnerId}/address/${addressId}`
  );
}

function _getPartnerContacts(orgId, partnerId) {
  return BaseApiService.get(
    `/organization/${orgId}/affiliate/${partnerId}/contacts`
  );
}

function _createPartnerContact(orgId, partnerId, data) {
  return BaseApiService.post(
    `/organization/${orgId}/affiliate/${partnerId}/contact`,
    null,
    data
  );
}

function _updatePartnerContact(orgId, partnerId, data) {
  return BaseApiService.put(
    `/organization/${orgId}/affiliate/${partnerId}/contact`,
    null,
    data
  );
}

function _deletePartnerContact(orgId, partnerId, contactId) {
  return BaseApiService.delete(
    `/organization/${orgId}/affiliate/${partnerId}/contact/${contactId}`
  );
}

function _uploadAffiliateLogo(orgId, affiliateId, data, otherHeaders) {
  return BaseApiService.post(
    `/organization/${orgId}/upload/${affiliateId}`,
    null,
    data,
    otherHeaders
  );
}

function _membersActions({payload,orgId }) {
  return BaseApiService.post(`/member/${orgId}/config`, null, payload);
}

function _updatePassword({email}){
  return BaseApiService.put(`/organization/resetPassword?email=${email}`);
}

const DataPartnersApi = {
  getAllPartners: _getAllPartners,
  getAllMembers: _getAllMembers,
  getPartnerById: _getPartnerById,
  createPartner: _createPartner,
  updatePartner: _updatePartner,
  deletePartner: _deletePartner,
  getPartnerAddresses: _getPartnerAddresses,
  createPartnerAddress: _createPartnerAddress,
  updatePartnerAddress: _updatePartnerAddress,
  deletePartnerAddress: _deletePartnerAddress,
  getPartnerContacts: _getPartnerContacts,
  createPartnerContact: _createPartnerContact,
  updatePartnerContact: _updatePartnerContact,
  deletePartnerContact: _deletePartnerContact,
  uploadAffiliateLogo: _uploadAffiliateLogo,
  membersActions: _membersActions,
  updatePassword:_updatePassword,
  getLabelOptions: _getLabelOptions,
};

export default DataPartnersApi;
