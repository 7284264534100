import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  title: {
    style: { display: 'none' },
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
        distance: -50,
        style: {
          fontWeight: 'bold',
          color: 'white',
        },
      },
      showInLegend: true,
      size: '80%',
    },
  },
  series: [
    {
      type: 'pie',
      name: 'Top 5 API calls by service',
      innerSize: '70%',
      data: [],
    },
  ],
  colors: ['#2D9CDB', '#F2994A', '#9B51E0', '#FFE2AE', '#219653'],
  credits: {
    enabled: false,
  },
};

const APIChart = ({ data = [] }) => {
  const [chartOptions, setChartOptions] = useState(options);

  useEffect(() => {
    const formattedData = data
      .sort((el1, el2) => el2.calls - el1.calls)
      .map(item => {
        return [item.name, item.calls];
      });

    const updatedOptions = {
      ...options,
      series: [
        {
          ...options.series[0],
          data: formattedData,
        },
      ],
    };

    setChartOptions(updatedOptions);
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default APIChart;
