import BaseApiService from './BaseApiService';

function _getAllCriteria(orgId) {
  return BaseApiService.get(`/attribute?orgId=${orgId}`);
}

function _getAllCriteriaWithAttrType(orgId, attrType = 'FHIR') {
  return BaseApiService.get(
    `/attribute?orgId=${orgId}&searchAttrType=${attrType}`
  );
}

function _createCriteria({ orgId, payload }) {
  return BaseApiService.post(`/organization/${orgId}/criteria`, null, payload);
}

function _editCriteria({ orgId, payload }) {
  return BaseApiService.put(`/organization/${orgId}/criteria`, null, payload);
}

function _getCriteriaTitle({ orgId, criteriaTitle }) {
  return BaseApiService.get(
    `/organization/${orgId}/criteria/name-list?searchName=${criteriaTitle}`
  );
}

function _criteriaDetails({ orgId, criteriaId }) {
  return BaseApiService.get(`/organization/${orgId}/criteria/${criteriaId}`);
}

function _criteriaHistory({ orgId, criteriaId }) {
  return BaseApiService.get(
    `/organization/${orgId}/criteria/history/${criteriaId}`
  );
}

function _deleteCriteria(orgId, criteriaId) {
  return BaseApiService.delete(`/organization/${orgId}/criteria/${criteriaId}`);
}

function _getDownloadLink(orgId, criteriaId, version) {
  return BaseApiService.get(
    `/organization/${orgId}/criteria/getDownloadLink/${criteriaId}/${version}`
  );
}

function _getLinkedRules(orgId, criteriaId) {
  return BaseApiService.get(
    `/${orgId}/rule/ruleLinkedToCriteria/${criteriaId}`
  );
}

function _getEvent(orgId) {
  return BaseApiService.get(`/organization/${orgId}/criteria/event-names`);
}

const CriteriaApi = {
  createCriteria: _createCriteria,
  getAllCriteria: _getAllCriteria,
  getAllCriteriaWithAttrType: _getAllCriteriaWithAttrType,
  getCriteriaTitle: _getCriteriaTitle,
  criteriaDetails: _criteriaDetails,
  criteriaHistory: _criteriaHistory,
  editCriteria: _editCriteria,
  deleteCriteria: _deleteCriteria,
  getDownloadLink: _getDownloadLink,
  getLinkedRules: _getLinkedRules,
  getEvent: _getEvent,
};

export default CriteriaApi;
