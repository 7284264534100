import React from 'react';
import { Dialog, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import inviteImg from '../../../src/assets/invite-user.svg';
import './InviteUserLandingPage.scss';

export const InviteUserLandingPage = props => {
  const renderCard = category => {
    return (
      <div
        className="card-container"
        onClick={() => props?.handleInviteCard?.(category)}
      >
        <img
          src={`${
            !category
              ? 'https://mpowered-assets.s3.us-east-2.amazonaws.com/Firefly+create+a+developer+image+50172+1external.svg'
              : inviteImg
          }`}
          alt="internal-user"
          className="btn-image"
        />

        <div className="card-title">
          <div className="card-header">
            {category ? 'Organization user' : 'External user'}
          </div>
          <div className="card-subheader">
            {category
              ? 'Invite organization user by filling their details, defining a role and giving permissions'
              : 'Invite external user by filling their details, defining a role and giving permissions'}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog open={true} className="invite-user-dialog">
      <div className="closeIcon">
        <CloseIcon onClick={props?.handleClose} />
      </div>
      <div className="root-invite" id="root-invite-user">
        <div className="title">
          <div className="header">Who would you like to invite?</div>
          <div className="sub-header">
            Get the most out of Mpowered health by inviting your organization
            user
          </div>
        </div>
        <div className="buttons">
          <div className="internal">{renderCard('internal')}</div>
          <div className="external">{renderCard()}</div>
        </div>
      </div>
    </Dialog>
  );
};
