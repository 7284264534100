import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import CustomizedDialog from 'components/CustomizedDialog';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OrganizationApi from 'services/api/OrganizationApi';
import { useSnackbar } from 'notistack';
import { SimpleBackdrop } from 'components/widgets';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  domainPopup: {
    minHeight: '303px',
    minWidth: '732px',
  },
  domainContainer: {
    backgroundColor: '#F5F7F8',
    borderRadius: '8px',
    padding: '8px 12px',
  },
}));

function ReadonlyField({ label, value }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 2,
        marginRight: 24,
        gap: '4px',
      }}
    >
      {label && (
        <span style={{ fontWeight: 500, fontSize: '14px', color: '#667085' }}>
          {label}
          {':'}
        </span>
      )}
      <span style={{ fontWeight: 500, fontSize: '14px', color: '#344054' }}>
        {value}
      </span>
    </div>
  );
}

const DETAILS_MAX_LENGTH = 45;

const DomainPopup = ({
  open,
  handleClickClose,
  domainDetails,
  isEdit,
  orgId,
  fetchAllDomains,
  domainNames,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [orgType, setOrgType] = useState(
    domainDetails?.internalDomain === false ? 'External' : 'Internal'
  );
  const isInternalDomain = orgType === 'Internal';

  const [domainName, setDomainName] = useState(
    domainDetails?.domain?.split('@')?.[1] || ''
  );
  const [orgName, setOrgName] = useState(domainDetails?.orgName || '');
  const [loading, setLoading] = useState(false);

  const snackbarError = msg => {
    enqueueSnackbar(msg || 'Something went wrong', {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };
  const handleOnSave = () => {
    if (domainName === '' || (!isInternalDomain && orgName === '')) {
      let isDomainNameEmpty = domainName === '';
      return snackbarError(
        `Please provide ${
          isDomainNameEmpty ? 'domain name' : 'organization name'
        } `
      );
    } else if (!/\./.test(domainName)) {
      return snackbarError('Please enter valid domain name');
    } else if (
      domainNames.has(`@${domainName}`) &&
      domainDetails?.domain !== `@${domainName}`
    ) {
      return snackbarError(`${domainName} domain already exists`);
    }

    const payload = {
      internalDomain: isInternalDomain,
      orgName: isInternalDomain ? '' : orgName,
      domain: `@${domainName}`,
    };
    setLoading(true);
    const API_ENPOINT = isEdit
      ? OrganizationApi.updateDomain
      : OrganizationApi.createDomain;

    API_ENPOINT({ orgId, payload, domainId: domainDetails?.id })
      .then(res => {
        enqueueSnackbar(
          `Domain ${isEdit ? 'updated' : 'created'} successfully`,
          {
            variant: 'success',
            autoHideDuration: 2000,
          }
        );
        fetchAllDomains();
        setLoading(false);
        handleClickClose();
      })
      .catch(err => {
        enqueueSnackbar(err.response?.data?.details || 'Something went wrong', {
          variant: 'error',
          autoHideDuration: 2000,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <CustomizedDialog
        open={Boolean(open)}
        handleClickClose={handleClickClose}
        title={isEdit ? 'Edit a domain' : 'Create a domain'}
        handleOnSave={handleOnSave}
        customStyles={classes.domainPopup}
        primaryBtnDisabled={
          isInternalDomain
            ? domainName?.length > DETAILS_MAX_LENGTH
            : domainName?.length > DETAILS_MAX_LENGTH ||
              orgName?.length > DETAILS_MAX_LENGTH
        }
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          style={{ gap: '10px' }}
          data-testid="add-domain-popup"
        >
          <FormControl component="fieldset">
            <RadioGroup
              style={{ display: 'flex', flexDirection: 'row' }}
              aria-label="domainType"
              name="domainType"
              value={orgType}
              onChange={e => {
                setOrgType(e.target.value);
              }}
            >
              {['Internal', 'External'].map(val => (
                <FormControlLabel
                  // disabled={isEdit}
                  key={val}
                  value={val}
                  control={
                    <Radio
                      color="primary"
                      inputProps={{
                        'data-testid': `${val}-domain-type`,
                      }}
                    />
                  }
                  label={val}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <Box
            display={'flex'}
            flexDirection={'column'}
            style={{ gap: '15px' }}
          >
            <Box>
              <Typography
                style={{ fontWeight: 500, fontSize: '12px', color: '#344054' }}
              >
                Domain name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                value={domainName}
                onChange={e => setDomainName(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box display={'flex'} style={{ gap: '5px' }}>
                        <Typography>@</Typography>
                        <Divider orientation="vertical" flexItem />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: '10px 10px 10px 0px',
                    color: 'black',
                  },
                  'data-testid': 'domain-name-text-field',
                }}
                variant="outlined"
                error={domainName.length > DETAILS_MAX_LENGTH}
                helperText={
                  domainName.length > DETAILS_MAX_LENGTH &&
                  `Domain name shouldn't be more than ${DETAILS_MAX_LENGTH} characters`
                }
              />
            </Box>
            {!isInternalDomain && (
              <Box>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#344054',
                  }}
                >
                  Organization name <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  value={orgName}
                  onChange={e => setOrgName(e.target.value)}
                  fullWidth
                  inputProps={{
                    style: {
                      padding: '10px 10px 10px 10px',
                      color: 'black',
                    },
                    'data-testid': 'domain-org-name',
                  }}
                  variant="outlined"
                  error={orgName.length > DETAILS_MAX_LENGTH}
                  helperText={
                    orgName.length > DETAILS_MAX_LENGTH &&
                    `Organization name shouldn't be more than ${DETAILS_MAX_LENGTH} characters`
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
        <SimpleBackdrop open={loading} />
      </CustomizedDialog>
    </>
  );
};

const DeleteDomainPopup = ({
  domainDetails,
  handleClickClose,
  open,
  orgId,
  fetchAllDomains,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleOnDelete = () => {
    setLoading(true);
    OrganizationApi.deleteDomain({ orgId, domainId: domainDetails?.id })
      .then(res => {
        enqueueSnackbar(`Domain deleted successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setLoading(false);
        fetchAllDomains();
        handleClickClose();
      })
      .catch(err => {
        enqueueSnackbar(err.response?.data?.details || `Something went wrong`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        setLoading(false);
      });
  };
  const classes = useStyles();
  return (
    <>
      <CustomizedDialog
        open={Boolean(open)}
        handleClickClose={handleClickClose}
        title={'Delete domain'}
        handleOnSave={handleOnDelete}
        customStyles={classes.domainPopup}
        primaryBtnTitle="Confirm"
      >
        <Box display={'flex'} flexDirection={'column'} style={{ gap: '10px' }}>
          <Typography>
            {`Are you sure you want to delete ${
              domainDetails?.domain || ''
            } domain ?`}
          </Typography>
        </Box>
        <SimpleBackdrop open={loading} />
      </CustomizedDialog>
    </>
  );
};

const OrganizationDomain = ({
  domainList,
  orgId,
  fetchAllDomains,
  isSuperAdmin,
  hasDeletePermissions,
  hasCreatePermissions,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const domainNames = new Map();
  const { info } = useSelector(state => state.userInfo);

  const [domainDetails, setDomainDetails] = useState();

  const handleDomainPopupType = (type, domain) => {
    if (type === 'add') {
      setOpen('add');
    } else if (type === 'edit') {
      setOpen('edit');
      setDomainDetails(domain);
      return;
    } else if (type === 'delete') {
      setOpen('delete');
      setDomainDetails(domain);
      return;
    } else if (type === 'close') {
      setOpen(false);
    }
    setDomainDetails();
  };

  return (
    <>
      {domainList.length > 0 &&
        domainList.map(domain => {
          if (!domainNames.has(domain.domain)) {
            domainNames.set(domain.domain, true);
          }
          return (
            <Box
              className={classes.domainContainer}
              style={{ marginRight: '5px', minHeight: '94px' }}
              key={domain.id}
              data-testid="domain-list"
            >
              <Box display={'flex'} alignItems={'center'}>
                <ReadonlyField label={`Domain name`} value={domain.domain} />
                <Box display={'flex'} alignItems={'center'}>
                  {(hasCreatePermissions ||
                    isSuperAdmin) && (
                    <IconButton
                      style={{ margin: 1, padding: 0 }}
                      onClick={() => handleDomainPopupType('edit', domain)}
                    >
                      <EditIcon
                        style={{ fontSize: '16px' }}
                        data-testid="domain-edit-icon"
                      />
                    </IconButton>
                  )}
                  {(hasDeletePermissions ||
                    isSuperAdmin) && (
                    <IconButton
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => {
                        handleDomainPopupType('delete', domain);
                      }}
                    >
                      <DeleteIcon
                        style={{ fontSize: '16px' }}
                        data-testid="domain-delete-icon"
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
              {!domain.internalDomain && (
                <Box display={'flex'} alignItems={'center'}>
                  <ReadonlyField
                    label={`Organization name`}
                    value={domain.orgName}
                  />
                </Box>
              )}
              <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={domain.internalDomain ? 'Internal' : 'External'}
                  style={{
                    color: '#005DB9',
                    fontWeight: 500,
                    fontSize: '11px',
                    textTransform: 'uppercase',
                  }}
                />
              </Box>
            </Box>
          );
        })}

      {(hasCreatePermissions ||
        isSuperAdmin) && (
        <Box
          style={{
            border: '2px dotted #667085',
            cursor: 'pointer',
          }}
          display={'flex'}
          alignItems={'center'}
          alignSelf={'center'}
          borderRadius={'50%'}
          padding={'10px'}
          onClick={() => {
            handleDomainPopupType('add');
          }}
          data-testid="add-domain-icon"
        >
          <AddIcon style={{ color: '#667085', fontSize: '14px' }} />
        </Box>
      )}

      <DomainPopup
        open={open && open !== 'delete'}
        handleClickClose={() => {
          handleDomainPopupType('close');
        }}
        isEdit={open === 'edit'}
        domainDetails={domainDetails}
        key={open}
        orgId={orgId}
        fetchAllDomains={fetchAllDomains}
        domainNames={domainNames}
      />

      <DeleteDomainPopup
        orgId={orgId}
        open={open === 'delete'}
        handleClickClose={() => {
          handleDomainPopupType('close');
        }}
        domainDetails={domainDetails}
        fetchAllDomains={fetchAllDomains}
      />
    </>
  );
};

export default OrganizationDomain;
