import { Component } from 'react';
import { DataGrid } from 'components/widgets';
import moment from 'moment';
import PhoneCellTemplate from './PhoneCellTemplate';
import ActionsCellTemplate from 'components/widgets/DataGrid/ActionsCellTemplate';
import {LABELS_FIELDS_BUTTONS} from 'shared/constants';

class UsersGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          field: 'firstName',
          headerName: 'Name',
          cellRenderer: 'FullNameRenderer',
        },
        {
          field: 'userRoles',
          headerName: 'Role',
          cellRenderer: 'RoleSetRenderer',
          hide: !props.isInternalUser,
        },
        {
          field: 'affiliates',
          headerName: 'Affiliate',
          cellRenderer: 'SetRenderer',
        },
        {
          field: 'plans',
          headerName: 'LOBs',
          cellRenderer: 'SetRenderer',
        },
        {
          field: 'emailAddress',
          headerName: `Email`,
        },
        // {
        //   field: 'phone',
        //   headerName: 'Phone',
        //   cellRenderer: 'PhoneRenderer',
        // },

        {
          field: 'createdAt',
          headerName: `${LABELS_FIELDS_BUTTONS.CREATED_ON}`,
          cellRenderer: 'DateFormatter',
        },
        {
          field: 'actions',
          headerName: '',
          width: 110,
          maxWidth: 110,
          pinned: 'right',
          cellRenderer: 'ActionsRenderer',
          resizable: false,
          sortable: false,
          filter: false,
        },
      ],

      defaultColDef: {
        flex: 1,
        minWidth: 200,
        resizable: true,
        sortable: true,
        filter: false,
      },
      frameworkComponents: {
        DateFormatter: rowData => {
          let formattedDate = moment(rowData.value).format(
            'MM/DD/YYYY hh:mm A'
          );
          return formattedDate;
        },
        PhoneRenderer: PhoneCellTemplate,
        ActionsRenderer: props => (
          <ActionsCellTemplate
            {...props}
            onEdit={item => this.onItemEdit(item)}
            onDelete={item => this.onItemDelete(item)}
            allowEdit
          />
        ),
        FullNameRenderer: rowData =>
          `${rowData.data.firstName} ${rowData.data.lastName}`,
        RoleSetRenderer: rowData => {
          return !rowData.value
            ? ''
            : rowData.value?.map(item => item.roleDisplayName).join(', ');
        },
        SetRenderer: rowData => {
          return !rowData.value
            ? ''
            : rowData.value?.map(item => item.name).join(', ');
        },
      },
      context: { componentParent: this },
    };
  }

  onItemEdit(data) {
    this.props.onEdit(data);
  }

  onItemDelete(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh' }}
            hideHeaderActions
          />
        </div>
      </div>
    );
  }
}

export default UsersGrid;
