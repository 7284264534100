import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BackendSearchTextField, SimpleBackdrop } from 'components/widgets';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import PrimaryButton from 'components/widgets/Buttons/PrimaryButton';
import CohortGrid from './CohortGrid';
import CreateEditCohort from './CreateEditCohort';
import CampaignApi from 'services/api/CampaignApi';
import { useParams } from 'react-router-dom';
import NotFoundPage from 'components/NotFoundPage';
import useInfiniteScroll from './useInfiniteScroll';
import { AlertDialog } from 'components/widgets';
import { CLOSE_ALERT_DESCRIPTION } from 'shared/constants';
import {
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
} from 'shared/constants';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
}));

const CohortPage = ({permissions}) => {
  const classes = useStyles();
  const orgId = sessionStorage.getItem("orgId");

  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [openCohort, setOpenCohort] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedCohort, setSelectedCohort] = useState();
  const [OnloadData, setOnloadData] = useState(false);
  const [disable, setDisable] = useState(true);
  const [flag, setFlag] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const {CREATE} = useHasPermissions({availablePermissions:permissions});

  const onPageNumberClick = () => {
    setPageNumber(prevPage => prevPage + 1);
  };
  const { lastElement } = useInfiniteScroll(onPageNumberClick);

  const getAllCohorts = async () => {
    setLoading(true);
    try {
      const results = await CampaignApi.getAllCohorts({
        orgId: orgId,
        offset: pageNumber,
        limit: 10,
        searchText: searchValue,
      });
      setOnloadData(true);

      setTotalPage(Math.floor(results?.totalItems / 10));
      if (searchFlag && pageNumber === 0) {
        setFilteredData(results.data);
      } else if (searchFlag && pageNumber !== 0) {
        setFilteredData(prev => [...prev, ...results.data]);
      } else {
        setFilteredData(prev => [...prev, ...results.data]);
      }

      setLoading(false);
      setOnloadData(true);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (searchValue === '') {
      setSearchFlag(false);
    } else {
      setSearchFlag(true);
    }
  }, [searchValue]);

  useEffect(() => {
    if (pageNumber <= totalPage) {
      getAllCohorts();
    }
  }, [pageNumber, searchText]);

  const onEditClick = id => {
    setSelectedCohort(filteredData.filter(item => item.id === id)[0]);
    setIsEdit(true);
    setOpenCohort(true);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };
  const onClearData = () => {
    setPageNumber(0);
    setSearchValue('');
    setSearchText('');
  };
  const onSearchClick = () => {
    setPageNumber(0);
    setSearchText(searchValue);
    setIsInitialRender(false);
  };

  const handleCloseCohort = () => {
    if (isEdit ? !disable : flag) {
      setCloseAlert(true);
    } else {
      setOpenCohort(false);
      setIsEdit(false);
      setSelectedCohort();
    }
  };

  const closeOnCohortCreation = () => {
    setOpenCohort(false);
    setIsEdit(false);
    setSelectedCohort();
  };

  const closeConfirm = () => {
    setOpenCohort(false);
    setCloseAlert(false);
    setIsEdit(false);
    setSelectedCohort();
    setDisable(true);
    setFlag(false);
  };

  const createCohortClick = () => {
    setOpenCohort(true);
  };

  const handleCreateCohort = () => {
    setOpenCohort(true);
    setSearchText('');
    setSearchValue('');
    setPageNumber(0);
    if (filteredData?.length === 0 && searchValue === '' && searchText === '') {
      getAllCohorts();
    }
  };

  const refetchCohorts = () => {
    setFilteredData([]);
    setPageNumber(0);
    setSearchValue('');
    setSearchText('');
    getAllCohorts();
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}>
          {(!isInitialRender || filteredData?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}>
                  <BackendSearchTextField
                    label="Search by cohort name"
                    data-testid="backend-search"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  data-testid="search-btn"
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}>
                  Search
                </SecondaryButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                }}>
                <PrimaryButton
                  data-testid="create-cohort"
                  onClick={handleCreateCohort}
                  hasPermissions={CREATE}
                  >
                  Create a cohort
                </PrimaryButton>
              </div>
            </>
          )}
          {filteredData.map((currentElem, i) => {
            const { id } = currentElem;
            return (
              <CohortGrid
                key={id}
                {...currentElem}
                onEditClick={onEditClick}
                filteredData={filteredData}
                loading={loading}
                cohortId={id}
                lastElementObserver={
                  Number(pageNumber) < Number(totalPage) ? lastElement : null
                }
                isLastElement={filteredData?.length - 1 === i}
                cohortValue={currentElem}
                orgId={orgId}
                hasEditPermission={CREATE}
              />
            );
          })}
        </div>
      </Grid>
      {filteredData?.length === 0 && OnloadData && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={
              !isInitialRender
                ? EMPTY_STATE_PAGE.COHORT_TITLE
                : INITIAL_RENDER_NO_DATA_PAGE.COHORT_TITLE
            }
            content={
              !isInitialRender
                ? EMPTY_STATE_PAGE.COHORT_CONTENT
                : INITIAL_RENDER_NO_DATA_PAGE.COHORT_CONTENT
            }
            titleContent={INITIAL_RENDER_NO_DATA_PAGE.COHORT_TITLE_CONTENT}
            showButton={(!isInitialRender || !CREATE) ? false : true}
            onNotFoundClick={createCohortClick}
          />
        </div>
      )}

      {openCohort && (
        <CreateEditCohort
          open={openCohort}
          handleClose={handleCloseCohort}
          isEdit={isEdit}
          disable={disable}
          setDisable={setDisable}
          cohortData={selectedCohort}
          refetchCohorts={refetchCohorts}
          setFlag={setFlag}
          closeOnCohortCreation={closeOnCohortCreation}
        />
      )}

      {closeAlert && (
        <AlertDialog
          open={closeAlert}
          onClose={() => setCloseAlert(false)}
          onConfirm={closeConfirm}
          title="Alert"
          description={CLOSE_ALERT_DESCRIPTION}
        />
      )}

      <SimpleBackdrop open={loading} />
    </>
  );
};

export default CohortPage;
