import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  dropDownWrapper: {
    minWidth: 120,
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '10px',
    marginRight: 12,
    fontFamily: 'Inter',
    height: '40px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#4B4B4B',
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
  },
  listTag: {
    flex: 1,
    height: 40,
  },
  selectRoot: {
    fontSize: '16px',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },
  dropDownMenuList: {
    fontSize: '16px',
    paddingLeft: 0,
    paddingRight: 0,
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxHeight: 40,
    fontWeight: 600,
    minWidth: '30px',
  },
  img: {
    width: 8,
    height: 8,
    marginRight: 8,
    paddingRight: 8,
  },
  // muiIcon: { width: 12, height: 12, paddingRight: 4 },
  label: {
    height: 24,
    padding: 0,
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  filterType: {
    lineHeight: 0,
    paddingLeft: 8,
    minWidth: 'max-content',
    fontSize: 14,
    fontWeight: 600,
    color: '#737373',
  },
}));

const ConsentDropdown = props => {
  const { changeHandler, filterType } = props;
  const classes = useStyles();
  const [value, setValue] = useState(props.value);
  const onChange = val => {
    setValue(val);
    changeHandler(val);
  };

  return (
    <FormControl className={classes.dropDownWrapper}>
      {props.inputLabel && (
        <InputLabel id="inputLabel">{props.inputLabel}</InputLabel>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <p className={classes.filterType}>{filterType || ''}</p>
      </div>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          MenuListProps: {
            disablePadding: true,
          },
          getContentAnchorEl: null,
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue="10"
        disableUnderline
        // disableGutters={true}
        value={value}
        onChange={onChange}
        {...props}
        IconComponent={KeyboardArrowDown}>
        {props.options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              //   disableGutters={true}
              value={option.value}
              className={classes.listTag}>
              <div className={classes.labelWrapper}>
                {option.icon && (
                  <span>
                    <img src={option.icon} alt="icon" className={classes.img} />
                  </span>
                )}
                <p className={classes.label}>{option.label || option.value}</p>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default ConsentDropdown;
