import Pagination from '@material-ui/lab/Pagination';
import { PropTypes } from 'prop-types';

const PaginationComponent = props => {
  const { count, numberOfRowsPerPage, onPageClick, page } = props;
  const pagecount = Math.ceil(count / numberOfRowsPerPage);
  const pageNumber = page + 1;
  return (
    <Pagination
      count={pagecount}
      page={pageNumber}
      data-testid="pagination-component"
      size="medium"
      color="primary"
      style={{ display: 'flex', justifyContent: 'center' }}
      onChange={(event, value) => {
        onPageClick(value);
      }}
    />
  );
};

PaginationComponent.propTypes = {
  setPageNumber: PropTypes.func,
  numberOfRowsPerPage: PropTypes.number,
  datalength: PropTypes.number,
};
export default PaginationComponent;
