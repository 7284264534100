import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ApprovalPending from '../../assets/ApprovalPending.svg';
import RevokedStatus from '../../assets/Revoked_status_image.svg';
import { makeStyles } from '@material-ui/core/styles';
import { DEVELOPER_SIGNUP_CONSTANTS } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#FFFFFF',
    height: '304px',
    maxWidth: '560px',
    margin: '10% auto 0px',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '24px',
    padding: '40px',
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#101828',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
}));

const ApprovalPendingPage = (status) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container}>
        <img
          src={(status.status === 'Revoked' || status.status === 'Declined') ? RevokedStatus : ApprovalPending}
          alt={'Pending/Revoked state icon'}
          style={{ height: '120px' }}
        />
        <Typography className={classes.title}>
          {status.status === 'Expired'
            ? DEVELOPER_SIGNUP_CONSTANTS.EXPIRED_TITLE
            : status.status === 'Revoked' ? DEVELOPER_SIGNUP_CONSTANTS.REVOKED_TITLE : status.status === 'Declined' ? DEVELOPER_SIGNUP_CONSTANTS.DECLINED_TITLE : DEVELOPER_SIGNUP_CONSTANTS.APPROVAL_PENDING_TITLE}
        </Typography>
        <Typography className={classes.subtitle}>
          {status.status === 'Expired'
            ? DEVELOPER_SIGNUP_CONSTANTS.EXPIRED_SUBTITLE
            : status.status === 'Revoked' ? DEVELOPER_SIGNUP_CONSTANTS.REVOKED_SUTITLE : status.status === 'Declined' ? DEVELOPER_SIGNUP_CONSTANTS.DECLINED_SUTITLE : DEVELOPER_SIGNUP_CONSTANTS.APPROVAL_PENDING_SUBTITLE}
        </Typography>
      </Box>
    </Box>
  );
};

export default ApprovalPendingPage;
