import { PERMISSION_CODES, RESOURCES } from 'shared/constants';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import moment from 'moment';
import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

export const DownloadRawFile = (link, fileName) => {
  let a = document.createElement('a');
  a.href = link;
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const formattedDate = value => {
  let expiryDate = '';
  let date = new Date();
  let offsetValue = date.getTimezoneOffset();

  if (value === 'Next week') {
    expiryDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 7
    );
  } else if (value === 'Next 1 month') {
    expiryDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
  } else if (value === 'Next 3 month') {
    expiryDate = new Date(
      date.getFullYear(),
      date.getMonth() + 3,
      date.getDate()
    );
  }

  return new Date(expiryDate.getTime() - offsetValue * 60 * 1000)
    .toISOString()
    .slice(0, -1)
    .split('T')
    .join(' ');
};

export const constructNameForS3 = name => {
  if (RESOURCES.includes(name)) {
    return name;
  } else {
    return name
      .split('')
      .map(character => {
        if (!character.match(/[a-z0-9-.]/gi)) {
          return '-';
        }
        return character.toLowerCase();
      })
      .join('');
  }
};

export const convertToSentenceCase = data => {
  let firstChar = data?.[0];
  return firstChar + data?.slice(1, data.length).toLowerCase();
};

const convertToDate = (value, date) => {
  let convertedDate = new Date(date);
  let tempVal = value.split(':');
  convertedDate.setHours(tempVal[0]);
  convertedDate.setMinutes(tempVal[1]);
  convertedDate.setSeconds(tempVal[2]);
  return convertedDate;
};

export const validateTimeZone = (
  selectedTime,
  selectedStartDate,
  selectedZone
) => {
  let currentZone = moment().toDate().toString();

  if (currentZone.includes('Pacific')) {
    if (
      selectedZone === 'EST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(3, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'CST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(2, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'MST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(1, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'PST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(moment())
    ) {
      return 'Invalid';
    }
  }

  if (currentZone.includes('Mountain')) {
    if (
      selectedZone === 'EST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(2, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'CST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(1, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'PST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(-1, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'MST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(moment())
    ) {
      return 'Invalid';
    }
  }

  if (currentZone.includes('Central')) {
    if (
      selectedZone === 'EST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(1, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'MST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(-1, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'PST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(-2, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'CST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(moment())
    ) {
      return 'Invalid';
    }
  }

  if (currentZone.includes('Eastern')) {
    if (
      selectedZone === 'CST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(-1, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'MST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(-2, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'PST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(
        moment().add(-3, 'hours')
      )
    ) {
      return 'Invalid';
    } else if (
      selectedZone === 'EST' &&
      moment(convertToDate(selectedTime, selectedStartDate)).isBefore(moment())
    ) {
      return 'Invalid';
    }
  }

  return 'Valid';
};

export const statusColorMap = {
  DRAFT: '#FB8900',
  Draft: '#FB8900',
  Executed: '#288719',
  EXECUTED: '#288719',
  Cancelled: '#DB0032',
  CANCELLED: '#FB8900',
  Failed: '#DB0032',
  FAILED: '#DB0032',
  QUERY_BUILDING_FAILED: '#DB0032',
  EXECUTION_FAILED: '#DB0032',
  RESULT_MERGE_FAILED: '#DB0032',
  Abort: '#DB0032',
  ABORT: '#DB0032',
  Scheduled: '#036FCB',
  SCHEDULED: '#036FCB',
  PUBLISHED: '#288719',
  Published: '#288719',
  PUBLISHED: '#288719',
  COMPLETED: '#288719',
  RESOLVED: '#288719',
  IN_PROGRESS: '#FB8900',
  PENDING: '#FB8900',
  Pending: '#FB8900',
  pending: '#FB8900',
  Approved: '#288719',
  Active: '#288719',
  Enabled: '#288719',
  Revoked: '#DB0032',
  Disabled: '#DB0032',
  Declined: '#DB0032',
  Denied: '#DB0032',
  expired: '#DB0032',
  Expired: '#DB0032',
  EXPIRED: '#DB0032',
  failed: '#DB0032',
  Email_Verification_Failed: '#DB0032',
};

export const convertStrToTitleCase = str => {
  const newStr = str
    ?.split(' ')
    ?.map(w => {
      const word = w.trim();
      if (word.length > 1) {
        const statusText =
          word?.[0].toUpperCase() + word.substring(1).toLowerCase();
        return statusText?.replaceAll('_', ' ');
      }
      return w;
    })
    .join(' ');
  return newStr;
};

export const formatTimeInHHMMWithComma = dateString => {
  const date = new Date(dateString);
  if (!date) {
    return '';
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const combineNameAndId = (name, id) => {
  return `${constructNameForS3(name)}-${id}`;
};

export const downloadMemberJSON = (fileName, ...data) => {
  const BlobArray = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]) BlobArray.push(JSON.stringify(data[i], null, 2));
  }
  const blob = new Blob(BlobArray, { type: 'text/json' });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const tempDate = (date, timeline) => {
  if (timeline === 'Weekly') {
    return moment(date).clone().add(7, 'days').format('MM-DD-YYYY');
  } else if (timeline === 'Monthly') {
    return moment(date).clone().add(1, 'months').format('MM-DD-YYYY');
  } else if (timeline === 'Yearly') {
    return moment(date).clone().add(1, 'years').format('MM-DD-YYYY');
  }
};

export const singleMemberDownload = async (
  _affiliateId,
  _memberId,
  fileName,
  enqueueSnackbar
) => {
  enqueueSnackbar('Downloading data', { variant: 'info' });
  try {
    const memberRecord = await OrganizationUsersApi.singleMemberDownload(
      _affiliateId,
      _memberId
    );
    downloadMemberJSON(fileName, ...memberRecord);
    enqueueSnackbar('Downloaded data successfully', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Something went wrong downloading data', {
      variant: 'error',
    });
  }
};

export const checkValidity = (value, rules) => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

let Base64 = {
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  encode: function (e) {
    var t = '';
    var n, r, i, s, o, u, a;
    var f = 0;
    e = Base64._utf8_encode(e);
    while (f < e.length) {
      n = e.charCodeAt(f++);
      r = e.charCodeAt(f++);
      i = e.charCodeAt(f++);
      s = n >> 2;
      o = ((n & 3) << 4) | (r >> 4);
      u = ((r & 15) << 2) | (i >> 6);
      a = i & 63;
      if (isNaN(r)) {
        u = a = 64;
      } else if (isNaN(i)) {
        a = 64;
      }
      t =
        t +
        this._keyStr.charAt(s) +
        this._keyStr.charAt(o) +
        this._keyStr.charAt(u) +
        this._keyStr.charAt(a);
    }
    return t;
  },
  decode: function (e) {
    var t = '';
    var n, r, i;
    var s, o, u, a;
    var f = 0;
    e = e.replace(/[^A-Za-z0-9+/=]/g, '');
    while (f < e.length) {
      s = this._keyStr.indexOf(e.charAt(f++));
      o = this._keyStr.indexOf(e.charAt(f++));
      u = this._keyStr.indexOf(e.charAt(f++));
      a = this._keyStr.indexOf(e.charAt(f++));
      n = (s << 2) | (o >> 4);
      r = ((o & 15) << 4) | (u >> 2);
      i = ((u & 3) << 6) | a;
      t = t + String.fromCharCode(n);
      if (u !== 64) {
        t = t + String.fromCharCode(r);
      }
      if (a !== 64) {
        t = t + String.fromCharCode(i);
      }
    }
    t = Base64._utf8_decode(t);
    return t;
  },
  _utf8_encode: function (e) {
    e = e.replace(/rn/g, 'n');
    var t = '';
    for (var n = 0; n < e.length; n++) {
      var r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode((r >> 6) | 192);
        t += String.fromCharCode((r & 63) | 128);
      } else {
        t += String.fromCharCode((r >> 12) | 224);
        t += String.fromCharCode(((r >> 6) & 63) | 128);
        t += String.fromCharCode((r & 63) | 128);
      }
    }
    return t;
  },
  _utf8_decode: function (e) {
    //var c1 = 0;
    var c2 = 0;
    var c3 = '';
    var t = '';
    var n = 0;
    var r = (c2 = 0);
    while (n < e.length) {
      r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
        n++;
      } else if (r > 191 && r < 224) {
        c2 = e.charCodeAt(n + 1);
        t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
        n += 2;
      } else {
        c2 = e.charCodeAt(n + 1);
        c3 = e.charCodeAt(n + 2);
        t += String.fromCharCode(
          ((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        n += 3;
      }
    }
    return t;
  },
};

export default Base64;

export const getDateWithLocalTimeZone = date => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateInCurrentTimeZone = formatInTimeZone(
    new Date(date),
    localTimeZone,
    'yyyy-MM-dd'
  );
  return dateInCurrentTimeZone;
};

export const getDomainName = url => {
  if (url.includes('localhost')) {
    return 'cigna';
  } else {
    let name = url.split('-developer')[0].split('//')[1];

    if (name.includes('-')) {
      name = name.split('-')[1];
    }

    return name;
  }
};

export const useHasPermissions = ({
  availablePermissions,
  featureName = '',
  subFeatureName = '',
  manageOrg = false,
}) => {
  const { solutionPermissions } = useSelector(state => state.userInfo);
  const [permissions, setPermissions] = useState({
    READ: false,
    CREATE: false,
    DELETE: false,
    APPROVE: false,
  });

  useEffect(() => {
    let solutionId = '';
    solutionPermissions.forEach(function (value, key) {
      if (value?.solutionName === '-') {
        solutionId = value?.solutionId;
      }
    });
    let subFeature = null;
    if (featureName) {
      const selectedSolution = solutionPermissions?.get(
        manageOrg
          ? solutionId.toString()
          : localStorage.getItem('selectedSolutionId')
      );

      subFeature = selectedSolution?.features?.find(
        feature => feature?.featureName === featureName
      )?.subFeatures;

      if (subFeatureName) {
        subFeature = subFeature?.filter(
          item => item?.subFeatureName === subFeatureName
        )?.[0]?.selectedPermissions;
      }
    }

    const availablePermissionsMap = new Set(subFeature ?? availablePermissions);
    Object.keys(PERMISSION_CODES).forEach(permissionKey => {
      if (availablePermissionsMap.has(PERMISSION_CODES[permissionKey])) {
        permissions[permissionKey] = true;
      }
    });
    setPermissions({ ...permissions });
  }, [availablePermissions, featureName, subFeatureName, solutionPermissions]);

  return permissions;
};

export const useGetManageOrgSolutionId = () => {
  const { solutionPermissions } = useSelector(state => state.userInfo);

  let solutionId = '';
  solutionPermissions.forEach(function (value, key) {
    if (value?.solutionName === '-') {
      solutionId = value?.solutionId;
    }
  });

  return solutionId;
};

export const useGetAppTypeBasedOnSolution = () => {
  const { solutionPermissions } = useSelector(state => state.userInfo);

  let xchangeAccess, complyAccess, connectAccess, nexusAccess;

  solutionPermissions.forEach(function (value) {
    if (value?.solutionName === 'xchange') {
      xchangeAccess = value.isActive;
    }
    if (value?.solutionName === 'comply') {
      complyAccess = value.isActive;
    }
    if (value?.solutionName === 'connect') {
      connectAccess = value.isActive;
    }
    if (value?.solutionName === 'nexus') {
      nexusAccess = value.features
        .filter(item => item.featureName === 'Developer portal')?.[0]
        ?.subFeatures.filter(
          item => item.subFeatureName === 'Applications'
        )?.[0]
        ?.selectedPermissions.includes(PERMISSION_CODES.CREATE);
    }
  });

  return {
    xchangeAccess: xchangeAccess,
    complyAccess: complyAccess,
    connectAccess: connectAccess,
    nexusAccess: nexusAccess,
  };
};


export const solutionAndSubFeatureMapping = (permissionsResponse) => {
  const solutionPermissionMap = new Map();
  const subFeaturePermissionMap = new Map();
  permissionsResponse &&
    permissionsResponse?.forEach(solution => {
      solution?.features?.forEach(feature => {
        let hasAnySubFeaturePermissions = false;
        feature?.subFeatures?.forEach(subFeature => {
          const keyName = `${solution.solutionId}-${feature?.featureName}-${subFeature?.subFeatureName}`;
          if(subFeature.selectedPermissions?.length) hasAnySubFeaturePermissions = true
          subFeaturePermissionMap.set(
            keyName,
            subFeature?.selectedPermissions
          );
        });
        feature.hasAnySubFeaturePermissions = hasAnySubFeaturePermissions
      });
      if(solution.solutionName === '-'){
        solutionPermissionMap.set('manage-org',solution)
      }
      solutionPermissionMap.set(String(solution.solutionId), solution);
    });

  return {solutionPermissionMap,subFeaturePermissionMap}
}
