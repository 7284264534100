import { makeStyles, Container } from '@material-ui/core';
import Page from 'components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

const DocumentationPage = ({ title }) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title={title}
      showHeader
      headerText="Documentation">
      <Container>
        <div style={{marginTop: 32}}>Documentation content</div>
      </Container>
    </Page>
  );
};

export default DocumentationPage;
