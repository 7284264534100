import { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function ProfileMenu({ onClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  // const authState = useSelector(state => state.auth);

  // const { userInfo } =
  //   authState && authState.authInfo ? authState.authInfo : {};
  const { info: userInfo } = useSelector(state => state.userInfo);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = action => {
    setAnchorEl(null);

    if (action) {
      onClick(action);
    }
  };

  return (
    <div>
      <IconButton
        aria-controls="profile-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        >
        <KeyboardArrowDownIcon />
      </IconButton>

      <Menu
        id="profile-menu"
        style={{marginTop:"40px"}}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => handleClose('profile')}>Profile</MenuItem>
        <MenuItem onClick={() => handleClose()}>My account</MenuItem>
        <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
