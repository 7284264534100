import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  BackendSearchTextField,
  SecondaryButton,
  SimpleBackdrop,
  AlertDialog,
} from 'components/widgets';
import React, { useState, useEffect } from 'react';
import AttributeGrid from './AttributeGrid';
import AttributeDetail from './AttributeDetail';
import AttributeMainGrid from './attributeDetails/AttributeMainGrid';
import AttributeApi from 'services/api/AttributeApi';
import FilterDropDown from 'components/FilterDropDown';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import AttributePage from './AttributePage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import RulesEngineApi from 'services/api/RuleEngineApi';
import NotFoundPage from 'components/NotFoundPage';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import { useSnackbar } from 'notistack';
import {
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
  TypeofAttributeFilterOptions,
} from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contained2: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
}));

const AttributeListingPage = ({
  value,
  isSuperAdmin,
  options,
  createAttributeOpenState,
  onCloseCreate,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredAttribute, setFilteredAttribute] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isApiCallRequired, setIsApiCallRequired] = useState(false);
  const [attributeFilter, setAttributeFilter] = useState('FHIR');
  const [open, setOpen] = useState(false);
  const [attriDetails, setAttriDetails] = useState({});
  const [attrTitle, setAttrTitle] = useState('');
  const [orgList, setOrgList] = useState([]);
  const [attributeDetails, setAttributeDetails] = useState();
  const [attributeOrgs, setAttributeOrgs] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [isLastOffset, setIsLastOffset] = useState(false);
  const [isNotPageFound, setIsNotPageFound] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const styles = {
    TabContainer: {
      backgroundColor: '#F5F5F5',
      position: 'relative',
      //   marginLeft: 32,
    },

    TabHeading: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
    },

    cardContainer: {
      minHeight: '100%',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      paddingTop: 20,
    },
    cardWrapper: { marginBottom: 32, marginRight: 32 },
    TabsWrapper: {
      borderBottom: '2px solid #D9D9D9',
      height: 62,
    },
  };

  const handleCloseAttributeCreation = () => {
    setOpen(false);
    setIsEdit(false);
    setAttributeDetails();
    setAttributeOrgs();
    createAttributeOpenState = false;
    onCloseCreate();
  };

  const orgId = sessionStorage.getItem("orgId");

  const fetchAttribute = async () => {
    const res = await AttributeApi.getAttribute({
      orgId: orgId ?? '',
      searchName: searchValue,
      searchType: value === 0 ? 'FHIR' : 'NON_FHIR',
      offset: offset,
    });
    setOnloadData(true);
    return res;
  };

  const getAllAttributes = async () => {
    try {
      setLoading(true);
      const results = await fetchAttribute();
      if (results?.data?.length <= 0 && isNotPageFound) {
        setFilteredAttribute(prev => prev);
        setIsLastOffset(true);
      } else if (results?.data?.length <= 0 && !isNotPageFound) {
        setFilteredAttribute(results?.data);
      } else {
        setFilteredAttribute(results?.data);
      }
      results.totalItems && setTotalCount(results.totalItems);
      setLoading(false);
      setOnloadData(true);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);

      console.log('error: ', error);
    }
  };

  useEffect(() => {
    getAllAttributes();
  }, [offset, searchText, attributeFilter]);

  useEffect(() => {
    onClearData();
    getAllAttributes();
  }, [value]);

  useEffect(() => {
    if (createAttributeOpenState) {
      handleCreateAttribute();
    }
  }, [createAttributeOpenState]);

  const getAttributeDetails = async attriId => {
    try {
      const results = await AttributeApi.attributeDetails({ attriId });
      setOnloadData(true);

      if (!results.existingOrgAddition) {
        getOrgList(attriId);
      }

      if (results.existingOrgAddition && results.futureOrgAddition) {
        let array = [
          {
            id: 'existing',
            name: 'Add this attribute in all existing organizations',
          },
          {
            id: 'future',
            name: 'Add this attribute in all future organizations',
          },
        ];
        setOrgList(array);
      } else if (results.futureOrgAddition) {
        let array = [
          {
            id: 'future',
            name: 'Add this attribute in all future organizations',
          },
        ];
        setOrgList(array);
      } else if (results.existingOrgAddition) {
        let array = [
          {
            id: 'existing',
            name: 'Add this attribute in all existing organizations',
          },
        ];
        setOrgList(array);
      }
      setAttrTitle(results.name);
      setAttriDetails(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);

      console.log('error: ', error);
    }
  };

  const handleCreateAttribute = () => {
    setOpen(true);
    setSearchText('');
    setSearchValue('');
    setAttributeFilter('FHIR');
    if (
      setFilteredAttribute?.length === 0 &&
      searchValue === '' &&
      searchText === '' &&
      setAttributeFilter('FHIR')
    ) {
      getAllAttributes();
    }
  };

  const getOrgList = async orgId => {
    setLoading(true);
    try {
      const results = await RulesEngineApi.getOrgnizationList({ orgId });
      results[0].id && setOrgList(prev => [...prev, ...results]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setAttriDetails({});
    setOrgList([]);
  };

  const handleEdit = () => {
    setDrawerOpen(false);
    setIsEdit(true);
    setAttributeDetails({
      name: attriDetails.name,
      type: attriDetails.type,
      description: attriDetails.description,
      details: attriDetails.details,
      dataSource: attriDetails?.details?.dataSource,
      query: attriDetails?.details?.query,
      parameters: attriDetails?.details?.parameters,
    });
    setAttributeOrgs({
      existingFlag: attriDetails.existingOrgAddition,
      futureFlag: attriDetails.futureOrgAddition,
      orgIdArray: attriDetails.existingOrgAddition
        ? []
        : orgList.map(item => item.id),
    });
    setOpen(true);
    setOrgList([]);
  };

  const typeofattributeFilterOptions = {
    options: TypeofAttributeFilterOptions,
    value: attributeFilter,
    changeHandler: ev => {
      setIsInitialRender(false);
      setIsNotPageFound(false);
      setAttributeFilter(ev.target.value);
      offset != 0 && setOffset(0);
    },
  };

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setIsInitialRender(false);
    setIsNotPageFound(false);
    setSearchText(searchValue);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
  };

  const onViewClick = item => {
    getAttributeDetails(item.id);
    setDrawerOpen(true);
  };

  const refetchAttributes = () => {
    offset !== 0 ? setOffset(0) : getAllAttributes();
  };

  const leftClick = async () => {
    if (offset !== 0) {
      setOffset(prevValue => prevValue - 1);
    }
    setLoading(true);
    setIsNotPageFound(true);
    setIsLastOffset(false);
    setLoading(false);
  };

  const rightClick = async () => {
    if (!isLastOffset) {
      setLoading(true);
      setIsNotPageFound(true);
      setOffset(prevValue => prevValue + 1);
    }
    setLoading(false);
  };

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const onDeleteClick = item => {
    setDeleteId(item.id);
    setAlertDialog({
      open: true,
      title: 'Delete attribute',
      description: 'Are you sure you want to delete this attribute?',
    });
  };

  const onDeleteConfirm = async () => {
    setAlertDialog({ open: false });
    setLoading(true);
    try {
      await AttributeApi.deleteAttribute(deleteId);
      enqueueSnackbar('Attribute deleted successfully', { variant: 'success' });
      setLoading(false);
      refetchAttributes();
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        error.response.data?.details
          ? error.response.data.details
          : 'Something went wrong',
        { variant: 'error' }
      );
    }
  };

  return (
    <>
      <Grid container className={classes.container} data-testid="attribute">
        <Box sx={{ width: '100%' }} style={styles.TabContainer}>
          <Box style={{ width: '100%', height: '100%' }}>
            <div
              className={isSuperAdmin ? classes.contained2 : classes.container}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    label="Search by attribute name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  data-testid="search"
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                >
                  Search
                </SecondaryButton>

                {/* {isSuperAdmin && (
                      <div
                        style={{
                          display: 'flex',
                          paddingLeft: '20px',
                          marginTop: '-10px',
                        }}>
                        <FilterDropDown
                          {...typeofattributeFilterOptions}
                          filterType="Type of attribute:"
                        />
                      </div>
                    )} */}
              </div>
            </div>

            {/* FHIR tab code */}
            {/* {value === 0 && */}
            {(!isInitialRender || filteredAttribute?.length > 0) && (
              <>
                {isSuperAdmin && (
                  <>
                    {filteredAttribute?.length > 0 && (
                      <Grid item xs={12}>
                        <AttributeGrid
                          apiLoading={loading}
                          rowData={filteredAttribute}
                          onView={onViewClick}
                          onDelete={onDeleteClick}
                          tabValue={value}
                        />
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '5px 0px',
                          }}
                        >
                          <Button
                            style={{ borderRadius: '24px' }}
                            onClick={leftClick}
                            disabled={offset === 0}
                          >
                            <ChevronLeftIcon
                              color={offset === 0 ? 'disabled' : 'primary'}
                            />
                          </Button>
                          <Button
                            style={{ borderRadius: '24px' }}
                            onClick={rightClick}
                            disabled={
                              filteredAttribute.length < 10 || isLastOffset
                            }
                          >
                            <ChevronRightIcon
                              color={
                                filteredAttribute.length < 10 || isLastOffset
                                  ? 'disabled'
                                  : 'primary'
                              }
                            />
                          </Button>
                        </Box>
                      </Grid>
                    )}

                    <FullScreenDialog
                      title={attriDetails?.name}
                      open={drawerOpen}
                      handleClose={handleClose}
                    >
                      <AttributeDetail
                        attriDetails={attriDetails}
                        orgList={orgList}
                        handleEdit={handleEdit}
                      />
                    </FullScreenDialog>
                  </>
                )}
                {!isSuperAdmin && (
                  <Grid item xs={12}>
                    {filteredAttribute?.length > 0 && (
                      <>
                        <AttributeMainGrid
                          apiLoading={loading}
                          rowData={filteredAttribute}
                          onView={onViewClick}
                        />
                        <Box style={{ marginBottom: '10px' }}>
                          <PaginationComponent
                            numberOfRowsPerPage={10}
                            count={totalCount}
                            onPageClick={paginationClick}
                            page={offset}
                          />
                        </Box>
                      </>
                    )}
                  </Grid>
                )}
              </>
            )}

            {/* Non-FHIR tab code */}
            {/* {value === 1 && 'non fhir'} */}
          </Box>
        </Box>

        {/* code end for tabs */}
      </Grid>
      {!isSuperAdmin &&
        filteredAttribute?.length === 0 &&
        isInitialRender &&
        OnloadData && (
          <div style={{ marginTop: '32px' }}>
            <NotFoundPage
              title={INITIAL_RENDER_NO_DATA_PAGE.ATTRIBUTE_TITLE}
              content={INITIAL_RENDER_NO_DATA_PAGE.IT_ADMIN_ATTRIBUTE_CONTENT}
              showButton={true}
              titleContent={'Send email'}
            />
          </div>
        )}
      {isSuperAdmin &&
        filteredAttribute?.length === 0 &&
        isInitialRender &&
        OnloadData && (
          <div style={{ marginTop: '32px' }}>
            <NotFoundPage
              title={INITIAL_RENDER_NO_DATA_PAGE.ATTRIBUTE_TITLE}
              content={
                INITIAL_RENDER_NO_DATA_PAGE.SUPER_ADMIN_ATTRIBUTE_CONTENT
              }
              showButton={true}
              titleContent={'Create attribute'}
              onNotFoundClick={() => setOpen(true)}
            />
          </div>
        )}

      {isSuperAdmin && filteredAttribute?.length === 0 && !isInitialRender && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.ATTRIBUTE_TITLE}
            content={EMPTY_STATE_PAGE.ATTRIBUTE_CONTENT}
            showButton={false}
          />
        </div>
      )}
      {!isSuperAdmin && filteredAttribute?.length === 0 && !isInitialRender && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.ATTRIBUTE_TITLE}
            content={EMPTY_STATE_PAGE.ATTRIBUTE_CONTENT}
            showButton={false}
          />{' '}
        </div>
      )}
      {open && (
        <AttributePage
          options={options}
          open={open}
          handleCloseAttributeCreation={handleCloseAttributeCreation}
          refetchAttributes={refetchAttributes}
          attributeDetails={attributeDetails}
          setAttributeDetails={setAttributeDetails}
          attributeOrgs={attributeOrgs}
          setAttributeOrgs={setAttributeOrgs}
          isEdit={isEdit}
          attributeId={attriDetails.id ? attriDetails.id : null}
          attributeTitle={attrTitle}
        />
      )}
      {alertDialog.open && (
        <AlertDialog
          onClose={() => setAlertDialog({ open: false })}
          onConfirm={onDeleteConfirm}
          loading={loading}
          {...alertDialog}
        />
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default AttributeListingPage;
