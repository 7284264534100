// import BaseApiService from './BaseApiService';
import SessionService from '../SessionService';
import UserRolesApiService from './UserRolesApiService';
import { storePermissions } from '../AbilityService';
import faker from 'faker';

const login = payload => {
  return new Promise((resolve, reject) => {
    if (
      ['super-admin', 'admin', 'dev-user', 'compliance-admin'].includes(
        payload.userName
      ) &&
      payload.password === 'admin@123'
    ) {
      UserRolesApiService.getRolesAndPermissions(payload.userName).then(
        roles => {
          storePermissions(roles);

          resolve({
            token:
              'eyJraWQiOiJzbWlsZWNkci1kZW1vIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI4ODYxNzEyODM2IiwibmJmIjoxNjI3NTM5NTkwLCJhenAiOiJtcG93ZXJlZCIsInBhdGllbnQiOiIzMjM5NSIsImlzcyI6Imh0dHBzOlwvXC9kZW1vaWRwLm1wb3dlcmVkaGVhbHRoLmNvbVwvc21hcnRfYXV0aCIsInNtaWxlX2Nkcl9tb2R1bGVfaWQiOiJzbWFydF9hdXRoIiwic21pbGVfY2RyX25vZGVfaWQiOiJNYXN0ZXIiLCJleHAiOjE2Mjc1NDMxOTAsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJpYXQiOjE2Mjc1Mzk1OTAsImp0aSI6ImM4NmIxODA5LWVlNWYtNGExOC04ZDE3LWI5ZjhhZjI3Yjg3NCJ9.PyarkxhAbAUhTDCqk576qsiBSrDEV5gu2h3FI4rPKPxHkoP4HoUSZ7ov9vNN2Z-JS7ZoMdxFVaZq1OmvrnqBFsHhee-TzoKm1HC42cJgcGwaF54NtRxiBVsCnwN4NO_5yMMXCNjXmpUAdZDNx8naseVLajGs_g_usK9VG9DWXjqXHFEfrS-8Kq3dnJ8axrggcJQQ4H-HmN2PIR-kU8szbpmpBo0WsFyOD8N7QY3HTUuuuzrbpJ3Op9IAqJkaS_dy3VpF3iPewYAFj4gkX1WQH5eZ8-h34PAEq1SCjS0w9XuohbsAGPdS2_LBqNipp7DbSqKXvM8jr9efsSWvHFy0LQ',
            userInfo: {
              firstName: faker.name.firstName(),
              lastName: faker.name.lastName(),
              profilePictureUrl: faker.image.avatar(),
              userName: payload.userName,
            },
          });
        }
      );
    } else {
      reject({ error: { message: 'Username or password mismatched' } });
    }
  });
};

const processAuthentication = keycloak => {
  return new Promise((resolve, reject) => {
    UserRolesApiService.getPermissionsByRole(keycloak.realmAccess.roles).then(
      roles => {
        storePermissions(roles);

        resolve({
          token:
            'eyJraWQiOiJzbWlsZWNkci1kZW1vIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI4ODYxNzEyODM2IiwibmJmIjoxNjI3NTM5NTkwLCJhenAiOiJtcG93ZXJlZCIsInBhdGllbnQiOiIzMjM5NSIsImlzcyI6Imh0dHBzOlwvXC9kZW1vaWRwLm1wb3dlcmVkaGVhbHRoLmNvbVwvc21hcnRfYXV0aCIsInNtaWxlX2Nkcl9tb2R1bGVfaWQiOiJzbWFydF9hdXRoIiwic21pbGVfY2RyX25vZGVfaWQiOiJNYXN0ZXIiLCJleHAiOjE2Mjc1NDMxOTAsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJpYXQiOjE2Mjc1Mzk1OTAsImp0aSI6ImM4NmIxODA5LWVlNWYtNGExOC04ZDE3LWI5ZjhhZjI3Yjg3NCJ9.PyarkxhAbAUhTDCqk576qsiBSrDEV5gu2h3FI4rPKPxHkoP4HoUSZ7ov9vNN2Z-JS7ZoMdxFVaZq1OmvrnqBFsHhee-TzoKm1HC42cJgcGwaF54NtRxiBVsCnwN4NO_5yMMXCNjXmpUAdZDNx8naseVLajGs_g_usK9VG9DWXjqXHFEfrS-8Kq3dnJ8axrggcJQQ4H-HmN2PIR-kU8szbpmpBo0WsFyOD8N7QY3HTUuuuzrbpJ3Op9IAqJkaS_dy3VpF3iPewYAFj4gkX1WQH5eZ8-h34PAEq1SCjS0w9XuohbsAGPdS2_LBqNipp7DbSqKXvM8jr9efsSWvHFy0LQ',
          userInfo: {
            firstName: faker.name.firstName(),
            lastName: faker.name.lastName(),
            profilePictureUrl: faker.image.avatar(),
            // userName: payload.userName,
          },
        });
      }
    );
  });
};

const logout = () => {
  return new Promise((resolve, reject) => {
    SessionService.clear();
    resolve(true);
    // BaseApiService.post('/user/logout', null, null, true)
    //   .then(response => {
    //     SessionService.clear();
    //     resolve(true);
    //   })
    //   .catch(err => {
    //     const errResponse =
    //       err && err.response
    //         ? err.response.data
    //         : { error: { message: 'Something went wrong' } };
    //     reject(errResponse);
    //   });
  });
};

const AuthApiService = {
  login,
  logout,
  processAuthentication,
};

export default AuthApiService;
