import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  pending: [],
  applicationData: [],
  totalItems: 0,
  pendingApps: null,
  pendingOffset: 0,
  pendingText: '',
  pendingSearch: '',
  approved: [],
  approvedApps: null,
  approvedOffset: 0,
  approvedText: '',
  approvedSearch: '',
  denied: [],
  deniedApps: null,
  deniedOffset: 0,
  deniedText: '',
  deniedSearch: '',
  isFlyoutClose: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPLICATIONS_FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.APPROVED_APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        approved: action.data,
        approvedApps: action.totalItems,
      };
    case actionTypes.CLOSE_FLYOUT:
      return {
        ...state,
        isFlyoutClose: !state.isFlyoutClose,
      };
    case actionTypes.PENDING_APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        pending: action.data,
        pendingApps: action.totalItems,
      };
    case actionTypes.APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationData: action.data,
        totalItems: action.totalItems,
      };
    case actionTypes.DENIED_APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        denied: action.data,
        deniedApps: action.totalItems,
      };
    case actionTypes.APPLICATIONS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.APPLICATIONS_PENDING_OFFSET:
      return {
        ...state,
        pendingOffset: action.data,
      };
    case actionTypes.APPLICATIONS_APPROVED_OFFSET:
      return {
        ...state,
        approvedOffset: action.data,
      };
    case actionTypes.APPLICATIONS_DENIED_OFFSET:
      return {
        ...state,
        deniedOffset: action.data,
      };
    case actionTypes.APPLICATION_PENDING_TEXT: {
      return {
        ...state,
        pendingText: action.data,
      };
    }
    case actionTypes.APPLICATION_PENDING_SEARCH: {
      return {
        ...state,
        pendingSearch: action.data,
      };
    }
    case actionTypes.APPLICATION_APPROVED_TEXT: {
      return {
        ...state,
        approvedText: action.data,
      };
    }
    case actionTypes.APPLICATION_APPROVED_SEARCH: {
      return {
        ...state,
        approvedSearch: action.data,
      };
    }
    case actionTypes.APPLICATION_DENIED_TEXT: {
      return {
        ...state,
        deniedText: action.data,
      };
    }
    case actionTypes.APPLICATION_DENIED_SEARCH: {
      return {
        ...state,
        deniedSearch: action.data,
      };
    }

    default:
      return state;
  }
};

export default reducer;
