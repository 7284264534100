import React, { useState, useEffect } from 'react';
import ConsentDropdown from '../Members/ConsentDropdown';
import { SearchTextField } from 'components/widgets';
import { Button } from '@material-ui/core';
import ConsentInfoModal from './ConsentInfoModal';
import { useSelector } from 'react-redux';
import ConsentTrigger from './ConsentForm/ConsentTrigger';

const ConsentFilter = ({ query, searchValue, dateFilterOptions }) => {

  const [drawer, setDrawer] = useState(false);
  const [showConsentInfoModal,setShowConsentInfoModal] = useState(false);
  const [messageValue,setMessageValue]= useState("");
  const [messageVariant,setMessageVariant]= useState("");
  const { memberData } = useSelector(state => state.members);
  const enterDDUI = () => {
    setDrawer(true);
  };

  function isJsonString(item) {
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === 'object' && item !== null) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    function handleMessage(event) {
      if (isJsonString(event.data)) {
        const messageObject = JSON.parse(event.data);
        if (messageObject.flowTypeValue === 'DDUI') {
          exitDDUI();
          setMessageVariant(messageObject.variant);
          setMessageValue(messageObject.message);
          setShowConsentInfoModal(true);
          //Handle Error or Success message based on messageObject.variant
        }
      }
    }
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const exitDDUI = () => {
    setDrawer(false);
    
  };

  return (
    <div>
      {showConsentInfoModal && <ConsentInfoModal open={showConsentInfoModal} handleClose={()=>{setShowConsentInfoModal(false); setMessageValue("");setMessageVariant(""); }} membersDetails={[memberData]} messageVariant={messageVariant} message={messageValue}/> }
      {drawer && <ConsentTrigger data={memberData} exitDDUI={exitDDUI}/>}
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ width: '210px', marginRight: '12px' }}>
            <SearchTextField
              label="Search by records"
              value={query}
              onChangeText={searchValue}
            />
          </div>
          <ConsentDropdown {...dateFilterOptions} filterType="Status :" />
        </div>
        <Button
        onClick={enterDDUI}
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 40,
            height: 35,
            textTransform: 'none',
            color: '#036FCB',
            fontSize: '14px',
            padding: '6px 24px 6px 24px',
          }}
          variant="outlined">
          Initiate consent request
        </Button>
      </div>
    </div>
  );
};

export default ConsentFilter;
