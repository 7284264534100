import BaseApiService from './BaseApiService';

function _getAttribute({ searchName, searchType, orgId }) {
  return BaseApiService.get(
    `/attribute?offset=0&limit=10&searchName=${searchName}&orgId=${orgId}&searchAttrType=${searchType}`
  );
}

function _getCriteria({ attributeType, searchName, searchType, orgId, limit = 10, offset }) {
  return BaseApiService.get(
    `/organization/${orgId}/criteria?offset=${offset}&limit=${limit}&searchAttrId=${searchType}&searchName=${searchName}&type=${attributeType}`
  );
}

function _attributeDetails({ attriId }) {
  return BaseApiService.get(`/attribute/${attriId}`);
}

function _getActionList({ name }) {
  return BaseApiService.get(
    `/action?offset=0&limit=10&searchName=${name || ''}`
  );
}

function _getOrgnizationList({ orgId }) {
  return BaseApiService.get(`/attribute/org/${orgId}
`);
}

function _getAllRulesList({
  orgId,
  searchName,
  offset,
  searchStatus,
  searchAffId,
  limit = 10,
}) {
  return BaseApiService.get(
    `/${orgId}/rule?searchName=${searchName}&searchStatus=${searchStatus}&searchAffId=${searchAffId}&offset=${offset}&limit=${limit}`
  );
}

function _getRuleDetails({ orgId, ruleId }) {
  return BaseApiService.get(`/${orgId}/rule/details/${ruleId}`);
}

function _getRuleHistory({ orgId, rulesId }) {
  return BaseApiService.get(`/${orgId}/rule/history/${rulesId}`);
}

function _getExecutionInfo({ orgId, rulesId }) {
  return BaseApiService.get(`/${orgId}/rule/execution-info/${rulesId}`);
}

const RulesEngineApi = {
  getAttribute: _getAttribute,
  getCriteria: _getCriteria,
  getActionList: _getActionList,
  attributeDetails: _attributeDetails,
  getOrgnizationList: _getOrgnizationList,
  getAllRulesList: _getAllRulesList,
  getRuleDetails: _getRuleDetails,
  getRuleHistory: _getRuleHistory,
  getExecutionInfo: _getExecutionInfo,
};

export default RulesEngineApi;
