import React from 'react';
import { Field } from 'react-final-form';
import { required as requiredMsg, composeValidators } from 'shared/validators';
import { TextField } from 'components/widgets';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  customInputField: {
    backgroundColor: '#f5f7f8',
    borderRadius: '4px',
  },
  removeHeight: {
    height: 'auto !important',
    borderRadius: '4px',
    padding: '10px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
    border: '1.42857px solid #E4E7EC',
  },
}));

const checkForValue = fields => {
  if (fields?.length === 1 || !Array.isArray(fields)) return undefined;
  else {
    return fields?.some(val => val === null || val === undefined)
      ? 'Please provide value'
      : undefined;
  }
};

export default function FormTextFieldWithAddDelt({
  fieldName,
  label,
  disabled,
  placeholder,
  otherValidators = [],
  trim = false,
  required,
  addElement,
  removeElement,
  dataTestId,
  multiplePlaceholder,
}) {
  const classes = useStyles();
  return (
    <FieldArray name={fieldName} validate={!required && checkForValue}>
      {({ fields, meta }) => {
        return fields.map((name, index) => {
          return (
            <Box display={'flex'} alignItems={'center'} key={name}>
              <Box style={{ minWidth: '288px' }}>
                <Field
                  name={`${name}`}
                  component={'input'}
                  validate={composeValidators(
                    required ? requiredMsg : () => {},
                    ...otherValidators
                  )}>
                  {({ input, meta: { error, touched } }) => {
                    let isError = required
                      ? error
                      : input.value === ''
                      ? meta.error
                      : undefined;

                    return (
                      <Box display={'flex'} alignItems={'center'}>
                        <TextField
                          customClass={classes.removeHeight}
                          customInputFieldStyles={classes.customInputField}
                          trim={trim}
                          required={required}
                          disabled={disabled}
                          placeholder={
                            placeholder
                              ? placeholder
                              : fields.length > 1
                              ? `${multiplePlaceholder} ${index + 1}`
                              : multiplePlaceholder
                          }
                          dataTestId={dataTestId || ''}
                          {...input}
                          touched={touched}
                          error={isError}
                        />

                        <Box
                          style={{
                            marginBottom:
                              (error && touched) || (isError && touched)
                                ? 'calc(15% - 19px)'
                                : '0px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                          }}>
                          {fields.length > 1 && (
                            <DeleteIcon
                              style={{
                                color: 'rgba(102, 112, 133, 1)',
                                fontSize: '25px',
                                cursor: 'pointer',
                              }}
                              onClick={() => fields.remove(index)}
                              data-testid="remove-value-icon"
                            />
                          )}

                          {fields.length === index + 1 && (
                            <Box
                              className={classes.addIconContainer}
                              onClick={() => addElement(fieldName, null)}>
                              <AddIcon
                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                data-testid="add-value-icon"
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    );
                  }}
                </Field>
              </Box>
            </Box>
          );
        });
      }}
    </FieldArray>
  );
}
