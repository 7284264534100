import React from 'react';
import { makeStyles } from '@material-ui/core';
// import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    // overflow: 'hidden !important',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden !important',
    // paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#F5F7F8',
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
