import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import LoaderGifIcon from '../../assets/DownloadingGIF.gif';
import { Button } from '@mui/material';
import { Redirect } from 'react-router-dom';

const StyledCard = styled('div')(({ theme }) => ({
  borderRadius: 12,
  width: 200,
  '&:hover': {
    cursor: 'pointer',
  },
  height: 120,
  boxSizing: 'border-box',
  padding: '20px',
  paddingLeft: '24px',
  boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)',
  backgroundColor: '#fff',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '16px',
    height: 'auto',
  },
}));

const LeftContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const IconAndArrow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
});

const Icon = styled('img')(({ theme }) => ({
  marginTop: 5,
  height: 40,
  width: 40,
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    height: 24,
    width: 24,
    marginRight: 12,
  },
}));

const Arrow = styled(KeyboardArrowRight)({
  color: '#828282',
});

const Caption = styled('div')(({ theme }) => ({
  marginTop: 12,
  letterSpacing: 1.06,
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: '0px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const GifIconWrapper = styled('div')({
  width: 32,
  height: 32,
  backgroundColor: '#FFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  position: 'absolute',
  top: 8,
  right: 8,
});

const MuiButton = styled(Button)({
  color: '#522cbc',
  border: `1px solid '#522cbc'`,
  backgroundColor: '#F7E9FF',
  fontSize: 11,
  fontWeight: 500,
  height: 16,
  borderRadius: 24,
  padding: '0px 8px',
  position: 'relative',
  bottom: 8,
  left: 8,
});

const GifImg = styled('img')`
  width: 32px;
  height: 32px;
`;

export default function MenuCard({
  icon,
  link,
  caption,
  onServiceClick,
  serviceType = '',
  ingestionStatus,
  isButton,
  buttonText = '',
  webRouteParams = {},
  analyticsCode = '',
}) {
  const [redirectTo, setRedirectTo] = useState(null);

  function handleLink() {
    if (link === '/scanbill') {
      const inputEl = document.getElementById('ios-input-camera');
      if (inputEl) {
        inputEl.click();
      }
    } else if (link.includes('https')) {
      window.open(link);
    } else if (onServiceClick) {
      onServiceClick(link, serviceType, caption);
    } else {
      if (analyticsCode) {
        const event = {
          eventGroup: [],
          eventName: analyticsCode,
        };
      }
      setRedirectTo(link);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <StyledCard onClick={handleLink}>
      <IconAndArrow>
        <LeftContent>
          <Icon src={icon} />
          <Caption>{caption}</Caption>
        </LeftContent>
        {ingestionStatus === false ? (
          <GifIconWrapper>
            <GifImg src={LoaderGifIcon} />
          </GifIconWrapper>
        ) : isButton ? (
          <MuiButton>{buttonText}</MuiButton>
        ) : (
          <Arrow />
        )}
      </IconAndArrow>
    </StyledCard>
  );
}
