import { Grid, TextField } from '@material-ui/core';
import CriteriaDropdown from 'containers/Rules/formElements/criteriaDropdown';
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import clsx from 'classnames';
import CriteriaApi from 'services/api/CriteriaApi';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    background: '#FFFFFF',
    borderRadius: '16px',
    '& th': {
      fontWeight: 600,
      fontSize: '14px',
    },
  },
  tableContainer: {
    borderRadius: '8px',
    background: '#F5F7F8',
    padding: '12px 16px',
  },

  tableBody: {
    '& .MuiTableRow-root:last-child': {
      borderBottom: '2px solid transparent',
    },
  },

  inputElem: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },

  dateContainer: {
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0px',
    },
  },
});

const BOOLEAN_OPTIONS = [
  {
    id: 0,
    name: 'True',
    value: 'true',
  },
  {
    id: 1,
    name: 'False',
    value: 'false',
  },
];

const EVENT_PARAM_NAME = 'event';

const setInitParamValue = (paramsValue, isEdit) => {
  const value = {
    isEvent: false,
    eventIndex: 0,
  };
  if (!paramsValue) return value;
  else if (isEdit) {
    value.isEvent = paramsValue.some(
      param =>
        param.name.includes(EVENT_PARAM_NAME) && param.dataType.match(/string/i)
    );
    return value;
  }
  paramsValue?.forEach((param, index) => {
    if (
      param.name.includes(EVENT_PARAM_NAME) &&
      param.dataType.match(/string/i)
    ) {
      value.isEvent = true;
      value.eventIndex = index;
    } else if (param.dataType?.match(/date/i)) {
      // param.value = moment(new Date()).format('MM-DD-YYYY');
      param.value = '';
    } else if (param.dataType?.match(/boolean/i)) {
      // param.value = BOOLEAN_OPTIONS[0].value;
      param.value = '';
    } else {
      param.value = '';
    }
  });

  return value;
};

const AttributeValueType = ({
  classes,
  customStyles,
  eventOptions,
  index,
  onParamValueChange,
  param,
}) => {
  const dataType = param.dataType?.toUpperCase();
  if (
    (dataType === 'STRING' || dataType === 'NUMBER') &&
    !param.name.includes(EVENT_PARAM_NAME)
  ) {
    return (
      <TextField
        key={index}
        required
        id="outlined-basic"
        margin="dense"
        variant="outlined"
        className={clsx(customStyles.formTextField, classes.inputElem)}
        value={param.value || ''}
        onChange={e => {
          onParamValueChange(param.name, e.target.value, index);
        }}
        placeholder="Enter the value"
        autoComplete="off"
        type={dataType === 'NUMBER' ? 'number' : 'text'}
        onKeyDown={evt =>
          dataType === 'NUMBER' &&
          ['e', 'E', '+'].includes(evt.key) &&
          evt.preventDefault()
        }
        inputProps={{
          'data-testid': `${param.name}-input-field`,
        }}
      />
    );
  } else if (
    param.name.includes(EVENT_PARAM_NAME) &&
    param.dataType.match(/string/i)
  ) {
    return (
      <Autocomplete
        value={param.value}
        onChange={(e, newValue) => {
          onParamValueChange(param.name, newValue, index);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push(params.inputValue);
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="event-dropdown"
        options={eventOptions}
        getOptionLabel={option => option}
        renderOption={option => option}
        freeSolo
        disableClearable
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Select / Enter a value"
            margin="dense"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              'data-testid': 'event-dropdown',
            }}
          />
        )}
      />
    );
  } else if (dataType === 'DATE') {
    return (
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        style={{ width: '100%' }}
        key={index}>
        <KeyboardDatePicker
          style={{
            width: '100%',
            background: 'white',
            marginTop: '1px',
          }}
          value={param.value || null}
          className={classes.dateContainer}
          size="small"
          inputVariant="outlined"
          format="MM-dd-yyyy"
          onChange={value => {
            onParamValueChange(
              param.name,
              moment(new Date(value)).format('MM-DD-YYYY'),
              index
            );
          }}
          margin="normal"
          placeholder={param.name}
          InputProps={{
            'data-testid': 'date-picker',
          }}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (dataType === 'BOOLEAN') {
    return (
      <CriteriaDropdown
        key={index}
        value={param.value || 'placeholder'}
        options={BOOLEAN_OPTIONS}
        disabled={false}
        handleChange={e =>
          onParamValueChange(param.name, e.target.value, index)
        }
        dataTestIdForOptions="boolean-dropdown"
        dataTestId="boolean-dropdown"
        optionLabelKey="name"
        optionValueKey="value"
        placeHolderText={'Select the value'}
      />
    );
  } else return null;
};

const NonFhirAttributeCriteria = ({
  customStyles,
  nonFhirAttributeOptions,
  getNonFHIRValues,
  isEdit,
  setAlertFlag,
  criteriaDetails,
  orgId,
}) => {
  const [attributeId, setAttributeId] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [eventOptions, setEventOptions] = useState([]);

  const isValueChanged = useRef(false);

  const onAttributeChange = value => {
    let selectedValue = isEdit
      ? criteriaDetails
      : nonFhirAttributeOptions.find(item => item.id === value);
    const clonedSelectedValue = structuredClone(selectedValue);
    if (isEdit) {
      clonedSelectedValue.parameters =
        clonedSelectedValue.criteriaDefinitionDto?.parameters;
    }
    const { isEvent } = setInitParamValue(
      clonedSelectedValue?.parameters || null,
      isEdit
    );

    if (isEvent) {
      if (eventOptions?.length === 0) {
        CriteriaApi.getEvent(orgId).then(res => {
          setEventOptions(res);
        });
      }
    }
    setAttributeId(value);
    setSelectedAttribute(clonedSelectedValue);
  };
  const classes = useStyles();

  const onParamValueChange = (
    param,
    value,
    index,
    selectedAttr,
    defaultChange
  ) => {
    if (!defaultChange) {
      isValueChanged.current = true;
      setAlertFlag(true);
    }
    let updatedParameter = [];
    if (selectedAttr) {
      updatedParameter = [...selectedAttr?.parameters];
      updatedParameter[index].value = value;

      setSelectedAttribute(() => ({
        ...selectedAttr,
        parameters: updatedParameter,
      }));
    } else {
      updatedParameter = [...selectedAttribute?.parameters];
      updatedParameter[index].value = value;

      setSelectedAttribute(prev => ({
        ...prev,
        parameters: updatedParameter,
      }));
    }
  };

  getNonFHIRValues.current = () => {
    let isValid = true;
    let errorMsg = '';
    for (let param of selectedAttribute?.parameters) {
      if (
        param.value === '' ||
        param.value === 'placeholder' ||
        param.value === null ||
        (param.dataType === 'Date' && param.value === 'Invalid date')
      ) {
        isValid = false;
        errorMsg = `Please provide ${param.name} value`;
        break;
      }
    }
    return {
      attributeId,
      parameters: selectedAttribute?.parameters || {},
      isValid,
      errorMsg,
    };
  };

  useEffect(() => {
    if (nonFhirAttributeOptions && !selectedAttribute) {
      onAttributeChange(
        isEdit
          ? nonFhirAttributeOptions[0]?.attrId
          : nonFhirAttributeOptions[0]?.id
      );

      if (isEdit) setAlertFlag(false);
    }
  }, [nonFhirAttributeOptions, selectedAttribute, isEdit]);

  return (
    <>
      <Grid item xs={6}>
        {nonFhirAttributeOptions && attributeId && (
          <CriteriaDropdown
            title={'Attribute name'}
            required={true}
            value={attributeId}
            options={nonFhirAttributeOptions}
            disabled={isEdit}
            handleChange={e =>
              onAttributeChange(e.target.value, nonFhirAttributeOptions)
            }
            dataTestIdForOptions="attribute-dropdown"
            dataTestId="attribute-dropdown"
            optionLabelKey="name"
            optionValueKey={isEdit ? 'attrId' : 'id'}
          />
        )}
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={12}>
        {selectedAttribute?.parameters?.length ? (
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Parameters</TableCell>
                  <TableCell>Data type</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {selectedAttribute?.parameters?.map((param, index) => (
                  <TableRow
                    key={param.id}
                    className={classes.tableRow}
                    data-testid={'attribute-parameters'}>
                    <TableCell>{param.name}</TableCell>
                    <TableCell>{param.dataType}</TableCell>
                    <TableCell width={'50%'}>
                      <AttributeValueType
                        classes={classes}
                        customStyles={customStyles}
                        eventOptions={eventOptions}
                        index={index}
                        onParamValueChange={onParamValueChange}
                        param={param}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </>
  );
};

export default NonFhirAttributeCriteria;
