import { APP_ROLES, USER_ROLES, RESOURCE_NAMES } from 'shared/constants';

const {
  DEV_DASHBOARD,
  DEV_APPLICATIONS,
  DEV_API_OVERVIEW,
  DEV_SANDBOX,
  DEV_SETTINGS,
  DEV_APPLICATION_APPROVER,
  DEV_APPLICATION_CRUD,
  DEV_PRICELIST,
  MEMBERS,

  ORGANIZATIONS,
  ORG_DETAILS,
  ORG_PARTNERS,
  ORG_LOBS,
  ORG_USERS,
  ORG_REPORTS,
  ADMIN_SETTINGS,
  ORG_APPROVER,
  CMDE,
} = RESOURCE_NAMES;

const CommonDevPermissions = [
  DEV_DASHBOARD,
  DEV_APPLICATIONS,
  DEV_API_OVERVIEW,
  DEV_SANDBOX,
  DEV_SETTINGS,
  DEV_PRICELIST,
];

const DeveloperPermissions = [...CommonDevPermissions, DEV_APPLICATION_CRUD];

const CompliancePermissions = [
  ...CommonDevPermissions,
  MEMBERS,
  DEV_APPLICATION_APPROVER,
];

const OwnerPermissions = [
  ORGANIZATIONS,
  ORG_DETAILS,
  ORG_PARTNERS,
  ORG_LOBS,
  ORG_USERS,
  ORG_REPORTS,
  ADMIN_SETTINGS,
];

const ITAdminPermissions = [
  ORG_DETAILS,
  ORG_PARTNERS,
  ORG_LOBS,
  ORG_USERS,
  ORG_REPORTS,
  ADMIN_SETTINGS,
  MEMBERS,
  CMDE,
];

const AdminPermissions = [...OwnerPermissions, ORG_APPROVER];

const _getRolesAndPermissions = username => {
  return new Promise((resolve, reject) => {
    let resourceInfo = [];
    let roleName = 'MPH_ADMIN';

    if (username === 'dev-user') {
      resourceInfo = DeveloperPermissions;
      roleName = 'ROLE_DEV';
    } else if (username === 'admin') {
      resourceInfo = AdminPermissions;
      roleName = 'MPH_ADMIN';
    } else if (username === 'compliance-admin') {
      resourceInfo = CompliancePermissions;
      roleName = 'ROLE_COMPLIANCE';
    } else if (username === 'super-admin') {
      resourceInfo = [...AdminPermissions, ...CompliancePermissions];
      roleName = 'ROLE_SUPER_ADMIN';
    }

    resolve([
      {
        roleId: 1,
        roleName,
        resourceInfo,
      },
    ]);
  });
};

const _getPermissionsByRoleAsync = (roles = []) => {
  return new Promise((resolve, reject) => {
    let resourceInfo = [];

    roles.forEach(role => {
      if ([USER_ROLES.Org_Owner].includes(role)) {
        resourceInfo = [...resourceInfo, ...OwnerPermissions];
      } else if ([USER_ROLES.Admin, APP_ROLES.MPH_ADMIN].includes(role)) {
        resourceInfo = [...resourceInfo, ...AdminPermissions];
      } else if (
        [
          APP_ROLES.INTERNAL_DEVELOPER,
          APP_ROLES.EXTERNAL_DEVELOPER,
          USER_ROLES.Internal_Developer,
          USER_ROLES.Third_Party_Developer,
          APP_ROLES.DEVELOPER,
        ].includes(role)
      ) {
        resourceInfo = [...resourceInfo, ...DeveloperPermissions];
      } else if (
        [
          USER_ROLES.Compliance,
          APP_ROLES.AFFILIATE_COMPLIANCE_MANAGER,
        ].includes(role)
      ) {
        resourceInfo = [...resourceInfo, ...CompliancePermissions];
      } else if (role === 'ROLE_SUPER_ADMIN') {
        const tempPermissions = [...AdminPermissions, ...CompliancePermissions];
        resourceInfo = [...resourceInfo, ...tempPermissions];
      }
    });

    resolve([
      {
        roleId: 1,
        roles,
        resourceInfo,
      },
    ]);
  });
};

const _getPermissionsByRole = (roles = []) => {
  let resourceInfo = [];

  roles.forEach(role => {
    if ([USER_ROLES.Org_Owner].includes(role)) {
      resourceInfo = [...resourceInfo, ...OwnerPermissions];
    } else if ([USER_ROLES.Admin, APP_ROLES.MPH_ADMIN].includes(role)) {
      resourceInfo = [...resourceInfo, ...AdminPermissions];
    } else if (
      [
        APP_ROLES.INTERNAL_DEVELOPER,
        APP_ROLES.EXTERNAL_DEVELOPER,
        USER_ROLES.Internal_Developer,
        USER_ROLES.Third_Party_Developer,
        APP_ROLES.DEVELOPER,
      ].includes(role)
    ) {
      resourceInfo = [...resourceInfo, ...DeveloperPermissions];
    } else if (
      [USER_ROLES.Compliance, APP_ROLES.AFFILIATE_COMPLIANCE_MANAGER].includes(
        role
      )
    ) {
      resourceInfo = [...resourceInfo, ...CompliancePermissions];
    } else if (role === 'ROLE_SUPER_ADMIN') {
      const tempPermissions = [...AdminPermissions, ...CompliancePermissions];
      resourceInfo = [...resourceInfo, ...tempPermissions];
    } else if (role === USER_ROLES.IT_Admin || role === USER_ROLES.ORGANIZATION_ADMIN)  {
      resourceInfo = [...resourceInfo, ...ITAdminPermissions];
    }
  });

  return [
    {
      roleId: 1,
      roles,
      resourceInfo,
    },
  ];
};

const UserRolesApiService = {
  getRolesAndPermissions: _getRolesAndPermissions,
  getPermissionsByRoleAsync: _getPermissionsByRoleAsync,
  getPermissionsByRole: _getPermissionsByRole,
};

export default UserRolesApiService;
