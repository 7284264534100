import { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  PrimaryButton,
  FormTextField,
  FormEINField,
  FormDBField,
  FormEmailField,
  FormSelectField,
  SimpleBackdrop,
  SimpleAlert,
  FormZipField,
  FormPhoneField,
  SecondaryButton,
} from 'components/widgets';
import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Box,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import OrganizationApi from 'services/api/OrganizationApi';
import { useSelector } from 'react-redux';
import ImageDropzone from './ImageDropzone';
import { ORG_TYPE } from 'shared/constants';
import { email, phoneNumber } from 'shared/validators';
import NumberFormat from 'react-number-format';
const emptyAddress = {
  city: '',
  country: 'US',
  line1: '',
  line2: '',
  // name: '',
  state: '',
  type: '',
  zip: '',
  contacts: [
    {
      email: '',
      fax: '',
      phone: '',
    },
  ],
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#FFFFFF',
    padding: '10px',
    borderRadius: '10px!important',
  },
  adminGrpContainer: {
    // backgroundColor: '#E4E7EC',
    // border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '8px',
    // padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    // padding: '0px 16px',
    width: '100%',
  },
  adminSummaryContainer: {
    // backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
  },
  adminGrpSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  formTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#344054',
  },
  GridMaxWidth: {
    maxWidth: '32%',
  },
  mandatory: {
    color: 'red',
  },
  detailstitle: {
    color: '#798294',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  detailsValue: {
    color: '#344054',
  },
}));

function PhoneMaskedTextField(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+1 (###) ###-####"
      mask="_"
    />
  );
}

export default function CreateOrganization({
  orgId,
  onSubmit,
  handleButtonClick,
  disable,
  isAddingNew,
}) {
  const [loading, setLoading] = useState(false);
  const [orgData, setOrgData] = useState({
    name: '',
    db: '',
    ein: '',
    typeCode: '',
    addresses: [emptyAddress],
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: null,
  });
  const [alert, setAlert] = useState({
    message: '',
    type: '',
    isOpen: false,
  });

  const { data: states } = useSelector(state => state.locationStates);
  const { data: addressTypes } = useSelector(state => state.addressTypes);
  const classes = useStyles();

  const getOrgById = useCallback(orgId => {
    if (orgId) {
      setLoading(true);

      OrganizationApi.getOrganizationById(orgId)
        .then(results => {
          if (!results.addresses || results.addresses.length === 0) {
            results.addresses = [emptyAddress];
          }
          setOrgData({
            ...results,
            firstName: results?.adminDetails?.firstName,
            lastName: results?.adminDetails?.lastName,
            phoneNumber: results?.adminDetails?.phoneNumber,
            emailId: results?.adminDetails?.emailId,
          });
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setAlert({
            message: 'Something went wrong',
            type: 'error',
            isOpen: true,
          });
        });
    }
  }, []);

  const checkValidation = (value, type) => {
    if (type === 'contact') {
      return phoneNumber(value);
    }
    if (type === 'email') {
      return email(value);
    }
  };

  useEffect(() => {
    getOrgById(orgId);
  }, [getOrgById, orgId]);

  return (
    <>
      {!orgId && (
        <div style={{ marginBottom: 20 }}>
          <ImageDropzone handleButtonClick={handleButtonClick} />
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...orgData }}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          reset,
          pristine,
          values,
          form: {
            mutators: { push, pop },
          },
        }) => (
          <form
            noValidate
            onSubmit={event => {
              handleSubmit(event);
            }}
            autoComplete="off"
          >
            <Box
              className={classes.adminGrpContainer}
              style={{
                display: 'flex',
                marginLeft: '0px',
                marginBottom: '20px',
              }}
            >
              <Typography
                data-testid="action-title"
                className={classes.heading}
                style={{ width: '100%', marginLeft: '8px' }}
              >
                Admin details
              </Typography>
              <>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  className={classes.GridMaxWidth}
                  style={{ height: '70px' }}
                >
                  <FormTextField
                    name={`firstName`}
                    label="First name"
                    disabled={!isAddingNew}
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  className={classes.GridMaxWidth}
                  style={{ height: '70px' }}
                >
                  <FormTextField
                    name={`lastName`}
                    label="Last name"
                    disabled={!isAddingNew}
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  className={classes.GridMaxWidth}
                  style={{ height: '70px' }}
                >
                  <FormEmailField
                    name={`emailId`}
                    label="Email id"
                    disabled={!isAddingNew}
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  className={classes.GridMaxWidth}
                  style={{ height: '70px' }}
                >
                  <FormPhoneField
                    name={`phoneNumber`}
                    label="Phone number"
                    style={{ height: '70px' }}
                    disabled={!isAddingNew}
                  />
                </Grid>
              </>
            </Box>
            <Typography
              data-testid="action-title"
              className={classes.heading}
              style={{
                marginBottom: '10px',
                width: '100%',
                marginLeft: '8px',
                marginTop: '30px',
              }}
            >
              Organization details
            </Typography>
            <Grid container>
              <Grid item xs={12} style={{ height: '70px' }}>
                <FormTextField
                  name="name"
                  label="Organization name"
                  style={{ height: '10px !important' }}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ height: '70px' }}>
                <FormDBField name="db" label={`D&B`} />
              </Grid>
              <Grid item xs={12} sm={4} style={{ height: '70px' }}>
                <FormEINField name="ein" label="EIN" />
              </Grid>
              <Grid item xs={12} sm={4} style={{ height: '70px' }}>
                <FormSelectField
                  name="typeCode"
                  label="Organization type"
                  options={ORG_TYPE}
                  required
                />
              </Grid>
            </Grid>
            {!orgId && (
              <>
                <b style={{ marginLeft: '8px' }}>Addresses</b>

                <FieldArray name="addresses">
                  {({ fields }) =>
                    fields.map((address, index) => (
                      <Paper
                        key={address}
                        className={classes.paper}
                        elevation={2}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 'bold',
                              margin: '16px 8px',
                            }}
                          >{`Address ${index + 1}`}</div>
                          {fields.length > 1 && (
                            <Tooltip
                              title="Remove address"
                              placement="top"
                              arrow
                            >
                              <IconButton onClick={() => fields.remove(index)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>

                        <Grid container>
                          <FieldArray name={`${address}.contacts`}>
                            {({ fields: addrFields }) =>
                              addrFields.map(contact => (
                                <Grid key={contact} container>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <FormPhoneField
                                      name={`${contact}.phone`}
                                      label="Business contact number"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <FormEmailField
                                      name={`${contact}.email`}
                                      label="Email"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <FormPhoneField
                                      name={`${contact}.fax`}
                                      label="Fax number"
                                    />
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </FieldArray>
                          {/* <Grid item xs={12} sm={6} md={4}>
                            <FormTextField
                              name={`${address}.name`}
                              label="Address name"
                            />
                          </Grid> */}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormSelectField
                              name={`${address}.type`}
                              label="Type"
                              options={addressTypes}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormTextField
                              name={`${address}.line1`}
                              label="Apt/suite #"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormTextField
                              name={`${address}.line2`}
                              label="Street address"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormTextField
                              name={`${address}.city`}
                              label="City"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormSelectField
                              name={`${address}.state`}
                              label="State"
                              options={states}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormZipField name={`${address}.zip`} label="Zip" />
                          </Grid>
                        </Grid>
                      </Paper>
                    ))
                  }
                </FieldArray>
                <div style={{ margin: '16px 8px' }}>
                  <SecondaryButton
                    onClick={() => push('addresses', emptyAddress)}
                  >
                    Add another address
                  </SecondaryButton>
                </div>
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '16px 0px',
              }}
            >
              <PrimaryButton
                type="submit"
                style={{ marginRight: '8px' }}
                disabled={
                  isAddingNew ? loading || disable : loading || pristine
                }
              >
                Save
              </PrimaryButton>
            </div>
          </form>
        )}
      />
      <SimpleBackdrop open={loading} />
      <SimpleAlert
        onClose={() => setAlert({ ...alert, isOpen: false })}
        {...alert}
      />
    </>
  );
}
