
export const mockTemplates = 
{
  "documents": {
    "title": "Documents",
    "resourceName": "documents",
    "loadingText": "documents",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/conditions/conditions-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "addDocument": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by document name",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "260",
        "addDebounce": true
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Document name",
          "type": "string",
          "dataCol": "documentResponse.document.documentName"
        },
        {
          "headerName": "Type",
          "type": "string",
          "dataCol": "documentResponse.document.documentType"
        },
        {
          "headerName": "Category",
          "type": "string",
          "isTitleCase": true,
          "dataCol": "documentResponse.document.category"
        },
        {
          "headerName": "Uploaded By",
          "type": "string",
          "isTitleCase": true,
          "dataCol": "documentResponse.document.updatedBy"
        },
        {
          "headerName": "Uploaded date",
          "type": "string",
          "dataCol": "documentResponse.documentDisplay.createdDate"
        },
        {
          "headerName": "",
          "type": "downloadButton",
          "dataCol": ""
        }
      ]
    }
  },
  "conditions": {
    "title": "Conditions",
    "resourceName": "condition",
    "loadingText": "conditions",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/conditions/conditions-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by condition code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "260",
        "addDebounce": true
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Active",
              "value": "active",
              "iconColor": "#036FCB"
            },
            {
              "label": "Inactive",
              "value": "inactive",
              "iconColor": "#DB0032"
            },
            {
              "label": "Resolved",
              "value": "resolved",
              "iconColor": "#007D32"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": ["recurrence", "relapse", "remission", "unknown"]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Recorded on",
          "type": "string",
          "dataCol": "recordedDate"
        },
        {
          "headerName": "Condition",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "conditionCodeName",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "source": "extension",
          "filterKey": "url",
          "filterVals": ["statusColor", "clinicalStatus"],
          "resultKey": "valueString"
        },
        {
          "headerName": "Severity",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "severity",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Condition details",
      "loadingText": "conditions",
      "categoryNameForMarkAsRestricted": "condition",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "conditionName",
          "resultKey": "valueString"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dot",
            "source": "extension",
            "filterKey": "url",
            "filterVals": ["statusColor", "clinicalStatus"],
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "label": "Recorded on",
            "type": "string",
            "key": "recordedDate",
            "requestKeyForSelfReporting": "recordedOn"
          },
          {
            "label": "Recorded by",
            "type": "string",
            "key": "recorder.display",
            "requestKeyForSelfReporting": "recordedBy"
          },
          {
            "label": "Facility",
            "type": "string",
            "key": "encounter.display",
            "requestKeyForSelfReporting": "facility"
          },
          {
            "label": "Category",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "category",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "category"
          },
          {
            "label": "Code",
            "key": "code.coding.0.code",
            "requestKeyForSelfReporting": "codeName",
            "type": "string"
          },
          {
            "label": "Severity",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "severity",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "severity"
          },
          {
            "label": "Documents",
            "type": "document",
            "key": "docArn",
            "requestKeyForSelfReporting": "documents",
            "width": "fullwidth"
          },
          {
            "label": "Summary",
            "type": "arrayFromExtension",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "summary",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "summary",
            "width": "fullwidth"
          },
          {
            "label": "Note",
            "type": "objectList",
            "source": "note",
            "resultKey": "text",
            "requestKeyForSelfReporting": "note",
            "width": "fullwidth"
          }
        ],
        "lists": [
          {
            "header": "Problems",
            "descriptionKey": "addresses.code.coding.display",
            "statusKey": "addresses.clinicalStatus"
          },
          {
            "header": "Goals",
            "descriptionKey": "goal.description.coding.display",
            "statusKey": "goal.lifecycleStatus"
          },
          {
            "header": "Activities",
            "descriptionKey": "activity.detail.code.coding.display",
            "statusKey": ""
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "conditions",
      "cardFields": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "dataCol": "recordedDate",
          "filterKey": "url",
          "filterVal": "recorded_on",
          "source": "extension",
          "resultKey": "valueString",
          "objectKeyForUI": "recordedOn"
        },
        {
          "headerName": "Data source",
          "type": "filter",
          "filterKey": "url",
          "filterVal": "dataSource",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString"
        }
      ]
    },
    "selfReportingSection": {
      "steps": ["Add data", "Documents"],
      "resourceNameForSelfReportingFileUpload": "Condition",
      "resourceType": "Conditions",
      "convertToLowerCaseList": ["status"],
      "formElementList": [
        {
          "label": "Conditions name",
          "type": "text",
          "placeholder": "Enter condition name",
          "valueKey": "conditionName",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Facility",
          "type": "text",
          "placeholder": "Enter facility",
          "valueKey": "facility"
        },
        {
          "label": "Status",
          "type": "dropdown",
          "placeholder": "Select status",
          "valueKey": "status",
          "required": true,
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "value": "active",
              "label": "Active",
              "statusIcon": true,
              "color": "#036FCB"
            },
            {
              "value": "recurrence",
              "label": "Recurrence",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "value": "relapse",
              "label": "Relapse",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "value": "inactive",
              "label": "Inactive",
              "statusIcon": true,
              "color": "#DB0032"
            },
            {
              "value": "remission",
              "label": "Remission",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "value": "resolved",
              "label": "Resolved",
              "statusIcon": true,
              "color": "#007D32"
            }
          ]
        },
        {
          "label": "Category",
          "placeholder": "Select category",
          "type": "dropdown",
          "valueKey": "category",
          "options": [
            {
              "value": "Problem list item",
              "label": "Problem list item"
            },
            {
              "value": "Encounter diagnosis",
              "label": "Encounter diagnosis"
            }
          ]
        },
        {
          "label": "Severity",
          "type": "dropdown",
          "placeholder": "Select severity",
          "valueKey": "severity",
          "options": [
            {
              "value": "Severe",
              "label": "Severe"
            },
            {
              "value": "Moderate",
              "label": "Moderate"
            },
            {
              "value": "Mild",
              "label": "Mild"
            }
          ]
        },
        {
          "label": "Recorded by",
          "type": "text",
          "placeholder": "Recorded by e.g. John Robertson",
          "valueKey": "recordedBy"
        },
        {
          "label": "Recorded on",
          "type": "date",
          "required": true,
          "valueKey": "recordedOn",
          "placeholder": "MM / DD / YYYY",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Code",
          "type": "text",
          "placeholder": "Enter condition code",
          "valueKey": "codeName"
        },
        {
          "label": "Note",
          "type": "multiline",
          "placeholder": "Add note",
          "numberOfLines": 5,
          "valueKey": "note",
          "width": "fullWidth"
        }
      ]
    }
  },
  "allergies": {
    "title": "Allergies",
    "resourceName": "allergy",
    "loadingText": "allergies",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/allergies/allergies-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by allergy code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "addDebounce": true,
        "width": "260"
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Active",
              "value": "active",
              "iconColor": "#036FCB"
            },
            {
              "label": "Inactive",
              "value": "inactive",
              "iconColor": "#DB0032"
            },
            {
              "label": "Resolved",
              "value": "resolved",
              "iconColor": "#007D32"
            },
            {
              "label": "Confirmed",
              "value": "confirmed",
              "iconColor": "#036FCB"
            },
            {
              "label": "Unconfirmed",
              "value": "unconfirmed",
              "iconColor": "#737373"
            },
            {
              "label": "Refuted",
              "value": "refuted",
              "iconColor": "#737373"
            },
            {
              "label": "Unknown",
              "value": "others",
              "mappingList": [
                "entered-in-error",
                "on-hold",
                "draft",
                "unknown"
              ],
              "iconColor": "#737373"
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Recorded on",
          "type": "string",
          "dataCol": "recordedDate"
        },
        {
          "headerName": "Allergic to",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "allergyName",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "source": "extension",
          "filterKey": "url",
          "filterVals": ["statusColor", "clinicalStatus"],
          "resultKey": "valueString"
        },
        {
          "headerName": "Criticality",
          "type": "string",
          "dataCol": "criticality"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Allergy details",
      "loadingText": "allergies",
      "categoryNameForMarkAsRestricted": "allergy",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "allergyName",
          "resultKey": "valueString"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dot",
            "source": "extension",
            "filterKey": "url",
            "filterVals": ["statusColor", "clinicalStatus"],
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "label": "Recorded on",
            "type": "string",
            "key": "recordedDate",
            "requestKeyForSelfReporting": "recordedOn"
          },
          {
            "label": "Recorded by",
            "type": "string",
            "key": "recorder.display",
            "requestKeyForSelfReporting": "recordedBy"
          },
          {
            "label": "Facility",
            "type": "string",
            "key": "encounter.display",
            "requestKeyForSelfReporting": "facility"
          },
          {
            "label": "Code",
            "type": "string",
            "key": "code.coding.0.code",
            "requestKeyForSelfReporting": "codeName"
          },
          {
            "label": "Start time",
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "startTime",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "startTime"
          },
          {
            "label": "Criticality",
            "type": "string",
            "key": "criticality",
            "requestKeyForSelfReporting": "criticality"
          },
          {
            "label": "Category",
            "type": "string",
            "key": "category.0",
            "requestKeyForSelfReporting": "category"
          },
          {
            "label": "Route",
            "width": "fullWidth",
            "type": "arrayFromExtension",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "route",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "route"
          },
          {
            "label": "Reaction",
            "type": "arrayFromExtension",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "reaction",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "reaction",
            "width": "fullwidth"
          },
          {
            "label": "Documents",
            "type": "document",
            "key": "docArn",
            "requestKeyForSelfReporting": "documents",
            "width": "fullWidth"
          },
          {
            "label": "Note",
            "type": "objectList",
            "source": "note",
            "resultKey": "text",
            "requestKeyForSelfReporting": "note",
            "width": "fullWidth"
          }
        ],
        "lists": [
          {
            "header": "Problems",
            "descriptionKey": "addresses.code.coding.display",
            "statusKey": "addresses.clinicalStatus"
          },
          {
            "header": "Goals",
            "descriptionKey": "goal.description.coding.display",
            "statusKey": "goal.lifecycleStatus"
          },
          {
            "header": "Activities",
            "descriptionKey": "activity.detail.code.coding.display",
            "statusKey": ""
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "allergies",
      "cardFields": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recorded_on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "filter",
          "filterKey": "url",
          "filterVal": "data_source",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString"
        }
      ]
    },
    "selfReportingSection": {
      "resourceNameForSelfReportingFileUpload": "AllergyIntolerance",
      "resourceType": "Allergies",
      "steps": ["Add data", "Documents"],
      "convertToLowerCaseList": ["status", "category", "criticality"],
      "formElementList": [
        {
          "label": "Allergic to",
          "type": "text",
          "placeholder": "Enter allergy",
          "required": true,
          "valueKey": "allergicTo",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Facility",
          "type": "text",
          "placeholder": "Enter facility name",
          "valueKey": "facility"
        },
        {
          "label": "Criticality",
          "type": "dropdown",
          "isMappingRequired": true,
          "conversionFromValueToLabel": ["criticality"],
          "placeholder": "Select criticality",
          "valueKey": "criticality",
          "options": [
            {
              "label": "Low risk",
              "value": "low"
            },
            {
              "label": "High risk",
              "value": "high"
            },
            {
              "label": "Unable to assess risk",
              "value": "unable-to-assess"
            }
          ]
        },
        {
          "label": "Category",
          "type": "dropdown",
          "placeholder": "Select category type",
          "valueKey": "category",
          "required": true,
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "label": "Food",
              "value": "food"
            },
            {
              "label": "Medication",
              "value": "medication"
            },
            {
              "label": "Environment",
              "value": "environment"
            },
            {
              "label": "Biologic",
              "value": "biologic"
            }
          ]
        },
        {
          "label": "Route",
          "type": "text",
          "resultKey": "description",
          "placeholder": "Enter route e.g. Nasal, oral, dental, etc",
          "valueKey": "route"
        },
        {
          "label": "Status",
          "type": "dropdown",
          "placeholder": "Select allergy status",
          "required": true,
          "valueKey": "status",
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "value": "active",
              "label": "Active",
              "statusIcon": true,
              "color": "#036FCB"
            },
            {
              "value": "inactive",
              "label": "Inactive",
              "statusIcon": true,
              "color": "#DB0032"
            },
            {
              "value": "resolved",
              "label": "Resolved",
              "statusIcon": true,
              "color": "#007D32"
            }
          ]
        },
        {
          "label": "Recorded on",
          "type": "date",
          "valueKey": "recordedOn",
          "required": true,
          "placeholder": "MM / DD / YYYY",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Start time",
          "type": "date",
          "valueKey": "startTime",
          "required": true,
          "placeholder": "MM / DD / YYYY",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Recorded by",
          "type": "text",
          "placeholder": "Recorded by e.g. John Robertson",
          "valueKey": "recordedBy"
        },
        {
          "label": "Code",
          "type": "text",
          "placeholder": "Enter allergy code",
          "valueKey": "codeName"
        },
        {
          "label": "Reactions",
          "type": "multiple",
          "placeholder": "Add reactions of the allergy",
          "resultKey": "description",
          "numberOfLines": 5,
          "valueKey": "reaction",
          "width": "fullWidth"
        },
        {
          "label": "Note",
          "type": "multiline",
          "placeholder": "Add note",
          "numberOfLines": 5,
          "valueKey": "note",
          "width": "fullWidth"
        }
      ]
    }
  },
  "labs": {
    "title": "Lab results",
    "resourceName": "lab result",
    "loadingText": "lab results",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/labresults/labresults-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by lab result code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "addDebounce": true,
        "width": "260"
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on time:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Registered",
              "value": "registered",
              "iconColor": "#036FCB"
            },
            {
              "label": "Partial",
              "value": "partial",
              "iconColor": "#F2994A"
            },
            {
              "label": "Preliminary",
              "value": "preliminary",
              "iconColor": "#F2994A"
            },
            {
              "label": "Final",
              "value": "final",
              "iconColor": "#007D32"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": [
                "amended",
                "appended",
                "corrected",
                "cancelled",
                "entered-in-error",
                "unknown"
              ]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "recordedOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Name",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "labresultCodeName",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Category code",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "categoryCode",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Lab result details",
      "loadingText": "lab result",
      "categoryNameForMarkAsRestricted": "lab result",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "labresultName",
          "resultKey": "valueString"
        },
        "customCardForViewDetails": {
          "sourceObj": "extension",
          "filterKey": "url",
          "requiredPropsForViewDetailsCustomCards": [
            "value",
            "labResult",
            "referenceRange"
          ]
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "label": "Recorded on",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "recordedOn",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "recordedOn"
          },
          {
            "label": "Value",
            "type": "filter",
            "hide": true,
            "source": "extension",
            "filterKey": "url",
            "filterVal": "value",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "value"
          },
          {
            "label": "Unit",
            "hide": true,
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "unit",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "units"
          },
          {
            "label": "Recorded by",
            "key": "performer.0.display",
            "type": "string",
            "requestKeyForSelfReporting": "recordedBy"
          },
          {
            "label": "Reference range",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "referenceRange",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "referenceRange"
          },
          {
            "label": "Facility",
            "type": "string",
            "key": "encounter.display",
            "requestKeyForSelfReporting": "facility"
          },
          {
            "label": "Lab result",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "test_results",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "testResults"
          },
          {
            "label": "Issued date",
            "hide": true,
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "issued_date_time",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "issuedDate"
          },
          {
            "label": "Issued time",
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "issued_date_time",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "issuedTime"
          },
          {
            "label": "Issuer of the report",
            "type": "string",
            "key": "resultsInterpreter.0.display",
            "requestKeyForSelfReporting": "issuerOfTheReport"
          },
          {
            "label": "Category code",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "categoryCode",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "categoryCode"
          },
          {
            "label": "LOINC code",
            "key": "code.coding.0.code",
            "type": "string",
            "width": "fullwidth",
            "requestKeyForSelfReporting": "loincCode"
          },
          {
            "label": "Documents",
            "type": "document",
            "key": "docArn",
            "width": "fullWidth",
            "requestKeyForSelfReporting": "documents"
          },
          {
            "label": "Note",
            "width": "fullWidth",
            "type": "arrayFromExtension",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "note",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "note"
          }
        ],
        "lists": [
          {
            "header": "Problems",
            "descriptionKey": "addresses.code.coding.display",
            "statusKey": "addresses.clinicalStatus"
          },
          {
            "header": "Goals",
            "descriptionKey": "goal.description.coding.display",
            "statusKey": "goal.lifecycleStatus"
          },
          {
            "header": "Activities",
            "descriptionKey": "activity.detail.code.coding.display",
            "statusKey": ""
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "Labs",
      "cardFields": [
        {
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recordedOn",
          "headerName": "Recorded on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString",
          "collapsedView": true,
          "type": "filter"
        },
        {
          "filterKey": "url",
          "filterVal": "dataSource",
          "headerName": "Data source",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString",
          "type": "filter"
        }
      ]
    },
    "selfReportingSection": {
      "resourceNameForSelfReportingFileUpload": "DiagnosticReport",
      "convertToLowerCaseList": ["status"],
      "resourceType": "labs",
      "steps": ["Add data", "Documents"],
      "formElementList": [
        {
          "label": "Test name",
          "type": "text",
          "placeholder": "Enter test name",
          "required": true,
          "valueKey": "testName",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Facility",
          "type": "text",
          "placeholder": "Enter facility name",
          "valueKey": "facility"
        },
        {
          "label": "Value",
          "type": "text",
          "required": true,
          "placeholder": "Enter lab-result value e.g. positive, 4.8 etc.",
          "valueKey": "value",
          "errorMsg": "Please enter a valid value",
          "regex": "^[a-zA-Z0-9]+(.[0-9]+)?$"
        },
        {
          "label": "Units",
          "type": "text",
          "placeholder": "Enter lab-result value unit e.g. mmHg",
          "valueKey": "units"
        },
        {
          "label": "Reference range",
          "type": "text",
          "placeholder": "Enter reference range",
          "valueKey": "referenceRange",
          "regex": "^[0-9]+(.[0-9]+)?-[0-9]+(.[0-9]+)?$",
          "errorMsg": "Please follow this pattern for reference range (lower-upper). Ex. 80.45-100.34"
        },
        {
          "label": "Lab result",
          "type": "dropdown",
          "required": true,
          "placeholder": "Select result",
          "valueKey": "testResults",
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "label": "High",
              "value": "High"
            },
            {
              "label": "Normal",
              "value": "Normal"
            },
            {
              "label": "Low",
              "value": "Low"
            },
            {
              "label": "Abnormal",
              "value": "Abnormal"
            },
            {
              "label": "Indeterminate",
              "value": "Indeterminate"
            },
            {
              "label": "Equivocal",
              "value": "Equivocal"
            },
            {
              "label": "Negative",
              "value": "Negative"
            },
            {
              "label": "Not detected",
              "value": "Not detected"
            },
            {
              "label": "Positive",
              "value": "Positive"
            },
            {
              "label": "Detected",
              "value": "Detected"
            },
            {
              "label": "Expected",
              "value": "Expected"
            },
            {
              "label": "Unexpected",
              "value": "Unexpected"
            },
            {
              "label": "Non-reactive",
              "value": "Non-reactive"
            },
            {
              "label": "Reactive",
              "value": "Reactive"
            },
            {
              "label": "Others",
              "value": "Others"
            }
          ]
        },
        {
          "label": "Status",
          "type": "dropdown",
          "placeholder": "Select status",
          "required": true,
          "valueKey": "status",
          "errorMsg": "This field is a required field!",
          "isMappingRequired": true,
          "conversionFromValueToLabel": ["status"],
          "options": [
            {
              "label": "Registered",
              "value": "registered",
              "statusIcon": true,
              "color": "#036FCB"
            },
            {
              "label": "Partial",
              "value": "partial",
              "statusIcon": true,
              "color": "#F2994A"
            },
            {
              "label": "Preliminary",
              "value": "preliminary",
              "statusIcon": true,
              "color": "#F2994A"
            },
            {
              "label": "Final",
              "value": "final",
              "statusIcon": true,
              "color": "#007D32"
            },
            {
              "label": "Amended",
              "value": "amended",
              "statusIcon": true,
              "color": "#007D32"
            },
            {
              "label": "Corrected",
              "value": "corrected",
              "statusIcon": true,
              "color": "#036FCB"
            },
            {
              "label": "Appended",
              "value": "appended",
              "statusIcon": true,
              "color": "#007D32"
            },
            {
              "label": "Cancelled",
              "value": "cancelled",
              "statusIcon": true,
              "color": "#DB0032"
            },
            {
              "label": "Entered in error",
              "value": "entered-in-error",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "label": "Unknown",
              "value": "unknown",
              "statusIcon": true,
              "color": "#737373"
            }
          ]
        },
        {
          "label": "Category code",
          "type": "dropdown",
          "placeholder": "Select category code",
          "valueKey": "categoryCode",
          "isMappingRequired": true,
          "conversionFromValueToLabel": ["categoryCode"],
          "options": [
            {
              "label": "Audiology",
              "value": "AU"
            },
            {
              "label": "Blood Gases",
              "value": "BG"
            },
            {
              "label": "Blood Bank",
              "value": "BLB"
            },
            {
              "label": "Cytogenetics",
              "value": "CG"
            },
            {
              "label": "Chemistry",
              "value": "CH"
            },
            {
              "label": "Cytopathology",
              "value": "CP"
            },
            {
              "label": "CAT Scan",
              "value": "CT"
            },
            {
              "label": "Cardiac Catheterization",
              "value": "CTH"
            },
            {
              "label": "Cardiac Ultrasound",
              "value": "CUS"
            },
            {
              "label": "Electrocardiac (e.g., EKG, EEC, Holter)",
              "value": "EC"
            },
            {
              "label": "Electroneuro (EEG, EMG, EP, PSG)",
              "value": "EN"
            },
            {
              "label": "Genetics",
              "value": "GE"
            },
            {
              "label": "Hematology",
              "value": "HM"
            },
            {
              "label": "Bedside ICU Monitoring",
              "value": "ICU"
            },
            {
              "label": "Immunology",
              "value": "IMM"
            },
            {
              "label": "Laboratory",
              "value": "LAB"
            },
            {
              "label": "Microbiology",
              "value": "MB"
            },
            {
              "label": "Mycobacteriology",
              "value": "MCB"
            },
            {
              "label": "Mycology",
              "value": "MYC"
            },
            {
              "label": "Nuclear Magnetic Resonance",
              "value": "NMR"
            },
            {
              "label": "Nuclear Medicine Scan",
              "value": "NMS"
            },
            {
              "label": "Nursing Service Measures",
              "value": "NRS"
            },
            {
              "label": "Outside Lab",
              "value": "OSL"
            },
            {
              "label": "Occupational Therapy",
              "value": "OT"
            },
            {
              "label": "Other",
              "value": "OTH"
            },
            {
              "label": "OB Ultrasound",
              "value": "OUS"
            },
            {
              "label": "Pulmonary Function",
              "value": "PF"
            },
            {
              "label": "Pharmacy",
              "value": "PHR"
            },
            {
              "label": "Physician (Hx. Dx, admission note, etc.)",
              "value": "PHY"
            },
            {
              "label": "Physical Therapy",
              "value": "PT"
            },
            {
              "label": "Radiology",
              "value": "RAD"
            },
            {
              "label": "Respiratory Care (therapy)",
              "value": "RC"
            },
            {
              "label": "Radiation Therapy",
              "value": "RT"
            },
            {
              "label": "Radiology Ultrasound",
              "value": "RUS"
            },
            {
              "label": "Radiograph",
              "value": "RX"
            },
            {
              "label": "Surgical Pathology",
              "value": "SP"
            },
            {
              "label": "Serology",
              "value": "SR"
            },
            {
              "label": "Toxicology",
              "value": "TX"
            },
            {
              "label": "Virology",
              "value": "VR"
            },
            {
              "label": "Vascular Ultrasound",
              "value": "VUS"
            },
            {
              "label": "Cineradiograph",
              "value": "XRC"
            }
          ]
        },
        {
          "label": "LOINC code",
          "type": "text",
          "placeholder": "Enter lab-test code",
          "valueKey": "loincCode"
        },
        {
          "label": "Issued date",
          "type": "date",
          "valueKey": "issuedDate",
          "required": true,
          "placeholder": "MM / DD / YYYY",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Issued time",
          "type": "time",
          "valueKey": "issuedTime",
          "placeholder": "HH : MM",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Recorded on",
          "type": "date",
          "valueKey": "recordedOn",
          "required": true,
          "placeholder": "MM / DD / YYYY",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Recorded by",
          "type": "text",
          "placeholder": "Recorded by e.g. John Robertson",
          "valueKey": "recordedBy"
        },
        {
          "label": "Issuer of the report",
          "type": "text",
          "placeholder": "Enter issuer name",
          "valueKey": "issuerOfTheReport"
        },
        {
          "label": "Note",
          "type": "multiline",
          "placeholder": "Add note",
          "numberOfLines": 5,
          "valueKey": "note",
          "width": "fullWidth"
        }
      ]
    }
  },
  "care_team": {
    "title": "Care teams",
    "resourceName": "care team",
    "loadingText": "care teams",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careteam/careteam-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "filters": [
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Active",
              "value": "active",
              "iconColor": "#036FCB"
            },
            {
              "label": "Entered in error",
              "value": "entered-in-error",
              "iconColor": "#737373"
            },
            {
              "label": "Inactive",
              "value": "inactive",
              "iconColor": "#DB0032"
            },
            {
              "label": "Proposed",
              "value": "proposed",
              "iconColor": "#F2994A"
            },
            {
              "label": "Suspended",
              "value": "suspended",
              "iconColor": "#DB0032"
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Team name",
          "type": "string",
          "dataCol": "name",
          "collapsedView": true
        },
        {
          "headerName": "Category",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "category",
          "resultKey": "valueString",
          "type": "filter"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Care team details",
      "loadingText": "care team",
      "categoryNameForMarkAsRestricted": "care teams",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "name",
          "key2": "name"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "key": "period.start",
            "type": "date",
            "label": "Start date",
            "requestKeyForSelfReporting": "start_time"
          },
          {
            "label": "Contact number",
            "type": "string",
            "key": "telecom.0.value",
            "requestKeyForSelfReporting": "contactNumber"
          },
          {
            "key": "period.end",
            "type": "date",
            "label": "End date",
            "requestKeyForSelfReporting": "end_time"
          },
          {
            "label": "Category",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "category",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "category"
          },
          {
            "label": "Reason",
            "source": "extension",
            "filterKey": "url",
            "type": "arrayFromExtension",
            "filterVal": "reason",
            "resultKey": "valueString",
            "width": "fullWidth",
            "requestKeyForSelfReporting": "reason"
          },
          {
            "label": "Note",
            "width": "fullWidth",
            "type": "objectList",
            "source": "note",
            "resultKey": "text",
            "requestKeyForSelfReporting": "note"
          }
        ],
        "lists": [
          {
            "header": "Problems",
            "descriptionKey": "addresses.code.coding.display",
            "statusKey": "addresses.clinicalStatus"
          },
          {
            "header": "Goals",
            "descriptionKey": "goal.description.coding.display",
            "statusKey": "goal.lifecycleStatus"
          },
          {
            "header": "Activities",
            "descriptionKey": "activity.detail.code.coding.display",
            "statusKey": ""
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": false
        }
      }
    }
  },
  "care_plan": {
    "title": "Care plans",
    "resourceName": "care plan",
    "loadingText": "care plans",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careplan/careplan-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": false,
        "placeholder": "Search by lab result code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "addDebounce": true,
        "width": "260"
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": true,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on time:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Active",
              "value": "active",
              "iconColor": "#036FCB"
            },
            {
              "label": "Completed",
              "value": "completed",
              "iconColor": "#007D32"
            },
            {
              "label": "Revoked",
              "value": "revoked",
              "iconColor": "#DB0032"
            },
            {
              "label": "Draft",
              "value": "draft",
              "iconColor": "#F2994A"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "gray",
              "mappingList": ["entered-in-error", "on-hold", "unknown"]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Plan name",
          "type": "string",
          "dataCol": "title"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Start date",
          "type": "string",
          "dataCol": "period.start"
        },
        {
          "headerName": "End date",
          "type": "string",
          "dataCol": "period.end"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Care plan",
      "loadingText": "care plan",
      "categoryNameForMarkAsRestricted": "care plan",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "title",
          "key2": "code.coding.0.code"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString"
          },
          {
            "label": "Recorded on",
            "key": "created",
            "type": "date"
          },
          {
            "label": "Recorded by",
            "type": "string",
            "key": "author.display"
          },
          {
            "label": "Facility",
            "type": "string",
            "key": "contributor.0.display"
          },
          {
            "label": "Category",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "category",
            "resultKey": "valueString"
          },
          {
            "label": "Start date",
            "type": "string",
            "key": "period.start"
          },
          {
            "label": "End date",
            "type": "string",
            "key": "period.end"
          },
          {
            "label": "Intent",
            "key": "intent",
            "type": "string"
          },
          {
            "label": "Description",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "key": "description",
            "width": "fullWidth"
          },
          {
            "label": "Note",
            "type": "arrayList",
            "arrayName": "note",
            "objectPath": "text",
            "width": "fullWidth"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      },
      "list": [
        {
          "title": "Problems",
          "arrayName": "addresses",
          "objectPath": "display",
          "parseAssociatedResponseObject": true,
          "desciptionPath": "-",
          "statusPath": "problem-status"
        },
        {
          "title": "Goals",
          "arrayName": "goal",
          "objectPath": "display",
          "parseAssociatedResponseObject": true,
          "desciptionPath": "-",
          "statusPath": "status"
        },
        {
          "title": "Activities",
          "arrayName": "activity",
          "parseAssociatedResponseObject": false,
          "objectPath1": "detail.code.coding.0.display",
          "objectPath2": "detail.code.text",
          "objectPath3": "detail.code.coding.0.code"
        }
      ],
      "associatedData": {
        "title": "Care team",
        "type": "string",
        "source": "extension",
        "filterKey": "url",
        "filterVal": "associated_data",
        "resultKey": "valueString"
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "Lab results",
      "cardFields": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "objectKeyForUI": "recordedOn",
          "dataCol": "recordedOn",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "recordedOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "filter",
          "objectKeyForUI": "dataSource",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "key": "data_source",
          "resultKey": "valueString"
        }
      ]
    }
  },
  "claims": {
      "title": "Claims",
      "resourceName": "claim",
      "loadingText": "claims",
      "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/claims/claims-web.svg",
      "dashboard": {
        "reloadOnScroll": true,
        "selfReporting": {
          "enabled": false
        },
        "search": {
          "isEnabled": true,
          "placeholder": "Search by claim number",
          "requestKey": "search_parameter",
          "deviceType": "all",
          "addDebounce": true,
          "width": "260"
        },
        "filters": [
          {
            "label": "Date :",
            "defaultActiveIndex": 0,
            "datePickerEnabled": false,
            "filterType": "dateFilter",
            "requestKey": "dateFilter",
            "deviceType": "all",
            "modalTitle": "Filter based on time:",
            "options": [
              {
                "label": "All",
                "value": "all"
              },
              {
                "label": "Past 1 month",
                "value": "1M"
              },
              {
                "label": "Past 3 months",
                "value": "3M"
              },
              {
                "label": "Past 6 months",
                "value": "6M"
              }
            ]
          },
          {
            "label": "Status :",
            "defaultActiveIndex": 0,
            "filterType": "statusFilter",
            "requestKey": "statusFilter",
            "deviceType": "all",
            "modalTitle": "Filter based on status:",
            "options": [
              {
                "label": "All",
                "value": "all"
              },
              {
                "label": "Active",
                "value": "active",
                "iconColor": "#036FCB"
              },
              {
                "label": "Cancelled",
                "value": "cancelled",
                "iconColor": "#DB0032"
              },
              {
                "label": "Draft",
                "value": "draft",
                "iconColor": "#F2994A"
              },
              {
                "label": "Others",
                "value": "others",
                "iconColor": "#737373",
                "mappingList": ["entered-in-error", "unknown"]
              }
            ]
          }
        ],
        "tableColumns": [
          {
            "headerName": "Recorded on",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "recorded_on",
            "resultKey": "valueString"
          },
          {
            "headerName": "Claim number",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "claim_number",
            "resultKey": "valueString"
          },
          {
            "headerName": "Claim type",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "claim_type",
            "resultKey": "valueString"
          },
          {
            "headerName": "Status",
            "type": "dotValue",
            "dataCol": "status",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString"
          },
          {
            "headerName": "Provider",
            "type": "string",
            "dataCol": "provider.display"
          },
          {
            "headerName": "Total claim amount",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "total_claim_amount",
            "resultKey": "valueString"
          },
          {
            "headerName": "",
            "type": "button",
            "dataCol": ""
          }
        ]
      },
      "details": {
        "show": true,
        "header": "Claims details",
        "loadingText": "claims",
        "categoryNameForMarkAsRestricted": "claims",
        "detailsTable": {
          "header": {
            "template": "${value}",
            "source": "extension",
            "key1": "-",
            "filterKey": "url",
            "filterVal": "claim_number",
            "resultKey": "valueString"
          },
          "cols": [
            {
              "label": "Status",
              "type": "dotWithStatusfromdiffloc",
              "source1": "status",
              "source2": "extension",
              "filterKey": "url",
              "filterVal": "statusColor",
              "resultKey": "valueString"
            },
            {
              "label": "Provider",
              "type": "string",
              "key": "provider.display"
            },
            {
              "label": "Provider number",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "providerNumber",
              "resultKey": "valueString"
            },
            {
              "label": "Claim type",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "claim_type",
              "resultKey": "valueString"
            },
            {
              "label": "Created on",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "recorded_on",
              "resultKey": "valueString"
            },
            {
              "label": "Total claim amount",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "total_claim_amount",
              "resultKey": "valueString"
            },
            {
              "label": "Start date",
              "type": "string",
              "key": "billablePeriod.start"
            },
            {
              "label": "End date",
              "type": "string",
              "key": "billablePeriod.end"
            },
            {
              "label": "Paid to provider",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "paid_to_provider",
              "resultKey": "valueString"
            },
            {
              "label": "Diagnosis",
              "width": "fullWidth",
              "type": "arrayFromExtension",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "diagnosis",
              "resultKey": "valueString"
            },
            {
              "label": "Procedure",
              "width": "fullWidth",
              "type": "arrayFromExtension",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "procedure",
              "resultKey": "valueString"
            }
          ],
          "options": {
            "markRestricted": true,
            "isHistoricalCardEnable": false
          }
        }
      }
    },
  "procedures": {
    "title": "Procedures",
    "resourceName": "procedure",
    "loadingText": "procedures",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/procedures/procedures-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by procedure code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "addDebounce": true,
        "width": "260"
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on time:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Preparation",
              "value": "preparation",
              "iconColor": "#036FCB"
            },
            {
              "label": "In progress",
              "value": "in-progress",
              "iconColor": "#036FCB"
            },
            {
              "label": "Not done",
              "value": "not-done",
              "iconColor": "#DB0032"
            },
            {
              "label": "Completed",
              "value": "completed",
              "iconColor": "#007D32"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": [
                "on-hold",
                "stopped",
                "entered-in-error",
                "unknown"
              ]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Start date",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "startDate",
          "resultKey": "valueString"
        },
        {
          "headerName": "Procedure",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "procedureCodeName",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Outcome",
          "type": "dotValue",
          "source": "extension",
          "filterKey": "url",
          "filterVals": ["outcomeColor", "outcome"],
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Procedure details",
      "loadingText": "procedures",
      "categoryNameForMarkAsRestricted": "procedure",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "procedureName",
          "resultKey": "valueString"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "source": "extension",
            "filterKey": "url",
            "filterVal": "startDate",
            "resultKey": "valueString",
            "type": "date",
            "label": "Start date",
            "requestKeyForSelfReporting": "startDate"
          },
          {
            "source": "extension",
            "filterKey": "url",
            "filterVal": "endDate",
            "resultKey": "valueString",
            "type": "date",
            "label": "End date",
            "requestKeyForSelfReporting": "endDate"
          },
          {
            "label": "Facility",
            "key": "location.display",
            "type": "string",
            "requestKeyForSelfReporting": "facility"
          },
          {
            "label": "Code",
            "key": "code.coding.0.code",
            "type": "string",
            "requestKeyForSelfReporting": "codeName"
          },
          {
            "label": "Outcome",
            "type": "dot",
            "source": "extension",
            "filterKey": "url",
            "filterVals": ["outcomeColor", "outcome"],
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "outcome"
          },
          {
            "source": "extension",
            "filterKey": "url",
            "filterVal": "complications",
            "resultKey": "valueString",
            "type": "string",
            "label": "Complications",
            "requestKeyForSelfReporting": "complications"
          },
          {
            "label": "Category",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "category",
            "resultKey": "valueString",
            "type": "string",
            "requestKeyForSelfReporting": "category"
          },
          {
            "label": "Follow up",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "followUp",
            "resultKey": "valueString",
            "type": "string",
            "requestKeyForSelfReporting": "followUp"
          },
          {
            "label": "Reason code",
            "width": "fullWidth",
            "type": "arrayFromExtension",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "reasonCode",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "reasonCode"
          },
          {
            "label": "Recorded by",
            "key": "recorder.display",
            "type": "string",
            "requestKeyForSelfReporting": "recordedBy"
          },
          {
            "label": "Body site",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "bodySite",
            "resultKey": "valueString",
            "type": "string",
            "width": "fullWidth",
            "requestKeyForSelfReporting": "bodySite"
          },
          {
            "label": "Documents",
            "key": "docArn",
            "type": "document",
            "width": "fullWidth"
          },
          {
            "label": "Note",
            "width": "fullWidth",
            "type": "objectList",
            "source": "note",
            "resultKey": "text",
            "requestKeyForSelfReporting": "note"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "Procedures",
      "cardFields": [
        {
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recordedOn",
          "headerName": "Recorded on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString",
          "type": "filter"
        },
        {
          "filterKey": "url",
          "filterVal": "dataSource",
          "headerName": "Data source",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString",
          "type": "filter"
        }
      ]
    },
    "selfReportingSection": {
      "resourceType": "procedures",
      "steps": ["Add data", "Documents"],
      "resourceNameForSelfReportingFileUpload": "Procedure",
      "formElementList": [
        {
          "label": "Procedure name",
          "placeholder": "Enter procedure name",
          "required": true,
          "type": "text",
          "valueKey": "procedureName",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Facility",
          "placeholder": "Enter facility name",
          "type": "text",
          "valueKey": "facility"
        },
        {
          "label": "Outcome",
          "type": "dropdown",
          "required": true,
          "placeholder": "Select outcome",
          "valueKey": "outcome",
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "label": "Successful",
              "value": "Successful"
            },
            {
              "label": "Unsuccessful",
              "value": "Unsuccessful"
            },
            {
              "label": "Partially successful",
              "value": "Partially successful"
            }
          ]
        },
        {
          "label": "Reason",
          "placeholder": "Enter reason for procedure",
          "type": "text",
          "valueKey": "reasonCode"
        },
        {
          "label": "Complications",
          "placeholder": "Enter complications after procedure",
          "type": "text",
          "valueKey": "complications"
        },
        {
          "label": "Status",
          "type": "dropdown",
          "required": true,
          "isMappingRequired": true,
          "conversionFromValueToLabel": ["status"],
          "placeholder": "select status",
          "valueKey": "status",
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "label": "Preparation",
              "value": "preparation",
              "color": "#036FCB",
              "statusIcon": true,
              "key": 0
            },
            {
              "label": "In progress",
              "value": "in-progress",
              "color": "#036FCB",
              "statusIcon": true,
              "key": 1
            },
            {
              "label": "Not done",
              "value": "not-done",
              "color": "#DB0032",
              "statusIcon": true,
              "key": 2
            },
            {
              "label": "On hold",
              "value": "on-hold",
              "color": "#737373",
              "statusIcon": true,
              "key": 3
            },
            {
              "label": "Stopped",
              "value": "stopped",
              "color": "#737373",
              "statusIcon": true,
              "key": 4
            },
            {
              "label": "Completed",
              "value": "completed",
              "color": "#007D32",
              "statusIcon": true,
              "key": 5
            },
            {
              "label": "Entered in error",
              "value": "entered-in-error",
              "color": "#737373",
              "statusIcon": true,
              "key": 6
            },
            {
              "label": "Unknown",
              "value": "unknown",
              "color": "#737373",
              "statusIcon": true,
              "key": 7
            }
          ]
        },
        {
          "label": "Code",
          "placeholder": "Enter procedure code name",
          "valueKey": "codeName",
          "type": "text"
        },
        {
          "label": "Follow up",
          "placeholder": "Enter instructions you need to follow",
          "type": "text",
          "valueKey": "followUp"
        },
        {
          "label": "Start date",
          "type": "date",
          "valueKey": "startDate",
          "required": true,
          "placeholder": "MM / DD / YYYY",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "End date",
          "type": "date",
          "valueKey": "endDate",
          "placeholder": "MM / DD / YYYY"
        },
        {
          "label": "Recorded by",
          "placeholder": "Enter name e.g., Kerry, John",
          "type": "text",
          "valueKey": "recordedBy"
        },
        {
          "label": "Category",
          "placeholder": "Choose procedure classification",
          "type": "dropdown",
          "valueKey": "category",
          "options": [
            {
              "label": "Psychiatry procedure or service",
              "value": "Psychiatry procedure or service"
            },
            {
              "label": "Counseling",
              "value": "Counselling"
            },
            {
              "label": "Education",
              "value": "Education"
            },
            {
              "label": "Surgical procedure",
              "value": "Surgical procedure"
            },
            {
              "label": "Diagnostic procedure",
              "value": "Diagnostic procedure"
            },
            {
              "label": "Chiropractic manipulation",
              "value": "Chiropractic manipulation"
            },
            {
              "label": "Social service procedure",
              "value": "Social service procedure"
            }
          ]
        },
        {
          "label": "Body site",
          "placeholder": "Enter body site e.g. left arm",
          "type": "multiline",
          "numberOfLines": 8,
          "valueKey": "bodySite",
          "width": "fullWidth"
        },
        {
          "label": "Note",
          "placeholder": "Add note about the procedure",
          "type": "multiline",
          "numberOfLines": 4,
          "valueKey": "note",
          "width": "fullWidth"
        }
      ]
    }
  },
  "visits": {
    "title": "Visits",
    "resourceName": "visits",
    "loadingText": "visits",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/visits/visits-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "datePicker": true,
        "placeholder": "Search by visit date",
        "requestKey": "dateFilter",
        "deviceType": "all",
        "addDebounce": true,
        "width": "180"
      },
      "filters": [
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Planned",
              "value": "planned",
              "iconColor": "#F2994A"
            },
            {
              "label": "In progress",
              "value": "in-progress",
              "iconColor": "#036FCB"
            },
            {
              "label": "Arrived",
              "value": "arrived",
              "iconColor": "#007D32"
            },
            {
              "label": "Finished",
              "value": "finished",
              "iconColor": "#007D32"
            },
            {
              "label": "Cancelled",
              "value": "cancelled",
              "iconColor": "#DB0032"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": [
                "triaged",
                "onleave",
                "entered-in-error",
                "unknown"
              ]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Start date",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "startDate",
          "resultKey": "valueString"
        },
        {
          "headerName": "Facility",
          "type": "string",
          "dataCol": "serviceProvider.display"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Type of visit",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "visitClass",
          "resultKey": "valueString"
        },
        {
          "headerName": "Provider",
          "type": "string",
          "dataCol": "participant.0.individual.display"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "visits details",
      "loadingText": "visits",
      "categoryNameForMarkAsRestricted": "visits",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "CardHeader",
          "resultKey": "valueString"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString"
          },
          {
            "source": "extension",
            "filterKey": "url",
            "filterVal": "startDate",
            "resultKey": "valueString",
            "type": "filter",
            "label": "Start date"
          },
          {
            "source": "extension",
            "filterKey": "url",
            "filterVal": "endDate",
            "resultKey": "valueString",
            "type": "filter",
            "label": "End date"
          },
          {
            "label": "Recorded by",
            "key": "participant.0.individual.display",
            "type": "string"
          },
          {
            "label": "Visit class",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "visitClass",
            "resultKey": "valueString"
          },
          {
            "label": "Provider",
            "type": "string",
            "key": "participant.0.individual.display"
          },
          {
            "label": "Last visited on",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "lastVisitedOn",
            "resultKey": "valueString",
            "type": "filter"
          },
          {
            "label": "Provider speciality",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "SPECIALITY_DISPLAY",
            "resultKey": "valueString",
            "type": "filter"
          },
          {
            "label": "Service type",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "serviceType",
            "resultKey": "valueString",
            "type": "filter"
          },
          {
            "label": "Encounter type",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "EncounterType",
            "resultKey": "valueString",
            "type": "filter"
          },
          {
            "label": "Description",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "description",
            "resultKey": "valueString",
            "type": "filter"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": false
        }
      }
    }
  },
  "immunizations": {
    "title": "Immunizations",
    "resourceName": "immunization",
    "loadingText": "immunizations",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/immunizations/immunizations-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by immunization code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "280",
        "addDebounce": true
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on time:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Completed",
              "value": "completed",
              "iconColor": "#007D32"
            },
            {
              "label": "Not done",
              "value": "not-done",
              "iconColor": "#DB0032"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": ["entered-in-error", "unknown"]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Administered on",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "administeredOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Immunization",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "immunizationCodeName",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Dosage",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dosage",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Immunization details",
      "loadingText": "immunizations",
      "categoryNameForMarkAsRestricted": "immunization",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "immunizationName",
          "resultKey": "valueString"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "label": "Administered on",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "administeredOn",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "recordedOn"
          },
          {
            "label": "Administered by",
            "key": "performer.0.actor.display",
            "type": "string",
            "requestKeyForSelfReporting": "recordedBy"
          },
          {
            "label": "Body site",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "bodySite",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "bodySite"
          },
          {
            "label": "Route",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "route",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "route"
          },
          {
            "label": "Dosage",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dosage",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "dose"
          },
          {
            "label": "NDC code",
            "key": "vaccineCode.coding.0.code",
            "type": "string",
            "requestKeyForSelfReporting": "ndcCode"
          },
          {
            "label": "Vaccine lot no",
            "key": "lotNumber",
            "type": "string",
            "requestKeyForSelfReporting": "vaccineLotnumber"
          },
          {
            "label": "Vaccine manufacturer",
            "key": "manufacturer.display",
            "type": "string",
            "requestKeyForSelfReporting": "vaccineManufacturer"
          },
          {
            "label": "Vaccine dose no.",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "vaccineDoseNumber",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "vaccineDosenumber"
          },
          {
            "label": "Reason",
            "width": "fullWidth",
            "type": "arrayFromExtension",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "reasonCode",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "reasons"
          },
          {
            "label": "Reaction",
            "arrayName": "reaction",
            "type": "arrayList",
            "objectPath": "detail.display",
            "requestKeyForSelfReporting": "reaction"
          },
          {
            "label": "Vaccine expiration date",
            "type": "string",
            "key": "expirationDate",
            "requestKeyForSelfReporting": "vaccineExpirationdate"
          },
          {
            "label": "Facility",
            "type": "string",
            "key": "encounter.display",
            "requestKeyForSelfReporting": "facility"
          },
          {
            "label": "Note",
            "width": "fullWidth",
            "type": "objectList",
            "source": "note",
            "resultKey": "text",
            "requestKeyForSelfReporting": "note"
          },
          {
            "label": "Documents",
            "key": "docArn",
            "type": "document",
            "width": "fullwidth"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "immunizations",
      "cardFields": [
        {
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recordedOn",
          "headerName": "Recorded on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString",
          "type": "filter"
        },
        {
          "filterKey": "url",
          "filterVal": "dataSource",
          "headerName": "Data source",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString",
          "type": "filter"
        }
      ]
    },
    "selfReportingSection": {
      "resourceType": "immunizations",
      "resourceNameForSelfReportingFileUpload": "Immunization",
      "steps": ["Add data", "Documents"],
      "formElementList": [
        {
          "label": "Immunization name",
          "placeholder": "Enter vaccination name",
          "required": true,
          "type": "text",
          "valueKey": "immunizationName",
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Dosage",
          "placeholder": "Enter dosage e.g. 2.5 ml,5 ml,10 ml,etc",
          "type": "text",
          "valueKey": "dose"
        },
        {
          "label": "Body site",
          "placeholder": "Enter body site e.g. left arm",
          "type": "text",
          "valueKey": "bodySite"
        },
        {
          "label": "Vaccine dose number",
          "placeholder": "Enter vaccine dose number",
          "type": "text",
          "valueKey": "vaccineDosenumber",
          "regex": "^([1-9]|[1-9][0-9]|[1-4][0-9][0-9]|500)$",
          "errorMsg": "Please enter a valid vaccine dose number between 1 - 500",
          "information": "Please enter a valid vaccine dose number between 1 - 500"
        },
        {
          "label": "Vaccine manufacturer",
          "placeholder": "Enter vaccine manufacturer",
          "type": "text",
          "valueKey": "vaccineManufacturer"
        },
        {
          "label": "Vaccine lot number",
          "placeholder": "Enter vaccine lot number",
          "type": "text",
          "valueKey": "vaccineLotnumber"
        },
        {
          "label": "Vaccine expiration date",
          "placeholder": "Enter vaccine expiration date",
          "type": "date",
          "valueKey": "vaccineExpirationdate",
          "allowFutureDates": true
        },
        {
          "label": "Route",
          "type": "dropdown",
          "required": true,
          "placeholder": "Select route",
          "valueKey": "route",
          "conversionFromValueToLabel": ["route"],
          "isMappingRequired": true,
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "key": 0,
              "label": "Injection, intradermal",
              "value": "IDINJ"
            },
            {
              "key": 1,
              "label": "Injection, intramuscular",
              "value": "IM"
            },
            {
              "key": 2,
              "label": "Inhalation, nasal",
              "value": "NASINHLC"
            },
            {
              "key": 3,
              "label": "Injection, intravenous",
              "value": "IVINJ"
            },
            {
              "key": 4,
              "label": "Swallow, oral",
              "value": "PO"
            },
            {
              "key": 5,
              "label": "Injection, subcutaneous",
              "value": "SQ"
            },
            {
              "key": 6,
              "label": "Transdermal",
              "value": "TRNSDERM"
            }
          ]
        },
        {
          "label": "NDC code",
          "placeholder": "Enter vaccination code",
          "type": "text",
          "valueKey": "ndcCode"
        },
        {
          "label": "Status",
          "type": "dropdown",
          "required": true,
          "placeholder": "Select status",
          "valueKey": "status",
          "isMappingRequired": true,
          "conversionFromValueToLabel": ["status"],
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "key": 0,
              "label": "Completed",
              "value": "completed",
              "statusIcon": true,
              "color": "#007D32"
            },
            {
              "key": 1,
              "label": "Entered in error",
              "value": "entered-in-error",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "key": 2,
              "label": "Not done",
              "value": "not-done",
              "statusIcon": true,
              "color": "#DB0032"
            }
          ]
        },
        {
          "label": "Administered on",
          "placeholder": "MM / DD / YYYY",
          "type": "date",
          "valueKey": "recordedOn",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Administered by",
          "placeholder": "Administered by e.g. John Robertson",
          "type": "text",
          "valueKey": "recordedBy"
        },
        {
          "label": "Facility",
          "placeholder": "Facility name, e.g. Mayo Clinic",
          "type": "text",
          "valueKey": "facility"
        },
        {
          "label": "Reasons",
          "placeholder": "Add reasons",
          "type": "multiline",
          "numberOfLines": 5,
          "valueKey": "reasons",
          "width": "fullWidth",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Reactions",
          "placeholder": "Add reactions related to immunization",
          "type": "multiple",
          "numberOfLines": 5,
          "valueKey": "reaction",
          "isConvertionRequired": true,
          "conversionType": "list",
          "width": "fullWidth"
        },
        {
          "label": "Note",
          "placeholder": "Add note related to immunization",
          "type": "multiline",
          "numberOfLines": 4,
          "valueKey": "note",
          "width": "fullWidth"
        }
      ]
    }
  },
  "smoking_status": {
    "title": "Social history",
    "resourceName": "social history",
    "loadingText": "social history",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/socialhistory/socialhistory-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by social history code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "280",
        "addDebounce": true
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on time:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Final",
              "value": "final",
              "iconColor": "#007D32"
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": [
                "entered-in-error",
                "registered",
                "preliminary",
                "amended",
                "corrected",
                "cancelled",
                "unknown"
              ]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Onset time",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "onsetTime",
          "resultKey": "valueString"
        },
        {
          "headerName": "Name",
          "source": "extension",
          "filterKey": "url",
          "resultKey": "valueString",
          "type": "filter",
          "filterVal": "socialHistoryCodeName"
        },
        {
          "headerName": "Recorded by",
          "type": "string",
          "dataCol": "performer.0.display"
        },
        {
          "headerName": "Frequency",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "frequency",
          "resultKey": "valueString",
          "type": "filter"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Social history details",
      "loadingText": "social history",
      "categoryNameForMarkAsRestricted": "social history",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "-",
          "source": "extension",
          "filterKey": "url",
          "resultKey": "valueString",
          "type": "filter",
          "filterVal": "socialHistoryName"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString"
          },
          {
            "key": "issued",
            "type": "string",
            "label": "Recorded on"
          },
          {
            "key": "performer.0.display",
            "type": "string",
            "label": "Recorded by"
          },
          {
            "label": "Frequency",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "frequency",
            "resultKey": "valueString",
            "type": "filter"
          },
          {
            "label": "Code",
            "key": "code.coding.0.code",
            "type": "string"
          },
          {
            "label": "Onset time",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "onsetTime",
            "resultKey": "valueString",
            "type": "filter"
          },
          {
            "label": "Note",
            "width": "fullWidth",
            "type": "objectList",
            "source": "note",
            "resultKey": "text"
          },
          {
            "label": "Documents",
            "key": "docArn",
            "type": "document",
            "width": "fullwidth"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": false
        }
      }
    }
  },
  "medications": {
    "title": "Medications",
    "resourceName": "medication",
    "loadingText": "medications",
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/medications/medications-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by medication code",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "265",
        "addDebounce": true
      },
      "queryResourceTypes": ["medicationRequest", "medicationStatement"],
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Active",
              "value": "active",
              "iconColor": "#036FCB"
            },
            {
              "label": "Inactive",
              "value": "inactive",
              "iconColor": "#DB0032",
              "mappingList": ["stopped", "completed", "cancelled"]
            },
            {
              "label": "Others",
              "value": "others",
              "iconColor": "#737373",
              "mappingList": ["on-hold", "entered-in-error", "draft", "unknown"]
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Recorded on",
          "type": "string",
          "dataCol": "authoredOn"
        },
        {
          "headerName": "Medication",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "medicationCodeName",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Frequency",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "frequency",
          "resultKey": "valueString"
        },
        {
          "headerName": "Dosage",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dosage",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Medication details",
      "loadingText": "medications",
      "categoryNameForMarkAsRestricted": "medication",
      "medicationRequest": {
        "detailsTable": {
          "header": {
            "template": "${value}",
            "key1": "medicationCodeableConcept.coding.0.display",
            "source": "extension",
            "filterVal": "name",
            "filterKey": "url",
            "resultKey": "valueString"
          },
          "cols": [
            {
              "label": "Data source",
              "type": "pill",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "dataSource",
              "resultKey": "valueString"
            },
            {
              "label": "Status",
              "type": "dotWithStatusfromdiffloc",
              "source1": "status",
              "source2": "extension",
              "filterKey": "url",
              "filterVal": "statusColor",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "status"
            },
            {
              "label": "Recorded on",
              "key": "authoredOn",
              "type": "string",
              "requestKeyForSelfReporting": "autheredOn"
            },
            {
              "label": "Type of medication",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "type",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "medicationType"
            },
            {
              "label": "Dosage",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "dosage",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "dosage"
            },
            {
              "label": "Recorded by",
              "key": "performer.display",
              "type": "string",
              "requestKeyForSelfReporting": "recordedBy"
            },
            {
              "label": "Prescriber",
              "key": "recorder.display",
              "type": "string",
              "requestKeyForSelfReporting": "prescriber"
            },
            {
              "label": "Code",
              "type": "string",
              "requestKeyForSelfReporting": "code",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "code",
              "resultKey": "valueString"
            },
            {
              "label": "Start date",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "startDate",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "startDate"
            },
            {
              "label": "End date",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "endDate",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "endDate"
            },
            {
              "label": "Ingredients",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "ingredients",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "ingredients"
            },
            {
              "label": "Frequency",
              "type": "string",
              "requestKeyForSelfReporting": "frequency",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "frequency",
              "resultKey": "valueString"
            },
            {
              "label": "Manufacturer",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "manufacturer",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "manufacturer"
            },
            {
              "label": "Requested by",
              "key": "requester.display",
              "type": "string",
              "requestKeyForSelfReporting": "requestedBy"
            },
            {
              "label": "Documents",
              "type": "document",
              "key": "docArn",
              "requestKeyForSelfReporting": "documents",
              "width": "fullwidth"
            }
          ],
          "options": {
            "markRestricted": true,
            "isHistoricalCardEnable": true
          }
        },
        "list": {
          "mainHeader": "Dose",
          "header1": "No of pills",
          "value1": "",
          "objectPath1": "timing.repeat.frequency",
          "header2": "Time",
          "value2": "",
          "objectPath2": "timing.repeat.timeOfDay.0",
          "header3": "Note",
          "value3": "",
          "objectPath3": "text"
        },
        "buttonDetails": {
          "headerName": "Medication adherence",
          "type": "button",
          "dataCol": ""
        }
      },
      "medicationStatement": {
        "detailsTable": {
          "header": {
            "template": "${value}",
            "key1": "medicationCodeableConcept.coding.0.display",
            "source": "extension",
            "filterVal": "name",
            "filterKey": "url",
            "resultKey": "valueString"
          },
          "cols": [
            {
              "label": "Data source",
              "type": "pill",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "dataSource",
              "resultKey": "valueString"
            },
            {
              "label": "Status",
              "type": "dotWithStatusfromdiffloc",
              "source1": "status",
              "source2": "extension",
              "filterKey": "url",
              "filterVal": "statusColor",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "status"
            },
            {
              "label": "Recorded on",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "recordedOn",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "recordedOn"
            },
            {
              "label": "Type of medication",
              "type": "filter",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "typeOfMedication",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "medicationType"
            },
            {
              "label": "Dosage",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "dosage",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "dosage"
            },
            {
              "label": "Recorded by",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "recordedBy",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "recordedBy"
            },
            {
              "label": "Prescriber",
              "type": "string",
              "key": "informationSource.display",
              "requestKeyForSelfReporting": "prescriber"
            },
            {
              "label": "Code",
              "type": "string",
              "requestKeyForSelfReporting": "code",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "code",
              "resultKey": "valueString"
            },
            {
              "label": "Start date",
              "type": "date",
              "key": "effectivePeriod.start",
              "requestKeyForSelfReporting": "startDate"
            },
            {
              "label": "End date",
              "type": "date",
              "key": "effectivePeriod.end",
              "requestKeyForSelfReporting": "endDate"
            },
            {
              "label": "Ingredients",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "ingredients",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "ingredients"
            },
            {
              "label": "Frequency",
              "type": "string",
              "requestKeyForSelfReporting": "frequency",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "frequency",
              "resultKey": "valueString"
            },
            {
              "label": "Manufacturer",
              "type": "string",
              "source": "extension",
              "filterKey": "url",
              "filterVal": "manufacturer",
              "resultKey": "valueString",
              "requestKeyForSelfReporting": "manufacturer"
            },
            {
              "label": "Requested by",
              "type": "string",
              "key": "informationSource.display",
              "requestKeyForSelfReporting": "requestedBy"
            },
            {
              "label": "Documents",
              "type": "document",
              "key": "docArn",
              "requestKeyForSelfReporting": "documents",
              "width": "fullwidth"
            }
          ],
          "options": {
            "markRestricted": true,
            "isHistoricalCardEnable": true
          }
        },
        "list": {
          "mainHeader": "Dose",
          "header1": "No of pills",
          "value1": "",
          "objectPath1": "timing.repeat.frequency",
          "header2": "Time",
          "value2": "",
          "objectPath2": "timing.repeat.timeOfDay.0",
          "header3": "Note",
          "value3": "",
          "objectPath3": "text"
        },
        "buttonDetails": {
          "headerName": "Medication adherence",
          "type": "button",
          "dataCol": ""
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "medications",
      "cardFields": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recorded_on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "filter",
          "filterKey": "url",
          "filterVal": "data_source",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString"
        }
      ]
    },
    "selfReportingSection": {
      "resourceType": "Medications",
      "steps": ["Add data", "Documents"],
      "resourceNameForSelfReportingFileUpload": "MedicationRequest",
      "convertToLowerCaseList": ["status", "medicationName", "medicationType"],
      "formElementList": [
        {
          "label": "Name of medication",
          "type": "text",
          "placeholder": "Name of medication",
          "valueKey": "medicationName",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Type of medication",
          "placeholder": "Enter type of medication e.g. powder, tablets, capsule, etc",
          "type": "text",
          "valueKey": "medicationType"
        },
        {
          "label": "Code",
          "placeholder": "Enter medication code",
          "type": "text",
          "valueKey": "code"
        },
        {
          "label": "Frequency",
          "type": "dropdown",
          "placeholder": "Select frequency",
          "valueKey": "frequency",
          "options": [
            {
              "label": "Once a day",
              "value": 1
            },
            {
              "label": "Twice a day",
              "value": 2
            },
            {
              "label": "Thrice a day",
              "value": 3
            },
            {
              "label": "Four times a day",
              "value": 4
            }
          ]
        },
        {
          "label": "Dosage",
          "placeholder": "Enter medication dosage e.g. 10 mg, 5 mg, etc",
          "type": "text",
          "valueKey": "dosage"
        },
        {
          "label": "Start date",
          "type": "date",
          "placeholder": "MM / DD / YYYY",
          "valueKey": "startDate",
          "required": true,
          "errorMsg": "This field is a required field!",
          "formatting": "(val) => {\n return val && new Date(val).toUTCString();\n }"
        },
        {
          "label": "Recorded by",
          "placeholder": "Recorded by e.g. John Robertson",
          "type": "text",
          "valueKey": "recordedBy"
        },
        {
          "label": "Status",
          "placeholder": "Select status",
          "type": "dropdown",
          "valueKey": "status",
          "required": true,
          "errorMsg": "This field is a required field!",
          "isMappingRequired": true,
          "conversionFromValueToLabel": ["status"],
          "options": [
            {
              "value": "active",
              "label": "Active",
              "statusIcon": true,
              "color": "#036FCB"
            },
            {
              "value": "on-hold",
              "label": "On hold",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "value": "cancelled",
              "label": "Cancelled",
              "statusIcon": true,
              "color": "#DB0032"
            },
            {
              "value": "completed",
              "label": "Completed",
              "statusIcon": true,
              "color": "#148E3C"
            },
            {
              "value": "entered-in-error",
              "label": "Entered in error",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "value": "stopped",
              "label": "Stopped",
              "statusIcon": true,
              "color": "#737373"
            },
            {
              "value": "draft",
              "label": "Draft",
              "statusIcon": true,
              "color": "#F2994A"
            },
            {
              "value": "unknown",
              "label": "Unknown",
              "statusIcon": true,
              "color": "#737373"
            }
          ]
        },
        {
          "label": "Prescriber",
          "placeholder": "Enter name of provider",
          "type": "text",
          "valueKey": "prescriber"
        },
        {
          "label": "Manufacturer",
          "type": "text",
          "valueKey": "manufacturer",
          "placeholder": "Enter name of manufacturer"
        },
        {
          "label": "Ingredients",
          "type": "text",
          "valueKey": "ingredients",
          "placeholder": "Enter name of ingredients"
        }
      ],
      "lists": [
        {
          "header": "No of pills",
          "value": "",
          "objectPath": "dosageInstruction.0.timing.repeat.frequency"
        },
        {
          "header": "Time",
          "value": "",
          "objectPath": "dosageInstruction.0.timing.repeat.frequency"
        },
        {
          "header": "Note",
          "value": "",
          "objectPath": "note.0.text"
        }
      ]
    }
  },
  "family_member_history": {
    "title": "Family history",
    "resourceName": "family history",
    "loadingText": "family history",
    "bgColorForInitial": "#00A0DF",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Partial",
              "value": "partial",
              "iconColor": "#F2994A"
            },
            {
              "label": "Completed",
              "value": "completed",
              "iconColor": "#007D32"
            },
            {
              "label": "Unknown",
              "value": "unknown",
              "mappingList": ["entered-in-error", "health-unknown"],
              "iconColor": "#737373"
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Recorded on",
          "type": "string",
          "dataCol": "date"
        },
        {
          "headerName": "Name",
          "type": "stringWithFirstLetter",
          "dataColForName": "name",
          "dataCol": "name"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "dataCol": "status",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "statusColor",
          "resultKey": "valueString"
        },
        {
          "headerName": "Relationship",
          "type": "filter",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "relationship",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "dataSource",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Family member history details",
      "loadingText": "family history",
      "categoryNameForMarkAsRestricted": "family member history",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "name",
          "key2": "name"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "dataSource",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dotWithStatusfromdiffloc",
            "source1": "status",
            "source2": "extension",
            "filterKey": "url",
            "filterVal": "statusColor",
            "resultKey": "valueString"
          },
          {
            "label": "Recorded on",
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "recorded_on",
            "resultKey": "valueString"
          },
          {
            "label": "Gender",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "gender",
            "resultKey": "valueString"
          },
          {
            "label": "Relationship",
            "type": "filter",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "relationship",
            "resultKey": "valueString"
          }
        ],
        "valueAndDateTable": [
          {
            "label": "Condition",
            "labelKey": "condition"
          },
          {
            "label": "Start date",
            "labelKey": "date"
          },
          {
            "label": "Reason for history",
            "labelKey": "reasonForHistory",
            "width": "fullWidth"
          },
          {
            "label": "Note",
            "labelKey": "note",
            "width": "fullWidth"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": false
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "family history",
      "cardFields": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recorded_on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "filter",
          "filterKey": "url",
          "filterVal": "dataSource",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString"
        }
      ]
    }
  },
  "coverage": {
    "title": "Coverage",
    "resourceName": "coverage",
    "icon": "",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": true,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by coverage policy number",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "260",
        "addDebounce": true
      },
      "filters": [
        {
          "label": "Date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "options": [
            {
              "label": "All",
              "value": "all",
              "iconColor": "inherit"
            },
            {
              "label": "Partial",
              "value": "partial",
              "iconColor": "#F2994A"
            },
            {
              "label": "Completed",
              "value": "completed",
              "iconColor": "#007D32"
            },
            {
              "label": "Unknown",
              "value": "unknown",
              "mappingList": ["entered-in-error", "health-unknown"],
              "iconColor": "#737373"
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Coverage",
          "type": "string",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "coverage",
          "resultKey": "valueString"
        },
        {
          "headerName": "Policy number",
          "type": "string",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "policy_number",
          "resultKey": "valueString"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "source": "extension",
          "filterKey": "url",
          "filterVals": ["status_color", "status"],
          "resultKey": "valueString"
        },
        {
          "headerName": "Start date",
          "type": "date",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "start_date",
          "resultKey": "valueString"
        },
        {
          "headerName": "Expiry date",
          "type": "date",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "expiry_date",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "pillString",
          "source": "identifier",
          "filterKey": "system",
          "filterVal": "data_source",
          "resultKey": "value"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Coverage",
      "categoryNameForMarkAsRestricted": "coverage",
      "detailsTable": {
        "header": {
          "template": "${value}",
          "key1": "class.name"
        },
        "cols": [
          {
            "label": "Data source",
            "type": "pill",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "data_source",
            "resultKey": "valueString"
          },
          {
            "label": "Status",
            "type": "dot",
            "source": "extension",
            "filterKey": "url",
            "filterVals": ["status_color", "status"],
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "status"
          },
          {
            "label": "Recorded on",
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "recorded_on",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "recordedOn"
          },
          {
            "label": "Policy number",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "policy_number",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "policyNumber"
          },
          {
            "label": "Subscriber id",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "subscriber_id",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "subscrberId"
          },
          {
            "label": "Subscriber name",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "subscriber_name",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "subscrberName"
          },
          {
            "label": "Relationship",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "relationship",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "relationship"
          },
          {
            "label": "Plan type",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "plan_type",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "planType"
          },
          {
            "label": "Plan name",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "plan_name",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "planName"
          },
          {
            "label": "Plan id",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "plan_id",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "planId"
          },
          {
            "label": "Coverage type",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "coverage_type",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "coverageType"
          },
          {
            "label": "Issuer organization",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "issuer_organization",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "issuerOrganization"
          },
          {
            "label": "Start date",
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "start_date",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "startDate"
          },
          {
            "label": "End date",
            "type": "date",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "end_date",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "endDate"
          },
          {
            "label": "Total amount",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "total_amount",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "totalAmount"
          },
          {
            "label": "Contact name",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "contact_name",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "contactName"
          },
          {
            "label": "Contact phone number",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "contact_phone_number",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "contactPhoneNumber"
          },
          {
            "label": "Contact address",
            "type": "string",
            "source": "extension",
            "filterKey": "url",
            "filterVal": "contact_address",
            "resultKey": "valueString",
            "requestKeyForSelfReporting": "contactAddress",
            "width": "fullWidth"
          },
          {
            "label": "Note",
            "type": "string",
            "key": "note.0.text",
            "requestKeyForSelfReporting": "note",
            "width": "fullwidth"
          },
          {
            "label": "Documents",
            "type": "document",
            "key": "docArn",
            "requestKeyForSelfReporting": "documents",
            "width": "fullwidth"
          }
        ],
        "options": {
          "markRestricted": true,
          "isHistoricalCardEnable": true
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "code.coding.0.display",
      "categoryName": "coverage",
      "cardFields": [
        {
          "headerName": "Recorded on",
          "type": "filter",
          "dataCol": "recordedOn",
          "filterKey": "url",
          "filterVal": "recorded_on",
          "source": "extension",
          "objectKeyForUI": "recordedOn",
          "resultKey": "valueString"
        },
        {
          "headerName": "Data source",
          "type": "filter",
          "filterKey": "url",
          "filterVal": "data_source",
          "key": "data_source",
          "source": "extension",
          "objectKeyForUI": "dataSource",
          "resultKey": "valueString"
        }
      ]
    },
    "selfReportingSection": {
      "resourceNameForSelfReportingFileUpload": "Coverage",
      "resourceType": "coverage",
      "convertToLowerCaseList": ["status"],
      "formElementList": [
        {
          "label": "Policy number",
          "placeholder": "Enter policy number",
          "type": "text",
          "valueKey": "policyNumber",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Subscriber id",
          "placeholder": "Enter subscriber ID",
          "type": "text",
          "valueKey": "subscriberId",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Subscriber name",
          "placeholder": "Enter subscriber name",
          "type": "text",
          "valueKey": "subscriber"
        },
        {
          "label": "Relationship",
          "type": "dropdown",
          "placeholder": "Select relationship",
          "valueKey": "relationship",
          "options": [
            {
              "label": "Child",
              "value": "Child"
            },
            {
              "label": "Parent",
              "value": "Parent"
            },
            {
              "label": "Spouse",
              "value": "Spouse"
            },
            {
              "label": "Common",
              "value": "Common"
            },
            {
              "label": "Other",
              "value": "Other"
            },
            {
              "label": "Self",
              "value": "Self"
            },
            {
              "label": "Injured",
              "value": "Injured"
            }
          ]
        },
        {
          "label": "Plan type",
          "placeholder": "Enter plan type",
          "type": "text",
          "valueKey": "planType",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Plan name",
          "placeholder": "Enter plan name",
          "type": "text",
          "valueKey": "healthPlanName",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Plan id",
          "placeholder": "Enter plan ID",
          "type": "text",
          "valueKey": "planId"
        },
        {
          "label": "Coverage type",
          "placeholder": "Enter coverage type",
          "type": "text",
          "valueKey": "coverageType"
        },
        {
          "label": "Status",
          "placeholder": "Select status",
          "type": "dropdown",
          "valueKey": "status",
          "required": true,
          "errorMsg": "This field is a required field!",
          "options": [
            {
              "key": 0,
              "label": "Active",
              "value": "active",
              "statusIcon": true,
              "color": "#036FCB"
            },
            {
              "key": 1,
              "label": "Draft",
              "value": "draft",
              "statusIcon": true,
              "color": "#F2994A"
            },
            {
              "key": 2,
              "label": "Cancelled",
              "value": "cancelled",
              "statusIcon": true,
              "color": "#DB0032"
            },
            {
              "key": 3,
              "label": "Entered in error",
              "value": "entered-in-error",
              "statusIcon": true,
              "color": "#737373"
            }
          ]
        },
        {
          "label": "Start date",
          "placeholder": "MM / DD / YYYY",
          "type": "date",
          "valueKey": "startDate",
          "required": true,
          "errorMsg": "This is a required field. Start date cannot be after the end date",
          "allowFutureDates": true
        },
        {
          "label": "End date",
          "placeholder": "MM / DD / YYYY",
          "type": "date",
          "valueKey": "endDate",
          "required": true,
          "errorMsg": "This is a required field. End date cannot be before the start date",
          "allowFutureDates": true
        },
        {
          "label": "Total amount (in $)",
          "placeholder": "Enter total cost",
          "type": "text",
          "valueKey": "totalCost",
          "regex": "^[0-9]+(.[0-9]+)?$",
          "errorMsg": "Please enter a valid amount"
        },
        {
          "label": "Contact name",
          "placeholder": "Enter contact name",
          "type": "text",
          "valueKey": "contactName"
        },
        {
          "label": "Contact phone number",
          "placeholder": "Enter contact phone number",
          "type": "text",
          "valueKey": "contactPhoneNumber",
          "regex": "^[0-9]{10}$",
          "errorMsg": "Please enter a valid 10 digit phone number"
        },
        {
          "label": "Issuer organization",
          "placeholder": "Enter issuer organization",
          "type": "text",
          "valueKey": "issuerOrganization",
          "required": true,
          "errorMsg": "This field is a required field!"
        },
        {
          "label": "Contact address",
          "placeholder": "Enter contact address",
          "type": "multiline",
          "valueKey": "contactAddress",
          "numberOfLines": 5
        },
        {
          "label": "Note",
          "placeholder": "Enter note",
          "type": "multiline",
          "valueKey": "note",
          "numberOfLines": 5
        }
      ]
    }
  },
  "assessments_dashboard": {
    "title": "Assessments",
    "resourceName": "assessments",
    "loadingText": "Assessments",
    "useResponseGrid": true,
    "disableButton": {
      "type": "dotValue",
      "source": "extension",
      "filterKey": "url",
      "filterVals": ["status_color", "status"],
      "resultKey": "valueString",
      "comparatorValues": ["expired", "revoked", "completed"],
      "buttonTitleView": "View",
      "buttonTitleFillNow": "Fill now"
    },
    "icon": "https://mpowered-assets.s3.us-east-2.amazonaws.com/services/assessments/assessment-web.svg",
    "dashboard": {
      "reloadOnScroll": true,
      "selfReporting": {
        "enabled": false,
        "deviceType": "all"
      },
      "search": {
        "isEnabled": true,
        "placeholder": "Search by assessment name",
        "requestKey": "search_parameter",
        "deviceType": "all",
        "width": "300",
        "addDebounce": true
      },
      "filters": [
        {
          "label": "Assigned date :",
          "defaultActiveIndex": 0,
          "datePickerEnabled": false,
          "filterType": "dateFilter",
          "requestKey": "dateFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on time:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Past 1 month",
              "value": "1M"
            },
            {
              "label": "Past 3 months",
              "value": "3M"
            },
            {
              "label": "Past 6 months",
              "value": "6M"
            }
          ]
        },
        {
          "label": "Status :",
          "defaultActiveIndex": 0,
          "filterType": "statusFilter",
          "requestKey": "statusFilter",
          "deviceType": "all",
          "modalTitle": "Filter based on status:",
          "options": [
            {
              "label": "All",
              "value": "all"
            },
            {
              "label": "Completed",
              "value": "completed",
              "iconColor": "#007D32"
            },
            {
              "label": "In progress",
              "value": "in-progress",
              "iconColor": "#036FCB"
            },
            {
              "label": "Pending",
              "value": "pending",
              "iconColor": "#F2994A",
              "mappingList": ["DRAFT"]
            },
            {
              "label": "Expired",
              "value": "expired",
              "iconColor": "#DB0032"
            }
          ]
        }
      ],
      "tableColumns": [
        {
          "headerName": "Assigned date",
          "type": "string",
          "dataCol": "assessmentResponse.assessmentDisplay.assignedDate"
        },
        {
          "headerName": "Name",
          "type": "string",
          "dataCol": "assessmentResponse.assessmentMeta.assessmentName"
        },
        {
          "headerName": "Question count",
          "type": "string",
          "dataCol": "assessmentResponse.assessmentMeta.numberOfQuestions"
        },
        {
          "headerName": "Expiry date",
          "type": "string",
          "dataCol": "assessmentResponse.assessmentDisplay.expiredDate"
        },
        {
          "headerName": "Status",
          "type": "dotValue",
          "source": "extension",
          "filterKey": "url",
          "filterVals": ["status_color", "status"],
          "resultKey": "valueString"
        },
        {
          "headerName": "Organization name",
          "type": "pillString",
          "source": "extension",
          "filterKey": "url",
          "filterVal": "orgName",
          "resultKey": "valueString"
        },
        {
          "headerName": "",
          "type": "button",
          "dataCol": ""
        }
      ]
    },
    "details": {
      "show": true,
      "header": "Assessment details",
      "loadingText": "Assessment",
      "categoryNameForMarkAsRestricted": "",
      "detailsTable": {
        "header": {
          "template": "",
          "key1": "",
          "key2": ""
        },
        "cols": [],
        "options": {
          "markRestricted": false,
          "isHistoricalCardEnable": false
        }
      }
    },
    "historicalRecordsCardFields": {
      "titleSource": "",
      "categoryName": "",
      "cardFields": []
    },
    "selfReportingSection": {
      "resourceType": "assessments",
      "resourceNameForSelfReportingFileUpload": "",
      "steps": [],
      "formElementList": []
    }
  }
}
