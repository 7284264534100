import BaseApiService from './BaseApiService';

function _createOrg(payload, otherHeaders) {
  return BaseApiService.post(
    `/unauth/organization/signup`,
    null,
    payload,
    true,
    null,
    otherHeaders
  );
}

function _getAllSolutions() {
  return BaseApiService.get(`/unauth/solutions/all`);
}

function _editSolutions(orgId, payload) {
  return BaseApiService.put(`/solutions/${orgId}`, null, payload);
}

function _checkEINandDB(dbValue,ein,orgName) {
  return BaseApiService.get(`/unauth/organization/check-if-exits?db=${dbValue}&ein=${ein}&name=${orgName}`);
}

function _checkValidSession(session){
  return BaseApiService.get(`/unauth/signup/v2/session/validate?session=${session}`);
}
function _updatePassword(payload,session){
  return BaseApiService.post(`/unauth/signup/v2/password/update?session=${session}`,null,payload);
}

const SignUp = {
  createOrg: _createOrg,
  getAllSolutions: _getAllSolutions,
  checkEINandDB: _checkEINandDB,
  editSolutions: _editSolutions,
  updatePassword: _updatePassword,
  checkValidSession: _checkValidSession,
};

export default SignUp;
