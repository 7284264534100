import React, { useState, useEffect, useCallback } from 'react';
import { BackendSearchTextField, SimpleBackdrop } from 'components/widgets';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import FilterDropDown from 'components/FilterDropDown';
import NotFoundPage from 'components/NotFoundPage';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import {
  INITIAL_RENDER_USERS,
  StatusFilterMenuItems,
  USERS_EMPTY_STATE_PAGE,
} from 'shared/constants';
import ExternalUserGrid from './ExternalUserGrid';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import UsersDetailPage from './UsersDetailPage';
import RolesApi from 'services/api/RolesApiNew';
import { convertStrToTitleCase } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
}));

const ExternalUserPage = ({
  refetchExternalUsers,
  setRefetchExternalUsers,
  onAddUser,
  onEditUser,
  lobs,
  affiliates,
  readAccess,
  createAccess,
  isOrgAdmin,
}) => {
  const classes = useStyles();
  const orgId = sessionStorage.getItem('orgId');

  const [loading, setLoading] = useState(false);
  const [OnloadData, setOnloadData] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');

  const [affiliate, setAffiliate] = useState('all');
  const [role, setRole] = useState('all');
  const [lob, setLob] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');

  const [filteredExternalDevData, setFilteredExternalDevData] = useState([]);

  const [usersDetails, setUsersDetails] = useState({});
  const [flyout, setFlyout] = useState({ open: false });
  const [roleData, setRoleData] = useState([]);

  const formattedAffiliate = affiliates?.map(affiliate => {
    return { label: affiliate.name, value: affiliate.name, id: affiliate?.id };
  });
  const affiliateId = affiliates?.find(val => val.name === affiliate)?.id;
  const listOfAffiliate = [
    { label: 'All', value: 'all' },
    ...formattedAffiliate,
  ];

  const formattedLob = lobs?.map(lob => {
    return { label: lob.name, value: lob.name, id: lob?.id };
  });
  const lobId = lobs?.find(val => val.name === lob)?.id;

  const listOfLob = [{ label: 'All', value: 'all' }, ...formattedLob];

  const lobFilterOptions = {
    options: listOfLob,
    value: lob,
    changeHandler: ev => {
      setIsInitialRender(false);
      setLob(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const affiliateFilterOptions = {
    options: listOfAffiliate,
    value: affiliate,
    changeHandler: ev => {
      setIsInitialRender(false);
      setAffiliate(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  //for testing purpose use affiliate_id=368 and lob_id=120

  const finalFormatedListForGrid = filteredExternalDevData?.map(
    (listItem, idx) => {
      const affiliateIdsList = listItem?.affiliateIds;
      if (!affiliateIdsList?.length) return listItem;
      const affiliate = [];
      if (Array.isArray(affiliateIdsList)) {
        listOfAffiliate?.map((affiliateObj, idx) => {
          if (affiliateIdsList?.includes(String(affiliateObj?.id))) {
            affiliate.push(affiliateObj?.label);
          }
        });
      }
      listItem.affiliate = affiliate?.toString();
      return listItem;
    }
  );

  const formattedListofRole = roleData?.map(item => ({
    id: item.roleId,
    name: item.roleName,
  }));

  const formattedRole = formattedListofRole?.map(role => {
    return { label: convertStrToTitleCase(role.name), value: role.name };
  });

  const roleId = formattedListofRole?.find(val => val.name === role)?.id;

  const listOfRole = [{ label: 'All', value: 'all' }, ...formattedRole];

  const roleFilterOptions = {
    options: listOfRole,
    value: role,
    changeHandler: ev => {
      setIsInitialRender(false);
      setRole(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const onSearchClick = () => {
    setIsInitialRender(false);
    setSearchText(searchValue);
    offset !== 0 && setOffset(0);
  };

  const onChangeText = text => {
    if (!text?.length) {
      setSearchText('');
      setSearchValue('');
      return;
    }
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
  };

  const onViewClick = data => {
    setFlyout({ open: true });
    setUsersDetails(data);
  };

  const onToggle = () => {
    setFlyout({ open: false });
  };

  const onEditUsers = title => {
    setFlyout({ open: false });
    onEditUser({ usersDetails, title });
  };

  const statusFilterOptions = {
    options: StatusFilterMenuItems,
    value: statusFilter,
    changeHandler: ev => {
      setIsInitialRender(false);
      setStatusFilter(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const getAllRoles = async () => {
    await RolesApi.listRoles({ orgId, searchValue, offset: 0 })
      .then(res => {
        setRoleData(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAllRoles();
  }, [offset, searchText]);

  const getListOfUser = useCallback(async () => {
    setLoading(true);
    try {
      const results = await OrganizationUsersApi.getListOfUsers({
        orgId: orgId,
        search_text: searchText,
        searchAffId: affiliateId === 'all' ? '' : affiliateId ?? '',
        role_Id: roleId === 'all' ? '' : roleId ?? '',
        lob_Id: lobId === 'all' ? '' : lobId ?? '',
        signup_type: 'invite',
        is_external: true,
        offset: offset,
        status: statusFilter === 'all' ? '' : statusFilter ?? '',
        user_type: 'external',
      });
      let finalValue = results?.content
        ?.filter(value => value !== null)
        .map(filteredValue => {
          return filteredValue;
        });
      setFilteredExternalDevData(finalValue);
      setTotalCount(results?.totalElements);
      setOnloadData(true);
      setLoading(false);
      setRefetchExternalUsers(false);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);
      console.log('error: ', error);
    }
  }, [
    affiliateId,
    lobId,
    offset,
    roleId,
    searchText,
    statusFilter,
    refetchExternalUsers,
  ]);

  useEffect(() => {
    getListOfUser();
  }, [getListOfUser]);

  useEffect(() => {
    if (refetchExternalUsers) {
      getListOfUser();
    }
  }, [refetchExternalUsers, getListOfUser]);

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const handleInviteUser = () => {
    onAddUser();
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {(!isInitialRender || finalFormatedListForGrid?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    label="Search by user name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                  data-testid="search-rule"
                >
                  Search
                </SecondaryButton>
                <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                  <FilterDropDown
                    {...statusFilterOptions}
                    filterType="Status:"
                  />
                </div>
                <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                  <FilterDropDown
                    {...affiliateFilterOptions}
                    filterType="Affiliate:"
                  />
                </div>
                <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                  <FilterDropDown {...roleFilterOptions} filterType="Role:" />
                </div>
                {/* <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                  <FilterDropDown {...lobFilterOptions} filterType="LOB:" />
                </div> */}
              </div>

              {finalFormatedListForGrid?.length > 0 && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <ExternalUserGrid
                    apiLoading={loading}
                    rowData={finalFormatedListForGrid}
                    onView={onViewClick}
                    readAccess={readAccess}
                    isOrgAdmin={isOrgAdmin}
                  />
                  <PaginationComponent
                    numberOfRowsPerPage={10}
                    count={totalCount}
                    onPageClick={paginationClick}
                    page={offset}
                  />
                </Grid>
              )}
            </>
          )}
        </div>
      </Grid>
      <SimpleBackdrop open={loading} />

      {finalFormatedListForGrid?.length === 0 && isInitialRender && OnloadData && (
        <Box style={{ marginTop: '20px' }}>
          <NotFoundPage
            title={INITIAL_RENDER_USERS.TITLE}
            titleContent={INITIAL_RENDER_USERS.TITLE_CONTENT}
            content={INITIAL_RENDER_USERS.CONTENT}
            showButton={createAccess}
            onNotFoundClick={handleInviteUser}
          />
        </Box>
      )}

      {finalFormatedListForGrid?.length === 0 &&
        !isInitialRender &&
        OnloadData && (
          <Box style={{ marginTop: '20px' }}>
            <NotFoundPage
              title={USERS_EMPTY_STATE_PAGE.TITLE}
              content={USERS_EMPTY_STATE_PAGE.CONTENT}
              showButton={false}
            />
          </Box>
        )}
      <SimpleBackdrop open={loading} />
      {flyout.open && (
        <UsersDetailPage
          pageHeading="User details"
          onToggle={onToggle}
          loading={loading}
          usersDetails={usersDetails}
          getListOfUser={getListOfUser}
          setFlyout={setFlyout}
          onEditUsers={onEditUsers}
          lobs={lobs}
          affiliates={affiliates}
          {...flyout}
        />
      )}
    </>
  );
};

export default ExternalUserPage;
