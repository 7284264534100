import { useCallback, useEffect, useRef } from 'react';

export default function useInfiniteScroll(onPageNumberClick) {
  const observer = useRef();
  const lastElement = useCallback(node => {
    if (node) {
      observer.current.observe(node);
    }
  }, []);

  const options = {
    rootMargin: '0px',
    threshold: 0.2,
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      const { intersectionRatio, target } = entries[0];
      if (intersectionRatio >= 0.2 && target) {
        onPageNumberClick();
      }
    }, options);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [lastElement]);

  return { lastElement };
}
