import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PrimaryButton, SecondaryButton } from './widgets';
import clsx from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  footer: {
    textAlign: 'center',
    minHeight: '80px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 30px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#344054',
  },
  dialogPaper: {
    minHeight: '85vh',
    maxHeight: '85vh',
    borderRadius: '24px',
    minWidth: '60vw',
    maxWidth: '60vw',
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: '10px',
    borderTop: 'none',
    marginRight: '10px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '10px 20px',
  },
}))(MuiDialogActions);

const DialogTitle = props => {
  const { children, onClose } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2" style={{ fontSize: '24px', fontWeight: 600 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const CustomizedDialog = ({
  open,
  handleClickClose,
  title,
  handleOnSave,
  primaryBtnDisabled,
  primaryBtnTitle = 'Save',
  secondaryBtnTitle = 'Cancel',
  customStyles,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: clsx(classes.dialogPaper, customStyles) }}
      onClose={handleClickClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Box style={{ padding: '10px', flex: 1 }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClickClose}>
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Box>
      <DialogActions className={classes.footer}>
        <SecondaryButton
          onClick={handleClickClose}
          data-testid="customized-popup-cancel-btn"
        >
          {secondaryBtnTitle}
        </SecondaryButton>
        <PrimaryButton
          onClick={handleOnSave}
          disabled={primaryBtnDisabled}
          data-testid="customized-popup-primary-btn"
        >
          {primaryBtnTitle}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomizedDialog;
