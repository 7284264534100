import { Grid, makeStyles } from '@material-ui/core';
import { ReadonlyField, Card } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  root: {},
  layoutContainer: {},
}));

const OrgAffiliateName = ({ orgName, affiliateName }) => {
  const classes = useStyles();
  return (
    <Card className={classes.layoutContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ReadonlyField
            label="Organization"
            value={orgName}
            isValueBold={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadonlyField
            label="Affiliate"
            value={affiliateName}
            isValueBold={true}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default OrgAffiliateName;
