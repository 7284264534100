import BaseApiService from './BaseApiService';

function _listSolution({ orgId }) {
  return BaseApiService.get(`/${orgId}/solutions`);
}

function _requestSolutionAccess({orgId, requestedSolutionId}) {
  return BaseApiService.put(`/${orgId}/${requestedSolutionId}/requestAccess`, null, {})
}

const SolutionApi = {
  listSolution: _listSolution,
  requestSolutionAccess: _requestSolutionAccess
};

export default SolutionApi;
