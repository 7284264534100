import React, { useEffect, useState } from 'react';
import CampaignsTab from './CampaignsTab';
import { useSelector } from 'react-redux';

const CampaignsPage = () => {
  const TabHeading = ['Cohorts', 'Campaigns'];
  const { solutionPermissions } = useSelector(state => state.userInfo);
  const [campaignPermissons, setCampaignPermissions] = useState();

  useEffect(()=>{
    const selectedSolution = solutionPermissions?.get(
      localStorage.getItem('selectedSolutionId')
    );

    const campPermission = selectedSolution?.features?.find(
      feature => feature?.featureName === 'Campaigns'
    )?.subFeatures;

    setCampaignPermissions(campPermission || []);
  },[solutionPermissions])

  return (
    <div>
      <CampaignsTab
        TabHeading={TabHeading}
        campaignPermissons={campaignPermissons}
      />
    </div>
  );
};

export default CampaignsPage;
