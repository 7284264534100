import React, { useState, useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Grid,
  Button,
  FormControl,
} from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import JPGIcon from 'assets/consent/JPG.svg';
import CloseIcon from '@material-ui/icons/Close';
import { ORG_TYPE } from 'shared/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import {
  zipCode,
  einNumber,
  dbNumber,
  phoneNumber,
} from 'shared/validators';
import SignUp from 'services/api/SignUp';
import NumberFormat from 'react-number-format';
import Contactus from '../../assets/contactus.svg';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

const FIVE_MB_IN_BYTES = 5000000;
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 30px 30px',
    marginTop: '1%',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#FFFFFF',
    padding: '10px',
    borderRadius: '10px!important',
  },
  adminGrpContainer: {
    // backgroundColor: '#E4E7EC',
    // border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '20px',
    // padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    // padding: '0px 16px',
    width: '100%',
  },
  adminSummaryContainer: {
    // backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
  },
  adminGrpSummaryContainer: {
    // display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  formTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#344054',
  },
  GridMaxWidth: {
    maxWidth: '32%',
  },
  mandatory: {
    color: 'red',
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    border: '1px dashed #8C8C8C',
    borderRadius: '8px',
    minHeight: '144px',
    textAlign: 'center',
    padding: '15px',
  },
  detailstitle: {
    color: '#798294',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  detailsValue: {
    color: '#344054',
  },
  detailsContainer: {
    backgroundColor: '#FFFFFF',
    // minHeight: '352px',
    // width: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    flexDirection: 'column',
    padding: '0px 0px 30px 0px',
    gap: '20px',
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 0,
    alignItems: 'center',
    alignSelf: 'self-end',
    // marginBottom: theme.spacing(2),
  },
  dialogContainer: {
    borderRadius: 24,
  },
}));

function MaskedTextField(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={
        props.id === 'ein'
          ? '##-#######'
          : props.id === 'businessContact' || props.id === 'fax'
          ? '+1 (###) ###-####'
          : props.id === 'dbValue'
          ? '##-###-####'
          : ''
      }
      mask="_"
    />
  );
}

export default function OrganisationDetails({
  signUpValues,
  setSavedOrg,
  setEditOrg,
  tabStatus,
  setSignUp,
  setOrgLogo,
  logoDetails
}) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [orgType, setOrgType] = useState(signUpValues?.organization?.typeCode || '');
  const [orgName, setOrgName] = useState(
    signUpValues?.organization?.name || ''
  );
  const [dbValue, setdbValue] = useState(signUpValues?.organization?.db || '');
  const [ein, setein] = useState(signUpValues?.organization?.ein || '');
  const [contact, setContact] = useState(
    signUpValues?.organization?.addresses?.[0]?.contacts?.[0]?.phone || ''
  );
  const [fax, setFax] = useState(
    signUpValues?.organization?.addresses?.[0]?.contacts?.[0]?.fax || ''
  );
  const [address, setAddress] = useState(signUpValues?.organization?.addresses[0].type || '');
  const [aptSuite, setAptSuit] = useState(signUpValues?.organization?.addresses?.[0]?.line1 || '');
  const [street, setStreet] = useState(signUpValues.organization.addresses[0].line2 || '');
  const [city, setCity] = useState(signUpValues.organization.addresses[0].city || '');
  const [stateValue, setStateValue] = useState(signUpValues?.organization?.addresses[0]?.state || '');
  const [zip, setZip] = useState(signUpValues?.organization?.addresses[0]?.zip ||'');
  const [expanded, setExpanded] = useState(
    signUpValues?.organization?.name ? false : true
  );
  const [logo, setLogo] = useState('');
  const [errors, setErrors] = useState("");
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [bulkUploadFile, setBulkUploadFile] = useState(logoDetails || []);
  const [checkEIN_DB, setCheckEIN_DB] = useState(true); //* This should be props
  const [saveOrgStatus, setSaveOrgStatus] = useState(
    signUpValues?.organization?.name ? tabStatus?.SAVED : tabStatus?.NOT_SAVED
  );
  
  const [orgGrpValue, setorgGrpValue] = useState([
    {
      organization: {
        name: signUpValues?.organization?.name || null,
        db: signUpValues?.organization?.db || null,
        ein: signUpValues?.organization?.ein || null,
        typeCode: signUpValues?.organization?.typeCode || null,
        addresses: [
          {
            city: signUpValues?.organization?.addresses?.[0]?.city || null,
            country: 'US',
            line1: signUpValues?.organization?.addresses?.[0]?.line1 || null,
            state: signUpValues?.organization?.addresses?.[0]?.state || null,
            type: signUpValues?.organization?.addresses?.[0]?.type || null,
            zip: signUpValues?.organization?.addresses?.[0]?.zip || null,
            contacts: [
              {
                email:
                  signUpValues?.organization?.addresses?.[0]?.contacts?.[0]
                    ?.email || null,
                fax:
                  signUpValues?.organization?.addresses?.[0]?.contacts?.[0]
                    ?.fax || null,
                phone:
                  signUpValues?.organization?.addresses?.[0]?.contacts?.[0]
                    ?.phone || null,
              },
            ],
            line2: signUpValues?.organization?.addresses?.[0]?.line2 || null,
          },
        ],
        userId: null,
      },
    },
  ]);
  const { data: states } = useSelector(state => state.locationStates);
  const { data: addressTypes } = useSelector(state => state.addressTypes);
  const { enqueueSnackbar } = useSnackbar();
  
  const isActionSavedBefore = useRef(
    signUpValues?.organization?.name ? true : false
  );

  const handleTypeSelection = e => {
    handleActionStatus();
    // setlabel('');
    // setSelectedType(e.target.value);
  };

  const handleClearAction = () => {
    setOrgName('');
    setOrgType('');
    setdbValue('');
    setein('');
    setContact('');
    setFax('');
    setAddress('');
    setAptSuit('');
    setStreet('');
    setCity('');
    setStateValue('');
    setZip('');
    setBulkUploadFile([]);
    setExpanded(false);
    setEditOrg(false);
    setorgGrpValue([
      {
        organization: {
          name: null,
          db: null,
          ein: null,
          typeCode: null,
          addresses: [
            {
              city: null,
              country: 'US',
              line1: null,
              state: null,
              type: null,
              zip: null,
              contacts: [
                {
                  email: null,
                  fax: null,
                  phone: null,
                },
              ],
              line2: null,
            },
          ],
          userId: null,
        },
      },
    ]);
  };

  const handleActionStatus = () => {
    if (saveOrgStatus === tabStatus?.SAVED) {
      setSaveOrgStatus(tabStatus?.NOT_SAVED);
      setSavedOrg(false);
    }
  };

  const check_EIN_DB_Value = async () => {
    if (dbValue?.length >= 9 || ein?.length >= 9 || orgName) {
      try {
        let results = await SignUp.checkEINandDB(dbValue, ein,orgName);
        setCheckEIN_DB(true);
        setOpenDialog(false);
      } catch (err) {
        if (err?.response?.data?.details === 'Organization already exist') {
          setOpenDialog(true);
          setCheckEIN_DB(false);
          // enqueueSnackbar(
          //   'Organization with the same EIN or D&B value already exists',
          //   {
          //     variant: 'error',
          //     autoHideDuration: 1500,
          //   }
          // );
        }
      }
    }
  };

  const handleClose = () =>{
    setOpenDialog(false);
    setSaveOrgStatus(tabStatus?.NOT_SAVED);
    setSavedOrg(false);
    setEditOrg(true);
    setExpanded(prevValue => !prevValue);
  }

  const handleOnchange = val => {
    handleActionStatus();
    setEditOrg(true);
    let orgGrpVal = [
      {
        organization: {
          name: orgName || null,
          db: dbValue || null,
          ein: ein || null,
          typeCode: orgType || null,
          addresses: [
            {
              city: city || null,
              country: 'US',
              line1: aptSuite || null,
              state: stateValue || null,
              type: address || null,
              zip: zip || null,
              contacts: [
                {
                  email: null,
                  fax: fax || null,
                  phone: contact || null,
                },
              ],
              line2: street || null,
            },
          ],
          userId: null,
        },
      },
    ];
    setorgGrpValue(orgGrpVal);

    return orgGrpVal;
  };

  const handleEditAction = () => {
    setSaveOrgStatus(tabStatus?.NOT_SAVED);
    setSavedOrg(false);
    setEditOrg(true);
    setExpanded(prevValue => !prevValue);
  };

  const closeWithoutEdit = () => {
    check_EIN_DB_Value();
    setSaveOrgStatus(tabStatus?.SAVED);
    setSavedOrg(true);
    setEditOrg(false);
    setExpanded(prevValue => !prevValue);
    setorgGrpValue([
      {
        organization: {
          name: signUpValues?.organization?.name,
          db: signUpValues?.organization?.db,
          ein: signUpValues?.organization?.ein,
          typeCode: signUpValues?.organization?.typeCode,
          addresses: [
            {
              city: signUpValues?.organization?.addresses?.[0]?.city,
              country: 'US',
              line1: signUpValues?.organization?.addresses?.[0]?.line1,
              state: signUpValues?.organization?.addresses?.[0]?.state,
              type: signUpValues?.organization?.addresses?.[0]?.type,
              zip: signUpValues?.organization?.addresses?.[0]?.zip,
              contacts: [
                {
                  email:
                    signUpValues?.organization?.addresses?.[0]?.contacts?.[0]
                      ?.email,
                  fax: signUpValues?.organization?.addresses?.[0]?.contacts?.[0]
                    ?.fax,
                  phone:
                    signUpValues?.organization?.addresses?.[0]?.contacts?.[0]
                      ?.phone,
                },
              ],
              line2: signUpValues?.organization?.addresses?.[0]?.line2,
            },
          ],
          userId: null,
        },
      },
    ]);
  };

  const handleOrgValidations = () => {
    if (
      !orgName &&
      !orgType &&
      !dbValue &&
      !ein &&
      !contact &&
      !fax &&
      !address &&
      !aptSuite &&
      !street &&
      !city &&
      !stateValue &&
      !zip
    ) {
      enqueueSnackbar('Please enter value', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!orgName) {
      enqueueSnackbar('Please enter organization name', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!orgType) {
      enqueueSnackbar('Please enter organization type', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!dbValue) {
      enqueueSnackbar('Please enter D&B', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!ein) {
      enqueueSnackbar('Please enter EIN', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!contact) {
      enqueueSnackbar('Please enter bussiness contact', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
    else if (!fax) {
      enqueueSnackbar('Please enter fax number', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
    else if (!address) {
      enqueueSnackbar('Please enter address type', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
    else if (!aptSuite) {
      enqueueSnackbar('Please enter apt/suite', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
    else if (!street) {
      enqueueSnackbar('Please enter street address', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!city) {
      enqueueSnackbar('Please enter city', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!stateValue) {
      enqueueSnackbar('Please enter state', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!zip) {
      enqueueSnackbar('Please enter zip', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } 
    // else if(!checkEIN_DB)
    // {
    //   enqueueSnackbar('Organization with the same EIN or D&B value already exists', {
    //     variant: 'error',
    //     autoHideDuration: 1500,
    //   });
    // } 
    else {
      if (
        phoneNumber(contact) === 'Invalid number' ||
        phoneNumber(fax) === 'Invalid number' ||
        einNumber(ein) === 'Invalid EIN' ||
        dbNumber(dbValue) === 'Invalid D&B' ||
        zipCode(zip) === 'Invalid zip code'
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const checkValidation = (value, type) => {
    if (type === 'zip') {
      return zipCode(value);
      setZip(value);
    }
    if (type === 'ein') {
      return einNumber(value);
    }
    if (type === 'db') {
      return dbNumber(value);
    }
    if (type === 'contact' || type === 'fax') {
      return phoneNumber(value);
    }

    handleOnchange(value);
  };

  const redirectPage = () => {
    window.location.href = 'https://www.mpoweredhealth.com/contact-us';
  };

  const handleSaveOrg = async () => {
    if (!handleOrgValidations()) return;
    check_EIN_DB_Value()
    setLoading(true);
    setSaveOrgStatus(tabStatus?.SAVING);
    let updatedOrgGrpValue = handleOnchange();

    let payload = {
      ...signUpValues,
      organization: updatedOrgGrpValue?.[0]?.organization,
    };

    setOrgLogo(bulkUploadFile[0]);
    setSignUp(payload);
    setExpanded(false);
    setLoading(false);
    isActionSavedBefore.current = true;
    setSaveOrgStatus(tabStatus?.SAVED);
    setSavedOrg(true);
    setEditOrg(false);
  };

  const savedStatusStatus = useMemo(() => {
    if (saveOrgStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
        />
      );
    } else if (saveOrgStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
        />
      );
    } else if (saveOrgStatus === tabStatus?.REJECTED) {
      return <HighlightOffIcon style={{ color: 'red', fontSize: '22px' }} />;
    } else {
      return (
        <CheckCircleOutlineIcon style={{ color: 'grey', fontSize: '22px' }} />
      );
    }
  }, [saveOrgStatus]);

  const Dropzone = ({ myFiles, setMyFiles }) => {
    // const onDrop = acceptedFiles => {
    //   setMyFiles(acceptedFiles);
    // };

    const onDrop = (acceptedFiles, fileRejections) => {
      setErrors("");
      setMyFiles(acceptedFiles);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrors(`File size is more than 2MB`);
          }

          if (err.code === "file-invalid-type") {
            setErrors(`${err.message}`);
          }
        });
      });
    }


    const { getRootProps, getInputProps, open } = useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: '.png,.jpg,.jpeg',
      onDrop,
      maxFiles: 1,
      maxSize: FIVE_MB_IN_BYTES, //Max Size allowed is 5MB
    });

    const files = myFiles?.length > 0 && myFiles?.map(file => {
      let BYTES_TO_MB = 0.000001;
      let sizeInMB = file.size * BYTES_TO_MB;
      setFileName(
        file.name +
          ' ' +
          (sizeInMB > 0.1
            ? `(${sizeInMB.toFixed(2)} MB)`
            : `(${file.size} bytes)`)
      );
      return (
        <>
        {file?.name &&
        <Box
          display={'flex'}
          justifyContent="center"
          alignItems={'center'}
          style={{ gap: '10px' }}>
          <img src={JPGIcon} alt="csv-icon" />
          <Typography
            key={file.path}
            style={{ fontWeight: 400, fontSize: '14px', color: '#101828' }}>
            {file.name} (
            {sizeInMB > 0.1
              ? `${sizeInMB.toFixed(2)} MB`
              : `${file.size} bytes`}
            )
          </Typography>
        </Box>
            }
            </>
      );
    });
    const classes = useStyles();
    const remove = () => {
      setMyFiles([]);
    };
    return (
      <Box>
        <Box className={classes.dropzoneContainer}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} data-testid="bulk-upload-file-upload" />

            <Typography
              style={{
                fontWeight: 400,
                fontSize: '13px',
                color: '#667085',
                marginBottom: '10px',
              }}>
              JPG or PNG smaller than 2MB
            </Typography>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '16px',
                color: '#667085',
                marginBottom: '10px',
              }}>
              Drag and drop your organization logo or
            </Typography>
            <SecondaryButton onClick={open}>Choose file</SecondaryButton>
          </div>
          <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>
          {errors}
        </p>
        </Box>
        {myFiles?.length > 0 && (
          <Box
            style={{
              border: '1px solid #8C8C8C',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              padding: '10px',
              alignItems: 'center',
            }}
            data-testid="list-uploaded-file">
            {files}
            <CloseIcon
              style={{ fontSize: '22px', cursor: 'pointer' }}
              onClick={remove}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.containerBox}
        expanded={expanded}
        // onChange={() => setExpanded(prevValue => !prevValue)}
        data-testid="action-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {savedStatusStatus}
              <Typography
                data-testid="action-title"
                className={classes.heading}>
                Organization details
              </Typography>
              {!expanded && (
                <Box style={{ marginLeft: 'auto' }}>
                  {isActionSavedBefore.current ? (
                    <SecondaryButton onClick={handleEditAction}>
                      Edit
                    </SecondaryButton>
                  ) : (
                    <SecondaryButton
                      onClick={() => setExpanded(prevValue => !prevValue)}>
                      Add
                    </SecondaryButton>
                  )}
                </Box>
              )}
            </Box>
            {!isActionSavedBefore.current && (
              <span style={{ marginLeft: '34px', fontSize: '14px' }}>
                Add all information about your organization and upload the
                documents
              </span>
            )}
            {saveOrgStatus === 'SAVED' && !expanded && (
              <Box className={classes.adminGrpSummaryContainer}>
                {orgGrpValue?.map(val => {
                  return (
                    <>
                      <Grid container>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Organization name
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>{val?.organization?.name || '-'}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Organization type
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                  {ORG_TYPE.map(i=>{
                                    if(i.code === val?.organization?.typeCode)
                                    {
                                       return <b>{i.name || '-'}</b>
                                    }
                                  })}
                                
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Organization logo
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>{fileName || '-'}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ rowGap: '20px', marginTop: '20px' }}>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                D&B
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>{val?.organization?.db || '-'}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                EIN
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>{val?.organization?.ein || '-'}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Business contact
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]
                                    ?.contacts?.[0]?.phone || '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Fax number
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]
                                    ?.contacts?.[0]?.fax || '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Address type
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]?.type ||
                                    '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Apt/Suite
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]?.line1 ||
                                    '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Street address
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]?.line2 ||
                                    '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                City
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]?.city ||
                                    '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                State
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                  {states?.map(i=>{
                                    if(i.code === val?.organization?.addresses?.[0]?.state)
                                    {
                                       return <b>{i.name || '-'}</b>
                                    }
                                  })}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Zip
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailsValue}>
                                <b>
                                  {val?.organization?.addresses?.[0]?.zip ||
                                    '-'}
                                </b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            <Box
              className={classes.adminGrpContainer}
              style={{ display: 'flex' }}>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Organization name{' '}
                  <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="orgName"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter organization name"
                  value={orgName}
                  onChange={e => {
                    setOrgName(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Organization type{' '}
                  <span className={classes.mandatory}> *</span>
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ maxWidth: '100%' }}>
                  <Select
                    style={{
                      backgroundColor: 'white',
                      color: orgType === '' ? '#667085' : 'initial',
                    }}
                    value={orgType}
                    onChange={e => {
                      setOrgType(e.target.value);
                      handleOnchange(e.target.value);
                    }}
                    className={classes.formTextField}
                    margin="dense"
                    placeholder="Select organization type"
                    IconComponent={ExpandMoreIcon}
                    defaultValue=""
                    displayEmpty>
                    <MenuItem disabled value="">
                      Select organization type
                    </MenuItem>
                    {ORG_TYPE.map(i => {
                      return (
                        <MenuItem key={i.code} value={i.code}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  D&B <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="dbValue"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter D&B"
                  value={dbValue}
                  onChange={e => {
                    setdbValue(e.target.value);
                    // check_EIN_DB_Value("DB",e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  InputProps={{
                    inputComponent: MaskedTextField,
                  }}
                  helperText={checkValidation(dbValue, 'db')}
                  error={checkValidation(dbValue, 'db')}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  EIN <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="ein"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter EIN"
                  value={ein}
                  onChange={e => {
                    setein(e.target.value);
                    // check_EIN_DB_Value("EIN",e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  InputProps={{
                    inputComponent: MaskedTextField,
                  }}
                  helperText={checkValidation(ein, 'ein')}
                  error={checkValidation(ein, 'ein')}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Business contact <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="businessContact"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter business contact"
                  value={contact}
                  onChange={e => {
                    setContact(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  InputProps={{
                    inputComponent: MaskedTextField,
                  }}
                  helperText={checkValidation(contact, 'contact')}
                  error={checkValidation(contact, 'contact')}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Fax number <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="fax"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter fax number"
                  value={fax}
                  onChange={e => {
                    setFax(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  InputProps={{
                    inputComponent: MaskedTextField,
                  }}
                  helperText={checkValidation(fax, 'fax')}
                  error={checkValidation(fax, 'fax')}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Address type <span className={classes.mandatory}> *</span>
                </Typography>

                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ maxWidth: '100%' }}>
                  <Select
                    style={{
                      backgroundColor: 'white',
                      color: address === '' ? '#667085' : 'initial',
                    }}
                    value={address}
                    onChange={e => {
                      setAddress(e.target.value);
                      handleOnchange(e.target.value);
                    }}
                    className={classes.formTextField}
                    margin="dense"
                    placeholder="Select type"
                    IconComponent={ExpandMoreIcon}
                    defaultValue=""
                    displayEmpty>
                    <MenuItem disabled value="">
                      Select address type
                    </MenuItem>
                    {addressTypes?.map(i => {
                      return (
                        <MenuItem key={i.code} value={i.code}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>Apt/suite <span className={classes.mandatory}> *</span></Typography>
                <TextField
                  required
                  id="aptSuite"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter apartment/suite"
                  value={aptSuite}
                  onChange={e => {
                    setAptSuit(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Street address <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="street"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter street address"
                  value={street}
                  onChange={e => {
                    setStreet(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  City <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="city"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter city"
                  value={city}
                  onChange={e => {
                    setCity(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  State <span className={classes.mandatory}> *</span>
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ maxWidth: '100%' }}>
                  <Select
                    style={{
                      backgroundColor: 'white',
                      color: stateValue === '' ? '#667085' : 'initial',
                    }}
                    value={stateValue}
                    onChange={e => {
                      setStateValue(e.target.value);
                      handleOnchange(e.target.value);
                    }}
                    className={classes.formTextField}
                    margin="dense"
                    placeholder="Select state"
                    IconComponent={ExpandMoreIcon}
                    defaultValue=""
                    displayEmpty>
                    <MenuItem disabled value="">
                      Select state
                    </MenuItem>
                    {states?.map(i => {
                      return (
                        <MenuItem key={i.code} value={i.code}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Zip <span className={classes.mandatory}> *</span>
                </Typography>

                <TextField
                  required
                  id="zip"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter zip"
                  value={zip}
                  onChange={e => {
                    setZip(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  helperText={checkValidation(zip, 'zip')}
                  error={checkValidation(zip, 'zip')}
                  autoComplete="off"
                
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography className={classes.formTitle}>
                  Organization logo
                </Typography>
                <Dropzone
                  myFiles={bulkUploadFile}
                  setMyFiles={setBulkUploadFile}
                />
              </Grid>
            </Box>

            <Box style={{ margin: '10px 0px' }}>
              <Box style={{ margin: '20px 0px', display: 'flex', gap: '10px' }}>
                <SecondaryButton
                  onClick={
                    isActionSavedBefore.current
                      ? closeWithoutEdit
                      : handleClearAction
                  }
                  disabled={
                    saveOrgStatus === tabStatus?.SAVING ||
                    saveOrgStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    saveOrgStatus === tabStatus?.SAVING ||
                    saveOrgStatus === tabStatus?.SAVED
                  }
                  onClick={handleSaveOrg}
                  data-testid="save-criteria">
                  {saveOrgStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>

          {openDialog && (
       <Dialog
       open={openDialog}
       fullWidth
       maxWidth="xs"
       style={{borderRadius : "24px"}}
       className={classes.dialogContainer}>
       <div className={classes.closeContainer}>
        { " " }
          <IconButton >
           <CloseIcon onClick={handleClose}/>
         </IconButton>
       </div>
       <Box className={classes.contactContainer}>
      <Box className={classes.detailsContainer}>
        <img src={Contactus} alt="contact-logo" />
        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
        Contact support
        </Typography>
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: 400,
            color: '#667085',
            lineHeight: '20px',
            textAlign: 'center',
          }}>
          This organization details already exists. (Please check for organization name, D&B or EIN)
        </Typography>
        <Button
          variant="text"
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={redirectPage}>
          Go to support
        </Button>
      </Box>
    </Box>
     </Dialog>
      )}

        </AccordionDetails>
      </Accordion>
      <SimpleBackdrop open={loading} />
    </div>
  );
}
