import React, { useEffect, useState } from 'react';
import { Button, makeStyles, Typography, Card } from '@material-ui/core';
import { NO_ACCESS_CARD } from '../../../shared/constants';
import NoteBook from '../../../assets/consent/Notebook.svg';
import ConsentsGrid from './ConsentsGrid';
import ConsentTrigger from '../ConsentForm/ConsentTrigger';
import ConsentFilter from '../ConsentFilter';
import { useSelector } from 'react-redux';
import * as actions from 'store/actions';
import { useDispatch } from 'react-redux';
import ConsentInfoModal from '../ConsentInfoModal';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f5',
    height: 'calc(100% - 78px)',
    padding: theme.spacing(4),
  },
  avatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),

    marginRight: theme.spacing(2),
  },
  profileContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    alignItems: 'center',
  },
  userName: {
    marginRight: theme.spacing(2),
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: theme.spacing(3.5),
    lineHeight: '40px',
    color: '#1b1b1b',
  },
  btn: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    border: '1px solid #D0D0D0',
    '& .MuiButton-label': {
      color: '#036FCB',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      paddingLeft: 24,
      paddingRight: 24,
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  card: {
    border: 'none',
    marginTop: theme.spacing(5),
    padding: theme.spacing(6),
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: 50,
  },
  img: {
    height: 80,
    width: 80,
    marginBottom: theme.spacing(2),
  },
  initialConsent: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: theme.spacing(4),
  },
  initiateConsentButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const NoAccessCard = ({
  member,
  setMember,
  consentViewDetails,
  query,
  searchValue,
  dateFilterOptions,
  getAllMemberConsents,
  hasInitiateConsentPermission
}) => {
  const classes = useStyles();
  const { HEADING, CONTENT, BTN_TEXT } = NO_ACCESS_CARD;
  const [drawer, setDrawer] = useState(false);
  const { memberData } = useSelector(state => state.members);
  const [showConsentInfoModal,setShowConsentInfoModal] = useState(false);
  const [messageValue,setMessageValue]= useState("");
  const [messageVariant,setMessageVariant]= useState("");
  const {memberId} = useParams();
  const orgId = sessionStorage.getItem("orgId");
  const [dataFlag, setDataFlag] = useState(false);
  const [confirmationCount,setconfirmationCount]=useState(1);
  
  const enterDDUI = () => {
    setDrawer(true);
  };
  const exitDDUI = () => {
    setDrawer(false);
    
  };

  function isJsonString(item) {
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === 'object' && item !== null) {
      return true;
    }
    return false;
  }
  
  useEffect(() => {
    function handleMessage(event) {
      if (isJsonString(event.data)) {
        const messageObject = JSON.parse(event.data);
        if (messageObject.flowTypeValue === 'DDUI') {
          exitDDUI();
          setMessageVariant(messageObject.variant);
          setMessageValue(messageObject.message);
          setShowConsentInfoModal(true);
          //Handle Error or Success message based on messageObject.variant
        }
      }
    }
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const memberData = JSON.parse(localStorage.getItem('memberData'));
    if (memberData) {
      dispatch(actions.saveMemberDetails(memberData));
    }
  }, [dispatch]);

  useEffect(() => {
    if(member.length > 0) {
      setDataFlag(true);
    }
  }, [member])

  const memberDetails = useSelector(state => {
    return state?.members;
  });
  const reloadPage = () => {
    window.location.reload()
  }
  
  return (
    <>
    {showConsentInfoModal && <ConsentInfoModal open={showConsentInfoModal} handleClose={()=>{setShowConsentInfoModal(false); setMessageValue("");setMessageVariant("");getAllMemberConsents(orgId,memberId)}} membersDetails={[memberData]} messageVariant={messageVariant} totalCount={confirmationCount} message={messageValue} type="individual"/> }
      {dataFlag ? (
        <>
          <ConsentFilter
            member={member}
            setMember={setMember}
            searchValue={searchValue}
            dateFilterOptions={dateFilterOptions}
            query={query}
          />

          <ConsentsGrid rowData={member} onView={consentViewDetails} />
        </>
      ) : (
        <Card>
          <div className={classes.cardContainer}>
            <img src={NoteBook} alt={'img-notebook'} className={classes.img} />
            <Typography
              style={{
                color: '#000000',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '24px',
              }}>
              {HEADING}
            </Typography>
            <Typography className={classes.initialConsent}>
              {CONTENT}
            </Typography>
            <PrimaryButton
              color="primary"
              variant="contained"
              className={classes.initiateConsentButton}
              onClick={enterDDUI}
              hasPermissions={hasInitiateConsentPermission}
              >
              {BTN_TEXT}
            </PrimaryButton>
          </div>
        </Card>
      )}
    {drawer && <ConsentTrigger data={memberData} exitDDUI={exitDDUI}/>}
    </>
  );
};

export default NoAccessCard;
