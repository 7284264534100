import { makeStyles, Grid, Box, Typography, CircularProgress } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import InsightChart from 'containers/APIOverview/InsightChart';
import { Card as CustomCard } from 'components/widgets';
import TelemetryApi from 'services/api/TelemetryApi';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useEffect, useCallback, useState } from 'react';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: theme.spacing(2),
  },
  card: {
    width: 345,
  },
  cardContent: {
    minHeight: 160,
  },
  btn: {
    textTransform: 'none',
  },
  listItem: {
    margin: '8px 0',
  },
  numIcons: {
    display: 'inline',
    padding: '4px',
    marginRight: '5px',
    borderRadius: '50%',
    background: '#ffa400',
  },
  apiCard: {
    marginTop: theme.spacing(2),
    minHeight: 240,
  },
  name: {
    fontWeight: '600',
    fontSize: 28,
    paddingLeft: theme.spacing(2),
  },
  circularProgress: {
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Insights({ history, orgId, affiliateId }) {
  const { approved } = useSelector(state => state.applications);

  const classes = useStyles();
  const [data, setData] = useState();
  const [value, setValue] = useState('DAY');
  const [app, setApp] = useState('All');

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleAppChange = event => {
    setApp(event.target.value);
  }

  let insights = [];
  let totalApps = [];

  for(let i=0; i<approved.length; i++) {
    totalApps.push(approved[i].name);
  }

  const today = new Date();
  const startDate = new Date(
    today.getFullYear(),
    today.getMonth()-1,
    today.getDate()-7,
  )
    .toLocaleString('en-US')
    .split(',')
    .join('')
    .slice(0, -3);

  const appInsights = useCallback(() => {
    async function getResponse() {
      try {
        const details = await TelemetryApi.getInsights(
          orgId,
          affiliateId,
          value,
          app,
          startDate
        );
        setData(details);
      } catch (e) {
        console.log(e);
      }
    }
    getResponse();
  }, [affiliateId, orgId, value, app, startDate]);

  useEffect(() => appInsights(), [appInsights]);

  if(data) {
    for (let item in data) {
      insights.push([item*1000, data[item]]);
    }
  }

  return (
    <>
      <CustomCard className={classes.apiCard}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography component="b" variant="h4">
              API insights
            </Typography>
            <FormControl>
              <Select value={app} onChange={handleAppChange}>
                <MenuItem value="All">All apps</MenuItem>
                {totalApps.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select value={value} onChange={handleChange}>
                <MenuItem value={'DAY'}>Daily</MenuItem>
                <MenuItem value={'WEEK'}>Weekly</MenuItem>
                <MenuItem value={'MONTH'}>Monthly</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              {data ? (
                <InsightChart data={insights} timeInterval={value} />
              ) : (
                <div className={classes.circularProgress}>
                  <CircularProgress
                    color='secondary'
                    size={70}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </CustomCard>
    </>
  );
}

export default Insights;
