import { Typography, Paper, Box } from '@material-ui/core';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const OrgListDetail = ({ orgList = [] }) => {
  const classes = useStyles();

  const existingOrFuture = ['existing', 'future'];

  const orgFlagList = orgList.filter(item =>
    existingOrFuture.includes(item.id)
  );

  const orgIdList = orgList.filter(item => !existingOrFuture.includes(item.id));

  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box>
          <Typography
            style={{ marginBottom: '10px', fontWeight: 600 }}
            variant="h5">
            List of organizations
          </Typography>
          {orgFlagList.length !== 0 &&
            orgFlagList?.map(orgnization => (
              <Box key={orgnization?.id} style={{ display: 'flex'}}>
                <FormControlLabel
                  readyonly
                  control={<Checkbox checked name="checkedE" />}
                  label={orgnization?.name}
                />
              </Box>
            ))}
          {orgIdList.length !== 0 && (
            <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
              {orgIdList.map(val => {
                return (
                  <Box
                    style={{
                      borderRadius: '4px',
                      padding: '4px 8px',
                      backgroundColor: '#E4E7EC',
                      marginRight: '16px',
                      marginBottom: '8px',
                    }}>
                    <Typography style={{ fontWeight: 500 }}>
                      {val.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Paper>
    </DrawerBoxComponent>
  );
};

export default OrgListDetail;
