import React, { useState } from 'react';
import { Grid, InputBase } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { SEARCH_DEBOUNCE_TIME, SEARCH_HERE } from '../constants';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 12,
    backgroundColor: 'white',
    minWidth: '260px',
    borderRadius: 4,
    border: '1px solid #D0D0D0',
    height: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
    padding: '0px 8px',
    fontSize: '1rem',
    border: '1px solid #B7BDBF',
    width: '100%',
    border: 'none',
    borderRadius: 4,
    fontWeight: 400,
    fontFamily: 'Inter',
    height: '2.4rem',
    letterSpacing: '0px',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
}));

let searchTimer = undefined;

const SearchBox = (props) => {
  const {
    searchBoxRootClass,
    searchInputClass,
    handleSearch,
    search: { isEnabled = true, deviceType, placeholder = SEARCH_HERE, width, addDebounce = true },
  } = props;
  const classes = useStyles(); 


  const [inputValue, setInputValue] = useState();

  const onKeyDown = (event) => {
    const {
      target: { value },
      keyCode,
    } = event;
    if (keyCode === 13) {
      clearTimeout(searchTimer);
      handleSearch(inputValue);
    }
  };

  const onChange = (e) => {
    const val = e.target.value;
    setInputValue(val);

    if (addDebounce) {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      searchTimer = setTimeout(() => {
        handleSearch(val);
      }, SEARCH_DEBOUNCE_TIME);
    } else {
      handleSearch(val);
    }
  };

  return (
    isEnabled &&
    ['all', 'web'].includes(deviceType?.toLowerCase()) && (
      <Grid
        item
        className={[classes.root, searchBoxRootClass].join(' ')}
        style={{ width: `${width}px` }}
      >
        <InputBase
          style={{ width: `${width - 10}px` }}
          placeholder={placeholder}
          className={[classes.searchInput, searchInputClass].join(' ')}
          onChange={(e) => onChange(e)}
          onKeyDown={(e) => onKeyDown(e)}
          value={inputValue}
          startAdornment={
            <SearchIcon
            // fontSize="medium"
            />
          }
        />
      </Grid>
    )
  );
};

export default SearchBox;
