import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { PrimaryButton, SecondaryButton } from 'components/widgets';
import LogoPopup from 'components/widgets/FlyoutPanel/LogoPopup';
import ImageDropzone from 'containers/SetupOrganization/ImageDropzone';
import MPHLogo from '../../assets/mph.svg';
import * as actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RESOURCE_NAMES } from 'shared/constants';
import { useUserPermissions } from 'utils/userPermissions';
import ProfileMenu from './ProfileMenu';
import NavItems from './NavItems';
import AboutUserFlyout from 'layouts/DashboardLayout/AboutUserFlyout';
import { useSnackbar } from 'notistack';
import OrganizationApi from 'services/api/OrganizationApi';
import { SimpleBackdrop } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },

  menu: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: '14px',
  },
  popUpContainer: {
    width: 431,
    margin: 0,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  fileInputContainer: {
    width: 400,
    marginTop: 24,
  },

  buttonWrapper: {
    marginTop: 48,
  },

  //new
  appBar: {
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: '0px 0px 1px gray',
      // marginLeft: 75,
      // width: `calc(100% - 74px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      // marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  hiddenToolBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 74px)`,
    },
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  isDesktopNavMinified,
  history,
  onLogout,
  orgId,
  userLayoutType,
  affiliateId,
  ...rest
}) => {
  const classes = useStyles();
  const {
    checkDevPermission,
    checkITAdminPermission,
    checkSuperAdminPermission,
  } = useUserPermissions();
  const { info: userInfo } = useSelector(state => state.userInfo);
  const dispatch = useDispatch();
  const [notifications] = useState([]);
  const [showAboutUser, setShowAboutUser] = useState(false);
  const [hasCrudRights, setHasCrudRights] = useState(false);
  const [newLogo, setNewLogo] = useState();
  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isITAdmin = checkITAdminPermission();
  const isSuperAdmin = checkSuperAdminPermission();
  const orgIdFromSession = sessionStorage.getItem('orgId');
  const status = sessionStorage.getItem('status');

  const onToggle = () => {
    setShow(!show);
    setDisable(true);
  };

  const openPopup = () => {
    onToggle();
  };

  const handleButtonClick = props => {
    if (props) {
      setNewLogo(props);
      setDisable(false);
    }
  };

  const getOrgLogo = useCallback(() => {
    async function getLogo() {
      try {
        const logoUrl = await OrganizationApi.getLogo(orgIdFromSession);
        if (logoUrl) {
          setLogo(logoUrl);
          localStorage.setItem('orgLogo', logoUrl);
        } else {
          setLogo(MPHLogo);
        }
      } catch (e) {
        setLogo(MPHLogo);
      }
    }
    getLogo();
  }, [orgIdFromSession]);

  useEffect(() => {
    if (orgIdFromSession) getOrgLogo();
  }, [orgIdFromSession, getOrgLogo]);

  const updateLogo = async () => {
    const formData = new FormData();
    formData.append('org_img', newLogo);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      onToggle();
      setLoading(true);
      await OrganizationApi.editLogo(formData, orgIdFromSession, otherHeaders);
      setLoading(false);
      getOrgLogo();
      enqueueSnackbar('Organization logo updated', {
        variant: 'success',
      });
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while updating organization logo';
      onToggle();
      setLoading(false);
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (orgId && affiliateId) {
      const hasApplicationCrudRights = checkDevPermission(
        orgId,
        affiliateId,
        RESOURCE_NAMES.DEV_APPLICATION_CRUD
      );
      setHasCrudRights(hasApplicationCrudRights);
    }
  }, [orgId, affiliateId, checkDevPermission]);

  const onProfileMenuAction = action => {
    if (action === 'logout') {
      onLogout();
    } else if (action === 'profile') {
      setShowAboutUser(true);
    }
  };

  const onShowAlertsClick = () => {
    dispatch(actions.showManageAlerts(orgId));
  };

  return (
    <>
      <AppBar
        className={clsx(classes.root, className, {
          [classes.appBar]: orgId,
          [classes.appBarShift]: !isDesktopNavMinified && orgId,
        })}
        elevation={1}
        {...rest}
      >
        <Toolbar>
          {isSuperAdmin && window.location?.pathname === '/'
            ? null
            : logo &&
              (isITAdmin || isSuperAdmin ? (
                <Tooltip title="Update Organization logo" placement="top" arrow>
                  <img
                    style={{ height: 60, maxWidth: 180, cursor: 'pointer' }}
                    onClick={openPopup}
                    alt={'org-logo'}
                    // src={isDesktopNavMinified ? '/static/home.svg' : '/static/logo.svg'}
                    src={logo}
                  />
                </Tooltip>
              ) : (
                <img
                  style={{ height: 60, maxWidth: 180 }}
                  alt={'org-logo'}
                  src={logo}
                />
              ))}
          <Box flexGrow={1} />
          {(status === 'Approved' || isSuperAdmin) && (
            <NavItems history={history} />
          )}
          <Box flexGrow={1} />
          <IconButton
            style={{ marginRight: '16px' }}
            onClick={onShowAlertsClick}
          >
            <Badge badgeContent={notifications.length} color="error" showZero>
              <NotificationsNoneIcon />{' '}
            </Badge>
          </IconButton>
          <Avatar className={classes.avatar} src={userInfo?.profilePictureUrl}>
            {userInfo.firstName[0]}
            {userInfo.lastName[0]}
          </Avatar>

          <Typography style={{ marginLeft: '5px' }}>
            {userInfo.firstName}
          </Typography>
          <ProfileMenu onClick={onProfileMenuAction} />
          <AboutUserFlyout
            open={showAboutUser}
            hasCrudRights={hasCrudRights}
            onToggle={() => setShowAboutUser(false)}
            title="About User"
          />
        </Toolbar>
      </AppBar>
      <LogoPopup onClose={onToggle} open={show}>
        <div className={classes.popUpContainer}>
          <div className={classes.fileInputContainer}>
            <ImageDropzone handleButtonClick={handleButtonClick} />
          </div>
          <div className={classes.buttonWrapper}>
            {
              <React.Fragment>
                <SecondaryButton
                  onClick={onToggle}
                  size="large"
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  type="add"
                  disabled={disable}
                  onClick={updateLogo}
                >
                  Add
                </PrimaryButton>
              </React.Fragment>
            }
          </div>
        </div>
      </LogoPopup>

      <SimpleBackdrop open={loading} />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
