import { Typography, Box } from '@mui/material';
import CareDashboardTable from './CareDashboardTable';
import { useEffect, useState } from 'react';
import CareDashboardDetails from './CareDashboardDetails';
import { getCareTeam } from 'services/api/CareDashboardService';
import { SimpleBackdrop } from 'components/widgets';

export default function CareDashboard(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [openMemberDetails, setOpenMemberDetails] = useState(null);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function handleOpenViewDetails(memberDetails) {
    setOpenMemberDetails(memberDetails);
  }

  const orgId = props.match.params.orgId;

  useEffect(() => {
    if (props.match.params.orgId) {
      (async () => {
        setIsLoading(true);
        const result = await getCareTeam(props.match.params.orgId);
        setIsLoading(false);
        setMembers(result);
      })();
    }
  }, [props.match.params.orgId]);

  return (
    <Box sx={{ padding: '20px', paddingTop: '50px' }}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        Care dashboard
      </Typography>
      <CareDashboardTable
        rows={members}
        currentPage={currentPage}
        onPageChange={(e, page) => setCurrentPage(page)}
        openViewDetails={handleOpenViewDetails}
      />
      <CareDashboardDetails
        orgId={orgId}
        fhirReferenceId={openMemberDetails?.fhirReferenceId}
        open={openMemberDetails !== null}
        title={`${openMemberDetails?.firstName} ${openMemberDetails?.lastName} `}
        handleClose={() => setOpenMemberDetails(null)}
        keycloakUserId={openMemberDetails?.keycloakUserId}
      />
      <SimpleBackdrop open={isLoading} />
    </Box>
  );
}
