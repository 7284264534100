import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';

export const Tabs = withStyles(theme => ({
  root: {
    border: 'none',
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: '#f5f5f5',
    paddingLeft: theme.spacing(4),
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: 4,
  },
  flexContainer: {
    overflowX: 'auto',
  },
}))(MuiTabs);

export const Tab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: '#4B4B4B',
    opacity: 0.87,
    fontSize: 16,

    '&:hover': {
      //   color: '#4B4B4B',
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      opacity: 1,
    },
    '&:focus': {
      //   color: '#40a9ff',
    },
  },
  selected: {
    color: theme.palette.text.primary,
  },
}))(props => <MuiTab {...props} />);
