import React, { useState } from 'react';
import { SecondaryButton, PrimaryButton } from 'components/widgets';
import DocPopup from 'components/widgets/FlyoutPanel/DocPopup';
import DocumentDropzone from 'containers/SetupOrganization/DocumentDropzone';
import { SimpleBackdrop } from 'components/widgets';
import FilterDropDown from 'components/FilterDropDown';
import { useSnackbar } from 'notistack';

const MemberDocUploadPopUp = ({ open, onClose }) => {
  const [document, setDocument] = useState();
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [docCategoryValue, setDocCategoryValue] = useState('medical');
  const { enqueueSnackbar } = useSnackbar();

  const handleButtonClick = props => {
    if (props) {
      setDocument(props);
      setDisable(false);
    }
  };

  const handleAddDocument = () => {
    // Add document upload logic here
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      enqueueSnackbar('Document uploaded successfully', { variant: 'success' });
      onClose();
    }, 1000); // Simulating an API call with setTimeout
  };

  const uploadStatusFilterOptions = {
    options: [
      { label: 'Medical', value: 'medical' },
      { label: 'Financial', value: 'financial' },
      { label: 'Legal', value: 'legal' }
    ],
    value: docCategoryValue,
    changeHandler: ev => {
      setDocCategoryValue(ev.target.value);
    }
  };

  return (
    <DocPopup open={open} onClose={onClose} title="Upload document">
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '560px', marginTop: '24px' }}>
            <DocumentDropzone handleButtonClick={handleButtonClick} />
          </div>
          <div style={{ maxWidth: '250px' }}>
            <FilterDropDown
              {...uploadStatusFilterOptions}
              filterType="Select category: "
            />
          </div>
        </div>
        <div style={{ marginTop: '48px' }}>
          <SecondaryButton onClick={onClose} size="large" style={{ marginRight: 10 }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" type="add" disabled={disable} onClick={handleAddDocument}>
            Add
          </PrimaryButton>
        </div>
      </div>
      <SimpleBackdrop open={loading} />
    </DocPopup>
  );
};

export default MemberDocUploadPopUp;
