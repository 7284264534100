import PropTypes, { string } from 'prop-types';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';

import DatePicker from '../SelfReportingItems/DatePicker';
import TimePicker from '../SelfReportingItems/TimePicker';
import TextField from '../SelfReportingItems/TextField';
import FormSelect from '../SelfReportingItems/Dropdown';
import MultipleValueTextInput from '../SelfReportingItems/MultiInput';

import colors from '../colors';
import { SELF_REPORTING_LABELS, SELF_REPORTING_TEXTS } from '../constants';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  message: {
    color: colors.GRAY_FORD,
    fontSize: 12,
    marginTop: 2,
    marginLeft: 2,
    width: '100%',
  },
  successMsg: {
    color: colors.GREEN,
  },
  errorMsg: {
    color: colors.ERROR,
  },
  informationText: {
    color: colors.GRAY_FORD,
    fontSize: 12,
    marginTop: 2,
    marginLeft: 2,
    width: '95%',
    flexWrap: 'wrap',
  },
});

const { INPUT_TYPE } = SELF_REPORTING_TEXTS;

const FieldWrapper = (props) => {
  const {
    classes,
    label,
    value,
    placeholder,
    disabled,
    type,
    valueKey,
    maxLength,
    formatting,
    errorMsg,
    successMsg,
    dateformat,
    timeFormat,
    required,
    numberOfLines,
    maxFileSize,
    fileTypes,
    options,
    onChange,
    errored,
    suggestionList,
    showSearch,
    keyboardType,
    information,
    allowFutureDates,
    multiline,
  } = props;
  const findValue = (val, options) => {
    const res = options.find((option) => option.value === val);
    return res ? true : false;
  };
  const trueOrFalse = findValue(value, options);
  const [labResultOther, setLabResultOther] = useState(
    label === SELF_REPORTING_LABELS.LAB_RESULT_LABEL && (trueOrFalse || value === '')
      ? false
      : true,
  );
  const [labResultTextField, setLabResultTextField] = useState(
    label === SELF_REPORTING_LABELS.LAB_RESULT_LABEL && trueOrFalse ? '' : value,
  );
  const [showInfo, setShowInfo] = useState(false);

  const handleOnChange = (val, isAutoComplete = false) => {
    onChange(valueKey, (formatting && formatting(val)) || val);
  };

  const handleOnChangeLabResult = (val, isAutoComplete = false, label) => {
    let enteredValue = val;
    if (val === 'Others' && label === SELF_REPORTING_LABELS.LAB_RESULT_LABEL) {
      setLabResultOther(true);
      enteredValue = value;
    } else if (
      label === SELF_REPORTING_LABELS.LAB_RESULT_LABEL &&
      val !== 'Others' &&
      labResultOther
    ) {
      setLabResultOther(false);
      setLabResultTextField(value);
    }
    onChange(valueKey, (formatting && formatting(enteredValue)) || enteredValue);
  };

  const handleTextFieldChange = (val, isAutoComplete = false, label) => {
    setLabResultTextField(val);
    onChange(valueKey, (formatting && formatting(val)) || val);
  };

  const selectValue = (val) => {
    if (val === '') {
      return 'none';
    }
    if (trueOrFalse) {
      return val;
    } else {
      return 'Others';
    }
  };

  const onBlur = () => {
    setShowInfo(false);
  };

  const onFocus = () => {
    setShowInfo(true);
  };

  const renderInputBasedOnType = () => {
    switch (type) {
      case INPUT_TYPE.DROPDOWN: {
        return (
          <div>
            <FormSelect
              label={label}
              options={options}
              value={selectValue(value)}
              changeHandler={(val) => handleOnChangeLabResult(val, '', label)}
              placeholder={placeholder}
              valueKey={valueKey}
              disabled={disabled}
              showSearch={showSearch}
              required={required}
              error={errored}
            />
            {labResultOther && label === SELF_REPORTING_LABELS.LAB_RESULT_LABEL && (
              <TextField
                placeholder={SELF_REPORTING_LABELS.LAB_RESULT_PLACEHOLDER}
                value={labResultTextField}
                onChange={(val) => handleTextFieldChange(val)}
                editable={!disabled}
                required={required}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )}
          </div>
        );
      }
      case INPUT_TYPE.TEXT: {
        return (
          <TextField
            placeholder={placeholder}
            value={value}
            onChange={(val) => handleOnChange(val)}
            maxLength={maxLength || 200}
            editable={!disabled}
            numberOfLines={numberOfLines}
            label={label}
            required={required}
            onFocus={onFocus}
            onBlur={onBlur}
            error={errored}
            disabled={disabled}
          />
        );
      }
      case INPUT_TYPE.MULTIPLE_INPUT: {
        return (
          <div>
            <span className={classes.label}></span>
            <MultipleValueTextInput
              placeholder={placeholder}
              value={value}
              onChange={(val) => handleOnChange(val)}
              editable={!disabled}
              multiline={multiline}
              numberOfLines={numberOfLines}
              label={label}
              required={required}
              error={errored}
            />
          </div>
        );
      }
      case INPUT_TYPE.DATE: {
        return (
          <DatePicker
            value={value}
            changeHandler={(val) => handleOnChange(val)}
            placeholder={placeholder}
            valueKey={valueKey}
            disabled={disabled}
            format={dateformat}
            label={label}
            required={required}
            allowFutureDates={allowFutureDates}
            error={errored}
          />
        );
      }
      case INPUT_TYPE.TIME: {
        return (
          <TimePicker
            value={value}
            changeHandler={(val) => handleOnChange(val)}
            placeholder={placeholder}
            valueKey={valueKey}
            disabled={disabled}
            format={timeFormat}
            label={label}
            required={required}
            error={errored}
          />
        );
      }

      case INPUT_TYPE.MULTILINE: {
        return (
          <TextField
            placeholder={placeholder}
            value={value}
            onChange={(val) => handleOnChange(val)}
            maxLength={maxLength || 200}
            editable={!disabled}
            multiline={true}
            numberOfLines={numberOfLines}
            label={label}
            required={required}
            onFocus={onFocus}
            onBlur={onBlur}
            error={errored}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className={classes.root}>
      {/* {label ? <Typography text={convertToTitleCase(label)} style={styles.label} /> : null} */}
      {renderInputBasedOnType()}
      {successMsg || (errored && errorMsg) || (information && showInfo) ? (
        <span
          className={clsx(
            classes.message,
            successMsg
              ? classes.successMsg
              : errored && errorMsg
              ? classes.errorMsg
              : classes.informationText,
          )}
        >
          {successMsg || errorMsg || information}
        </span>
      ) : null}
    </div>
  );
};

FieldWrapper.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(INPUT_TYPE)),
  valueKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formatting: PropTypes.func,
  errored: PropTypes.bool,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  information: PropTypes.string,
  dateformat: PropTypes.string,
  timeFormat: PropTypes.string,
  numberOfLines: PropTypes.number,
  maxFileSize: PropTypes.number,
  fileTypes: PropTypes.arrayOf(string),
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  suggestionList: PropTypes.arrayOf(PropTypes.string),
  showSearch: PropTypes.bool,
  keyboardType: PropTypes.string,
  multiline: PropTypes.bool,
};

FieldWrapper.defaultProps = {
  label: null,
  value: null,
  placeholder: null,
  disabled: false,
  type: INPUT_TYPE.TEXT,
  formatting: null,
  errored: false,
  errorMsg: null,
  successMsg: null,
  information: null,
  dateformat: 'MM/dd/yyyy',
  timeFormat: 'HH:mm',
  numberOfLines: 2,
  maxFileSize: 5242880,
  // fileTypes: [DocumentPicker.types.images, DocumentPicker.types.pdf],
  maxLength: null,
  options: [],
  suggestionList: [],
  showSearch: false,
  keyboardType: 'default',
  multiline: false,
};

export default withStyles(styles, { withTheme: true })(FieldWrapper);
