import * as actionTypes from './actionTypes';
import OrganizationApi from 'services/api/OrganizationApi';

export const selectedOrgFetchSuccess = selectedOrg => {
  return {
    type: actionTypes.SELECTED_ORGANIZATION_FETCH_SUCCESS,
    selectedOrg,
  };
};

const selectedOrgFetchFailure = error => {
  return {
    type: actionTypes.SELECTED_ORGANIZATION_FETCH_FAILURE,
    error: error,
  };
};

const selectedOrgFetchStart = () => {
  return {
    type: actionTypes.SELECTED_ORGANIZATION_FETCH_START,
  };
};

export const fetchSelectedOrganization = orgId => {
  return dispatch => {
    dispatch(selectedOrgFetchStart());

    OrganizationApi.getOrganizationById(orgId)
      .then(response => {
        dispatch(selectedOrgFetchSuccess(response));
      })
      .catch(error => {
        dispatch(selectedOrgFetchFailure(error));
      });
  };
};
