import BaseApiService from "./BaseApiService";

const _getAssessment = ({ orgId, payload }) => {
    return BaseApiService.post(
      `/assessment/${orgId}/getAllAssessments`,null,payload
    );
  };

  const _getAssessmentDetails = ({ orgId,assessmentId }) => {
    return BaseApiService.get(`/assessment/${orgId}/${assessmentId}`)
  };
  
  export const AssessmentAPI = {
    getAllAssessment: _getAssessment,
    getAssessmentDetails:_getAssessmentDetails,
  };
  