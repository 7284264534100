import React from 'react';
import { Field } from 'react-final-form';
import { required, composeValidators } from 'shared/validators';
import TextAreaField from 'components/widgets/TextField/TextAreaField';

export default function FormTextAreaField({
  name,
  label,
  value,
  dataTestId,
  placeholder,
  customClass,
  maxLength,
  customInputFieldStyles,
  minRows,
  disabled
}) {
  return (
    <Field name={name} validate={composeValidators(required)}>
      {({ input, meta: { error, touched } }) => (
        <TextAreaField
          required
          label={label}
          value={value}
          dataTestId={dataTestId || ''}
          placeholder={placeholder}
          {...input}
          touched={touched}
          error={error}
          customClass={customClass}
          maxLength={maxLength}
          customInputFieldStyles={customInputFieldStyles}
          minRows={minRows}
          disabled={disabled}
        />
      )}
    </Field>
  );
}
