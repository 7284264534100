import React from 'react';
import {
  FlyoutPanel,
  FooterActions,
  FormTextField,
  FormEINField,
  FormDBField,
  SimpleBackdrop,
  FormEmailField,
  FormSelectField,
  FormZipField,
  FormPhoneField,
} from 'components/widgets';
import { Form } from 'react-final-form';
import AffiliateDropZone from './AffiliateDropzone';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, Paper, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

export default function AddEditPartner({
  onToggle,
  onSubmit,
  partner,
  title,
  open,
  loading,
  handleButtonClick,
  disableButton,
}) {
  const { data: states } = useSelector(state => state.locationStates);
  const { data: addressTypes } = useSelector(state => state.addressTypes);

  // const emptyAddress = {
  //   city: '',
  //   country: 'US',
  //   line1: '',
  //   line2: '',
  //   name: '',
  //   state: '',
  //   type: '',
  //   zip: '',
  //   orgId: partner.orgId,
  //   contacts: [
  //     {
  //       email: '',
  //       fax: '',
  //       phone: '',
  //       orgId: partner.orgId,
  //     },
  //   ],
  // };

  const classes = useStyles();
  return (
    <FlyoutPanel title={title} open={open} onToggle={() => onToggle()}>
      <>
        <div style={{ marginBottom: 20 }}>
          <AffiliateDropZone
            handleButtonClick={handleButtonClick}
            disableButton={disableButton}
          />
        </div>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ ...partner }}
          render={({
            handleSubmit,
            pristine,
            form: {
              mutators: { push, pop },
            },
          }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid container>
                <Grid item xs={12}>
                  <FormTextField name="name" label="Name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormDBField name="groupId" label="D&B" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormEINField name="ein" label="EIN" />
                </Grid>
              </Grid>
              <>
                <b style={{marginLeft: '8px'}}>Address</b>

                <FieldArray name="addresses">
                  {({ fields }) =>
                    fields.map((address, index) => (
                      <Paper
                        key={address}
                        className={classes.paper}
                        elevation={2}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          {/* <div
                            style={{
                              fontSize: 14,
                              fontWeight: 'bold',
                              margin: 16,
                            }}>{`Address ${index + 1}`}</div> */}
                          {fields.length > 1 && (
                            <Tooltip
                              title="Remove address"
                              placement="top"
                              arrow>
                              <IconButton onClick={() => fields.remove(index)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>

                        <Grid container>
                          <Grid item xs={6}>
                            <FormSelectField
                              name={`${address}.type`}
                              label="Type of Address"
                              options={addressTypes}
                              required
                            />
                          </Grid>

                          <FieldArray name={`${address}.contacts`}>
                            {({ fields: addrFields }) =>
                              addrFields.map(contact => (
                                <Grid key={contact} container>
                                  <Grid item xs={12} sm={6}>
                                    <FormPhoneField
                                      name={`${contact}.phone`}
                                      label={LABELS_FIELDS_BUTTONS.BUSINESS_CONTACT}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormEmailField
                                      name={`${contact}.email`}
                                      label="Email"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormPhoneField
                                      name={`${contact}.fax`}
                                      label="Fax number"
                                    />
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </FieldArray>

                          {/* <Grid item xs={12} sm={6}>
                            <FormTextField
                              name={`${address}.name`}
                              label="Address name"
                            />
                          </Grid> */}

                          <Grid item xs={12} sm={6}>
                            <FormTextField
                              name={`${address}.line1`}
                              label="Apt/Suite #"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormTextField
                              name={`${address}.line2`}
                              label="Street address"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormTextField
                              name={`${address}.city`}
                              label="City"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormSelectField
                              name={`${address}.state`}
                              label="State"
                              options={states}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormZipField name={`${address}.zip`} label="Zip" />
                          </Grid>
                        </Grid>
                      </Paper>
                    ))
                  }
                </FieldArray>
                {/* <div style={{ margin: 16 }}>
                  <SecondaryButton
                    onClick={() => push('addresses', emptyAddress)}>
                    Add another address
                  </SecondaryButton>
                </div> */}
              </>

              <FooterActions
                saveLabel={LABELS_FIELDS_BUTTONS.SAVE_CHANGES}
                type="submit"
                onToggle={onToggle}
                disabled={
                  title === 'Add affiliate'
                    ? disableButton || loading
                    : (disableButton ? pristine || loading : disableButton || loading)
                }
              />
              <SimpleBackdrop open={loading} />
            </form>
          )}
        />
      </>
    </FlyoutPanel>
  );
}
