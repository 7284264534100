import React from 'react';
import './SideMenu.scss';
import { Step, StepLabel, Stepper } from '@material-ui/core';

const SideMenu = props => {
  const logo=localStorage.getItem("orgLogo");
  const steps = [
    {
      header: 'Enter user details',
      subHeader: 'First name, last name, email ID',
    },
    {
      header: 'Assign role',
      subHeader:
        'Define the role for the user you want to invite',
    },
    {
      header: 'Permissions',
      subHeader:
        'Add permissions to the assigned role',
    },
  ];

  return (
    <div className="sideMenu-root">

      <img height="50px" src={logo ? logo : "/static/logo.svg"} alt="mph-logo" />
      <div className="stepper-container">
        <div className="title">
          <div className="header">Add a new user to your team</div>
          <div className="subheader">
            We’re excited to meet another member of your team!
          </div>
        </div>
        <div className="stepper">
          <Stepper activeStep={props?.currentStep} orientation="vertical">
            {steps?.map(label => {
              return (
                <Step key={label?.header}>
                  <StepLabel className="stepper-label">
                    <div className="stepper-header">{label?.header}</div>
                    <div className="stepper-subheader">{label?.subHeader}</div>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
