import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';

const CustomDialog = withStyles((theme) => ({
  paper:{
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: theme.spacing(3),
  },

}))(Dialog);

export default CustomDialog;
