import {
  makeStyles,
  Container,
  IconButton,
  Grid,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  PrimaryButton,
  AlertDialog,
  Card,
  SearchTextField,
} from 'components/widgets';
import Page from 'components/Page';
import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import DataPartnersApi from 'services/api/DataPartnersApi';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';
import AddEditPartner from './AddEditPartner';
import LoadingPartnerDetails from './LoadingPartnerDetails';
import { useUserPermissions } from 'utils/userPermissions';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  paperItem: {
    minWidth: 280,
    minHeight: 220,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  newPaperItem: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paperContactItem: {
    minHeight: 180,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionFooter: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(1),
  },
  miniItem: {
    padding: theme.spacing(0.5),
    fontSize: 14,
  },
  accordDetails: {
    flexDirection: 'column',
  },
}));

const emptyContact = {
  email: '',
  fax: '',
  phone: '',
};

const emptyAddress = {
  city: '',
  country: 'US',
  line1: '',
  line2: '',
  // name: '',
  state: '',
  type: '',
  zip: '',
  contacts: [emptyContact],
};

function ReadonlyField({ label, value, valueStyle }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 8,
        marginRight: 24,
      }}
    >
      <span style={{ color: '#828282', fontWeight: '400', fontSize: 14 }}>
        {label}
      </span>
      <span style={valueStyle}>{value}</span>
    </div>
  );
}

const PartnersDetailPage = ({
  title,
  orgId,
  createaffiliateAccess,
  readaffiliateAccesss,
  deleteaffiliateAccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [filterText, setFilterText] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [file, setFile] = useState(null);
  const [flyout, setFlayout] = useState({ open: false });
  const { checkITAdminPermission, checkSuperAdminPermission } =
    useUserPermissions();
  const isITAdmin = checkITAdminPermission();
  const isSuperAdmin = checkSuperAdminPermission();
  const classes = useStyles();
  const { info } = useSelector(state => state.userInfo);

  const { enqueueSnackbar } = useSnackbar();

  const handleButtonClick = props => {
    if (props) {
      setFile(props);
      setDisableButton(false);
    }
  };

  const getAllPartners = useCallback(
    _orgId => {
      if (_orgId) {
        setLoading(true);

        DataPartnersApi.getAllPartners(_orgId)
          .then(results => {
            setPartnerData(results);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            enqueueSnackbar('Error getting affiliates', {
              variant: 'error',
            });
          });
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    setFilterText('');
  }, [orgId]);


  useEffect(() => {
    if (!orgId) return;
    getAllPartners(orgId);
  }, [getAllPartners, orgId]);

  const emptyPartner = {
    orgId: orgId,
    name: '',
    ein: '',
    groupId: '',
    addresses: [
      { ...emptyAddress, orgId, contacts: [{ ...emptyContact, orgId }] },
    ],
  };

  const onAddPartner = () => {
    setFlayout({
      open: true,
      partner: emptyPartner,
      title: 'Add affiliate',
    });
  };

  const onAddPartnerSubmit = async values => {
    const formData = new FormData();
    formData.append('aff_image', file);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      const results = await DataPartnersApi.createPartner(orgId, values);
      await DataPartnersApi.uploadAffiliateLogo(
        orgId,
        results.id,
        formData,
        otherHeaders
      );
      getAllPartners(orgId);
      setSelectedPartner(null);
      setFlayout({ open: false });
      setDisableButton(true);
      setLoading(false);
      enqueueSnackbar('Affiliate created successfully', {
        variant: 'success',
      });
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while creating';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const onEditPartnerSubmit = async values => {
    const formData = new FormData();
    formData.append('aff_image', file);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      const results = await DataPartnersApi.updatePartner(orgId, values);
      file &&
        (await DataPartnersApi.uploadAffiliateLogo(
          orgId,
          results.id,
          formData,
          otherHeaders
        ));
      getAllPartners(orgId);
      setSelectedPartner(null);
      setFlayout({ open: false });
      setLoading(false);
      setDisableButton(true);
      enqueueSnackbar('Affiliate updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      setLoading(false);

      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while updating';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const onPartnerSubmit = async values => {
    setLoading(true);
    if (values.id) {
      await onEditPartnerSubmit(values);
    } else {
      await onAddPartnerSubmit(values);
    }
    setLoading(false);
  };

  const onEditPartner = partner => {
    setSelectedPartner(partner);
    setFlayout({
      open: true,
      partner: partner,
      title: 'Edit affiliate',
    });
  };

  const onDeletePartner = partner => {
    setSelectedPartner(partner);
    setAlertDialog({
      open: true,
      title: 'Delete affiliate',
      description: 'Are you sure, you want to delete this partner?',
    });
  };

  const onDialogConfirm = async () => {
    setLoading(true);
    try {
      await DataPartnersApi.deletePartner(orgId, selectedPartner.id);
      await getAllPartners(orgId);
      setSelectedPartner(null);
      setAlertDialog({ open: false });

      enqueueSnackbar('Affiliate deleted successfully', {
        variant: 'success',
      });

      setLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      setAlertDialog({ open: false });

      enqueueSnackbar('Error deleting partner', {
        variant: 'error',
      });
    }
  };

  const onDialogClose = () => {
    setSelectedPartner(null);
    setAlertDialog({ open: false });
  };

  const onPartnerToggle = () => {
    setFlayout({ open: false });
    setDisableButton(true);
    setFile(null);
  };

  return (
    <Page className={classes.root} title={title}>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        {loading ? (
          <LoadingPartnerDetails />
        ) : (
          <>
            <div
              style={{
                margin: 16,
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div>
                    <SearchTextField
                      label={LABELS_FIELDS_BUTTONS.SEARCH_AFFILIATE}
                      onChangeText={setFilterText}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {(createaffiliateAccess ||
                    isSuperAdmin) && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <PrimaryButton
                        onClick={onAddPartner}
                        hasPermissions={createaffiliateAccess || isSuperAdmin}
                        size="large"
                      >
                        Add affiliate
                      </PrimaryButton>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>

            <Grid container>
              {partnerData
                ?.filter(
                  fItem =>
                    fItem.name
                      .toLowerCase()
                      .indexOf(filterText.toLowerCase()) !== -1
                )
                .map(item => (
                  <Grid item xs={12} lg={6} key={item.id}>
                    <Card className={classes.card}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 2,
                        }}
                      >
                        {item.imageUrl ? (
                          <img
                            alt="Affiliate Logo"
                            src={item.imageUrl}
                            style={{ paddingLeft: '6px', height: '40px' }}
                          />
                        ) : (
                          <ReadonlyField
                            // label="Affiliate Name"
                            value={item.name}
                            valueStyle={{ fontWeight: 'bold', fontSize: 16 }}
                          />
                        )}
                        <div>
                          {(createaffiliateAccess ||
                            isSuperAdmin) && (
                            <>
                              <Tooltip
                                title="Add/Edit affiliate details/address"
                                placement="top"
                                arrow
                              >
                                <IconButton onClick={() => onEditPartner(item)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}

                          {(deleteaffiliateAccess ||
                            isSuperAdmin) && (
                            <>
                              <Tooltip
                                title="Delete affiliate"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  onClick={() => onDeletePartner(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </div>

                      {item.imageUrl && (
                        <ReadonlyField
                          // label="Affiliate Name"
                          value={item.name}
                          valueStyle={{ fontWeight: 'bold', fontSize: 16 }}
                        />
                      )}
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ width: '160px' }}>
                          <ReadonlyField
                            label={`D&B`}
                            value={`${item.groupId.substr(
                              0,
                              2
                            )}-${item.groupId.substr(
                              2,
                              3
                            )}-${item.groupId.substr(5)}`}
                          />
                        </div>
                        <ReadonlyField
                          label={LABELS_FIELDS_BUTTONS.EIN_NUMBER}
                          value={`${item.ein.substr(0, 2)}-${item.ein.substr(
                            2
                          )}`}
                        />
                      </div>
                      <div>
                        {item.addresses?.length > 0 &&
                          item.addresses.map((item, index) => (
                            <div key={item.createdAt}>
                              <div
                                style={{ display: 'flex', flexWrap: 'wrap' }}
                              >
                                <div style={{ width: '160px' }}>
                                  <ReadonlyField
                                    label={
                                      LABELS_FIELDS_BUTTONS.BUSINESS_CONTACT
                                    }
                                    value={`(${item.contacts[0].phone.substr(
                                      0,
                                      3
                                    )})-${item.contacts[0].phone.substr(
                                      3,
                                      3
                                    )}-${item.contacts[0].phone.substr(6)}`}
                                  />
                                </div>
                                <ReadonlyField
                                  label={`Fax`}
                                  value={`(${item.contacts[0].fax.substr(
                                    0,
                                    3
                                  )})-${item.contacts[0].fax.substr(
                                    3,
                                    3
                                  )}-${item.contacts[0].fax.substr(6)}`}
                                />
                              </div>
                              <div style={{ width: '450px' }}>
                                <ReadonlyField
                                  label={'Address'}
                                  value={`${item.line1} ${item.line2}, ${item.city}, ${item.state}, ${item.zip}`}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* <ManageAffiliateAddresses
                        orgId={orgId}
                        affiliateData={item}
                        refreshData={getAllPartners}
                        partnerId={item.id}
                      /> */}
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>

      {flyout.open && (
        <AddEditPartner
          onSubmit={onPartnerSubmit}
          onToggle={onPartnerToggle}
          loading={loading}
          handleButtonClick={handleButtonClick}
          disableButton={disableButton}
          {...flyout}
        />
      )}
      {alertDialog.open && (
        <AlertDialog
          onClose={onDialogClose}
          onConfirm={onDialogConfirm}
          loading={loading}
          {...alertDialog}
        />
      )}
    </Page>
  );
};

export default PartnersDetailPage;
