import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Checkbox, Chip, Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { PERMISSION_CODES } from 'shared/constants';

const useStyles = ({ isManageRole }) =>
  makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: '14px',
    },
    primaryChip: {
      margin: theme.spacing(0.5),
      backgroundColor: '#E2F1FC',
      borderColor: '#005DB9',
      color: '#005DB9',
      textTransform: 'uppercase',
      height: '16px',
      padding: '5px',
    },
    warningChip: {
      margin: theme.spacing(0.5),
      backgroundColor: '#FFEAEF',
      borderColor: '#DB0032',
      color: '#DB0032',
      textTransform: 'uppercase',
      height: '16px',
      padding: '5px',
    },
    secondaryBtn: {
      padding: 0,
      marginLeft: 'auto',
    },
    featureHeading: {
      color: '#667085',
      fontWeight: 400,
      fontSize: '14px',
      padding: '0px 15px',
    },
    accordionRoot: {
      '&.MuiAccordion-root:before': {
        display: 'none',
      },
      marginTop: '15px',
      zIndex: 2,
    },
    accordionSummary: {
      margin: 0,
      padding: 0,
      zIndex: 2,
      '&:hover': {
        '&::before': {
          backgroundColor: '#f5f5f5',
          borderRadius: '5px',
          content: "''",
          height: '50px',
          width: isManageRole ? '105%' : '107%',
          position: 'absolute',
          zIndex: 1,
        },
      },
    },
  }));

const FeaturePermissionAccordion = ({
  featureIdx,
  featureTitle,
  handleFeaturePermission,
  isView,
  permissionCode,
  permissionLimit,
  solutionIdx,
  subFeatures,
  isManageRole,
}) => {
  const classes = useStyles({
    isManageRole,
  })();
  const permissionCount =
    Object.values(permissionLimit || {})?.reduce(
      (cur, acc) => (cur += acc),
      0
    ) || 0;

  return (
    <Accordion elevation={0} className={classes.accordionRoot} key={`${solutionIdx}-${featureTitle}`}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        className={classes.accordionSummary}
        IconButtonProps={{
          style: {
            zIndex: 2,
          },
        }}
        data-testid='feature-accordion-summary'
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          gridGap={'5px'}
          style={{ zIndex: 2 }}
        >
          <Typography className={classes.heading} variant="h3" data-testid="feature-permission-title">
            {featureTitle}
          </Typography>
          {Boolean(permissionCount) && (
            <Chip
              variant="outlined"
              size="medium"
              label={`${permissionCount} Permissions`}
              className={classes.primaryChip}
              data-testid="feature-permission-count-chip"
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        style={{ margin: 0, padding: 0, flexDirection: 'column' }}
      >
        {subFeatures?.map((subFeature, subFeatureIdx) => (
          <div
            style={{ flexGrow: 1, padding: '16px 0px' }}
            key={subFeature.subFeatureId}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography
                  className={classes.featureHeading}
                  style={{ fontWeight: 400, fontSize: '14px' }}
                >
                  {subFeature.subFeatureName}
                </Typography>
              </Grid>
              {permissionCode?.map(permission => {
                const isSelected = subFeature.selectedPermissions?.includes(
                  permission.code
                );
                const shouldNotRender =
                  !subFeature.allowedPermissions?.includes(permission.code);

                const shouldDisableReadPermission =
                  permission.code === PERMISSION_CODES.READ &&
                  subFeature.selectedPermissions?.length > 1;
                return (
                  <Grid item xs={2} key={permission.code}>
                    {isView || shouldNotRender ? (
                      <DoneIcon
                        style={{
                          fontSize: '24px',
                          color:
                            isSelected && !shouldNotRender
                              ? '#005DB9'
                              : 'white',
                        }}
                        data-testid={`${featureTitle}-${subFeature.subFeatureName}-${permission.code}`}
                      />
                    ) : (
                      <Checkbox
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                          'data-testid': `${featureTitle}-${subFeature.subFeatureName}-${permission.code}`,
                        }}
                        style={{ padding: 0 }}
                        onChange={() =>
                          handleFeaturePermission(
                            solutionIdx,
                            featureIdx,
                            subFeatureIdx,
                            permission.code,
                            isSelected ? 'remove' : 'add'
                          )
                        }
                        checked={isSelected || false}
                        disabled={shouldDisableReadPermission}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default FeaturePermissionAccordion;
