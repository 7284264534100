import React from 'react';
import MuiButton from '@mui/material/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({ 
   primaryHover: {
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.primary.hover,
    },
  },
  secondaryHover: {
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.black,
      backgroundColor: '#389DEF',
      color: '#FFFFFF',
    },
    '&:disabled': {
      background: theme.palette.primary || 'red',
      color: '#FFFFFF',
    },
  },
  inactive: {
    borderColor: theme.palette.grey[500],
    color: theme.palette.common.main,
    opacity: 0.36,
  },
}));

const defaultProps = {
  disableFocusRipple: false,
};
const propTypes = {};

const Button = (props) => {
  const { rootClass, color, title, inactive, disableFocusRipple, ...rest } = props;
  const classes = useStyles(); 

  return (
    <MuiButton
      classes={{
        root: clsx(
          rootClass,
          !disableFocusRipple
            ? color === 'primary'
              ? classes.primaryHover
              : classes.secondaryHover
            : null,
          inactive ? classes.inactive : null,
        ),
      }}
      // color={color}
      {...rest}
    >
      {title}
    </MuiButton>
  );
};

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default Button;
