import React, { Component, memo } from 'react';
import {
  DataGrid,
  FlyoutPanel,
} from 'components/widgets';
import { withStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
    marginRight: '5px',
  },
  editbtn: {
    width: '58px',
    height: '24px',
    fontSize: '12px',
  },
  attributeType: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    borderRadius: '16px',
    backgroundColor: '#E2F1FC',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '120px',
    height: '24px',
    border: '1px solid #036FCB',
    padding: '10px',
    fontWeight: '500',
    color: '#036FCB',
  },
  fieldLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  fieldValue: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  paper: {
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    background: '#f5f5f5',
    width: '100%',
    marginTop: '20px',
    padding: '10px',
  },
  bulletPoints: {
    marginTop: '8px',
    marginLeft: '16px',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#344054',
  },
});

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
  classes,
}) {
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: '12px',
      }}
    >
      <PrimaryButton
        className={classes.viewDetailsButton}
        onClick={onClickHandler}
      >
        View details
      </PrimaryButton>
    </div>
  );
});

class CarePlanGrid extends Component {
  state = {
    openFlyout: false,
    flyoutData: {},
    columnDefs: [
      {
        headerName: 'CarePlan Name',
        field: 'carePlanName',
        minWidth: '150',
      },
      {
        headerName: 'Duration',
        field: 'duration',
        minWidth: '150',
      },
      {
        headerName: 'Category',
        field: 'category',
        minWidth: '150',
      },
      {
        headerName: 'Goals',
        field: 'goals',
        minWidth: '150',
        valueGetter: params => params.data.goals ? params.data.goals[0] : '-',
      },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: (valueA, valueB) => valueA.toLowerCase().localeCompare(valueB.toLowerCase()),
    },
    frameworkComponents: {
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          classes={this.props.classes}
        />
      ),
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.setState({
      flyoutData: data,
      openFlyout: true,
    });
  }

  onToggle() {
    this.setState({
      openFlyout: false,
    });
  }

  renderBulletPoints(array) {
    if (!array || array.length === 0) {
      return '-';
    }
    return (
      <ul className={this.props.classes.bulletPoints}>
        {array.map((item, index) => (
          <li style={{fontWeight: 500,fontSize: '16px',lineHeight: '24px',color: '#344054',fontFamily: 'Inter'}} key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  render() {
    const {
      defaultColDef,
      columnDefs,
      frameworkComponents,
      openFlyout,
      flyoutData,
    } = this.state;
    const { rowData, classes } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
        <FlyoutPanel
          style={{ overflowY: 'hidden' }}
          title={'Care Plan Details'}
          open={openFlyout}
          onToggle={() => this.onToggle()}
          handleClose={() => this.onToggle()}
          isAppBarRequired={true}
        >
          <Paper className={classes.paper}>
            <Grid container spacing={3} style={{ paddingTop: '10px' }}>
              <Grid item xs={12}>
                <Typography className={classes.fieldLabel}>CarePlan Name</Typography>
                <Typography className={classes.fieldValue} variant="h6">
                  {flyoutData?.carePlanName || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.fieldLabel}>Description</Typography>
                <Typography className={classes.fieldValue} variant="h6">
                  {flyoutData?.description || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.fieldLabel}>Duration</Typography>
                <Typography className={classes.fieldValue} variant="h6">
                  {flyoutData?.duration || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.fieldLabel}>Category</Typography>
                <Typography className={classes.fieldValue} variant="h6">
                  {flyoutData?.category || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.fieldLabel}>Goals</Typography>
                {this.renderBulletPoints(flyoutData?.goals)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.fieldLabel}>Intent</Typography>
                <Typography className={classes.fieldValue} variant="h6">
                  {flyoutData?.intent || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.fieldLabel}>Problems</Typography>
                {this.renderBulletPoints(flyoutData?.problems)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.fieldLabel}>Activities</Typography>
                {this.renderBulletPoints(flyoutData?.activities)}
              </Grid>
            </Grid>
          </Paper>
        </FlyoutPanel>
      </div>
    );
  }
}

export default withStyles(styles)(CarePlanGrid);
