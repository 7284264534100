import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NoAccessCard from '../MembersTab/NoaccessCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TAB_ID = {
  'Applications':0,
  'Consents':1,
  'Outreach':2
}

export default function BasicTabs({
  TabsVal,
  TabHeading,
  onServiceClick,
  consentViewDetails,
  getTabIndexClicked,
  MemberName,
  handleTabIndex,
  tabIndex,
  member,
  setMember,
  searchValue,
  query,
  dateFilterOptions,
  getAllMemberConsents,
  hasInitiateConsentPermission
}) {
  const [selectedTab, setSelectedTab] = React.useState(
    TabHeading.defaultTabIndex || 1
  );

  const handleChange = (tabName, tabIndex) => {
    handleTabIndex(tabIndex);
    // switch (event.target.innerText) {
    //   case 'Financial':
    //     fireHanselEvent('MPH_SERVICES_FINANCIAL_ON_CLICK', {});
    //     break;
    // case 'Care':
    //   fireHanselEvent('MPH_SERVICES_CARE_ON_CLICK', {});
    //   break;
    // case 'Legal':
    //   fireHanselEvent('MPH_SERVICES_LEGAL_ON_CLICK', {});
    //   break;
    // }
    setSelectedTab(tabIndex);
  };
  const styles = {
    TabContainer: {
      backgroundColor: '#F5F5F5',
      minHeight: '100%',
      position: 'relative',
      marginLeft: 12,
      marginTop: '-16px',
    },

    TabHeading: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      textAlign: 'left',
      marginLeft: '-20px',
      textTransform: 'capitalize',
    },
    cardContainer: {
      minHeight: '100%',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      paddingTop: 40,
    },
    cardWrapper: { marginBottom: 32, marginRight: 32 },
    TabsWrapper: { borderBottom: '2px solid #D9D9D9', height: 62 },
  };
  
  return (
    <Box sx={{ width: '95%' }} style={styles.TabContainer}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
        style={styles.TabsWrapper}>
        <Tabs
          value={tabIndex}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#00A0DF',
              height: 4,
              borderRadius: '100px 100px 0px 0px',
              display: 'flex',
              justifyContent: 'center',
              width:
                tabIndex === 0 ? '110px' : tabIndex === 1 ? '88px' : '85px',
            },
          }}
          style={{ paddingTop: 15, marginLeft: 0 }}>
          {TabHeading.availableTabs &&
            TabHeading.availableTabs.length > 0 &&
            TabHeading.availableTabs.map((heading, index) => (
              <Tab
                key={index}
                value={TAB_ID[heading]}
                onClick={() => handleChange(heading,TAB_ID[heading])}
                label={<span style={styles.TabHeading}>{heading}</span>}
                {...a11yProps(index)}
              />
            ))}
        </Tabs>
      </Box>
      <Box style={{ width: '100%', height: '100%', marginTop: '40px' }}>
        {selectedTab === 0 && 'Application'}
        {selectedTab === 1 && (
          <NoAccessCard
            member={member}
            setMember={setMember}
            consentViewDetails={consentViewDetails}
            searchValue={searchValue}
            query={query}
            dateFilterOptions={dateFilterOptions}
            getAllMemberConsents={getAllMemberConsents}
            hasInitiateConsentPermission={hasInitiateConsentPermission}
          />
        )}
        {selectedTab === 2 && 'Outreach'}
      </Box>
    </Box>
  );
}
