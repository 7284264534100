import React from 'react';
import UserDetailsCards from './UserDetailsCards';
import { Box, Typography } from '@material-ui/core';

const VerifyDetails = ({
  userInfoPayload,
  setInviteCard,
  setRefetchInternalUsers,
  setRefetchExternalUsers,
  roleId,
  changeStep,
  editUserDetails,
  roleData,
  payload,
  lobs,
  affiliates,
  isExternal,
  ...props
}) => {
  const data = {
    firstName: payload?.firstName,
    lastName: payload?.lastName,
    emailId: payload?.emailAddress,
    affiliateLobMap:payload?.affiliateLobMap
  };
  return (
    <div style={{ width: '100%', display: 'flex',flexDirection:'column' }}>
      <Box style={{display:'flex',textAlign:'center',flexDirection:'column',justifyContent:'center',gap:'5px'}}>
        <Typography style={{ fontWeight: 600, fontSize: '28px' }}>
          Verify details
        </Typography>
        <Typography
          style={{ fontWeight: 500, fontSize: '16px', color: '#667085' }}
        >
          Confirm all the details that you have addedd
        </Typography>
      </Box>
      <UserDetailsCards
        usersDetails={data}
        roleId={roleId}
        userInfoPayload={userInfoPayload}
        setInviteCard={setInviteCard}
        setRefetchInternalUsers={setRefetchInternalUsers}
        setRefetchExternalUsers={setRefetchExternalUsers}
        isVerifyDetails={true}
        changeStep={changeStep}
        editUserDetails={editUserDetails}
        roleData={roleData}
        lobs={lobs}
        affiliates={affiliates}
        isExternal={isExternal}
      />
    </div>
  );
};

export default VerifyDetails;
