import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import FilterAlt from '../assets/consent/FilterAlt.svg';
import AffiliateFilter from './MembersFilter/AffiliateFilter';
import ExpireOnFilter from './MembersFilter/ExpireOnFilter';
import ConsentStatusFilter from './MembersFilter/ConsentStatusFilter';
import ButtonSection from './MembersFilter/ButtonSection';
import { FILTER_LIST } from '../shared/constants';
import LobFilter from './MembersFilter/LobFilter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  counterboxWrapper: {
    width: '23px',
    height: '16px',
    backgroundColor: '#E9F4FD',
    borderRadius: '40px',
    padding: '0px 8px',
    border: '1px solid #036FCB',
    marginLeft: '4px',
  },
  menuItemWrapper: {
    backgroundColor: '#F5F5F5',
    width: 227,
    borderRadius: 4,
    // boxShadow:
    //   '0px 0.5px 1.5px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
    transfoSrm: 'translateY(2px)',
    transition: 'all 0.8s infinite',
    padding: 4,
  },
  buttonWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#fff',
    width: '100%',
  },
  menuBox: {
    position: 'absolute',
    top: 45,
    left: 20,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    width: '100%',
    maxWidth: '120px',
    textTransform: 'none',
    marginLeft: '28px',
    border: '1px solid #fff',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },
}));

const CustomizedMenus = ({
  affiliateStatus,
  lobStatus,
  consentStatus,
  expireOn,
  handleSelectedData,
  affiliates,
  lobs,
  onFilterSubmit,
  active,
  setActive,
  consentStatusFilterValues,
  lobFilterValues,
  affiliateFilterValues,
  expireOnFilterValues,
  setAllFiltersValues,
  allFiltersValues,
  setShowChip,
}) => {
  const menuRef = useRef(null);

  const classes = useStyles();
  const [hover, setHover] = React.useState({
    affiliate: false,
    consentStatus: false,
    lob: false,
    expireOn: false,
    activeHover: false,
  });

  const [subMenu, setSubMenu] = React.useState({
    affiliate: false,
    consentStatus: false,
    lob: false,
    expireOn: false,
  });

  const handleClear = () => {
    const clearconsentStatus = allFiltersValues?.consentStatus?.map(
      (item, i) => ({
        ...item,
        isChecked: false,
      })
    );
    const clearaffiliateStatus = allFiltersValues?.affiliateStatus?.map(
      (item, i) => ({
        ...item,
        isChecked: false,
      })
    );
    const clearlobStatus = allFiltersValues?.lobStatus?.map((item, i) => ({
      ...item,
      isChecked: false,
    }));
    const clearexpireOn = allFiltersValues?.expireOn?.map((item, i) => ({
      ...item,
      isChecked: false,
    }));
    setAllFiltersValues({
      consentStatus: [...clearconsentStatus],
      expireOn: [...clearexpireOn],
      lobStatus: [...clearlobStatus],
      affiliateStatus: [...clearaffiliateStatus],
    });
    setActive(prev => !prev);
  };

  const handleClick = () => {
    setActive(prev => !prev);
  };

  const MENU_LIST_ITEM = FILTER_LIST;

  const handleSelectMenu = item => {
    if (item && item.key === 1) {
      setHover({
        consentStatus: false,
        expireOn: false,
        affiliate: true,
        lob: false,
        activeHover: 1,
      });
      setSubMenu(prevState => ({
        consentStatus: false,
        expireOn: false,
        lob: false,
        affiliate: true,
      }));
    }
    if (item && item.key === 0) {
      setHover({
        consentStatus: true,
        expireOn: false,
        lob: false,
        affiliate: false,
        activeHover: 0,
      });
      setSubMenu(prevState => ({
        consentStatus: true,
        expireOn: false,
        lob: false,
        affiliate: false,
      }));
    }
    if (item && item.key === 2) {
      setHover({
        consentStatus: false,
        expireOn: false,
        affiliate: false,
        lob: true,
        activeHover: 2,
      });
      setSubMenu(prevState => ({
        consentStatus: false,
        expireOn: false,
        lob: true,
        affiliate: false,
      }));
    }
    if (item && item.key === 3) {
      setHover({
        consentStatus: false,
        expireOn: true,
        affiliate: false,
        lob: false,
        activeHover: 3,
      });
      setSubMenu(prevState => ({
        consentStatus: false,
        expireOn: true,
        lob: false,
        affiliate: false,
      }));
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        variant="contained"
        className={classes.btnContainer}
        onClick={handleClick}>
        <img
          src={FilterAlt}
          alt="filter-alt"
          style={{ color: '#000000', marginRight: '5px' }}
        />
        Add filter
      </Button>
      {active && (
        <div ref={menuRef} className={classes.menuBox}>
          <div>
            <div className={classes.menuItemWrapper}>
              {MENU_LIST_ITEM.map(item => {
                return (
                  <p
                    onMouseOver={e => handleSelectMenu(item)}
                    onClick={e => handleSelectMenu(item)}
                    key={item.key}
                    style={{
                      padding: 10,
                      backgroundColor:
                        (hover.expireOn && hover.activeHover === item.key) ||
                        (hover.consentStatus &&
                          hover.activeHover === item.key) ||
                        (hover.affiliate && hover.activeHover === item.key) ||
                        (hover.lob && hover.activeHover === item.key)
                          ? '#F5F5F5'
                          : '#fff',
                      width: '100%',
                    }}>
                    <span>{item.value}</span>
                    {consentStatusFilterValues?.length > 0 &&
                      item.key === 0 && (
                        <span className={classes.counterboxWrapper}>
                          {consentStatusFilterValues?.length}
                        </span>
                      )}{' '}
                    {affiliateFilterValues?.length > 0 && item.key === 1 && (
                      <span className={classes.counterboxWrapper}>
                        {affiliateFilterValues?.length}
                      </span>
                    )}
                    {lobFilterValues?.length > 0 && item.key === 2 && (
                      <span className={classes.counterboxWrapper}>
                        {lobFilterValues?.length}
                      </span>
                    )}
                    {expireOnFilterValues?.length > 0 && item.key === 3 && (
                      <span className={classes.counterboxWrapper}>
                        {expireOnFilterValues?.length}
                      </span>
                    )}
                  </p>
                );
              })}
              <div className={classes.buttonWrapper}>
                <ButtonSection
                  onCancel={handleClear}
                  onFilterSubmit={onFilterSubmit}
                />
              </div>
            </div>
          </div>

          {subMenu.affiliate && (
            <AffiliateFilter
              menuRef={menuRef}
              setSubMenu={setSubMenu}
              handleSelectedData={handleSelectedData}
              affiliates={affiliates}
              affiliateStatus={affiliateStatus}
              setActive={setActive}
              setShowChip={setShowChip}
            />
          )}
          {subMenu.consentStatus && (
            <ConsentStatusFilter
              menuRef={menuRef}
              setSubMenu={setSubMenu}
              handleSelectedData={handleSelectedData}
              consentStatus={consentStatus}
              setActive={setActive}
              setShowChip={setShowChip}
            />
          )}
          {subMenu.lob && (
            <LobFilter
              menuRef={menuRef}
              setSubMenu={setSubMenu}
              handleSelectedData={handleSelectedData}
              lobs={lobs}
              lobStatus={lobStatus}
              setActive={setActive}
              setShowChip={setShowChip}
            />
          )}
          {subMenu.expireOn && (
            <ExpireOnFilter
              menuRef={menuRef}
              expireOn={expireOn}
              handleSelectedData={handleSelectedData}
              setSubMenu={setSubMenu}
              setActive={setActive}
              setShowChip={setShowChip}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CustomizedMenus;
