import React from 'react';
import {
  FlyoutPanel,
  FooterActions,
  FormTextField,
  FormZipField,
  FormSelectField,
  FormEmailField,
  FormPhoneField,
  SimpleBackdrop,
} from 'components/widgets';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';

export default function AddEditAddress({
  onToggle,
  onSubmit,
  address,
  title,
  open,
  loading,
  states = [],
  addressTypes = [],
}) {
  return (
    <FlyoutPanel title={title} open={open} onToggle={() => onToggle()}>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{ ...address }}
        render={({ handleSubmit, pristine }) => (
          <form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Grid container>
              <FieldArray name="contacts">
                {({ fields: addrFields }) =>
                  addrFields.map(contact => (
                    <Grid key={contact} container>
                      <Grid item xs={12}>
                        <FormPhoneField
                          name={`${contact}.phone`}
                          label={LABELS_FIELDS_BUTTONS.BUSINESS_CONTACT}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormEmailField
                          name={`${contact}.email`}
                          label="Email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormPhoneField
                          name={`${contact}.fax`}
                          label="Fax number"
                        />
                      </Grid>
                    </Grid>
                  ))
                }
              </FieldArray>

              {/* <Grid item xs={12}>
                <FormTextField name="name" label="Address name" />
              </Grid> */}
              <Grid item xs={12}>
                <FormSelectField
                  name="type"
                  label="Type"
                  options={addressTypes}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField name="line1" label="Apt/Suite #" />
              </Grid>
              <Grid item xs={12}>
                <FormTextField name="line2" label="Street address" />
              </Grid>
              <Grid item xs={12}>
                <FormTextField name="city" label="City" />
              </Grid>
              <Grid item xs={12}>
                <FormSelectField
                  name="state"
                  label="State"
                  options={states}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormZipField name="zip" label="Zip" />
              </Grid>
            </Grid>
            <FooterActions
              saveLabel="Save"
              type="submit"
              onToggle={onToggle}
              disabled={pristine || loading}
            />
            <SimpleBackdrop open={loading} />
          </form>
        )}
      />
    </FlyoutPanel>
  );
}
