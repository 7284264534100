import React from 'react';
import { FlyoutPanel } from 'components/widgets';
import moment from 'moment/moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  viewDetailsContainer: {
    width: '100%',
    marginTop: '10px',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
  },
  tableHeading: {
    flex: '0 0 35%',
  },
  tableValue: {
    flex: '0 0 65%',
  },
  tableContainer: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
    flexDirection: 'row',
    margin: '10px',
    padding: '10px',
  },
  dialogTitleContainer: {},
}));

const RulesViewDetailsPage = ({ onToggle, open, data, title }) => {
  const classes = useStyles();
  return (
    <FlyoutPanel
      open={open}
      onToggle={() => onToggle()}
      title={title}
      customDialogTitleStyles={classes.dialogTitleContainer}>
      {' '}
      <div style={{ width: '100%', height: '640px' }}>
        <div className={classes.viewDetailsContainer}>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeading}>
              <h5 style={{ color: 'gray' }}>Rule name :</h5>
            </div>
            <div className={classes.tableValue}>
              <h4>{data.name || '-'}</h4>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeading}>
              <h5 style={{ color: 'gray' }}>Label :</h5>
            </div>
            <div className={classes.tableValue}>
              <h4>{data.securityLabel || '-'}</h4>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeading}>
              <h5 style={{ color: 'gray' }}>Description :</h5>
            </div>
            <div className={classes.tableValue}>
              <h4>{data.description || '-'}</h4>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeading}>
              <h5 style={{ color: 'gray' }}>Last Executed :</h5>
            </div>
            <div className={classes.tableValue}>
              <h4>{data.lastExecuted || '-'}</h4>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeading}>
              <h5 style={{ color: 'gray' }}>Status :</h5>
            </div>
            <div className={classes.tableValue}>
              <h4>{data.status || '-'}</h4>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeading}>
              <h5 style={{ color: 'gray' }}>Created Date :</h5>
            </div>
            <div className={classes.tableValue}>
              <h4>{moment(data.createdAt).format('MMM DD, YYYY')}</h4>
            </div>
          </div>
        </div>
      </div>
    </FlyoutPanel>
  );
};

export default RulesViewDetailsPage;
