import React from 'react';
import { SvgIcon } from '@material-ui/core';

const AssessmentIcon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M24.0156 7C25.0938 7 25.9844 7.89062 25.9844 9.01562V22.9844C25.9844 24.1094 25.0938 25 24.0156 25H7.98438C6.90625 25 6.01562 24.1094 6.01562 22.9844V9.01562C6.01562 7.89062 6.90625 7 7.98438 7H24.0156ZM13 21.0156C13.5625 21.0156 13.9844 20.5469 13.9844 19.9844C13.9844 19.4688 13.5625 19 13 19H10C9.4375 19 9.01562 19.4688 9.01562 19.9844C9.01562 20.5469 9.4375 21.0156 10 21.0156H13ZM13 16.9844C13.5625 16.9844 13.9844 16.5625 13.9844 16C13.9844 15.4375 13.5625 15.0156 13 15.0156H10C9.4375 15.0156 9.01562 15.4375 9.01562 16C9.01562 16.5625 9.4375 16.9844 10 16.9844H13ZM13 13C13.5625 13 13.9844 12.5312 13.9844 12.0156C13.9844 11.4531 13.5625 10.9844 13 10.9844H10C9.4375 10.9844 9.01562 11.4531 9.01562 12.0156C9.01562 12.5312 9.4375 13 10 13H13ZM22.7031 15.1094C23.0781 14.7344 23.0781 14.0781 22.7031 13.7031C22.3281 13.3281 21.6719 13.3281 21.2969 13.7031L18.8125 16.1875L18.1094 15.4375C17.7344 15.0625 17.0781 15.0625 16.7031 15.4375C16.3281 15.8594 16.3281 16.4688 16.7031 16.8906L18.1094 18.2969C18.4844 18.6719 19.1406 18.6719 19.5156 18.2969L22.7031 15.1094Z" />
    </SvgIcon>
  );
};

export default AssessmentIcon;
