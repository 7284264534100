import React, { useMemo } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import { SNACKBAR_TYPE } from '../constants';
import PropTypes from 'prop-types';

const SnackbarToast = (props) => {
  const { open, duration, onClose, message, type, anchor, alertStyle, ...rest } = props;

  const anchorOrigin = useMemo(
    () => anchor || { vertical: 'bottom', horizontal: 'left' },
    [anchor],
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose(event);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={duration || 6000}
        onClose={handleClose}
        {...rest}
      >
        <Alert
          onClose={handleClose}
          severity={type || SNACKBAR_TYPE.INFO}
          sx={{ width: '100%' }}
          elevation={6}
          style={alertStyle}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

SnackbarToast.prototypes = {
  /**
   * To make toast message visible
   */
  open: PropTypes.bool,
  /**
   * duration of toast open in milliseconds
   */
  duration: PropTypes.number,
  /**
   * method to handle on close of toast message
   */
  onclose: PropTypes.func,
  /**
   * message to display in toast bar
   */
  message: PropTypes.string,
  /**
   * Severity of message
   * one of SNACKBAR_TYPE from constants file.
   */
  type: PropTypes.string,
  /**
   * position of toast message
   * sample { vertical: 'bottom', horizontal: 'left' }
   */
  anchor: PropTypes.object,
};

export default SnackbarToast;
