import BaseApiService from './BaseApiService';
import { useSelector, useDispatch } from 'react-redux';
import { graphqlAPI } from '../../config/apiConfig';

const baseURL = graphqlAPI;

function _uploadDocument(payload, otherConfig) {
  return BaseApiService.post(
    `${baseURL}/documentservice/upload/documents?folder=mpowered-documents&resourceName=Document&category=Financial&updatedBy=Admin`,
    null,
    payload,
    false,
    otherConfig,
    {},
    true
  );
}

function _getAllDocuments() {
  return BaseApiService.get(
    `${baseURL}/documentservice/document/all?updatedBy=Admin&offSet=0&searchFilter=&dateFilter=0&count=10`,
    null,
    false,
    {},
    true
  );
}

function _downloadDocument(docArn) {
  return BaseApiService.get(
    `${baseURL}/documentservice/document/download?docArn=${docArn}&folder=mpowered-documents`,
    null,
    false,
    {
      responseType: 'blob',
    },
    true
  );
}

function _deleteDocument(docId) {
  return BaseApiService.delete(
    `${baseURL}/documentservice/document/delete?documentId=${docId}`,
    null,
    null,
    false,
    true
  );
}

function _getAllSupport({ status, offset, limit = 10 }) {
  // todo Change BaseURL to scan
  return BaseApiService.get(
    `/organization/demo/support?status=${status}&offset=${offset}&limit=${limit}`,
    null,
    true
  );
}

function _getAllCarePlans({ orgId,offset=0, limit = 10 }) {
  // todo Change BaseURL to scan
  return BaseApiService.get(
    `/organization/${orgId}/getAllCarePlans?offset=${offset}&limit=${limit}`,
    null,
    true
  );
}

function _putSupport(payload) {
  return BaseApiService.put(`/organization/demo/support`, null, payload);
}

const DemoApis = {
  uploadDocument: _uploadDocument,
  getAllDocuments: _getAllDocuments,
  downloadDocument : _downloadDocument,
  deleteDocument : _deleteDocument,
  getAllSupport:_getAllSupport,
    putSupport: _putSupport,
  getAllCarePlans: _getAllCarePlans
  

};

export default DemoApis;
