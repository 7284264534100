import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { BUTTON_TITLES } from '../../shared/constants';

const useStyles = makeStyles(theme => ({
  conatiner: {
    display: 'flex',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: '26px',
    width: '100%',
    maxWidth: '79px',
    textTransform: 'capitalize',
    paddingLeft: 14,
    marginLeft: '5px',
    paddingRight: 14,
    color: '#036FCB',
  },
  applyFilterButton: {
    borderRadius: 40,
    height: '26px',
    width: '100%',
    marginLeft: '10px',
    maxWidth: '116px',
    textTransform: 'capitalize',
  },
}));

const ButtonSection = ({ onCancel, onFilterSubmit }) => {
  const classes = useStyles();
  const { CANCEL, APPLY_FILTERS } = BUTTON_TITLES;
  return (
    <div className={classes.conatiner}>
      <Button
        variant="contained"
        className={classes.cancelButton}
        onClick={onCancel}>
        {CANCEL}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.applyFilterButton}
        onClick={onFilterSubmit}>
        {APPLY_FILTERS}
      </Button>
    </div>
  );
};

export default ButtonSection;
