import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import { PrimaryButton, SecondaryButton } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1.5),
    textTransform: 'none',
  },
  actions: {
    justifyContent: 'flex-start',
  },
}));

function HeaderActions({
  saveLabel = 'Save',
  canceLabel = 'Cancel',
  onSave,
  type = 'button',
  onToggle,
  disabled,
  hideSave = false,
  hideCancel,
  ...otherCommonProps
}) {
  const classes = useStyles();

  return (
    <DialogActions className={classes.actions}>
      {!hideCancel && (
        <SecondaryButton
          onClick={onToggle}
          variant="outlined"
          color="primary"
          className={classes.actionButton}
          {...otherCommonProps}
        >
          {canceLabel}
        </SecondaryButton>
      )}
      {!hideSave && (
        <PrimaryButton
          onClick={onSave}
          disabled={disabled}
          type={type}
          className={classes.actionButton}
          {...otherCommonProps}
        >
          {saveLabel}
        </PrimaryButton>
      )}
    </DialogActions>
  );
}

export default HeaderActions;
