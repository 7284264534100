import BaseApiService from './BaseApiService';

function _getFhirResources() {
  return BaseApiService.get('/fhir/metadata/fhirResource', null, false);
}

function _getFhirComponent(resourceName) {
  return BaseApiService.get(
    `/fhir/metadata/fhirResource/${resourceName}`,
    null,
    false
  );
}

function _getFhirComponentPreDefinedValues(resourceName, componentName) {
  return BaseApiService.get(
    `/fhir/metadata/getFhirComponentValue/${resourceName}/${componentName}`,
    null,
    false
  );
}

function _getAttributeTitle(attrTitle) {
  return BaseApiService.get(`/attribute/name-list?searchName=${attrTitle}`);
}

function _createAttribute(payload) {
  return BaseApiService.post(`/attribute`, null, payload);
}

function _getAttribute({ searchName, orgId, offset, searchType }) {
  return BaseApiService.get(
    `/attribute?offset=${offset}&limit=10&searchName=${searchName}&orgId=${orgId}&searchAttrType=${searchType}`
  );
}

function _editAttribute(attributeId, payload) {
  return BaseApiService.put(`/attribute/${attributeId}`, null, payload);
}

function _attributeDetails({ attriId }) {
  return BaseApiService.get(`/attribute/${attriId}`);
}

function _deleteAttribute(attriId) {
  return BaseApiService.delete(`/attribute/${attriId}`);
}

function _dataTypes() {
  return BaseApiService.get(`/attribute/non-fhir/data-type-supported-check`);
}

function _dataSource() {
  return BaseApiService.get(`/attribute/non-fhir/supported-data-source`);
}

const AttributeApi = {
  getFhirResource: _getFhirResources,
  getFhirComponent: _getFhirComponent,
  getFhirComponentPreDefinedValues: _getFhirComponentPreDefinedValues,
  getAttributeTitle: _getAttributeTitle,
  createAttribute: _createAttribute,
  getAttribute: _getAttribute,
  editAttribute: _editAttribute,
  attributeDetails: _attributeDetails,
  deleteAttribute: _deleteAttribute,
  dataTypes: _dataTypes,
  dataSource: _dataSource,
};

export default AttributeApi;
