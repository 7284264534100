import React, { useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  tempDate,
  validateTimeZone,
  formatTimeInHHMMWithComma,
} from 'shared/utility';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Alarm } from '@material-ui/icons';
import moment from 'moment';
import RulesApi from 'services/api/RulesApi';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 1px 16px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#F5F7F8',
    padding: '10px',
    borderRadius: '8px',
  },
  scheduleGrpContainer: {
    backgroundColor: '#E4E7EC',
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '10px',
    padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    padding: '0px 16px',
    width: '100%',
  },
  ScheduleSummaryContainer: {
    backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
    marginLeft: '16px',
  },
  ScheduleGrpSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  active: {
    '& .MuiStepLabel-active': {
      color: '#036FCB',
    },
  },
  calendarStyle: {
    background: 'white',
    border: '1px solid #e4e7ec',
    // borderBottom: "0px",
    borderRadius: '5px',
    padding: '6px',
  },
  gridWidth: {
    maxWidth: '32%!important',
  },
}));

const SCHEDULE_FREQUENCY_OPTIONS = [
  { id: 'WEEK', name: 'Weekly' },
  { id: 'MONTH', name: 'Monthly' },
  { id: 'DAY', name: 'Daily' },
  { id: 'YEAR', name: 'Yearly' },
];

export default function ScheduleForRules({
  setSavedSchedule,
  setEditSchedule,
  tabStatus,
  setRule,
  ruleValues,
  executionStatus,
  hasCreatePermissions
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [disable, setDisable] = useState(true);

  const [timeZoneOption, setTimeZoneOption] = useState([
    { id: '1', name: 'EST' },
    { id: '2', name: 'CST' },
    { id: '3', name: 'MST' },
    { id: '4', name: 'PST' },
  ]);

  const [selectedType, setSelectedType] = useState(
    ruleValues?.ruleDefn?.schedule?.frequency || ''
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    ruleValues?.ruleDefn?.schedule?.timeZone || ''
  );
  const criteriaIdMapping = useRef({});
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [saveSchedule, setSaveSchedule] = useState(false); //* This should be props
  const orgId= sessionStorage.getItem("orgId");
  const isScheduleSavedBefore = useRef(
    ruleValues?.ruleDefn?.schedule?.startDate ? true : false
  );
  const [saveScheduleStatus, setSaveScheduleStatus] = useState(
    ruleValues?.ruleDefn?.schedule?.startDate
      ? tabStatus?.SAVED
      : tabStatus?.NOT_SAVED
  );

  const RADIO_OPTIONS = ['Recurring', 'One time'];
  const [scheduleType, setScheduleType] = useState(
    ruleValues?.ruleDefn?.schedule?.oneTime === false ? 'Recurring' : 'One time'
  );
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(
      new Date(
        ruleValues?.ruleDefn?.schedule?.startDate
          ? ruleValues.ruleDefn.schedule.startDate
          : ''
      )
    ).format('MM-DD-YYYY')
  );
  const [selectedStartDateshow, setSelectedStartDateshow] = useState(
    ruleValues?.ruleDefn?.schedule?.startDate || null
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment(
      new Date(
        ruleValues?.ruleDefn?.schedule?.endDate
          ? ruleValues.ruleDefn.schedule.endDate
          : ''
      )
    ).format('MM-DD-YYYY')
  );
  const [selectedEndDateshow, setSelectedEndDateshow] = useState(
    ruleValues?.ruleDefn?.schedule?.endDate || null
  );

  const [selectedTimeshow, setSelectedTimeshow] = useState(
    ruleValues?.ruleDefn?.schedule?.startTime
      ? new Date(`2021-06-10T${ruleValues?.ruleDefn?.schedule?.startTime}`)
      : null
  );
  const [selectedTime, setSelectedTime] = useState(
    ruleValues?.ruleDefn?.schedule?.startTime || '01:00'
  );
  const [startisOpen, setStartIsOpen] = useState(false);
  const [endisOpen, setEndIsOpen] = useState(false);
  const [scheduleGrpValue, setscheduleGrpValue] = useState([
    {
      schedule: {
        oneTime: ruleValues?.ruleDefn?.schedule?.oneTime || false,
        startTime: ruleValues?.ruleDefn?.schedule?.startTime || null,
        frequency: ruleValues?.ruleDefn?.schedule?.frequency || null,
        startDate: ruleValues?.ruleDefn?.schedule?.startDate || null,
        endDate: ruleValues?.ruleDefn?.schedule?.endDate || null,
        timeZone: ruleValues?.ruleDefn?.schedule?.timeZone || null,
      },
    },
  ]);

  const handleTypeSelection = e => {
    handleScheduleStatus();
    setDisable(false);
    handleOnchange();
    setEditSchedule(true);
    setSelectedType(e.target.value);
  };

  const handleStartDateChange = date => {
    handleOnchange();
    setDisable(false);
    setEditSchedule(true);
    handleScheduleStatus();
    setStartIsOpen(false);
    setSelectedStartDateshow(date);
    setSelectedStartDate(moment(new Date(date)).format('MM-DD-YYYY'));
  };

  const handleEndDateChange = date => {
    handleOnchange();
    setDisable(false);
    setEditSchedule(true);
    handleScheduleStatus();
    setEndIsOpen(false);
    setSelectedEndDateshow(date);
    setSelectedEndDate(moment(new Date(date)).format('MM-DD-YYYY'));
  };

  const changeTimeOnly = useCallback(time => {
    handleScheduleStatus();
    setDisable(false);
    if (time) {
      setSelectedTime(
        `${time?.getHours() < 10 ? `0${time?.getHours()}` : time?.getHours()}:${
          time?.getMinutes() == 0
            ? '00'
            : time?.getMinutes() < 10
            ? `0${time?.getMinutes()}`
            : time?.getMinutes()
        }:00`
      );
      setSelectedTimeshow(
        new Date(
          `2021-06-10T${
            time?.getHours() < 10 ? `0${time?.getHours()}` : time?.getHours()
          }:${
            time?.getMinutes() == 0
              ? '00'
              : time?.getMinutes() < 10
              ? `0${time?.getMinutes()}`
              : time?.getMinutes()
          }:00`
        )
      );
      handleOnchange();
      setEditSchedule(true);
    } else {
      setSelectedTime(null);
      setSelectedTimeshow(null);
    }
  });

  useEffect(() => {
    handleOnchange();
  }, [
    selectedTime,
    selectedEndDate,
    selectedStartDate,
    selectedTimeZone,
    selectedType,
  ]);

  const handleClearSchedule = () => {
    setEditSchedule(false);
    setExpanded(false);
    setSelectedType('');
    setScheduleType('Recurring');
    setSelectedStartDate('');
    setSelectedStartDateshow(null);
    setSelectedEndDate('');
    setSelectedEndDateshow(null);
    setscheduleGrpValue([
      {
        schedule: {
          oneTime: false,
          startTime: null,
          frequency: null,
          startDate: null,
          endDate: null,
          timeZone: null,
        },
      },
    ]);
  };

  const handleScheduleStatus = () => {
    if (saveScheduleStatus === tabStatus?.SAVED) {
      setSaveScheduleStatus(tabStatus?.NOT_SAVED);
      setSavedSchedule(false);
    }
  };

  const handleOnchange = () => {
    // handleScheduleStatus();
    // setEditSchedule(true);
    let scheduleGrp = [
      {
        schedule: {
          oneTime: scheduleType === 'Recurring' ? false : true,
          startTime: selectedTime || null,
          frequency: scheduleType === 'Recurring' ? selectedType : null,
          startDate:
            selectedStartDate === 'Invalid date'
              ? null
              : selectedStartDate || null,
          endDate:
            scheduleType === 'Recurring'
              ? selectedEndDate === 'Invalid date'
                ? null
                : selectedEndDate
              : null,
          timeZone: selectedTimeZone || null,
        },
      },
    ];
    setscheduleGrpValue(scheduleGrp);

    return scheduleGrp;
  };

  const handleTimeSZoneSelection = e => {
    handleScheduleStatus();
    setDisable(false);
    handleOnchange();
    setEditSchedule(true);
    setSelectedTimeZone(e.target.value);
  };

  const handleEditSchedule = () => {
    setSaveScheduleStatus(tabStatus?.NOT_SAVED);
    setSavedSchedule(false);
    setEditSchedule(true);
    setExpanded(prevValue => !prevValue);
  };

  const closeWithoutEdit = () => {
    setSaveScheduleStatus(tabStatus?.SAVED);
    setDisable(true);
    setSavedSchedule(true);
    setEditSchedule(false);
    setScheduleType(
      ruleValues?.ruleDefn?.schedule?.oneTime === false
        ? 'Recurring'
        : 'One time'
    );
    setSelectedType(ruleValues?.ruleDefn?.schedule?.frequency || '');
    setSelectedTime(ruleValues?.ruleDefn?.schedule?.startTime || '01:00');
    setSelectedTimeZone(ruleValues?.ruleDefn?.schedule?.timeZone || '');
    setSelectedStartDateshow(ruleValues?.ruleDefn?.schedule?.startDate || null);
    setSelectedEndDateshow(ruleValues?.ruleDefn?.schedule?.endDate || null);
    setSelectedTimeshow(
      ruleValues?.ruleDefn?.schedule?.startTime
        ? new Date(`2021-06-10T${ruleValues?.ruleDefn?.schedule?.startTime}`)
        : null
    );
    setSelectedStartDate(
      moment(
        new Date(
          ruleValues?.ruleDefn?.schedule?.startDate
            ? ruleValues.ruleDefn.schedule.startDate
            : ''
        )
      ).format('MM-DD-YYYY')
    );
    setSelectedEndDate(
      moment(
        new Date(
          ruleValues?.ruleDefn?.schedule?.endDate
            ? ruleValues.ruleDefn.schedule.endDate
            : ''
        )
      ).format('MM-DD-YYYY')
    );
    setscheduleGrpValue([
      {
        schedule: {
          oneTime: ruleValues?.ruleDefn?.schedule?.oneTime || false,
          startTime: ruleValues?.ruleDefn?.schedule?.startTime || null,
          frequency: ruleValues?.ruleDefn?.schedule?.frequency || null,
          startDate: ruleValues?.ruleDefn?.schedule?.startDate || null,
          endDate: ruleValues?.ruleDefn?.schedule?.endDate || null,
          timeZone: ruleValues?.ruleDefn?.schedule?.timeZone || null,
        },
      },
    ]);
    setExpanded(prevValue => !prevValue);
  };

  const handleScheduleValidations = () => {
    if (
      selectedTimeZone !== '' &&
      selectedStartDate !== 'Invalid date' &&
      moment(selectedStartDate).isBefore(moment().add(1, 'days').toDate())
    ) {
      let validationResponse = validateTimeZone(
        selectedTime,
        selectedStartDate,
        selectedTimeZone
      );

      if (validationResponse === 'Invalid') {
        enqueueSnackbar('Please select a valid time / time zone', {
          variant: 'error',
        });
        return false;
      }
    }
    if (scheduleType === 'Recurring') {
      if (
        !selectedTime ||
        !selectedType ||
        selectedStartDate === 'Invalid date' ||
        !selectedTimeZone
      ) {
        enqueueSnackbar('Please fill all value', {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return false;
      } else if (
        moment(selectedStartDateshow).isSameOrAfter(moment(selectedEndDateshow))
      ) {
        enqueueSnackbar("Start date can't be same or greater than end date", {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return false;
      } else if (
        selectedType === 'WEEK' &&
        moment(selectedEndDate).isBefore(
          moment(tempDate(selectedStartDate, 'Weekly'))
        )
      ) {
        enqueueSnackbar(
          'Please choose a date which is at least one week after the start date',
          { variant: 'error', autoHideDuration: 2500 }
        );
        return false;
      } else if (
        selectedType === 'MONTH' &&
        moment(selectedEndDate).isBefore(
          moment(tempDate(selectedStartDate, 'Monthly'))
        )
      ) {
        enqueueSnackbar(
          'Please choose a date which is at least one month after the start date',
          { variant: 'error', autoHideDuration: 2500 }
        );
        return false;
      } else if (
        selectedType === 'YEAR' &&
        moment(selectedEndDate).isBefore(
          moment(tempDate(selectedStartDate, 'Yearly'))
        )
      ) {
        enqueueSnackbar(
          'Please choose a date which is at least one year after the start date',
          { variant: 'error', autoHideDuration: 2500 }
        );
        return false;
      } else {
        return true;
      }
    } else if (scheduleType === 'One time') {
      if (
        !selectedTime ||
        selectedStartDate === 'Invalid date' ||
        !selectedTimeZone
      ) {
        enqueueSnackbar('Please fill all value', {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const handleSaveSchedule = async () => {
    let scheduleGrpVal = handleOnchange();
    setLoading(true);
    setSaveScheduleStatus(tabStatus?.SAVING);

    let checkForEdit = ruleValues.id;
    let payload = {
      ...ruleValues,
      editBeforeExec: checkForEdit ? true : false,
      ruleDefn: {
        ...ruleValues.ruleDefn,
        schedule: scheduleGrpVal?.[0]?.schedule,
      },
    };

    // setRule(payload);
    const API_ENDPOINT =
      ruleValues.status === 'PUBLISHED'
        ? RulesApi.updateSchedule({ orgId, payload, ruleId: ruleValues.id })
        : RulesApi.saveRules({ orgId, payload });

    try {
      let results = await API_ENDPOINT;
      setRule(results);
      setExpanded(false);
      setLoading(false);
      isScheduleSavedBefore.current = true;
      setSaveScheduleStatus(tabStatus?.SAVED);
      setSavedSchedule(true);
      setEditSchedule(false);
    } catch {
      setSaveScheduleStatus(tabStatus?.REJECTED);
      setSavedSchedule(false);
      setEditSchedule(true);
      setLoading(false);
    }
  };

  const deleteSchedule =async()=>{
    
    let scheduleGrpVal = handleOnchange();
    setLoading(true);
    let checkForEdit = ruleValues.id;
    let payload = {
      ...ruleValues,
      editBeforeExec: checkForEdit ? true : false,
      ruleDefn: {
        ...ruleValues.ruleDefn,
        schedule: scheduleGrpVal?.[0]?.schedule,
      },
    };
    try {
      let results = await RulesApi.deleteSchedule({ orgId, payload, ruleId: ruleValues.id });
      setRule(results);
      setExpanded(false);
      setLoading(false);
      isScheduleSavedBefore.current = true;
      setSaveScheduleStatus(tabStatus?.SAVED);
      setSavedSchedule(true);
      setEditSchedule(false);
    } catch {
      setSaveScheduleStatus(tabStatus?.REJECTED);
      setSavedSchedule(false);
      setEditSchedule(true);
      setLoading(false);
    }
  }

  const handleSaveScheduleCheck = () => {
    handleOnchange();
    if (!handleScheduleValidations()) return;
    handleSaveSchedule();
  };

  const savedStatusStatus = useMemo(() => {
    if (saveScheduleStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
        />
      );
    } else if (saveScheduleStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
        />
      );
    } else if (saveScheduleStatus === tabStatus?.REJECTED) {
      return <HighlightOffIcon style={{ color: 'red', fontSize: '22px' }} />;
    } else {
      return (
        <CheckCircleOutlineIcon style={{ color: 'grey', fontSize: '22px' }} />
      );
    }
  }, [saveScheduleStatus]);

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.containerBox}
        expanded={expanded}
        data-testid="schedule-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {savedStatusStatus}
              <Typography
                className={classes.heading}
                data-testid="schedule-title">
                Schedule
              </Typography>
              {!expanded && executionStatus !== 'IN_PROGRESS' && (
                <Box style={{ marginLeft: 'auto' }}>
                  {isScheduleSavedBefore.current ? (
                    <SecondaryButton onClick={handleEditSchedule} hasPermissions={hasCreatePermissions}>
                      Edit
                    </SecondaryButton>
                  ) : (
                    <SecondaryButton
                      data-testid="add-shedule-btn"
                      onClick={() => setExpanded(prevValue => !prevValue)} hasPermissions={hasCreatePermissions}>
                      Add schedule
                    </SecondaryButton>
                  )}
                </Box>
              )}
            </Box>

            {saveScheduleStatus === 'SAVED' && !expanded && (
              <Box className={classes.ScheduleGrpSummaryContainer}>
                {scheduleGrpValue?.map(schedule => {
                  return (
                    <Box display={'flex'} alignItems={'center'}>
                      <Box className={classes.ScheduleSummaryContainer}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          style={{ gap: '10px' }}>
                          <Typography variant="h6">
                            {schedule?.schedule['oneTime']
                              ? 'One time'
                              : 'Recurring'}
                          </Typography>
                        </Box>
                      </Box>
                      {!schedule?.schedule['oneTime'] && (
                        <Box className={classes.ScheduleSummaryContainer}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ gap: '10px' }}>
                            <Typography variant="h6">
                              {SCHEDULE_FREQUENCY_OPTIONS.find(
                                option =>
                                  option.id === schedule?.schedule['frequency']
                              )?.name || ''}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <Box className={classes.ScheduleSummaryContainer}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          style={{ gap: '10px' }}>
                          <Typography variant="h6">
                            <b>Start date :</b>{' '}
                            {schedule?.schedule['startDate']}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.ScheduleSummaryContainer}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          style={{ gap: '10px' }}>
                          <Typography variant="h6">
                            <b>Time : </b>{' '}
                            {formatTimeInHHMMWithComma(
                              new Date(
                                `${schedule?.schedule?.startDate} ${schedule.schedule.startTime}`
                              )
                            )}{' '}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.ScheduleSummaryContainer}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          style={{ gap: '10px' }}>
                          <Typography variant="h6">
                            <b>Time zone : </b> {schedule?.schedule['timeZone']}
                          </Typography>
                        </Box>
                      </Box>

                      {!schedule?.schedule['oneTime'] && (
                        <Box className={classes.ScheduleSummaryContainer}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ gap: '10px' }}>
                            <Typography variant="h6">
                              <b>End date : </b> {schedule?.schedule['endDate']}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            <Box
              className={classes.scheduleGrpContainer}
              style={{ display: 'flex' }}>
              <Grid item sm={12} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    style={{ display: 'flex', flexDirection: 'row' }}
                    aria-label="scheduleType"
                    name="scheduleType"
                    value={scheduleType}
                    onChange={(e, value) => {
                      setScheduleType(value);
                      handleOnchange();
                    }}>
                    {RADIO_OPTIONS.map(val => (
                      <FormControlLabel
                        // disabled={isEdit}
                        key={val}
                        value={val}
                        control={<Radio color="primary" />}
                        label={val}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {/* {ruleValues.status !== 'DRAFT' && ruleValues.isActive && (
                  <SecondaryButton style={{ float: 'right' }} onClick={deleteSchedule}>
                    Remove this schedule
                  </SecondaryButton>
                )} */}
              </Grid>

              {scheduleType === 'Recurring' && (
                <Grid item sm={12} xs={12}>
                  <Grid item sm={4} xs={4} className={classes.gridWidth}>
                    <Typography className={classes.formTitle}>
                      Frequency
                    </Typography>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      style={{ maxWidth: '100%' }}>
                      <Select
                        style={{
                          backgroundColor: 'white',
                          color: selectedType === '' ? '#667085' : 'initial',
                        }}
                        value={selectedType}
                        onChange={e => handleTypeSelection(e)}
                        className={classes.formTextField}
                        margin="dense"
                        placeholder="Select Frequency"
                        IconComponent={ExpandMoreIcon}
                        defaultValue=""
                        displayEmpty>
                        <MenuItem disabled value="">
                          Choose Frequency
                        </MenuItem>
                        {SCHEDULE_FREQUENCY_OPTIONS.map(i => {
                          return (
                            <MenuItem key={i.name} value={i.id}>
                              {i.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid item sm={4} xs={4} className={classes.gridWidth}>
                <Typography className={classes.formTitle}>
                  Start date
                </Typography>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  style={{ width: '100%' }}>
                  <KeyboardDatePicker
                    //   className={classes.calendarStyle}
                    style={{
                      width: '100%',
                      background: 'white',
                      marginTop: '1px',
                    }}
                    // disableToolbar
                    disablePast
                    size="small"
                    // variant="inline"
                    inputVariant="outlined"
                    format="MM-dd-yyyy"
                    margin="normal"
                    placeholder="Start date"
                    id="date-picker-inline"
                    // KeyboardButtonProps={{
                    //   'aria-label': 'change date',
                    // }}
                    value={selectedStartDateshow}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      // onFocus: e => {
                      //   setStartIsOpen(true);
                      // },
                    }}
                    // PopoverProps={{
                    //   disableRestoreFocus: true,
                    //   onClose: () => {
                    //     setStartIsOpen(false);
                    //   },
                    // }}
                    // InputProps={{
                    //   onFocus: () => {
                    //     setStartIsOpen(true);
                    //   },
                    // }}
                    // open={startisOpen}
                    // disabled={isEdit}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={4} xs={4} className={classes.gridWidth}>
                <Typography className={classes.formTitle}>Time</Typography>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  style={{ width: '100%' }}>
                  <KeyboardTimePicker
                    // className={classes.calendarStyle}
                    style={{
                      marginTop: '1px',
                      width: '100%',
                      background: 'white',
                    }}
                    //   label="Masked timepicker"
                    inputVariant="outlined"
                    size="small"
                    // variant="inline"
                    placeholder="08:00 AM"
                    // mask="__:__ _M"
                    value={selectedTimeshow}
                    onChange={e => changeTimeOnly(e)}
                    dateFunsUtils={DateFnsUtils}
                    keyboardIcon={<Alarm />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={4} xs={4} className={classes.gridWidth}>
                <Typography className={classes.formTitle}>Time zone</Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ maxWidth: '100%' }}>
                  <Select
                    style={{
                      backgroundColor: 'white',
                      color: selectedTimeZone === '' ? '#667085' : 'initial',
                    }}
                    value={selectedTimeZone}
                    onChange={e => handleTimeSZoneSelection(e)}
                    className={classes.formTextField}
                    margin="dense"
                    placeholder="Select time zone"
                    IconComponent={ExpandMoreIcon}
                    defaultValue=""
                    displayEmpty>
                    <MenuItem disabled value="">
                      Choose time zone
                    </MenuItem>
                    {timeZoneOption.map(i => {
                      return (
                        <MenuItem key={i.name} value={i.name}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {scheduleType === 'Recurring' && (
                <Grid item sm={4} xs={4} className={classes.gridWidth}>
                  <Typography className={classes.formTitle}>
                    End date
                  </Typography>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    style={{ width: '100%' }}>
                    <KeyboardDatePicker
                      //   className={classes.calendarStyle}
                      style={{
                        width: '100%',
                        background: 'white',
                        marginTop: '1px',
                      }}
                      // disableToolbar
                      disablePast
                      inputVariant="outlined"
                      // variant="inline"
                      format="MM-dd-yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      size="small"
                      placeholder="End date"
                      // KeyboardButtonProps={{
                      //   'aria-label': 'change date',
                      // }}
                      value={selectedEndDateshow}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                        // onFocus: e => {
                        //   setEndIsOpen(true);
                        // },
                      }}
                      // PopoverProps={{
                      //   disableRestoreFocus: true,
                      //   onClose: () => {
                      //     setEndIsOpen(false);
                      //   },
                      // }}
                      // InputProps={{
                      //   onFocus: () => {
                      //     setEndIsOpen(true);
                      //   },
                      // }}
                      // open={endisOpen}
                      // disabled={isEdit}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            </Box>
            <Box style={{ margin: '10px 0px' }}>
              <Box style={{ margin: '20px 0px', display: 'flex', gap: '10px' }}>
                <SecondaryButton
                  onClick={
                    isScheduleSavedBefore.current
                      ? closeWithoutEdit
                      : handleClearSchedule
                  }
                  disabled={
                    saveScheduleStatus === tabStatus?.SAVING ||
                    saveScheduleStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel-btn">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    scheduleGrpValue?.[0]?.schedule?.startDate === null ||
                    saveScheduleStatus === tabStatus?.SAVING ||
                    saveScheduleStatus === tabStatus?.SAVED ||
                    disable
                  }
                  onClick={handleSaveScheduleCheck}
                  data-testid="save-criteria">
                  {saveScheduleStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SimpleBackdrop open={loading} />
    </div>
  );
}
