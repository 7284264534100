import { Chip, Container, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import colors from '../../colors';
import DeleteIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    maxWidth: '100%',
  },
  textFieldLabel: {
    fontFamily: 'Inter',
    fontSize: "18px",
    fontWeight: 400,
    letterSpacing: 0,
    marginBottom: '16px',
    // lineHeight: theme.spacing(2),
    lineHeight: "16px"
  },
  textField: {
    letterSpacing: 0,
    '& .MuiOutlinedInput-input': {
      color: colors.PRIMARY_GRAY,
      fontFamily: 'Inter',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      padding: theme.spacing(1.5),
      opacity: 0.87,
      boxSizing: 'border-box',
      letterSpacing: theme.spacing(0),
      height: theme.spacing(5),
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.GRAY_SECONDARY}`,
    },
    '& .MuiOutlinedInput-root.Mui-disabled':{
      backgroundColor: colors.SUBTLE_GRAY,
    },
    '& .MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.BLUE} !important`, 
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.SUBTLE_GRAY}`,
    },
    '& .MuiInputBase-multiline': {
      padding: '1px 0px',
    },
  },
  textFieldRoot: {
    width: '100%',
  },
  chipsContainer: { 
    marginTop: theme.spacing(0.5), 
    marginBottom: theme.spacing(1.25),
  },
  chip: {
    backgroundColor: colors.WHITE_CHINESE,
    fontSize: theme.spacing(1.75),
    color: colors.BLACK_B500,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  required: { 
    color: colors.RED, 
    fontSize: theme.spacing(2), 
    fontWeight: 500, 
    marginLeft: theme.spacing(0.25), 
  },
  maxWidth: { 
    maxWidth: '100%'
   },
}));

const MultiInputTextField = (props) => {
  const {
    variant,
    fullWidth,
    color,
    label,
    multiline,
    placeHolder,
    numberOfLines,
    value,
    onChange,
    required = false,
    error,
    onFocus,
    onBlur,
    ...rest
  } = props;
  const classes = useStyles();
  const [values, setValues] = useState(value || []);
  const [inputValue, setInputValue] = useState('');

  const keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      const val = e.target.value;
      if (!val.trim()) {
        return;
      }
      const finalSet = [...values, val];
      setValues(finalSet);
      onChange(finalSet);
      setInputValue('');
    }
  };

  const handleDelete = (index) => {
    const newArr = values.filter((item, i) => i !== index);
    setValues(newArr);
    onChange(newArr);
  };

  const handleOnBlur = () => {
    onBlur && onBlur();
    if (inputValue.trim()) {
      const finalSet = [...values, inputValue.trim()];
      setValues(finalSet);
      onChange(finalSet);
      setInputValue('');
    }
  };

  return (
    <Container className={classes.root}>
      {label && (
        <p className={classes.textFieldLabel}>
          {label} <span className={classes.required}>{required ? '*' : ''}</span>
        </p>
      )}
      <div className={classes.chipsContainer}>
        {values.map((item, index) => {
          return (
            <Chip
              classes={{ root: classes.maxWidth }}
              key={item + index}
              className={classes.chip}
              label={item}
              deleteIcon={<DeleteIcon style={{ height: 20, width: 20, color: colors.BLACK }} />}
              onDelete={() => handleDelete(index)}
              color="primary"
            />
          );
        })}
      </div>
      <TextField
        classes={{
          root: classes.textFieldRoot,
        }}
        variant={variant || 'outlined'}
        fullWidth={fullWidth}
        color={color || 'secondary'}
        // value={value}
        value={inputValue}
        multiline={Boolean(multiline)}
        rows={numberOfLines}
        onKeyPress={(e) => keyPressHandler(e)}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeHolder}
        className={classes.textField}
        onFocus={() => onFocus && onFocus()}
        onBlur={ handleOnBlur}
        error={error}
        {...rest}
      />
    </Container>
  );
};

export default MultiInputTextField;
