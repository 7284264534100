import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  AlertDialog,
  BackendSearchTextField,
  PrimaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import RulesGrid from './RulesGrid';
import RulesViewDetails from './RulesViewDetailsPage';
import RulesEngineApi from 'services/api/RuleEngineApi';
import FilterDropDown from 'components/FilterDropDown';
import CreateRuleModal from './CreateRuleModal';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import DataPartnersApi from 'services/api/DataPartnersApi';
import NotFoundPage from 'components/NotFoundPage';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import {
  CLOSE_ALERT_DESCRIPTION,
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
  RulesStatusFilterMenuItems,
} from 'shared/constants';
import CriteriaApi from 'services/api/CriteriaApi';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
}));

const RulesPage = ({permissions}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [flyout, setFlyout] = useState({ open: false });
  const [flyoutData, setFlyoutData] = useState('');
  const [isCreateRule, setIsCreateRule] = useState(false);

  const [statusFilter, setStatusFilter] = useState('all');
  const [affiliateList, setAffiliateList] = useState([]);
  const [affiliate, setAffiliate] = useState('all');
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);
  const [fetchedData, setFetchedData] = useState('');

  const [rulesId, setRulesId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [lastRunDate, setLastRunDate] = useState('');
  const [ruleTransactionId, setRuleTransactionId] = useState('');
  const [executionStatus, setExecutionStatus] = useState('');
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const trackUnsavedChangesRef = useRef(false);
  const [criteriaLoader, setCriteriaLoader] = useState(false);
  const {CREATE} = useHasPermissions({availablePermissions:permissions});

  const formattedAffiliate = affiliateList?.map(affiliate => {
    return { label: affiliate.name, value: affiliate.name };
  });
  const affiliateId = affiliateList?.find(val => val.name === affiliate)?.id;

  const listOfAffiliate = [
    { label: 'All', value: 'all' },
    ...formattedAffiliate,
  ];
  const [openCreateRuleModal, setOpenCreateRuleModal] = useState(false);
  const [criteriaValues, setAllCreteriaValues] = useState([]);

  const dataForAccondian = criteriaValues
    ?.map(item => {
      return item?.map(subItem => {
        return subItem?.length > 1 && subItem?.[1];
      });
    })
    ?.flat(2);

  const orgId = sessionStorage.getItem("orgId");

  const onToggle = () => {
    setFlyout({ open: false });
  };

  const handleCreateRuleModal = val => {
    setOpenCreateRuleModal(val);
  };

  const handleUnsavedChanges = () => {
    if (trackUnsavedChangesRef.current()) {
      setOpenAlertDialog(true);
    } else {
      setOpenCreateRuleModal(false);
      setAllCreteriaValues([]);
    }
  };

  const handleClose = () => {
    setOpenCreateRuleModal(false);
  };

  const getAllRules = async () => {
    setLoading(true);
    try {
      const results = await RulesEngineApi.getAllRulesList({
        orgId: orgId,
        searchName: searchValue,
        searchStatus: statusFilter === 'all' ? '' : statusFilter ?? '',
        searchAffId: affiliateId === 'all' ? '' : affiliateId ?? '',
        offset: offset,
      });
      setFilteredMembers(results?.data);
      setTotalCount(results.totalItems);
      setOnloadData(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    getAllRules();
  }, [offset, searchText, statusFilter, affiliate]);

  const getAffiliateList = useCallback(async () => {
    setLoading(true);
    try {
      const results = await DataPartnersApi.getAllPartners(orgId);
      const data = results.filter(item => item.isDeleted !== 1);
      const options = data.map(fItem => ({ id: fItem.id, name: fItem.name }));
      setAffiliateList(options);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      getAffiliateList();
    }
  }, [orgId, getAffiliateList]);

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
  };

  const refetchData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
    if (searchValue === '' && searchText === '' && offset === 0) {
      getAllRules();
    }
  };

  const handleCreateClick = () => {
    setFetchedData('');
    handleCreateRuleModal(true);
    setIsCreateRule(true);
    setLastRunDate('');
    setRuleTransactionId('');
    setExecutionStatus('');
  };

  const getCriteriaObjects = async criteriaId => {
    return new Promise(async resolve => {
      const results = await CriteriaApi.criteriaHistory({
        orgId,
        criteriaId,
      });
      setTimeout(() => {
        resolve(results);
      }, 0);
    });
  };

  const sleep = time => {
    return new Promise(resolved => {
      setTimeout(() => resolved(), time);
    });
  };

  const getCriteriaHistory = async results => {
    const criteriaIdforRulesList = results?.ruleDefn?.criteria;
    setCriteriaLoader(true);
    const mainList = [];
    for (const criteriaObj of criteriaIdforRulesList) {
      const listItem = [];
      for (const obj of criteriaObj?.criteriaGroup) {
        const val = await getCriteriaObjects(obj?.criteriaId);
        await sleep(1000);
        listItem.push(val);
      }
      mainList.push(listItem);
    }
    setCriteriaLoader(false);
    setAllCreteriaValues(mainList?.filter(item => item));
  };

  const onViewClick = async data => {
    setLoading(true);
    try {
      const results = await RulesEngineApi.getRuleDetails({
        orgId,
        ruleId: data.id,
      });

      if (
        results.ruleDefn?.actions?.[0]?.labelCode &&
        results.ruleDefn?.actions?.[0]?.createLabel
      ) {
        results.ruleDefn.actions[0].createLabel = false;
      }
      setRulesId(data);
      setFetchedData(results);
      setLastRunDate(data.lastRunDatetime);
      setRuleTransactionId(data.ruleTransactionId);
      setExecutionStatus(data.ruleExecutionStatus || data.status);
      setLoading(false);
      handleCreateRuleModal(true);
      getCriteriaHistory(results);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const statusFilterOptions = {
    options: RulesStatusFilterMenuItems,
    value: statusFilter,
    changeHandler: ev => {
      setIsInitialRender(false);
      setStatusFilter(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const affiliateFilterOptions = {
    options: listOfAffiliate,
    value: affiliate,
    changeHandler: ev => {
      setIsInitialRender(false);
      setAffiliate(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const handleCreateRule = () => {
    handleCreateClick();
    setSearchText('');
    setSearchValue('');
    setAffiliate('all');
    setStatusFilter('all');
    if (
      setFilteredMembers?.length === 0 &&
      searchValue === '' &&
      searchText === '' &&
      setAffiliate('all') &&
      setStatusFilter('all')
    ) {
      getAllRules();
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {(!isInitialRender || filteredMembers?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    label="Search by rule name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                  data-testid="search-rule"
                >
                  Search
                </SecondaryButton>
                <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                  <FilterDropDown
                    {...statusFilterOptions}
                    filterType="Status:"
                  />
                </div>
                <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                  <FilterDropDown
                    {...affiliateFilterOptions}
                    filterType="Affiliate:"
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                }}
              >
                <PrimaryButton onClick={handleCreateRule} hasPermissions={CREATE}>
                  Create a rule
                </PrimaryButton>
              </div>
              {filteredMembers?.length > 0 && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <RulesGrid
                    apiLoading={loading}
                    rowData={filteredMembers}
                    onView={onViewClick}
                  />
                  <PaginationComponent
                    numberOfRowsPerPage={10}
                    count={totalCount}
                    onPageClick={paginationClick}
                    page={offset}
                  />
                </Grid>
              )}
            </>
          )}
        </div>
      </Grid>
      <SimpleBackdrop open={loading} />
      {flyout.open && (
        <RulesViewDetails
          onToggle={onToggle}
          data={flyoutData}
          title={flyoutData.name}
          {...flyout}
        />
      )}{' '}
      {filteredMembers?.length === 0 && isInitialRender && OnloadData && (
        <NotFoundPage
          title={INITIAL_RENDER_NO_DATA_PAGE.RULE_TITLE}
          titleContent={INITIAL_RENDER_NO_DATA_PAGE.RULE_TITLE_CONTENT}
          content={INITIAL_RENDER_NO_DATA_PAGE.RULE_CONTENT}
          showButton={CREATE}
          onNotFoundClick={handleCreateRuleModal}
        />
      )}
      {filteredMembers?.length === 0 && !isInitialRender && OnloadData && (
        <Box style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.RULE_TITLE}
            content={EMPTY_STATE_PAGE.RULE_CONTENT}
            showButton={false}
          />
        </Box>
      )}
      {openCreateRuleModal && (
        <FullScreenDialog
          open={openCreateRuleModal}
          handleClose={handleUnsavedChanges}
        >
          <CreateRuleModal
            handleCreateRuleModal={handleCreateRuleModal}
            handleClose={handleClose}
            fetchedData={fetchedData}
            isCreateRule={isCreateRule}
            setIsCreateRule={setIsCreateRule}
            orgId={orgId}
            rulesId={rulesId}
            refetchData={refetchData}
            trackUnsavedChangesRef={trackUnsavedChangesRef}
            lastRunDate={lastRunDate}
            ruleTransactionId={ruleTransactionId}
            executionStatus={executionStatus}
            criteriaHistoryForRules={dataForAccondian}
            criteriaLoader={criteriaLoader}
            hasCreatePermissions={CREATE}
          />
        </FullScreenDialog>
      )}
      {openAlertDialog && (
        <AlertDialog
          open={openAlertDialog}
          description={CLOSE_ALERT_DESCRIPTION}
          title="Alert"
          onClose={() => setOpenAlertDialog(false)}
          onConfirm={() => {
            setOpenCreateRuleModal(false);
            setOpenAlertDialog(false);
          }}
        />
      )}
    </>
  );
};

export default RulesPage;
