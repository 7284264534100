import React, { useState, useEffect, useCallback } from 'react';
import LobGrid from './LOBGrid';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';

const LobPagination = props => {
  const {
    numberOfRowsPerPage,
    rowData,
    onDelete,
    onView,
    showActionCell,
    hasReadPermissions,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState(rowData);

  const onPageClick = useCallback(
    pageNumber => {
      const lastIndex = numberOfRowsPerPage * pageNumber;
      const initialIndex = lastIndex - numberOfRowsPerPage;
      const tableInput = rowData.slice(initialIndex, lastIndex);
      const paginatedData = tableInput ? tableInput : rowData;
      setTableData(paginatedData);
      setPageNumber(pageNumber - 1);
    },
    [numberOfRowsPerPage, rowData]
  );

  useEffect(() => {
    onPageClick(1);
  }, [onPageClick]);

  return (
    <>
      <LobGrid
        rowData={tableData}
        onView={onView}
        onDelete={onDelete}
        showActionCell={showActionCell}
        hasReadPermissions={hasReadPermissions}
      />
      {rowData.length > numberOfRowsPerPage && (
        <PaginationComponent
          numberOfRowsPerPage={numberOfRowsPerPage}
          count={rowData.length}
          onPageClick={onPageClick}
          page={pageNumber}
        />
      )}
    </>
  );
};

export default LobPagination;
