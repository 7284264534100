import React from 'react';
import { Avatar, Typography } from '@material-ui/core';

const AvatarImg = ({ userName, src, style }) => {
  if (!userName) return null;

  let initials = userName
    .split(' ')
    .map(item => item[0])
    .join('');

  return (
    <React.Fragment>
      <Avatar
        alt={userName ?? 'Avatar'}
        src={src}
        style={{
          width: '24px',
          height: '24px',
          marginRight: 2,
          backgroundColor: '#00A0DF',
          color: 'black',
          ...style,
        }}>
        <Typography style={{ fontSize: '11px' }}>{initials}</Typography>
      </Avatar>
    </React.Fragment>
  );
};

export default AvatarImg;
