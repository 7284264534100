import React from 'react';
import SideMenu from './SideMenu';
import { Dialog } from '@mui/material';
import { useState } from 'react';
import UserDetailsForm from './UserDetailsForm';
import AssignRoleForm from './AssignRoleForm';
import PermissionForm from './PermissionForm';
import CloseIcon from '@material-ui/icons/Close';
import './InviteUser.scss';
import {
  AlertDialog,
  PrimaryButton,
  SecondaryButton,
} from 'components/widgets';
import UsersDetailPage from './UsersDetailPage';
import VerifyDetails from './VerifyDetails';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CustomizedDialog from 'components/CustomizedDialog';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    borderRadius: theme.spacing(10),
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 300,
    marginTop: '10px',
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    alignItems: 'center',
    borderRadius: '2rem',

    // marginBottom: theme.spacing(2),
  },
  customStyles: {
    maxWidth: '500px',
  },
}));

const InviteUser = props => {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0);
  const [userData, setUserData] = useState({
    firstName: { value: '', error: false },
    lastName: { value: '', error: false },
    emailId: { value: '', error: false },
    organizationName: { value: '', error: false },
    selectedAffiliate: [],
  });

  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    role: '',
    affiliateLobMap: [],
  });
  const [affiliateMapping, setAffiliateMapping] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [permissionData, setPermissionData] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [newRoleId, setNewRoleId] = useState();

  const [isEdit, setIsEdit] = useState();

  const incrementStep = () => {
    if (!isEdit) {
      setCurrentStep(prev => prev + 1);
    } else {
      setIsEdit(false);
      setCurrentStep(3);
    }
  };

  const onCloseConfirm = () => {
    setOpenAlertDialog(false);
    props?.setInviteCard(false);
    setUserData({});
  };

  const decrementStep = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleClose = () => {
    if (
      userData?.firstName?.value ||
      userData?.lastName?.value ||
      userData?.emailId?.value
    ) {
      setOpenAlertDialog(true);
    } else {
      props?.setInviteCard(false);
    }
  };

  const onCloseClick = () => {
    setOpenAlertDialog(false);
  };

  const changeStep = val => {
    setCurrentStep(val);
  };
  const editUserDetails = val => {
    setCurrentStep(val);
    setIsEdit(true);
  };

  const renderSelectedStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <UserDetailsForm
            incrementStep={incrementStep}
            userData={userData}
            setUserData={setUserData}
            affiliateMapping={affiliateMapping}
            setAffiliateMapping={setAffiliateMapping}
            payload={payload}
            setPayload={setPayload}
            externalUser={props?.externalUser}
            isEdit={isEdit}
          />
        );
      case 1:
        return (
          <AssignRoleForm
            incrementStep={incrementStep}
            setSelectedRoleValue={setRoleData}
            roleData={roleData}
            payload={payload}
            setPayload={setPayload}
            setNewRoleId={setNewRoleId}
          />
        );
      case 2:
        return (
          <PermissionForm
            incrementStep={incrementStep}
            roleDescription={roleData?.description}
            roleName={roleData?.roleName}
            roleId={roleData?.roleId}
            isView={
              (roleData?.hasPermission &&
                roleData?.roleId !== Number(newRoleId)) ||
              roleData?.isStatic
            }
          />
        );
      case 3:
        return (
          <VerifyDetails
            incrementStep={incrementStep}
            userInfoPayload={payload}
            setInviteCard={props?.setInviteCard}
            setRefetchInternalUsers={props?.setRefetchInternalUsers}
            setRefetchExternalUsers={props?.setRefetchExternalUsers}
            roleId={roleData?.roleId}
            changeStep={changeStep}
            editUserDetails={editUserDetails}
            roleData={roleData}
            payload={payload}
            affiliates={props?.affiliates}
            lobs={props?.lobs}
            isExternal={props?.externalUser}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={true} className="invite-user-form-dialog">
      <div className="closeIcon">
        <CloseIcon onClick={handleClose} />
      </div>
      <span className="backButton">
        {currentStep > 0 && (
          <SecondaryButton
            size="large"
            onClick={decrementStep}
            color="primary"
            className={'backBtn'}
          >
            Go Back
          </SecondaryButton>
        )}
      </span>
      <div className="invite-user-form">
        <SideMenu currentStep={currentStep} setCurrentStep={setCurrentStep} />
        <div className="rightPanel">{renderSelectedStep()}</div>
      </div>
      {openAlertDialog && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: '1rem',
            },
          }}
          open={openAlertDialog}
          onClose={() => setOpenAlertDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.closeContainer}>
            <div
              id="alert-dialog-title"
              style={{
                fontSize: 20,
                padding: 20,
              }}
            >
              <Typography style={{ fontWeight: 600 }} variant="h3">
                Close screen{' '}
              </Typography>
            </div>
            <IconButton
              onClick={() => onCloseClick()}
              style={{ display: 'flex-end' }}
            >
              <CloseIcon />
            </IconButton>
          </div>{' '}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className={classes.subTitle}>
                Closing this will erase all your progress and you'll need to
                start from the begining. Are you sure you would like to proceed
                ahead?{' '}
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: '6px 20px' }}>
            <PrimaryButton onClick={() => setOpenAlertDialog(false)}>
              No
            </PrimaryButton>
            <SecondaryButton onClick={onCloseConfirm}>Yes </SecondaryButton>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default InviteUser;
