import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Checkbox, Chip, Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { SecondaryButton } from 'components/widgets';
import CustomizedDialog from 'components/CustomizedDialog';
import MphLogo from '../../../assets/mphLogo.png';

const useStyles = makeStyles(theme => ({
  accordionRoot: {
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  },
  solutionsHeading: {
    color: '#344054',
    fontWeight: 500,
    fontSize: '12px',
  },
  featureHeading: {
    color: '#667085',
    fontWeight: 400,
    fontSize: '14px',
  },
  warningChip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#FFEAEF',
    borderColor: '#DB0032',
    color: '#DB0032',
    textTransform: 'uppercase',
    height: '16px',
    padding: '5px',
  },
  secondaryBtn: {
    padding: 0,
    marginLeft: 'auto',
  },
  domainPopup: {
    minHeight: '250px',
    minWidth: '732px',
  },
}));

const PERMISSION = [
  {
    id: 2,
    displayName: 'Create/Edit',
    code: 'P001',
  },
];

const ManageOrgFeature = ({
  classes,
  featureIdx,
  handleFeaturePermission,
  isView,
  solutionIdx,
  subFeature,
  subFeatureIdx,
}) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography
          className={classes.featureHeading}
          style={{ fontWeight: 400, fontSize: '14px' }}
        >
          {subFeature.subFeatureName}
        </Typography>
      </Grid>
      {PERMISSION?.map(permission => {
        const isSelected = Boolean(subFeature.selectedPermissions?.length);
        return (
          <Grid item xs={2} key={permission.code}>
            {isView ? (
              <DoneIcon
                style={{
                  fontSize: '24px',
                  color: isSelected ? '#005DB9' : 'white',
                }}
              />
            ) : (
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: 0 }}
                onChange={() =>
                  handleFeaturePermission(
                    solutionIdx,
                    featureIdx,
                    subFeatureIdx,
                    permission.code,
                    isSelected ? 'remove' : 'add'
                  )
                }
                checked={isSelected || false}
              />
            )}
          </Grid>
        );
      })}
    </>
  );
};

const ManageOrgHeading = ({ classes, permission }) => (
  <Grid container spacing={3} style={{ borderBottom: '1px solid #E4E7EC' }}>
    <Grid item xs={4}>
      <Typography className={classes.solutionsHeading}>Module</Typography>
    </Grid>
    <Grid item xs={2} />
    <Grid item xs={2} key={permission.code}>
      <Typography className={classes.solutionsHeading}>
        {permission[0]?.displayName}
      </Typography>
    </Grid>
  </Grid>
);

const ManageOrganizationPermission = ({
  handleFeaturePermission,
  isView,
  manageOrg,
  solutionIdx,
  permissionLimit,
  handleSolutionPermission,
  solutionId,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [clearWarningPopupOpen, setClearWarningPopupOpen] = useState(false);
  const permissionCount =
    Object.values(permissionLimit || {})?.reduce(
      (cur, acc) => (cur += acc),
      0
    ) || 0;
  const btnTitle =
    permissionCount !== 0 ? (expanded ? 'Clear' : 'Edit') : 'Add';

  const handleClearPopup = () => {
    setClearWarningPopupOpen(prev => !prev);
  };

  const handleClearPermissions = () => {
    handleSolutionPermission('', 'clear', solutionId);
    handleClearPopup();
    setExpanded(prev => !prev);
  };

  return (
    <React.Fragment key={solutionId}>
      <Accordion
        style={{
          backgroundColor:
            permissionCount === 0 && !expanded ? '#F5F5F5' : 'white',
          marginBottom: '10px',
        }}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            onClick: () => setExpanded(prev => !prev),
          }}
        >
          <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
            <img src={MphLogo} alt="mph-logo" height={'20px'} width={'24px'} />
            <Typography variant="h4">
              {manageOrg.features?.[0]?.featureName}
            </Typography>
            {!permissionCount && (
              <Chip
                variant="outlined"
                size="medium"
                label={'No Permissions'}
                className={classes.warningChip}
              />
            )}
          </Box>

          {!isView && (
            <SecondaryButton
              className={classes.secondaryBtn}
              onClick={
                btnTitle === 'Clear'
                  ? handleClearPopup
                  : () => setExpanded(prev => !prev)
              }
            >
              {btnTitle}
            </SecondaryButton>
          )}
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1, borderRight: '1px solid #E4E7EC' }}>
            <ManageOrgHeading classes={classes} permission={PERMISSION} />
            {manageOrg.features?.[0]?.subFeatures?.map(
              (subFeature, subFeatureIdx) =>
                subFeatureIdx < 3 && (
                  <div
                    style={{
                      flexGrow: 1,
                      padding: '16px 0px',
                      marginTop: '10px',
                    }}
                    key={subFeature.subFeatureId}
                  >
                    <Grid container spacing={2}>
                      <ManageOrgFeature
                        classes={classes}
                        isView={isView}
                        subFeature={subFeature}
                        subFeatureIdx={subFeatureIdx}
                        featureIdx={0}
                        solutionIdx={solutionIdx}
                        handleFeaturePermission={handleFeaturePermission}
                      />
                    </Grid>
                  </div>
                )
            )}
          </div>
          <div style={{ flexGrow: 1, padding: '0px 20px' }}>
            <ManageOrgHeading classes={classes} permission={PERMISSION} />
            {manageOrg.features?.[0]?.subFeatures?.map(
              (subFeature, subFeatureIdx) =>
                subFeatureIdx >= 3 && (
                  <div
                    style={{
                      flexGrow: 1,
                      padding: '16px 0px',
                      marginTop: '10px',
                    }}
                    key={subFeature.subFeatureId}
                  >
                    <Grid container spacing={3}>
                      <ManageOrgFeature
                        classes={classes}
                        isView={isView}
                        subFeature={subFeature}
                        subFeatureIdx={subFeatureIdx}
                        featureIdx={0}
                        solutionIdx={solutionIdx}
                        handleFeaturePermission={handleFeaturePermission}
                      />
                    </Grid>
                  </div>
                )
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <CustomizedDialog
        title={'Clear permissions'}
        open={clearWarningPopupOpen}
        customStyles={classes.domainPopup}
        primaryBtnTitle="Clear"
        handleOnSave={handleClearPermissions}
        handleClickClose={handleClearPopup}
      >
        <Typography>{`Are you sure you want to clear ${manageOrg.features?.[0]?.featureName} permissions ?`}</Typography>
      </CustomizedDialog>
    </React.Fragment>
  );
};

export default ManageOrganizationPermission;
