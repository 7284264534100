import React, { useState } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  subMenuBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 64,
    '&:hover': {
      // backgroundColor: '#ddd',
    },
  },
  itemWithSubMenu: {
    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%',
    minWidth: 150,
  },
  icon: {
    color: '#BDBDBD',
    fontSize: 32,
    marginTop: '10px',
  },
  title: {
    marginLeft: '-8px',
  },
  active: {
    borderLeftColor: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
  loading: {
    borderLeftColor: theme.palette.background.paper,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const NavBar = ({
  className,
  href,
  icon: Icon,
  title,
  history,
  showOnlyIcon,
  isActive = false,
  onMobileClose,
  loading,
  subMenu = null,
  ...rest
}) => {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  function handleClick(href) {
    onMobileClose();
    history.push(href);
  }

  const handleExpandClick = () => {
    setExpand(!expand);
  };

  return (
    <>
      <ListItem
        className={
          subMenu
            ? clsx(
                classes.item,
                {
                  [classes.itemWithSubMenu]: subMenu
                    .map(item => item.href)
                    .includes(location.pathname),
                  [classes.loading]: loading,
                },
                className
              )
            : clsx(
                classes.item,
                { [classes.active]: isActive, [classes.loading]: loading },
                className
              )
        }
        disableGutters
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          disabled={loading}
          className={classes.button}
          onClick={() =>
            subMenu === null ? handleClick(href) : handleExpandClick()
          }
          to={href}
        >
          {Icon && <Icon className={classes.icon} />}
          {!showOnlyIcon && <span className={classes.title}>{title}</span>}
        </Button>
      </ListItem>
      {subMenu !== null && expand && (
        <Box className={classes.subMenuBox}>
          {subMenu.map((item, index) => (
            <ListItem
              className={clsx(
                classes.subMenuItem,
                {
                  [classes.active]: location.pathname.indexOf(item.href) !== -1,
                  [classes.loading]: loading,
                },
                className
              )}
              disableGutters
              {...rest}
            >
              <Button
                disabled={loading}
                className={classes.button}
                onClick={() => handleClick(item.href)}
                to={item.href}
              >
                {!showOnlyIcon && (
                  <span className={classes.title}>{item.title}</span>
                )}
              </Button>
            </ListItem>
          ))}
        </Box>
      )}
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavBar;
