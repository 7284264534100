import clsx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: theme.spacing(2),
    margin: theme.spacing(1),
    // marginBottom: theme.spacing(3.5),
    padding: theme.spacing(2),
    border: '1px solid #E5E5E5',
    borderColor: theme.palette.secondary.light,
    // borderColor: 0.5,
    // boxShadow: '2px 2px 24px rgba(75, 75, 75, 0.08)',
    '&:hover': {
      cursor: 'pointer',
      // textDecoration: 'underline',
      boxShadow: '2px 2px 24px rgba(75, 75, 75, 0.08)',
    },
  },
}));

function Card({ className, style, children, ...otherProps }) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      style={style}
      {...otherProps}>
      {children}
    </div>
  );
}

export default Card;
