import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M17.2549 17.8076V23.9326C17.2549 24.459 17.7334 24.9375 18.3076 24.9375H24.4326C24.959 24.9375 25.4375 24.459 25.4375 23.9326V17.8076C25.4375 17.2334 24.959 16.7549 24.4326 16.7549H18.3076C17.7334 16.7549 17.2549 17.2334 17.2549 17.8076ZM8.06738 24.9375H14.1924C14.7666 24.9375 15.2451 24.459 15.2451 23.9326V17.8076C15.2451 17.2334 14.7666 16.7549 14.1924 16.7549H8.06738C7.54102 16.7549 7.0625 17.2334 7.0625 17.8076V23.9326C7.0625 24.459 7.54102 24.9375 8.06738 24.9375ZM7.0625 7.56738V13.6924C7.0625 14.2666 7.54102 14.7451 8.06738 14.7451H14.1924C14.7666 14.7451 15.2451 14.2666 15.2451 13.6924V7.56738C15.2451 7.04102 14.7666 6.5625 14.1924 6.5625H8.06738C7.54102 6.5625 7.0625 7.04102 7.0625 7.56738ZM20.2695 5.94043L15.9629 10.2949C15.5322 10.6777 15.5322 11.2998 15.9629 11.7305L20.2695 16.0371C20.7002 16.4678 21.3223 16.4678 21.7051 16.0371L26.0596 11.7305C26.4424 11.2998 26.4424 10.6777 26.0596 10.2949L21.7529 5.94043C21.3223 5.55762 20.7002 5.55762 20.2695 5.94043Z" />
    </SvgIcon>
  );
};

export default Icon;
