import { combineReducers } from 'redux';
import * as actionTypes from '../actions/actionTypes';

import authReducer from './auth';
import organizationsReducer from './organizations';
import slideoutReducer from './slideouts';
import selectedOrgReducer from './selectedOrganization';
import locationStatesReducer from './locationStates';
import addressTypesReducer from './addressTypes';
import applicationsReducer from './applications';
import userInfoReducer from './userInfo';
import memberReducer from './members';
import dynamicTemplateSlice from 'containers/DynamicRenderOfRecords/dynamicTemplate';

const appReducer = combineReducers({
  auth: authReducer,
  organizations: organizationsReducer,
  slideouts: slideoutReducer,
  selectedOrganization: selectedOrgReducer,
  locationStates: locationStatesReducer,
  addressTypes: addressTypesReducer,
  applications: applicationsReducer,
  userInfo: userInfoReducer,
  members: memberReducer,
  templates: dynamicTemplateSlice,
});

const rootReducer = (state, action) => {
  if (
    action.type === actionTypes.AUTH_LOGOUT ||
    action.type === actionTypes.AUTH_INVALID_SESSION
  ) {
    state = {
      addressTypes: {
        ...state.addressTypes,
      },
      locationStates: {
        ...state.locationStates,
      },
      // phoneCodes: {
      //   ...state.phoneCodes,
      // },
      // professions: {
      //   ...state.professions,
      // },
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
