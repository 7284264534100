import React, { useState, useEffect, useCallback } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/widgets';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LeftImage from '../../assets/CloseUpFingerPressingVirtualButton.svg';
import { DEVELOPER_SIGNUP_CONSTANTS } from 'shared/constants';
import DevSignupPage from './DevSignupPage';
import PartnerApi from 'services/api/PartnerApi';
import { getDomainName } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
  },
  imageBox: {
    width: '33%',
    backgroundColor: '#E2F1FC',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 40px 0px',
    justifyContent: 'space-between',
  },
  rightBox: {
    width: '67%',
    backgroundColor: '#FFFFFF',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30% 0px 0px 20%',
    gap: '10px',
  },
  title: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '40px',
    color: '#101828',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054',
  },
  buttonBox: {
    display: 'flex',
    gap: '20px',
    marginTop: '15px',
  },
}));

const DevSignupLanding = ({onLogin}) => {
  const [flag, setFlag] = useState(false);
  const classes = useStyles();

  const getPartnerConfigs = useCallback(() => {
    async function getConfigs() {
      try {
        const details = await PartnerApi.partnerConfiguration(getDomainName(window.location.href));
        sessionStorage.setItem('devSignUpOrgId', details.orgId);
      } catch (e) {
        console.log(e);
      }
    }
    getConfigs();
  }, []);



  useEffect(() => {
    getPartnerConfigs()
  }, [getPartnerConfigs])

  return (
    <Box>
      {flag ? (
        <DevSignupPage onLogin={onLogin} />
      ) : (
        <Box className={classes.container}>
          <Box className={classes.imageBox}>
            <Box className={classes.orgLogo}>
            <img src={'https://mph-dev-campaign-email-assets.s3.amazonaws.com/default/mphlogo.png'} alt='Org logo' style={{maxWidth: '180px'}}/>
              </Box>
            <Box>
            <img src={LeftImage} alt="dev signup left icon" />
            </Box>
          </Box>
          <Box className={classes.rightBox}>
            <Box className={classes.contentBox}>
              <Typography className={classes.title}>
                {DEVELOPER_SIGNUP_CONSTANTS.LANDING_TITLE}
              </Typography>
              <Typography className={classes.subtitle}>
                {DEVELOPER_SIGNUP_CONSTANTS.LANDING_SUBTITLE}
              </Typography>
              <Box className={classes.buttonBox}>
                <PrimaryButton onClick={() => onLogin()} >Login</PrimaryButton>
                <SecondaryButton onClick={() => setFlag(true)}>
                  Sign Up
                </SecondaryButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DevSignupLanding;
