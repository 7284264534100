import { TextField as MuiTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback } from 'react';
import clsx from 'classnames';

const useStyles = makeStyles(theme => ({
  textField: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 90,
  },
}));

function TextField({
  name,
  touched,
  onChange,
  trim = false,
  error,
  maxLength,
  placeholder,
  disabled,
  upperCase = false,
  dataTestId,
  customClass,
  customInputFieldStyles,
  ...otherProps
}) {
  const classes = useStyles();

  const hasError = error && touched;

  const errorText = error && touched && error;

  const onChangeEventHandler = useCallback(
    event => {
      let text = event.target.value;

      if (trim) {
        text = text.trim();
      }

      if (upperCase) {
        text = text.toUpperCase();
      }

      onChange(text);
    },
    [trim, upperCase, onChange]
  );
  return (
    <MuiTextField
      className={clsx(classes.textField, customClass)}
      id={name}
      margin="dense"
      variant="outlined"
      name={name}
      error={hasError}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChangeEventHandler}
      helperText={errorText}
      inputProps={{
        maxLength,
        'data-testid': dataTestId || '',
        className:customInputFieldStyles
      }}
      {...otherProps}
    />
  );
}

export default TextField;
