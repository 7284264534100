import {
  FooterActions,
  SimpleBackdrop,
  ReadonlyField,
} from 'components/widgets';
import { useEffect, useState, useRef } from 'react';
import ApplicationApi from 'services/api/ApplicationApi';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  REQUEST_MODAL,
  RESOURCE_NAMES,
  REUSABLE_MODAL,
  VIEW_APPLICATION_CONTENT,
} from 'shared/constants';
import clsx from 'classnames';
import { useUserPermissions } from 'utils/userPermissions';
import { useDispatch } from 'react-redux';
import * as actions from 'store/actions';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import ApplicationFullScreenDialog from 'components/widgets/FullScreenDialog/ApplicationFullScreenDialog';
import AddResource from './AddResource';
import ReusableModal from './ReusableModal';
import './ViewApplicationFlyoutContent.scss';
import moment from 'moment/moment';
import SaveApplicationFlyout from './SaveApplicationFlyout';
import { Questionnaire } from './Questionnaire';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DomainDetails } from './DomainDetails';
import { StatusContainer } from './StatusContainer';
import DeveloperOrganizationDetails from './DeveloperOrganizationDetails';
import OrganizationApi from 'services/api/OrganizationApi';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  status: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 5,
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
  },
  approved: {
    backgroundColor: theme.palette.success.main,
  },
  denied: {
    backgroundColor: '#DB0032',
  },
  revoked: {
    backgroundColor: '#DB0032',
  },
  gridContainer: {
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    marginBottom: '30px',
    backgroundColor: '#fff',
    padding: '16px',
    margin: '40px',
  },
  labelHeading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  labelSubheading: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
  },
}));

const FHIR_APP_TYPE_NAME = 'mph fhir';

function ViewApplicationFlyoutContent({
  onToggle,
  orgId,
  affiliateId,
  applicationData,
}) {
  const [loading, setLoading] = useState(false);
  const [appStatus, setAppStatus] = useState('');
  const [declinePopup, setDeclinePopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [openApplicationContent, setOpenApplicationContent] = useState(false);
  const [sandboxData, setSandboxData] = useState({});
  const [productionData, setProductionData] = useState({});
  const [containerLoading, setContainerLoading] = useState(false);
  const [deniedSandboxReason, setDeniedSandboxReason] = useState([]);
  const [revokeSandboxReason, setRevokeSandboxReason] = useState([]);
  const [deniedProductionReason, setDeniedProductionReason] = useState([]);
  const [revokeProductionReason, setRevokeProductionReason] = useState([]);
  const [isMphAdmin, setIsMphAdmin] = useState(
    JSON.parse(sessionStorage.getItem('MPH_ADMIN'))
  );
  const [env, setEnv] = useState('');
  const [orgAndDeveloperDetails, setOrgAndDeveloperDetails] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const permissions = useHasPermissions({
    featureName: 'Developer portal',
    subFeatureName: 'Applications',
  });
  const { checkDevPermission, checkSuperAdminPermission } =
    useUserPermissions();
  const isSuperAdmin = checkSuperAdminPermission();
  const { enqueueSnackbar } = useSnackbar();
  const handleSaveResourceRef = useRef();

  const {
    id,
    desc,
    reason,
    status,
    affiliateName,
    applicationTypeDisplayName,
    applicationTypeName,
  } = applicationData;

  const isFhirApp = applicationTypeName === FHIR_APP_TYPE_NAME;

  const getViewDetails = async (orgId, applicationId) => {
    const resp = await ApplicationApi.viewApplicationDetails(
      orgId,
      applicationId
    );

    await OrganizationApi.getOrganizationAndDeveloperDetails({
      orgId,
      appId: applicationData?.applicationId,
    })
      .then(res => {
        setOrgAndDeveloperDetails(res);
      })
      .catch(err => {
        console.error(err?.response);
      });
    // setViewDetails(resp);
    const sandboxData = resp?.find(el => el.env === 'sandbox');

    setSandboxData(sandboxData);

    const productionData = resp?.find(el => el.env === 'production');
    setProductionData(productionData);

    if (sandboxData?.sandboxStatus?.toLowerCase() === 'denied') {
      setDeniedSandboxReason(
        await getDeclinedReason(orgId, sandboxData?.applicationEnvId, 'Denied')
      );
    } else if (sandboxData?.sandboxStatus?.toLowerCase() === 'revoked') {
      setRevokeSandboxReason(
        await getDeclinedReason(orgId, sandboxData?.applicationEnvId, 'Revoked')
      );
    }
    if (productionData?.productionStatus?.toLowerCase() === 'denied') {
      setDeniedProductionReason(
        await getDeclinedReason(
          orgId,
          productionData?.applicationEnvId,
          'Denied'
        )
      );
    } else if (productionData?.productionStatus?.toLowerCase() === 'revoked') {
      setRevokeProductionReason(
        await getDeclinedReason(
          orgId,
          productionData?.applicationEnvId,
          'Revoked'
        )
      );
    }
    setContainerLoading(false);
  };

  const getDeclinedReason = async (orgId, appId, status) => {
    const res = await ApplicationApi.getDeclinedReason(orgId, appId, status);
    return res;
  };

  useEffect(() => {
    if (applicationData?.orgId && applicationData?.applicationId) {
      setContainerLoading(true);
      getViewDetails(applicationData?.orgId, applicationData?.applicationId);
    }
  }, [applicationData]);

  // useEffect(() => {
  //   if (applicationData && orgId && affiliateId) {
  //     const {
  //       approvedOn,
  //       deniedOn,
  //       isActive,
  //       mphApprovalStatus,
  //       productionStatus,
  //       sandboxStatus,
  //     } = applicationData;

  //     const hasApprovalRights = checkDevPermission(
  //       orgId,
  //       affiliateId,
  //       RESOURCE_NAMES.DEV_APPLICATION_APPROVER
  //     );
  //     let _appStatus = 'Pending';

  //     if (!approvedOn && !deniedOn) {
  //       _appStatus = 'Pending';
  //     } else if (approvedOn !== null && isActive === 1) {
  //       _appStatus = 'Approved';
  //     } else if (deniedOn !== null) {
  //       _appStatus = 'Denied';
  //     }

  //     if (
  //       (mphApprovalStatus === 'Approved' && productionStatus === 'Approved') ||
  //       (sandboxStatus === 'Approved' && productionStatus === null)
  //     ) {
  //       _appStatus = 'Approved';
  //     }

  //     setHasRights(hasApprovalRights);
  //     setAppStatus(_appStatus);
  //   }
  // }, [applicationData, orgId, affiliateId, checkDevPermission]);

  useEffect(() => {
    if (
      (isMphAdmin === null || sessionStorage.getItem('MPH_ORG') === null) &&
      permissions.APPROVE
    ) {
      OrganizationApi.mphOrgCheck(orgId).then(res => {
        sessionStorage.setItem('MPH_ORG', isSuperAdmin ? true : res);
        setIsMphAdmin(isSuperAdmin ? true : res);
      });
    }
  }, [isMphAdmin, orgId, permissions]);

  const onChangeApproval = params => {
    const { id } = applicationData;

    let declinedPayload = {
      id: selectedTab === 0 ? sandboxData?.id : productionData?.id,
      status:
        appStatus === 'Approved' ||
        (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
          productionData?.productionStatus?.toLowerCase() === 'approved')
          ? 'Revoked'
          : 'Denied',
      reason: declineReason,
      env:
        sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
        productionData?.productionStatus?.toLowerCase() === 'approved'
          ? 'production'
          : env,
      applicationEnvId:
        sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
        productionData?.productionStatus?.toLowerCase() === 'approved'
          ? productionData?.applicationEnvId
          : selectedTab === 0
          ? sandboxData?.applicationEnvId
          : productionData?.applicationEnvId,
      affiliate: {
        id: applicationData?.affiliateId,
      },
    };
    let resourceIDs =
      typeof handleSaveResourceRef.current === 'function' &&
      params.approve !== 0 &&
      handleSaveResourceRef.current();

    if (resourceIDs === false && params.approve !== 0) return;

    let status =
      sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
      productionData?.productionStatus?.toLowerCase() === 'approved'
        ? 'Revoked'
        : selectedTab === 0
        ? sandboxData?.sandboxStatus?.toLowerCase() === 'approved'
          ? 'Revoked'
          : 'Denied'
        : productionData?.productionStatus?.toLowerCase() === 'approved'
        ? 'Revoked'
        : 'Denied';

    if (isFhirApp && selectedTab === 1 && isMphAdmin) {
      // MPH Admin Decline / Revoke
      if (
        productionData?.productionStatus?.toLowerCase() === 'approved' &&
        productionData?.mphApprovalStatus?.toLowerCase() === 'approved'
      ) {
        declinedPayload.status = 'Revoked';
      } else {
        declinedPayload.status = 'Denied';
      }
      declinedPayload.applicationId = declinedPayload.id;
    }
    if (
      productionData?.productionStatus?.toLowerCase() === 'approved' &&
      productionData?.mphApprovalStatus?.toLowerCase() === 'approved'
    ) {
      declinedPayload.env = 'production';
    }
    if (params.approve === 0) {
      setLoading(true);
      status = status?.toLowerCase() === 'revoked' ? 'revoke' : status;
      const API = isMphAdmin
        ? ApplicationApi.mphApproveApplication(orgId, declinedPayload)
        : ApplicationApi.approveApplication(
            orgId,
            status === 'revoke' ? declinedPayload.id : id,
            declinedPayload,
            status?.toLowerCase() ?? 'denied'
          );

      API.then(response => {
        setLoading(false);
        if (
          (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' ||
            productionData?.productionStatus?.toLowerCase() === 'approved') &&
          !isMphAdmin
        ) {
          enqueueSnackbar('Application revoked', { variant: 'success' });
        } else if (
          isMphAdmin &&
          productionData?.mphApprovalStatus?.toLowerCase() === 'approved'
        ) {
          enqueueSnackbar('Application revoked', { variant: 'success' });
        } else {
          enqueueSnackbar('Application declined', { variant: 'success' });
        }
        onToggle(true);
      }).catch(err => {
        setLoading(false);
        enqueueSnackbar(
          err.response?.data?.details ||
            'There were some error performing this action',
          {
            variant: 'error',
          }
        );
      });
      return;
    }
    let temp = selectedTab === 0 ? sandboxData : productionData;
    let payload = {
      ...temp,
      resourceIds: resourceIDs || [''],
      env: env,
      applicationEnvId:
        selectedTab === 0
          ? sandboxData?.applicationEnvId
          : productionData?.applicationEnvId,
    };
    setLoading(true);

    ApplicationApi.updateApplication(orgId, payload)
      .then(res => {
        const mphApprovalPayload = {
          applicationId: res.id,
          applicationEnvId: res.applicationEnvId,
          reason: '',
          status: 'Approved',
        };
        const API = isMphAdmin
          ? ApplicationApi.mphApproveApplication(orgId, mphApprovalPayload)
          : ApplicationApi.approveApplication(
              orgId,
              applicationData?.applicationId,
              res,
              'approved'
            );

        API.then(response => {
          setLoading(false);
          const message = `Application approved`;
          enqueueSnackbar(message, { variant: 'success' });
          onToggle(true);
        }).catch(err => {
          setLoading(false);
          enqueueSnackbar(
            err.response?.data?.details ||
              'There were some error performing this action',
            {
              variant: 'error',
            }
          );
        });
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar(
          err.response?.data?.details
            ? err.response?.data?.details
            : 'Something went wrong while updating resources',
          {
            variant: 'error',
          }
        );
      });
  };

  const onApproveRequest = env => {
    if (typeof env === 'string')
      setEnv(env?.includes('sandbox') ? 'sandbox' : 'production');
    else setEnv(selectedTab === 0 ? 'sandbox' : 'production');
    setApprovePopup(true);
  };

  const onDenyRequest = env => {
    if (typeof env === 'string')
      setEnv(env?.includes('sandbox') ? 'sandbox' : 'production');
    else setEnv(selectedTab === 0 ? 'sandbox' : 'production');
    setDeclinePopup(true);
  };

  const handleConfirmDenied = () => {
    if (!checkValidation()) return;
    onChangeApproval({ approve: 0 });
  };

  const handleConfirmApprove = () => {
    onChangeApproval({ approve: 1 });
  };

  const handleClose = () => {
    setApprovePopup(false);
  };

  const handleCloseConfirmation = () => {
    setDeclinePopup(false);
    setDeclineReason('');
  };

  const checkFooter = () => {
    if (!permissions.APPROVE || !sandboxData) return false;
    const sandboxStatus = sandboxData.sandboxStatus?.toLowerCase();
    const productionStatus = productionData?.productionStatus?.toLowerCase();
    const mphApprovalStatus = productionData?.mphApprovalStatus?.toLowerCase();

    const declineStatus = new Map([
      ['denied', true],
      ['revoked', true],
    ]);

    if (
      declineStatus.has(sandboxStatus) ||
      declineStatus.has(productionStatus) ||
      (isMphAdmin && declineStatus.has(mphApprovalStatus))
    ) {
      return false;
    }

    // Sandbox Data
    if (selectedTab === 0) {
      if (
        (sandboxStatus === 'pending' ||
          (sandboxStatus === 'approved' && productionStatus === 'approved')) &&
        !isFhirApp
      )
        return true;
      // FHIR App Conditions
      else if (isFhirApp && sandboxStatus === 'pending') return true;
      else if (
        isFhirApp &&
        sandboxStatus === 'approved' &&
        mphApprovalStatus === 'pending'
      ) {
        return false;
      } else {
        return false;
      }
    } else {
      // Production Data
      if (
        isFhirApp &&
        productionStatus === 'approved' &&
        mphApprovalStatus === 'pending' &&
        !isMphAdmin // Can probably remove this from here and add it inside the if block
      ) {
        return false;
      }
      return ['pending', 'approved', 'applied'].includes(productionStatus);
    }
  };

  const checkValidation = () => {
    if (declineReason.length >= 1) {
      return true;
    } else if (
      appStatus === 'Approved' ||
      (selectedTab === 0 &&
        sandboxData?.sandboxStatus?.toLowerCase() === 'approved') ||
      productionData?.productionStatus?.toLowerCase() === 'approved'
    ) {
      enqueueSnackbar('Please enter reason for revoking', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return false;
    } else if (
      appStatus !== 'Approved' ||
      sandboxData?.sandboxStatus?.toLowerCase() !== 'approved' ||
      productionData?.productionStatus?.toLowerCase() !== 'approved'
    ) {
      enqueueSnackbar('Please enter reason for declining', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return false;
    }
  };

  const handleProdAccess = type => {
    if (type !== 'prod' && type !== 'sandbox') {
      return setOpenApplicationContent();
    }
    setOpenApplicationContent(type);
  };

  if (openApplicationContent) {
    let applicationData;
    let isProdUpdate = false;
    if (openApplicationContent === 'sandbox') {
      applicationData = sandboxData;
    } else if (openApplicationContent === 'prod') {
      if (typeof productionData === 'undefined') {
        applicationData = sandboxData;
      } else {
        isProdUpdate = true;
        applicationData = productionData;
      }
    }
    return (
      <SaveApplicationFlyout
        applicationData={applicationData}
        onCloseApplicationCreation={handleProdAccess}
        isProd={openApplicationContent === 'prod'}
        open={openApplicationContent}
        orgId={orgId}
        onToggle={handleProdAccess}
        getViewDetails={getViewDetails}
        isProdUpdate={isProdUpdate}
      />
    );
  }

  const handleDisableResources = () => {
    if (!permissions.APPROVE || isMphAdmin) return true;
    else if (
      selectedTab === 0 &&
      sandboxData?.sandboxStatus?.toLowerCase() !== 'pending'
    )
      return true;
    else if (
      selectedTab === 1 &&
      productionData?.productionStatus?.toLowerCase() !== 'pending' &&
      productionData?.productionStatus !== null &&
      !isMphAdmin
    )
      return true;
    else return false;
  };

  const handleFhirAppStatus = type => {
    const productionStatus = productionData?.productionStatus?.toLowerCase();
    const mphApprovalStatus = productionData?.mphApprovalStatus?.toLowerCase();
    switch (type) {
      case 'hideSave': {
        if (
          isMphAdmin &&
          productionStatus === 'approved' &&
          mphApprovalStatus === 'pending'
        ) {
          return false;
        } else if (!isMphAdmin && productionStatus === 'approved') {
          return true;
        } else if (
          isMphAdmin &&
          productionStatus === 'approved' &&
          mphApprovalStatus === 'approved'
        ) {
          return true;
        } else {
          return false;
        }
      }
      case 'cancelLabel': {
        if (mphApprovalStatus === 'approved') return 'Revoke access';
        else return 'Decline';
      }
      default:
        return false;
    }
  };

  return (
    <>
      <ApplicationFullScreenDialog.Content>
        <Grid container spacing={4} id="applicationFlyoutContentRoot">
          <div
            className="header-container"
            style={{
              backgroundColor: '#fff',
              margin: '-12px 36px 0px 36px',
              width: '100%',
              borderRadius: '8px',
            }}
          >
            <div className="description-container">
              <span
                style={{ color: '#344054', fontWeight: 600, fontSize: '16px' }}
              >
                Description:
              </span>
              <span
                style={{
                  color: '#667085',
                }}
              >
                {' '}
                {sandboxData?.desc}
              </span>
            </div>
            <div className="aff-container">
              <div>
                <span className="affliate">Affiliate name: </span>
                <span
                  style={{
                    color: '#667085',
                    fontSize: '16px',
                  }}
                >
                  {affiliateName}
                </span>
              </div>
              <div>
                <span className="affliate">Application type: </span>
                <span
                  style={{
                    color: '#667085',
                    fontSize: '16px',
                  }}
                >
                  {applicationTypeDisplayName}
                </span>
              </div>
            </div>
          </div>
          <div className="key-container">
            {containerLoading ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {/* SANDBOX DATA */}
                <StatusContainer
                  containerName="sandbox-key-container"
                  appStatus={sandboxData?.sandboxStatus}
                  deniedOn={sandboxData?.deniedOn}
                  approvedOn={sandboxData?.approvedOn}
                  clientId={sandboxData?.clientId}
                  hasRights={permissions.APPROVE}
                  requestedOn={sandboxData?.createdAt}
                  onDenyRequest={onDenyRequest}
                  productionStatus={productionData?.productionStatus}
                  apiKey={sandboxData?.apiKey}
                  handleProdAccess={handleProdAccess}
                  isSandbox={true}
                  declinedReason={deniedSandboxReason}
                  revokeReason={revokeSandboxReason}
                  applicationEnvId={sandboxData?.applicationEnvId}
                  permissions={permissions}
                />
                {/* PRODUCTION DATA */}
                {(permissions.APPROVE
                  ? productionData && Object.keys(productionData)?.length > 0
                  : true) && (
                  <StatusContainer
                    containerName="production-key-container"
                    appStatus={productionData?.productionStatus}
                    deniedOn={productionData?.deniedOn}
                    approvedOn={productionData?.approvedOn}
                    clientId={productionData?.clientId}
                    hasRights={permissions.APPROVE}
                    requestedOn={productionData?.createdAt}
                    handleProdAccess={handleProdAccess}
                    sandboxStatus={sandboxData?.sandboxStatus}
                    onDenyRequest={onDenyRequest}
                    apiKey={productionData?.apiKey}
                    isSandbox={false}
                    declinedReason={deniedProductionReason}
                    revokeReason={revokeProductionReason}
                    applicationEnvId={productionData?.applicationEnvId}
                    mphApprovalStatus={productionData?.mphApprovalStatus}
                    isFhirApp={isFhirApp}
                    permissions={permissions}
                  />
                )}
              </>
            )}
          </div>

          {/* Developer and Organization Details */}
          {orgAndDeveloperDetails && permissions.APPROVE && (
            <DeveloperOrganizationDetails
              type="developer"
              data={orgAndDeveloperDetails?.developerDetails}
            />
          )}

          {isFhirApp &&
            permissions.APPROVE &&
            isMphAdmin &&
            orgAndDeveloperDetails && (
              <DeveloperOrganizationDetails
                type="organization"
                data={orgAndDeveloperDetails?.organizationDetails}
              />
            )}

          <Tabs
            className="tabs-container"
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, value) => setSelectedTab(value)}
            style={{
              marginLeft: '40px',
            }}
          >
            <Tab
              className="tabs"
              label="Sandbox data"
              style={{
                textTransform: 'none',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
              }}
            />
            <Tab
              className="tabs"
              label="Production data"
              style={{
                textTransform: 'none',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
              }}
              disabled={!productionData}
            />
          </Tabs>

          {/* Domain details section */}
          <>
            <DomainDetails
              supportEmail={
                selectedTab === 0
                  ? sandboxData?.supportEmail
                  : productionData?.supportEmail
              }
              domainUrl={
                selectedTab === 0
                  ? sandboxData?.domainUrl
                  : productionData?.domainUrl
              }
              privacyLink={
                selectedTab === 0
                  ? sandboxData?.privacyLink
                  : productionData?.privacyLink
              }
              termsOfServiceLink={
                selectedTab === 0
                  ? sandboxData?.termsOfServiceLink
                  : productionData?.termsOfServiceLink
              }
              redirectUrl={
                selectedTab === 0
                  ? sandboxData?.redirectUrl
                  : productionData?.redirectUrl
              }
              appQueryLimit={
                selectedTab === 0
                  ? sandboxData?.appQueryLimit
                  : productionData?.appQueryLimit
              }
            />
            <Grid item xs={12} style={{ margin: '25px', marginTop: '0px' }}>
              {(sandboxData?.applicationEnvId ||
                productionData?.applicationEnvId) && (
                <AddResource
                  onSaveResource={handleSaveResourceRef}
                  appStatus={appStatus.toUpperCase()}
                  appID={
                    selectedTab === 1
                      ? productionData?.applicationEnvId
                      : sandboxData?.applicationEnvId
                  }
                  readOnly={handleDisableResources()}
                  key={selectedTab}
                />
              )}
            </Grid>

            {selectedTab === 1 && (
              <Grid item xs={12} style={{ margin: '25px', marginTop: '-10px' }}>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="header">Questionnaire</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Questionnaire
                      data={productionData?.questionAnsJson}
                      view={true}
                      styles={{
                        root: {
                          margin: '0px',
                          marginTop: '-2px',
                          padding: '0px',
                          marginBottom: '24px',
                        },
                        card: {
                          marginBottom: '24px',
                        },
                        // acc: {
                        //   marginTop: '-20px',
                        // },
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </>

          {appStatus === 'Denied' && (
            <Grid item xs={12} className={classes.gridContainer}>
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <ReadonlyField
                  label={`Reason for ${
                    status === 'Revoked' ? 'revoking' : 'declining'
                  } access`}
                  value={reason}
                  isValueBold={true}
                  customLabelStyle={classes.labelSubheading}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ApplicationFullScreenDialog.Content>
      {checkFooter() && (
        <ApplicationFullScreenDialog.Footer>
          <FooterActions
            hideSave={
              permissions?.APPROVE
                ? selectedTab === 0
                  ? sandboxData?.sandboxStatus?.toLowerCase() === 'approved'
                  : isFhirApp
                  ? handleFhirAppStatus('hideSave')
                  : productionData?.productionStatus?.toLowerCase() ===
                    'approved'
                : true
            }
            hideCancel={
              permissions?.APPROVE
                ? selectedTab === 0
                  ? sandboxData?.sandboxStatus?.toLowerCase() === 'denied' ||
                    sandboxData?.sandboxStatus?.toLowerCase() === 'revoked'
                  : isFhirApp
                  ? handleFhirAppStatus('hideCancel')
                  : productionData?.productionStatus?.toLowerCase() ===
                      'denied' ||
                    productionData?.productionStatus?.toLowerCase() ===
                      'revoked'
                : true
            }
            saveLabel="Approve request"
            canceLabel={
              isFhirApp && isMphAdmin
                ? handleFhirAppStatus('cancelLabel')
                : sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
                  productionData?.productionStatus?.toLowerCase() === 'approved'
                ? 'Revoke access'
                : 'Decline'
            }
            onSave={onApproveRequest}
            onToggle={onDenyRequest}
            disabled={loading}
            size="large"
          />
        </ApplicationFullScreenDialog.Footer>
      )}

      {approvePopup && (
        // Primary Btn Fn - handleClose & Secondary Btn Fn - handleConfirmApprove
        <ReusableModal
          open={approvePopup}
          handleClose={handleConfirmApprove}
          onChangeApproval={onChangeApproval}
          title={REUSABLE_MODAL.DIALOG_TITLE}
          context={REUSABLE_MODAL.DIALOG_CONTEXT}
          primarybtn={REUSABLE_MODAL.SEC_BTN}
          secondarybtn={REUSABLE_MODAL.PRIMARY_BTN}
          isShowReason={false}
          handleConfirmApprove={handleClose}
          shouldCallHandleConfirm={true}
          loading={loading}
        />
      )}
      {declinePopup && (
        <ReusableModal
          open={declinePopup}
          handleClose={handleCloseConfirmation}
          title={
            appStatus === 'Approved' ||
            (isFhirApp &&
              isMphAdmin &&
              productionData?.mphApprovalStatus?.toLowerCase() ===
                'approved') ||
            (isFhirApp &&
              sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              productionData?.productionStatus?.toLowerCase() === 'denied') ||
            (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              productionData?.productionStatus?.toLowerCase() === 'approved' &&
              !isMphAdmin) ||
            (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              typeof productionData === 'undefined')
              ? REQUEST_MODAL.REVOKE_DIALOG_TITLE
              : REQUEST_MODAL.DECLINE_DIALOG_TITLE
          }
          context={
            appStatus === 'Approved' ||
            (isFhirApp &&
              isMphAdmin &&
              productionData?.mphApprovalStatus?.toLowerCase() ===
                'approved') ||
            (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              productionData?.productionStatus?.toLowerCase() === 'approved' &&
              !isMphAdmin) ||
            (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              (typeof productionData === 'undefined' ||
                productionData?.productionStatus?.toLowerCase() === 'denied'))
              ? sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
                (typeof productionData === 'undefined' ||
                  productionData?.productionStatus?.toLowerCase() === 'denied')
                ? REQUEST_MODAL.SANDBOX_APPLICATION_REVOKE_CONTEXT
                : REQUEST_MODAL.APPLICATION_REVOKE_CONTEXT
              : REQUEST_MODAL.DECLINE_DIALOG_CONTEXT
          }
          placeholder={
            appStatus === 'Approved' ||
            (isFhirApp &&
              isMphAdmin &&
              productionData?.mphApprovalStatus?.toLowerCase() ===
                'approved') ||
            (isFhirApp &&
              sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              productionData?.productionStatus?.toLowerCase() === 'denied') ||
            (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              productionData?.productionStatus?.toLowerCase() === 'approved' &&
              !isMphAdmin) ||
            (sandboxData?.sandboxStatus?.toLowerCase() === 'approved' &&
              typeof productionData === 'undefined')
              ? REQUEST_MODAL.PLACEHOLDER_REVOKE
              : REQUEST_MODAL.PLACEHOLDER_DECLINE
          }
          primarybtn={REQUEST_MODAL.PRIMARY_BTN}
          secondarybtn={REQUEST_MODAL.SEC_BTN}
          isShowReason={true}
          handleConfirmDenied={handleConfirmDenied}
          setDeclineReason={setDeclineReason}
          declineReason={declineReason}
          loading={loading}
        />
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
}

export default ViewApplicationFlyoutContent;
