import React, { useEffect, useState } from 'react';
import RulesTab from '../Rules/tab/RulesTab';
import { useSelector } from 'react-redux';

const RulesDetailPage = () => {
  const TabHeading = ['Attributes', 'Criteria', 'Actions', 'Rules'];

  const { solutionPermissions } = useSelector(state => state.userInfo);
  const [rulesPermissons, setRulesPermissions] = useState();

  useEffect(() => {
    const selectedSolution = solutionPermissions?.get(
      localStorage.getItem('selectedSolutionId')
    );

    const tempRulesPermission = selectedSolution?.features?.find(
      feature => feature?.featureName === 'Rules engine'
    )?.subFeatures;

    setRulesPermissions(tempRulesPermission || []);
  }, [solutionPermissions]);
  return (
    <div>
      <RulesTab TabHeading={TabHeading} rulesPermissions={rulesPermissons} />
    </div>
  );
};

export default RulesDetailPage;
