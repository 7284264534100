import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FilterDropDown from 'components/FilterDropDown';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import PrimaryButton from 'components/widgets/Buttons/PrimaryButton';
import CampainsGrid from './CampaignsGrid';
import { useParams, useHistory } from 'react-router-dom';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import CreateCampaignModal from './CreateCampaignModal';
import CampaignApi from 'services/api/CampaignApi';
import {
  AlertDialog,
  BackendSearchTextField,
  SimpleBackdrop,
} from 'components/widgets';
import {
  CampaignStatusFilterMenuItems,
  CLOSE_ALERT_DESCRIPTION,
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
} from 'shared/constants';
import NotFoundPage from 'components/NotFoundPage';
import useInfiniteScroll from '../cohort/useInfiniteScroll';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
}));

const CampaignsMainPage = ({ permissions }) => {
  const classes = useStyles();
  const orgId = sessionStorage.getItem("orgId");
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get('create');

  const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState(
    type === 'campaign' ? true : false
  );
  const [isCreateCampaign, setIsCreateCampaign] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [searchText, setSearchText] = useState('');
  const [fetchedData, setFetchedData] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [OnloadData, setOnloadData] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const trackUnsavedChangesRef = useRef(false);
  const onPageNumberClick = () => {
    setPageNumber(prevPage => prevPage + 1);
  };
  const { lastElement } = useInfiniteScroll(onPageNumberClick);
  const { CREATE } = useHasPermissions({availablePermissions:permissions});

  const getAllCampaigns = async () => {
    setLoading(true);
    try {
      const results = await CampaignApi.getAllCampaigns({
        orgId: orgId,
        offset: pageNumber,
        searchStatus: statusFilter === 'all' ? '' : statusFilter ?? '',
        searchName: searchValue,
      });
      setOnloadData(true);
      setTotalPage(Math.floor(results?.totalItems / 10));
      if (searchFlag && pageNumber === 0) {
        setFilteredData(prev => results.data);
      } else if (searchFlag && pageNumber !== 0) {
        setFilteredData(prev => [...prev, ...results.data]);
      } else {
        setFilteredData(prev => [...prev, ...results.data]);
      }

      setLoading(false);
      setOnloadData(true);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      setSearchFlag(false);
    } else {
      setSearchFlag(true);
    }
  }, [searchValue]);

  useEffect(() => {
    if (pageNumber <= totalPage) {
      getAllCampaigns();
    }
  }, [pageNumber, searchText, statusFilter]);

  const handleCreateCampaignModal = val => {
    setOpenCreateCampaignModal(val);
  };

  const handleUnsavedChanges = () => {
    if (type === 'campaign') {
      history.replace({ pathname: `/organization/${orgId}/campaigns` });
    }
    if (trackUnsavedChangesRef.current()) {
      setOpenAlertDialog(true);
    } else {
      setOpenCreateCampaignModal(false);
    }
  };

  const handleClose = () => {
    setOpenCreateCampaignModal(false);
    if (type === 'campaign') {
      history.replace({ pathname: `/organization/${orgId}/campaigns` });
    }
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setPageNumber(0);
    setSearchValue('');
    setSearchText('');
  };
  const onSearchClick = () => {
    setPageNumber(0);
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const statusFilterOptions = {
    options: CampaignStatusFilterMenuItems,
    value: statusFilter,
    changeHandler: ev => {
      setPageNumber(0);
      setIsInitialRender(false);
      setSearchFlag(true);
      setStatusFilter(ev.target.value);
    },
  };

  const createCampaignClick = () => {
    setIsCreateCampaign(true);
    handleCreateCampaignModal(true);
  };

  const refetchCampaigns = () => {
    setFilteredData([]);
    setPageNumber(0);
    setSearchValue('');
    setSearchText('');
    getAllCampaigns();
  };

  const handleCreateCampaign = () => {
    setIsCreateCampaign(true);
    handleCreateCampaignModal(true);
    setSearchText('');
    setSearchValue('');
    setStatusFilter('all');
    setPageNumber(0);
    if (
      filteredData?.length === 0 &&
      searchValue === '' &&
      searchText === '' &&
      setStatusFilter('all')
    ) {
      getAllCampaigns();
    }
  };
  return (
    <>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {(!isInitialRender || filteredData?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    data-testid="campagins-details"
                    label="Search by campaign name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>{' '}
                <SecondaryButton
                  data-testid="search-btn"
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                >
                  Search
                </SecondaryButton>
                <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                  <FilterDropDown
                    data-testid="campagins-details"
                    {...statusFilterOptions}
                    filterType="Status:"
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                }}
              >
                <PrimaryButton
                  data-testid="create-campaign"
                  onClick={handleCreateCampaign}
                  hasPermissions={CREATE}
                >
                  Create a campaign
                </PrimaryButton>
              </div>
            </>
          )}
          {filteredData?.map((currentElem, i) => {
            const { id } = currentElem;
            return (
              <CampainsGrid
                data-testid="campagins-details"
                key={id}
                {...currentElem}
                filteredData={filteredData}
                getAllCampaigns={getAllCampaigns}
                loading={loading}
                refetchCampaigns={refetchCampaigns}
                setCampaignId={setCampaignId}
                campaignId={campaignId}
                lastElementObserver={
                  Number(pageNumber) < Number(totalPage) ? lastElement : null
                }
                isLastElement={filteredData?.length - 1 === i}
                cohortValue={currentElem}
                hasCreatePermissions={CREATE}
              />
            );
          })}
        </div>
      </Grid>
      {/* code for campaign pop up screen */}
      {openCreateCampaignModal && (
        <FullScreenDialog
          open={openCreateCampaignModal}
          handleClose={handleUnsavedChanges}
        >
          <CreateCampaignModal
            handleCreateCampaignModal={handleCreateCampaignModal}
            handleClose={handleClose}
            fetchedData={fetchedData}
            isCreateCampaign={isCreateCampaign}
            setIsCreateCampaign={setIsCreateCampaign}
            orgId={orgId}
            campaignId={campaignId}
            refetchCampaigns={refetchCampaigns}
            trackUnsavedChangesRef={trackUnsavedChangesRef}
            hasCreatePermissions={CREATE}
          />
        </FullScreenDialog>
      )}
      {openAlertDialog && (
        <AlertDialog
          open={openAlertDialog}
          description={CLOSE_ALERT_DESCRIPTION}
          title="Alert"
          onClose={() => setOpenAlertDialog(false)}
          onConfirm={() => {
            setOpenCreateCampaignModal(false);
            setOpenAlertDialog(false);
          }}
        />
      )}

      <SimpleBackdrop open={loading} />
      {filteredData.length === 0 && OnloadData && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={
              !isInitialRender
                ? EMPTY_STATE_PAGE.CAMPAIGN_TITLE
                : INITIAL_RENDER_NO_DATA_PAGE.CAMPAIGN_TITLE
            }
            content={
              !isInitialRender
                ? EMPTY_STATE_PAGE.CAMPAIGN_CONTENT
                : INITIAL_RENDER_NO_DATA_PAGE.CAMPAIGN_CONTENT
            }
            titleContent={INITIAL_RENDER_NO_DATA_PAGE.CAMPAIGN_TITLE_CONTENT}
            showButton={(!isInitialRender || !CREATE) ? false : true}
            onNotFoundClick={createCampaignClick}
          />
        </div>
      )}
    </>
  );
};

export default CampaignsMainPage;
