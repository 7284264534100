import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1.5),
    textTransform: 'none',
  },
  actions: {
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    position: 'sticky',
    top: 49,
    backgroundColor: '#fff',
    zIndex: 2,
  },
}));

function BottomActions({
  saveLabel = 'Save',
  canceLabel = 'Cancel',
  onSave,
  type = 'button',
  onToggle,
  disabled,
}) {
  const classes = useStyles();

  return (
    <div className={classes.actions}>
      <Button
        onClick={onSave}
        disabled={disabled}
        type={type}
        variant="contained"
        color="primary"
        className={classes.actionButton}>
        {saveLabel}
      </Button>
      <Button
        onClick={onToggle}
        variant="outlined"
        color="primary"
        className={classes.actionButton}>
        {canceLabel}
      </Button>
    </div>
  );
}

export default BottomActions;
