import { Component, memo } from 'react';
import { DataGrid } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import DownloadIcon from '../../assets/consent/DownloadIcon.svg';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
}));

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  value,
  data,
  onClick,
  onDownloadClick,
  hasDownloadPermission
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  const onDownloadButtonClick = event => {
    event.preventDefault();
    onDownloadClick(data);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-evenly',
          marginTop: '12px',
        }}
      >
        {hasDownloadPermission && (
          <img
            style={{ cursor: 'pointer' }}
            src={DownloadIcon}
            alt="downloadIcon"
            onClick={onDownloadButtonClick}
          />
        )}
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
        >
          View details
        </PrimaryButton>
      </div>
    </>
  );
});

// const AppNameCellRenderer = memo(function AppNameCellRenderer({
//   value,
//   data,
//   onClick,
// }) {
//   const classes = useStyles();
//   const onClickHandler = event => {
//     event.preventDefault();
//     onClick(data);
//   };

//   return (
//     <Typography
//       component="span"
//       variant="body1"
//       className={classes.name}
//       onClick={onClickHandler}>
//       {value}
//     </Typography>
//   );
// });

const statusColorMap = {
  Pending: '#FB8900',
  Active: '#036FCB',
  Cancelled: '#DB0032',
  Expired: '#DB0032',
  Terminated: `#DB0032`,
};

class MembersDataGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Name',
        field: 'fullName',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        colId: 'NameID',
      },
      {
        field: 'email',
      },
      {
        headerName: 'Last exported',
        field: 'lastExported',
        cellRenderer: 'DateFormatter',
      },
      {
        headerName: 'Consent expiry',
        field: 'consentExpiryDate',
        cellRenderer: 'ConsentExpiryDateFormatter',
      },
      {
        headerName: 'Consent status',
        field: 'consentStatus',
        cellRenderer: 'StatusRenderer',
      },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      ConsentExpiryDateFormatter: rowData => {
        if (rowData.value && rowData.data.consentStatus !== 'Pending') {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      StatusRenderer: rowData => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(statusColorMap).includes(rowData.value) ? (
              <>
                <span
                  style={{
                    backgroundColor: statusColorMap[rowData.value],
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    padding: 2,
                  }}></span>
                <span style={{ marginLeft: 8 }}>{rowData.value}</span>
              </>
            ) : (
              <span>{rowData.value}</span>
            )}
          </Box>
        );
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDownloadClick={item => this.onDownloadButton(item)}
          hasDownloadPermission={this.props.hasDownloadPermission}
        />
      ),

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.props.onView(data);
  }

  onDownloadButton(data) {
    this.props.onDownload(data);
  }

  onRowDataChanged=(event)=>{
    let prevSelectedNodes = this.props.selectedRows?.map(val => val.patientId) || []
    event.api?.forEachNode((node) =>
      {
        if(prevSelectedNodes.includes(node.data?.patientId)){
          node.setSelected(true);
        }
      }
    );
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData,onRowSelected } = this.props;
    const rowSelectionType = 'multiple';

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
            apiLoading={this.props.apiLoading}
            membersPage={true}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh', height: 'calc(100vh - 270px)' }}
            hideHeaderActions
            rowSelection={rowSelectionType}
            rowMultiSelectWithClick={true}
            suppressRowClickSelection={true}
            onRowSelected={onRowSelected}
            onRowDataChanged={this.onRowDataChanged}
          />
        </div>
      </div>
    );
  }
}

export default MembersDataGrid;