import React, { useState, useCallback, useEffect} from 'react';
import {
  Box,
  Typography,
  Link,
  FormLabel,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DEVELOPER_SIGNUP_CONSTANTS } from 'shared/constants';
import { Form } from 'react-final-form';
import { FormTextField, FormEmailField } from 'components/widgets';
import PartnerApi from 'services/api/PartnerApi';
import {SimpleBackdrop} from 'components/widgets';
import { useSnackbar } from 'notistack';
import OrganizationApi from 'services/api/OrganizationApi';



const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    width: 28,
    height: 28,
    display: 'flex',
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#005DB9',
  },
});



function StepIcon(props) {
  const classes = useStepIconStyles();
  const icons = {
    1:  '1',
    2:  '2',
    3: '3',
    4: '4'
  };
  return (
      <div
        className={classes.root}>
        {icons[String(props.icon)]}
      </div>
  );
}
 

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  leftBox: {
    width: '40%',
    backgroundColor: '#E2F1FC',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  stepperBox: {
    backgroundColor: '#E2F1FC',
    paddingLeft: '0px',
  },
  stepperLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    color: '#101828',
  },
  stepperDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#667085',
  },
  rightBox: {
    width: '60%',
    backgroundColor: '#FFFFFF',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20% auto 0px',
    width: '50%',
  },
  title: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '40px',
    color: '#101828',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054',
  },
  buttonBox: {
    display: 'flex',
    gap: '20px',
    marginTop: '15px',
  },
  labelStyle: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#344054',
  },
  inputHeight: {
    height: '90px',
  },
  formSelect: {
    marginLeft: '-8px',
  },
  leftTitle: {
    color: '#005DB9',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 600,
  },
  stepContent: {
    '&.MuiStepContent-root': {
      borderLeft: '1px dashed #005DB9',
      marginTop: '0px',
      paddingLeft: '24px'
    },
  },
  lastStep: {
    '&.MuiStepContent-root': {
     marginTop: '0px',
     paddingLeft: '24px'
    }
  }
}));

const DevSignupPage = props => {

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const orgId = sessionStorage.getItem('devSignUpOrgId');

  let initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    organizationName: '',
  };



  const getOrgLogo = useCallback(() => {
    async function getLogo() {
      try {
        const logoUrl = await OrganizationApi.getLogoUnauth(orgId);
        setLogo(logoUrl);
      } catch (e) {
        // enqueueSnackbar('Unable to fetch Organization Logo', {
        //   variant: 'error',
        // });
        console.log(e);
      }
    }
    getLogo();
  }, [orgId]);

   useEffect(() => {
     
       getOrgLogo();
  
   }, [getOrgLogo]);

  const classes = useStyles();

  const onSubmit = async values => {
    setLoading(true);
    try {
        await PartnerApi.newDevSignup(values);
        setLoading(false);
        enqueueSnackbar(DEVELOPER_SIGNUP_CONSTANTS.CONFIRM_EMAIL, {variant: 'success'});
        props.onLogin()
    } catch (err) {
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.details || 'Something went wrong', {variant: 'error'});
    }
  };

  const steps = [
    DEVELOPER_SIGNUP_CONSTANTS.STEP1_TITLE,
    DEVELOPER_SIGNUP_CONSTANTS.STEP2_TITLE,
    DEVELOPER_SIGNUP_CONSTANTS.STEP3_TITLE,
    DEVELOPER_SIGNUP_CONSTANTS.STEP4_TITLE,
  ];

  const getStepContent = (index) => {
    switch (index) {
      case 0:
        return DEVELOPER_SIGNUP_CONSTANTS.STEP1_SUBTITLE;
      case 1:
        return DEVELOPER_SIGNUP_CONSTANTS.STEP2_SUBTITLE;
      case 2:
        return DEVELOPER_SIGNUP_CONSTANTS.STEP3_SUBTITLE;
      case 3: 
        return DEVELOPER_SIGNUP_CONSTANTS.STEP4_SUBTITLE;
      default:
        return 'Unknown step';
    }
  }

  return (
    <>
      {/* {flag ? (
        <ApprovalPendingPage />
      ) : ( */}
      <Box className={classes.container}>
        <Box className={classes.leftBox}>
          <img
            src= {logo ? logo : "https://mph-dev-campaign-email-assets.s3.amazonaws.com/default/mphlogo.png"}
            alt="Org Logo"
            style={{ maxWidth: '180px', marginBottom: '120px' }}
          />
          <Box>
            <Typography className={classes.leftTitle}>
              {DEVELOPER_SIGNUP_CONSTANTS.LEFT_PANE_TITLE}
            </Typography>
            <Stepper orientation="vertical" className={classes.stepperBox}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} active={true} connector={<StepConnector />}>
                    <StepLabel
                      className={classes.stepperLabel}
                      StepIconComponent={StepIcon}
                    >
                      {label}
                    </StepLabel>
                    <StepContent
                      className={
                        index !== 3 ? classes.stepContent : classes.lastStep
                      }
                    >
                      <Typography className={classes.stepperDescription}>
                        {getStepContent(index)}
                      </Typography>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
        <Box className={classes.rightBox}>
          <Box className={classes.contentBox}>
            <Typography
              style={{
                fontSize: '28px',
                fontWeight: 600,
                lineHeight: '40px',
              }}
            >
              Create your account!
            </Typography>
            <Typography
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400,
                color: '#667085',
                marginBottom: '20px',
              }}
            >
              Tell us a little about yourself and your organization
            </Typography>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit, pristine }) => (
                <form
                  noValidate
                  onSubmit={event => {
                    handleSubmit(event);
                  }}
                  autoComplete="off"
                >
                  <Box>
                    <Box className={classes.inputHeight}>
                      <FormLabel
                        component="legend"
                        className={classes.labelStyle}
                      >
                        First name
                      </FormLabel>
                      <Box className={classes.formSelect}>
                        <FormTextField
                          name="firstName"
                          placeholder="Enter your first name"
                        />
                      </Box>
                    </Box>
                    <Box className={classes.inputHeight}>
                      <FormLabel
                        component="legend"
                        className={classes.labelStyle}
                      >
                        Last name
                      </FormLabel>
                      <Box className={classes.formSelect}>
                        <FormTextField
                          name="lastName"
                          placeholder="Enter your last name"
                        />
                      </Box>
                    </Box>
                    <Box className={classes.inputHeight}>
                      <FormLabel
                        component="legend"
                        className={classes.labelStyle}
                      >
                        Email
                      </FormLabel>
                      <Box className={classes.formSelect}>
                        <FormEmailField
                          name="emailAddress"
                          placeholder="Enter your email id"
                        />
                      </Box>
                    </Box>
                    <Box className={classes.inputHeight}>
                      <FormLabel
                        component="legend"
                        className={classes.labelStyle}
                      >
                        Organization Name
                      </FormLabel>
                      <Box className={classes.formSelect}>
                        <FormTextField
                          name="organizationName"
                          placeholder="Enter organization name"
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Button
                    style={{
                      textTransform: 'none',
                      borderRadius: '24px',
                      marginTop: '10px',
                    }}
                    fullWidth
                    type="submit"
                    disabled={pristine}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Verify Email
                  </Button>
                </form>
              )}
            />
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                color: '#344054',
                marginTop: '10px',
              }}
            >
              Already have an account?{' '}
              <Link component="button" onClick={() => props.onLogin()}>
                Login
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* )} */}
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default DevSignupPage;
