import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, FormControl, Button } from '@material-ui/core';
import PieChart from './PieChart';
import BarGraph from './BarGraph';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { PageHeaderTitle } from 'components/widgets';
import LineGraph from './LineGraph';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const CATEGORY_TO_GRAPH_MAPPING = {
  'Members logged in for X days': 'bar',
  'Members not logged for last X days': 'bar',
  'Inactive members in last X days': 'bar',
  'Active members in last X days': 'pie',
  'Password changes': 'pie',
  'Members based on gender': 'pie',
  'Members count based on age': 'column',
  'Members based on states': 'pie',

  'HRA summary': 'pie',
  'Diabetes summary': 'pie',
  'Asthma summary': 'pie',

  'CKD campaign': 'pie',
  'HRA campaign': 'pie',
  'Diabetes campaign': 'pie',
};

const DUMMY_DATA = {
  'Active members in last X days': {
    title: {
      text: 'Active members in last X days',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Active',
            y: 27.2,
          },
          {
            name: 'Inactive',

            y: 72.8,
          },
        ],
      },
    ],
  },
  'Password changes': {
    title: {
      text: 'Password changes',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Updated password',
            y: 17.2,
          },
          {
            name: 'Not updated password',

            y: 82.8,
          },
        ],
      },
    ],
  },
  'Members based on gender': {
    title: {
      text: 'Members based on gender',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Male',
            y: 56.04,
          },
          {
            name: 'Female',
            y: 42.96,
          },
          {
            name: 'Others',
            y: 2.5,
          },
        ],
      },
    ],
  },
  'Members based on states': {
    title: {
      text: 'Members based on states',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'South carolina',
            y: 56.04,
          },
          {
            name: 'ohio',
            y: 42.96,
          },
          {
            name: 'california',
            y: 2.5,
          },
        ],
      },
    ],
  },
  'Members count based on age': {
    title: {
      text: 'Members count based on age',
    },
    chart: {
      type: 'column',
    },
    series: [
      {
        data: [1045, 4857, 3897, 1543, 324, 122],
      },
    ],
    xAxis: {
      categories: ['0-20', '21-40', '41-60', '61-80', '81-100', 'above 100'],
    },
    yAxis: {
      title: {
        text: 'Number of users',
      },
    },
  },

  'HRA summary': {
    title: {
      text: 'HRA summary',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Completed',
            y: 16.04,
          },
          {
            name: 'In-progress',
            y: 32.96,
          },
          {
            name: 'Assigned',
            y: 10.96,
          },
          {
            name: 'Expired',
            y: 30.1,
          },
        ],
      },
    ],
  },
  'Diabetes summary': {
    title: {
      text: 'Diabetes summary',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Completed',
            y: 14.96,
          },
          {
            name: 'In-progress',
            y: 22.96,
          },
          {
            name: 'Assigned',
            y: 36.04,
          },
          {
            name: 'Expired',
            y: 26.1,
          },
        ],
      },
    ],
  },
  'Asthma summary': {
    title: {
      text: 'Asthma summary',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Completed',
            y: 36.04,
          },
          {
            name: 'In-progress',
            y: 22.96,
          },
          {
            name: 'Assigned',
            y: 14.96,
          },
          {
            name: 'Expired',
            y: 26.1,
          },
        ],
      },
    ],
  },

  'CKD campaign': {
    title: {
      text: 'CKD campaign',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Sent',
            y: 36.04,
          },
          {
            name: 'Read',
            y: 22.96,
          },
          {
            name: 'Not read',
            y: 14.96,
          },
          {
            name: 'Bounced',
            y: 26.1,
          },
        ],
      },
    ],
  },
  'Diabetes campaign': {
    title: {
      text: 'Diabetes campaign',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Sent',
            y: 36.04,
          },
          {
            name: 'Read',
            y: 22.96,
          },
          {
            name: 'Not read',
            y: 14.96,
          },
          {
            name: 'Bounced',
            y: 26.1,
          },
        ],
      },
    ],
  },
  'HRA campaign': {
    title: {
      text: 'HRA campaign',
    },
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Sent',
            y: 36.04,
          },
          {
            name: 'Read',
            y: 22.96,
          },
          {
            name: 'Not read',
            y: 14.96,
          },
          {
            name: 'Bounced',
            y: 26.1,
          },
        ],
      },
    ],
  },
};

const DemoDashboard = () => {
  const [value, setValue] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [category, setCategory] = useState('');
  const [resources, setResources] = useState([]);
  const [graph, setGraph] = useState('');
  const history = useHistory();

  const handleChange = event => {
    setValue(event.target.value);

    if (
      event.target.value != '' &&
      category != '' &&
      startDate != '' &&
      endDate != ''
    ) {
      setGraph(CATEGORY_TO_GRAPH_MAPPING[event.target.value]);

      if (CATEGORY_TO_GRAPH_MAPPING[event.target.value] == 'bar') {
        let categoryList = [];
        let seriesList = [];
        let currentDate = startDate;
        do {
          categoryList.push(normalizeDate(currentDate));
          seriesList.push(Math.floor(Math.random() * (1000 - 100) + 100));
          const nextDate = new Date(currentDate);
          nextDate.setDate(currentDate.getDate() + 1);
          currentDate = nextDate;
        } while (currentDate <= endDate);
        setGraphData({
          title: {
            text: event.target.value,
          },
          chart: {
            type: 'column',
          },
          series: [
            {
              data: seriesList,
            },
          ],
          xAxis: {
            categories: categoryList,
          },
          yAxis: {
            title: {
              text: `Number of ${category}`,
            },
          },
        });
      } else {
        setGraphData(DUMMY_DATA[event.target.value]);
      }
    }
  };

  const handleCreateCustomReportClick = () => {
    let url = history.location.pathname.split('/');

    history.push('/' + url[1] + '/' + url[2] + '/customReport');
  };
  function normalizeDate(dateString) {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function calculateSumForDateRange(dataArray, startDate, endDate) {
    const filteredData = dataArray.filter(
      obj => obj.date >= startDate && obj.date <= endDate
    );

    if (filteredData.length === 0) {
      return 0; // Return 0 if no data is found in the specified date range
    }

    const sumArray = filteredData.reduce(
      (acc, obj) => {
        return acc.map((value, index) => value + obj.data[index]);
      },
      Array.from({ length: 4 }, () => 0)
    );

    return sumArray;
  }

  useEffect(() => {
    if (value != '' && category != '' && startDate != '' && endDate != '') {
      if (CATEGORY_TO_GRAPH_MAPPING[value] == 'bar') {
        let categoryList = [];
        let seriesList = [];
        let currentDate = startDate;
        do {
          categoryList.push(normalizeDate(currentDate));
          seriesList.push(Math.floor(Math.random() * (1000 - 100) + 100));
          const nextDate = new Date(currentDate);
          nextDate.setDate(currentDate.getDate() + 1);
          currentDate = nextDate;
        } while (currentDate <= endDate);

        setGraphData({
          title: {
            text: value,
          },
          chart: {
            type: 'column',
          },
          series: [
            {
              data: seriesList,
            },
          ],
          xAxis: {
            categories: categoryList,
          },
          yAxis: {
            title: {
              text: `Number of ${category}`,
            },
          },
        });
      } else {
        setGraphData(DUMMY_DATA[value]);
      }
    }
  }, [startDate, endDate, value, resources]);

  const handleCategoryChange = event => {
    setCategory(event.target.value);
    if (event.target.value === 'member') {
      setResources([
        'Members logged in for X days',
        'Members not logged for last X days',
        'Inactive members in last X days',
        'Active members in last X days',
        'Password changes',
        'Members based on gender',
        'Members count based on age',
        'Members based on states',
      ]);
    } else if (event.target.value === 'assessment') {
      setResources(['HRA summary', 'Diabetes summary', 'Asthma summary']);
    } else if (event.target.value === 'campaign') {
      setResources(['Diabetes campaign', 'HRA campaign', 'CKD campaign']);
    }
    setValue('');
  };

  const handleStartDate = date => {
    setStartDate(date);
  };

  const handleEndDate = date => {
    setEndDate(date);
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
      }}
    >
      <Box>
        <PageHeaderTitle sx={{ marginLeft: '0px' }}>Reports</PageHeaderTitle>
      </Box>
      <Box sx={{ margin: '16px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Box sx={{ marginTop: '8px' }}>
              <FormControl variant="outlined">
                <Select
                  value={category}
                  onChange={handleCategoryChange}
                  style={{ width: '241.5px' }}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select a category</em>
                  </MenuItem>
                  <MenuItem value={'member'}>Member</MenuItem>
                  <MenuItem value={'assessment'}>Assessment</MenuItem>
                  <MenuItem value={'campaign'}>Campaign</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: '8px' }}>
              <FormControl variant="outlined">
                <Select
                  value={value}
                  onChange={handleChange}
                  displayEmpty
                  style={{ width: '241.5px' }}
                >
                  <MenuItem value="">
                    <em>Select a resource</em>
                  </MenuItem>
                  {resources.map((item, index) => (
                    <MenuItem value={item} index={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              disableFuture
              label="Select start date"
              value={startDate}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={endDate}
            />
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              disableFuture
              margin="normal"
              label="Select end date"
              value={endDate}
              onChange={handleEndDate}
              minDate={startDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <Button
              style={{
                borderRadius: "10px", 
                textTransform: "none",
                height: '50px',
                marginTop: '5px',
              }}
              color="primary"
              variant="contained"
              onClick={handleCreateCustomReportClick}
            >
              Create custom report
            </Button>
          </Box>
        </MuiPickersUtilsProvider>
      </Box>

      <Box sx={{ marginTop: '50px' }}>
        {graph == 'pie' && <PieChart data={graphData} />}
        {(graph == 'bar' || graph == 'column') && <BarGraph data={graphData} />}

        {graph == 'line' && <LineGraph data={graphData} />}
      </Box>
    </Box>
  );
};

export default DemoDashboard;
