import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import clsx from 'classnames';
import { useState } from 'react';
import AddValuePopover from './AddValuePopover';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& > *': {
      minWidth: '100%',
      minHeight: theme.spacing(16),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 'auto',
    alignItems: 'center',
  },
  paperContainer: {
    marginBottom: theme.spacing(6),
    borderRadius: theme.spacing(0.5),
  },
  paperError: {
    border: '1px solid red',
    borderColor: theme.palette.error.main,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function CreatableSelectInput({
  value = [],
  onChange,
  onBlur,
  error,
  touched,
  placeholder = 'Values',
  required = false,
  name,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItemIndex(-1);
  };

  const handleDelete = index => {
    const updatedValues = value
      .filter((existingItem, itemIndex) => itemIndex !== index)
      .map(item => item);
    onBlur();
    onChange(updatedValues);
  };

  const onEditValueClick = (event, index) => {
    setSelectedItemIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const onAddNewValueClick = event => {
    setSelectedItemIndex(-1);
    setAnchorEl(event.currentTarget);
  };

  const onSaveHandler = val => {
    const updatedValues = value ? value.map(item => item) : [];

    if (selectedItemIndex === -1) {
      updatedValues.push(val);
    } else {
      updatedValues[selectedItemIndex] = val;
    }

    onChange(updatedValues);
    onBlur();
    setSelectedItemIndex(-1);
  };

  const hasErrors = error && touched ? true : false;

  return (
    <div className={clsx(classes.root, classes.paperContainer)}>
      <div className={classes.header}>
        <span>
          {placeholder}
          {required ? (
            <span style={{ fontSize: 10, paddingLeft: 4 }}>*</span>
          ) : null}
        </span>
      </div>

      <Paper
        variant="outlined"
        className={clsx(classes.paper, {
          [classes.paperError]: hasErrors,
        })}>
        <div>
          {value && value.length > 0
            ? value.map((item, index) => (
                <Chip
                  key={`${name}-${index}`}
                  size="small"
                  className={classes.chip}
                  label={item.name}
                  onClick={event => onEditValueClick(event, index)}
                  onDelete={() => handleDelete(index)}
                  variant="outlined"
                />
              ))
            : null}
        </div>
        <div className={classes.actionContainer}>
          <Tooltip placement="top" title="Add new value" arrow>
            <Fab size="small" color="secondary" onClick={onAddNewValueClick}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </Paper>
      {hasErrors && (
        <FormHelperText error={hasErrors} style={{ minHeight: 'auto' }}>
          {error}
        </FormHelperText>
      )}

      <AddValuePopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleClick={onAddNewValueClick}
        onSave={onSaveHandler}
        value={selectedItemIndex >= 0 ? value[selectedItemIndex] : ''}
      />
    </div>
  );
}

export default CreatableSelectInput;
