import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { useState, useEffect, useRef } from 'react';
import ApplicationApi from 'services/api/ApplicationApi';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  labelHeading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  labelSubheading: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
  },
  categoryBox: {
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    marginBottom: '30px',
    width: '100%',
    padding: '16px',
  },
  categoryHeading: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#101828',
  },
  subCategoryLabel: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
  },
}));

const AddResource = ({ onSaveResource, appID, readOnly }) => {
  const classes = useStyles();
  const [resourceData, setResourceData] = useState();
  const [openAccordion, setOpenAccordion] = useState(true);
  const [resourceDataLoading, setResourceDataLoading] = useState(false);

  const prevSavedResourceID = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const fetchAllResources = () => {
    setResourceDataLoading(true);
    ApplicationApi.fetchAllResources()
      .then(response => {
        response?.forEach(res => {
          if (appID) {
            res.subCategories.forEach(subCat => {
              if (prevSavedResourceID.current.has(subCat.id)) {
                subCat.checked = true;
              }
            });
          }
          res.subCategories.unshift({
            id: `select-all-${res.category}`,
            isSelectAll: true,
            name: 'All records',
            checked:
              prevSavedResourceID.current?.get(res.category) ===
              res.subCategories?.length
                ? true
                : false,
          });
        });

        setResourceData(response);

        setResourceDataLoading(false);
      })
      .catch(err => {
        console.error(err);

        setResourceDataLoading(false);
      });
  };

  const handleResourceSelection = (event, catIdx, subCatIdx) => {
    if (readOnly) return;
    let prevResourceData = [...resourceData];
    const selectedCategory = prevResourceData[catIdx];
    let selectedSubCategory = selectedCategory.subCategories[subCatIdx];
    selectedSubCategory.checked = !selectedSubCategory.checked;

    if (selectedCategory.subCategories[0].checked) {
      selectedCategory.subCategories[0].checked = false;
    } else if (event.target.checked) {
      let isAllSubSelected = selectedCategory.subCategories?.every(subCat => {
        if (subCat.isSelectAll || (!subCat.isSelectAll && subCat.checked))
          return true;
        return false;
      });
      selectedCategory.subCategories[0].checked = isAllSubSelected;
    }

    setResourceData(prevResourceData);
  };

  const handleCheckAll = (event, catIdx) => {
    if (readOnly) return;
    let prevResourceData = [...resourceData];

    prevResourceData[catIdx].subCategories[0].checked =
      !prevResourceData[catIdx].subCategories[0].checked;

    prevResourceData[catIdx]?.subCategories.forEach(
      sub => (sub.checked = event.target.checked)
    );

    setResourceData(prevResourceData);
  };

  const handleSaveResource = () => {
    let resourceID = [];

    let sensitiveRecordsID = new Map();

    resourceData?.forEach(resource =>
      resource.subCategories.forEach((subCat, index) => {
        if (
          (resource.category?.toUpperCase() === 'SENSITIVE RECORDS' ||
            resource.category?.toUpperCase() === 'SENSITIVE RECORD') &&
          index !== 0
        ) {
          sensitiveRecordsID.set(subCat.id, true);
        }
        if (index !== 0 && subCat.checked) {
          resourceID.push(subCat.id);
        }
      })
    );

    if (resourceID.length === 0) {
      enqueueSnackbar('Please select atleast one record', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1500,
      });

      return false;
    }

    const selectedResourceWithoutSensitiveRecords = resourceID.some(
      val => !sensitiveRecordsID.has(val)
    );

    if (!selectedResourceWithoutSensitiveRecords) {
      enqueueSnackbar(
        'Please select atleast one record apart from Sensitive Records',
        {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 1500,
        }
      );

      return false;
    }

    return resourceID;
  };
  useEffect(() => {
    if (resourceData) {
      onSaveResource.current = handleSaveResource;
    }

    if (typeof resourceData === 'undefined') {
      if (!appID) {
        fetchAllResources();
      } else {
        setResourceDataLoading(true);
        ApplicationApi.getAllSavedResource(appID).then(res => {
          let resourceID = new Map();

          res?.forEach((resource, index, resourceList) => {
            let selectedSubCategory = 0;

            resource.subCategories.forEach(subCat => {
              resourceID.set(subCat.id, true);
              selectedSubCategory += 1;
            });
            resourceID.set(resource.category, selectedSubCategory);
          });

          prevSavedResourceID.current = resourceID;
          fetchAllResources();
        });
      }
    }
  }, [resourceData, appID]);

  return (
    <Accordion
      expanded={openAccordion}
      onChange={() => setOpenAccordion(prevState => !prevState)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box>
          <Typography className={classes.labelHeading}>
            Access resources from Mpowered Health
          </Typography>
          <Typography className={classes.labelSubheading}>
            Select all the resources that you would like to access from Mpowered
            Health
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
        {resourceDataLoading && (
          <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        )}
        {resourceData?.map((resource, categoryIdx) => {
          return (
            <Box className={classes.categoryBox} key={resource.category}>
              <Box display={'flex'} alignItems={'center'}>
                <img
                  src={resource.iconUrl}
                  alt={resource.category}
                  style={{ marginRight: '5px' }}
                  height={'24px'}
                  width={'24px'}
                />
                <Typography className={classes.categoryHeading}>
                  {resource.category}
                </Typography>
              </Box>
              <FormGroup row>
                {resource.subCategories?.map((subCategory, subCatIdx) => (
                  <FormControlLabel
                    key={subCategory.id}
                    disabled={readOnly}
                    control={
                      <Checkbox
                        name={subCategory.name}
                        style={{ paddingRight: '5px' }}
                        checked={subCategory.checked || false}
                        disabled={readOnly}
                        inputProps={{ 'aria-label': subCategory.name }}
                      />
                    }
                    label={subCategory.name}
                    className={classes.subCategoryLabel}
                    onClick={event =>
                      subCategory.isSelectAll
                        ? handleCheckAll(event, categoryIdx)
                        : handleResourceSelection(event, categoryIdx, subCatIdx)
                    }
                  />
                ))}
              </FormGroup>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default AddResource;
