import React from 'react';
import { Field } from 'react-final-form';
import { required, zipCode, composeValidators } from 'shared/validators';
import { TextField } from 'components/widgets';

export default function FormZipField({ name, label, value }) {
  return (
    <Field name={name} validate={composeValidators(required, zipCode)}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          required
          label={label}
          value={value}
          {...input}
          touched={touched}
          error={error}></TextField>
      )}
    </Field>
  );
}
