import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  BackendSearchTextField,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import React, { useState, useEffect, useCallback } from 'react';
import ActionGrid from './ActionGrid';
import RulesEngineApi from 'services/api/RuleEngineApi';
import NotFoundPage from 'components/NotFoundPage';
import {
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
} from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
  pageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
  },
}));

const ActionPage = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [name, setName] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);

  const getAllAcationList = useCallback(() => {
    setLoading(true);
    async function getAttribute() {
      try {
        const results = await RulesEngineApi.getActionList({ name });
        const arrayValue = [];
        results?.data?.map(a => {
          arrayValue.push({
            id: a.id,
            name: a.name,
            description: a.description,
            parameters: a.parameters,
          });
        });
        setFilteredMembers(arrayValue);
        setLoading(false);
        setOnloadData(true);
      } catch (error) {
        setLoading(false);
        setOnloadData(true);
        console.log('error: ', error);
      }
    }
    getAttribute();
  });

  useEffect(() => {
    getAllAcationList();
  }, [name]);

  const onSearchClick = () => {
    setIsInitialRender(false);
    setName(searchValue);
  };
  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setName('');
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div className={classes.pageContainer}>
          {(!isInitialRender || filteredMembers?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}>
                  <BackendSearchTextField
                    label="Search by action name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  onClick={onSearchClick}
                  data-testid="search"
                  style={{ marginLeft: '-8px' }}>
                  Search
                </SecondaryButton>
              </div>
              {filteredMembers?.length > 0 && (
                <Grid item xs={12}>
                  <ActionGrid rowData={filteredMembers} apiLoading={loading} />
                </Grid>
              )}
            </>
          )}{' '}
        </div>{' '}
      </Grid>

      {filteredMembers?.length === 0 && isInitialRender && OnloadData && (
        <NotFoundPage
          title={INITIAL_RENDER_NO_DATA_PAGE.ACTION_TITLE}
          titleContent={INITIAL_RENDER_NO_DATA_PAGE.ACTION_TITLE_CONTENT}
          content={''}
          showButton={false}
        />
      )}
      {filteredMembers?.length === 0 && !isInitialRender && OnloadData && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.ACTION_TITLE}
            content={EMPTY_STATE_PAGE.ACTION_CONTENT}
            showButton={false}
          />
        </div>
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default ActionPage;
