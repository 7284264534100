import {
  Box,
  Button,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SecondaryButton, SimpleBackdrop } from 'components/widgets';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import React, { useState, useEffect } from 'react';
import AttributeApi from 'services/api/AttributeApi';
import { useSnackbar } from 'notistack';

const CreateAttributeOrganization = ({
  StepperCustomComponent,
  customStyles,
  setActiveStep,
  attributeDetails,
  attributeOrgs,
  setAttributeOrgs,
  handleCloseAttributeCreation,
  options,
  isEdit,
  refetchAttributes,
  attributeId,
  attrType,
}) => {
  const [selectedOrgs, setSelectedOrgs] = useState(
    attributeOrgs?.orgIdArray ? attributeOrgs?.orgIdArray?.filter(Number) : []
  );
  const [loading, setLoading] = useState(false);
  const [existingOrgFlag, setExistingOrgFlag] = useState(
    attributeOrgs?.existingFlag ? attributeOrgs.existingFlag : false
  );
  const [futureOrgFlag, setFutureOrgFlag] = useState(
    attributeOrgs?.futureFlag ? attributeOrgs.futureFlag : false
  );
  const [tempState, setTempState] = useState([]);
  const [checkMPHOrg,setCheckMPHOrg]= useState(isEdit ? true : false);

  const { enqueueSnackbar } = useSnackbar();

  const findAddedOrgs = () => {
    if (
      JSON.stringify(attributeOrgs.orgIdArray.sort()) !==
      JSON.stringify(selectedOrgs.sort())
    ) {
      return selectedOrgs.filter(
        item => !attributeOrgs.orgIdArray.includes(item)
      );
    } else {
      return [];
    }
  };

  const findRemovedOrgs = () => {
    if (
      JSON.stringify(attributeOrgs.orgIdArray.sort()) !==
      JSON.stringify(selectedOrgs.sort())
    ) {
      return attributeOrgs.orgIdArray
        .filter(item => !selectedOrgs.includes(item))
        .filter(Number);
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (attributeOrgs?.orgIdArray) {
      let idArray = [];
      for (let x of attributeOrgs.orgIdArray.filter(Number)) {
        let comp = options.map(item => item.id).indexOf(x);
        idArray.push(comp);
      }
      let defaultValueArray = idArray.map(item => options[item]);
      setTempState(prev => [...prev, ...defaultValueArray]);
    }
  }, [attributeOrgs]);

  const createAttribute = async () => {
    let payload = {
      ...attributeDetails,
      details: {
        ...attributeDetails.details,
        dataSource:
          attributeDetails.type === 'FHIR'
            ? ''
            : attributeDetails.details.dataSource,
        ...(attributeDetails.type !== 'FHIR' && { dataType: '' }),
      },
      existingOrgAddition: existingOrgFlag,
      futureOrgAddition: futureOrgFlag,
      orgIdList: selectedOrgs,
    };

    try {
      setLoading(true);
      await AttributeApi.createAttribute(payload);
      setLoading(false);
      handleCloseAttributeCreation();
      refetchAttributes();
      enqueueSnackbar('Attribute created successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong while creating attribute', {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const editAttribute = async () => {
    let payload = {
      name: attributeDetails.name,
      existingOrgAddition: existingOrgFlag,
      futureOrgAddition: futureOrgFlag,
      removedOrgIdList: existingOrgFlag ? [] : findRemovedOrgs(),
      addedOrgIdList: existingOrgFlag ? [] : findAddedOrgs(),
    };

    try {
      setLoading(true);
      await AttributeApi.editAttribute(attributeId, payload);
      setLoading(false);
      handleCloseAttributeCreation();
      refetchAttributes();
      enqueueSnackbar('Attribute edited successfully', { variant: 'success' });
    } catch {
      enqueueSnackbar('Something went wrong while editing attribute', {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const handleMPHOrg = event =>{
    setCheckMPHOrg(event.target.checked);
    if(event.target.checked)
    {
      setSelectedOrgs([1]);
    }
    else
    {
      setSelectedOrgs([]);
    }
  }
  const handleExisting = event => {
    setExistingOrgFlag(event.target.checked);
  };

  const handleFuture = event => {
    setFutureOrgFlag(event.target.checked);
  };

  const handleChange = (event, value) => {
    if (value.length > 0) {
      setTempState(value);
      let temp = value.map(item => item.id);
      setSelectedOrgs(temp);
    } else {
      setTempState([]);
      setSelectedOrgs([]);
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleClose = () => {
    setAttributeOrgs({
      existingFlag: existingOrgFlag,
      futureFlag: futureOrgFlag,
      orgIdArray: selectedOrgs,
    });
    setActiveStep(0);
  };

  return (
    <>
      <>
        <FullScreenDialog.Content>
          {StepperCustomComponent && <StepperCustomComponent />}
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            {attrType === 'FHIR' && (
              <>
                <Grid item sm={12} xs={12}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      letterSpacing: '-0.24px',
                      color: '#344054',
                    }}>
                    List of organizations
                  </Typography>
                  <FormGroup style={{ width: '450px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={existingOrgFlag}
                          onChange={handleExisting}
                          inputProps={{
                            'data-testid': 'add-attr-for-existing-org',
                          }}
                        />
                      }
                      label="Add this attribute in all existing organizations"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={futureOrgFlag}
                          onChange={handleFuture}
                          inputProps={{
                            'data-testid': 'add-attr-for-future-org',
                          }}
                        />
                      }
                      label="Add this attribute in all future organizations"
                    />
                  </FormGroup>
                </Grid>
              </>
            )}

{attrType !== 'FHIR' && (
              <>
                <Grid item sm={12} xs={12}>
                  <FormGroup style={{ width: '450px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkMPHOrg}
                          onChange={handleMPHOrg}
                          disabled={isEdit}
                          inputProps={{
                            'data-testid': 'add-attr-for-existing-org',
                          }}
                        />
                      }
                      label="MPH organization"
                    />
                  </FormGroup>
                </Grid>
              </>
            )}

            {/* <EmptyGridForUI /> */}
            {!existingOrgFlag && attrType === 'FHIR' && (
              <Grid item sm={6} xs={6}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  data-testid="organization-list-dropdown"
                  onChange={handleChange}
                  options={options}
                  value={tempState}
                  disableCloseOnSelect
                  getOptionLabel={option => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{ width: 500 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Search organization name "
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': 'organization-list-dropdown-textbox',
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </FullScreenDialog.Content>
        <Box className={customStyles.footer}>
          <SecondaryButton
            onClick={handleClose}
            className={customStyles.cancelButton}>
            Back
          </SecondaryButton>
          <Button
            onClick={isEdit ? editAttribute : createAttribute}
            disabled={
              !existingOrgFlag && !futureOrgFlag && selectedOrgs.length === 0
            }
            color="primary"
            variant="contained"
            className={customStyles.searchButton}>
            {isEdit ? 'Save changes' : 'Create attribute'}
          </Button>
        </Box>
      </>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default CreateAttributeOrganization;
