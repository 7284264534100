import React, { useEffect, useCallback, useRef } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataPartnersApi from 'services/api/DataPartnersApi';
import OrganizationApi from 'services/api/OrganizationApi';
import CampaignStep1 from './CampaignStep1';
import CampaignStep2 from './campaignStep2';

const CreateCampaignModal = ({
  open,
  handleCloseCampaignCreation,
  isCreateRule,
  setIsCreateCampaign,
  rulesId,
  refetchCampaigns,
  isEdit,
  handleClose,
  fetchedData,
  isCreateCampaign,
  campaignId,
  trackUnsavedChangesRef,
  hasCreatePermissions
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryParameters = new URLSearchParams(window.location.search);
  const assessmentName = queryParameters.get("assessmentName");
  const assessmentId = queryParameters.get("assessmentId");

  const [loading, setLoading] = useState(false);
  const [Step1, setStep1] = useState(fetchedData ? false : true);
  const [Step1Data, setStep1Data] = useState({
    title: fetchedData?.name || null,
    description: fetchedData?.description || null,
    mode: fetchedData?.mode || null,
    assessmentId: fetchedData?.assessmentId || assessmentId || null,
    assessmentName:null,
    // mode: "EMAIL",
  });
  const [allAffiliates, setAllAffiliates] = useState([]);
  const shouldRefetechData = useRef(false);
  const [logo, setLogo] = useState();
  const orgId = sessionStorage.getItem("orgId");
  // console.log("fetchedData",fetchedData)
  const [campaignValues, setcampaignValues] = useState({
    id: fetchedData?.id || null,
    name: fetchedData?.name || Step1Data?.title || '',
    description: fetchedData?.description || Step1Data?.description || null,
    mode: fetchedData?.mode || Step1Data?.mode || '',
    // mode:"EMAIL",
    status: fetchedData?.status || 'DRAFT',
    assessmentExpiry:fetchedData?.assessmentExpiry || null,
    assessmentId: assessmentId || Step1Data?.assessmentId|| null ,
    cohortId: fetchedData?.cohortId || null,
    schedule: fetchedData?.schedule || {
      oneTime: false,
      startTime: null,
      frequency: null,
      startDate: null,
      endDate: null,
      timeZone: null,
    },
    emailTemplate: fetchedData?.emailTemplate || {
      template: null,
      subject: null,
      attachmentUrls: [],
      attributesUsed: [],
    },
  });

  const tabStatus = {
    SAVED: 'SAVED',
    NOT_SAVED: 'NOT_SAVED',
    REJECTED: 'REJECTED',
    SAVING: 'SAVING',
  };

  const getAllAffiliates = useCallback(
    async _orgId => {
      try {
        const results = await DataPartnersApi.getAllPartners(_orgId);
        const data = results.filter(item => item.isDeleted !== 1);
        const options = data.map(fItem => ({ id: fItem.id, name: fItem.name }));
        setAllAffiliates(options);
      } catch (error) {
        enqueueSnackbar('Something went wrong while fetching affiliates', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const getOrgLogo = useCallback(() => {
    async function getLogo() {
      try {
        const logoUrl = await OrganizationApi.getLogo(orgId);
        setLogo(logoUrl);
      } catch (e) {
        enqueueSnackbar('Unable to fetch Organization Logo', {
          variant: 'error',
        });
      }
    }
    getLogo();
  }, [enqueueSnackbar, orgId]);

  useEffect(() => {
    if (orgId) {
      getAllAffiliates(orgId);
      getOrgLogo();
    }
  }, [getAllAffiliates, getOrgLogo, orgId]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (shouldRefetechData.current) {
        refetchCampaigns();
      }
    };
  }, []);

  const handleStep = val => {
    setStep1(val);
  };

  const setcampaignPayloadValues = data => {
    // console.log("data",data);
    setcampaignValues(data);
  };
  const step1Value = val => {
    setStep1Data(val);

    let payload = {
      ...campaignValues,
      name: val?.title || '',
      description: val?.description || null,
      mode: val?.mode || '',
      assessmentId:val?.assessmentId || null,
      // mode: "EMAIL" || '',
    };
    setcampaignValues(payload);
  };

  return (
    <div>
      {Step1 ? (
        <CampaignStep1
          changeStep={handleStep}
          step1Data={Step1Data}
          options={allAffiliates}
          data={step1Value}
          logo={logo}
          campaignValues={campaignValues}
          shouldRefetechData={shouldRefetechData}
          trackUnsavedChangesRef={trackUnsavedChangesRef}
          assessmentName={assessmentName}
          assessmentId={assessmentId}
          hasCreatePermissions={hasCreatePermissions}
        />
      ) : (
        <CampaignStep2
          changeStep={handleStep}
          isCreateCampaign={isCreateCampaign}
          step1Data={Step1Data}
          tabStatus={tabStatus}
          campaignValues={campaignValues}
          setCampaign={setcampaignPayloadValues}
          handleClose={handleClose}
          isCreateRule={isCreateRule}
          setIsCreateCampaign={setIsCreateCampaign}
          orgId={orgId}
          rulesId={rulesId}
          refetchCampaigns={refetchCampaigns}
          campaignId={campaignId}
          shouldRefetechData={shouldRefetechData}
          trackUnsavedChangesRef={trackUnsavedChangesRef}
          fetchedData={fetchedData}
          hasCreatePermissions={hasCreatePermissions}
        />
      )}
    </div>
  );
};

export default CreateCampaignModal;
