import BaseApiService from './BaseApiService';
// PartnerLOBApi.getPriceListByLOB(orgId, lobId)

function _getAll(orgId) {
  return BaseApiService.get(`/organization/${orgId}/plan`);
}

function _getById(orgId, planId) {
  return BaseApiService.get(`/organization/${orgId}/plan/${planId}`);
}

function _create(orgId, data) {
  return BaseApiService.post(`/organization/${orgId}/plan`, null, data);
}

function _update(orgId, data) {
  return BaseApiService.put(`/organization/${orgId}/plan`, null, data);
}

function _delete(orgId, planId) {
  return BaseApiService.delete(`/organization/${orgId}/plan/${planId}`);
}

function _getPriceListByLOB(orgId, affiliateId, lobId) {
  return BaseApiService.get(
    `/file/get-list-of-file/${orgId}/${affiliateId}/${lobId}/priceList`
  );
}

function _downloadFile(documentId) {
  return BaseApiService.get(`/file/download/${documentId}`, null, true, {
    responseType: 'blob',
  });
}

function _fileUpload(
  { orgId, affiliateId, lobId, filename },
  data,
  otherConfig,
  otherHeaders
) {
  return BaseApiService.post(
    `/file/upload/${orgId}/${affiliateId}/${lobId}/priceList/${filename}`,
    null,
    data,
    true,
    otherConfig,
    otherHeaders
  );
}

const _affiliateLevelPriceListUpload = (
  { orgId, affiliateId, filename },
  data,
  otherConfig,
  otherHeaders
) => {
  return BaseApiService.post(
    `/file/upload-file/${orgId}/${affiliateId}/priceList/${filename}`,
    null,
    data,
    true,
    otherConfig,
    otherHeaders
  );
};

const _dataLoadConfig = (lobId, data) => {
  return BaseApiService.post(`/lob/${lobId}/dataloadconfig`, null, data);
};

const _dataLoadConfigEdit = (lobId, data) => {
  return BaseApiService.put(`/lob/${lobId}/dataloadconfig`, null, data);
};

function _deleteFile(documentId) {
  return BaseApiService.delete(`/file/delete/${documentId}`);
}

function _getDataLoadConfigData(lobId) {
  return BaseApiService.get(`/lob/${lobId}/dataloadconfig`);
}

const PartnerLOBApi = {
  getAll: _getAll,
  getById: _getById,
  create: _create,
  update: _update,
  delete: _delete,
  getPriceListByLOB: _getPriceListByLOB,
  downloadFile: _downloadFile,
  fileUpload: _fileUpload,
  dataLoadConfig: _dataLoadConfig,
  deleteFile: _deleteFile,
  getDataLoadConfigData: _getDataLoadConfigData,
  dataLoadConfigEdit: _dataLoadConfigEdit,
  affiliateLevelPriceListUpload: _affiliateLevelPriceListUpload,
};

export default PartnerLOBApi;
