import React, { useEffect, useState } from "react";
import Select from "react-select";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomDatePicker from "./CustomDatePicker";
import TrashIcon from '../../assets/trash.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PreviewScreen from "./PreviewScreen";
import CarePlanApi from "./CarePlanApi";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { parseISO } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    padding: "40px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    minHeight: '100%',
    height: 'fit-content'
  },
  header: {
    textAlign: "left",
    fontFamily: 'Inter',
    marginBottom: "20px",
    fontWeight: 'bold',
    fontSize: 28
  },
  label: {
    display: "block",
    marginBottom: "10px",
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "15px",
    fontFamily: 'Inter',
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textarea: {
    width: "100%",
    padding: "8px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    fontFamily: 'Inter',
    borderRadius: "4px",
    boxSizing: "border-box",
    resize: "vertical",
  },
  select: {
    marginBottom: "15px",
  },
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
  },
  continueButton: {
    backgroundColor: "#036FCB",
    color: "white",
    padding: "12px 20px",
    border: "none",
    fontFamily: 'Inter',
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "16px",
    position: "absolute",
    right: "20px",
    bottom: "20px",
    "&:hover": {
      backgroundColor: "#45a049",
    },
    "&:disabled": {
      backgroundColor: "#8BC5F5",
      cursor: "not-allowed",
    },
  },
  submitButton: {
    backgroundColor: "#036FCB",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "16px",
    position: "absolute",
    fontFamily: 'Inter',
    right: "20px",
    bottom: "20px",
    "&:hover": {
      backgroundColor: "#45a049",
    },
    "&:disabled": {
      backgroundColor: "#8BC5F5",
      cursor: "not-allowed",
    },
  },
  backButton: {
    backgroundColor: "#036FCB",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: 'Inter',
    position: "absolute",
    bottom: "20px",
    left: "280px",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  },
  subheading: {
    fontSize: "20px",
    marginTop: "20px",
    fontFamily: 'Inter',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  addGoalButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "8px 16px",
    border: "none",
    borderRadius: "40px",
    fontFamily: 'Inter',
    cursor: "pointer",
    fontSize: "14px",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  iconBackground: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    backgroundColor: '#E4E4E4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addActivityButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "8px 16px",
    fontFamily: 'Inter',
    border: "none",
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "14px",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  goalContainer: {
    marginBottom: "20px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  activityContainer: {
    marginBottom: "20px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px', 
  },
  halfWidth: {
    flex: '1',
  },
  thirdWidth: {
    flex: '1',
  },
  collapseButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },
  collapsedTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const CarePlanForm = () => {
  const classes = useStyles();
  const [currentScreen, setCurrentScreen] = useState(1);
  const [goals, setGoals] = useState([
  ]);
  const [formHeader, setFormHeader] = useState("Create Care Plan");
  const [formData, setFormData] = useState({
    id: null,
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    intent: null,
    carePlanCategoryMap: [],
  });
  const history = useHistory();
  const orgId = sessionStorage.getItem("orgId");
  const patientId = sessionStorage.getItem("patientId")
  const [metadata, setMetadata] = useState(null);

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
     if (id) {
      setGoals((prev) =>
        prev.map((goal) =>
        (goal.goalId === id || goal.id === id) ? { ...goal, [name]: value } : goal
        )
      );
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const addGoal = () => {
    setGoals((prevGoals) => [
      ...prevGoals,
      {
        id: null,
        goalId: Date.now(),
        title: "",
        description: "",
        priority: "",
        startDate: "",
        endDate: "",
        activities: [],
      },
    ]);
  };

  const removeGoal = (goalId) => {
    setGoals((prevGoals) => prevGoals.filter((goal) => (goal.goalId || goal.id) !== goalId));
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const getCategoryOptionsForSelect = () => {
    return formData.carePlanCategoryMap.map(({ category }) => ({
        value: category.id,
        label: category.display,
    }));
};

  const handleContinue = async () => {
    const adjustedGoals = goals.map(goal => ({
      ...goal,
      id: goal.id || null 
    }));
    
    const payload = { ...formData, patientId, goals: adjustedGoals };    
    try {
      let response;
      if (formData.id) {
          // Update the existing care plan if formData.id is not null
          response = await CarePlanApi.updateCarePlan({ orgId, payload });
      } else {
          // Create a new care plan if formData.id is null
          response = await CarePlanApi.createCarePlan({ orgId, payload });
      }
      console.log('API response:', response);
      setFormData((prev) => ({ ...prev, id: response.id,carePlanCategoryMap: response.carePlanCategoryMap }));
      setGoals(response.goals);
      setCurrentScreen(2);
    } catch (error) {
      console.error('Failed to create care plan:', error);
    }
  };

  const handlePreview = async () => {
    try {
      const payload = {
        ...formData,
        patientId,
        goals,
      };
      const response = await CarePlanApi.updateCarePlan({ orgId, payload });
      console.log('API response with goals:', response);
      
      setFormData((prev) => ({
        ...prev,
        id: response.id || prev.id,
        carePlanCategoryMap: response.carePlanCategoryMap || prev.carePlanCategoryMap,
        startDate: response.startDate || prev.startDate,
        endDate: response.endDate || prev.endDate,
      }));
      
      setGoals(response.goals.length ? response.goals : goals);
      setFormHeader("Preview");
      setCurrentScreen(3);
    } catch (error) {
      console.error('Failed to create care plan with goals:', error);
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...formData,
        patientId,
        goals,
      };
  
      const response = await CarePlanApi.updateCarePlan({ orgId, payload });
      console.log('API response with updated goals:', response);
      history.push(`/organization/${orgId}/members`);
    } catch (error) {
      console.error('Failed to update care plan with goals:', error);
    }
  };
  

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await CarePlanApi.geCarePlantStaticMetadata();
        setMetadata(response);
      } catch (err) {
        console.error('Failed to fetch metadata');
      } 
    };

    fetchMetadata();
  }, []);

  const handleIntentChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, intent: selectedOption.label }));
  };
  
  // Map metadata to select options
  const intentOptions = metadata?.carePlanIntentEnum.map((intent) => ({
    value: intent,
    label: intent.charAt(0).toUpperCase() + intent.slice(1).toLowerCase(),
  })) || [];

  const categoryOptions = metadata?.carePlanCategory.map((category) => ({
    value: category.id,
    label: category.display,
  })) || [];

  const startDate = parseDate(formData.startDate);
  const endDate = parseDate(formData.endDate);

  const areFieldsValid = () => {
    const { title, description, intent, carePlanCategoryMap, startDate, endDate } = formData;
    return title && description && intent && carePlanCategoryMap.length > 0 && startDate && endDate;
  };

  const areGoalsValid = () => {
    return goals.every((goal) => {
      return goal.title && goal.description && goal.startDate && goal.endDate && goal.activities.every((activity) => {
        return activity.title && activity.description && activity.kind && activity.startDate && activity.endDate;
      });
    });
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.header}>{formHeader}</h2>

      {currentScreen === 1 && (
        <>
            <label htmlFor="title" className={classes.label}>
            Title<span style={{color: 'red'}}> *</span>
            </label>
            <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            className={classes.input}
            required
            />

            <label htmlFor="description" className={classes.label}>
            Description<span style={{color: 'red'}}> *</span>
            </label>
            <textarea
            id="description"
            name="description"
            rows="3"
            value={formData.description}
            onChange={handleInputChange}
            className={classes.textarea}
            ></textarea>

            <div className={classes.row}>
            <div className={classes.halfWidth}>
                <label htmlFor="intent" className={classes.label}>
                Intent<span style={{color: 'red'}}> *</span>
                </label>
                <Select
                id="intent"
                name="intent"
                value={{ label: formData.intent, value: formData.intent }} 
                onChange={handleIntentChange}
                options={intentOptions}
                className={classes.select}
                placeholder="Select intent"
                />
            </div>

            <div className={classes.halfWidth}>
                <label htmlFor="carePlanCategoryMap" className={classes.label}>
                Category<span style={{color: 'red'}}> *</span>
                </label>
                <Select
                id="carePlanCategoryMap"
                name="carePlanCategoryMap"
                value={getCategoryOptionsForSelect()}
                onChange={(selectedOptions) => {
                    const updatedCategories = selectedOptions.map(option => ({
                        id: formData.carePlanCategoryMap.find(cat => cat.category.id === option.value)?.id || null,
                        category: {
                            id: option.value,
                            display: option.label,
                        },
                    }));
                    setFormData((prev) => ({ ...prev, carePlanCategoryMap: updatedCategories }));
                }}
                options={categoryOptions}
                isMulti
                className={classes.select}
                placeholder="Select category"
                />
            </div>
            </div>

            <div className={classes.row}>
            <div className={classes.halfWidth}>
                <label htmlFor="startDate" className={classes.label}>
                Start Date<span style={{color: 'red'}}> *</span>
                </label>
                <CustomDatePicker
                    selectedDate={startDate || new Date()}
                    onChange={(date) => handleInputChange({ target: { name: 'startDate', value: date?.toISOString().split('T')[0] } })}
                    minDate={new Date()}
                />
            </div>

            <div className={classes.halfWidth}>
                <label htmlFor="endDate" className={classes.label}>
                End Date<span style={{color: 'red'}}> *</span>
                </label>
                <CustomDatePicker
                selectedDate={endDate || new Date()}
                onChange={(date) => handleInputChange({ target: { name: 'endDate', value: date?.toISOString().split('T')[0] } })}
                minDate={startDate}  
                />
            </div>
            </div>

            <div className={classes.buttonContainer}>
            <button
                type="button"
                onClick={handleContinue}
                className={classes.continueButton}
                disabled={!areFieldsValid()}
            >
                Continue
            </button>
            </div>
        </>
        )}


      {currentScreen === 2 && (
        <>
          <div className={classes.subheading}>
            <h3 style={{marginTop: '15px'}}>Goals</h3>
            <button type="button" onClick={addGoal} className={classes.addGoalButton}>
              Add Goal
            </button>
          </div>
          {goals.map((goal) => (
            <GoalForm
              key={goal.goalId ||goal.id}
              goal={goal}
              onRemove={removeGoal}
              startDate={formData.startDate}
              endDate={formData.endDate}
              onChange={(e) => handleInputChange(e, (goal.goalId || goal.id))}
              metadata={metadata}
            />
          ))}

          <div className={classes.buttonContainer}>
            <button
              type="button"
              onClick={() => {
                setCurrentScreen(1);
                setFormHeader("Create Care Plan");
              }}
              className={classes.backButton}
            >
              Back
            </button>
            <button onClick={handlePreview} className={classes.submitButton} disabled={!areGoalsValid()}>
              Preview
            </button>
          </div>
        </>
      )}

      {currentScreen === 3 && (
        <>
          <PreviewScreen
            formData={formData}
            goals={goals}
          />
          <div className={classes.buttonContainer}>
          <button
            type="button"
            onClick={() => {
              setCurrentScreen(2)
              setFormHeader("Create Care Plan")  
            }}
            className={classes.backButton}
          >
            Back
          </button>
          <button
              type="button"
              onClick={handleSend}
              className={classes.submitButton}
            >
              Submit
          </button>
          </div>
        </>
      )}
    </div>
  );
};

// GoalForm Component
const GoalForm = ({ goal, onRemove, onChange, startDate, endDate, metadata }) => {
  
  const classes = useStyles();
  const [activities, setActivities] = useState(goal.activities || []);
  const [isGoalCollapsed, setIsGoalCollapsed] = useState(false);
  const [collapsedActivities, setCollapsedActivities] = useState({});

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const handleStartDateChange = (date) => {
    onChange({ target: { name: 'startDate', value: date?.toISOString() } }, goal.goalId);
  };

  const handleEndDateChange = (date) => {
    onChange({ target: { name: 'endDate', value: date?.toISOString() } }, goal.goalId);
  };

  const activityKindOption = metadata?.activityKind.map((activity) => ({
    id: activity.id,
    kind: activity.kind,
    label: activity.display,
    description: activity.description,
  })) || [];

  const CustomOption = (props) => {
    const useStyles = makeStyles({
      option: {
        padding: '8px 12px',
        margin: '4px 0',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      },
      selectedOption: {
        backgroundColor: '#d3d3d3',
      },
      tooltip: {
        fontSize: '1rem',
      },
    });
    const { innerRef, innerProps, data, isFocused, isSelected } = props;
    const classes = useStyles();
  
    return (
      <Tooltip 
        title={data.description || ''} 
        placement="right" 
        classes={{ tooltip: classes.tooltip }}
        arrow
      >
        <div
          ref={innerRef}
          {...innerProps}
          className={`${classes.option} ${isSelected ? classes.selectedOption : ''}`}
          style={{ backgroundColor: isFocused && !isSelected ? '#f0f0f0' : 'transparent' }}
        >
          {data.label || data.display}
        </div>
      </Tooltip>
    );
  };
  

  const handleActivityChange = (e, activityId) => {
    const { name, value } = e.target;
    const updatedActivities = activities.map((activity) =>
      (activity.activityid === activityId || activity.id === activityId) ? { ...activity, [name]: value } : activity
    );
    setActivities(updatedActivities);
    onChange({ target: { name: 'activities', value: updatedActivities } }, (goal.goalId || goal.id));
  };

  const addActivity = () => {
    const newActivity = {
      id: null,
      activityid: Date.now(),
      title: "",
      description: "",
      kind: null,
      startDate: "",
      endDate: "",
      note: [],
    };
    const updatedActivities = [...activities, newActivity];
    setActivities(updatedActivities);
    onChange({ target: { name: 'activities', value: updatedActivities } },  (goal.goalId || goal.id));
  };

  const removeActivity = (activityId) => {
    const updatedActivities = activities.filter((activity) => (activity.activityid || activity.id) !== activityId);
    setActivities(updatedActivities);
    onChange({ target: { name: 'activities', value: updatedActivities } }, (goal.goalId || goal.id));
  };

  const toggleGoalCollapse = () => {
    setIsGoalCollapsed(!isGoalCollapsed);
  };

  const handleActivityKindChange = (selectedOption, activityId) => {
    const updatedActivities = activities.map((activity) =>
      (activity.activityid === activityId || activity.id === activityId) ? { ...activity, kind: selectedOption } : activity
    );
    setActivities(updatedActivities);
    onChange({ target: { name: 'activities', value: updatedActivities } }, goal.goalId || goal.id);
  };

  const toggleActivityCollapse = (activityId) => {
    setCollapsedActivities((prevState) => ({
      ...prevState,
      [activityId]: !prevState[activityId],
    }));
  };

  return (
    <div className={classes.goalContainer}>
      <div onClick={toggleGoalCollapse} style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center',marginBottom: '20px'}}>
        <h5 >Goal - {goal.title || 'Goal'}</h5>
        <div style={{display: 'flex',flexDirection: 'row', gap: '20px'}}>
        <a
        onClick={() => onRemove(goal.goalId || goal.id)}
        className={classes.iconBackground}
        >
          <img style={{height: '16px',width: '24px',}} src={TrashIcon} />
        </a>

        <button
          type="button"
          onClick={toggleGoalCollapse}
          className={classes.collapseButton}
        >
          {isGoalCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </button>
      </div>
      </div>

      {!isGoalCollapsed ? (
        <>
          <label htmlFor={`title-${(goal.goalId || goal.id)}`} className={classes.label}>
            Goal Title<span style={{color: 'red'}}> *</span>
          </label>
          <input
            type="text"
            id={`title-${(goal.goalId || goal.id)}`}
            name="title"
            value={goal.title}
            onChange={onChange}
            className={classes.input}
            required
          />

          <label htmlFor={`description-${(goal.goalId || goal.id)}`} className={classes.label}>
            Goal Description<span style={{color: 'red'}}> *</span>
          </label>
          <textarea
            id={`description-${(goal.goalId || goal.id)}`}
            name="description"
            rows="2"
            value={goal.description}
            onChange={onChange}
            className={classes.textarea}
            required
          ></textarea>

          <div className={classes.row}>
            <div className={classes.thirdWidth}>
              <label htmlFor={`priority-${(goal.goalId || goal.id)}`} className={classes.label}>
                Goal Priority<span style={{color: 'red'}}> *</span>
              </label>
              <select
                id={`priority-${(goal.goalId || goal.id)}`}
                name="priority"
                value={goal.priority}
                onChange={onChange}
                className={classes.input}
                required
              >
                <option value="" disabled>Select Priority</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>

            <div className={classes.thirdWidth}>
              <label htmlFor={`startDate-${(goal.goalId || goal.id)}`} className={classes.label}>
                Goal Start Date<span style={{color: 'red'}}> *</span>
              </label>
              <CustomDatePicker
                selectedDate={parseDate(goal.startDate) || parseDate(startDate)}
                onChange={handleStartDateChange}
                minDate={startDate}
                maxDate={endDate}
                required
              />
            </div>
            <div className={classes.thirdWidth}>
              <label htmlFor={`endDate-${(goal.goalId || goal.id)}`} className={classes.label}>
                Goal End Date<span style={{color: 'red'}}> *</span>
              </label>
              <CustomDatePicker
                selectedDate={parseDate(goal.endDate) || parseDate(endDate)}
                onChange={handleEndDateChange}
                minDate={goal.startDate || startDate}
                maxDate={endDate}
                required
              />
            </div>
          </div>

          <div className={classes.subheading}>
            <h3 style={{marginTop: '15px'}}>Activities</h3>
            <button
              type="button"
              onClick={addActivity}
              className={classes.addActivityButton}
            >
              Add Activity
            </button>
          </div>

          {activities.map((activity) => (
            <div key={activity.id} className={classes.activityContainer}>
              <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center',marginBottom: '20px'}}>
                <h5>Activity - {activity.title || 'Activity'}</h5>
                <div style={{display: 'flex',flexDirection: 'row', gap: '20px'}}>
                  <a
                    type="button"
                    onClick={() => removeActivity((activity.activityid || activity.id))}
                    className={classes.iconBackground}
                  >
                    <img style={{height: '16px',width: '24px',}} src={TrashIcon} />
                  </a>
                <button
                  type="button"
                  onClick={() => toggleActivityCollapse((activity.activityid || activity.id))}
                  className={classes.collapseButton}
                >
                  {collapsedActivities[(activity.activityid || activity.id)] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </button>
                </div>
              </div>

              {!collapsedActivities[(activity.activityid || activity.id)] ? (
                <>
                  <label
                    htmlFor={`title-${(activity.activityid || activity.id)}`}
                    className={classes.label}
                  >
                    Activity Title<span style={{color: 'red'}}> *</span>
                  </label>
                  <input
                    type="text"
                    id={`title-${(activity.activityid || activity.id)}`}
                    name="title"
                    value={activity.title}
                    onChange={(e) => handleActivityChange(e, (activity.activityid || activity.id))}
                    className={classes.input}
                  />

                  <label
                    htmlFor={`description-${(activity.activityid || activity.id)}`}
                    className={classes.label}
                  >
                    Activity Description<span style={{color: 'red'}}> *</span>
                  </label>
                  <textarea
                    id={`description-${(activity.activityid || activity.id)}`}
                    name="description"
                    rows="2"
                    value={activity.description}
                    onChange={(e) => handleActivityChange(e, (activity.activityid || activity.id))}
                    className={classes.textarea}
                  ></textarea>

                  {/* <label htmlFor={`note-${activity.id}`} className={classes.label}>
                    Note
                  </label>
                  <input
                    type="text"
                    id={`note-${activity.id}`}
                    name="note"
                    value={activity.note}
                    onChange={(e) => handleActivityChange(e, activity.id)}
                    className={classes.input}
                  /> */}

                  <div className={classes.row}>
                    <div className={classes.halfWidth}>
                      <label
                        htmlFor={`kind-${(activity.activityid || activity.id)}`}
                        className={classes.label}
                      >
                        Activity Kind<span style={{color: 'red'}}> *</span>
                      </label>
                      <Select
                        id={`kind-${(activity.activityid || activity.id)}`}
                        name="kind"
                        value={activityKindOption.find(option => (option?.id === activity?.kind?.id))}
                        onChange={(selectedOption) => handleActivityKindChange(selectedOption, activity.activityid)}
                  
                        options={activityKindOption}
                        components={{ Option: CustomOption }}
                        menuPlacement="top"                 
                      />
                    </div>
                    <div className={classes.halfWidth}>
                      <label
                        htmlFor={`startDate-${(activity.activityid || activity.id)}`}
                        className={classes.label}
                      >
                        Activity Start Date<span style={{color: 'red'}}> *</span>
                      </label>
                      <CustomDatePicker
                        selectedDate={parseDate(activity.startDate)}
                        onChange={(date) =>
                          handleActivityChange(
                            { target: { name: 'startDate', value: date?.toISOString() } },
                            (activity.activityid || activity.id)                          )
                        }
                        minDate={goal.startDate || startDate}
                        maxDate={goal.endDate || endDate}
                      />
                    </div>
                    <div className={classes.halfWidth}>
                      <label
                        htmlFor={`endDate-${(activity.activityid || activity.id)}`}
                        className={classes.label}
                      >
                        Activity End Date<span style={{color: 'red'}}> *</span>
                      </label>
                      <CustomDatePicker
                        selectedDate={parseDate(activity.endDate)}
                        onChange={(date) =>
                          handleActivityChange(
                            { target: { name: 'endDate', value: date?.toISOString() } },
                            (activity.activityid || activity.id)                          )
                        }
                        minDate={activity.startDate || goal.startDate}
                        maxDate={goal.endDate || endDate}
                      />
                    </div>
                  </div>

                </>
              ) : (
                <p className={classes.collapsedTitle}>
                  {/* {activity.activityTitle || 'Untitled Activity'} */}
                </p>
              )}
            </div>
          ))}
        </>
      ) : (
        <p className={classes.collapsedTitle}>
          {/* {goal.goalTitle || 'Goal'} */}
        </p>
      )}
    </div>
  );
};
   

export default CarePlanForm;
