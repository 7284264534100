import React from 'react';
import { Field } from 'react-final-form';
import {
  required as requiredField,
  composeValidators,
} from 'shared/validators';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import clsx from 'classnames';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    height: 90,
  },
  control: {
    '& fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  controlLabel: {},
}));

export default function FormMultiSelectField({
  name,
  label,
  options,
  required,
  placeholder,
  optionValueKey = 'id',
  optionLabelKey = 'name',
  disabled,
  setRoleOptionsBasedOnSelectedRole,
  ...otherProps
}) {
  const classes = useStyles();
  return (
    <Field
      name={name}
      validate={composeValidators(requiredField)}
      component="select">
      {({
        input: { name, value, onChange, ...formInputProps },
        meta: { error, touched },
      }) => (
        <FormControl
          required={required}
          error={error && touched}
          className={classes.formControl}>
          <Autocomplete
            disabled={disabled}
            style={{ marginRight: 16 }}
            fullWidth
            multiple
            size="small"
            id={name}
            options={options}
            getOptionLabel={option => option[optionLabelKey]}
            onChange={(event, newValue) => {
              if (setRoleOptionsBasedOnSelectedRole)
                setRoleOptionsBasedOnSelectedRole(newValue);
              onChange(newValue);
            }}
            value={value}
            filterSelectedOptions
            ChipProps={{
              color: 'primary',
              size: 'small',
              variant: 'outlined',
            }}
            renderInput={params => (
              <TextField
                {...params}
                required={required}
                variant="outlined"
                label={label}
                placeholder={placeholder}
                error={error && touched}
              />
            )}
            getOptionSelected={(option, value) =>
              option[optionValueKey] === value[optionValueKey]
            }
            classes={{
              inputRoot: clsx({ [classes.control]: error && touched }),
              inputFocused: clsx({ [classes.control]: error && touched }),
            }}
            {...formInputProps}
            {...otherProps}
          />

          {error && touched && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}
