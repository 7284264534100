import React, { useState, useEffect, useCallback } from 'react';
import {
  SecondaryButton,
  PrimaryButton,
  BackendSearchTextField,
} from 'components/widgets';
import { Typography, Grid } from '@material-ui/core';
import DocPopup from 'components/widgets/FlyoutPanel/DocPopup';
import DocumentDropzone from 'containers/SetupOrganization/DocumentDropzone';
import { useSnackbar } from 'notistack';
import { SimpleBackdrop } from 'components/widgets';
import DemoApis from 'services/api/DemoApis';
import DocGrid from './DocGrid';
import FilterDropDown from 'components/FilterDropDown';

const DocumentUpload = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [categoryValue, setCategoryValue] = useState('all');
  const [docCategoryValue, setDocCategoryValue] = useState('financial');
  const [searchValue, setSearchValue] = useState('');

  const onToggle = () => {
    setShow(!show);
    setDisable(true);
  };

  const handleDownloadDoc = async docArn => {
    try {
      setLoading(true);
      const response = await DemoApis.downloadDocument(docArn);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = window.document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docArn.split('/')[4]}`);
      window.document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Document downloaded successfully', {
        variant: 'success',
      });
      setLoading(false);
    } catch {
      enqueueSnackbar('Unable to download the document', {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  // const handleDeleteDoc = async (docId) => {
  //   console.log(docId);
  //   try {
  //     setLoading(true);
  //     await DemoApis.deleteDocument(docId);
  //     setLoading(false);
  //     enqueueSnackbar('Document deleted successfully', { variant: 'success' });
  //     getListing()
  //   } catch (e) {
  //     setLoading(false);
  //     console.log(e);
  //     enqueueSnackbar('Something went wrong while deleting the document', {
  //       variant: 'error',
  //     });
  //   }
  // }

  const onChangeText = text => {
    setSearchValue(text);
  };

  const getListing = useCallback(() => {
    async function listing() {
      try {
        setLoading(true);
        const details = await DemoApis.getAllDocuments();
        setFilteredData(details.recordList);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    listing();
  }, []);

  useEffect(() => {
    getListing();
  }, [getListing]);

  const statusFilterOptions = {
    options: [
      { label: 'All', value: 'all' },
      { label: 'Financial', value: 'financial' },
    ],
    value: categoryValue,
    changeHandler: ev => {
      setCategoryValue(ev.target.value);
    },
  };

  useEffect(() => {
    if (filteredData.length !== 0) {
      if (searchValue === '') {
        setFilteredData(filteredData);
      }
    }
  }, [filteredData, searchValue]);

  const uploadStatusFilterOptions = {
    options: [{ label: 'Financial', value: 'financial' }],
    value: docCategoryValue,
    changeHandler: ev => {
      setDocCategoryValue(ev.target.value);
    },
  };

  const uploadDocument = async () => {
    const formData = new FormData();
    formData.append('files', document);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      onToggle();
      setLoading(true);
      await DemoApis.uploadDocument(formData, otherHeaders);
      setLoading(false);
      enqueueSnackbar('Document uploaded successfully', {
        variant: 'success',
      });
      getListing();
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while uploading document';
      onToggle();
      setLoading(false);
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const handleButtonClick = props => {
    if (props) {
      setDocument(props);
      setDisable(false);
    }
  };

  return (
    <div style={{ marginLeft: '16px' }}>
      <Typography
        variant="h2"
        style={{ margin: '24px 16px', fontWeight: 'bold', fontSize: 28 }}
      >
        {`Document upload`}
      </Typography>
      <Grid container style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              style={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                marginRight: '285px',
              }}
            >
              <div style={{ width: '300px' }}>
                <BackendSearchTextField
                  label="Search by document name"
                  onChangeText={onChangeText}
                  value={searchValue}
                  clearData={() => setSearchValue('')}
                  onSearchClick={() => {}}
                />
              </div>
              <div style={{ maxWidth: '200px', marginTop: '-8px' }}>
                <FilterDropDown
                  {...statusFilterOptions}
                  filterType="Category: "
                />
              </div>
            </Grid>

            <Grid item xs={12} md={7} lg={5}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <PrimaryButton onClick={() => setShow(true)}>
                  Upload document
                </PrimaryButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DocGrid
            rowData={
              searchValue.length > 0
                ? filteredData.filter(
                    fItem =>
                      fItem.documentName
                        .toLowerCase()
                        .indexOf(searchValue.toLowerCase()) !== -1
                  )
                : filteredData
            }
            onDownload={item => handleDownloadDoc(item.documentUrl)}
          />
        </Grid>
      </Grid>

      <DocPopup
        onClose={onToggle}
        open={show}
        title={'Upload document'}
        subtitle={
          'All the members tied to this account will have access to this document'
        }
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '560px', marginTop: '24px' }}>
              <DocumentDropzone handleButtonClick={handleButtonClick} />
            </div>
            <div style={{ maxWidth: '250px' }}>
              <FilterDropDown
                {...uploadStatusFilterOptions}
                filterType="Select category: "
              />
            </div>
          </div>
          <div style={{ marginTop: '48px' }}>
            {
              <React.Fragment>
                <SecondaryButton
                  onClick={onToggle}
                  size="large"
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  type="add"
                  disabled={disable}
                  onClick={uploadDocument}
                >
                  Add
                </PrimaryButton>
              </React.Fragment>
            }
          </div>
        </div>
      </DocPopup>
      <SimpleBackdrop open={loading} />
    </div>
  );
};

export default DocumentUpload;
