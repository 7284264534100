import React from 'react';
import { FlyoutPanel } from 'components/widgets';
import ManualScheduleType from './ManualScheduleType';
import FileSensedScheduleType from './FileSensedScheduleType';
import TimeBasedScheduleType from './TimeBasedScheduleType';

export default function DataLoadConfig({
  onToggle,
  title,
  open,
  onSubmit,
  dataLoadType,
  orgId,
  lobId,
  affId,
  prePopulateData,
}) {
  return (
    <FlyoutPanel title={title} open={open} onToggle={onToggle} maxWidth="md">
      {dataLoadType === 'manual' && (
        <ManualScheduleType
          onToggle={onToggle}
          onSubmit={onSubmit}
          dataLoadType={dataLoadType}
          orgId={orgId}
          lobId={lobId}
          affId={affId}
          prePopulateData={prePopulateData}
        />
      )}

      {dataLoadType === 'file sensed' && (
        <FileSensedScheduleType
          onToggle={onToggle}
          onSubmit={onSubmit}
          dataLoadType={dataLoadType}
          orgId={orgId}
          lobId={lobId}
          affId={affId}
          prePopulateData={prePopulateData}
        />
      )}

      {dataLoadType === 'time based' && (
        <TimeBasedScheduleType
          onToggle={onToggle}
          onSubmit={onSubmit}
          dataLoadType={dataLoadType}
          orgId={orgId}
          lobId={lobId}
          affId={affId}
          prePopulateData={prePopulateData}
        />
      )}
    </FlyoutPanel>
  );
}
