import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getStore } from './store';
import { LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
// import DispatcherService from './services/DispatcherService';
// import history from './history';
// import Piwik from './analytics/Piwik';

function Root({ children, initialState = {} }) {
  const [loading, setLoading] = useState(true);
  const store = getStore(initialState);

  const { initialized } = useKeycloak();

  useEffect(() => {
    setLoading(!initialized);
  }, [initialized]);

  // DispatcherService.storeDispatcher(store.dispatch);

  if (loading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>{children}</BrowserRouter>
    </Provider>
  );
}

export default Root;
