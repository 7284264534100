import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { LOADER_DEFAULT_TXT } from '../constants';
import useWidth from '../hooks/useWidth';

const styles = (theme) => ({
  loader: {
    color: '#124987',
  },
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    cursor: 'not-allowed',
    zIndex: 100,
  },
  text: {
    marginLeft: '15px',
    marginTop: '5px',
    fontSize: '15px',
    fontWeight: 600,
  },
  overlayText: {
    color: 'white',
    marginTop: '5px',
    fontSize: '15px',
    fontWeight: 600,
    marginLeft: '15px',
  },
  content: {
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const Loader = (props) => {
  const { classes, text, overlay } = props;

  const screenWidth = useWidth();

  const isLarge = screenWidth === 'lg' || screenWidth === 'xl';

  return overlay ? (
    <div id="overlay" className={classes.root}>
      <div id="overlay-content-container" className={classes.content}>
        <CircularProgress
          id="overlay-loader"
          disableShrink
          className={classes.loader}
          size={isLarge ? '85px' : '70px'}
        />
        <Typography id="overlay-loader-text" className={classes.overlayText}>
          {text && `${text} . . .`}
        </Typography>
      </div>
    </div>
  ) : (
    <div id="content-container" className={classes.content}>
      <CircularProgress
        id="loader"
        disableShrink
        className={classes.loader}
        size={isLarge ? '85px' : '70px'}
      />
      <Typography id="loader-text" className={classes.text}>
        {text && `${text} . . .`}
      </Typography>
    </div>
  );
};

Loader.defaultProps = {
  text: LOADER_DEFAULT_TXT,
};
export default withStyles(styles, { withTheme: true })(Loader);
