import { memo, useEffect, useState } from 'react';

const PhoneCellTemplate = memo(function PhoneCellTemplate({ value }) {
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    if (value && value.trim().length === 10) {
      const temp = value.trim();
      const phone = `+1 (${temp.substring(0, 3)}) ${temp.substring(
        3,
        5
      )} - ${temp.substring(6, 10)}`;

      setFormattedValue(phone);
    }
  }, [value]);

  return <span>{formattedValue}</span>;
});

export default PhoneCellTemplate;
