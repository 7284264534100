import BaseApiService from './BaseApiService';

function _createCriteria({ orgId, payload }) {
  return BaseApiService.post(`/organization/${orgId}/criteria`, null, payload);
}

function _getAllCriteria(orgId, reqBody) {
  return BaseApiService.post(
    `/organization/${orgId}/criteria/all`,
    null,
    reqBody
  );
}

function _deleteCriteria(orgId, criteriaId) {
  return BaseApiService.delete(`/organization/${orgId}/criteria/${criteriaId}`);
}

function _createRules({ orgId, payload }) {
  return BaseApiService.post(
    `/organization/${orgId}/rule/byName`,
    null,
    payload
  );
}
function _getAllRules(orgId, reqBody) {
  return BaseApiService.post(
    `/organization/${orgId}/rule/byName`,
    null,
    reqBody
  );
}

function _deleteRule(orgId, ruleId) {
  return BaseApiService.delete(`/organization/${orgId}/rule/${ruleId}`);
}

function _saveRules({ orgId, payload }) {
  return BaseApiService.put(`/${orgId}/rule`, null, payload);
}

function _updateScheduler({ orgId, payload, ruleId }) {
  return BaseApiService.put(`/${orgId}/rule/${ruleId}`, null, payload);
}

function _checkForDuplicateTitle({ orgId, ruleTitle }) {
  return BaseApiService.get(`/${orgId}/rule/check-name?searchName=${ruleTitle}`);
}

function _nextSchedule(rulesId) {
  return BaseApiService.get(`/organization/schedules/RuleEngine/RuleEngine${rulesId}`);
}

function _deleteSchedule({ orgId, payload, ruleId }) {
  return BaseApiService.put(`/${orgId}/rule/delete/${ruleId}`, null, payload);
}

const RulesApi = {
  createCriteria: _createCriteria,
  getAllCriteria: _getAllCriteria,
  deleteCriteria: _deleteCriteria,
  createRules: _createRules,
  getAllRules: _getAllRules,
  deleteRule: _deleteRule,
  saveRules: _saveRules,
  checkForDuplicateTitle: _checkForDuplicateTitle,
  updateSchedule: _updateScheduler,
  nextSchedule : _nextSchedule,
  deleteSchedule : _deleteSchedule,
};

export default RulesApi;
