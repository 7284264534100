import { SlideOutPanel } from 'components/widgets';

import ManageAlertsContent from './ManageAlertsContent';

function ManageAlertsSlideout({ open, onToggle, ...otherProps }) {
  return (
    <SlideOutPanel size="small" title="Alerts" open={open} onToggle={onToggle}>
      {open && (
        <ManageAlertsContent open={open} onToggle={onToggle} {...otherProps} />
      )}
    </SlideOutPanel>
  );
}

export default ManageAlertsSlideout;
