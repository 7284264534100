import BaseApiService from './BaseApiService';

function getPermissionsCode() {
  return BaseApiService.get(`/permissions`);
}

function getRolePermissions({ roleId }) {
  return BaseApiService.get(`/permissions/role/${roleId}`);
}

function getRolePermissionForSolutions({ roleId,solutionId="" }) {
  return BaseApiService.get(`/permissions/role/${roleId}?solutionId=${solutionId}`);
}


function updatePermissionToRole({ orgId, roleId, payload }) {
  return BaseApiService.post(
    `/${orgId}/role/${roleId}/permissions`,
    null,
    payload
  );
}

const PermissionApi = {
  getPermissionsCode,
  getRolePermissions,
  updatePermissionToRole,
  getRolePermissionForSolutions
};

export default PermissionApi;
