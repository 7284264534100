import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  BackendSearchTextField,
  PageHeaderTitle,
  SimpleBackdrop,
} from 'components/widgets';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { makeStyles } from '@material-ui/core/styles';
// import MembersDataGrid from './MembersDataGrid';
import { Grid, Button } from '@material-ui/core';
import DataPartnersApi from 'services/api/DataPartnersApi';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import * as actions from 'store/actions';
import CMDEDataGrid from './CMDEDatGrid';
import MemberViewDetail from 'containers/Members/MemberViewDetail';
import FilterDropDown from 'components/FilterDropDown';
import { CMDEStatusFilterMenuItems } from 'shared/constants';
// import ConsentTrigger from './ConsentForm/ConsentTrigger';
// import ConsentInfoModal from './ConsentInfoModal';
import { useHasPermissions } from 'shared/utility';
import CampaignApi from 'services/api/CampaignApi';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    borderRadius: '40px',
    color: '#036FCB',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
    marginRight: '12px',
  },
}));

const CMDE = ({ title, history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [name, setName] = useState('');
  const [affiliateIds, setAffiliateIds] = useState([]);
  const [lobIds, setLobIds] = useState([]);
  const [consentArray, setConsentArray] = useState([]);
  const [expiryDate, setExpiryDate] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showChip, setShowChip] = useState(false);
  const [showConsentInfoModal, setShowConsentInfoModal] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [messageVariant, setMessageVariant] = useState('');
  const [flyout, setFlyout] = useState({ open: false });
  const [checkForFilter, setCheckForFilter] = useState(false);
  const [CMDEDetailsData, setCmdeDetailsData] = useState({});
  const [statusFilter, setStatusFilter] = useState('all');
  const [labelFilterOptions, setLabelFilterOptions] = useState([ { label: 'All', value: 'all' }]);
  const [labelFilter, setLabelFilter] = useState('all');

  const permissions = useHasPermissions({
    featureName: 'CMDE',
    subFeatureName: 'Members',
  });

  const [allFiltersValues, setAllFiltersValues] = React.useState({
    affiliateStatus: [],
    consentStatus: [],
    expireOn: [],
    lobStatus: [],
  });

  const { affiliateStatus, consentStatus, expireOn, lobStatus } =
    allFiltersValues;

  const affiliateIsChecked = affiliateStatus?.filter(
    (data, i) => data.isChecked
  );

  const affiliateFilterValues = affiliateIsChecked?.map(
    (item, i) => item.value
  );

  const lobIsChecked = lobStatus?.filter((data, i) => data.isChecked);
  const selectedAll = useRef(false);
  const lobFilterValues = lobIsChecked?.map((item, i) => item.value);

  const expireOnIsChecked = expireOn?.filter((data, i) => data.isChecked);

  const expireOnFilterValues = expireOnIsChecked?.map((item, i) => item.value);

  const consentValues = consentStatus
    ?.filter(data => data.isChecked)
    .map(item => item.value);
  const affiliateValues = affiliateStatus
    ?.filter(data => data.isChecked)
    .map(item => item.key);
  const lobsValues = lobStatus
    ?.filter(data => data.isChecked)
    .map(item => item.key);
  const expiresOnValue = expireOn?.filter(data => data.isChecked)[0]?.value;

  const orgId = sessionStorage.getItem('orgId');

  const getAllMembersData = useCallback(
    async orgId => {
      setLoading(true);

      try {
        const results = await DataPartnersApi.getAllMembers(
          orgId,
          searchValue,
          statusFilter === 'all' ? '' : statusFilter ?? '',
          labelFilter === 'all'  ? '' : labelFilter ?? '',
        );
        setTotalItems(results?.length || 0);
        setFilteredMembers(results);
        if (selectedAll.current && checkForFilter) {
          setSelectedRows(results.data);
          setCheckForFilter(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    },
    [searchValue, statusFilter,labelFilter]
  );

  useEffect(() => {
    if (orgId) {
      // getAllPartners(orgId);
      getAllMembersData(orgId);
      // getAllLobs(orgId);
    }
  }, [orgId, getAllMembersData, statusFilter,labelFilter]);

  useEffect(() => {
    async function fetchLabels() {
      try {
        const response = await CampaignApi.getLabels(orgId);
        const options = response.map(label => ({
          label: label.name,
          value: label.id
        }));
        setLabelFilterOptions(prevOptions => [...prevOptions, ...options]);
      } catch (error) {
        console.error('Error fetching labels:', error);
      }
    }

    if (orgId) {
      fetchLabels();
    }
  }, [orgId]);

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setName(searchValue);
  };

  const statusFilterOptions = {
    options: CMDEStatusFilterMenuItems,
    value: statusFilter,
    changeHandler: ev => {
      setStatusFilter(ev.target.value);
    },
  };

  const lableFilterOptions = {
    options: labelFilterOptions,
    value: labelFilter,
    changeHandler: ev => {
      setLabelFilter(ev.target.value);
    },
  };

  const onPageClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    offset !== 0 && setOffset(0);
    setName('');
  };

  useEffect(() => {
    if (consentArray?.length && !consentValues?.length && active === false) {
      offset !== 0 && setOffset(0);
      setConsentArray([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentArray, consentValues]);

  useEffect(() => {
    if (affiliateIds?.length && !affiliateValues?.length && active === false) {
      offset !== 0 && setOffset(0);
      setAffiliateIds([]);
    }
    if (active) {
      setShowChip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateIds, affiliateValues, active]);

  useEffect(() => {
    if (lobIds?.length && !lobsValues?.length && active === false) {
      offset !== 0 && setOffset(0);
      setLobIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lobIds, lobsValues]);

  useEffect(() => {
    if (expiryDate !== '' && !expiresOnValue && active === false) {
      offset !== 0 && setOffset(0);
      setExpiryDate('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresOnValue, expiryDate]);

  useEffect(() => {
    localStorage.removeItem('memberData');
  }, []);

  const onViewClick = data => {
    setFlyout({ open: true });
    setCmdeDetailsData(data);
  };

  const onToggle = () => {
    setFlyout({ open: false });
  };

  function isJsonString(item) {
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === 'object' && item !== null) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    function handleMessage(event) {
      if (isJsonString(event.data)) {
        const messageObject = JSON.parse(event.data);
        if (messageObject.flowTypeValue === 'DDUI') {
          exitDDUI();
          setMessageVariant(messageObject.variant);
          setMessageValue(messageObject.message);
          setShowConsentInfoModal(true);
          //Handle Error or Success message based on messageObject.variant
        }
      }
    }
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const exitDDUI = () => {
    setOpenDrawer(false);
  };

  const handleMembersAction = (data, type, successMsg, propName) => {
    let payload = {
      memberId: data?.memberId || '',
      activate: data?.memberEnabled || false,
      isUserReadOnly: data?.isUserReadOnly || false,
      communicationPreferences: data?.communicationPreferences || [],
    };
    if (type !== 'communicationPreferences' && type !== 'changePassword') {
      payload[type] = !data[propName];
    }
    setLoading(true);

    const URL =
      type === 'changePassword'
        ? DataPartnersApi.updatePassword({ email: data?.email, orgId })
        : DataPartnersApi.membersActions({ payload, orgId });
    URL.then(res => {
      setLoading(false);
      getAllMembersData(orgId);
      enqueueSnackbar(successMsg || 'Success', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    }).catch(err => {
      enqueueSnackbar(err.response?.data?.details || 'Something went wrong', {
        variant: 'error',
        autoHideDuration: 2500,
      });
      setLoading(false);
    });
  };

  return (
    <>
      {/* {permissions?.READ && ( */}
        <div>
          <Grid
            container
            alignItems="center"
            style={{ marginBottom: 16, marginTop: 16 }}
          >
            <Grid item>
              <PageHeaderTitle>Members</PageHeaderTitle>
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <div
              style={{
                // display: 'flex',
                // flexWrap: 'wrap',
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '280px',
                      marginRight: '16px',
                      height: '40px',
                    }}
                  >
                    <BackendSearchTextField
                      label="Search by name"
                      onChangeText={onChangeText}
                      value={searchValue}
                      clearData={onClearData}
                      onSearchClick={onSearchClick}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSearchClick}
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                  <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                    <FilterDropDown
                      {...statusFilterOptions}
                      filterType="Status:"
                    />
                  </div>
                  <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
                    <FilterDropDown
                      {...lableFilterOptions}
                      filterType="Label:"
                    />
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: 'lightgrey',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '15px',
                    marginRight: '20px',
                    height: '40px',
                  }}
                >
                  <h5 style={{ padding: '10px' }}>
                    Members count: {totalItems}
                  </h5>
                </div>
              </div>
            </div>

            <Grid item xs={12}>
              <CMDEDataGrid
                apiLoading={loading}
                rowData={filteredMembers}
                onView={onViewClick}
                orgId={orgId}
                handleMembersAction={handleMembersAction}
                hasReadPermission={permissions?.READ}
                hasEditPermission={permissions?.CREATE}
              />
            </Grid>
          </Grid>

          {flyout.open && (
            <MemberViewDetail
              open={flyout.open}
              setFlyout={setFlyout}
              onToggle={onToggle}
              CMDEDetailsData={CMDEDetailsData}
            />
          )}

          <SimpleBackdrop open={loading} />
        </div>
      {/* )} */}
    </>
  );
};

export default CMDE;
