import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box, Button, Typography } from '@material-ui/core';
import { PrimaryButton } from 'components/widgets';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#EBF5FB',
    borderRadius: '16px',
  },
  btn: {
    textTransform: 'none',
    color: '#3498DB',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const Banner = () => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToInviteUsers = () => {
    const orgId = sessionStorage.getItem('orgId');
    history.push({
      pathname: `/list/${orgId}/users`,
      state: { shouldOpenInvite: true },
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  Invite your organization user
                </Typography>
                <Typography>
                  Get the most out of Mpoweredhealth by inviting your
                  organization user
                </Typography>{' '}
              </Box>
              <Box sx={{ display: 'flex' }}>
                <PrimaryButton
                  data-testid="invite"
                  onClick={redirectToInviteUsers}
                >
                  Invite
                </PrimaryButton>
                {/* <Button className={classes.btn}>Skip</Button> */}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Banner;
