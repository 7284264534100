import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import CriteriaDropdown from 'containers/Rules/formElements/criteriaDropdown';
import React, { useEffect, useRef, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AttributeApi from 'services/api/AttributeApi';
import { SimpleBackdrop } from 'components/widgets';
import { format, isValid } from 'date-fns';
import { useSnackbar } from 'notistack';
import QueryDetailTable from './QueryDetailTable';
import { findDataType } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
    color: '#344054',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  active: {
    '& .MuiStepLabel-active': {
      color: '#036FCB',
    },
  },
  attrLabelTitle: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#101828',
    marginBottom: '10px',
  },
  attrValuesContainer: {
    minHeight: '72px',
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
    minWidth: '340px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px 0px',
    justifyContent: 'center',
  },
  attrDefaultValuesContainer: {
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
    display: 'flex',
    padding: '10px ',
    gap: '10px',
    alignItems: 'center',
    overflowY: 'auto',
    maxWidth: '600px',
    width: 'fit-content',
    flexWrap: 'wrap',
    maxHeight: '150px',
    margin: '10px 0px',
  },
}));

const EmptyGridForUI = () => <Grid item sm={9} xs={6} />;

const FHIR_OPTIONS = ['FHIR', 'Non-FHIR'];

const TEXT_FIELD_RESOURCE_COMPONENTS = {
  TOKEN_WITHOUT_VALUES: true,
};

const ATTR_LABEL_TITLE = 'Value';

const CreateAttributeDetails = ({
  StepperCustomComponent,
  setActiveStep,
  customStyles,
  setAttributeDetails,
  attributeDetails,
  isEdit,
  attributeTitle,
  setEditFlag,
  setAlertFlag,
  setAttrType,
  attrType,
}) => {
  const initLoad = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attrTitle, setAttrTitle] = useState(attributeDetails?.name || '');
  const [description, setDescription] = useState(
    attributeDetails?.description || ''
  );
  const [query, setQuery] = useState(attributeDetails?.details?.query || '');
  const [selectedDate, setSelectedDate] = useState(null);
  const [uiComponent, setUIComponent] = useState();

  const handleDateChange = date => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const [fhirResourceOptions, setFhirResourceOptions] = useState();
  const [dataSourceOptionS, setDataSourceOptions] = useState([]);
  const [fhirResourceValue, setFhirResourceValue] = useState(
    attributeDetails?.details?.resource || 'placeholder'
  );
  const [fhirComponentOptions, setFhirComponentOptions] = useState();
  const [fhirComponentValue, setFhirComponentValue] = useState(
    attributeDetails?.details?.component || 'placeholder'
  );

  const [dataSourceValue, setDataSourceValue] = useState(
    attributeDetails?.details?.dataSource || 'placeholder'
  );
  const [paramList, setParamList] = useState(
    attributeDetails?.details?.parameters || []
  );

  const [fhirComponentPreDefinedValues, setFhirComponentPreDefinedValues] =
    useState();

  const [datePickerChecked, setDatePickerChecked] = useState(
    attributeDetails?.details?.isDatePicker === undefined
      ? true
      : attributeDetails?.details?.isDatePicker
  );
  const [attributeValues, setAttributeValues] = useState(
    !isValid(new Date(attributeDetails?.details?.value?.[0] || []))
      ? attributeDetails?.details?.value
      : [] || []
  );

  useEffect(() => {
    if (attributeDetails?.name) {
      if (attrTitle !== attributeDetails.name) {
        setEditFlag(false);
      }
    }
  }, [attrTitle, attributeDetails?.name]);

  const handleAddAttributes = () => {
    setAttributeValues(prev => [
      ...prev,
      {
        code: '',
        displayValue: '',
      },
    ]);
    setTimeout(scrollIntoView, 0);
  };

  const scrollIntoView = () => {
    let element = document.getElementById('scroll-view');
    let focusTextField = document.getElementById('scroll-view-text-field');
    if (element && focusTextField) {
      focusTextField.focus();
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onAttrValuesChange = (value, idx, prop) => {
    let currentValues = [...attributeValues];
    currentValues[idx][prop] = value;
    setAttributeValues(currentValues);
  };
  const onAttrvDelete = idx => {
    let prevValues = [...attributeValues];
    prevValues.splice(idx, 1);
    setAttributeValues(prevValues);
  };
  const onFhirResourceChange = value => {
    fetchFhirComponent(value);
    setFhirResourceValue(value);
  };

  const onDataSourceChange = value => {
    setDataSourceValue(value);
  };

  const onFhirComponentChange = e => {
    let selectedValue = fhirComponentOptions.filter(
      item => item.name === e.target.value
    )[0];
    if (selectedValue?.type === 'TOKEN') {
      fetchFhirComponentPreDefinedValues(e.target.value);
    } else if (selectedValue?.type === 'DATE') {
      if (!isEdit && !initLoad.current) {
        setFhirComponentPreDefinedValues()
        setDatePickerChecked(true);
      }
    } else {
      setFhirComponentPreDefinedValues();
    }
    setUIComponent(selectedValue?.type);
    setFhirComponentValue(e.target.value);
    // setSelectedDate(new Date());
    setAttributeValues([]);
  };

  const fetchFhirComponent = resourceName => {
    setLoading(true);
    AttributeApi.getFhirComponent(resourceName)
      .then(response => {
        setFhirComponentOptions(response);
        let responseType = response.filter(
          val => val.name === fhirComponentValue
        )?.[0];
        if (initLoad.current && responseType) {
          // Edit Functionality

          if (responseType.type === 'DATE') {
            // setSelectedDate(
            //   new Date(attributeDetails?.details?.value?.[0]?.code)
            // );
            if (!isEdit && !initLoad.current) {
              setDatePickerChecked(true);
            }
          } else if (responseType.type === 'TOKEN') {
            fetchFhirComponentPreDefinedValues(responseType.name);
          }

          setUIComponent(responseType?.type);
        } else {
          setFhirComponentPreDefinedValues();
          setUIComponent();
          setFhirComponentValue('placeholder');
          setAttributeValues([]);
        }

        setLoading(false);
        initLoad.current = false;
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  const fetchFhirComponentPreDefinedValues = value => {
    setLoading(true);
    AttributeApi.getFhirComponentPreDefinedValues(fhirResourceValue, value)
      .then(res => {
        if (res?.length !== 0 && res) {
          setFhirComponentPreDefinedValues(res);
        } else {
          setUIComponent('TOKEN_WITHOUT_VALUES');
          setFhirComponentPreDefinedValues();
        }
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  const fetchDataSource = () => {
    setLoading(true);
    AttributeApi.dataSource()
      .then(response => {
        setDataSourceOptions(response);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDataSource();
  }, []);

  const handleAddAttribute = () => {
    const retrieveAttributeValues = () => {
      switch (uiComponent) {
        case 'TOKEN':
          return fhirComponentPreDefinedValues || [];
        // case 'TOKEN_WITHOUT_VALUES':
        //   return attributeValues || [];
        // case 'DATE':
        //   let date = format(selectedDate, 'MM/dd/yyyy');
        //   return [
        //     {
        //       code: date,
        //       displayValue: date,
        //     },
        //   ];
        default:
          return [];
      }
    };
    const dataType = fhirComponentOptions?.find(
      option => option.name === fhirComponentValue
    )?.type;

    const dataSource = dataSourceOptionS?.find(
      option => option.name === dataSourceValue
    )?.name;

    setAttributeDetails({
      name: attrTitle.trim(),
      type: attrType,
      description: description,
      details: {
        resource: fhirResourceValue,
        component: fhirComponentValue,
        value: retrieveAttributeValues(),
        isDatePicker: uiComponent === 'DATE' ? datePickerChecked : false,
        internalComponentName: '', // Internal Component is optional for R1
        dataType: dataType,
        dataSource: dataSource,
        parameters: paramList,
        query: query,
      },
    });
    setActiveStep(1);
  };

  const onProceed = async () => {
    // if (uiComponent === 'TOKEN_WITHOUT_VALUES') {
    //   for (const { displayValue, code } of attributeValues) {
    //     if (displayValue === '' || code === '') {
    //       enqueueSnackbar('Please provide attribute value', {
    //         variant: 'error',
    //         autoHideDuration: 1500,
    //       });
    //       return;
    //     }
    //   }
    // } else if (
    //   uiComponent === 'DATE' &&
    //   (!isValid(new Date(selectedDate)) || selectedDate === null)
    // ) {
    //   enqueueSnackbar('Please provide valid date', {
    //     variant: 'error',
    //     autoHideDuration: 1500,
    //   });
    //   return;
    // }
    if (isEdit && attrTitle === attributeTitle) {
      handleAddAttribute();
      return;
    }
    setLoading(true);
    await AttributeApi.getAttributeTitle(attrTitle.trim())
      .then(response => {
        if (response?.length !== 0) {
          enqueueSnackbar(
            'This attribute already exists in the system. Please enter different title',
            {
              variant: 'error',
              autoHideDuration: 1500,
            }
          );
          document.getElementById('attribute-title').focus();
          setLoading(false);
          return;
        }
        handleAddAttribute();
      })
      .catch(err => {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          autoHideDuration: 1500,
        });
        setLoading(false);
      });
  };

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    AttributeApi.getFhirResource()
      .then(response => {
        setFhirResourceOptions(response);
        if (!initLoad.current || fhirResourceValue === '') {
          setFhirResourceValue(response[0]);
        }
        if (initLoad.current && attributeDetails?.details?.resource) {
          fetchFhirComponent(attributeDetails?.details?.resource);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEdit) {
      if (
        attrTitle !== '' ||
        description !== '' ||
        (fhirComponentValue === 'placeholder' && attrType === 'FHIR') ||
        (fhirResourceValue === 'placeholder' && attrType === 'FHIR') ||
        (dataSourceValue === 'placeholder' && attrType === 'Non-FHIR') ||
        (query !== '' && attrType === 'Non-FHIR')
      ) {
        setAlertFlag(false);
      } else {
        setAlertFlag(true);
      }
    }
  }, [
    attrTitle,
    description,
    fhirComponentValue,
    fhirResourceValue,
    dataSourceValue,
    query,
    isEdit,
  ]);

  const handleQuery = e => {
    const query = e.target.value;
    if (!query.includes(':')) {
      setQuery(query);
      setParamList([]);
      return;
    }
    const list = [];

    const parameters = query.split(/[:]/);
    parameters.forEach((val, indx) => {
      if (indx > 0 && val.match(/^[a-zA-Z0-9]+/)) {
        const finalValue = val?.split(/[\s,;]/)[0];
        list.push(finalValue);
      }
    });

    const paramsList = list.map(val => ({
      name: val,
      dataType:
        paramList.find(paramValue => paramValue.name === val)?.dataType || null,
      value: null,
    }));
    setParamList(paramsList);
    setQuery(query);
  };

  return (
    <>
      <FullScreenDialog.Content>
        {StepperCustomComponent && <StepperCustomComponent />}
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item sm={3} xs={6}>
            <Typography className={classes.formTitle}>
              Attribute title <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              required
              id="attribute-title"
              margin="dense"
              variant="outlined"
              className={classes.formTextField}
              value={attrTitle}
              onChange={e => setAttrTitle(e.target.value)}
              autoComplete="off"
              autoFocus
              inputProps={{ 'data-testid': 'attribute-title-textbox' }}
              placeholder="Enter name of your attribute"
            />
          </Grid>
          <EmptyGridForUI />
          <Grid item sm={3} xs={6}>
            <Typography className={classes.formTitle}>
              Description <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              required
              id="outlined-basic"
              margin="dense"
              variant="outlined"
              className={classes.formTextField}
              placeholder="Enter description of attribute"
              multiline
              value={description}
              onChange={e => setDescription(e.target.value)}
              minRows={4}
              disabled={isEdit}
              inputProps={{
                maxLength: 100,
                'data-testid': 'attribute-description-textbox',
              }}
              helperText={`${description.length}/100`}
              FormHelperTextProps={{
                style: {
                  // textAlign: 'center',
                  position: 'absolute',
                  bottom: '0%',
                  right: '0%',
                },
              }}
            />
          </Grid>
          <EmptyGridForUI />
          <Grid item sm={3} xs={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.formTitle}>
                Type of attribute <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <RadioGroup
                style={{ display: 'flex', flexDirection: 'row' }}
                aria-label="gender"
                name="gender1"
                value={attrType}
                onChange={(e, value) => setAttrType(value)}>
                {FHIR_OPTIONS.map(val => (
                  <FormControlLabel
                    disabled={isEdit}
                    key={val}
                    value={val}
                    control={<Radio color="primary" />}
                    label={val}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <EmptyGridForUI />
          {attrType === 'FHIR' ? (
            <>
              <Grid item sm={3} xs={6}>
                <CriteriaDropdown
                  placeHolderText={'Select the FHIR resource'}
                  title={'FHIR resource'}
                  required={true}
                  value={fhirResourceValue}
                  disabled={isEdit}
                  options={fhirResourceOptions}
                  optionValueKey={null}
                  optionLabelKey={null}
                  handleChange={e => onFhirResourceChange(e.target.value)}
                  dataTestId="fhir-resource-dropdown"
                  dataTestIdForOptions="fhir-resource-dropdown-options"
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <CriteriaDropdown
                  placeHolderText={'Select the FHIR component'}
                  title={'FHIR component'}
                  required={true}
                  disabled={isEdit}
                  value={fhirComponentValue}
                  options={fhirComponentOptions}
                  handleChange={e => onFhirComponentChange(e)}
                  dataTestId="fhir-component-dropdown"
                  dataTestIdForOptions="fhir-component-dropdown-options"
                  optionLabelKey={'name'}
                  optionValueKey={'name'}
                />
              </Grid>
              <Grid item sm={6} />
            </>
          ) : (
            <>
              <Grid item sm={3} xs={6}>
                <CriteriaDropdown
                  placeHolderText={'Select the data source'}
                  title={'Data source'}
                  required={true}
                  disabled={isEdit}
                  value={dataSourceValue}
                  dataTestId="data-source-dropdown"
                  dataTestIdForOptions="data-source-dropdown-options"
                  options={dataSourceOptionS}
                  optionValueKey={'name'}
                  optionLabelKey={'name'}
                  handleChange={e => onDataSourceChange(e.target.value)}
                />
              </Grid>
              <Grid container style={{ marginLeft: '5px' }}>
                <Grid item xs={8}>
                  <Typography className={classes.formTitle}>
                    Query <span style={{ color: 'red' }}>*</span>
                  </Typography>{' '}
                  <TextField
                    required
                    id="outlined-basic"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    className={classes.formTextField}
                    placeholder="Enter the query"
                    multiline
                    value={query}
                    onChange={handleQuery}
                    minRows={8}
                    disabled={isEdit}
                    FormHelperTextProps={{
                      style: {
                        // textAlign: 'center',
                        position: 'absolute',
                        bottom: '0%',
                        right: '0%',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <EmptyGridForUI />
              {paramList?.length > 0 && (
                <QueryDetailTable
                  paramList={paramList}
                  setParamList={setParamList}
                  isEdit={isEdit}
                />
              )}{' '}
            </>
          )}

          <Grid item sm={3} xs={6}>
            {fhirComponentPreDefinedValues && (
              // uiComponent === 'DATE' ||
              // TEXT_FIELD_RESOURCE_COMPONENTS[uiComponent]) &&
              <Typography className={classes.attrLabelTitle}>
                List of values
              </Typography>
            )}

            {fhirComponentPreDefinedValues ? (
              <Box className={classes.attrDefaultValuesContainer}>
                {fhirComponentPreDefinedValues?.map((option, index, arr) => (
                  <Box
                    display={'flex'}
                    alignItems="center"
                    key={option.displayValue}
                    style={{ gap: '10px' }}
                    data-testid="fhir-predefined-value">
                    <Typography>{option.displayValue}</Typography>
                    {index !== arr.length - 1 && (
                      <FiberManualRecordIcon style={{ fontSize: '10px' }} />
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <>
                {uiComponent === 'DATE' && (
                  <Box style={{ display: 'flex' }}>
                    <Typography style={{ padding: '8px' }}>
                      Insert value
                    </Typography>
                    <Switch
                      inputProps={{ 'aria-label': 'ant design' }}
                      checked={datePickerChecked}
                      onChange={e => setDatePickerChecked(e.target.checked)}
                      disabled={isEdit}
                    />
                    <Typography style={{ padding: '8px' }}>
                      Date picker
                    </Typography>
                  </Box>
                  // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  //   <Box>
                  //     <KeyboardDatePicker
                  //       disableToolbar
                  //       variant="inline"
                  //       format="MM/dd/yyyy"
                  //       margin="normal"
                  //       id="date-picker-inline"
                  //       // KeyboardButtonProps={{
                  //       //   'aria-label': 'change date',
                  //       // }}
                  //       value={selectedDate}
                  //       onChange={handleDateChange}
                  //       disabled={isEdit}
                  //       KeyboardButtonProps={{
                  //         'aria-label': 'change date',
                  //         onFocus: e => {
                  //           setIsOpen(true);
                  //         }
                  //       }}
                  //       PopoverProps={{
                  //         disableRestoreFocus: true,
                  //         onClose: () => {
                  //           setIsOpen(false);
                  //         }
                  //       }}
                  //       InputProps={{
                  //         onFocus: () => {
                  //           setIsOpen(true);
                  //         }
                  //       }}
                  //       open={isOpen}
                  //     />
                  //   </Box>
                  // </MuiPickersUtilsProvider>
                )}

                {/* {TEXT_FIELD_RESOURCE_COMPONENTS[uiComponent] && (
                  <Box className={classes.attrValuesContainer}>
                    {attributeValues?.map((attr, index, arr) => (
                      <Box
                        display={'flex'}
                        key={index}
                        alignItems="center"
                        justifyContent={'space-between'}
                        width="100%"
                        padding={'0px 10px'}
                        id={
                          index === arr.length - 1 ? 'scroll-view' : undefined
                        }
                        data-testid="attribute-values-textbox"
                        optionid={index}
                        onKeyPress={event => {
                          if (event.key === 'Enter') handleAddAttributes();
                        }}>
                        <Box display={'flex'} flexShrink={0}>
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontWeight: 400,
                            }}>{`${ATTR_LABEL_TITLE} ${index + 1}`}</Typography>
                        </Box>
                        <Box
                          display={'flex'}
                          style={{ gap: '10px', margin: '0px 10px' }}>
                          <TextField
                            required
                            disabled={isEdit}
                            id={
                              index === arr.length - 1
                                ? 'scroll-view-text-field'
                                : undefined
                            }
                            margin="dense"
                            variant="outlined"
                            className={classes.formTextField}
                            value={attr.displayValue}
                            onChange={event =>
                              onAttrValuesChange(
                                event.target.value,
                                index,
                                'displayValue'
                              )
                            }
                            placeholder="Display Name"
                            autoComplete="off"
                            style={{ backgroundColor: '#fff' }}
                          />
                          <TextField
                            required
                            disabled={isEdit}
                            id={
                              index === arr.length - 1
                                ? 'scroll-view-text-field'
                                : undefined
                            }
                            margin="dense"
                            variant="outlined"
                            className={classes.formTextField}
                            value={attr.code}
                            onChange={event =>
                              onAttrValuesChange(
                                event.target.value,
                                index,
                                'code'
                              )
                            }
                            autoComplete="off"
                            style={{ backgroundColor: '#fff' }}
                            placeholder="Code"
                          />
                        </Box>
                        {!isEdit &&
                          (index === arr.length - 1 ? (
                            <AddCircleIcon
                              style={{
                                color: 'rgba(102, 112, 133, 1)',
                                fontSize: '20px',
                                cursor: 'pointer',
                              }}
                              data-testid="add-attribute-value-icon"
                              onClick={handleAddAttributes}
                            />
                          ) : (
                            <DeleteIcon
                              style={{
                                color: 'rgba(102, 112, 133, 1)',
                                fontSize: '20px',
                                cursor: 'pointer',
                              }}
                              onClick={() => onAttrvDelete(index)}
                            />
                          ))}
                      </Box>
                    ))}
                  </Box>
                )} */}
              </>
            )}
          </Grid>
        </Grid>
      </FullScreenDialog.Content>

      {/* Footer */}
      <Box className={customStyles?.footer || ''}>
        <Button
          onClick={onProceed}
          color="primary"
          variant="contained"
          data-test-id="add-organization-button"
          disabled={
            attrTitle === '' ||
            description === '' ||
            (fhirComponentValue === 'placeholder' && attrType === 'FHIR') ||
            (fhirResourceValue === 'placeholder' && attrType === 'FHIR') ||
            (dataSourceValue === 'placeholder' && attrType === 'Non-FHIR') ||
            (query === '' && attrType === 'Non-FHIR') ||
            (paramList.find(val => val.dataType === null) &&
              attrType === 'Non-FHIR')
          }
          className={customStyles?.searchButton || ''}>
          {isEdit ? 'Proceed' : 'Map organizations'}
        </Button>
      </Box>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default CreateAttributeDetails;
