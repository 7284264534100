import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M6.16667 19.5C5.70833 19.5 5.31597 19.3368 4.98958 19.0104C4.66319 18.684 4.5 18.2917 4.5 17.8333V6.16667C4.5 5.70833 4.66319 5.31597 4.98958 4.98958C5.31597 4.66319 5.70833 4.5 6.16667 4.5H17.8333C18.2917 4.5 18.684 4.66319 19.0104 4.98958C19.3368 5.31597 19.5 5.70833 19.5 6.16667V17.8333C19.5 18.2917 19.3368 18.684 19.0104 19.0104C18.684 19.3368 18.2917 19.5 17.8333 19.5H6.16667ZM6.16667 17.8333H11.1667V6.16667H6.16667V17.8333ZM12.8333 17.8333H17.8333V12H12.8333V17.8333ZM12.8333 10.3333H17.8333V6.16667H12.8333V10.3333Z" />{' '}
    </SvgIcon>
  );
};

export default Icon;
