/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { PageHeaderTitle, SimpleBackdrop } from 'components/widgets';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import FilterDropDown from 'components/FilterDropDown';
import NotFoundPage from 'components/NotFoundPage';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import { useSnackbar } from 'notistack';
import {
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
} from 'shared/constants';
import SupportGrid from './SupportGrid';
import DemoApis from 'services/api/DemoApis';
import PendingIcon from '../../assets/status/pending.svg';
import CompletedIcon from '../../assets/status/green.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    //  padding:16
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
  supportContainer: {
    backgroundColor: '#F5F5F5',
    position: 'relative',
    marginLeft: 32,
  },
}));

const Support = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelecteStatus] = useState('all');
  const [filteredSupport, setFilteredSupport] = useState();

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [reinitiate, setReinitiate] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const statusOption = [
    { label: 'All', value: 'all' },
    { label: 'Pending', value: 'PENDING', icon: PendingIcon },
    { label: 'Resolved', value: 'RESOLVED', icon: CompletedIcon },
  ];

  const orgId = sessionStorage.getItem("orgId");

  const getAllSupport = async () => {
    setLoading(true);
    try {
      const results = await DemoApis.getAllSupport({
        orgId: orgId,
        searchName: searchValue,
        status: selectedStatus === 'all' ? '' : selectedStatus,
        offset: offset,
      });

      setFilteredSupport(results?.data);
      setTotalCount(results.totalItems);
      setLoading(false);
      setOnloadData(true);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    getAllSupport();
  }, [offset, searchText, selectedStatus, reinitiate]);

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
  };

  const typeofStatusFilterOptions = {
    options: statusOption,
    value: selectedStatus,
    changeHandler: ev => {
      setIsInitialRender(false);
      setSelecteStatus(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  return (
    <Box sx={{ width: '95%' }} className={classes.supportContainer}>
      <Box style={{ marginLeft: '-16px' }}>
        <PageHeaderTitle>Support</PageHeaderTitle>
      </Box>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {(!isInitialRender || filteredSupport?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {/* <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    label="Search by support name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                  data-testid="search"
                >
                  Search
                </SecondaryButton> */}

                <div style={{ marginTop: '-8px' }}>
                  <FilterDropDown
                    {...typeofStatusFilterOptions}
                    filterType="Status:"
                  />
                </div>
              </div>

              {filteredSupport?.length > 0 && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <SupportGrid
                    apiLoading={loading}
                    rowData={filteredSupport}
                    setReinitiate={setReinitiate}
                  />
                  <PaginationComponent
                    numberOfRowsPerPage={10}
                    count={totalCount}
                    onPageClick={paginationClick}
                    page={offset}
                  />
                </Grid>
              )}
            </>
          )}
        </div>{' '}
      </Grid>
      {filteredSupport?.length === 0 && isInitialRender && OnloadData && (
        <NotFoundPage
          title={INITIAL_RENDER_NO_DATA_PAGE.CRITERIA_TITLE}
          titleContent={INITIAL_RENDER_NO_DATA_PAGE.CRITERIA_TITLE_CONTENT}
          content={INITIAL_RENDER_NO_DATA_PAGE.CRITERIA_CONTENT}
        />
      )}{' '}
      {filteredSupport?.length === 0 && !isInitialRender && OnloadData && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.CRITERIA_TITLE}
            content={EMPTY_STATE_PAGE.CRITERIA_CONTENT}
            showButton={false}
          />
        </div>
      )}
      <SimpleBackdrop open={loading} />
    </Box>
  );
};

export default Support;
