import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Rules from './RulesTabComponent/Rules';
import { PageHeaderTitle } from 'components/widgets';
import Criteria from './CriteriaTabComponent/Criteria';
import { useHistory } from 'react-router-dom';
import AttributeMainPage from './AttributeTab/AttributeMainPage';
import ActionPage from './actions/ActionPage';

const styles = theme => ({
  container: {
    width: 'max-content',
    [theme.breakpoints.up('lg')]: {
      width: '872px',
    },
    justifyConent: 'center',
  },
  heading: {
    paddingBottom: '24px',
    fontSize: 18,
    fontWeight: 600,
  },
  card: {
    marginBottom: '24px',
    boxSizing: 'content-box',
    maxWidth: '25%',
    '&:last-child': {
      marginBottom: '36px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
  },
  image: {
    heigth: '40px',
    width: '40px',
  },
  iconImage: {
    height: '40px',
    width: '40px',
    backgroundColor: '#003A64',
    borderRadius: 24,
  },
  tabRoot: {
    width: '100%',
    '@media (min-width: 600px)': {
      '& .MuiTab-root': {
        minWidth: '100px',
        textAlign: 'center',
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({
  TabHeading,
  getTabIndexClicked,
  classes,
  rulesPermissions,
}) => {
  const history = useHistory();
  const [value, setValue] = useState(
    history.location?.state?.selectTabIndex || 0
  );

  const [permissionMapping, setPermissionMapping] = useState(new Map());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const styles = {
    TabContainer: {
      backgroundColor: '#F5F5F5',
      position: 'relative',
      marginLeft: 32,
    },

    TabHeading: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
      marginRight: '15px',
    },

    cardContainer: {
      minHeight: '100%',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      paddingTop: 40,
    },
    cardWrapper: { marginBottom: 32, marginRight: 32 },
    TabsWrapper: {
      borderBottom: '2px solid #D9D9D9',
      height: 62,
    },
  };

  useEffect(() => {
    const permissionMap = new Map();
    rulesPermissions?.forEach(subFeature =>
      permissionMap.set(
        subFeature.subFeatureName,
        subFeature.selectedPermissions
      )
    );
    if (typeof history.location?.state?.selectTabIndex !== 'number') {
      for (let i = 0; i < TabHeading.length; i++) {
        let tabName = TabHeading[i];
        if (permissionMap.get(tabName)?.length) {
          setValue(i);
          break;
        }
      }
    }
    setPermissionMapping(permissionMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rulesPermissions]);

  return (
    <Box sx={{ width: '95%' }} style={styles.TabContainer}>
      <Box style={{ marginLeft: '-16px' }}>
        <PageHeaderTitle>Rules engine</PageHeaderTitle>
      </Box>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
        style={styles.TabsWrapper}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.tabRoot}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#00A0DF',
              height: 4,
              borderRadius: '100px 100px 0px 0px',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '4px',
              width:
                value === 0
                  ? '87px'
                  : value === 1
                  ? '67px'
                  : value === 2
                  ? '67px'
                  : '48px',
            },
          }}
          style={{ paddingTop: 15, marginLeft: 0, paddingRight: 0, margin: 0 }}
        >
          {TabHeading &&
            TabHeading.length > 0 &&
            TabHeading.map((heading, index) => (
              <Tab
                sx={{ width: '70px' }}
                key={index}
                onClick={() => getTabIndexClicked && getTabIndexClicked(index)}
                label={<span style={styles.TabHeading}>{heading}</span>}
                disabled={permissionMapping?.get(heading)?.length === 0}
                {...a11yProps(index)}
              />
            ))}
        </Tabs>
      </Box>
      <Box style={{ width: '100%', height: '100%', marginTop: '40px' }}>
        {value === 0 &&
          Boolean(permissionMapping?.get(TabHeading[0])?.length) && (
            <AttributeMainPage
              permissions={permissionMapping.get(TabHeading[0])}
            />
          )}
        {value === 1 &&
          Boolean(permissionMapping?.get(TabHeading[1])?.length) && (
            <Criteria
              permissions={permissionMapping.get(TabHeading[1])}
              rulesPermissions={permissionMapping.get(TabHeading[3])}
            />
          )}
        {value === 2 &&
          Boolean(permissionMapping?.get(TabHeading[2])?.length) && (
            <ActionPage permissions={permissionMapping.get(TabHeading[2])} />
          )}
        {value === 3 &&
          Boolean(permissionMapping?.get(TabHeading[3])?.length) && (
            <Rules permissions={permissionMapping.get(TabHeading[3])} />
          )}
      </Box>
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(BasicTabs);
