import UsersPage from 'containers/ManageUsers/UsersPage';
import React from 'react';

const UserPage = () => {
  return (
    <div>
      <UsersPage />
    </div>
  );
};

export default UserPage;
