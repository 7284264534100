import { makeStyles } from '@material-ui/core';
import clsx from 'classnames';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { useMemo, useCallback } from 'react';
import _debounce from 'lodash.debounce';

const useStyles = makeStyles(theme => ({
  margin: {
    // margin: theme.spacing(2, 1.5),
  },
  searchControl: {
    backgroundColor: '#fff',
  },
}));

function SearchTextField({ label, onChangeText }) {
  const classes = useStyles();

  const changeHandler = useCallback(
    event => {
      onChangeText(event.target.value);
    },
    [onChangeText]
  );

  const debouncedChangeHandler = useMemo(
    () => _debounce(changeHandler, 300),
    [changeHandler]
  );

  return (
    <FormControl
      size="small"
      fullWidth
      className={clsx(classes.margin, classes.searchControl)}
      variant="outlined">
      <OutlinedInput
        id="outlined-adornment-amount"
        placeholder={label}
        onChange={debouncedChangeHandler}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default SearchTextField;
