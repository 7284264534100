import React, { useState, useEffect, useMemo } from 'react';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import CriteriaForRules from './CriteriaForRules';
import { Typography, Paper, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton, SimpleBackdrop } from 'components/widgets';
import ActionForRules from './ActionForRules';
import ScheduleForRules from './ScheduleForRules';
import Rocketlaunch from '../../../../components/icons/RocketLaunch';
import { useSnackbar } from 'notistack';
import progress from '../../../../assets/progress.svg';
import RulesEngineApi from 'services/api/RuleEngineApi';
import MembersActionableDetail from './MembersActionableDetail';
import RulesApi from 'services/api/RulesApi';
import { formatTimeInHHMMWithComma, statusColorMap } from 'shared/utility';
import { convertStrToTitleCase } from 'shared/utility';
import moment from 'moment';
import VersionHistory from 'containers/VersionHistory';
import HistoryForRulesCriteria from './HistoryForRulesCriteria';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  executeButton: {
    borderRadius: '30px',
    textTransform: 'none',
  },
  abortButton: {
    color: 'red',
    borderColor: 'red!important',
  },
  editButton: {
    // display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },

  cancelButton: {
    fontSize: '16px',
    borderRadius: '40px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 40px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
  lastRunBox: {
    background: '#E2F1FC',
    width: 'fit-content',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4%',
  },
}));

const RuleStep2 = ({
  changeStep,
  step1Data,
  tabStatus,
  ruleValues,
  setRule,
  allAffiliates,
  handleClose,
  isCreateRule,
  setIsCreateRule,
  orgId,
  rulesId,
  refetchData,
  shouldRefetechData,
  options,
  trackUnsavedChangesRef,
  lastRunDate,
  ruleTransactionId,
  executionStatus,
  criteriaHistoryForRules,
  criteriaLoader,
  hasCreatePermissions
}) => {
  const classes = useStyles();
  const [savedCriteria, setSavedCriteria] = useState(false);
  const [editCriteria, setEditCriteria] = useState(false);
  const [savedAction, setSavedAction] = useState(false);
  const [editAction, setEditAction] = useState(false);
  const [savedScheduler, setSavedSchedule] = useState(false);
  const [editScheduler, setEditSchedule] = useState(false);
  const [executeStatus, setExecuteStatus] = useState(
    ruleValues?.status === 'DRAFT' ? false : true
  );
  const [loading, setLoading] = useState(false);
  const [rulesHistoryDetails, setRulesHistoryDetails] = useState([]);

  const [publishedClick, setPublishedClick] = useState(false);
  const [executionInfo, setExecutionInfo] = useState([]);
  const [nextRun, setNextRun] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const checkStatus = () => {
    if (!editCriteria && !editAction && !editScheduler) {
      handleClose(true);
      shouldRefetechData.current = false;
      refetchData();
    } else if (editCriteria) {
      enqueueSnackbar('Please save criteria details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    } else if (editAction) {
      enqueueSnackbar('Please save action details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    } else if (editScheduler) {
      enqueueSnackbar('Please save schedule details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    }
  };

  const getHisoryList = async (orgId, rulesId) => {
    try {
      const results = await RulesEngineApi.getRuleHistory({
        orgId,
        rulesId: rulesId.id || ruleValues.id,
      });
      setRulesHistoryDetails(results);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getExecutionDetail = async (orgId, ruleTransactionId) => {
    try {
      const results = await RulesEngineApi.getExecutionInfo({
        orgId,
        rulesId: ruleTransactionId,
      });
      setExecutionInfo(results);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const publishRule = async orgId => {
    setLoading(true);
    let payload = {
      ...ruleValues,
      status: 'PUBLISHED',
      editBeforeExec: true,
    };

    try {
      let results = await RulesApi.saveRules({ orgId, payload });
      setLoading(false);
      refetchData();
      handleClose(true);
      setExecuteStatus(true);
      setPublishedClick(true);
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };

  const getNextSchedule = async rulesId => {
    try {
      let results = await RulesApi.nextSchedule(rulesId?.id);
      setNextRun(results);
    } catch (err) {
      console.log('error', err);
    }
  };

  const getAffiliatesList = useMemo(() => {
    if (step1Data?.affiliateName !== null) {
      return step1Data?.affiliateName;
    }
    return (
      options?.filter(val =>
        step1Data.affiliate?.includes(val.id?.toString())
      ) || []
    );
  }, [step1Data, options]);

  useEffect(() => {
    getNextSchedule(rulesId);
    if (!isCreateRule) {
      getHisoryList(orgId, rulesId);
    }
    return () => {
      setIsCreateRule(false);
    };
  }, [orgId, rulesId]);

  useEffect(() => {
    if (ruleTransactionId) {
      getExecutionDetail(orgId, ruleTransactionId);
    }
  }, [orgId, ruleTransactionId]);

  useEffect(() => {
    if (trackUnsavedChangesRef) {
      trackUnsavedChangesRef.current = () => {
        if (editCriteria || editAction || editScheduler) {
          return true;
        } else {
          return false;
        }
      };
    }
  }, [editAction, editCriteria, editScheduler, trackUnsavedChangesRef]);

  useEffect(() => {
    if (ruleValues) {
      if (
        ruleValues?.ruleDefn?.schedule?.startDate &&
        ruleValues?.ruleDefn?.criteria?.[0]?.criteriaGroup?.[0]?.criteriaId &&
        ruleValues?.ruleDefn?.actions?.[0]?.actionId
      ) {
        setSavedCriteria(true);
        setSavedAction(true);
        setSavedSchedule(true);
      }
    }
  }, [ruleValues]);

  return (
    <>
      <DrawerBoxComponent style={{ minHeight: '78vh' }}>
        <Paper elevation={0} className={classes.paper}>
          <Box className={classes.container}>
            <Box style={{ display: 'flex' }}>
              <Box style={{ marginRight: '30px' }}>
                <Typography style={{ marginBottom: '15px' }} variant="h3">
                  <b>{step1Data.title}</b>
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '6%',
                  }}>
                  {Object.keys(statusColorMap).includes(
                    executionStatus || ruleValues.status
                  ) ? (
                    <>
                      <span
                        style={{
                          backgroundColor:
                            statusColorMap[
                              executionStatus || ruleValues.status
                            ],
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          padding: 2,
                        }}></span>
                      <span style={{ marginLeft: 8 }}>
                        {convertStrToTitleCase(
                          executionStatus || ruleValues.status
                        )}
                      </span>
                    </>
                  ) : (
                    <span>{executionStatus || ruleValues.status}</span>
                  )}
                </Box>

                <Typography style={{ marginBottom: '7px' }} variant="h6">
                  <b>Description : </b> {step1Data.description}
                </Typography>

                <Typography style={{ marginBottom: '7px' }} variant="h6">
                  <b>Type : </b> {step1Data.type}
                </Typography>
                {(sessionStorage.getItem('MPH_ORG') === 'true' ||
                  sessionStorage.getItem('MPH_ORG') === true) && (
                  <Typography style={{ marginBottom: '7px' }} variant="h6">
                    <b>Type of attribute : </b> {step1Data?.category}
                  </Typography>
                )}
                <Typography style={{ marginBottom: '7px' }} variant="h6">
                  <b>Affiliate : </b>{' '}
                  {getAffiliatesList?.map((i, index) => {
                    return index === 0 ? i.name : `, ${i.name}`;
                  })}
                </Typography>
                {lastRunDate && (
                  <Box className={classes.lastRunBox}>
                    <Rocketlaunch />
                    <Typography>
                      Last run date:{' '}
                      {moment(lastRunDate).format('MMM DD, YYYY -') +
                        ' ' +
                        formatTimeInHHMMWithComma(lastRunDate)}
                    </Typography>
                  </Box>
                )}

                {nextRun && (
                  <Box className={classes.lastRunBox}>
                    <Rocketlaunch />
                    <Typography>
                      Next run date:{' '}
                      {moment(nextRun).format('MMM DD, YYYY -') +
                        ' ' +
                        formatTimeInHHMMWithComma(nextRun)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.editButton}>
              {ruleValues?.status !== 'PUBLISHED' &&
                executionStatus !== 'IN_PROGRESS' && (
                  <SecondaryButton
                    onClick={() => {
                      changeStep(true);
                    }}
                    hasPermissions={hasCreatePermissions}
                    >
                    Edit
                  </SecondaryButton>
                )}
            </Box>
          </Box>
        </Paper>

        {/* after rule execution progress image */}

        {((executeStatus &&
          ruleValues.status !== 'DRAFT' &&
          ruleValues.status !== 'PUBLISHED') ||
          executionStatus === 'IN_PROGRESS' ||
          publishedClick) && (
          <Box style={{ margin: '2%' }}>
            <p>Running the rule based on the criteria</p>
            <img alt="Logo" src={progress} />
          </Box>
        )}

        {/* actionable cards (members selected) */}
        {executionStatus !== 'DRAFT' &&
          ruleValues.status !== 'DRAFT' &&
          executionStatus !== 'PUBLISHED' &&
          executionStatus !== 'SCHEDULED' && (
            <MembersActionableDetail executionInfo={executionInfo} />
          )}

        {/* criteria section */}

        <CriteriaForRules
          className={classes.paper}
          setEditCriteria={setEditCriteria}
          setSavedCriteria={setSavedCriteria}
          tabStatus={tabStatus}
          ruleValues={ruleValues}
          setRule={setRule}
          category={step1Data?.category}
          hasCreatePermissions={hasCreatePermissions}
        />

        {/* Action section */}
        <ActionForRules
          className={classes.paper}
          setEditAction={setEditAction}
          setSavedAction={setSavedAction}
          tabStatus={tabStatus}
          ruleValues={ruleValues}
          setRule={setRule}
          hasCreatePermissions={hasCreatePermissions}
        />

        {/* Schedule section */}
        <ScheduleForRules
          className={classes.paper}
          setEditSchedule={setEditSchedule}
          setSavedSchedule={setSavedSchedule}
          tabStatus={tabStatus}
          ruleValues={ruleValues}
          setRule={setRule}
          executionStatus={executionStatus}
          hasCreatePermissions={hasCreatePermissions}
        />

        {/*History section */}
        {!isCreateRule && (
          <VersionHistory historyDetails={rulesHistoryDetails} />
        )}

        {!isCreateRule && (
          <HistoryForRulesCriteria
            criteriaHistoryForRules={criteriaHistoryForRules}
            criteriaLoader={criteriaLoader}
          />
        )}
      </DrawerBoxComponent>
      {/* Footer */}
      <Box className={classes?.footer || ''}>
        <Box className={classes.editButton}>
          <SecondaryButton onClick={() => checkStatus()}>Close</SecondaryButton>
        </Box>

        {!executeStatus && hasCreatePermissions &&  (
          <Button
            // onClick={onProceed}
            color="primary"
            className={classes.executeButton}
            variant="contained"
            data-test-id="add-organization-button"
            onClick={() => {
              publishRule(orgId);
            }}
            disabled={
              savedCriteria && savedAction && savedScheduler ? false : true
            }>
            {/* {isEdit ? 'Proceed' : 'Add organization'} */}
            Publish
          </Button>
        )}

        {/* // <Box className={classes.editButton}>
          //   <SecondaryButton
          //     className={classes.abortButton}
          //     onClick={() => handleClose(true)}>
          //     Abort
          //   </SecondaryButton>
          // </Box> */}
      </Box>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default RuleStep2;
