import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { PageHeaderTitle, PrimaryButton } from 'components/widgets';
import { useHistory } from 'react-router-dom';
import InternalUserPage from './InternalUserPage';
import ExternalUserPage from './ExternalUserPage';
import { USER_ROLES } from 'shared/constants';
import DataPartnersApi from 'services/api/DataPartnersApi';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { InviteUserLandingPage } from './InviteUserLandingPage';
import InviteUser from './InviteUser';
import { useHasPermissions } from 'shared/utility';
import { useSelector } from 'react-redux';

const styles = theme => ({
  container: {
    width: 'max-content',
    [theme.breakpoints.up('lg')]: {
      width: '872px',
    },
    justifyConent: 'center',
  },
  heading: {
    paddingBottom: '24px',
    fontSize: 18,
    fontWeight: 600,
  },
  card: {
    marginBottom: '24px',
    boxSizing: 'content-box',
    maxWidth: '25%',
    '&:last-child': {
      marginBottom: '36px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
  },
  image: {
    heigth: '40px',
    width: '40px',
  },
  iconImage: {
    height: '40px',
    width: '40px',
    backgroundColor: '#003A64',
    borderRadius: 24,
  },
  tabRoot: {
    width: '100%',
    '@media (min-width: 600px)': {
      '& .MuiTab-root': {
        minWidth: '100px',
        textAlign: 'center',
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({ TabHeading, getTabIndexClicked, classes }) => {
  const history = useHistory();
  const orgId = sessionStorage.getItem('orgId');

  const [tabValue, setTabValue] = useState(
    history.location?.state?.selectTabIndex || 0
  );
  const [flyout, setFlyout] = useState({ open: false, title: '' });
  const [loading, setLoading] = useState(false);
  const [lobs, setLOBs] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [allAvailableRoles, setAllAvailableRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetchInternalUsers, setRefetchInternalUsers] = useState(false);
  const [refetchExternalUsers, setRefetchExternalUsers] = useState(false);
  const [openInvite, setOpenInvite] = useState(history?.location?.state?.shouldOpenInvite || false);
  const { info } = useSelector(state => state.userInfo);
  const [inviteCard, setInviteCard] = useState('');
  const getAffiliateList = useCallback(async () => {
    setLoading(true);
    try {
      const results = await DataPartnersApi.getAllPartners(orgId);
      const data = results.filter(item => item.isDeleted !== 1);
      const options = data.map(fItem => ({ id: fItem.id, name: fItem.name }));
      setAffiliates(options);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [orgId]);

  const getLobList = useCallback(async () => {
    setLoading(true);
    try {
      const results = await OrganizationUsersApi.getAllLOB({ orgId });
      const data = results.filter(item => item.isDeleted !== 1);
      setLOBs(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      getAffiliateList();
      getLobList();
    }
  }, [orgId, getAffiliateList, getLobList]);

  useEffect(() => {
    if (history?.location?.state?.shouldOpenInvite && openInvite) {
      history.replace({ state: { shouldOpenInvite: false } });
    }
  }, [history, openInvite]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onAddUser = () => {
    let emptyUser = {
      userType: 'internal',
      firstName: '',
      lastName: '',
      emailAddress: '',
      role: '',
      organizationName: '',
      affiliate: '',
      lob: '',
    };

    if (!'Invite user') {
      const externalDevRoleIndex = allRoles.findIndex(
        item => item.roleName === USER_ROLES.Third_Party_Developer
      );

      emptyUser.userRoles.push({
        ...allRoles[externalDevRoleIndex],
      });
    }

    setFlyout({
      open: true,
      user: emptyUser,
      title: 'Invite users',
    });

    setOpenInvite(true);
  };

  const onEditUser = ({ usersDetails, title }) => {
    setFlyout({
      open: true,
      user: usersDetails,
      title: title,
    });
  };

  const onUserToggle = () => {
    setFlyout({ open: false, title: '' });
  };

  const onUserUpdatedHandler = async tabValue => {
    if (tabValue === 'internal') {
      setTabValue(0);
      setRefetchInternalUsers(true);
    } else {
      setTabValue(1);
      setRefetchExternalUsers(true);
    }
    setFlyout({ open: false, title: '' });
    setSelectedUser(null);
  };

  const handleInviteCard = card => {
    setOpenInvite(false);
    if (card === 'internal') {
      setInviteCard('internal');
    } else {
      setInviteCard('external');
    }
  };

  const styles = {
    TabContainer: {
      backgroundColor: '#F5F5F5',
      position: 'relative',
      marginLeft: 32,
    },

    TabHeading: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
      marginRight: '15px',
    },

    cardContainer: {
      minHeight: '100%',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      paddingTop: 40,
    },
    cardWrapper: { marginBottom: 32, marginRight: 32 },
    TabsWrapper: {
      borderBottom: '2px solid #D9D9D9',
      height: 62,
    },
  };

  const handleClose = () => {
    setOpenInvite(false);
  };

  const permissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'User role management',
    manageOrg: true,
  });

  let readAccess = permissions?.READ;
  let createAccess = permissions?.CREATE;

  return (
    <>
      {(readAccess ||
        info.accessName === 'Organization Admin' ||
        info.accessName === 'IT_ADMIN') && (
        <Box
          sx={{ width: '95%', marginTop: '20px' }}
          style={styles.TabContainer}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '-16px',
            }}
          >
            <PageHeaderTitle>Users</PageHeaderTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // marginRight: 16,
                marginTop: '27px',
                height: '36px',
              }}
            >
              <PrimaryButton
                hasPermissions={createAccess}
                size="large"
                onClick={onAddUser}
                color="primary"
              >
                Invite user
              </PrimaryButton>
            </div>
          </Box>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            style={styles.TabsWrapper}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              className={classes.tabRoot}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#00A0DF',
                  height: 4,
                  borderRadius: '100px 100px 0px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: '4px',
                  width:
                    tabValue === 0
                      ? '115px'
                      : tabValue === 1
                      ? '77px'
                      : tabValue === 2
                      ? '77px'
                      : '48px',
                },
              }}
              style={{
                paddingTop: 15,
                marginLeft: 0,
                paddingRight: 0,
                margin: 0,
              }}
            >
              {TabHeading &&
                TabHeading.length > 0 &&
                TabHeading.map((heading, index) => (
                  <Tab
                    sx={{ width: '70px' }}
                    key={index}
                    onClick={() =>
                      getTabIndexClicked && getTabIndexClicked(index)
                    }
                    label={<span style={styles.TabHeading}>{heading}</span>}
                    {...a11yProps(index)}
                  />
                ))}
            </Tabs>
          </Box>
          <Box style={{ width: '100%', height: '100%', marginTop: '40px' }}>
            {tabValue === 0 && (
              <InternalUserPage
                isSuperAdmin={true}
                isItAdmin
                refetchInternalUsers={refetchInternalUsers}
                setRefetchInternalUsers={setRefetchInternalUsers}
                onEditUser={onEditUser}
                onAddUser={onAddUser}
                affiliates={affiliates}
                lobs={lobs}
                readAccess={readAccess}
                createAccess={createAccess}
                isOrgAdmin={
                  info.accessName === 'Organization Admin' ||
                  info.accessName === 'IT_ADMIN'
                }
              />
            )}
            {tabValue === 1 && (
              <ExternalUserPage
                isSuperAdmin={true}
                isItAdmin
                refetchExternalUsers={refetchExternalUsers}
                setRefetchExternalUsers={setRefetchExternalUsers}
                onEditUser={onEditUser}
                onAddUser={onAddUser}
                affiliates={affiliates}
                lobs={lobs}
                readAccess={readAccess}
                createAccess={createAccess}
                isOrgAdmin={
                  info.accessName === 'Organization Admin' ||
                  info.accessName === 'IT_ADMIN'
                }
              />
            )}
          </Box>

          {/* <NewUserFlyout
        onToggle={onUserToggle}
        loading={loading}
        allAffiliates={affiliates}
        allLobs={lobs}
        allRoles={allRoles}
        allAvailableRoles={allAvailableRoles}
        isInternalUser="Invite user"
        onUserUpdated={onUserUpdatedHandler}
        orgId={orgId}
        {...flyout}
      /> */}
          {openInvite && (
            <InviteUserLandingPage
              handleClose={handleClose}
              handleInviteCard={handleInviteCard}
            />
          )}
          {inviteCard && (
            <InviteUser
              setInviteCard={setInviteCard}
              externalUser={inviteCard === 'external'}
              setRefetchInternalUsers={setRefetchInternalUsers}
              setRefetchExternalUsers={setRefetchExternalUsers}
              affiliates={affiliates}
              lobs={lobs}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(BasicTabs);
