import React, { useState, useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Box, CircularProgress, TextField, Grid } from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { useSnackbar } from 'notistack';
import { phoneNumber,email } from 'shared/validators';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 1px 30px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#FFFFFF',
    padding: '10px',
    borderRadius: '10px!important',
  },
  adminGrpContainer: {
    // backgroundColor: '#E4E7EC',
    // border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '20px',
    // padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    // padding: '0px 16px',
    width: '100%',
  },
  adminSummaryContainer: {
    // backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
  },
  adminGrpSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  formTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#344054',
  },
  GridMaxWidth: {
    maxWidth: '32%',
  },
  mandatory: {
    color: 'red',
  },
  detailstitle: {
    color: '#798294',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  detailsValue:{
    color:"#344054"
  },
}));


function PhoneMaskedTextField(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+1 (###) ###-####"
      mask="_"
    />
  );
}

export default function AdminDetails({
  signUpValues,
  setSavedAdmin,
  setEditAdmin,
  tabStatus,
  setSignUp,
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [firstName, setFirstName] = useState(signUpValues?.orgAdminDetails?.firstName || '');
  const [lastName, setLastName] = useState(signUpValues?.orgAdminDetails?.lastName || '');
  const [emailID, setEmailID] = useState(signUpValues?.orgAdminDetails?.emailId || '');
  const [phone, setPhone] = useState(signUpValues?.orgAdminDetails?.phoneNumber || '');

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(signUpValues?.orgAdminDetails?.firstName ? false : true);
  const [saveAction, setSaveAction] = useState(false); //* This should be props
  const isActionSavedBefore = useRef(
    signUpValues?.orgAdminDetails?.firstName ? true : false
  );
  const [saveAdminStatus, setSaveAdminStatus] = useState(
    signUpValues?.orgAdminDetails?.firstName
      ? tabStatus?.SAVED
      : tabStatus?.NOT_SAVED
  );
 
  const [adminGrpValue, setadminGrpValue] = useState([
    {
      orgAdminDetails: {
        firstName: signUpValues?.orgAdminDetails?.firstName || null,
        lastName: signUpValues?.orgAdminDetails?.lastName || null,
        emailId: signUpValues?.orgAdminDetails?.emailId || null,
        phoneNumber: signUpValues?.orgAdminDetails?.phoneNumber || null,
      },
    },
  ]);

  const handleClearAction = () => {
    setFirstName('');
    setLastName('');
    setEmailID('');
    setPhone('');

    setExpanded(false);
    setEditAdmin(false);
    setadminGrpValue([
      {
        orgAdminDetails: {
          firstName: null,
          lastName: null,
          emailId: null,
          phoneNumber: null,
        },
      },
    ]);
  };

  const handleActionStatus = () => {
    if (saveAdminStatus === tabStatus?.SAVED) {
      setSaveAdminStatus(tabStatus?.NOT_SAVED);
      setSavedAdmin(false);
    }
  };

  const handleOnchange = val => {
    handleActionStatus();
    setEditAdmin(true);
    let actnGrpVal = [
      {
        orgAdminDetails: {
          firstName: firstName || null,
          lastName: lastName || null,
          emailId: emailID || null,
          phoneNumber: phone || null,
        },
      },
    ];
    setadminGrpValue(actnGrpVal);

    return actnGrpVal;
  };

  const handleEditAction = () => {
    setSaveAdminStatus(tabStatus?.NOT_SAVED);
    setSavedAdmin(false);
    setEditAdmin(true);
    setExpanded(prevValue => !prevValue);
  };

  const closeWithoutEdit = () => {
    setSaveAdminStatus(tabStatus?.SAVED);
    setSavedAdmin(true);
    setEditAdmin(false);
    setExpanded(prevValue => !prevValue);
    setadminGrpValue([
      {
        orgAdminDetails: {
          firstName: signUpValues?.orgAdminDetails?.firstName,
          lastName: signUpValues?.orgAdminDetails?.lastName,
          emailId: signUpValues?.orgAdminDetails?.emailId,
          phoneNumber: signUpValues?.orgAdminDetails?.phoneNumber,
        },
      },
    ]);
  };

  const handleAdminDetailsValidations = () => {
    if (!firstName && !lastName && !emailID && !phone) {
      enqueueSnackbar('Please enter value', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!firstName) {
      enqueueSnackbar('Please enter first name', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!lastName) {
      enqueueSnackbar('Please enter last name', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!emailID) {
      enqueueSnackbar('Please enter email id', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!phone) {
      enqueueSnackbar('Please enter phone number', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else {
      if(phoneNumber(phone)=== "Invalid number" || email(emailID) === "Invalid email address")
      {
        return false
      }
      return true;
    }
    return false;
  };

  const checkValidation=(value,type)=>{
    if(type === "contact")
    {
      return phoneNumber(value);
    }
    if(type === "email")
    {
      return email(value);
    }
    
  }

  const handleSaveAction = async () => {
    if (!handleAdminDetailsValidations()) return;
    setLoading(true);
    setSaveAdminStatus(tabStatus?.SAVING);
    let updatedActnGrpValue = handleOnchange();

    let payload = {
      ...signUpValues,
      orgAdminDetails: updatedActnGrpValue?.[0]?.orgAdminDetails,
    };

    setSignUp(payload);
    setExpanded(false);
    setLoading(false);
    isActionSavedBefore.current = true;
    setSaveAdminStatus(tabStatus?.SAVED);
    setSavedAdmin(true);
    setEditAdmin(false);
  };

  const savedStatusStatus = useMemo(() => {
    if (saveAdminStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
        />
      );
    } else if (saveAdminStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
        />
      );
    } else if (saveAdminStatus === tabStatus?.REJECTED) {
      return <HighlightOffIcon style={{ color: 'red', fontSize: '22px' }} />;
    } else {
      return (
        <CheckCircleOutlineIcon style={{ color: 'grey', fontSize: '22px' }} />
      );
    }
  }, [saveAdminStatus]);

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.containerBox}
        expanded={expanded}
        // onChange={() => setExpanded(prevValue => !prevValue)}
        data-testid="action-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {savedStatusStatus}
              <Typography
                data-testid="action-title"
                className={classes.heading}>
                User details
              </Typography>
             
              {!expanded && (
                <Box style={{ marginLeft: 'auto' }}>
                  {isActionSavedBefore.current ? (
                    <SecondaryButton onClick={handleEditAction}>
                      Edit
                    </SecondaryButton>
                  ) : (
                    <SecondaryButton
                      onClick={() => setExpanded(prevValue => !prevValue)}>
                      Add
                    </SecondaryButton>
                  )}
                </Box>
              )}
            </Box>
            {!isActionSavedBefore.current &&
            <span style={{ marginLeft: '34px', fontSize: '14px' }}>
            Add all your details 
            </span>
}
            {saveAdminStatus === 'SAVED' && !expanded && (
              <Box className={classes.adminGrpSummaryContainer}>
                {adminGrpValue?.map(val => {
                  return (
                    <>
                      <Grid container>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                First name
                              </Typography>
                              <Typography variant="h6" className={classes.detailsValue}>
                                <b>{val?.orgAdminDetails?.firstName || "-"}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Last name
                              </Typography>
                              <Typography variant="h6" className={classes.detailsValue}>
                                <b>{val?.orgAdminDetails?.lastName || "-"}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Email id
                              </Typography>
                              <Typography variant="h6" className={classes.detailsValue}>
                                <b>{val?.orgAdminDetails?.emailId || "-"}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                          <Box className={classes.adminSummaryContainer}>
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailstitle}>
                                Phone number
                              </Typography>
                              <Typography variant="h6" className={classes.detailsValue}>
                                <b>{val?.orgAdminDetails?.phoneNumber || "-"}</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            <Box
              className={classes.adminGrpContainer}
              style={{ display: 'flex' }}>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  First name <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="rule-title"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                  autoFocus
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Last name <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="rule-title"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                  
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Email id <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="rule-title"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter email id"
                  value={emailID}
                  onChange={e => {
                    setEmailID(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  helperText={checkValidation(emailID,"email")}
                  error={checkValidation(emailID,"email")}
                  autoComplete="off"
                  
                  style={{ background: 'white' }}
                />
              </Grid>
              <Grid item sm={4} xs={4} className={classes.GridMaxWidth}>
                <Typography className={classes.formTitle}>
                  Phone number <span className={classes.mandatory}> *</span>
                </Typography>
                <TextField
                  required
                  id="rule-title"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={e => {
                    setPhone(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  InputProps={{
                    inputComponent: PhoneMaskedTextField,
                  }}
                  helperText={checkValidation(phone,"contact")}
                  error={checkValidation(phone,"contact")}
                  autoComplete="off"
                  
                  style={{ background: 'white' }}
                />
              </Grid>
            </Box>

            <Box style={{ margin: '10px 0px' }}>
              <Box style={{ margin: '20px 0px', display: 'flex', gap: '10px' }}>
                <SecondaryButton
                  onClick={
                    isActionSavedBefore.current
                      ? closeWithoutEdit
                      : handleClearAction
                  }
                  disabled={
                    saveAdminStatus === tabStatus?.SAVING ||
                    saveAdminStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    adminGrpValue?.[0]?.actions?.[0]?.actionId ===
                      'placeholder' ||
                    saveAdminStatus === tabStatus?.SAVING ||
                    saveAdminStatus === tabStatus?.SAVED
                  }
                  onClick={handleSaveAction}
                  data-testid="save-criteria">
                  {saveAdminStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SimpleBackdrop open={loading} />
    </div>
  );
}
