import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.rdw-editor-main':{
      height:"400px!important",
    },
    '.ag-theme-custom-react .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected)':{
      border:"0px solid transparent!important"
    }
    

  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
