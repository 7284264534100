import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 40,
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export default function PrimaryButton({
  children,
  className,
  onClick,
  hasPermissions,
  ...otherProps
}) {
  const classes = useStyles();
  if (typeof hasPermissions === 'boolean' && !hasPermissions) return null;
  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.root, className)}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
