import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    // top: 18,
    left: 'calc(-50% - 45px)',
    right: 'calc(50% + 90px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      border: '1px solid #036fcb',
    },
  },
  completed: {
    '& $line': {
      border: '1px solid #036fcb',
    },
  },
  line: {
    border: '1px solid #D0D0D0',
    backgroundColor: '#D0D0D0',
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#E4E4E4',
    zIndex: 1,
    color: '#666666',
    width: 28,
    height: 28,
    // padding: 4,
    display: 'flex',
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 16,
  },

  active: {
    background: '#036FCB',
    color: '#fff',
    width: 18,
    height: 18,
  },
  completed: {
    background: '#036FCB',
    color: '#fff',
    height: 26,
    width: 26,
    fontWeight: 600,
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
  tabActive: {
    border: '1px solid #036FCB',
    padding: '4px',
    borderRadius: 8,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    fontWeight: 600,
    height: 28,
    width: 28,
  },
  tabCompleted: {
    border: '1px solid #036FCB',
    background: '#036FCB',
    borderRadius: 8,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: completed ? <Check /> : '1',
    2: completed ? <Check /> : '2',
  };
  return (
    <div
      className={
        active ? classes.tabActive : completed ? classes.tabCompleted : null
      }
      >
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
        >
        {icons[String(props.icon)]}
      </div>
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiStepper-alternativeLabel': {
      alignItems: 'flex-start',
      background: 'none',
      maxWidth: 324,
      padding: 0,
    },
    '& .MuiStep-horizontal': {
      paddingLeft: 0,
    },
    '& .MuiStepLabel-root.MuiStepLabel-alternativeLabel': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    ' & .MuiStepLabel-labelContainer': {
      width: ' 100%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
}));

export default function PriceListStepper({ activeStep, steps,activeLabelStyle }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root,activeLabelStyle)}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        >
        {steps.map(step => (
          <Step key={step}>
            <StepLabel 
            StepIconComponent={ColorlibStepIcon}
            >{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
