import BaseApiService from './BaseApiService';

function _getTransactions({ module, offset, limit = 10 }) {
  return BaseApiService.get(
    `/organization/demo/transactions?module=${module}&offset=${offset}&limit=${limit}`
  );
}

function _getAllModuleCode() {
  return BaseApiService.get(`/organization/demo/transactions/modules`);
}

const TransactionApi = {
  getTransactions: _getTransactions,
  getAllModuleCode: _getAllModuleCode,
};

export default TransactionApi;
