import { useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { SecondaryButton } from 'components/widgets';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import FileIcon from '../../components/icons/FileIcon';
import DragFileIcon from '../../components/icons/DragFileIcon';
import { useSnackbar } from 'notistack';

const styles = theme => ({
  rootContainer: {
    display: 'flex',
    textAlign: 'center',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 33px',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    height: 176,
    transition: 'border .3s ease-in-out',
  },
  acceptStyle: {
    borderColor: '#00e676',
  },
  rejectStyle: {
    borderColor: '#ff1744',
  },
  activeStyle: {
    borderColor: '#2196f3',
  },
  chooseButton: {
    marginTop: 22,
  },
  textContainer: {
    marginTop: 16,
  },
  fileType: {
    color: '#333333',
    fontWeight: 500,
    fontSize: 14,
    opacity: 0.55,
  },
  textDrop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    color: '#036FCB',
    lineHeight: 4,
  },
  dropfileIcon: {
    marginTop: 8,
    width: 36,
    height: 33,
  },
  fileicon: { position: 'relative' },
  dragfiletext: {
    fontSize: 16,
    fontWeight: 500,
  },
  filedescriptionIcon: {
    width: 56,
    height: 34,
  },
});

function DropzoneComponent({ classes, handleButtonClick, titleText }) {
  const [file, setFile] = React.useState(null);
  const inputFile = useRef(null);
  const [dropbox, setDropBox] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      acceptedFiles.forEach(file => {
        setFile(file);
        setDropBox(true);
        handleButtonClick(file);
      });
      rejectedFiles.forEach(file => {
        enqueueSnackbar('Please select a valid file type', {
          variant: 'error',
        });
      });
    },
    [handleButtonClick, enqueueSnackbar]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png',
  });

  const handleClick = () => {
    setDropBox(true);
  };

  const acceptedTypes = ['image/jpeg', 'image/png'];

  const fileOpen = e => {
    if (e.target.files[0]) {
      if (
        acceptedTypes.includes(e.target.files[0].type) &&
        e.target.files[0].size <= 2097152
      ) {
        setFile(e.target.files[0]);
        handleButtonClick(e.target.files[0]);
      } else {
        enqueueSnackbar(
          e.target.files[0].size <= 2097152
            ? 'Please select a valid file type'
            : 'Please select a file of size upto 2MB',
          {
            variant: 'error',
          }
        );
      }
    }
  };

  const openFile = e => {
    inputFile.current.click();
  };

  React.useEffect(() => {}, [file, dropbox]);
  return (
    <div
      {...getRootProps()}
      className={classes.rootContainer}
      onClick={e => openFile(e)}>
      <input
        type="file"
        ref={inputFile}
        accept=".png,.jpeg,.jpg,.svg"
        onChange={e => fileOpen(e)}
        style={{ display: 'none' }}
      />
      {!file ? (
        !dropbox ? (
          <div onClick={handleClick}>
            <input {...getInputProps()} />
            <p className={classes.fileType}>JPG, JPEG, PNG, SVG</p>
            <p className={classes.textContainer}>
              {titleText ? titleText : 'Drag and drop your organization logo or'}
            </p>
            <SecondaryButton
              type="file"
              size="small"
              variant="outlined"
              // onClick={open}
              style={{ marginTop: 10 }}>
              Choose file
            </SecondaryButton>
          </div>
        ) : (
          <div>
            <p className={classes.dragfiletext}>Drag file here</p>
            <DragFileIcon className={classes.dropfileIcon} />
          </div>
        )
      ) : (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <p>
            <FileIcon className={classes.filedescriptionIcon} />
          </p>
          <p className={classes.textDrop}>
            {file.name} - ({(file.size / 1000).toFixed(2)} KB)
          </p>
          <div onClick={handleClick}>
            <SecondaryButton
              type="file"
              size="small"
              variant="outlined"
              style={{ marginTop: 10 }}>
              Choose other File
            </SecondaryButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(DropzoneComponent);
