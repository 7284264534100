import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LogoPopup from 'components/widgets/FlyoutPanel/LogoPopup';
import { PrimaryButton, SecondaryButton } from 'components/widgets';
import ImageDropzone from '../../../containers/SetupOrganization/ImageDropzone';
import OrganizationApi from 'services/api/OrganizationApi';
import HomeIcon from 'components/icons/HomeIcon';
import ApplicationsIcon from 'components/icons/ApplicationsIcon';
import MembersIcon from 'components/icons/MembersIcon';
import ApiOverviewIcon from 'components/icons/ApiOverviewIcon';
import SandboxIcon from 'components/icons/SandboxIcon';
import PricelistIcon from 'components/icons/PricelistIcon';
import { useSnackbar } from 'notistack';
import { SimpleBackdrop } from '../../../components/widgets';
import PartnerApi from 'services/api/PartnerApi';
import {
  // Avatar,
  Box,
  // Button,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography,
  Tooltip,
  // IconButton,
} from '@material-ui/core';
import NavItem from './NavItem';
import clsx from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useUserPermissions } from 'utils/userPermissions';
import NavMenuItems from './_nav';
import { useCallback } from 'react';
import PermissionApi from 'services/api/PermissionApi';
import { saveSolutionPermission } from 'store/actions/userInfo';

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  name: {
    color: '#fff',
  },
  mobileDrawer: {
    width: drawerWidth,
  },
  desktopDrawer: {
    width: drawerWidth,
    top: 66,
    height: 'calc(100% - 66px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },

  content: {
    // backgroundColor: theme.palette.primary.main,
    // color: '#fff',
    // backgroundColor: '#fff',
    // color: theme.palette.primary.main,
  },

  // new stuff begins here

  menuButton: {
    // marginRight: 36,
  },
  hide: {
    display: 'none',
  },

  showName: {
    display: 'block',
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hideName: {
    display: 'none',
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },

  minifier: {
    cursor: 'pointer',
    display: 'flex',

    padding: 15,
    '&:hover': {
      backgroundColor: '#ddd',
      svg: {
        fontSize: 36,
      },
    },
  },

  popUpContainer: {
    width: 431,
    margin: 0,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },

  fileInputContainer: {
    width: 400,
    marginTop: 24,
  },

  buttonWrapper: {
    marginTop: 48,
  },
}));

const icons = {
  HomeIcon: HomeIcon,
  ApplicationsIcon: ApplicationsIcon,
  MembersIcon: MembersIcon,
  ApiOverviewIcon: ApiOverviewIcon,
  SandboxIcon: SandboxIcon,
  PricelistIcon: PricelistIcon,
};

const NavBar = ({
  onMobileClose,
  openMobile,
  isDesktopNavMinified,
  onDesktopNavMinifiedToggled,
  history,
  orgId,
  affiliateId,
  ...otherProps
}) => {
  const [menuItems, setMenuItems] = useState([]);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [newLogo, setNewLogo] = useState();
  const [logo, setLogo] = useState();
  const [data, setData] = useState(null);
  const classes = useStyles();
  const location = useLocation();
  const {
    checkAdminPermission,
    checkDevPermission,
    checkSuperAdminPermission,
    checkITAdminPermission,
  } = useUserPermissions();

  const { enqueueSnackbar } = useSnackbar();
  const isSuperAdmin = checkSuperAdminPermission();
  const isITAdmin = checkITAdminPermission();
  const [fetchedMenuList, setfetchedMenuList] = useState([]);

  const { info: userInfo,solutionPermissions,subFeaturePermissions } = useSelector(state => state.userInfo);

  const selectedSolutionName = solutionPermissions?.get(localStorage.getItem('selectedSolutionId'))?.solutionName
  const dispatch = useDispatch();
  const {
    selectedOrganization: {
      loading: loadingSelectedOrg,
      details: { isApproved },
    },
  } = useSelector(state => state);

  const isOrgApproved = isApproved === 1;

  const getOrgLogo = useCallback(() => {
    async function getLogo() {
      try {
        const logoUrl = await OrganizationApi.getLogo(orgId);
        setLogo(logoUrl);
      } catch (e) {
        enqueueSnackbar('Unable to fetch Organization Logo', {
          variant: 'error',
        });
      }
    }
    getLogo();
  }, [enqueueSnackbar, orgId]);

  const updateLogo = async () => {
    const formData = new FormData();
    formData.append('org_img', newLogo);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      onToggle();
      setLoading(true);
      await OrganizationApi.editLogo(formData, orgId, otherHeaders);
      setLoading(false);
      getOrgLogo();
      enqueueSnackbar('Organization logo updated', {
        variant: 'success',
      });
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while updating organization logo';
      onToggle();
      setLoading(false);
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  // const getMenuItems = useCallback(() => {
  //   async function getMenu() {
  //     try {
  //       const details = await PartnerApi.getMenuItems(orgId);
  //       for (let x in details) {
  //         details[x].icon = icons[details[x].icon];
  //       }
  //       setData(details);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  //   getMenu();
  // }, [orgId]);

  // useEffect(() => getMenuItems(), [getMenuItems]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgId) getOrgLogo();
  }, [orgId, getOrgLogo]);

  // const showName = !isDesktopNavMinified || openMobile;

  useEffect(() => {
    // todo Use Dynamic Menu Items -> R1

    // let solutionId = localStorage.getItem('selectedSolutionId');
    // let roleId = userInfo?.roleId;

    // NavMenuItems?.defaultItems?.forEach(item => {
    //   fetchedMenuList.push({
    //     ...item,
    //     href: isSuperAdmin
    //       ? `/admin/organization/${orgId}${item.href}`
    //       : `/organization/${orgId}${item.href}`,
    //   });
    // });
    // setLoading(true);
    // PermissionApi.getRolePermissionForSolutions({ roleId })
    //   .then(permissionsResponse => {
    //     permissionsResponse &&
    //       permissionsResponse?.forEach(data => {
    //         if (data.solutionId.toString() === solutionId) {
    //           setSelectedSolutionName(data.solutionName);
    //           data?.features?.forEach(item => {
    //             fetchedMenuList.push(item.menuMap);
    //           });
    //         }
    //       });
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setLoading(false);
    //   });
  }, []);

  useEffect(() => {
    let orgRestrictedMenuItems = [];
    let orgPublicdMenuItems = [];
    let affiliateRestrictedItems = [];
    if (orgId) {
      orgPublicdMenuItems = NavMenuItems?.publicOrgItems.map(item => ({
        ...item,
        href: `/organization/${orgId}${item.href}`,
      }));

      if (isOrgApproved) {
        orgRestrictedMenuItems = NavMenuItems?.restrictedAdminItems.map(
          item => ({
            ...item,
            href: isSuperAdmin
              ? `/admin/organization/${orgId}${item.href}`
              : `/organization/${orgId}${item.href}`,
          })
        );

        orgRestrictedMenuItems = orgRestrictedMenuItems.map(item => ({
          ...item,
          subMenu: item?.subMenu?.map(item => ({
            ...item,
            href: isSuperAdmin
              ? `/admin/organization/${orgId}${item.href}`
              : `/organization/${orgId}${item.href}`,
          })),
        }));
      }
    }

    if (affiliateId) {
      // if (data.length !== 0) {
      //   affiliateRestrictedItems = data.map(item => ({
      //     ...item,
      //     href:
      //       affiliateId === 'DEV'
      //         ? `/organization/${orgId}${item.href}`
      //         : `/organization/${orgId}/affiliate/${affiliateId}${item.href}`,
      //   }));
      // } else {
      affiliateRestrictedItems = NavMenuItems?.restrictedDevItems.map(item => ({
        ...item,
        href: `/organization/${orgId}${item.href}`,
      }));
      // }
    }

    const items = NavMenuItems?.rootItems.concat(
      orgPublicdMenuItems,
      orgRestrictedMenuItems,
      affiliateRestrictedItems
    );


    setMenuItems(items);
  }, [orgId, isOrgApproved, affiliateId, isITAdmin, data]);

  const onToggle = () => {
    setShow(!show);
    setDisable(true);
  };

  const openPopup = () => {
    onToggle();
  };

  const handleButtonClick = props => {
    if (props) {
      setNewLogo(props);
      setDisable(false);
    }
  };
  const checkSubFeaturePermissions = menu => {
    const {featureName, subFeatureNames, shouldCheckForSubFeaturePermissions} = menu
    let selectedSolutionId = localStorage.getItem('selectedSolutionId');

    if(menu.featureName === "Manage organization"){
      selectedSolutionId = solutionPermissions.get('manage-org')?.solutionId
    }
    if (shouldCheckForSubFeaturePermissions) {
      for (let subFeature of subFeatureNames) {
        const subFeatureName = `${selectedSolutionId}-${featureName}-${subFeature}`
        if (subFeaturePermissions?.get(subFeatureName)?.length) {
          return true;
        }
      }
    } else return true;

    return false;
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.content}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
        minHeight={64}
        justifyContent="center"
      >
        {logo ? (
          isITAdmin || isSuperAdmin ? (
            <Tooltip title="Update Organization logo" placement="top" arrow>
              <img
                style={{ height: 40, cursor: 'pointer' }}
                onClick={openPopup}
                alt={isDesktopNavMinified ? '' : 'Logo'}
                // src={isDesktopNavMinified ? '/static/home.svg' : '/static/logo.svg'}
                src={isDesktopNavMinified ? null : logo}
              />
            </Tooltip>
          ) : (
            <img
              style={{ height: 40 }}
              alt={isDesktopNavMinified ? '' : 'Logo'}
              src={isDesktopNavMinified ? null : logo}
            />
          )
        ) : (
          <Tooltip title="Update Organization logo" placement="top" arrow>
            <img
              // style={{ height: 75, width: 226 }}
              // onClick={() => {
              //   if (isSuperAdmin) {
              //     history.push('/');
              //   }
              // }}
              style={{ cursor: 'pointer' }}
              onClick={openPopup}
              alt="Logo"
              src={
                isDesktopNavMinified ? '/static/home.svg' : '/static/logo.svg'
              }
              // src={isDesktopNavMinified ? '' : logo}
            />
          </Tooltip>
        )}
      </Box>
      <Box p={2} paddingLeft={0} overflow="auto" style={{ padding: '0px' }}>
        <List style={{ padding: '0px' }}>
          {menuItems
            .filter(listItem => {
              if (
                listItem.solutionId?.includes(selectedSolutionName) ||
                listItem.solutionId?.includes('all') ||
                (isSuperAdmin &&
                  listItem.accessLevel === 'superAdmin' &&
                  listItem.solutionId?.includes('all'))
              ) {
                if(isSuperAdmin && (listItem.title === "Organization" || listItem.title === 'LOBs')) return true
                else  return checkSubFeaturePermissions(listItem);
              } else {
                return checkDevPermission(
                  orgId,
                  affiliateId,
                  listItem.resourceName
                );
              }
            })
            .map((item, index) => (
              <NavItem
                href={item.href}
                subMenu={item.subMenu}
                key={index}
                title={item.title}
                icon={item.icon}
                history={history}
                showOnlyIcon={isDesktopNavMinified && !openMobile}
                isActive={location.pathname.indexOf(item.href) !== -1}
                onMobileClose={onMobileClose}
                loading={loadingSelectedOrg}
                featureName={item.featureName}
              />
            ))}
        </List>
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {!isDesktopNavMinified && (
            <Typography variant="h6" color="textSecondary" align="center">
              Powered by
            </Typography>
          )}
          <img
            src={isDesktopNavMinified ? null : '/static/logo.svg'}
            alt={isDesktopNavMinified ? '' : 'Home'}
          />
        </Box>
        <Hidden smDown>
          <div
            onClick={onDesktopNavMinifiedToggled}
            className={classes.minifier}
            style={{
              justifyContent: !isDesktopNavMinified ? 'flex-end' : 'center',
            }}
          >
            {isDesktopNavMinified ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </div>
        </Hidden>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          // classes={{ paper: classes.desktopDrawer }}

          //new starts
          className={clsx(classes.desktopDrawer, {
            [classes.drawerOpen]: !isDesktopNavMinified,
            [classes.drawerClose]: isDesktopNavMinified,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: !isDesktopNavMinified,
              [classes.drawerClose]: isDesktopNavMinified,
            }),
          }}
          variant="permanent"

          // new ends

          // open
          // variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>

      <LogoPopup onClose={onToggle} open={show}>
        <div className={classes.popUpContainer}>
          <div className={classes.fileInputContainer}>
            <ImageDropzone handleButtonClick={handleButtonClick} />
          </div>
          <div className={classes.buttonWrapper}>
            {
              <React.Fragment>
                <SecondaryButton
                  onClick={onToggle}
                  size="large"
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  type="add"
                  disabled={disable}
                  onClick={updateLogo}
                >
                  Add
                </PrimaryButton>
              </React.Fragment>
            }
          </div>
        </div>
      </LogoPopup>

      <SimpleBackdrop open={loading} />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
