import React from 'react';
import { AssessmentLibrary } from './AssessmentLibrary';

const QuestionBank = (props) => {
    return (
        <>
        <AssessmentLibrary {...props} />
        </>
    )
}

export default QuestionBank;
