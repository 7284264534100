import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckCircleIcon from 'components/icons/CheckCircleIcon';
import { PrimaryButton } from 'components/widgets';
import clsx from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  row: {
    paddingBottom: theme.spacing(3),
  },
  check: {
    fontSize: 48,
    color: '#27AE60',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  body: {
    textAlign: 'center',
  },
  dialogPaper: {
    borderRadius: theme.spacing(3),
  },
}));

function SuccessDialog(props) {
  const { onClose, open, title, body, actionText, actionClick } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={handleClose}
      aria-labelledby="success-dialog-title"
      open={open}>
      <div className={classes.closeContainer}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className={classes.container}>
        <div className={classes.row}>
          <CheckCircleIcon className={classes.check} />
        </div>

        <div className={clsx(classes.row, classes.title)}>{title}</div>
        <div className={clsx(classes.row, classes.body)}>{body}</div>
        <div className={classes.row}>
          <PrimaryButton onClick={actionClick}>{actionText}</PrimaryButton>
        </div>
      </div>
    </Dialog>
  );
}

SuccessDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AppCreatedDialog({ open, onClose, ...otherProps }) {
  return <SuccessDialog open={open} onClose={onClose} {...otherProps} />;
}
