import BaseApiService from './BaseApiService';

function _createRole({ orgId, payload }) {
  return BaseApiService.post(`/${orgId}/role`, null, payload);
}

function _listRoles({ orgId, searchValue, offset }) {
  return BaseApiService.get(
    `/${orgId}/role?searchText=${searchValue}&offset=${offset}&limit=30`
  );
}

function _deleteRole({ orgId, roleId }) {
  return BaseApiService.delete(`/${orgId}/role/${roleId}`);
}

const RolesApi = {
  createRole: _createRole,
  listRoles: _listRoles,
  deleteRole: _deleteRole,
};

export default RolesApi;
