import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon width="17" height="15" viewBox="0 0 22 15" {...props}>
      <path
        d="M8 13.6797L7.29688 12.9375C6.28125 11.9609 6.32031 10.3594 7.29688 9.38281L8 8.71875C7.6875 8.67969 7.41406 8.67969 7.17969 8.67969C4.95312 8.67969 0.5 9.77344 0.5 12V13.6797H8ZM7.17969 7C9.01562 7 10.5 5.51562 10.5 3.67969C10.5 1.84375 9.01562 0.320312 7.17969 0.320312C5.34375 0.320312 3.82031 1.84375 3.82031 3.67969C3.82031 5.51562 5.34375 7 7.17969 7ZM11.4766 13.4844C11.1641 13.7969 10.6172 13.7969 10.3047 13.4844L8.58594 11.7266C8.27344 11.4141 8.27344 10.9062 8.58594 10.5938C8.89844 10.2422 9.44531 10.2422 9.75781 10.5938L10.8906 11.7266L14.6016 8.01562C14.9141 7.66406 15.4219 7.66406 15.7734 8.01562C16.0859 8.32812 16.0859 8.83594 15.7734 9.14844L11.4766 13.4844Z"
        fill="#007D32"
      />
    </SvgIcon>
  );
};

export default Icon;
