import { makeStyles } from '@material-ui/core/styles';
import clsx from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '2px 2px 24px rgba(75, 75, 75, 0.08)',
    backgroundColor: '#FFFFFF',
    borderRadius: theme.spacing(2),
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

function Card({ children, className, ...otherProps }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...otherProps}>
      {children}
    </div>
  );
}

export default Card;
