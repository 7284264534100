import React from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { CONFIRMATION_MODAL } from '../../../shared/constants';

const styles = (theme) => ({
  confirmationPopupButtonNo: {
    fontSize: '1rem',
    width: '140px',
    height: '40px',
    color: '#036FCB',
    border: '1px solid #036FCB',
    textTransform: 'none',
    borderRadius: '40px',
    boxShadow: '0px 0px 0px 0px',
  },
  confirmationPopupButtonYes: {
    fontSize: '1rem',
    width: '140px',
    height: '40px',
    color: '#FFFFFF',
    background: '#036FCB',
    textTransform: 'none',
    borderRadius: '40px',
    boxShadow: '0px 0px 0px 0px',
    marginRight: '2%',
    '&:hover': {
      color: '#FFFFFF',
      background: '#038FCB',
    },
  },
  confirmationPopup: {
    width: '560px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    borderRadius: '24px',
    margin: 0,
    minHeight: '200px',
  },
  confirmationPopupRevoked: {
    width: '560px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    borderRadius: '24px',
    margin: 0,
    minHeight: '232px',
  },
  confirmationPopupTypography: {
   margin:'8%',
  },
  
  confirmationPopupTypographyRevoked: {
    // marginTop: '7%',
    marginLeft: '9%',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 14,
    color: '#4B4B4B',
    marginTop: 24,
    lineHeight: 1.6,
  },
  confirmationPopupTypographyHeading: {
    marginTop: '5%',
    marginLeft: '9%',
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#333333',
  },
});

const ConfirmationModal = (props) => {
  const {
    openConfirmationModal,
    setOpenConfirmationModal,
    exitDDUI,
    classes,
    modalHeading,
    subHeading,
  } = props;
  return (
    <Modal keepMounted open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
      <Box className={modalHeading ? classes.confirmationPopupRevoked : classes.confirmationPopup} style={{textAlign:"center"}}>
        <Box>
          {modalHeading && (
            <Typography className={classes.confirmationPopupTypographyHeading} variant="h3">
              {modalHeading}
            </Typography>
          )}
          <Typography
            className={
              subHeading
                ? classes.confirmationPopupTypographyRevoked
                : classes.confirmationPopupTypography
            }
            variant="h4"
          >
            {subHeading || CONFIRMATION_MODAL.HEADING || ''}
          </Typography>
          <Box
            className={
              modalHeading
                ? classes.confirmationPopupButtonsInRevoke
                : classes.confirmationPopupButtons
            }
          >
            <Button
              className={classes.confirmationPopupButtonYes}
              onClick={() => {
                setOpenConfirmationModal(false);
                exitDDUI();
              }}
            >
              {CONFIRMATION_MODAL.BUTTON_YES || ''}
            </Button>
            <Button
              className={classes.confirmationPopupButtonNo}
              onClick={() => setOpenConfirmationModal(false)}
            >
              {CONFIRMATION_MODAL.BUTTON_NO || ''}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default withStyles(styles, { withTheme: true })(ConfirmationModal);
