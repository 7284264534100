import { useEffect, useState } from 'react';
import {
  NewFooterActions,
  FormTextField,
  FormNewEmailField,
  SimpleBackdrop,
  FormSelectField,
} from 'components/widgets';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
  Grid,
  FormLabel,
  Box,
  TextField,
} from '@material-ui/core';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import OrganizationApi from 'services/api/OrganizationApi';
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#E4E7EC',
    },
  },
})(TextField);

export default function NewUserFlyoutContent({
  onToggle,
  user,
  allAffiliates,
  allLobs,
  orgId,
  onUserUpdated,
}) {
  const roles = [
    { code: 'IT_ADMIN', name: 'IT admin' },
    { code: 'ORGANIZATION_ADMIN', name: 'Organization Admin' },
    { code: 'DEVELOPER', name: 'Developer' },
    { code: 'COMPLIANCE_MANAGER', name: 'Compliance manager' },
    { code: 'ContentManager', name: 'Content manager' },
    { code: 'CustomerSupportRepresentative', name: 'Customer support representative' },

  ];
  const [saving, setSaving] = useState(false);
  const [userType, setUserType] = useState(
    user?.orgUserType ? user.orgUserType : 'internal'
  );
  const [roleType, setRoleType] = useState(
    user?.roleName
      ? (user.roleName === 'INTERNAL_DEVELOPER' ||
        user.roleName === 'EXTERNAL_DEVELOPER' || user.roleName === 'DEVELOPER')
        ? 'DEVELOPER'
        : user.roleName
      : ''
  );
  const [lobs, setLobs] = useState('');
  const [affiliates, setAffiliates] = useState('');
  const [showLobs, setShowLobs] = useState(false);
  const [domainList, setDomaiList] = useState([]);
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const isUpdatingUser = user?.userId ? true : false;

  const fetchAllDomains = () => {
    OrganizationApi.getAllDomains({ orgId }).then(res => {
      setDomaiList(res);
    });
  };

  useEffect(() => {
    async function fetchDetails() {
      if (orgId) {
        fetchAllDomains();
      }
    }
    fetchDetails();
  }, [orgId]);

  useEffect(() => {
    if (allAffiliates) {
      let options = allAffiliates.map(item => ({
        code: item.id,
        name: item.name,
      }));
      setAffiliates(options);
    }
  }, [allAffiliates]);

  useEffect(() => {
    if (domainList.length !== 0 && email !== '' && userType === 'external') {
      setOrgName(
        domainList.filter(item => item.domain === `@${email?.split('@')[1]}`)[0]
          ?.orgName
      );
    } else {
      setOrgName('');
    }
  }, [email, domainList, userType]);

  useEffect(() => {
    if (domainList.length !== 0) {
      if (userType === 'internal') {
        setDomain(
          domainList
            .filter(item => item.internalDomain === true)
            .map(item => item.domain)
        );
      } else {
        setDomain(
          domainList
            .filter(item => item.internalDomain === false)
            .map(item => item.domain)
        );
      }
    }
  }, [domainList, userType]);

  const onUserSubmit = async values => {
    let data = {};

    if (isUpdatingUser) {
      data = {
        firstName: values.firstName,
        lastName: values.lastName,
        userId: values.userId,
        emailAddress: values.emailId,
      };
    } else {
      data = {
        ...values,
      };
        delete data.userType;
      if (data.role === 'IT_ADMIN' || data.role === 'Organization Admin') {
        delete data.organizationName;
        delete data.affiliate;
        delete data.lob;
      } else if (data.role === 'COMPLIANCE_MANAGER') {
        data.lob = [data.lob];
        data.affiliate = [data.affiliate];
        delete data.organizationName;
      } else {
        delete data.affiliate;
        delete data.lob;
        if (userType === 'internal' || 'DEVELOPER') {
          data.role = 'INTERNAL_DEVELOPER';
          delete data.organizationName;
        } else {
          data.role = 'EXTERNAL_DEVELOPER';
          data.organizationName = orgName;
        }
      }
    }

    setSaving(true);
    try {
      isUpdatingUser
        ? await OrganizationUsersApi.updateUserV2(orgId, data)
        : await OrganizationUsersApi.createrUserV2(orgId, data);
      onUserUpdated(userType);
      setSaving(false);
      enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
    } catch (error) {
      setSaving(false);
      console.log("error",error?.response?.data)
      enqueueSnackbar(
        error?.response?.data
          ? error?.response?.data || error.message.data
          : 'Something went wrong while sending invitation',
        {
          variant: 'error',
        }
      );
    }
  };

  const onValueChange = event => {
    setRoleType(event.target.value);
  };

  const onAffiliateValueChange = event => {
    setShowLobs(true);
    setLobs(
      allLobs
        .filter(item => item.affiliates[0].id === event.target.value)
        .map(item => ({ code: item.id, name: item.name }))
    );
  };

  return (
    <>
      {/* <FormControl
        component="fieldset"
        style={{ marginLeft: '8px', marginBottom: '20px', padding: '0px 24px' }}
      >
        <RadioGroup
          aria-label="developerType"
          name="developerType"
          value={userType}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value="internal"
            disabled={isUpdatingUser}
            control={<Radio />}
            label="Internal"
          />
          <FormControlLabel
            value="external"
            disabled={isUpdatingUser}
            control={<Radio />}
            label="External"
          />
        </RadioGroup>
      </FormControl> */}
      <Form
        onSubmit={onUserSubmit}
        initialValues={
          isUpdatingUser ? { ...user, userType: user.orgUserType } : user
        }
        render={({ handleSubmit, pristine, values, form }) => {
          setEmail(values.emailAddress);
          return (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid container style={{ padding: '0px 24px' }}>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      marginLeft: '8px',
                      marginBottom: '20px',
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                      <Field
                        name="userType"
                        component="input"
                        type="radio"
                        value="internal"
                        disabled={isUpdatingUser}
                      />
                      <OnChange name="userType">
                        {value => {
                          setUserType(value);
                          form.change('emailAddress');
                          form.resetFieldState('emailAddress');
                        }}
                      </OnChange>
                      <FormLabel component='legend' style={{fontWeight: 400, color: '#000'}}>Internal</FormLabel>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                      <Field
                        name="userType"
                        component="input"
                        type="radio"
                        value="external"
                        disabled={isUpdatingUser}
                      />
                      <OnChange name="userType">
                        {value => {
                          setUserType(value);
                          form.resetFieldState('emailAddress');
                          form.change('emailAddress');
                        }}
                      </OnChange>
                      <FormLabel component='legend' style={{fontWeight: 400, color: '#000'}}>External</FormLabel>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box style={{ height: '90px' }}>
                    <FormLabel
                      component="legend"
                      style={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#344054',
                        marginLeft: '8px',
                      }}
                    >
                      First name
                    </FormLabel>
                    {isUpdatingUser ? (
                      <Box style={{ margin: '8px' }}>
                        <DarkerDisabledTextField
                          name="firstName"
                          disabled
                          value={user.firstName}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Box>
                    ) : (
                      <FormTextField
                        name="firstName"
                        placeholder="Enter the first name"
                        dataTestId={'first-name-testId'}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box style={{ height: '90px' }}>
                    <FormLabel
                      component="legend"
                      style={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#344054',
                        marginLeft: '8px',
                      }}
                    >
                      Last name
                    </FormLabel>
                    {isUpdatingUser ? (
                      <Box style={{ margin: '8px' }}>
                        <DarkerDisabledTextField
                          name="lastName"
                          disabled
                          value={user.lastName}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Box>
                    ) : (
                      <FormTextField
                        name="lastName"
                        placeholder="Enter the last name"
                        dataTestId={'last-name-testId'}
                      />
                    )}
                  </Box>
                </Grid>
                {isUpdatingUser ? (
                  domain !== '' && (
                    <Grid item xs={12} md={12}>
                      <Box style={{ height: '90px' }}>
                        <FormLabel
                          component="legend"
                          style={{
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#344054',
                            marginLeft: '8px',
                          }}
                        >
                          Email id
                        </FormLabel>
                        <FormNewEmailField
                          name={isUpdatingUser ? 'emailId' : 'emailAddress'}
                          placeholder="Enter the email id"
                          domain={domain}
                          dataTestId={'new-email-testId'}
                        />
                      </Box>
                    </Grid>
                  )
                ) : (
                  <Grid item xs={12} md={12}>
                    <Box style={{ height: '90px' }}>
                      <FormLabel
                        component="legend"
                        style={{
                          fontWeight: 500,
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: '#344054',
                          marginLeft: '8px',
                        }}
                      >
                        Email id
                      </FormLabel>
                      <FormNewEmailField
                        name={isUpdatingUser ? 'emailId' : 'emailAddress'}
                        placeholder="Enter the email id"
                        domain={domain}
                        dataTestId={'new-email-testId'}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box style={{ height: '90px' }}>
                    <FormLabel
                      component="legend"
                      style={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#344054',
                        marginLeft: '8px',
                      }}
                    >
                      Roles
                    </FormLabel>
                    {isUpdatingUser ? (
                      <Box style={{ margin: '8px' }}>
                        <DarkerDisabledTextField
                          name="role"
                          disabled
                          value={
                            roles.filter(item => item.code === roleType)[0].name
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Box>
                    ) : (
                      <FormSelectField
                        name="role"
                        placeholder="Select roles"
                        options={roles}
                        onValueChange={onValueChange}
                        dataTestId={'role-select-testId'}
                        required
                      />
                    )}
                  </Box>
                </Grid>

                {userType === 'external' && roleType === 'DEVELOPER' && (
                  <Grid item xs={12}>
                    <Box style={{ height: '90px' }}>
                      <FormLabel
                        component="legend"
                        style={{
                          fontWeight: 500,
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: '#344054',
                          marginLeft: '8px',
                        }}
                      >
                        Organization name
                      </FormLabel>
                      <Box style={{ margin: '8px' }}>
                        <DarkerDisabledTextField
                          name="organizationName"
                          disabled
                          value={orgName}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}

                {roleType === 'COMPLIANCE_MANAGER' && (
                  <>
                    <Grid item xs={12}>
                      <Box style={{ height: '90px' }}>
                        <FormLabel
                          component="legend"
                          style={{
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#344054',
                            marginLeft: '8px',
                          }}
                        >
                          Affiliate
                        </FormLabel>
                        {isUpdatingUser ? (
                          <Box style={{ margin: '8px' }}>
                            <DarkerDisabledTextField
                              name="affiliate"
                              disabled
                              value={user.affiliate}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          </Box>
                        ) : (
                          <FormSelectField
                            name="affiliate"
                            placeholder="Select affiliate"
                            options={affiliates}
                            onValueChange={onAffiliateValueChange}
                            required
                          />
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box style={{ height: '90px' }}>
                        {(isUpdatingUser || showLobs) && (
                          <FormLabel
                            component="legend"
                            style={{
                              fontWeight: 500,
                              fontSize: '12px',
                              lineHeight: '16px',
                              color: '#344054',
                              marginLeft: '8px',
                            }}
                          >
                            {lobs.length === 0
                              ? 'No LOBs found for the selected affiliate'
                              : 'LOB'}
                          </FormLabel>
                        )}
                        {isUpdatingUser ? (
                          <Box style={{ margin: '8px' }}>
                            <DarkerDisabledTextField
                              name="lob"
                              disabled
                              value={user.lobs}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          </Box>
                        ) : (
                          lobs.length !== 0 && (
                            <FormSelectField
                              name="lob"
                              placeholder="Select LOB"
                              options={lobs}
                              required
                            />
                          )
                        )}
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>

              <NewFooterActions
                saveLabel={
                  isUpdatingUser ? 'Reinitiate invite request' : 'Invite'
                }
                type="submit"
                onToggle={onToggle}
                disabled={
                  user?.orgUserStatus === 'Expired'
                    ? saving
                    : pristine || saving
                }
              />
              <SimpleBackdrop open={saving} />
            </form>
          );
        }}
      />
    </>
  );
}
