import { TextField as MuiTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback } from 'react';
import clsx from 'classnames';

const useStyles = makeStyles(theme => ({
  textField: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // height: 90,
  },
}));

function TextAreaField({
  name,
  touched,
  onChange,
  trim = false,
  error,
  maxLength,
  upperCase = false,
  dataTestId,
  value,
  customClass,
  customInputFieldStyles,
  minRows,
  disabled,
  ...otherProps
}) {
  const classes = useStyles();

  const hasError = error && touched;

  const errorText = error && touched && error;

  const onChangeEventHandler = useCallback(
    event => {
      let text = event.target.value;

      if (trim) {
        text = text.trim();
      }

      if (upperCase) {
        text = text.toUpperCase();
      }

      onChange(text);
    },
    [trim, upperCase, onChange]
  );

  return (
    <MuiTextField
      className={clsx(classes.textField, customClass)}
      value={value}
      id={name}
      margin="dense"
      variant="outlined"
      name={name}
      error={hasError}
      onChange={onChangeEventHandler}
      helperText={
        <div>
          {maxLength && (
            <div
              style={{
                position: 'absolute',
                bottom: errorText ? '22%' : '5%',
                right: errorText ? '0.5%' : '0.5%',
              }}>
              {`${value.length}/${maxLength}`}
            </div>
          )}
          <div>{errorText}</div>
        </div>
      }
      FormHelperTextProps={{ component: 'div' }}
      inputProps={{
        maxLength,
        'data-testid': dataTestId || '',
        className: customInputFieldStyles,
      }}
      multiline
      minRows={minRows || 4}
      disabled={disabled}
      {...otherProps}
    />
  );
}

export default TextAreaField;
