import InternalUserPage from 'containers/ManageUsers/InternalUserPage';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import DataPartnersApi from 'services/api/DataPartnersApi';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';

const UserManageRole = ({ selectedRoleValue, isManageRole }) => {
  const [loading, setLoading] = useState(false);
  const [affiliates, setAffiliates] = useState([]);
  const [lobs, setLOBs] = useState([]);

  var orgId = sessionStorage.getItem('orgId');

  const manageRoldId = selectedRoleValue?.roleId;

  const getAffiliateList = useCallback(async () => {
    setLoading(true);
    try {
      const results = await DataPartnersApi.getAllPartners(orgId);
      const data = results.filter(item => item.isDeleted !== 1);
      const options = data.map(fItem => ({ id: fItem.id, name: fItem.name }));
      setAffiliates(options);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [orgId]);

  const getLobList = useCallback(async () => {
    setLoading(true);
    try {
      const results = await OrganizationUsersApi.getAllLOB({ orgId });
      const data = results.filter(item => item.isDeleted !== 1);
      setLOBs(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      getAffiliateList();
      getLobList();
    }
  }, [orgId, getAffiliateList, getLobList]);
  return (
    <div>
      <InternalUserPage
        isManageRole={isManageRole}
        manageRoldId={manageRoldId}
        affiliates={affiliates}
        lobs={lobs}
        hideRole={true}
        roleName={selectedRoleValue?.roleName}
      />
    </div>
  );
};

export default UserManageRole;
