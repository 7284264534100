import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import 'assets/swagger-files/customSwagger.css';
import { Box, Typography } from '@material-ui/core';
import SessionService from 'services/SessionService';
import { STORAGE_KEYS } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  description: {
    paddingLeft: 20,
  },
  testIds: {
    marginTop: 20,
    paddingLeft: 20,
  },
}));

const SwaggerContainer = ({ tabValue, description, spec, testIds = null }) => {
  const classes = useStyles();
  const token = `Bearer ${SessionService.getItem(STORAGE_KEYS.AUTH_TOKEN)}`;

  const onCompleteRendering = system => {
    system.preauthorizeApiKey('Bearer', token);
  };

  return (
    <>
      <Box className={classes.description}>{description}</Box>
      {testIds !== null && (
        <Box className={classes.testIds}>
          <Typography>{`Sample Patient IDs : `}</Typography>
          <Box display={'flex'} gridGap={'2px'}>
            {testIds?.map((testid, idx) => (
              <Typography style={{ fontWeight: 500 }}>
                {`${testid}${idx !== testIds.length - 1 ? ',' : ''}`}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
      <SwaggerUI spec={spec} onComplete={onCompleteRendering} />
    </>
  );
};

export default SwaggerContainer;
