import React, { useEffect, useCallback, useRef } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DEVELOPER_SIGNUP_CONSTANTS } from 'shared/constants';
import ApprovalPending from '../../assets/ApprovalPending.svg';
import { PrimaryButton, SimpleBackdrop } from 'components/widgets';
import SignUp from 'services/api/SignUp';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#FFFFFF',
    height: '304px',
    maxWidth: '560px',
    margin: '10% auto 0px',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '24px',
    padding: '40px',
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#101828',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  passwordContainer: {
    backgroundColor: '#FFFFFF',
    // height: '304px',
    maxWidth: '560px',
    margin: '10% auto 0px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '24px',
    padding: '40px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  formTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#344054',
    textAlign: 'left',
  },
}));

const UpdatePassword = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [passwordVAlidationError,setPasswordValidationError]= useState(false);
  const [showPasswordCountError, setShowPasswordCountError] = useState(false);
  const [sessionState, setSessionState] = useState(true);
  const [passwordState, setPasswordState] = useState(false);
  const [sessionValue, setSessionValue] = useState('');

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const session = encodeURIComponent(queryParameters.get('session'));
    setSessionValue(session);
    checkForValidSession(session);
  }, []);

  const checkForValidSession = async sessionValue => {
    SignUp.checkValidSession(sessionValue)
      .then(res => {
        setSessionState(false);
        setPasswordState(true);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setSessionState(true);
        setLoading(false);
      });
  };

  const handleOnchange = (field, value) => {
    if (field === 'passwordValue' && value?.length <= 7 && value?.length > 0) {
      setShowPasswordCountError(true);
    } else {
       // "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
     
      const validPassword = new RegExp(
        '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!%^&-+=()])(?=\\S+$).{8,}$'
     );
      if (!validPassword.test(value) && value?.length > 0) {
        setPasswordValidationError(true);
     }
     else{
      setPasswordValidationError(false);
     }

      setShowPasswordCountError(false);
    }

    if (
      (field === 'confirmPasswordValue' && passwordValue !== value && value) ||
      (field === 'passwordValue' &&
        confirmPasswordValue &&
        confirmPasswordValue !== value &&
        value)
    ) {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }
  };

  const handleSave = () => {
    let payload = {
      password: passwordValue,
    };

    setLoading(true);

    SignUp.updatePassword(payload,sessionValue)
      .then(res => {
        setLoading(false);
        window.location.pathname =  `/login`;
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message || DEVELOPER_SIGNUP_CONSTANTS.ERROR_MESSAGE, {
            variant: "error",
            autoHideDuration: 900,
          });
      });
  };

  return (
    <div>
      <Box>
        <img
          src="https://mph-dev-campaign-email-assets.s3.amazonaws.com/default/mphlogo.png"
          alt="Mpowered Logo"
          style={{ height: '26px' }}
        />
        {sessionState ? (
          <Box className={classes.container}>
            <img
              src={ApprovalPending}
              alt={'Pending state icon'}
              style={{ height: '120px' }}
            />
            <Typography className={classes.title}>
              {DEVELOPER_SIGNUP_CONSTANTS.SESSION_EXPIRED_TITLE}
            </Typography>
            <Typography className={classes.subtitle}>
              {DEVELOPER_SIGNUP_CONSTANTS.SESSION_EXPIRED_CONTENT}
            </Typography>
          </Box>
        ) : (
          passwordState && (
            <Box className={classes.passwordContainer}>
              <Grid container>
                <Grid item sm={12} xs={12}>
                  <Typography className={classes.formTitle}>
                  {DEVELOPER_SIGNUP_CONSTANTS.PASSWORD_FIELD}
                  </Typography>
                  <TextField
                    required
                    className={classes.formTextField}
                    margin="dense"
                    variant="outlined"
                    placeholder="Enter password"
                    value={passwordValue}
                    onChange={e => {
                      setPasswordValue(e.target.value);
                      handleOnchange('passwordValue', e.target.value);
                    }}
                    type="password"
                    autoComplete="off"
                    autoFocus
                    helperText={
                      showPasswordCountError ? 'minimum length 8 characters' : passwordVAlidationError ? "Password needs to be 8 characters long , 1 uppercase & lowercase character, number and special character" : ''
                    }
                    style={{ background: 'white' }}
                  />
                </Grid>
                <Grid item sm={12} xs={12} style={{ marginTop: '5%' }}>
                  <Typography className={classes.formTitle}>
                  {DEVELOPER_SIGNUP_CONSTANTS.CONFIRM_PASSWORD_FIELD}
                  </Typography>
                  <TextField
                    required
                    className={classes.formTextField}
                    margin="dense"
                    variant="outlined"
                    placeholder="Enter confirm password"
                    value={confirmPasswordValue}
                    onChange={e => {
                      setConfirmPasswordValue(e.target.value);
                      handleOnchange('confirmPasswordValue', e.target.value);
                    }}
                    type="password"
                    helperText={showPasswordError ? 'password not matched' : ''}
                    error={showPasswordError ? 'password not matched' : ''}
                    autoComplete="off"
                    style={{ background: 'white' }}
                  />
                </Grid>
              </Grid>
              <PrimaryButton
                disabled={
                  showPasswordError ||
                  showPasswordCountError || 
                  passwordVAlidationError ||
                  passwordValue?.length === 0 ||
                  confirmPasswordValue?.length === 0
                    ? true
                    : false
                }
                onClick={handleSave}
              >
                Submit
              </PrimaryButton>
            </Box>
          )
        )}
      </Box>
      <SimpleBackdrop open={loading} />
    </div>
  );
};

export default UpdatePassword;
