import {
  // SlideOutPanel,
  FlyoutPanel,
} from 'components/widgets';

import NewOrgContent from './NewOrgContent';

function NewOrgSlideout({ open, onToggle, isAddingNew, ...otherProps }) {
  return (
    <FlyoutPanel
      title={isAddingNew ? 'Setup organization' : 'Edit organization details'}
      maxWidth={isAddingNew ? 'md' : 'sm'}
      open={open}
      onToggle={onToggle}>
      {open ? (
        <NewOrgContent open={open} isAddingNew={isAddingNew} onToggle={onToggle} {...otherProps} />
      ) : null}
    </FlyoutPanel>
  );
}

export default NewOrgSlideout;
