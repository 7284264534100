import {
  makeStyles,
  Paper,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import HistoryIcon from '@material-ui/icons/History';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SimpleBackdrop } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    // background: '#F5F7F8',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    fontSize: '12px',
    color: '#002534',
    height: '25px',
    width: '25px',
  },
}));

const HistoryForRulesCriteria = ({
  isCriteria = false,
  criteriaHistoryForRules,
  criteriaLoader,
}) => {
  const classes = useStyles();

  const avatarInitials = name => {
    return name
      ?.split(' ')
      ?.map(item => item[0])
      ?.join('');
  };

  return (
    <>
      <DrawerBoxComponent>
        {criteriaHistoryForRules?.length > 0 &&
          criteriaHistoryForRules[0] !== false && (
            <Paper
              elevation={0}
              className={classes.paper}
              style={{ background: !isCriteria && '#F5F7F8' }}>
              <Box style={{ display: 'flex' }}>
                <Typography
                  variant="h5"
                  data-testid="version-history-title"
                  style={{ marginBottom: '15px', fontWeight: 600 }}>
                  Version history for criteria
                </Typography>
              </Box>
              <Paper
                elevation={0}
                style={{
                  borderRadius: '8px',
                  border: '1px solid #E4E7EC',
                  background: '#E4E7EC',
                }}>
                <Box>
                  {criteriaHistoryForRules?.map((item, index) => (
                    <Box>
                      <>
                        {item.version > 0 && (
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                {' '}
                                <Typography>
                                  {item?.version === 1
                                    ? item?.createdBy
                                    : item?.updatedBy}{' '}
                                  has changed criteria values for criteria{' '}
                                  {item?.name}
                                </Typography>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box
                                style={{
                                  backgroundColor: '#ffffff',
                                  padding: '16px',
                                  display: 'flex',
                                  border: '1px solid #E4E7EC',
                                }}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <HistoryIcon
                                    fontSize="small"
                                    style={{ fontWeight: 400 }}
                                  />
                                  <Typography
                                    style={{
                                      marginLeft: '5px',
                                      fontWeight: 400,
                                    }}
                                    data-testid="created">
                                    {item?.version === 1
                                      ? 'Created on'
                                      : 'Updated on'}{' '}
                                    {item?.version === 1
                                      ? moment(item?.createdAt).format(
                                          'MMM DD, YYYY'
                                        )
                                      : moment(item?.updatedAt).format(
                                          'MMM DD, YYYY'
                                        )}
                                  </Typography>
                                </Box>

                                <Box
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <Typography
                                    variant="h5"
                                    style={{ marginLeft: '24px' }}>
                                    <Typography className={classes.fieldValue}>
                                      <div className={classes.nameContainer}>
                                        <Avatar
                                          className={classes.avatar}
                                          data-testid="avatar">
                                          {item?.version === 1
                                            ? avatarInitials(item?.createdBy)
                                            : avatarInitials(item?.updatedBy)}
                                        </Avatar>
                                        <div style={{ fontWeight: 400 }}>
                                          {item?.version === 1
                                            ? item?.createdBy
                                            : item?.updatedBy}
                                        </div>
                                      </div>
                                    </Typography>
                                  </Typography>
                                </Box>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Paper>
          )}
      </DrawerBoxComponent>
      <SimpleBackdrop loading={criteriaLoader} />
    </>
  );
};

export default HistoryForRulesCriteria;
