import React, { useState } from 'react';
import { Box, Select, MenuItem, FormControl, Button } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { PageHeaderTitle } from 'components/widgets';
import DragDropComponent from './DragDropComponent';

const CustomReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tableNameInfo, setTableNameInfo] = useState('');

  const handleCategoryChange = event => {
    setTableNameInfo(event.target.value);
  };

  const handleStartDate = date => {
    setStartDate(date);
  };

  const handleEndDate = date => {
    setEndDate(date);
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
      }}
    >
      <Box><Button onClick={()=>window.history.back()} style={{ border: "1px solid #7cbbee", borderRadius: "10px", textTransform: "capitalize"}}>Back</Button></Box>
      <Box>
        <PageHeaderTitle sx={{ marginLeft: '0px' }}>
          Create custom report
        </PageHeaderTitle>
      </Box>
      <Box sx={{ margin: '16px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Box sx={{ marginTop: '8px' }}>
              <FormControl variant="outlined">
                <Select
                  value={tableNameInfo}
                  onChange={handleCategoryChange}
                  style={{ width: '241.5px' }}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select a table</em>
                  </MenuItem>
                  <MenuItem value={'user'}>User</MenuItem>
                  <MenuItem value={'assessment'}>Assessment</MenuItem>
                  <MenuItem value={'campaign'}>Campaign</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              disableFuture
              label="Select start date"
              value={startDate}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={endDate}
            />
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              disableFuture
              margin="normal"
              label="Select end date"
              value={endDate}
              onChange={handleEndDate}
              minDate={startDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Box>
        </MuiPickersUtilsProvider>
      </Box>
      <Box>
        <DragDropComponent tableName={tableNameInfo} />
      </Box>
    </Box>
  );
};

export default CustomReport;
