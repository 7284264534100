import { Box } from '@material-ui/core';
import SaveApplicationFlyoutContent from './SaveApplicationFlyoutContent';
import ViewApplicationFlyoutContent from './ViewApplicationFlyoutContent';
import AppCreatedDialog from './AppCreatedDialog';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ActivityIcon from 'components/icons/ActivityIcon';
import ApplicationFullScreenDialog from 'components/widgets/FullScreenDialog/ApplicationFullScreenDialog';
import { AlertDialog as ConfirmActionDialog } from 'components/widgets';

function SaveApplicationFlyout({
  onToggle,
  open,
  applicationData,
  orgId,
  affiliateId,
  isViewMode,
  isProd,
  isProdUpdate,
  getViewDetails,
  ...otherProps
}) {
  const [createdData, setCreatedData] = useState({
    show: false,
    orgId: null,
    affiliateId: null,
  });
  const [confirmationDialog, setConfirmationDialog] = useState({ open: false });

  const history = useHistory();

  const onActionClick = () => {
    history.push(
      `/organization/${createdData.orgId}/affiliate/${createdData.affiliateId}/sandbox`
    );

    setCreatedData({ show: false, orgId: null, affiliateId: null });
  };

  const onCloseApplicationCreation = () => {
    if (isViewMode) {
      onToggle(false);
      return;
    }
    setConfirmationDialog({
      open: true,
      title: 'Cancel application creation',
      description: 'Are you sure you want to cancel creating the application?',
    });
  };

  const onConfirmClose = () => {
    setConfirmationDialog({
      open: false,
    });
    onToggle(false);
  };

  return (
    <Box>
      <ApplicationFullScreenDialog
        title={
          isProd
            ? 'Complete filling the form'
            : applicationData
            ? open && isViewMode
              ? applicationData.name
              : 'Update application'
            : 'Create application'
        }
        subTitle={
          isProd &&
          'Verify all the details that you have entered, answer all the questions and send request to admin for prod key approval'
        }
        TitleIcon={'url'}
        desc={open && isViewMode ? applicationData.desc : null}
        open={open}
        onToggle={onCloseApplicationCreation}
        styles={{
          img: {
            width: '40px',
            height: '40px',
            flexShrink: 0,
            marginRight: '20px',
          },
          container: {
            margin: '20px 20px 0px 20px',
            borderRadius: '8px',
          },
          ...(isProd && {
            container: {
              backgroundColor: 'transparent',
            },
          }),
        }}
      >
        {open && (
          <>
            {isViewMode ? (
              <ViewApplicationFlyoutContent
                onToggle={onToggle}
                applicationData={applicationData}
                orgId={orgId}
                affiliateId={affiliateId}
                {...otherProps}
              />
            ) : (
              <SaveApplicationFlyoutContent
                onToggle={onToggle}
                applicationData={applicationData}
                onCloseApplicationCreation={onCloseApplicationCreation}
                onCreateSuccess={(_orgId, _affiliateId) =>
                  setCreatedData({
                    show: true,
                    orgId: _orgId,
                    affiliateId: _affiliateId,
                  })
                }
                orgId={orgId}
                affiliateId={affiliateId}
                isProd={isProd}
                isProdUpdate={isProdUpdate}
                getViewDetails={getViewDetails}
                {...otherProps}
              />
            )}
          </>
        )}
      </ApplicationFullScreenDialog>
      <AppCreatedDialog
        // open={createdData.show}
        open={false}
        onClose={() =>
          setCreatedData({ show: false, orgId: null, affiliateId: null })
        }
        title="Congratulations!"
        body="Your application has been created and is ready to be executed in the
          sandbox"
        actionText="Use sandbox"
        actionClick={onActionClick}
      />
      {confirmationDialog.open && (
        <ConfirmActionDialog
          onClose={() => setConfirmationDialog({ open: false })}
          onConfirm={onConfirmClose}
          {...confirmationDialog}
        />
      )}
    </Box>
  );
}

export default SaveApplicationFlyout;
