import React from 'react';
import { Component, memo } from 'react';
import { DataGrid } from 'components/widgets';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import DeleteIcon from '@material-ui/icons/Delete';
import { Chip, IconButton, Popover, Typography } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
  solutionType: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    borderRadius: '16px',
    backgroundColor: '#E2F1FC',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '120px',
    height: '24px',
    border: '1px solid #036FCB',
    // padding: '10px',
    fontWeight: '500',
    color: '#036FCB',
    fontSize: '11px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
  onDeleteClick,
  rowData,
  tabIndex,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  const onDelteClickHandler = event => {
    event.preventDefault();
    onDeleteClick(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          // justifyContent: 'space-evenly',
          float: 'right',
          marginTop: '12px',
        }}
      >
        {/* <IconButton
          style={{
            height: '25px',
            width: '25px',
            background: '#E4E4E4',
            marginRight: '20px',
          }}
          onClick={onDelteClickHandler}>
          <DeleteIcon color="action" style={{ fontSize: '18px' }} />
        </IconButton> */}
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
        >
          View details
        </PrimaryButton>
      </div>
    </>
  );
});

const RequestSolution = memo(function RequestSolution({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const EllipsisText = props => {
    const { children } = props;

    return (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '120px',
          textTransform: 'uppercase',
        }}
      >
        {children}
      </div>
    );
  };
  const classes = useStyles();

  return (
    <>
      {/* <div className={classes.solutionType}>
        {solutionName?.join(',') || '-'}
      </div> */}
      <Chip
        className={classes.solutionType}
        label={
          <EllipsisText>
            {data.solutionsNameList?.filter(item => item !== '-')?.join(', ') ||
              '-'}
          </EllipsisText>
        }
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      {data.solutionsNameList?.length > 1 && (
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography style={{ textTransform: 'uppercase' }}>
            {data.solutionsNameList?.filter(item => item !== '-')?.join(', ')}
          </Typography>
        </Popover>
      )}
    </>
  );
});

const RequestedBy = memo(function RequestedBy({ data }) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ marginTop: '10px', marginRight: '5px' }}></div>
        <div style={{ marginBottom: '5px' }}>{data.createdBy || '-'}</div>
      </div>
    </>
  );
});

// {
//   orgId: 580,
//   orgName: 'new pht',
//   solutionsResponseList: null,
//   requestedBy: 'admin admin',
//   requestedOn: '2023-06-28T07:34:20.706+00:00',
//   einNumber: '232377344',
// },

const PENDING_STATUS_HEADERS = [
  {
    headerName: 'Organization name',
    field: 'orgName',
    colId: 'NameID',
  },
  {
    headerName: 'Solutions',
    field: 'solutionsResponseList',
    cellRenderer: 'RequestSolution',
  },
  { headerName: 'Requested by', field: 'requestedBy' },
  {
    headerName: 'Requested on',
    field: 'requestedOn',
    cellRenderer: 'DateFormatter',
  },
  {
    headerName: 'Updated on',
    field: 'updatedOn',
    cellRenderer: 'DateFormatter',
  },
  { headerName: 'EIN', field: 'einNumber' },
  {
    field: '',
    cellRenderer: 'DetailsRenderer',
  },
];

const APPROVED_STATUS_HEADERS = [
  {
    headerName: 'Organization name',
    field: 'orgName',
    colId: 'NameID',
  },
  {
    headerName: 'Solutions',
    field: 'solutionsResponseList',
    cellRenderer: 'RequestSolution',
  },
  { headerName: 'Requested by', field: 'requestedBy' },
  {
    headerName: 'Requested on',
    field: 'requestedOn',
    cellRenderer: 'DateFormatter',
  },
  {
    headerName: 'Approved on',
    field: 'updatedOn',
    cellRenderer: 'DateFormatter',
  },
  { headerName: 'EIN', field: 'einNumber' },
  {
    field: '',
    cellRenderer: 'DetailsRenderer',
  },
];

const DENIED_STATUS_HEADERS = [
  {
    headerName: 'Organization name',
    field: 'orgName',
    colId: 'NameID',
  },
  {
    headerName: 'Solutions',
    field: 'solutionsResponseList',
    cellRenderer: 'RequestSolution',
  },
  { headerName: 'Requested by', field: 'requestedBy' },
  {
    headerName: 'Requested on',
    field: 'requestedOn',
    cellRenderer: 'DateFormatter',
  },
  {
    headerName: 'Declined on',
    field: 'updatedOn',
    cellRenderer: 'DateFormatter',
  },
  { headerName: 'EIN', field: 'einNumber' },
  {
    field: '',
    cellRenderer: 'DetailsRenderer',
  },
];

class RequestGrid extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    columnDefs:
      this.props.tabIndex === 0
        ? PENDING_STATUS_HEADERS
        : this.props.tabIndex === 1
        ? APPROVED_STATUS_HEADERS
        : DENIED_STATUS_HEADERS,

    defaultColDef: {
      flex: 1,
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return valueA;
        }
        return valueA?.toLowerCase()?.localeCompare(valueB?.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? '-'
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDeleteClick={item => this.onItemDelete(item)}
        />
      ),
      RequestSolution: rowData => (
        <RequestSolution {...rowData} onClick={item => this.onItemView(item)} />
      ),
      RequestedBy: rowData => (
        <RequestedBy {...rowData} onClick={item => this.onItemView(item)} />
      ),
      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tabIndex !== this.props.tabIndex) {
      this.setState({
        columnDefs:
          this.props.tabIndex === 0
            ? PENDING_STATUS_HEADERS
            : this.props.tabIndex === 1
            ? APPROVED_STATUS_HEADERS
            : DENIED_STATUS_HEADERS,
      });
    }
  }

  onItemView(data) {
    this.props.onView(data);
  }

  onItemDelete(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            hideHeaderActions
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default RequestGrid;
