import React, { useEffect, useState } from 'react';
import {
  FormSelectField,
  PrimaryButton,
  SecondaryButton,
} from 'components/widgets';
import { Grid, FormLabel, Box } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker, KeyboardTimePicker, makeValidate, makeRequired } from 'mui-rff';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';


const useStyles = makeStyles(theme => ({
  formSelect: {
    marginLeft: -8,
  },

  frequencyGrid: {
    marginRight: 8,
    height: 90,
  },
  dateGrid: {
    height: 90,
    marginRight: 8,
  },
  timeGrid: {
    marginLeft: 8,
    marginRight: 8,
    height: 90,
  },
  fileGrid: {
    marginLeft: 8,
  },
  label: {
    marginBottom: 8,
  },
  ingestionGrid: {
    marginLeft: 0,
    height: 80,
  },
  sequenceTitle: {
    marginBottom: 10,
  },
  addResourceButton: {
    marginTop: 5,
  },
  box: {
    marginTop: 100,
  },
  gridContainer2: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
  },
}));

const resourceTypes = [
  { code: '1', name: 'Coverage' },
  { code: '2', name: 'Insurance Plan' },
  { code: '3', name: 'Organization' },
  { code: '4', name: 'Practitioner' },
  { code: '5', name: 'Patient' },
  { code: '6', name: 'Device' },
  { code: '7', name: 'Encounter' },
  { code: '8', name: 'Allergy Intolerance' },
  { code: '9', name: 'Care Plan' },
  { code: '10', name: 'Care Team' },
  { code: '11', name: 'Condition' },
  { code: '12', name: 'Goal' },
  { code: '13', name: 'Procedure' },
  { code: '14', name: 'Observation' },
  { code: '15', name: 'Diagnostic Report' },
  { code: '16', name: 'Claim' },
  { code: '17', name: 'Explanation Of Benefit' },
  { code: '18', name: 'Medication' },
  { code: '19', name: 'Medication Request' },
  { code: '20', name: 'Medication Knowledge' },
  { code: '21', name: 'Immunization' },
  { code: '22', name: 'Consent' },
  { code: '23', name: 'Document Reference' },
];

const frequencyTypes = [
  { code: '1', name: 'Hourly' },
  { code: '2', name: 'Daily' },
  { code: '3', name: 'Weekly' },
  { code: '4', name: 'Monthly' },
  { code: '5', name: 'Yearly' },
  { code: '6', name: 'None' },
];


const ManualScheduleType = ({
  onToggle,
  onSubmit,
  dataLoadType,
  orgId,
  lobId,
  affId,
  prePopulateData,
}) => {

  const [sequence, setSequence] = useState(1);
  let flag;

  const schema = Yup.object().shape({
  batchStartDate: Yup.date().required('This field is required'),
  batchScheduleTime: Yup.date().required('This field is required'),
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);


  const emptyOptions = {
    resource: '',
    resourceSequence: sequence,
    scheduleType: dataLoadType,
    orgId: orgId,
    lobId: lobId,
    affId: affId,
    lastRun: '',
    status: '',
  };

  const classes = useStyles();

  useEffect(() => {
    if (prePopulateData) {
      setSequence(prePopulateData.resources.length + 1);
    }
  }, [prePopulateData]);

  return (
    <Form
      className={classes.form}
      onSubmit={onSubmit}
      initialValues={{ ...prePopulateData }}
      mutators={{ ...arrayMutators }}
      validate={validate}
      render={({
        handleSubmit,
        pristine,
        form: {
          mutators: { push, remove},
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={3} className={classes.frequencyGrid}>
              <FormLabel component="legend">Frequency</FormLabel>
              <div className={classes.formSelect}>
                <FormSelectField
                  name="frequency"
                  options={frequencyTypes}
                  required
                />
              </div>
            </Grid>
            <Grid item xs={3} className={classes.dateGrid}>
              <div className={classes.label}>
                <FormLabel component="legend">Batch Start Date</FormLabel>
              </div>
              <KeyboardDatePicker
                name="batchStartDate"
                inputVariant="outlined"
                variant="inline"
                disablePast
                size="small"
                format="MM/dd/yyyy"
                dateFunsUtils={DateFnsUtils}
                required={required.batchStartDate}
              />
            </Grid>
            <Grid item xs={3} className={classes.timeGrid}>
              <div className={classes.label}>
                <FormLabel component="legend"> Batch Schedule Time</FormLabel>
              </div>
              <KeyboardTimePicker
                name="batchScheduleTime"
                ampm={false}
                inputVariant="outlined"
                variant="inline"
                size="small"
                dateFunsUtils={DateFnsUtils}
                required={required.batchScheduleTime}
              />
            </Grid>
            <Grid item xs={2} className={classes.fileGrid}>
              <div className={classes.label}>
                <FormLabel component="legend">File type</FormLabel>
              </div>
              <div className={classes.formSelect}>
                <FormSelectField
                  name="fileType"
                  options={[{ code: '1', name: 'CSV' }]}
                  required
                />
              </div>
            </Grid>
          </Grid>

          <FormLabel className={classes.sequenceTitle} component="legend">
            Sequence of Ingestion
          </FormLabel>

          <FieldArray name="resources" marginTop={10}>
            {({ fields }) => (
              <>
                {fields.length > 0 ? flag=true : flag=false}
                {fields.length > 0 && (
                  <>
                    {fields.map((resources, index) => (
                      <Grid
                        container
                        key={index}
                        className={classes.gridContainer2}>
                        <Grid item xs={1} style={{ flexBasis: 0, height: 40 }}>
                          <FormLabel>{`${index + 1}`}</FormLabel>
                        </Grid>
                        <Grid item xs={4} className={classes.ingestionGrid}>
                          <FormSelectField
                            name={`${resources}.resource`}
                            options={resourceTypes}
                            required
                          />
                        </Grid>
                        <Grid item xs={1} style={{height: 50}}>
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => fields.remove(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}

                <SecondaryButton
                  className={classes.addResourceButton}
                  size="small"
                  onClick={() => {
                    push('resources', emptyOptions);
                    setSequence(prev => prev + 1);
                  }}>
                  Add Resource
                </SecondaryButton>
              </>
            )}
          </FieldArray>

          <Box
            display="flex"
            justifyContent="flex-start"
            className={classes.box}>
            <SecondaryButton onClick={onToggle} style={{ marginRight: 10 }}>
              Cancel
            </SecondaryButton>
            {!pristine && 
              <PrimaryButton
              type="submit"
              onClick={handleSubmit}
              disabled={!flag}>
                Add
              </PrimaryButton>
            }
          </Box>
        </form>
      )}
    />
  );
};

export default ManualScheduleType;
