import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    // margin: 0,
    padding: '20px',
    backgroundColor: '#f5f7f8',
    height: 'fit-content',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    paddingTop: '0px',
  },
  closeButton: {
    color: '#000',
  },
  paper: {
    backgroundColor: '#E6E6E6',
  },
  footer: {
    textAlign: 'center',
    height: '10%',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '0px 10px',
    // marginTop: 'auto',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#667085',
    paddingTop:'5px'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = props => {
  const { children, onClose, TitleIcon } = props;
  const classes = useStyles();
  return (
    <>
      <MuiDialogTitle
        disableTypography
        className={clsx(classes.root, classes.dialogTitle)}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            backgroundColor: TitleIcon ? '#fff' : "transparent",
            width: '100%',
            padding: '16px',
            ...props?.styles?.container,
          }}
        >
          {TitleIcon &&
            (TitleIcon === 'url' ? (
              <img
                src={
                  'https://mpowered-assets.s3.us-east-2.amazonaws.com/003-medical-records.svg'
                }
                alt="action"
                style={{ ...props?.styles?.img }}
              />
            ) : (
              <Box
                style={{
                  margin: '0px 10px',
                  width: '40px',
                }}
              >
                <TitleIcon style={{ width: '40px', height: '40px' }} />
              </Box>
            ))}
          <Typography
            variant="h2"
            style={{
              fontSize: '28px',
              fontWeight: 600,
              marginTop: '-5px',
              color: '#344054',
            }}
          >
            {children}
          </Typography>
        </Box>
        {TitleIcon !== 'url' && onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            size="small"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </>
  );
};

const DialogContent = ({ children, solutionBox = false }) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, classes.content)}
      style={{
        paddingLeft: solutionBox && '0px',
        paddingRight: solutionBox && '0px',
      }}
    >
      {children}
    </Box>
  );
};

const DialogFooter = ({ children, styles }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, classes.footer)} style={{ ...styles }}>
      {children}
    </Box>
  );
};

const ApplicationFullScreenDialog = ({
  open = true,
  onToggle,
  title,
  children,
  desc,
  TitleIcon,
  styles,
  subTitle,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullScreen
        className={classes.paper}
        open={open}
        onClose={onToggle}
        TransitionComponent={Transition}
      >
        {TitleIcon === 'url' && onToggle ? (
          <IconButton
            aria-label="close"
            size="small"
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px',
            }}
            onClick={onToggle}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogTitle
          onClose={() => onToggle()}
          desc={desc}
          TitleIcon={TitleIcon}
          styles={styles}
        >
          {title}
          {subTitle && (
            <Typography className={classes.subTitle}>{subTitle}</Typography>
          )}
        </DialogTitle>
        {children}
      </Dialog>
    </div>
  );
};

export default Object.assign(ApplicationFullScreenDialog, {
  Content: DialogContent,
  Footer: DialogFooter,
});
