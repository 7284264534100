import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 40,
    textTransform: 'none',
    backgroundColor: '#fff',
    // borderColor: '#A0A4A6',
    borderColor: '#E4E7EC',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export default function SecondaryButton({
  children,
  className,
  hasPermissions,
  ...otherProps
}) {
  const classes = useStyles();
  if (typeof hasPermissions === 'boolean' && !hasPermissions) return null;
  return (
    <Button
      variant="outlined"
      color="secondary"
      className={clsx(classes.root, className)}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
