import { Typography, Paper, Box } from '@material-ui/core';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import { AlertDialog, SecondaryButton } from 'components/widgets';
import AvatarImg from './AvatarImg';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },
}));

export const AttributeNameInfo = ({ attriDetails, handleEdit }) => {
  const classes = useStyles();
  let formattedDate = moment(attriDetails?.createdAt).format('MMM DD, YYYY');

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  
  return (
    <DrawerBoxComponent>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          borderRadius: '8px',
          margin: 16,
          marginRight: '40px',
        }}>
        <Box className={classes.container}>
          <Box style={{ display: 'flex' }}>
            <Box style={{ marginRight: '120px' }}>
              <Typography style={{ marginBottom: '7px', color: '#667085' }}>
                Created by
              </Typography>{' '}
              <Box style={{ display: 'flex' }}>
                <AvatarImg userName={`${attriDetails.createdBy || ''} `} />
                <Typography style={{ fontWeight: 500 }}>
                  {attriDetails.createdBy || '-'}
                </Typography>
              </Box>
            </Box>
            <Box style={{ marginRight: '120px' }}>
              <Typography style={{ marginBottom: '7px', color: '#667085' }}>
                Created on
              </Typography>
              <Typography style={{ fontWeight: 500 }}>
                {formattedDate || '-'}
              </Typography>
            </Box>
            <Box>
              <Typography style={{ marginBottom: '7px', color: '#667085' }}>
                Description
              </Typography>
              <Typography style={{ fontWeight: 500 }}>
                {attriDetails?.description || '-'}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.editButton}>
            <SecondaryButton
              data-testid="edit-btn"
              onClick={()=>setOpenAlertDialog(true)}
              style={{ padding: '2px', height: '30px' }}>
              Edit
            </SecondaryButton>
          </Box>
        </Box>
      </Paper>
      {true && (
        <AlertDialog
          open={openAlertDialog}
          description={
            'This attribute is mapped to multiple organization. Since this rule was published in production and may have an impact on the users. Are you sure you want to edit the attribute?'
          }
          title="Edit Attribute"
          onClose={() => setOpenAlertDialog(false)}
          onConfirm={() => {
            setOpenAlertDialog(false);
            handleEdit();
          }}
          closeBtnTxt="No"
          confirmBtnTxt="Yes"
          customTitleStyle={{ fontSize: 24, fontWeight: 600, padding: 20 }}
        />
      )}
    </DrawerBoxComponent>
  );
};
