import {
  Breadcrumbs,
  LinearProgress,
  makeStyles,
  Link,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataPartnersApi from 'services/api/DataPartnersApi';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import { PRICELISTDETAILS_PAGE, PRICELIST_FILE_NAME } from 'shared/constants';
import OrgAffiliateName from './OrgAffiliateName';
import FilesTable from './FilesTable';
import { SimpleBackdrop } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contentBox: {
    margin: 16,
  },
  filesTable: {
    margin: 16,
  },
}));

const emptyLOB = {
  name: '',
};

const emptyAffiliate = {
  name: '',
};

const PriceListDetails = ({ history }) => {
  const classes = useStyles();
  const [initLoading, setInitLoading] = useState(true);
  const [lob, setLob] = useState(emptyLOB);
  const [affiliate, setAffiliate] = useState(emptyAffiliate);
  const [priceList, setPriceList] = useState([]);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const {
    details: { name: orgName },
  } = useSelector(state => state.selectedOrganization);
  const { lobId } = useParams();
  const affiliateId = sessionStorage.getItem('affiliateId');
  const orgId = sessionStorage.getItem('orgId');
  const { enqueueSnackbar } = useSnackbar();

  const PriceListSummaryLink = `/organization/${orgId}/affiliate/${affiliateId}/price-list-summary`;

  const getFileDisplayName = fileName => {
    for (let i = 0; i < PRICELIST_FILE_NAME.length; i++) {
      if (PRICELIST_FILE_NAME[i].value === fileName)
        return PRICELIST_FILE_NAME[i].label;
    }
  };

  const formatPriceListData = useCallback(data => {
    return data.map(file => ({
      ...file,
      fileDisplayName: getFileDisplayName(file.lobDocModel.fileName),
      fileDisplayType: file.lobDocModel.fileType,
    }));
  }, []);

  const fetchPlanAndPrice = useCallback(async () => {
    try {
      const [lobData, affiliateData] = await Promise.all([
        PartnerLOBApi.getById(orgId, lobId),
        DataPartnersApi.getPartnerById(orgId, affiliateId),
      ]);
      setLob(lobData);
      setAffiliate(affiliateData);
      const priceListData = await PartnerLOBApi.getPriceListByLOB(
        orgId,
        affiliateId,
        lobId
      );
      const formattedPriceListData = formatPriceListData(priceListData);
      setPriceList(formattedPriceListData);
      setInitLoading(false);
    } catch (e) {
      enqueueSnackbar('Unable to fetch price list', {
        variant: 'error',
      });
      setInitLoading(false);
    }
  }, [enqueueSnackbar, orgId, lobId, affiliateId, formatPriceListData]);

  useEffect(() => {
    if (orgId) {
      fetchPlanAndPrice();
    }
  }, [orgId, affiliateId, lobId, fetchPlanAndPrice]);

  const onBreadcrumbClick = (event, link) => {
    event.preventDefault();
    history.push(link);
  };

  const onDownloadFile = async file => {
    try {
      setIsDownloadingFile(true);
      const { lobDocModel } = file;
      const response = await PartnerLOBApi.downloadFile(lobDocModel.documentId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${lobDocModel.fileName}.${lobDocModel.fileType}`
      );
      document.body.appendChild(link);
      link.click();
      setIsDownloadingFile(false);
    } catch {
      enqueueSnackbar('Unable to Download File', {
        variant: 'error',
      });
      setIsDownloadingFile(false);
    }
  };

  if (initLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: 35 }}>
        <Link
          color="primary"
          href={PriceListSummaryLink}
          onClick={event => onBreadcrumbClick(event, PriceListSummaryLink)}
        >
          {PRICELISTDETAILS_PAGE.BREADCRUMBLINK}
        </Link>
        <Typography color="textPrimary">{lob.name}</Typography>
      </Breadcrumbs>
      <Typography
        // variant="h2"
        style={{
          margin: 35,
          fontWeight: 'bold',
          fontSize: 28,
        }}
      >
        {PRICELISTDETAILS_PAGE.TITLE}
      </Typography>
      <Box className={classes.contentBox}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OrgAffiliateName
              orgName={orgName}
              affiliateName={affiliate.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.filesTable}>
              <FilesTable
                rowData={priceList}
                onDownloadFile={item => onDownloadFile(item)}
              />
            </Box>
          </Grid>
        </Grid>
        <SimpleBackdrop open={isDownloadingFile} />
      </Box>
    </>
  );
};

export default PriceListDetails;
