import React from 'react';
import { Field } from 'react-final-form';
import { required, email, composeValidators } from 'shared/validators';
import { TextField } from 'components/widgets';

export default function FormEmailField({
  name,
  label,
  value,
  placeholder,
  dataTestId,
  customClass,
  customInputFieldStyles,
  disabled
}) {
  return (
    <Field name={name} validate={composeValidators(required, email)}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          type="email"
          required
          label={label}
          placeholder={placeholder}
          value={value}
          dataTestId={dataTestId || ''}
          {...input}
          touched={touched}
          error={error}
          customClass={customClass}
          customInputFieldStyles={customInputFieldStyles}
          disabled={disabled}
          ></TextField>
      )}
    </Field>
  );
}
