import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserIcon from '../../components/icons/UserIcon';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '4px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  paperConatiner: {
    padding: theme.spacing(0.5),

    textAlign: 'center',
    backgroundColor: '#E4E7EC',
  },
  root1: { backgroudColor: 'pink', flexGrow: 1 },
}));

export const PaperContainer = ({
  title,
  value,
  showUserIcon,
  showMembersTitle,
  showUserIcon1,
}) => {
  const classes = useStyles();
  return (
    <Paper
      data-testid="papercomponent"
      className={classes.paperConatiner}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '8px',
      }}>
      {showUserIcon && <UserIcon />}
      {title && (
        <>
          <Typography
            style={{ marginLeft: '4px', marginRight: '4px' }}
            data-testid="title">
            <b>{title}</b>
          </Typography>{' '}
        </>
      )}
      {showUserIcon1 && <UserIcon />}
      <Typography
        data-testid="members"
        style={{ marginLeft: '4px', marginRight: '4px' }}>
        {value}
      </Typography>
      {showMembersTitle && (
        <Typography style={{ marginRight: '4px' }}> members</Typography>
      )}
    </Paper>
  );
};
