import React from 'react';
import { Box, Typography } from '@material-ui/core';
import UserCircle from '../../../assets/defaultAvatarBlack.svg';

const ProfileSection = ({ item, classes }) => {
  return (
    <Box className={classes.memberCardWrapper}>
      <Box className={classes.memberPictureWrapper}>
        {!item.imgUrl && item.member_name ? (
          <Box style={{ backgroundColor: item.bgColor }} className={classes.initialWrapper}>
            <Typography className={classes.initialName}>
              {item && item.member_name[0].toUpperCase()}
            </Typography>
          </Box>
        ) : (
          <img
            src={item.imgUrl}
            alt="member-pic"
            className={classes.memberPicture}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = UserCircle;
            }}
          />
        )}
      </Box>
      <Box className={classes.memberNameWrapper}>
        <Typography className={classes.memberName}>{item.member_name || '-'}</Typography>
        <Typography className={classes.specialty}>
          {item.specialty}
          <span className={classes.dot}></span>
          <span className={classes.partnerName}>{item.partnerName}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileSection;
