import { useSelector } from 'react-redux';

export const hasDevPermission = (
  permissions,
  orgId,
  affiliateId,
  resourceName
) => {
  if (permissions.developer[`${orgId}_${affiliateId}`]) {
    const isPresent = permissions.developer[`${orgId}_${affiliateId}`][
      resourceName
    ]
      ? true
      : false;

    return isPresent;
  } else {
    return false;
  }
};

export function useUserPermissions() {
  const { resourcePermissions: permissions } = useSelector(
    state => state.userInfo
  );

  const checkAdminPermission = (orgId, resourceName) => {
    if (permissions.admin[orgId]) {
      const isPresent = permissions.admin[orgId][resourceName] ? true : false;

      return isPresent;
    } else {
      return false;
    }
  };

  const checkDevPermission = (orgId, affiliateId, resourceName) => {
    return hasDevPermission(permissions, orgId, affiliateId, resourceName);
  };

  const checkSuperAdminPermission = () => {
    return permissions.isSuperAdmin;
  };

  const checkITAdminPermission = () => {
    return permissions.isITAdmin;
  };

  const checkITAdminPermissionStatus = () => {
    return permissions.ITAdminPermissionState;
  };

  return {
    checkAdminPermission,
    checkDevPermission,
    hasDevPermission,
    checkSuperAdminPermission,
    checkITAdminPermission,
    checkITAdminPermissionStatus,
  };
}
