import React from 'react';
import { Field } from 'react-final-form';
import { required as requiredField, composeValidators } from 'shared/validators';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';

const queryLimits = {
  '5k': 5000,
  '10k': 10000,
  '15k': 15000,
  '20k': 20000,
  '25k': 25000,
  '50k': 50000,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    // backgroundColor: 'white',
    borderRadius: '4px',
  },
  root: {
    margin: theme.spacing(0.5),
    display: 'flex',
    flexWrap: 'wrap',
  },
  control: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1.5),
    border: '1px solid #828282',
    borderRadius: theme.spacing(1),
    minWidth: 50,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selected: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export default function FormQueryLimitField({
  name,
  label,
  placeholderText,
  required,
  options = null,
  disabled,
  onValueChange
}) {
  const classes = useStyles();
  return (
    <Field
      name={name}
      validate={required ? composeValidators(requiredField) : false}
      component={'select'}
    >
      {({ input: { value, onChange }, meta: { error, touched } }) => (
        <FormControl
          error={error && touched}
          required
          variant="outlined"
          style={{ width: 'calc(100% - 12px)' }}
          className={classes.formControl}
        >
          <Select
            value={value || placeholderText}
            onChange={ev => {
              if (onValueChange) {
                onValueChange(ev);
              }

              onChange(ev);
            }}
            style={{
              backgroundColor: 'white',
              color: disabled ? 'grey' : value === '' ? '#667085' : 'initial',
            }}
            data-testid="query-limit-dropdown"
            defaultValue=""
            margin="dense"
            IconComponent={ExpandMoreIcon}
            disabled={disabled}
          >
            {placeholderText && (
              <MenuItem value={placeholderText} disabled>
                {placeholderText}
              </MenuItem>
            )}
            {options
              ? options.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              : Object.keys(queryLimits).map((key, index) => (
                  <MenuItem key={index} value={queryLimits[key]}>
                    {key}
                  </MenuItem>
                ))}
          </Select>
          {error && touched && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}
