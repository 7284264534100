import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme =>
  createStyles({
    dropDownWrapper: {
      padding: '6px 10px 0px 10px',
      height: 40,
      width: 210,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#4B4B4B',
      lineHeight: '16px',
      borderRadius: 8,
      backgroundColor: '#f4f4f4',
      boxShadow: '0px 1px 2px rgba(0,0,1,0.4)',
    },
    listTag: {
      width: 200,
      height: 40,
      paddingLeft: 10,
      paddingRight: 10,
    },
    selectRoot: {
      height: 40,
      boxSixing: 'border-box',
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: 24,
      fontFamily: 'Inter',
      paddingRight: 23,
      fontWeight: 600,
    },

    dropDownMenuList: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);
const FilterDropDown = props => {
  const classes = styles();
  const { rootContainer, changeHandler } = props;
  const [value, setValue] = useState(props.value);

  const changehandler = event => {
    setValue(event.target.value);
    changeHandler(event);
  };

  return (
    <FormControl
      className={
        (rootContainer && rootContainer.dropDownWrapper) ||
        classes.dropDownWrapper
      }>
      {props.inputLabel && (
        <InputLabel id="inputLabel">{props.inputLabel}</InputLabel>
      )}
      <Select
        MenuProps={{
          style: { select: classes.selectRoot, list: classes.dropDownMenuList },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          MenuListProps: {
            disablePadding: true,
          },
          getContentAnchorEl: null,
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue="10"
        disableUnderline
        // disableGutters={true}
        value={value}
        onChange={changehandler}
        {...props}>
        {props.options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              //   disableGutters={true}
              value={option.value}
              className={classes.listTag}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxHeight: 40,
                  paddingLeft: 12,
                }}>
                {option.icon && (
                  <span>
                    <img
                      src={option.icon}
                      alt="icon"
                      style={{ width: 8, height: 8, marginRight: 8 }}
                    />
                  </span>
                )}
                <p style={{ padding: 0, paddingLeft: 5 }}>
                  {option.label || option.value}
                </p>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default FilterDropDown;
