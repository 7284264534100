import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, MenuItem, Select } from '@material-ui/core';
import { DialogTitle, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import RecordsApi from 'containers/DynamicRenderOfRecords/RecordsApi';
import { PrimaryButton } from 'components/widgets';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: '500px',
        borderRadius: '16px',
        maxHeight: '600px',
    },
    selectAssessment: {
        marginBottom: '20px',
        width: '100%',
        fontSize: '16px', 
        fontWeight: 500, 
        fontFamily: 'Inter',
    },
    expiryDateField: {
        marginBottom: '20px',
    },
    sendButton: {
        color: '#fff',
        border: '1px solid #036FCB',
        backgroundColor: '#036FCB',
        padding: '8px 24px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 600,
        width: '97px',
        '&:disabled': {
            backgroundColor: '#8BC5F5',
            borderColor: '#8BC5F5',
        },
    },
}));

const SendAssessmentPopup = ({ orgId, open, onClose, onSend }) => {
    const classes = useStyles();
    const [assessments, setAssessments] = useState([]);
    const [selectedAssessment, setSelectedAssessment] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isDateValid, setIsDateValid] = useState(true);

    useEffect(() => {
        const fetchAssessments = async () => {
            let payload = {
                offset: 0,
                status: ["ACTIVE"],
                limit: 10,
                searchText: "",
            };

            try {
                const response = await RecordsApi.getAssessment({ orgId, payload });
                setAssessments(response.data || []);
            } catch (error) {
                console.error('Error fetching assessments:', error);
            }
        };

        if (open) {
            fetchAssessments();
        }
    }, [orgId, open]);

    const resetFields = () => {
        setSelectedAssessment('');
        setExpiryDate('');
        setFormSubmitted(false);
        setIsDateValid(true);
    };

    const handleSend = () => {
        const assessment = assessments.find(a => a.name === selectedAssessment);
        if (onSend && assessment) {
            onSend(assessment, expiryDate);
        }
        resetFields();
        onClose();
    };

    const handleCancel = () => {
        resetFields();
        onClose();
    };

    const handleAssessmentChange = (event) => {
        setSelectedAssessment(event.target.value);
        setExpiryDate('');
    };

    const handleExpiryDateChange = (event) => {
        const selectedDate = event.target.value;
        const today = new Date().toISOString().split('T')[0];
        setIsDateValid(selectedDate >= today);
        setExpiryDate(selectedDate);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleCancel} PaperProps={{ style: { borderRadius: 16 } }}>
                <DialogTitle style={{ fontSize: '30px', fontWeight: 600, fontFamily: 'Inter' }}>
                    Select an Assessment
                    <IconButton
                        aria-label="close"
                        onClick={handleCancel}
                        style={{ position: 'absolute', right: 8, top: 8, color: '#000' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Select
                        value={selectedAssessment}
                        onChange={handleAssessmentChange}
                        displayEmpty
                        className={classes.selectAssessment}
                        variant="outlined"
                    >
                        <MenuItem value="" disabled>Select an assessment</MenuItem>
                        {assessments.map((assessment) => (
                            <MenuItem key={assessment.name} value={assessment.name} style={{fontSize: '16px', fontWeight: 500, fontFamily: 'Inter',}}>
                                {assessment.name.charAt(0).toUpperCase() + assessment.name.slice(1)}
                            </MenuItem>
                        ))}
                    </Select>

                    {selectedAssessment && (
                        <>
                            <h6>Select Expiry date<span style={{color: 'red'}}> *</span></h6>
                            <TextField
                                type="date"
                                value={expiryDate}
                                onChange={handleExpiryDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                variant="outlined"
                                className={classes.expiryDateField}
                                InputProps={{
                                    style: {
                                        fontSize: '1rem',
                                        height: '60px',
                                        borderRadius: '8px',
                                        backgroundColor: '#fff',
                                    },
                                }}
                                required
                                error={!isDateValid || (!expiryDate && formSubmitted)}
                                helperText={!isDateValid ? "Expiry date cannot be in the past" : ""}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions style={{ marginTop: '16px', gap: '16px', justifyContent: 'center', marginBottom: '16px' }}>
                    <PrimaryButton
                        onClick={handleCancel}
                        style={{
                            color: '#ff5e5e',
                            border: '1px solid #ff5e5e',
                            backgroundColor: '#fff',
                            padding: '8px 24px',
                            borderRadius: '8px',
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        Cancel
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={handleSend}
                        className={classes.sendButton}
                        disabled={!expiryDate || !isDateValid}
                    >
                        Send
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SendAssessmentPopup;
