import SessionService from './SessionService';
import { STORAGE_KEYS } from '../shared/constants';

export const RESOURCE_PERMISSION_TYPES = {
  CREATE: 'create',
  DELETE: 'delete',
  VIEW: 'view',
  UPDATE: 'update',
  EXECUTE: 'execute',
};

export const canView = resource => {
  return can([RESOURCE_PERMISSION_TYPES.VIEW], resource);
};

export const canViewEither = resources => {
  let canView = false;

  if (resources && resources.length > 0) {
    for (let i = 0; i < resources.length; i++) {
      canView = canView || can([RESOURCE_PERMISSION_TYPES.VIEW], resources[i]);
      if (canView) {
        break;
      }
    }
  }

  return canView;
};

export const canCreate = resource => {
  return can([RESOURCE_PERMISSION_TYPES.CREATE], resource);
};

export const canDelete = resource => {
  return can([RESOURCE_PERMISSION_TYPES.DELETE], resource);
};

export const canUpdate = resource => {
  return can([RESOURCE_PERMISSION_TYPES.UPDATE], resource);
};

export const canExecute = resource => {
  return can([RESOURCE_PERMISSION_TYPES.EXECUTE], resource);
};

export const can = (requestedPermissions, resource) => {
  const resourcePersmissionsStr = SessionService.getItem(
    STORAGE_KEYS.RESOURCE_PERMISSIONS
  );
  let canBeAllowed = true;

  if (resourcePersmissionsStr !== null) {
    const resourcePermissions = JSON.parse(resourcePersmissionsStr);
    const allowedResourcePermission = resourcePermissions[resource];

    if (resource) {
      if (allowedResourcePermission) {
        for (let i = 0; i < requestedPermissions.length; i++) {
          canBeAllowed = allowedResourcePermission[requestedPermissions[i]];
          if (!canBeAllowed) {
            break;
          }
        }
      } else {
        canBeAllowed = false;
      }
    }
  }

  return canBeAllowed;
};

export const getResourcePermissions = (roles = []) => {
  const resourcePermissions = {};

  roles.forEach(role => {
    role.resourceInfo.forEach(resourceName => {
      if (!resourcePermissions[resourceName]) {
        resourcePermissions[resourceName] = {
          create: 1,
          delete: 1,
          update: 1,
          view: 1,
          execute: 1,
        };
      }
    });
  });

  return resourcePermissions;
};

export const storePermissions = (roles = []) => {
  const resourcePermissions = {};

  roles.forEach(role => {
    role.resourceInfo.forEach(resourceName => {
      if (!resourcePermissions[resourceName]) {
        resourcePermissions[resourceName] = {
          create: 1,
          delete: 1,
          update: 1,
          view: 1,
          execute: 1,
        };
      }
    });
  });

  SessionService.setItem(
    STORAGE_KEYS.RESOURCE_PERMISSIONS,
    JSON.stringify(resourcePermissions)
  );

  return resourcePermissions;
};

const AbilityService = {
  can,
  canView,
  canViewEither,
  canCreate,
  canUpdate,
  canDelete,
  canExecute,
  storePermissions,
  getResourcePermissions,
};

export default AbilityService;
