import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MphLogo from '../../../assets/mphLogo.png';
import { Box, Checkbox, Chip, Grid } from '@material-ui/core';
import { SecondaryButton } from 'components/widgets';
import CustomizedDialog from 'components/CustomizedDialog';
import { PERMISSION_CODES } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    textTransform: 'lowercase',
  },
  warningChip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#FFEAEF',
    borderColor: '#DB0032',
    color: '#DB0032',
    textTransform: 'uppercase',
    height: '16px',
    padding: '5px',
  },
  secondaryBtn: {
    padding: 0,
    marginLeft: 'auto',
  },
  solutionsHeading: {
    color: '#344054',
    fontWeight: 500,
    fontSize: '12px',
  },
  domainPopup: {
    minHeight: '250px',
    minWidth: '732px',
  },
  accordionRoot: {
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    borderRadius: '4px',
  },
}));

const SolutionPermissionAccordion = ({
  children,
  expanded,
  handleSolutionPermission,
  isView,
  permissionCode,
  permissionLimit,
  permissionMaxLimit,
  selectedPermissions,
  setExpanded,
  solutionId,
  title,
}) => {
  const classes = useStyles();
  const [clearWarningPopupOpen, setClearWarningPopupOpen] = useState(false);
  const permissionCount =
    Object.values(permissionLimit || {})?.reduce(
      (cur, acc) => (cur += acc),
      0
    ) || 0;

  const btnTitle =
    permissionCount !== 0 ? (expanded ? 'Clear' : 'Edit') : 'Add';

  const handleClearPopup = () => {
    setClearWarningPopupOpen(prev => !prev);
  };

  const handleClearPermissions = () => {
    handleSolutionPermission('', 'clear', solutionId);
    handleClearPopup();
    setExpanded();
  };

  return (
    <React.Fragment key={solutionId}>
      <Accordion
        style={{
          backgroundColor:
            permissionCount === 0 && !expanded ? '#F5F5F5' : 'white',
          marginTop: '10px',
        }}
        expanded={expanded}
        className={classes.accordionRoot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ cursor: 'default' }}
          IconButtonProps={{
            onClick: setExpanded,
          }}
          data-testid="solution-accordion-summary"
        >
          <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
            <img src={MphLogo} alt="mph-logo" height={'20px'} width={'24px'} />
            <Typography
              className={classes.heading}
              variant="h3"
              data-testid="solution-permission-title"
            >
              {title}
            </Typography>
            {!permissionCount && (
              <Chip
                variant="outlined"
                size="medium"
                label={'No Permissions'}
                className={classes.warningChip}
                data-testid="solution-permission-count-chip"
              />
            )}
          </Box>
          {!isView && (
            <SecondaryButton
              className={classes.secondaryBtn}
              onClick={btnTitle === 'Clear' ? handleClearPopup : setExpanded}
              data-testid="solution-permission-secondary-btn"
            >
              {btnTitle}
            </SecondaryButton>
          )}
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={4} style={{ borderBottom: '1px solid #F5F5F5' }}>
                <Typography className={classes.solutionsHeading}>
                  Module
                </Typography>
              </Grid>
              {permissionCode?.map(permission => (
                <Grid
                  item
                  xs={2}
                  key={permission.code}
                  style={{ borderBottom: '1px solid #F5F5F5' }}
                >
                  <Typography className={classes.solutionsHeading}>
                    {permission.displayName}
                  </Typography>
                </Grid>
              ))}
              {!isView && (
                <>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.solutionsHeading}
                      style={{ fontWeight: 400, fontSize: '14px' }}
                    >
                      Select all
                    </Typography>
                  </Grid>
                  {permissionCode?.map(permission => {
                    const isSelected = selectedPermissions?.includes(
                      permission.code
                    );

                    let shouldDisableReadPermission = false;

                    if (permission.code === PERMISSION_CODES.READ) {
                      shouldDisableReadPermission = Object.keys(
                        permissionLimit
                      ).some(
                        permissionKey =>
                          permissionKey !== PERMISSION_CODES.READ &&
                          permissionLimit[permissionKey] ===
                            permissionMaxLimit[permissionKey]
                      );
                    }

                    return (
                      <Grid item xs={2} key={permission.code}>
                        <Checkbox
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                            'data-testid': 'permission-all-checkbox',
                          }}
                          style={{ padding: 0 }}
                          onChange={() =>
                            handleSolutionPermission(
                              permission.code,
                              isSelected ? 'remove' : 'add',
                              solutionId
                            )
                          }
                          checked={isSelected}
                          disabled={shouldDisableReadPermission}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </div>
          {children}
        </AccordionDetails>
      </Accordion>
      <CustomizedDialog
        title={'Clear permissions'}
        open={clearWarningPopupOpen}
        customStyles={classes.domainPopup}
        primaryBtnTitle="Clear"
        handleOnSave={handleClearPermissions}
        handleClickClose={handleClearPopup}
      >
        <Typography>{`Are you sure you would like to clear ${title} permissions assigned to this role?`}</Typography>
      </CustomizedDialog>
    </React.Fragment>
  );
};

export default SolutionPermissionAccordion;
