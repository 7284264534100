import DashboardLayout from './DashboardLayout';
import MainLayout from './MainLayout';
import { useHistory } from 'react-router-dom';
import SolutionDashboardLayout from './SolutionDashboard/SolutionDashboardLayout';

function Layout({
  isAuthenticated,
  children,
  onLogout,
  orgId,
  affiliateId,
  userLayoutType,
  history,
  dashboardDetail,
}) {
  if (isAuthenticated) {
    return (
      <>
          <DashboardLayout
            history={history}
            onLogout={onLogout}
            orgId={orgId}
            affiliateId={affiliateId}
            userLayoutType={userLayoutType}
          >
            {children}
          </DashboardLayout>
      </>
    );
  }

  return (
    <MainLayout isAuthenticated={isAuthenticated} history={history}>
      {children}
    </MainLayout>
  );
}

export default Layout;
