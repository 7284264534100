import { useState, useEffect, useCallback } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
  AlertDialog,
  SearchTextField,
  SelectInput,
} from 'components/widgets';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import DataPartnersApi from 'services/api/DataPartnersApi';
import MasterDataApi from 'services/api/MasterDataApi';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import LobPagination from './LobPagination';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'utils/userPermissions';
import AddPriceList from './AddPriceList';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';
import { useSelector } from 'react-redux';
import './ManageLobPage.scss';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  container: {
    // justifyContent: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  topSec: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    width: '100%',
  },
  item: {
    padding: theme.spacing(1),
  },
  paperItem: {
    // minWidth: 280,
    minHeight: 196,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  newPaperItem: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function ManageLobPage({ history }) {
  const { checkITAdminPermission, checkSuperAdminPermission } =
    useUserPermissions();
  const { info } = useSelector(state => state.userInfo);
  const isSuperAdmin = checkSuperAdminPermission();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [lobs, setLobs] = useState([]);
  const [flyout, setFlyout] = useState({ open: false });
  const [filteredLobs, setFilteredLobs] = useState([]);
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [selectedLOB, setSelectedLOB] = useState(null);
  const [affiliates, setAffiliates] = useState([]);
  const [inNetError, setInNetError] = useState();
  const [inNetFlag, setInNetFlag] = useState(false);
  const [inNetworkFailArray, setInNetworkFailArray] = useState([]);
  const [outNetError, setOutNetError] = useState();
  const [outNetFlag, setOutNetFlag] = useState(false);
  const [outNetworkFailArray, setOutNetworkFailArray] = useState([]);
  const [prescriptionFailArray, setPrescriptionFailArray] = useState([]);
  const [prescriptionError, setPrescriptionError] = useState();
  const [prescriptionFlag, setPrescriptionFlag] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    affiliate: '',
    role: '',
    lob: '',
    name: '',
  });

  const orgId = sessionStorage.getItem('orgId');
  const { enqueueSnackbar } = useSnackbar();
  const numberOfRowsPerPage = 10;

  useEffect(() => {
    if (inNetFlag === true) {
      if (inNetworkFailArray.length > 0) {
        enqueueSnackbar(`${inNetError} - In Network Rate File`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('In Network Rate file uploaded', {
          variant: 'success',
        });
      }
    }
  }, [enqueueSnackbar, inNetworkFailArray, inNetFlag, inNetError]);

  useEffect(() => {
    if (outNetFlag) {
      if (outNetworkFailArray.length > 0) {
        enqueueSnackbar(`${outNetError} - Out Of Network File`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Out of Network File uploaded', { variant: 'success' });
      }
    }
  }, [outNetFlag, outNetworkFailArray, enqueueSnackbar, outNetError]);

  useEffect(() => {
    if (prescriptionFlag) {
      if (prescriptionFailArray.length > 0) {
        enqueueSnackbar(`${prescriptionError} - Prescription Drug File`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Prescription Drug File uploaded', {
          variant: 'success',
        });
      }
    }
  }, [
    prescriptionFlag,
    prescriptionFailArray,
    enqueueSnackbar,
    prescriptionError,
  ]);

  const getAllLobs = useCallback(
    (pTypes = []) => {
      if (orgId) {
        setLoading(true);
        PartnerLOBApi.getAll(orgId)
          .then(results => {
            const data = results.map(item => {
              return {
                ...item,
                planType: pTypes.filter(pt => pt.code === item.planTypeId)[0]
                  ?.name,
                members: Math.ceil(Math.random() * 231),
                affiliateIds: item.affiliates.map(aItem => aItem.id),
              };
            });

            setLobs(data);
            setLoading(false);
          })
          .catch(error => {
            // console.log('error: ', error);
            setLoading(false);
            enqueueSnackbar('Something went wrong fetching all LOBs', {
              variant: 'error',
            });
          });
      }
    },
    [orgId, enqueueSnackbar]
  );

  const getAllPartners = useCallback(
    async (_orgId, type) => {
      try {
        const results = await DataPartnersApi.getAllPartners(_orgId);
        const data = results
          .filter(item => item.isDeleted !== 1)
          .map(fItem => ({ code: fItem.id, name: fItem.name }));
        setAffiliates(data);
        // setLoading(false);
      } catch (error) {
        // console.log('error: ', error);
        // setLoading(false);
        enqueueSnackbar('Something went wrong fetching affiliates', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const getPlanTypes = useCallback(() => {
    MasterDataApi.getPlanTypes()
      .then(results => {
        const data = results.map(item => {
          return {
            code: item.id,
            name: item.name,
          };
        });

        getAllLobs(data);
      })
      .catch(err => {
        // console.log('Error', err);
        enqueueSnackbar('Something went wrong fetching plan types', {
          variant: 'error',
        });
      });
  }, [getAllLobs, enqueueSnackbar]);

  useEffect(() => {
    if (orgId) {
      getPlanTypes();
      getAllPartners(orgId);
    }
  }, [getAllPartners, orgId, getPlanTypes]);

  const onAddLOB = () => {
    isSuperAdmin
      ?  history.push(`/admin/organization/${orgId}/manage-lobs/lob/add`)
      : history.push(`/organization/${orgId}/manage-lobs/lob/add`);
  };

  // const onEditLOB = lob => {
  //   isITAdmin
  //     ? history.push(`/organization/${orgId}/manage-lobs/lob/${lob.id}`)
  //     : history.push(`/admin/organization/${orgId}/manage-lobs/lob/${lob.id}`);
  // };

  const onViewLOB = lob => {
    isSuperAdmin
      ?history.push(
        `/admin/organization/${orgId}/manage-lobs/lob/${lob.id}/view`
      ) : history.push(`/organization/${orgId}/manage-lobs/lob/${lob.id}/view`);
  };

  const onDeleteLOB = lob => {
    setSelectedLOB(lob);
    setAlertDialog({
      open: true,
      title: 'Delete LOB',
      description: 'Are you sure, you want to delete this LOB?',
    });
  };

  const onDialogConfirm = async () => {
    setLoading(true);
    try {
      await PartnerLOBApi.delete(orgId, selectedLOB.id);
      await getPlanTypes();
      setAlertDialog({ open: false });
      setSelectedLOB(null);

      enqueueSnackbar('LOB deleted successfully', {
        variant: 'success',
      });

      setLoading(false);
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong deleting LOB';
      setLoading(false);
      setAlertDialog({ open: false });
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const onDialogClose = () => {
    setSelectedLOB(null);
    setAlertDialog({ open: false });
  };

  const onToggle = () => {
    setFlyout({ open: false });
  };

  const onAddPriceList = () => {
    setFlyout({
      open: true,
      title: `${LABELS_FIELDS_BUTTONS.ADD_PRICE_LIST}`,
    });
    setInNetError();
    setInNetFlag(false);
    setInNetworkFailArray([]);
    setOutNetError();
    setOutNetFlag(false);
    setOutNetworkFailArray([]);
    setPrescriptionError();
    setPrescriptionFlag(false);
    setPrescriptionFailArray([]);
  };

  const onAppleFilter = (key, value) => {
    setSelectedFilter({ ...selectedFilter, [key]: value });
  };

  const { name, affiliate } = selectedFilter;

  useEffect(() => {
    const data = lobs
      .filter(
        fItem =>
          fItem.name.toLowerCase().indexOf(name.trim().toLowerCase()) !== -1
      )
      .filter(aItem => !affiliate || aItem.affiliateIds.includes(affiliate));
    // .filter(pItem => !lob || pItem.planIds.includes(lob))
    // .filter(rItem => !role || rItem.roleIds.includes(role));

    setFilteredLobs(data);
  }, [name, affiliate, lobs]);

  const permissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'LOB',
    manageOrg: true,
  });

  const readAccess = permissions?.READ;
  const createAccess = permissions?.CREATE;
  const deleteAccess = permissions?.DELETE;

  return (
    <div>
      {(isSuperAdmin ||
        readAccess) && (
        <div className="manage-lob-root">
          <Typography
            variant="h2"
            style={{ margin: '24px 16px', fontWeight: 'bold', fontSize: 28 }}
            data-testid="pageheader"
          >
            {`Manage LOBs`}
          </Typography>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={4}>
                  <div>
                    <SearchTextField
                      label="Search LOBs"
                      onChangeText={val => onAppleFilter('name', val)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <SelectInput
                    // label,
                    className="affiliate-select"
                    value={selectedFilter['affiliate']}
                    onChange={val => onAppleFilter('affiliate', val)}
                    options={affiliates}
                    emptyLabel="Affiliate: All"
                    optionValueKey="code"
                    data-testid="combobox"
                  />
                </Grid>
                <Grid item xs={12} md={7} lg={5}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <SecondaryButton
                      onClick={onAddPriceList}
                      hasPermissions={createAccess || isSuperAdmin}
                    >
                      {LABELS_FIELDS_BUTTONS.ADD_PRICE_LIST}
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={onAddLOB}
                      hasPermissions={createAccess || isSuperAdmin}
                      style={{ marginLeft: '16px' }}
                    >
                      Add LOB
                    </PrimaryButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <LobPagination
              rowData={filteredLobs}
              // onEdit={item => onEditLOB(item)}
              onDelete={item => onDeleteLOB(item)}
              hasReadPermissions={readAccess}
              onView={item => onViewLOB(item)}
              numberOfRowsPerPage={numberOfRowsPerPage}
              showActionCell={
                deleteAccess ||
                isSuperAdmin
              }
              />
            </Grid>
          </Grid>
          <Container>
            {alertDialog.open && (
              <AlertDialog
                onClose={onDialogClose}
                onConfirm={onDialogConfirm}
                loading={loading}
                {...alertDialog}
              />
            )}
            <SimpleBackdrop open={loading} />
          </Container>
          {flyout.open && (
            <AddPriceList
              onToggle={onToggle}
              loading={loading}
              affiliates={affiliates}
              lobs={lobs}
              setInNetError={setInNetError}
              setInNetFlag={setInNetFlag}
              setInNetworkFailArray={setInNetworkFailArray}
              setOutNetError={setOutNetError}
              setOutNetFlag={setOutNetFlag}
              setOutNetworkFailArray={setOutNetworkFailArray}
              setPrescriptionError={setPrescriptionError}
              setPrescriptionFlag={setPrescriptionFlag}
              setPrescriptionFailArray={setPrescriptionFailArray}
              {...flyout}
            />
          )}
        </div>
      )}
    </div>
  );
}
