import BaseApiService from './BaseApiService';
import faker from 'faker';
import appIcon1 from './images/001-heart.svg';
import appIcon2 from './images/003-medical-records.svg';
import appIcon3 from './images/AppleHealth.svg';
import appIcon4 from './images/icon_home_color.svg';
import appIcon5 from './images/healthCheck.svg';

let _membersData = null;

function _createUser(orgId, data) {
  return BaseApiService.post(`/organization/${orgId}/user`, null, data);
}

function _createrUserV2(orgId, data) {
  return BaseApiService.post(`/organization/${orgId}/invite-user`, null, data);
}

function _updateUserV2(orgId, data) {
  return BaseApiService.post(
    `/organization/reInvite?orgId=${orgId}`,
    null,
    data
  );
}

function _getUserById(orgId, userId) {
  return BaseApiService.get(`/organization/${orgId}/user/${userId}`);
}

function _getUser(userId) {
  return BaseApiService.get(`/organization/v2/user/${userId}`);
}

function _getAllUsers(orgId) {
  return BaseApiService.get(`/organization/${orgId}/user`);
}

function _updateUser(orgId, data) {
  return BaseApiService.put(`/organization/${orgId}/user`, null, data);
}

function _deleteUser(orgId, userId) {
  return BaseApiService.delete(`/organization/${orgId}/user/${userId}`);
}

function _getAllMembersData(reqBody) {
  return BaseApiService.post(`/member/all`, null, reqBody);
}

function _getAllConsents(orgId, patientId) {
  return BaseApiService.get(`/member/${orgId}/${patientId}/get-all-consents`);
}

function _singleMemberDownload(affiliateId, patientId) {
  return BaseApiService.get(`/file/download/${affiliateId}/${patientId}`);
}

function _bulkExport(reqBody) {
  return BaseApiService.post(`/file/bulkdownload`, null, reqBody);
}

function _getListOfUsers({
  offset,
  limit = 10,
  status,
  search_text,
  searchAffId,
  orgId,
  signup_type,
  role_Id,
  is_external = '',
  lob_Id,
  user_type,
}) {
  let url = `/organization/${orgId}/v2/users?offset=${offset}&limit=${limit}&signup_type=${signup_type}&status=${status}&search_text=${search_text}&affiliate_Id=${searchAffId}&role_Id=${role_Id}&lob_Id=${lob_Id}`;

  if (user_type) {
    url += `&user_type=${user_type}`;
  }
  if (is_external) {
    url += `&is_external=${is_external}&role_Id=${role_Id}&lob_Id=${lob_Id}`;
  }
  return BaseApiService.get(url);
}

function _getDevApprovedDecline({ orgId, userId, payload }) {
  return BaseApiService.put(
    `/organization/${orgId}/user-approval/${userId}`,
    null,
    payload
  );
}

function _AllRoles() {
  return BaseApiService.get(`/master/data/v2/roles`);
}

function _getAllRoles() {
  return BaseApiService.get(`/master/data/roles
  `);
}

function _getAllLOB({ orgId }) {
  return BaseApiService.get(`/organization/${orgId}/plan`);
}

function _getAllMembers() {
  return new Promise((resolve, reject) => {
    if (!_membersData) {
      _membersData = Array.from({ length: 25 }, () => ({
        name: faker.name.findName(),
        email: faker.internet.email(),
        lastAccessed: faker.date.past(),
        id: faker.datatype.uuid(),
        consentedApps: faker.random.arrayElements([
          {
            icon: appIcon4,
            appName: 'MPowered App',
            consentedOn: faker.date.past(),
            expiresOn: faker.date.future(),
            status: faker.random.arrayElement(['Active', 'Expired', 'Revoked']),
            consents: faker.random.arrayElements([
              'Medical',
              'Financial',
              'Demographics',
            ]),
          },
          {
            icon: appIcon5,
            appName: 'Health Check',
            consentedOn: faker.date.past(),
            expiresOn: faker.date.future(),
            status: faker.random.arrayElement(['Active', 'Expired', 'Revoked']),
            consents: faker.random.arrayElements([
              'Medical',
              'Financial',
              'Demographics',
            ]),
          },
          {
            icon: appIcon1,
            appName: 'Secure Health',
            consentedOn: faker.date.past(),
            expiresOn: faker.date.future(),
            status: faker.random.arrayElement(['Active', 'Expired', 'Revoked']),
            consents: faker.random.arrayElements([
              'Medical',
              'Financial',
              'Demographics',
            ]),
          },
          {
            icon: appIcon2,
            appName: 'Medical App',
            consentedOn: faker.date.past(),
            expiresOn: faker.date.future(),
            status: faker.random.arrayElement(['Active', 'Expired', 'Revoked']),
            consents: faker.random.arrayElements([
              'Medical',
              'Financial',
              'Demographics',
            ]),
          },
          {
            icon: appIcon3,
            appName: 'Apple Health',
            consentedOn: faker.date.past(),
            expiresOn: faker.date.future(),
            status: faker.random.arrayElement(['Active', 'Expired', 'Revoked']),
            consents: faker.random.arrayElements([
              'Medical',
              'Financial',
              'Demographics',
            ]),
          },
        ]),
      }));
    }

    resolve({ data: _membersData });
  });
}

function _getMember(id) {
  return new Promise((resolve, reject) => {
    _getAllMembers().then(response => {
      let memberData = {};
      const { data } = response;

      const memberIndex = data.findIndex(item => item.id === id);

      if (memberIndex !== -1) {
        memberData = data[memberIndex];
      } else {
        memberData = {
          name: faker.name.findName(),
          email: faker.internet.email(),
          lastAccessed: faker.date.past(),
          id,
          consentedApps: faker.random.arrayElements([
            {
              icon: appIcon4,
              appName: 'MPowered App',
              consentedOn: faker.date.past(),
              expiresOn: faker.date.future(),
              status: faker.random.arrayElement([
                'Active',
                'Expired',
                'Revoked',
              ]),
              consents: faker.random.arrayElements([
                'Medical',
                'Financial',
                'Demographics',
              ]),
            },
            {
              icon: appIcon5,
              appName: 'Health Check',
              consentedOn: faker.date.past(),
              expiresOn: faker.date.future(),
              status: faker.random.arrayElement([
                'Active',
                'Expired',
                'Revoked',
              ]),
              consents: faker.random.arrayElements([
                'Medical',
                'Financial',
                'Demographics',
              ]),
            },
            {
              icon: appIcon1,
              appName: 'Secure Health',
              consentedOn: faker.date.past(),
              expiresOn: faker.date.future(),
              status: faker.random.arrayElement([
                'Active',
                'Expired',
                'Revoked',
              ]),
              consents: faker.random.arrayElements([
                'Medical',
                'Financial',
                'Demographics',
              ]),
            },
            {
              icon: appIcon2,
              appName: 'Medical App',
              consentedOn: faker.date.past(),
              expiresOn: faker.date.future(),
              status: faker.random.arrayElement([
                'Active',
                'Expired',
                'Revoked',
              ]),
              consents: faker.random.arrayElements([
                'Medical',
                'Financial',
                'Demographics',
              ]),
            },
            {
              icon: appIcon3,
              appName: 'Apple Health',
              consentedOn: faker.date.past(),
              expiresOn: faker.date.future(),
              status: faker.random.arrayElement([
                'Active',
                'Expired',
                'Revoked',
              ]),
              consents: faker.random.arrayElements([
                'Medical',
                'Financial',
                'Demographics',
              ]),
            },
          ]),
        };
      }

      resolve(memberData);
    });
  });
}

const OrganizationUsersApi = {
  createUser: _createUser,
  getUserById: _getUserById,
  getUser: _getUser,
  getAllUsers: _getAllUsers,
  // searchUsers: _searchUsers,
  getAllMembers: _getAllMembers,
  getAllConsents: _getAllConsents,
  getMember: _getMember,
  updateUser: _updateUser,
  deleteUser: _deleteUser,
  getAllMembersData: _getAllMembersData,
  singleMemberDownload: _singleMemberDownload,
  bulkExport: _bulkExport,
  getListOfUsers: _getListOfUsers,
  getAllRoles: _getAllRoles,
  getAllLOB: _getAllLOB,
  getDevApprovedDecline: _getDevApprovedDecline,
  createrUserV2: _createrUserV2,
  updateUserV2: _updateUserV2,
  AllRoles: _AllRoles,
};

export default OrganizationUsersApi;
