import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      style={{ width: '100%', marginTop: 15 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          style={{ borderRadius: 50, height: 8 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" 
        // color="text.secondary"
        >{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ fileloaded }) {
  const progress = fileloaded;
  return (
    <Box xl={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
