import BaseApiService from "services/api/BaseApiService";

function _geCarePlantStaticMetadata() {
    return BaseApiService.get(`/organization/geCarePlantStaticMetadata`);
}

const _createCarePlan = ({ orgId, payload }) => {
    return BaseApiService.post(
        `/organization/${orgId}/createCarePlan`,
        null,
        JSON.stringify(payload)
    );
};

const _updateCarePlan = ({ orgId, payload }) => {
    return BaseApiService.put(
        `/organization/${orgId}/updateCarePlan`,
        null,
        payload
    );
};

const CarePlanApi = {
    geCarePlantStaticMetadata: _geCarePlantStaticMetadata,
    createCarePlan: _createCarePlan,
    updateCarePlan: _updateCarePlan,
};

export default CarePlanApi;
  