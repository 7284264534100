import * as actionTypes from './actionTypes';
import MasterDataApi from 'services/api/MasterDataApi';

const addressTypesFetchSuccess = payload => {
  return {
    type: actionTypes.ADDRESS_TYPES_FETCH_SUCCESS,
    payload,
  };
};

const addressTypesFetchFailure = error => {
  return {
    type: actionTypes.ADDRESS_TYPES_FETCH_FAILURE,
    error: error,
  };
};

const addressTypesFetchStart = () => {
  return {
    type: actionTypes.ADDRESS_TYPES_FETCH_START,
  };
};

export const fetchAddressTypes = () => {
  return dispatch => {
    dispatch(addressTypesFetchStart());

    MasterDataApi.getAddressTypes()
      .then(response => {
        dispatch(addressTypesFetchSuccess(response));
      })
      .catch(error => {
        dispatch(addressTypesFetchFailure(error));
      });
  };
};
