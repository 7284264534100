import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M16.25 5.55762C10.6035 5.55762 6.05762 10.1035 6.05762 15.75C6.05762 21.3965 10.6035 25.9424 16.25 25.9424C21.8965 25.9424 26.4424 21.3965 26.4424 15.75C26.4424 10.1035 21.8965 5.55762 16.25 5.55762ZM20.8916 19.6738L20.1738 20.3916C19.791 20.7744 19.1211 20.7744 18.7383 20.3916L15.6279 17.2812C14.3838 17.7119 12.9482 17.4248 11.9434 16.4678C10.7949 15.3193 10.6035 13.5967 11.3213 12.2568L13.7139 14.6494L15.1494 13.2139L12.7568 10.8213C14.0967 10.1035 15.8193 10.2949 16.9678 11.4434C17.9248 12.4004 18.2119 13.8838 17.7812 15.1279L20.8916 18.2383C21.2744 18.6211 21.2744 19.291 20.8916 19.6738Z" />
    </SvgIcon>
  );
};

export default Icon;
