import React from 'react';
import Box from '@material-ui/core/Box';

export const DrawerBoxComponent = ({ children, style, ...props }) => {
  return (
    <Box
      style={{
        marginBottom: 0,
        mx: { sm: 8, xs: 3 },
        paddingLeft: { xs: 1, sm: 4 },
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: '12px',
        ...style,
      }}
      {...props}>
      {children}
    </Box>
  );
};
