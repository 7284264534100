import React, { useEffect, useState } from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import { convertStrToTitleCase } from 'shared/utility';
import { Add } from '@material-ui/icons';
import NotFoundPage from 'components/NotFoundPage';
import { PrimaryButton, SecondaryButton } from 'components/widgets';
import CloseIcon from '@material-ui/icons/Close';
import { BUTTON_TITLES, INITIAL_RENDER_ROLE, ROLES } from 'shared/constants';
import Delete from '@material-ui/icons/Delete';
import RolesApi from 'services/api/RolesApiNew';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 10,
    fontSize: '18px',
    color: '#002534',
    height: '40px',
    width: '40px',
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  container1: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },

  root: {
    flexGrow: 1,
    minHeight: '5vh',
    height: '120px',
    width: '22%',
    minWidth: '30%',
    background: '#F5F7F8',
    padding: '10px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    margin: '10px',
    justifyContent: 'center',
  },
  root1: {
    flexGrow: 1,
    minHeight: '5vh',
    height: '120px',
    width: '22%',
    minWidth: '30%',
    background: '#ffffff',
    padding: '10px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    margin: '10px',
    justifyContent: 'center',
  },
  active: {
    flexGrow: 1,
    minHeight: '5vh',
    maxHeight: '85vh',
    width: '22%',
    minWidth: '30%',
    background: '#E2F1FC',
    padding: '10px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    border: '1px solid #036FCB',
    margin: '10px',
    justifyContent: 'center',
  },

  addNew: {
    flexGrow: 1,
    minHeight: '5vh',
    height: '120px',
    width: '22%',
    minWidth: '30%',
    padding: '10px 20px',
    borderRadius: '10px',
    border: '1px dashed',
    cursor: 'pointer',
    margin: '10px',
    justifyContent: 'center',
  },
  radioContainer: {
    display: '-webkit-box',
    padding: '20px',
    '-webkit-box-pack': 'center',
    // gap: '20px',
    // height: '53vh',
    overflow: 'auto',
  },
  radioContainer1: {
    display: '-webkit-box',
    padding: '20px',
    '-webkit-box-pack': 'center',
    // gap: '20px',
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    alignItems: 'center',
    // marginBottom: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(3),
  },
  check: {
    fontSize: 48,
    color: '#27AE60',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 300,
    marginTop: '10px',
  },
  body: {
    textAlign: 'center',
  },
  dialogPaper: {
    borderRadius: theme.spacing(3),
    minWidth: '500px',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  mt: {
    marginTop: '3%',
  },
}));

const Cards = ({ isManagerole = false, setNewRoleId, ...props }) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [alertType, setAlertTye] = useState('');
  const [roleName, setroleName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedRoleValue, setSelectedRoleValue] = useState('');
  const orgId = sessionStorage.getItem('orgId');
  const { enqueueSnackbar } = useSnackbar();

  function getInitials(inputString) {
    const words = inputString.split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0);
      const secondInitial = words[1].charAt(0);
      return `${firstInitial.toUpperCase()}${secondInitial.toUpperCase()}`;
    } else {
      return words.map(word => word.charAt(0).toUpperCase()).join('');
    }
  }

  useEffect(() => {
    props?.data &&
      props?.data?.map((item, i) => {
        if (item?.roleId === props?.payload?.role) {
          updateRadioGroup(i, item);
        }
      });
  }, [props?.payload]);

  const updateRadioGroup = (i, element) => {
    setActiveIndex(i);
    props.selectedRole(element);
    setSelectedRoleValue(element);
  };

  const addNewRole = () => {
    setOpenDialog(true);
    setAlertTye('add');
  };

  const deleteRole = element => {
    setOpenDialog(true);
    setAlertTye('remove');
    setSelectedRoleValue(element);
  };

  const onCloseClick = () => {
    setOpenDialog(false);
    setAlertTye('');
    setroleName('');
    setDescription('');
    setActiveIndex('');
  };

  const handleRoleName = e => {
    let text = e.target.value;
    let modifyText = text.trimStart().replace(/\s\s+/g, ' ');
    setroleName(modifyText);
  };

  const handleButton = async () => {
    if (alertType === 'remove') {
      //  API call for delete comes here
      const roleId = selectedRoleValue?.roleId;
      await RolesApi.deleteRole({ orgId, roleId })
        .then(res => {
          onCloseClick();
          enqueueSnackbar('Role deleted successfully.', {
            variant: 'success',
            autoHideDuration: 800,
          });
          props.refetchData();
        })
        .catch(err => {
          enqueueSnackbar(err.response.data.details, {
            variant: 'error',
            autoHideDuration: 800,
          });
        });
    } else {
      // API call for adding new role comes here

      const payload = {
        name: roleName?.trimEnd(),
        description: description,
      };

      await RolesApi.createRole({ orgId, payload })
        .then(res => {
          onCloseClick();
          enqueueSnackbar('Role created successfully.', {
            variant: 'success',
            autoHideDuration: 800,
          });
          setNewRoleId?.(res?.id);
          props.refetchData?.();
        })
        .catch(err => {
          enqueueSnackbar(err?.response?.data?.details, {
            variant: 'error',
            autoHideDuration: 800,
          });
        });
    }
  };
  const radioButtons = props.data;

  const EmptyGridForUI = () => <Grid item sm={9} xs={6} />;

  return (
    <>
      <div
        className={
          isManagerole ? classes.radioContainer1 : classes.radioContainer
        }
      >
        <Grid
          spacing={3}
          className={isManagerole ? classes.container1 : classes.container}
        >
          {radioButtons?.length !== 0 && (
            <Grid
              item
              xs={3}
              className={classes.addNew}
              onClick={() => addNewRole()}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px',
                }}
              >
                <Avatar className={classes.avatar}>
                  <Add style={{ color: 'white' }} />
                </Avatar>
                <div>
                  <div>
                    <Typography
                      style={{
                        fontWeight: 600,
                        lineHeight: '20px',
                        marginTop: '10px',
                      }}
                    >
                      Add new role
                    </Typography>{' '}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: '#667085',
                  marginTop: '10px',
                }}
              >
                <p>Customized role</p>
              </div>
            </Grid>
          )}
          {radioButtons &&
            radioButtons?.map((element, i) => (
              <Grid
                item
                xs={3}
                key={i}
                className={
                  activeIndex === i
                    ? classes.active
                    : isManagerole
                    ? classes.root1
                    : classes.root
                }
                onClick={e => {
                  updateRadioGroup(i, element);
                }}
                ref={radioButtons.length - 1 === i ? props.loadMore : null}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <Avatar className={classes.avatar}>
                      {getInitials(element.roleName)}
                    </Avatar>
                    <div>
                      <div>
                        <Typography
                          style={{ fontWeight: 600, lineHeight: '20px' }}
                        >
                          {' '}
                          {element?.roleName}
                        </Typography>{' '}
                      </div>
                      <div>
                        <span
                          style={{
                            marginTop: '4px',
                            fontSize: '12px',
                            lineHeight: '16px',
                          }}
                        >
                          {element?.usersActive}{' '}
                          {element?.usersActive > 1 ? 'people' : 'person'}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                  {isManagerole &&
                    props.deleteAccess &&
                    element?.usersActive === 0 &&
                    !element?.isStatic && (
                      <Delete
                        style={{ color: '#667085', fontSize: '20px' }}
                        onClick={e => {
                          e.stopPropagation();
                          deleteRole(element);
                        }}
                      />
                    )}
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    color: '#667085',
                    marginTop: '10px',
                  }}
                >
                  <p>{element?.description}</p>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
      {radioButtons?.length === 0 && props.OnloadData && (
        <Box style={{ margin: '20px' }}>
          <NotFoundPage
            title={INITIAL_RENDER_ROLE.TITLE}
            titleContent={INITIAL_RENDER_ROLE.TITLE_CONTENT}
            content={INITIAL_RENDER_ROLE.CONTENT}
            showButton={false}
            styles={{ background:isManagerole ? '#F5F7F8' : 'white' }}
          />
        </Box>
      )}
      {openDialog && (
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={openDialog}
          onClose={() => onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.closeContainer}>
            <div
              id="alert-dialog-title"
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                padding: 20,
                display: 'grid',
              }}
            >
              {alertType === 'remove'
                ? ROLES.REMOVE_ROLE_TITLE
                : ROLES.NEW_ROLE_TITLE}
              <span className={classes.subTitle}>
                {alertType === 'remove'
                  ? ROLES.REMOVE_ROLE_SUBTITLE
                  : ROLES.NEW_ROLE_SUBTITLE}{' '}
              </span>
            </div>
            <IconButton
              style={{ marginBottom: 'auto' }}
              onClick={() => onCloseClick()}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {alertType === 'remove' ? (
            <hr style={{ border: '0.5px solid #E4E7EC' }} />
          ) : (
            <DialogContent>
              <Grid item xs={12}>
                <div style={{ margin: 'auto' }}>
                  <Grid item sm={12} xs={12}>
                    <Typography className={classes.formTitle}>
                      Role name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      id="role-title"
                      margin="dense"
                      variant="outlined"
                      className={classes.formTextField}
                      value={roleName}
                      placeholder="eg., Marketing manager"
                      onChange={handleRoleName}
                      autoComplete="off"
                      autoFocus
                      inputProps={{
                        maxLength: 40,
                        'data-testid': 'role-title-textbox',
                      }}
                    />
                  </Grid>
                  <EmptyGridForUI />
                  <Grid item sm={12} xs={12} className={classes.mt}>
                    <Typography className={classes.formTitle}>
                      Description <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      id="outlined-basic"
                      margin="dense"
                      variant="outlined"
                      className={classes.formTextField}
                      placeholder="Enter the description of the role"
                      multiline
                      value={description}
                      onChange={e => setDescription(e.target.value?.trimStart())}
                      minRows={4}
                      // disabled={isEdit}
                      inputProps={{
                        maxLength: 150,
                        'data-testid': 'role-description-textbox',
                      }}
                      helperText={`${description.length}/150`}
                      FormHelperTextProps={{
                        style: {
                          // textAlign: 'center',
                          position: 'absolute',
                          bottom: '0%',
                          right: '0%',
                        },
                      }}
                    />
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
          )}
          <DialogActions style={{ margin: '6px 20px' }}>
            <SecondaryButton onClick={() => onCloseClick()}>
              {BUTTON_TITLES.CANCEL}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => handleButton()}
              disabled={
                alertType === 'remove'
                  ? false
                  : roleName?.length === 0 || description?.length === 0
                  ? true
                  : false
              }
            >
              {alertType === 'remove'
                ? BUTTON_TITLES.REMOVE
                : BUTTON_TITLES.CREATE_ROLE}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Cards;
