import React from 'react';
import { Box, Typography, Paper, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserIcon from '../../components/icons/UserIcon';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '4px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  paperConatiner: {
    padding: theme.spacing(0.5),

    textAlign: 'center',
    backgroundColor: '#E4E7EC',
  },
  root1: { backgroudColor: 'pink', flexGrow: 1 },
}));

export const MembersCount = ({
  memberCountValue,
  memberCountLoading,
  hideRefreshBtn,
  onRefresh
}) => {
  const classes = useStyles();
  return (
    <Paper
      data-testid="papercomponent"
      className={classes.paperConatiner}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '8px',
        paddingRight: '10px',
      }}>
      <UserIcon />
      <Typography
        style={{ marginLeft: '4px', marginRight: '4px' }}
        data-testid="title">
        <b>Members :</b>
      </Typography>{' '}
      {!memberCountLoading && (
        <Typography
          data-testid="member-count-display"
          style={{ marginLeft: '4px', marginRight: '4px' }}>
          {memberCountValue}
        </Typography>
      )}
      <Box display={'flex'} alignItems={'center'} style={{ gap: '5px' }}>
      {memberCountLoading ? (
        <CircularProgress color="secondary" size={10} />
      ) : (
        !hideRefreshBtn && (
          <RefreshIcon
              style={{
                color: '#667085',
                fontSize: '14px',
                cursor: 'pointer',
              }}
              onClick={onRefresh}
              data-testid='member-count-refresh-btn'
            />
        )
      )}
      </Box>
      
    </Paper>
  );
};
