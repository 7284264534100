import { Component, memo,useState } from 'react';
import { DataGrid } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import Box from '@material-ui/core/Box';
import FlyoutModal from '../FlyoutModal';
import ConsentTrigger from '../ConsentForm/ConsentTrigger';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
}));

const cancelledStatusData = [
  {
    title: 'Received From',
    property: 'fullName',
  },
  {
    title: 'Received On',
    property: 'consentRequestedOn',
  },
  {
    title: 'Email ID',
    property: 'email',
  },
  {
    title: 'Status',
    property: 'consentStatus',
  },
];

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  value,
  data,
  onClick,
  ...props
}) {
  const classes = useStyles();
  const handleViewButton = event => {
    event.preventDefault();
    if (['Expired','Terminated'].includes(data.consentStatus)) {
      props.openFlyOutModal(data);
    } else {
      const dataArray=[];
      const dataArray2=[];
      dataArray2.push(data);
      const finaldata=[
            {
              "attributeName": "workflowName",
              "attributeType":"string",
              "attributeValue": "CMDEViewConsent"
            },
            {
              "attributeName": "consent_id",
              "attributeType":"string",
              "attributeValue": `${data.consentId}`
            }
          ];
          
          dataArray.push({"data":dataArray2,"content":finaldata,"workflow":"CMDEViewConsent"});
        
      props.openDrawer(dataArray);
      onClick(data);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          marginTop: '12px',
        }}>
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={handleViewButton}>
          View details
        </PrimaryButton>
      </div>
    </>
  );
});

const statusColorMap = {
  Pending: '#FB8900',
  Active: '#036FCB',
  Cancelled: '#DB0032',
  Expired: '#DB0032',
  Terminated: '#DB0032',
};

class ConsentsGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Template name',
        field: 'typeOfRecords',
      },
      {
        headerName: 'Requested on',
        field: 'consentRequestedOn',
        cellRenderer: 'DateFormatter',
      },
      {
        headerName: 'Consent expiry',
        field: 'consentExpiresOn',
        cellRenderer: 'ConsentExpiryDateFormatter',
      },
      {
        headerName: 'Consent status',
        field: 'consentStatus',
        cellRenderer: 'StatusRenderer',
      },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 150,
      resizable: true,
      sortable: true,
      filter: false,
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      ConsentExpiryDateFormatter: rowData => {
        if (rowData.value && rowData.data.consentStatus !== 'Pending') {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      StatusRenderer: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <span
              style={{
                backgroundColor: statusColorMap[rowData.value],
                width: 8,
                height: 8,
                borderRadius: 4,
                padding: 2,
              }}></span>
            <span style={{ marginLeft: 8 }}>{rowData.value}</span>
          </Box>
        );
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          onClick={item => this.onItemView(item)}
          openFlyOutModal={data => {
            this.setState({ showFlyOutModal: true, patientRecord: data });
          }}
          openDrawer={data => {
            this.setState({ openDrawer: true, patientRecord: data });
          }}
          {...rowData}
        />
      ),

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
    showFlyOutModal: false,
    openDrawer: false,
    patientRecord: {},
  };

  onItemView(data) {
    this.props.onView(data);
  }

  render() {
    const {
      defaultColDef,
      columnDefs,
      frameworkComponents,
      showFlyOutModal,
      openDrawer,
      patientRecord,
    } = this.state;
    const { rowData } = this.props;

    return (
      <>
        {showFlyOutModal && (
          <FlyoutModal
            handleClose={() => this.setState({ showFlyOutModal: false })}
            open={showFlyOutModal}
            paramsValue={cancelledStatusData}
            patientRecord={patientRecord}
            statusColorMap={statusColorMap}
          />
        )}
        {openDrawer && (
          <ConsentTrigger
            data={patientRecord}
            exitDDUI={() => this.setState({ openDrawer: false })}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: 500,
            marginTop: 32,
          }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}>
            <DataGrid
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={rowData}
              frameworkComponents={frameworkComponents}
              context={this.state.context}
              style={{ minHeight: '50vh', height: 'calc(100vh - 270px)' }}
              hideHeaderActions
            />
          </div>
        </div>
      </>
    );
  }
}

export default ConsentsGrid;
