import { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
}));

const RecordClickCellRenderer = memo(function RecordClickCellRenderer({
  value,
  data,
  onClick,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <>
      <span className={classes.name} onClick={onClickHandler}>
        {value}
      </span>
    </>
  );
});

export default RecordClickCellRenderer;
