import { PrimaryButton } from 'components/widgets';
import Listing from 'containers/Roles/Listing';
import React, { useEffect } from 'react';

const AssignRoleForm = ({ setSelectedRoleValue, setNewRoleId, ...props }) => {
  useEffect(() => {
    if (props?.roleData) {
      props.payload = { ...props.payload, role: props?.roleData?.roleId };
      props?.setPayload(props.payload);
    }
  }, [props?.roleData]);

  return (
    <>
      <div style={{position:'relative', marginTop: '-10px' }}>
        <Listing
          setSelectedRole={setSelectedRoleValue}
          payload={props?.payload}
          setNewRoleId={setNewRoleId}
        />
      </div>
      <div style={{ position:'absolute',bottom:'0',backgroundColor:'white',width:'70%' }}>
        <div style={{ textAlign: 'center',padding:'10px' }}>
          <PrimaryButton
            disabled={props?.roleData?.roleId ? false : true}
            onClick={props?.incrementStep}
          >
            Proceed
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default AssignRoleForm;
