import { Component } from 'react';
import { DataGrid } from 'components/widgets';
import moment from 'moment';
import ActionsCellTemplate from './ActionsCellTemplate';
import RecordClickCellRenderer from 'components/widgets/DataGrid/RecordClickCellRenderer';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';

class UsersGrid extends Component {
  state = {
    columnDefs: [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'name',
        headerName: `${LABELS_FIELDS_BUTTONS.LOB_NAME}`,
        cellRenderer: 'NameRenderer',
      },
      {
        field: 'planId',
        headerName: 'Plan id',
      },
      {
        field: 'planType',
        headerName: `${LABELS_FIELDS_BUTTONS.PLAN_TYPE}`,
      },
      {
        field: 'affiliates',
        headerName: 'Affiliate',
        cellRenderer: 'SetRenderer',
      },
      {
        field: 'members',
        headerName: `${LABELS_FIELDS_BUTTONS.MEMBERS_TIED}`,
      },
      {
        field: 'updatedAt',
        headerName: `${LABELS_FIELDS_BUTTONS.LAST_UPDATED}`,
        cellRenderer: 'DateFormatter',
      },
      {
        field: 'actions',
        headerName: '',
        width: 50,
        maxWidth: 50,
        pinned: this.props.showActionCell ? 'right' : '',
        cellRenderer: this.props.showActionCell ? 'ActionsRenderer' : '',
        resizable: false,
        sortable: false,
        filter: false,
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 180,
      resizable: true,
      sortable: true,
      filter: false,
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        let formattedDate = moment(rowData.value).format('MMM DD YYYY hh:mm A');
        return formattedDate;
      },
      ActionsRenderer: props => (
        <ActionsCellTemplate
          {...props}
          // onEdit={item => this.onItemEdit(item)}
          onDelete={item => this.onItemDelete(item)}
        />
      ),
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      NameRenderer: rowData => (
        <RecordClickCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
        />
      ),
    },
    context: { componentParent: this },
  };

  // onItemEdit(data) {
  //   this.props.onEdit(data);
  // }

  onItemDelete(data) {
    this.props.onDelete(data);
  }

  onItemView(data) {
    this.props.onView(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            // rowSelection="single"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh' }}
            hideHeaderActions
          />
        </div>
      </div>
    );
  }
}

export default UsersGrid;
