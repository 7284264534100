import { useState, useEffect } from 'react';
import AlertsApi from 'services/api/AlertsApi';
import Alert from '@material-ui/lab/Alert';

function ManageAlertsContent({ orgId }) {
  //   const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (orgId) {
      setLoading(true);
      let _getRequest = AlertsApi.getAllAlerts(orgId)
        .then(response => {
          if (_getRequest) {
            setData(response.data);
          }
        })
        .catch(() => {})
        .finally(() => {
          if (_getRequest) {
            setLoading(false);
          }
        });

      return function cleanup() {
        _getRequest = null;
      };
    }
  }, [orgId]);

  if (loading) {
    return null;
  }

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      {data.length > 0 ? (
        <>
          {data.map(item => (
            <div key={item.id}>
              <Alert severity="info">{item.desc}</Alert>
            </div>
          ))}
        </>
      ) : (
        <div>No alerts found</div>
      )}
    </div>
  );
}

export default ManageAlertsContent;
