import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  FlyoutPanel,
  PageHeaderTitle,
  PrimaryButton,
  ReadonlyField,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import ReusableModal from 'containers/Applications/ReusableModal';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { DEV, REQUEST_MODAL, REUSABLE_MODAL } from 'shared/constants';
import { convertStrToTitleCase, statusColorMap } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  nameContainer: {
    display: 'flex',
    minHeight: '85vh',
    maxHeight: '85vh',
    marginTop: '20px',
    width: '100%',
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    fontSize: '18px',
    color: '#002534',
    height: '50px',
    width: '50px',
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  root: {
    flexGrow: 1,
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  paper: {
    padding: theme.spacing(1.8),
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
    width: '100%',
    maxWidth: '550px',
    flexWrap: 'wrap',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  componentWrapper: {
    fontWeight: 600,
    wordWrap: 'break-word',
    marginTop: '4px',
  },
}));

const ThirdPartyDevViewDetailsPage = ({
  open,
  onToggle,
  developerDetail,
  getListOfUser,
  hasApprovePermission,
  setFlyout,
}) => {
  const classes = useStyles();
  const [declinePopup, setDeclinePopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const orgId = sessionStorage.getItem('orgId');
  let userId = developerDetail?.userId;

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const onApproveRequest = () => {
    setApprovePopup(true);
  };

  const onDenyRequest = () => {
    setDeclinePopup(true);
  };

  const onChangeApproval = ({ status = '' }) => {
    setLoading(true);
    let payload = {
      id: userId,
      status: status
        ? status
        : developerDetail?.orgUserStatus?.toLowerCase() === 'pending'
        ? 'Approved'
        : 'Declined',
      ...(status?.toLowerCase() === 'declined'
        ? { deniedReason: declineReason }
        : { revokeReason: declineReason }),
      firstName: developerDetail.firstName,
      lastName: developerDetail.lastName,
      emailAddress: developerDetail?.emailId,
    };

    OrganizationUsersApi.getDevApprovedDecline({ orgId, userId, payload })
      .then(response => {
        setLoading(false);
        if (status === 'Revoked') {
          enqueueSnackbar('User request revoked', {
            variant: 'success',
          });
        } else if (status === 'Approved') {
          enqueueSnackbar('User request approved', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('User request denied', {
            variant: 'success',
          });
        }
        getListOfUser(
          developerDetail?.orgUserStatus === 'pending',
          developerDetail?.orgUserStatus === 'declined'
            ? 'DECLINED'
            : 'APPROVED'
        );
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar('There were some error performing this action', {
          variant: 'error',
        });
      });
  };

  const handleConfirmDenied = ({ status }) => {
    if (!checkValidation()) return;
    onChangeApproval({
      status:
        status && status?.toLowerCase() === 'pending' ? 'Declined' : 'Revoked',
    });
    setFlyout({ open: false });
  };

  const handleConfirmApprove = () => {
    onChangeApproval({ status: 'Approved' });
    setLoading(true);
    setFlyout({ open: false });
  };

  const handleCloseConfirmation = () => {
    setDeclinePopup(false);
  };

  const checkValidation = () => {
    if (declineReason.length >= 1) {
      return true;
    } else if (developerDetail.orgUserStatus === 'Approved') {
      enqueueSnackbar('Please enter reason for revoking', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return false;
    } else if (developerDetail.orgUserStatus !== 'Approved') {
      enqueueSnackbar('Please enter reason for declining', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return false;
    }
  };

  const onClose = () => {
    onToggle();
  };

  const handleClose = () => {
    setApprovePopup(false);
  };

  const avatarInitials = name => {
    return name
      ?.split(' ')
      ?.map(item => item[0])
      ?.join('');
  };

  const FooterAction = ({
    saveLabel = 'Save',
    canceLabel = 'Cancel',
    onSave,
    type = 'button',
    onToggle,
    status,
    disabled,
    hideSave = false,
    hasApprovePermission,
    ...otherCommonProps
  }) => {
    return (
      <Box
        sx={{
          background: '#fff',
          boxShadow: '-2px 0px 6px #e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0.8rem 1.4rem',
          gap: '18px',
        }}
      >
        {hasApprovePermission &&
          (status === 'Pending' || status === 'Approved') && (
            <SecondaryButton
              onClick={onToggle}
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              {...otherCommonProps}
            >
              {canceLabel}
            </SecondaryButton>
          )}
        {!hideSave && (
          <PrimaryButton
            onClick={onSave}
            disabled={disabled}
            type={type}
            className={classes.actionButton}
            {...otherCommonProps}
          >
            {saveLabel}
          </PrimaryButton>
        )}
      </Box>
    );
  };

  return (
    <FlyoutPanel
      open={open}
      onToggle={onClose}
      isAppBarRequired={true}
      appBarChildren={
        !['revoked', 'declined', 'expired'].includes(
          developerDetail?.orgUserStatus?.toLowerCase()
        ) && (
          <FooterAction
            hideSave={developerDetail?.orgUserStatus === 'Approved'}
            saveLabel="Approve"
            hasApprovePermission={hasApprovePermission}
            canceLabel={
              developerDetail.orgUserStatus === 'Approved'
                ? 'Revoke'
                : 'Decline'
            }
            status={developerDetail?.orgUserStatus}
            onSave={onApproveRequest}
            onToggle={onDenyRequest}
            disabled={loading}
          />
        )
      }
    >
      <Box sx={{ marginTop: '-20px', marginLeft: '-20px' }}>
        <PageHeaderTitle>{DEV.PAGE_HEADER}</PageHeaderTitle>
      </Box>

      <div className={classes.root}>
        <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Avatar className={classes.avatar}>
                {avatarInitials(
                  `${developerDetail?.firstName} ${developerDetail?.lastName}`
                )}
              </Avatar>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography style={{ fontWeight: 600 }}>
                    {convertStrToTitleCase(
                      `${developerDetail?.firstName} ${developerDetail?.lastName}`
                    )}
                  </Typography>{' '}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <span
                    style={{
                      backgroundColor:
                        statusColorMap[developerDetail?.orgUserStatus || 'N/A'],
                      width: 8,
                      marginTop: '7px',
                      height: 8,
                      borderRadius: 4,
                      padding: 4,
                    }}
                  ></span>
                  <span style={{ marginLeft: 8 }}>
                    {convertStrToTitleCase(developerDetail?.orgUserStatus) ||
                      'N/A'}
                  </span>{' '}
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0px',
              padding: '10px',
            }}
          >
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={4} sm={3}>
                  <Typography>{DEV.REQUEST_ON}</Typography>
                  <Typography className={classes.componentWrapper}>
                    {' '}
                    {developerDetail?.userCreatedAt
                      ? moment(new Date(developerDetail?.userCreatedAt)).format(
                          'MM-DD-YYYY'
                        )
                      : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {' '}
                  <Typography>{DEV.ORG_NAME}</Typography>
                  <Typography className={classes.componentWrapper}>
                    {' '}
                    {convertStrToTitleCase(
                      developerDetail?.externalOrganizationName
                    ) || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography>{DEV.EMAIL}</Typography>

                  <Typography className={classes.componentWrapper}>
                    {developerDetail?.emailId || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {(developerDetail.orgUserStatus === 'Declined' ||
            developerDetail.orgUserStatus === 'Revoked') && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography>
                  {' '}
                  Reason for{' '}
                  {developerDetail.orgUserStatus === 'Declined'
                    ? 'declining'
                    : 'revoking'}{' '}
                  access
                </Typography>
                <Typography style={{ fontWeight: 600 }}>
                  {developerDetail.orgUserStatus === 'Declined'
                    ? developerDetail?.deniedReason
                    : developerDetail?.revokeReason}{' '}
                </Typography>
              </Paper>
            </Grid>
          )}

          {developerDetail.orgUserStatus === 'Denied' && (
            <Grid item xs={12} className={classes.gridContainer}>
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <ReadonlyField
                  label={`Reason for ${
                    developerDetail?.orgUserStatus === 'Revoked'
                      ? 'revoking'
                      : 'declining'
                  } access`}
                  value={declineReason}
                  isValueBold={true}
                  customLabelStyle={classes.labelSubheading}
                />
              </Grid>
            </Grid>
          )}
          {approvePopup && (
            <ReusableModal
              open={approvePopup}
              handleClose={handleClose}
              onChangeApproval={onChangeApproval}
              title={REUSABLE_MODAL.DIALOG_TITLE}
              context={REUSABLE_MODAL.DIALOG_CONTEXT}
              primarybtn={REUSABLE_MODAL.PRIMARY_BTN}
              secondarybtn={REUSABLE_MODAL.SEC_BTN}
              isShowReason={false}
              handleConfirmApprove={handleConfirmApprove}
            />
          )}
          {declinePopup && (
            <ReusableModal
              status={developerDetail?.orgUserStatus}
              open={declinePopup}
              handleClose={handleCloseConfirmation}
              title={
                developerDetail?.orgUserStatus === 'Approved'
                  ? REQUEST_MODAL.REVOKE_DIALOG_TITLE
                  : REQUEST_MODAL.DECLINE_DIALOG_TITLE
              }
              context={
                developerDetail?.orgUserStatus === 'Approved'
                  ? REQUEST_MODAL.REVOKE_DIALOG_CONTEXT
                  : REQUEST_MODAL.DECLINE_DIALOG_CONTEXT
              }
              placeholder={
                developerDetail?.orgUserStatus === 'Approved'
                  ? REQUEST_MODAL.PLACEHOLDER_REVOKE
                  : REQUEST_MODAL.PLACEHOLDER_DECLINE
              }
              primarybtn={REQUEST_MODAL.PRIMARY_BTN}
              secondarybtn={REQUEST_MODAL.SEC_BTN}
              isShowReason={true}
              handleConfirmDenied={handleConfirmDenied}
              setDeclineReason={setDeclineReason}
              declineReason={declineReason}
            />
          )}
          <SimpleBackdrop open={loading} />
        </Grid>
      </div>
    </FlyoutPanel>
  );
};

export default ThirdPartyDevViewDetailsPage;
