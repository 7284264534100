/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
  BackendSearchTextField,
  PrimaryButton,
  AlertDialog,
  SimpleBackdrop,
} from 'components/widgets';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Box } from '@material-ui/core';
import CriteriaGrid from './CriteriaGrid';
import FilterDropDown from 'components/FilterDropDown';
import RulesEngineApi from 'services/api/RuleEngineApi';
import CreateCriteriaModal from './CreateCriteriaModal';
import CriteriaApi from 'services/api/CriteriaApi';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import CriteriaViewDetailsPage from './CriteriaViewDetailsPage ';
import NotFoundPage from 'components/NotFoundPage';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import { useSnackbar } from 'notistack';
import {
  EMPTY_STATE_PAGE,
  INITIAL_RENDER_NO_DATA_PAGE,
  CLOSE_ALERT_DESCRIPTION,
} from 'shared/constants';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
}));

const CriteriaPage = ({ permissions,rulesPermissions }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [attributeFilter, setAttributeFilter] = useState('all');
  const [attributeFilterType, setAttributeFilterType] = useState('all');
  const [filteredCriteria, setFilteredCriteria] = useState([]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [openCreateCriteriaModal, setOpenCreateCriteriaModal] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [criteriaTitle, setCriteriaTitle] = useState('');
  const [criteriaDetails, setcriteriaDetails] = useState({});
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);
  const [criteriaHistoryDetails, setcriteriaHistoryDetails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteAlert, setDeleteAlert] = useState({ open: false });
  const [deleteId, setDeleteId] = useState('');
  const [isLinkedRulesPublished, setIsLinkedRulesPublished] = useState(false);
  const [alertFlag, setAlertFlag] = useState(false);

  const [selectedCriteria, setSelectedCriteria] = useState({});
  const { CREATE, DELETE } = useHasPermissions({availablePermissions:permissions});

  const { enqueueSnackbar } = useSnackbar();

  const formattedAttributes = attributeList?.map(attribute => {
    return { label: attribute.name, value: attribute.name };
  });

  const attributeId = attributeList?.find(
    val => val.name === attributeFilter
  )?.id;

  const attributeFilterTypeOption = [
    { label: 'All', value: 'all' },
    { label: 'FHIR', value: 'FHIR' },
    { label: 'Non-FHIR', value: 'Non-FHIR' },
  ];

  const attributeType = [
    { label: 'All', value: 'all' },
    ...formattedAttributes,
  ];

  const handleClose = () => {
    setDrawerOpen(false);
    setcriteriaDetails({});
    setcriteriaHistoryDetails({});
  };

  const onViewClick = item => {
    getCriteriaDetails(item.id);
    setDrawerOpen(true);
  };

  const checkForLinkedRules = async (item, type) => {
    setLoading(true);
    try {
      const results = await CriteriaApi.getLinkedRules(orgId, item.id);
      if (results.length === 0) {
        setLoading(false);
        return false;
      }
      let isLinkedRulePublishedVal = false;
      let isLinkedRuleInProgress = false;
      let inProgressRulesName = [];
      for (let rule of results) {
        if (rule.status === 'IN_PROGRESS') {
          inProgressRulesName.push(rule.name);
          isLinkedRuleInProgress = true;
        } else if (rule.status === 'DRAFT') {
          isLinkedRulePublishedVal = true;
        }
      }

      if (isLinkedRuleInProgress) {
        enqueueSnackbar(
          type === 'edit'
            ? `${
                Array.isArray(inProgressRulesName)
                  ? inProgressRulesName.join(', ')
                  : ''
              } rules is in progress, ${type} once the execution is completed`
            : 'This criterion cannot be deleted since it is already mapped to a rule',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
      }

      setLoading(false);
      return { isLinkedRulePublishedVal, isLinkedRuleInProgress };
    } catch (err) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
      return false;
    }
  };

  const fetchCriteriaDetails = item => {
    getCriteriaDetails(item.id, true);
    setIsEdit(true);
  };

  const onEdit = async item => {
    let value = await checkForLinkedRules(item, 'edit');
    if (value.isLinkedRuleInProgress) return;
    else if (value.isLinkedRulePublishedVal) {
      setIsLinkedRulesPublished(true);
      setSelectedCriteria(item);
      return;
    }
    fetchCriteriaDetails(item);
  };

  const handleEdit = () => {
    setIsEdit(true);
    setDrawerOpen(false);
    setOpenCreateCriteriaModal(true);
  };

  const orgId = sessionStorage.getItem("orgId");

  const getAllCriterias = async () => {
    setLoading(true);
    try {
      const results = await RulesEngineApi.getCriteria({
        orgId: orgId,
        searchName: searchValue,
        searchType: attributeId === 'all' ? '' : attributeId ?? '',
        attributeType: attributeFilterType === 'all' ? '' : attributeFilterType,
        offset: offset,
      });
      setFilteredCriteria(results?.data);
      setTotalCount(results.totalItems);
      setLoading(false);
      setOnloadData(true);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    getAllCriterias();
  }, [offset, searchText, attributeFilter, attributeFilterType]);

  const getAttributeList = useCallback(async () => {
    setLoading(true);
    try {
      const results = await CriteriaApi.getAllCriteria(orgId);
      const options = results.data.map(fItem => ({
        id: fItem.id,
        name: fItem.name,
      }));

      setAttributeList(options);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      getAttributeList();
    }
  }, [orgId, getAttributeList]);

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
  };

  const typeofattributeFilterTypeOptions = {
    options: attributeFilterTypeOption,
    value: attributeFilterType,
    changeHandler: ev => {
      setIsInitialRender(false);
      setAttributeFilterType(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const typeofattributeFilterOptions = {
    options: attributeType,
    value: attributeFilter,
    changeHandler: ev => {
      setIsInitialRender(false);
      setAttributeFilter(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  const handleCreateCriteriaModal = val => {
    setOpenCreateCriteriaModal(val);
  };

  const handleCloseCriteriaModal = () => {
    if (alertFlag) {
      setAlertDialog(true);
      return;
    }
    setIsEdit(false);
    setOpenCreateCriteriaModal(false);
    setcriteriaDetails({});
  };

  const closeConfirm = () => {
    setAlertDialog(false);
    setIsEdit(false);
    setOpenCreateCriteriaModal(false);
    setcriteriaDetails({});
    setAlertFlag(false);
  };

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const getCriteriaDetails = async (criteriaId, isEdit = false) => {
    try {
      const results = await CriteriaApi.criteriaDetails({ orgId, criteriaId });
      setCriteriaTitle(results.name);
      setcriteriaDetails(results);
      setLoading(false);
      isEdit && setOpenCreateCriteriaModal(true);
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }

    if (!isEdit) {
      try {
        const results1 = await CriteriaApi.criteriaHistory({
          orgId,
          criteriaId,
        });
        setcriteriaHistoryDetails(results1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    }
  };

  const onDeleteClick = async item => {
    let value = await checkForLinkedRules(item, 'delete');
    if (value.isLinkedRuleInProgress) return;
    setDeleteId(item.id);
    setDeleteAlert({
      open: true,
      title: 'Delete criteria',
      description: 'Are you sure you want to delete this criteria?',
    });
  };

  const onDeleteConfirm = async () => {
    setDeleteAlert({ open: false });
    setLoading(true);
    try {
      await CriteriaApi.deleteCriteria(orgId, deleteId);
      enqueueSnackbar('criterion deleted successfully', { variant: 'success' });
      setLoading(false);
      offset === 0 ? getAllCriterias() : setOffset(0);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        error.response.data?.details
          ? error.response.data.details
          : 'Something went wrong',
        { variant: 'error' }
      );
    }
  };

  const handleCreateCriterian = () => {
    setOpenCreateCriteriaModal(true);
    setSearchText('');
    setSearchValue('');
    setAttributeFilter('all');
    setAttributeFilterType('all');
    if (
      setFilteredCriteria?.length === 0 &&
      searchValue === '' &&
      searchText === '' &&
      setAttributeFilter('all') &&
      setAttributeFilterType('all')
    ) {
      getAllCriterias();
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {(!isInitialRender || filteredCriteria?.length > 0) && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    label="Search by criterion name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                  data-testid="search"
                >
                  Search
                </SecondaryButton>

                <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                  <FilterDropDown
                    {...typeofattributeFilterOptions}
                    filterType="Attributes:"
                  />
                </div>
                {(sessionStorage.getItem('MPH_ORG') === 'true' ||
                  sessionStorage.getItem('MPH_ORG') === true) && (
                  <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                    <FilterDropDown
                      {...typeofattributeFilterTypeOptions}
                      filterType="Attributes type:"
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                }}
              >
                <PrimaryButton
                  onClick={handleCreateCriterian}
                  hasPermissions={CREATE}
                >
                  Create criterion
                </PrimaryButton>
              </div>
              {filteredCriteria?.length > 0 && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <CriteriaGrid
                    apiLoading={loading}
                    rowData={filteredCriteria}
                    onView={onViewClick}
                    onEdit={onEdit}
                    onDelete={onDeleteClick}
                    hasEditPermission={CREATE}
                    hasDeletePermission={DELETE}
                  />
                  <PaginationComponent
                    numberOfRowsPerPage={10}
                    count={totalCount}
                    onPageClick={paginationClick}
                    page={offset}
                  />
                </Grid>
              )}
            </>
          )}
        </div>{' '}
      </Grid>
      {filteredCriteria?.length === 0 && isInitialRender && OnloadData && (
        <NotFoundPage
          title={INITIAL_RENDER_NO_DATA_PAGE.CRITERIA_TITLE}
          titleContent={INITIAL_RENDER_NO_DATA_PAGE.CRITERIA_TITLE_CONTENT}
          content={INITIAL_RENDER_NO_DATA_PAGE.CRITERIA_CONTENT}
          showButton={CREATE}
          onNotFoundClick={handleCreateCriteriaModal}
        />
      )}{' '}
      {filteredCriteria?.length === 0 && !isInitialRender && OnloadData && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={EMPTY_STATE_PAGE.CRITERIA_TITLE}
            content={EMPTY_STATE_PAGE.CRITERIA_CONTENT}
            showButton={false}
          />
        </div>
      )}
      <Container>
        {alertDialog && (
          <AlertDialog
            open={alertDialog}
            onClose={() => setAlertDialog(false)}
            onConfirm={closeConfirm}
            title="Alert"
            description={CLOSE_ALERT_DESCRIPTION}
          />
        )}
        {deleteAlert.open && (
          <AlertDialog
            onClose={() => setDeleteAlert({ open: false })}
            onConfirm={onDeleteConfirm}
            loading={loading}
            {...deleteAlert}
          />
        )}
      </Container>
      <FullScreenDialog
        title={criteriaDetails?.name}
        open={drawerOpen}
        handleClose={handleClose}
      >
        <CriteriaViewDetailsPage
          criteriaDetails={criteriaDetails}
          criteriaHistoryDetails={criteriaHistoryDetails}
          handleEdit={handleEdit}
          orgId={orgId}
          hasCreatePermission={CREATE}
          rulesPermissions={rulesPermissions}
        />
      </FullScreenDialog>
      {openCreateCriteriaModal && (
        <CreateCriteriaModal
          open={openCreateCriteriaModal}
          handleClose={handleCloseCriteriaModal}
          handleSaveAndClose={closeConfirm}
          isEdit={isEdit}
          criteriaDetails={criteriaDetails}
          getAllCriterias={() => getAllCriterias()}
          setAlertFlag={setAlertFlag}
          alertFlag={alertFlag}
        />
      )}
      {isLinkedRulesPublished && (
        <AlertDialog
          open={isLinkedRulesPublished}
          description={
            'Since this rule was executed in production and may have an impact on the users. Are you sure you want edit the criteria?'
          }
          title="Edit criterion"
          onClose={() => setIsLinkedRulesPublished(false)}
          onConfirm={() => {
            setIsLinkedRulesPublished(false);
            fetchCriteriaDetails(selectedCriteria);
          }}
          closeBtnTxt="No"
          confirmBtnTxt="Yes"
          customTitleStyle={{ fontSize: 24, fontWeight: 600, padding: 20 }}
        />
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default CriteriaPage;
