import React from 'react';
import { Box, Divider, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: '#f9fafb',
    padding: theme.spacing(3),
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  section: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  bulletPoints: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1a202c',
  },
  fieldLabel: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#2d3748',
  },
  fieldValue: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1a202c',
    marginBottom: '20px',
  },
  accordion: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#edf2f7',
    // borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  accordionSummary: {
    backgroundColor: '#e2e8f0',
    // borderRadius: '8px',
    padding: theme.spacing(2),
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    // borderRadius: '8px',
  },
  divider: {
    margin: theme.spacing(3, 0),
    height: '2px',
    backgroundColor: '#cbd5e0',
  },
}));

const formatDate = (date) => {
  if (!date) return '-';
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
};

const PreviewScreen = ({ formData, goals }) => {
  const classes = useStyles();

  const renderBulletPoints = (array) => {
    if (!array || array.length === 0) {
      return '-';
    }
    return (
      <ul className={classes.bulletPoints}>
        {array.map((item, index) => (
          <li key={index}>
            {item.category.display || item}
          </li>
        ))}
      </ul>
    );
  };

  const renderActivities = (activities) => {

    return activities.map((activity) => (
      <Accordion key={activity.id} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <Typography className={classes.fieldLabel}>Title: {activity.title || '-'}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography className={classes.fieldLabel}>Description:</Typography>
          <Typography className={classes.fieldValue}>{activity.description || '-'}</Typography>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.fieldLabel}>Kind:</Typography>
              <Typography className={classes.fieldValue}>{activity.kind?.display || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.fieldLabel}>Start Date:</Typography>
              <Typography className={classes.fieldValue}>{formatDate(activity.startDate)}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.fieldLabel}>End Date:</Typography>
              <Typography className={classes.fieldValue}>{formatDate(activity.endDate)}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <div className={classes.container}>
      <Box className={classes.section}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel}>Title</Typography>
            <Typography className={classes.fieldValue}>{formData.title || '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel}>Description</Typography>
            <Typography className={classes.fieldValue}>{formData.description || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel}>Start Date</Typography>
            <Typography className={classes.fieldValue}>{formatDate(formData.startDate)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel}>End Date</Typography>
            <Typography className={classes.fieldValue}>{formatDate(formData.endDate)}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel}>Intent</Typography>
            <Typography className={classes.fieldValue}>{formData.intent || '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel}>Category</Typography>
            {renderBulletPoints(formData.carePlanCategoryMap)}
          </Grid>
        </Grid>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.section}>
        <Typography className={classes.fieldLabel}>Goals</Typography>
        {goals.map((goal) => (
          <Accordion key={goal.id} className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordionSummary}
            >
              <Typography className={classes.fieldLabel}>Title: {goal.title || '-'}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography className={classes.fieldLabel}>Description:</Typography>
              <Typography className={classes.fieldValue}>{goal.description || '-'}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.fieldLabel}>Priority:</Typography>
                  <Typography className={classes.fieldValue}>{goal.priority || '-'}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.fieldLabel}>Start Date:</Typography>
                  <Typography className={classes.fieldValue}>{formatDate(goal.startDate)}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.fieldLabel}>End Date:</Typography>
                  <Typography className={classes.fieldValue}>{formatDate(goal.endDate)}</Typography>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              {goal.activities.length > 0 && <Typography className={classes.fieldLabel}>Activities</Typography>}
              {renderActivities(goal.activities)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </div>
  );
};

export default PreviewScreen;
