import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRouteMatch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from 'theme';
import RootRoute from './RootRoute';
import Layout from './layouts/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import * as actions from 'store/actions';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './vendor-styles/ag-grid.scss';
// import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useCallback, useEffect, useState } from 'react';
import NewOrgSlideout from 'containers/SetupOrganization/NewOrgSlideout';
import ManageAlertsSlideout from 'containers/Alerts/ManageAlertsSlideout';
import SaveApplicationFlyout from 'containers/Applications/SaveApplicationFlyout';
import { useKeycloak } from '@react-keycloak/web';
import { LinearProgress } from '@material-ui/core';
import { updateAuthentication } from './store/actions/auth';
import DashboardPage from 'containers/SolutionDashboard/Dashboard/DashboardPage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AxiosInterceptor } from 'services/api/axiosInstance';
import "./App.css"

const App = ({ ...otherProps }) => {
  const { keycloak } = useKeycloak();

  const isAuthenticated = keycloak.authenticated;

  const [orgId, setOrgId] = useState(null);
  const adminRouteMatch = useRouteMatch(['/admin/organization/:orgId']);

  const complianceRouteMatch = useRouteMatch([
    '/organization/:orgId/affiliate/:affiliateId',
  ]);

  const devRouteMatch = useRouteMatch(['/organization/:orgId']);

  const ITAdminRouteMatch = useRouteMatch(['/organization/:orgId']);

  const authState = useSelector(state => state.auth);
  const { loading: loadingUserInfo, info: userInfo } = useSelector(
    state => state.userInfo
  );
  sessionStorage.setItem("loggedUser",`${userInfo?.firstName?.charAt(0).toUpperCase() + userInfo?.firstName?.slice(1).toLowerCase() } ${userInfo?.lastName?.charAt(0).toUpperCase() + userInfo?.lastName?.slice(1).toLowerCase()}`)
  
  const sandboxStatus = useSelector(
    state => state.slideouts.context.application
  );
  const slideoutsStateContext = useSelector(state => state.slideouts.context);
  let history = useHistory();

  const dashboardDetail = history?.location?.state?.isDashboard;

  const dispatch = useDispatch();
  // const isAuthenticated =
  //   authState.authInfo !== null && authState.authInfo !== undefined;

  const invalidSession = authState.invalidSession;

  const paramsAdminOrgId =
    adminRouteMatch && adminRouteMatch.params
      ? adminRouteMatch.params.orgId
      : null;

  const paramsDevOrgId =
    devRouteMatch && devRouteMatch.params ? devRouteMatch.params.orgId : null;

  const paramsComplianceOrgId =
    complianceRouteMatch && complianceRouteMatch.params
      ? complianceRouteMatch.params.orgId
      : null;

  const paramsITAdminOrgId =
    ITAdminRouteMatch && ITAdminRouteMatch.params
      ? ITAdminRouteMatch.params.orgId
      : null;

  const paramsOrgId = paramsAdminOrgId
    ? paramsAdminOrgId
    : paramsDevOrgId
    ? paramsDevOrgId
    : paramsComplianceOrgId
    ? paramsComplianceOrgId
    : paramsITAdminOrgId;

  const paramsAffiliateId =
    complianceRouteMatch && complianceRouteMatch.params
      ? complianceRouteMatch.params.affiliateId
      : devRouteMatch && 'DEV';

  const userLayoutType = adminRouteMatch
    ? 'admin'
    : devRouteMatch || complianceRouteMatch
    ? 'dev'
    : null;

  const onLogout = () => {
    dispatch(actions.logout());

    keycloak.logout().then(() => {});
  };

  const onLogin = () => {
    keycloak.login();
  };

  const onCloseSetupOrg = useCallback(
    triggerUpdate => {
      dispatch(actions.hideSetupOrganization(triggerUpdate));
    },
    [dispatch]
  );

  const onCloseManageOrgAlerts = useCallback(
    triggerUpdate => {
      dispatch(actions.hideManageAlerts(triggerUpdate));
    },
    [dispatch]
  );

  const onCloseSaveApplication = useCallback(
    ({ triggerUpdate }) => {
      dispatch(actions.hideSaveApplication(triggerUpdate));
      if (triggerUpdate) {
        const isMphAdmin = JSON.parse(sessionStorage.getItem('MPH_ADMIN'));
        dispatch(
          actions.fetchApplications({
            _orgId: orgId,
            offset: 0,
            limit: 10,
            search: '',
            sandboxStatus: isMphAdmin ? 'Approved' : sandboxStatus,
            productionStatus: '',
          })
        );
      }
      dispatch({ type: 'CLOSE_FLYOUT' });
    },
    [orgId, dispatch]
  );

  useEffect(() => {
    setOrgId(paramsOrgId);
    if (paramsOrgId) {
      dispatch(actions.fetchSelectedOrganization(paramsOrgId));
    }
  }, [paramsOrgId, dispatch]);

  useEffect(() => {
    if (isAuthenticated && keycloak) {
      dispatch(actions.setAuthentication(keycloak));

      keycloak.onAuthRefreshSuccess = () => {
        dispatch(updateAuthentication(keycloak));
      };

      keycloak.onAuthRefreshError = () => {
        dispatch(actions.logout());
        keycloak.logout();
      };
    }
  }, [isAuthenticated, dispatch, keycloak]);

  if (isAuthenticated && loadingUserInfo) {
    return <LinearProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >

        <AxiosInterceptor>
        <GlobalStyles />
        <Layout
          isDashboard={false}
          isAuthenticated={isAuthenticated}
          onLogout={onLogout}
          orgId={orgId}
          affiliateId={paramsAffiliateId}
          invalidSession={invalidSession}
          userLayoutType={userLayoutType}
          history={history}
          dashboardDetail={dashboardDetail}
          {...otherProps}
        >
          <RootRoute isAuthenticated={isAuthenticated} onLogin={onLogin} />

          {isAuthenticated && (
            <>
              <NewOrgSlideout
                open={slideoutsStateContext.organization.show}
                orgId={slideoutsStateContext.organization.orgId}
                isAddingNew={slideoutsStateContext.organization.orgId === null}
                onToggle={onCloseSetupOrg}
              />
              <ManageAlertsSlideout
                open={slideoutsStateContext.alerts.show}
                orgId={slideoutsStateContext.alerts.orgId}
                onToggle={onCloseManageOrgAlerts}
              />
              <SaveApplicationFlyout
                open={slideoutsStateContext.application.show}
                orgId={slideoutsStateContext.application.orgId}
                applicationData={slideoutsStateContext.application.data}
                affiliateId={slideoutsStateContext.application.affiliateId}
                onToggle={onCloseSaveApplication}
                isViewMode={slideoutsStateContext.application.isViewMode}
                userId={userInfo.userId}
              />
            </>
          )}
        </Layout>
        </AxiosInterceptor>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
