import BaseApiService from './BaseApiService';

function _getAllAlerts(orgId) {
  return BaseApiService.get(`/organization/${orgId}/alert`);
}

const AlertsApi = {
  getAllAlerts: _getAllAlerts,
};

export default AlertsApi;
