import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import HelloUserIcon from '../../../assets/hellousericon.svg';
import { useSelector } from 'react-redux';
import { convertStrToTitleCase } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  nameContainer: {
    display: 'flex',
    minHeight: '85vh',
    maxHeight: '85vh',
    marginTop: '20px',
    width: '100%',
  },
  avatar: {
    backgroundColor: '#FFFFFF',
    marginRight: 8,
    fontSize: '18px',
    height: '55px',
    width: '55px',
    fontWeight: 600,
    border: '1px solid #E0E2D4',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },

  root: {
    flexGrow: 1,
    minHeight: '85vh',
    maxHeight: '85vh',
  },
  paper: {
    padding: theme.spacing(1.8),
    borderRadius: '8px',
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
    width: '100%',
    marginTop: '20px',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  componentWrapper: {
    fontWeight: 600,
    wordWrap: 'break-word',
    marginTop: '4px',
    marginRight: '8px',
  },
}));

const UserDetail = () => {
  const classes = useStyles();
  const { info: userInfo } = useSelector(state => state.userInfo);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
          }}
        >
          <Avatar className={classes.avatar}>
            <img src={HelloUserIcon} alt="helloicon" />{' '}
          </Avatar>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                Hello, {convertStrToTitleCase(userInfo.firstName)}{' '}
                {convertStrToTitleCase(userInfo.lastName)}
              </Typography>{' '}
            </div>
            <span>Let's get started</span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserDetail;
