import HomeIcon from 'components/icons/HomeIcon';
import ApplicationsIcon from 'components/icons/ApplicationsIcon';
import MembersIcon from 'components/icons/MembersIcon';
import RulesIcon from 'components/icons/RulesIcon';
import UsersIcon from 'components/icons/UsersIcon';
import ApiOverviewIcon from 'components/icons/ApiOverviewIcon';
import SandboxIcon from 'components/icons/SandboxIcon';
import PricelistIcon from 'components/icons/PricelistIcon';
// import SettingsIcon from 'components/icons/SettingsIcons';
import LobIcon from 'components/icons/LobIcon';
import OrgIcon from 'components/icons/OrgIcon';
import DevelopersIcon from 'components/icons/DevelopersIcon';
import { RESOURCE_NAMES } from 'shared/constants';
import CampaignsIcon from 'components/icons/CampaignsIcon';
import ConfigurationsIcon from 'components/icons/ConfiguraitonsIcon';
import AssessmentIcon from 'components/icons/AssessmentIcon';

const rootItems = [];

const publicOrgItems = [];

const restrictedAdminItems = [
  {
    href: '/detail',
    icon: OrgIcon,
    title: 'Organization',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["all"],
    featureName:'Manage organization',
    subFeatureNames:['Organization Details','Affiliate'],
    shouldCheckForSubFeaturePermissions:true
  },
  {
    href: '/manage-lobs',
    icon: LobIcon,
    title: 'LOBs',
    resourceName: RESOURCE_NAMES.ORG_LOBS,
    accessLevel: 'Admin',
    solutionId:["all"],
    featureName:'Manage organization',
    subFeatureNames:['LOB'],
    shouldCheckForSubFeaturePermissions:true
  },
  // {
  //   href: '/manage-users',
  //   icon: UsersIcon,
  //   title: 'Users',
  //   resourceName: RESOURCE_NAMES.ORG_USERS,
  //   accessLevel: 'superAdmin',
  //   solutionId:["all"]
  // },
  // {
  //   href: '/external/manage-users',
  //   icon: DevelopersIcon,
  //   title: 'Third party developers',
  //   resourceName: RESOURCE_NAMES.ORG_REPORTS,
  //   accessLevel: 'superAdmin',
  //   solutionId:["all"]
  // },
  {
    href: '/members',
    icon: MembersIcon,
    title: 'Members',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    featureName:'Members',
    solutionId: ['frontdoor','engage'],
    restrictUser:"Content manager",
    subFeatureNames:['Members'],
    shouldCheckForSubFeaturePermissions:true
  },
  {
    href: '/campaigns',
    icon: CampaignsIcon,
    title: 'Campaigns',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["comply","acquire","engage",'frontdoor'],
    restrictUser:"Customer support representative",
    featureName:'Campaigns',
    subFeatureNames:['Cohorts','Campaigns'],
    shouldCheckForSubFeaturePermissions:true
  },
  {
    icon: AssessmentIcon,
    title: 'Assessments & CarePlans',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    restrictUser:"Customer support representative",
    solutionId:["comply","acquire","engage",'frontdoor'],
    featureName:'Assessments',
    subFeatureNames:['All Assessments','Question bank'],
    shouldCheckForSubFeaturePermissions:true,
    subMenu: [
      {
        href: '/allAssessments/',
        title: 'All assessments',
        resourceName: RESOURCE_NAMES.ORG_DETAILS,
        accessLevel: 'Admin',
        subFeatureName:'All Assessments'
      },
      {
        href: '/allCareplans/',
        title: 'Care Plans Bank',
        resourceName: RESOURCE_NAMES.ORG_DETAILS,
        accessLevel: 'Admin',
        subFeatureName:'All Assessments'
      },
      {
        href: '/assessment/',
        title: 'Question bank',
        resourceName: RESOURCE_NAMES.ORG_DETAILS,
        accessLevel: 'Admin',
        subFeatureName:'Question bank'
      },
    ],
  },
  {
    href: '/cmde',
    icon: MembersIcon,
    title: 'CMDE',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["xchange",'frontdoor'],
    restrictUser:"Content manager",
    featureName:'CMDE',
    subFeatureNames:['Download/Export Records','Initiate consent','Members'],
    shouldCheckForSubFeaturePermissions:true
  },
  {
    href: '/rules',
    icon: RulesIcon,
    title: 'Rules engine',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["comply","acquire","engage",'frontdoor'],
    restrictUser:"Customer support representative",
    featureName:'Rules engine',
    subFeatureNames:['Criteria','Actions','Rules','Attributes'],
    shouldCheckForSubFeaturePermissions:true
  },
  {
    href: '/configurations',
    icon: ConfigurationsIcon,
    title: 'Configurations',
    featureName: 'Configurations',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId: ['frontdoor', 'engage'],
    subFeatureNames:['Configurations',"Logs","Support","Document upload","Reports","Images"],
    shouldCheckForSubFeaturePermissions:true,
  },
  {
    href: '/care_dashboard',
    icon: AssessmentIcon,
    title: 'Care dashboard',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    featureName: 'Care dashboard',
    restrictUser: 'Customer support representative',
    solutionId: ['comply', 'engage', 'frontdoor'],
    subFeatureNames:['Care dashboard'],
    shouldCheckForSubFeaturePermissions:true,
  },
  {
    href: '/dashboard',
    icon: HomeIcon,
    title: 'Dashboard',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["nexus","connect"]
  },
  {
    href: '/third-party-developer',
    icon: DevelopersIcon,
    title: 'Third party developers',
    resourceName: RESOURCE_NAMES.ORG_REPORTS,
    accessLevel: 'Admin',
    solutionId:["nexus","comply"],
    restrictUser:"Content manager",
    featureName:'Third party developer',
    subFeatureNames:['Third party developer'],
    shouldCheckForSubFeaturePermissions:true
  },
  {
    href: '/applications',
    icon: ApplicationsIcon,
    title: 'Applications',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["nexus","connect","comply"],
    featureName:'Developer portal',
    subFeatureNames:['Applications'],
    shouldCheckForSubFeaturePermissions:true,
  },
  {
    href: '/api-overview',
    icon: ApiOverviewIcon,
    title: 'Insights',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["nexus","connect","comply"],
    featureName:'Developer portal',
    subFeatureNames:['Insights'],
    shouldCheckForSubFeaturePermissions:true,
  },
  {
    href: '/sandbox',
    icon: SandboxIcon,
    title: 'Sandbox',
    resourceName: RESOURCE_NAMES.ORG_DETAILS,
    accessLevel: 'Admin',
    solutionId:["nexus","connect","comply"],
    featureName:'Developer portal',
    subFeatureNames:['Sandbox'],
    shouldCheckForSubFeaturePermissions:true,
  },
  // {
  //   href: '/documentation',
  //   icon: DocumentationIcon,
  //   title: 'Documentation',
  // },
];

const restrictedDevItems = [
  // {
  //   href: '/dashboard',
  //   icon: HomeIcon,
  //   title: 'Dashboard',
  //   resourceName: RESOURCE_NAMES.ORG_DETAILS,
  //   accessLevel: 'Dev',
  //   solutionId:["nexus","connect"]
  // },
  // {
  //   href: '/applications',
  //   icon: ApplicationsIcon,
  //   title: 'Applications',
  //   resourceName: RESOURCE_NAMES.ORG_DETAILS,
  //   accessLevel: 'Dev',
  //   solutionId:["nexus","connect"]
  // },
  // {
  //   href: '/members',
  //   icon: MembersIcon,
  //   title: 'CMDE',
  //   resourceName: RESOURCE_NAMES.MEMBERS,
  //   accessLevel: 'Dev',
  //   solutionId:["nexus","connect"]
  // },
  // {
  //   href: '/api-overview',
  //   icon: ApiOverviewIcon,
  //   title: 'Insights',
  //   resourceName: RESOURCE_NAMES.ORG_DETAILS,
  //   accessLevel: 'Dev',
  //   solutionId:["nexus","connect"]
  // },
  // {
  //   href: '/sandbox',
  //   icon: SandboxIcon,
  //   title: 'Sandbox',
  //   resourceName: RESOURCE_NAMES.ORG_DETAILS,
  //   accessLevel: 'Dev',
  //   solutionId:["nexus","connect"]
  // },
  // {
  //   href: '/price-list-summary',
  //   icon: PricelistIcon,
  //   title: 'Price list',
  //   resourceName: RESOURCE_NAMES.DEV_PRICELIST,
  //   accessLevel: 'Dev',
  //   solutionId:""
  // },
  // {
  //   href: '/documentation',
  //   icon: DocumentationIcon,
  //   title: 'Documentation',
  // },
  // {
  //   href: '/dev-settings',
  //   icon: SettingsIcon,
  //   title: 'Settings',
  //   resourceName: RESOURCE_NAMES.DEV_SETTINGS,
  // },
];

const NavMenuItems = {
  rootItems,
  restrictedAdminItems,
  publicOrgItems,
  restrictedDevItems,
};

export default NavMenuItems;
