import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

function ReadonlyField({
  label,
  value,
  isLink,
  FieldIcon,
  containerStyle = {},
  isLabelBold,
  isValueBold,
  customLabelStyle,
  listValues,
}) {
  const classes = useStyles();
  const arrayValue = value;
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', ...containerStyle }}
    >
      {customLabelStyle ? (
        <Typography className={customLabelStyle}>{label}</Typography>
      ) : (
        <div
          style={{
            paddingBottom: 5,
            color: '#828282',
            fontSize: 14,
            fontWeight: isLabelBold ? '600' : 'none',
          }}
        >
          {label}
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {FieldIcon ? <FieldIcon /> : null}
        <Typography
          component="div"
          style={{
            opacity: 0.87,
            wordBreak: 'break-word',
            fontWeight: isValueBold ? '600' : 'none',
          }}
          className={isLink ? classes.link : null}
        >
          {isLink ? (
            listValues ? (
              value?.map(v => {
                if (v?.includes('https://')) {
                  return (
                    <Link
                      href={v}
                      style={{ marginRight: '10px' }}
                      target="_blank"
                      rel="noopener"
                    >
                      {v}
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      href={`//${v}`}
                      style={{ marginRight: '10px' }}
                      target="_blank"
                      rel="noopener"
                    >
                      {v}
                    </Link>
                  );
                }
              })
            ) : value?.includes('https://') ? (
              <Link href={value} target="_blank" rel="noopener">
                {value}
              </Link>
            ) : (
              <Link href={`//${value}`} target="_blank" rel="noopener">
                {value}
              </Link>
            )
          ) : (
            value
          )}
        </Typography>
      </div>
    </div>
  );
}

export default ReadonlyField;
