import {
  makeStyles,
  Paper,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import HistoryIcon from '@material-ui/icons/History';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { convertToSentenceCase } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    // background: '#F5F7F8',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    fontSize: '12px',
    color: '#002534',
    height: '25px',
    width: '25px',
  },
}));

const VersionHistory = ({ isCriteria = false, historyDetails }) => {
  const classes = useStyles();


  const avatarInitials = name => {
    return name
      ?.split(' ')
      ?.map(item => item[0])
      ?.join('');
  };

  return (
    <DrawerBoxComponent
    // style={{ width: isCriteria && '70%' }}
    >
      <Paper
        elevation={0}
        className={classes.paper}
        style={{ background: !isCriteria && '#F5F7F8' }}>
        <Box style={{ display: 'flex' }}>
          <Typography
            variant="h5"
            data-testid="version-history-title"
            style={{ marginBottom: '15px', fontWeight: 600 }}>
            Version history
          </Typography>
        </Box>
        <Paper
          elevation={0}
          style={{
            borderRadius: '8px',
            border: '1px solid #E4E7EC',
            background: '#E4E7EC',
          }}>
          {historyDetails.length ? (
            <Box
            // data-testid="orglist-data"
            // style={{
            //   display: 'flex',
            //   justifyContent: 'start',
            //   flexDirection: 'column',
            //   padding: '16px',
            // }}
            >
              {historyDetails?.map((item, index) =>
                item.version !== 1 ? (
                  (item.versionHistory !== null && item.versionHistory.length !== 0) &&
                  item?.versionHistory?.[0].field !== '' ? (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <HistoryIcon
                            fontSize="small"
                            style={{ fontWeight: 400 }}
                          />
                          <Typography
                            style={{ marginLeft: '5px', fontWeight: 400 }}
                            data-testid="created">
                            {item?.version === 1 ? 'Created on' : 'Updated on'}{' '}
                            {item?.version === 1
                              ? moment(item?.createdAt).format('MMM DD, YYYY')
                              : moment(item?.updatedAt).format('MMM DD, YYYY')}
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="h5"
                            style={{ marginLeft: '24px', marginTop: '4px' }}>
                            <Typography className={classes.fieldValue}>
                              <div className={classes.nameContainer}>
                                <Avatar
                                  className={classes.avatar}
                                  data-testid="avatar">
                                  {item?.version === 1
                                    ? avatarInitials(item?.createdBy)
                                    : avatarInitials(item?.updatedBy)}
                                </Avatar>
                                <div style={{ fontWeight: 400 }}>
                                  {item?.version === 1
                                    ? item?.createdBy
                                    : item?.updatedBy}
                                </div>
                              </div>
                            </Typography>
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            margin: '24px 0',
                            borderRadius: '16px',
                            backgroundColor: '#F5F7F8',
                            boxShadow: 'none',
                            p: '8px',
                            width: '100%',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between',
                              padding: '16px',
                            }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h5">Field</Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h5">Old value</Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h5">New value</Typography>
                            </Box>
                          </Box>
                          <hr style={{ border: '0.5px solid #E4E7EC' }} />
                          {item?.versionHistory?.map((val, index, arr) => (
                            <Box
                              sx={{
                                display: 'flex',
                                wrap: 'flex-wrap',
                                padding: '16px',
                                justifyContent: 'space-between',
                                ...(index !== arr.length - 1 && {
                                  borderBottom: '1px solid #E4E7EC',
                                }),
                                width: '100%',
                              }}>
                              <Box component="span" sx={{ flex: 1 }}>
                                {val?.field ? convertToSentenceCase(val.field) : '-'}
                              </Box>
                              <Box
                                component="span"
                                sx={{
                                  flex: 1,
                                  width:"33%",
                                  overflowWrap:"break-word"
                                }}>
                                {val?.oldValue || '-'}
                              </Box>
                              <Box
                                component="span"
                                sx={{
                                  flex: 1,
                                  // wrap: 'flex-wrap',
                                  width:"33%",
                                  overflowWrap:"break-word",
                                  paddingLeft:20,
                                }}>
                                {val?.newValue || '-'}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Box
                      style={{
                        backgroundColor: '#ffffff',
                        padding: '16px',
                        display: 'flex',
                        border: '1px solid #E4E7EC',
                      }}>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <HistoryIcon
                          fontSize="small"
                          style={{ fontWeight: 400 }}
                        />
                        <Typography
                          style={{ marginLeft: '5px', fontWeight: 400 }}
                          data-testid="created">
                          {item?.version === 1 ? 'Created on' : 'Updated on'}{' '}
                          {item?.version === 1
                            ? moment(item?.createdAt).format('MMM DD, YYYY')
                            : moment(item?.updatedAt).format('MMM DD, YYYY')}
                        </Typography>
                      </Box>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="h5"
                          style={{ marginLeft: '24px' }}>
                          <Typography className={classes.fieldValue}>
                            <div className={classes.nameContainer}>
                              <Avatar
                                className={classes.avatar}
                                data-testid="avatar">
                                {item?.version === 1
                                  ? avatarInitials(item?.createdBy)
                                  : avatarInitials(item?.updatedBy)}
                              </Avatar>
                              <div style={{ fontWeight: 400 }}>
                                {item?.version === 1
                                  ? item?.createdBy
                                  : item?.updatedBy}
                              </div>
                            </div>
                          </Typography>
                        </Typography>
                      </Box>

                      {(item.versionHistory !== null && item.versionHistory.length !== 0) && item.versionHistory?.[0].message.includes('criteria') && 
                      <Box style={{display: 'flex', alignItems: 'center', marginLeft: '40px'}}>
                        <Typography style={{ color: '#667085' }}>
                          {item?.versionHistory?.[0]?.message}
                        </Typography>
                      </Box>
                      }
                    </Box>
                  )
                ) : (
                  <Box
                    style={{
                      backgroundColor: '#ffffff',
                      padding: '16px',
                      display: 'flex',
                      border: '1px solid #E4E7EC',
                    }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <HistoryIcon
                        fontSize="small"
                        style={{ fontWeight: 400 }}
                      />
                      <Typography
                        style={{ marginLeft: '5px', fontWeight: 400 }}
                        data-testid="created">
                        {item?.version === 1 ? 'Created on' : 'Updated on'}{' '}
                        {item?.version === 1
                          ? moment(item?.createdAt).format('MMM DD, YYYY')
                          : moment(item?.updatedAt).format('MMM DD, YYYY')}
                      </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="h5"
                        style={{ marginLeft: '24px', marginTop: '4px' }}>
                        <Typography className={classes.fieldValue}>
                          <div className={classes.nameContainer}>
                            <Avatar
                              className={classes.avatar}
                              data-testid="avatar">
                              {item?.version === 1
                                ? avatarInitials(item?.createdBy)
                                : avatarInitials(item?.updatedBy)}
                            </Avatar>
                            <div style={{ fontWeight: 400 }}>
                              {item?.version === 1
                                ? item?.createdBy
                                : item?.updatedBy}
                            </div>
                          </div>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                )
              )}
              {/* {historyDetails?.map((item, index) => (
                <Box style={{ marginBottom: '25px' }}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <HistoryIcon fontSize="small" style={{ fontWeight: 400 }} />
                    <Typography
                      style={{ marginLeft: '5px', fontWeight: 400 }}
                      data-testid="created">
                      {item.version === 1 ? 'Created on' : 'Updated on'}{' '}
                      {item.version === 1
                        ? moment(item.createdAt).format('MMM DD, YYYY')
                        : moment(item.updatedAt).format('MMM DD, YYYY')}
                    </Typography>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="h5"
                      style={{ marginLeft: '24px', marginTop: '4px' }}>
                      <Typography className={classes.fieldValue}>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            data-testid="avatar">
                            {item.version === 1
                              ? avatarInitials(item.createdBy)
                              : avatarInitials(item.updatedBy)}
                          </Avatar>
                          <div style={{ fontWeight: 400 }}>
                            {item.version === 1
                              ? item.createdBy
                              : item.updatedBy}
                          </div>
                        </div>
                      </Typography>
                    </Typography>
                    {item.version !== 1 && (
                      <Typography
                        // variant="h6"
                        style={{ marginLeft: '24px', marginTop: '4px' }}>
                        Fields updated:{' '}
                        {historyDetails[index].versionHistory
                          ?.map(item => item.field)
                          .join(', ')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))} */}
            </Box>
          ) : null}
        </Paper>
      </Paper>
    </DrawerBoxComponent>
  );
};

export default VersionHistory;
