import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M20.3174 14.7451C22.04 14.7451 23.3799 13.3574 23.3799 11.6826C23.3799 9.95996 22.04 8.62012 20.3174 8.62012C18.6426 8.62012 17.2549 9.95996 17.2549 11.6826C17.2549 13.3574 18.6426 14.7451 20.3174 14.7451ZM12.1826 14.7451C13.8574 14.7451 15.1973 13.3574 15.1973 11.6826C15.1973 9.95996 13.8574 8.62012 12.1826 8.62012C10.46 8.62012 9.12012 9.95996 9.12012 11.6826C9.12012 13.3574 10.46 14.7451 12.1826 14.7451ZM12.1826 16.7549C9.79004 16.7549 5.00488 17.9512 5.00488 20.3438V21.875C5.00488 22.4492 5.4834 22.8799 6.05762 22.8799H18.3076C18.834 22.8799 19.3125 22.4492 19.3125 21.875V20.3438C19.3125 17.9512 14.5273 16.7549 12.1826 16.7549ZM20.3174 16.7549C20.0303 16.7549 19.6953 16.8027 19.3604 16.8027C19.3604 16.8506 19.3604 16.8506 19.3604 16.8506C20.5566 17.7119 21.3701 18.8604 21.3701 20.3438V21.875C21.3701 22.21 21.2744 22.5928 21.1787 22.8799H26.4424C27.0166 22.8799 27.4951 22.4492 27.4951 21.875V20.3438C27.4951 17.9512 22.71 16.7549 20.3174 16.7549Z" />
    </SvgIcon>
  );
};

export default Icon;
