import { memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Box } from '@material-ui/core';

const ActionsCellTemplate = memo(function ActionsCellTemplate({
  data,
  onEdit,
  onDelete,
  onDownload,
  allowEdit,
  allowDelete = true,
  allowDownload,
}) {
  const onClickHandler = (event, action) => {
    event.preventDefault();

    if (action === 'delete') {
      onDelete(data);
    } else if (action === 'edit') {
      onEdit(data);
    } else if (action === 'download') {
      onDownload(data);
    }
  };

  return (
    <Box style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px'}}>
      {allowEdit && (
        <IconButton
          size="small"
          style={{ marginRight: 10 }}
          onClick={event => onClickHandler(event, 'edit')}>
          <EditIcon />
        </IconButton>
      )}

      {allowDownload && (
        <IconButton
          size = "small"
          onClick={event => onClickHandler(event, 'download')}>
            <DownloadIcon />
        </IconButton>
      )}

      {allowDelete && (
        <IconButton
          size="small"
          onClick={event => onClickHandler(event, 'delete')}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
});

export default ActionsCellTemplate;
