import { makeStyles } from '@material-ui/core';
import Page from 'components/Page';
import * as React from 'react';
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
} from '@material-ui/core';
import clsx from 'classnames';
// import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Avatar from '@material-ui/core/Avatar';
import Card from './Card';
import LoadingOrgs from './LoadingOrgs';
import { useSelector, useDispatch } from 'react-redux';
import PartnerApi from 'services/api/PartnerApi';
import {
  PrimaryButton,
  SearchTextField,
  // Card as CustomCard,
} from 'components/widgets';
import { useEffect, useState, useCallback } from 'react';
import * as actions from 'store/actions';
import { useUserPermissions } from 'utils/userPermissions';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AttributeMainPage from 'containers/Rules/tab/AttributeTab/AttributeMainPage';
import RequestsPage from 'containers/Requests/RequestsTab/RequestsPage';
import ApprovalPendingPage from 'containers/DeveloperSignup/ApprovalPendingPage';
// import { database } from 'faker/locale/ar';
// import CodeIcon from '@material-ui/icons/Code';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    // backgroundColor: '#E5E5E5',
    position: 'relative',
    height: '80vh',
  },
  content: {
    padding: theme.spacing(2),
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 0,

    // padding: theme.spacing(6, 2),
  },
  notApprovedCard: {
    // borderColor: theme.palette.warning.main,
  },
  item: {
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
  },
  notApprovedItem: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
  smallItem: {
    fontSize: 12,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    height: '25px',
    width: '25px',
  },
  secondRowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'pink',
  },
  searchContainer: { paddingBottom: 30 },
  secondRow: {
    display: 'flex',
    alignItems: 'center',
  },
  paginationContainer: {
    // [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    // position: 'relative',
    // },
    // position: 'absolute',
    paddingTop: 40,
    // bottom: -50,
    // paddingTop: 5,
    // paddingBottom: 2,
    // left: '50%',
    // transform: 'translate(-50%, 0)',
  },
}));

function OrgItem({ org, history }) {
  const classes = useStyles();
  const onClickHandler = orgId => {
    sessionStorage.setItem("orgId",orgId);
    history.push(`/admin/organization/${orgId}/detail`);
  };

  return (
    <Card
      className={clsx(classes.card, {
        // [classes.notApprovedCard]: org.isApproved !== 1,
      })}
      onClick={() => onClickHandler(org.id)}
    >
      <div>
        <div className={classes.nameContainer}>
          <Avatar
            className={classes.avatar}
            src="https://mpowered-assets.s3.us-east-2.amazonaws.com/services/claims/claims-mob.png"
          />
          <div
            className={clsx(classes.name, classes.item)}
          >{`${org.name}`}</div>
        </div>

        {/* <div className={classes.secondRowContainer}>
          <div className={classes.secondRow}>
            <span className={classes.item}>{`D&B: ${org.db}`}</span>
            <span className={classes.item}>{`EIN: ${org.ein}`}</span>
          </div>
          <div className={classes.secondRow}>
            <span
              className={clsx(
                classes.item,
                classes.smallItem
              )}>{`Created on: ${moment(org.createdAt).format(
              'MMM DD, YYYY, h:mm a'
            )}`}</span>
            <span
              className={clsx(
                classes.item,
                classes.smallItem
              )}>{`Last Updated: ${moment(org.updatedAt).format(
              'MMM DD, YYYY, h:mm a'
            )}`}</span>
          </div>
        </div> */}
        {/* {org.isApproved !== 1 && (
          <div
            className={clsx(
              classes.item,
              classes.smallItem,
              classes.notApprovedItem
            )}>
            Please Note: This organization is not yet verified
          </div>
        )} */}
      </div>
      <div>
        <ChevronRightIcon />
      </div>
    </Card>
  );
}

// function AffiliateItem({ org, history }) {
//   const classes = useStyles();
//   const onClickHandler = (orgId, affiliateId) => {
//     history.push(
//       `/dev/organization/${orgId}/affiliate/${affiliateId}/dashboard`
//     );
//   };

//   return (
//     <Card
//       className={clsx(classes.card, {
//         // [classes.notApprovedCard]: org.isActive !== 1,
//       })}
//       onClick={() => onClickHandler(org.orgId, org.id)}>
//       <div>
//         <div className={classes.nameContainer}>
//           {/* <CodeIcon /> */}
//           <div
//             className={clsx(classes.name, classes.item)}>{`${org.name}`}</div>
//         </div>

//         {/* <div className={classes.secondRowContainer}>
//           <div className={classes.secondRow}>
//             <span className={classes.item}>{`D&B: ${org.groupId}`}</span>
//             <span className={classes.item}>{`EIN: ${org.ein}`}</span>
//           </div>
//           <div className={classes.secondRow}>
//             <span
//               className={clsx(
//                 classes.item,
//                 classes.smallItem
//               )}>{`Created on: ${moment(org.createdAt).format(
//               'MMM DD, YYYY, h:mm a'
//             )}`}</span>
//             <span
//               className={clsx(
//                 classes.item,
//                 classes.smallItem
//               )}>{`Last Updated: ${moment(org.updatedAt).format(
//               'MMM DD, YYYY, h:mm a'
//             )}`}</span>
//           </div>
//         </div>
//         {org.isActive !== 1 && (
//           <div
//             className={clsx(
//               classes.item,
//               classes.smallItem,
//               classes.notApprovedItem
//             )}>
//             Please Note: This affiliate is not yet verified
//           </div>
//         )} */}
//       </div>
//       <div>
//         <ChevronRightIcon />
//       </div>
//     </Card>
//   );
// }

function RedirectToDashboard({ history, orgId, affiliateId }) {
  const [data, setData] = useState(null);
  // const getMenuItems = useCallback(() => {
  //   async function getMenu() {
  //     try {
  //       const details = await PartnerApi.getMenuItems(orgId);
  //       setData(details);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  //   getMenu();
  // }, [orgId]);

  // useEffect(() => getMenuItems(), [getMenuItems]);

  let href = '/dashboard';

  if (data) {
    if (data.length !== 0) {
      href = data.filter(item => item.isDefaultTab === true)[0].href;
    }
  }
  useEffect(() => {
    if (orgId) {
      // if (data) {
        // if (affiliateId === 'DEV') {
          if (history?.location?.state?.isDashboard) {
            history.push(`/organization/${orgId}${href}`);
          } else {
            history.push(`/dashboard`);
          }
        // } else {
        //   history.push(
        //     `/organization/${orgId}/affiliate/${affiliateId}${href}`
        //   );
        // }
      }
    // }
  }, [orgId, affiliateId, history, href, data]);

  return null;
}

function RedirectToOrgDetails({ history, orgId }) {
  useEffect(() => {
    if (orgId) {
      if (history?.location?.state?.isDashboard) {
        history.push(`/organization/${orgId}/detail`);
      } else {
        history.push(`/dashboard`);
      }
      // history.push(`/organization/${orgId}/detail`);
    }
  }, [orgId, history]);

  return null;
}

function UserAffiliates({
  history,
  allAffiliates = [],
  loading,
  loadingAffiliates,
}) {
  if (loading || loadingAffiliates) {
    return <LoadingOrgs />;
  }
  return (
    <>
      {allAffiliates === 'pending' ||
      allAffiliates === 'Revoked' ||
      allAffiliates === 'Expired' ||
      allAffiliates === 'Declined' ? (
        <ApprovalPendingPage status={allAffiliates} />
      ) : (
        <>
          {allAffiliates?.length > 0 ? (
            <>
              <RedirectToDashboard
                history={history}
                orgId={allAffiliates[0].orgId}
                affiliateId={allAffiliates[0].id}
              />
            </>
          ) : (
            <Box padding={3}>
              You are not part of any affiliate for developer access
            </Box>
          )}
        </>
      )}
    </>
  );
}

// const Pagination = ({ datalength, postPerPage, PageNavigation }) => {
//   const pageNumbers = Math.ceil(datalength / postPerPage);
//   const pageArray = [];
//   for (let i = 1; i <= pageNumbers; i++) {
//     pageArray.push(i);
//   }
//   return pageArray.map(pagenumber => (
//     <span
//       style={{
//         // background: '#838383',
//         borderRadius: 6,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         border: '1px solid black',
//         padding: '4px 10px 4px 10px',
//         cursor: 'pointer',
//         marginRight: 4,
//       }}
//       onClick={e => PageNavigation(pagenumber)}
//       key={pagenumber}>
//       {pagenumber}
//     </span>
//   ));
// };

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TabHeading = ['Organizations', 'Attributes', 'Requests'];
const HomePage = ({ getTabIndexClicked, title, history }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilterText('');
  };
  const styles = {
    TabContainer: {
      backgroundColor: '#F5F5F5',
      position: 'relative',
      marginLeft: 32,
    },

    TabHeading: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
    },

    cardContainer: {
      minHeight: '100%',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      paddingTop: 40,
    },
    cardWrapper: { marginBottom: 32, marginRight: 32 },
    TabsWrapper: {
      borderBottom: '2px solid #D9D9D9',
      height: 62,
    },
  };
  const [filterText, setFilterText] = useState('');
  const classes = useStyles();

  const organizations = useSelector(state => state.organizations);
  const dispatch = useDispatch();
  const {
    checkSuperAdminPermission,
    checkITAdminPermission,
    checkITAdminPermissionStatus,
  } = useUserPermissions();

  const isSuperAdmin = checkSuperAdminPermission();
  const isITAdmin = checkITAdminPermission();
  const ITAdminStatus = checkITAdminPermissionStatus();
  const { details } = useSelector(state => state.selectedOrganization);
  
  const {
    data = [],
    allAffiliates = [],
    loading,
    loadingAffiliates,
  } = organizations;

  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = 20;

  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;

  const totalpost = data?.slice(firstIndex, lastIndex);
  const pageNumbers = Math.ceil(data?.length / postPerPage);

  // const PageNavigation = pagenumber => {
  //   setCurrentPage(pagenumber);
  // };

  // const searchedValue = keyfinder => {
  //   setFilterText(keyfinder);
  //   if (keyfinder !== '') {
  //     const searchedval = data.filter(
  //       fItem =>
  //         fItem.name.toLowerCase().trim().indexOf(filterText.toLowerCase()) !==
  //         -1
  //     );
  //     if (searchedval.length > 0) {
  //       SetSearchVal(searchedval);
  //     } else {
  //       SetSearchVal(null);
  //     }
  //   } else {
  //     SetSearchVal(null);
  //   }
  // };

  /////////////////////////////////////////////////////////////////
  const onSetupNewOrgClick = () => {
    dispatch(actions.showSetupOrganization());
    dispatch(actions.fetchLocationStates('US'));
    dispatch(actions.fetchAddressTypes());
  };

  if(isSuperAdmin)
  {
    sessionStorage.setItem("orgId","");
  }

  return (
    <Page
      data-testid="EmptyPage"
      className={classes.root}
      title={title}
      style={{ height: '100%', padding: 0 }}
    >
      {isSuperAdmin ? (
        <>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            style={styles.TabsWrapper}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className={classes.tabRoot}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#00A0DF',
                  height: 4,
                  borderRadius: '100px 100px 0px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: '20px',
                  width: value === 0 ? '135px' : value === 1 ? '100px' : '90px',
                },
              }}
              style={{
                paddingTop: 15,
                marginLeft: 0,
                paddingRight: 0,
                margin: 0,
              }}
            >
              {TabHeading &&
                TabHeading.length > 0 &&
                TabHeading.map((heading, index) => (
                  <Tab
                    style={{ minWidth: 'fit-content', padding: '0px 30px' }}
                    key={index}
                    onClick={() =>
                      getTabIndexClicked && getTabIndexClicked(index)
                    }
                    label={<span style={styles.TabHeading}>{heading}</span>}
                    {...a11yProps(index)}
                  />
                ))}
            </Tabs>
          </Box>
          <Box style={{ width: '100%', height: '100%', marginTop: '40px' }}>
            {value === 0 && (
              <div className={classes.content}>
                {loading ? (
                  <LoadingOrgs />
                ) : (
                  <MuiCard
                    variant="outlined"
                    style={{ height: '100%', padding: 0 }}
                  >
                    <MuiCardContent style={{ height: '100%' }}>
                      <Grid container style={{ minHeight: '83.5vh' }}>
                        <Grid item xs={12}>
                          <div style={{ paddingBottom: 20 }}>
                            <b>Your organizations</b>
                          </div>
                        </Grid>
                        <Grid item xs={12} className={classes.searchContainer}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <div style={{ marginLeft: '8px' }}>
                                <SearchTextField
                                  label="Search organization"
                                  onChangeText={setFilterText}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginRight: '8px',
                                }}
                              >
                                <PrimaryButton
                                  style={{
                                    backgroundColor: '#fff',
                                    fontWeight: 'bold',
                                  }}
                                  variant="outlined"
                                  size="medium"
                                  onClick={onSetupNewOrgClick}
                                >
                                  Setup new organization
                                </PrimaryButton>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingBottom: 10, marginBottom: 5 }}
                        >
                          {data.length > 0 ? (
                            <div
                              style={{
                                minHeight: '55vh',
                                alignItems: 'space-between',
                              }}
                            >
                              {filterText.length > 0 ? (
                                data
                                  .filter(
                                    fItem =>
                                      fItem.name
                                        .toLowerCase()
                                        .indexOf(filterText.toLowerCase()) !==
                                      -1
                                  )
                                  .map(org => (
                                    <Box key={org.id}>
                                      <OrgItem org={org} history={history} />
                                    </Box>
                                  ))
                              ) : (
                                <>
                                  {totalpost.map(org => (
                                    <Box key={org.id}>
                                      <OrgItem org={org} history={history} />
                                    </Box>
                                  ))}
                                  <div className={classes.paginationContainer}>
                                    {/* <Pagination
                              datalength={data.length}
                              postPerPage={postPerPage}
                              PageNavigation={PageNavigation}
                            /> */}

                                    <Pagination
                                      count={pageNumbers}
                                      variant="outlined"
                                      color="primary"
                                      onChange={(event, value) =>
                                        setCurrentPage(value)
                                      }
                                      showFirstButton
                                      showLastButton
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div>
                              You don't have access to any organizations
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </MuiCardContent>
                  </MuiCard>
                )}
              </div>
            )}
            {value === 1 && (
              <AttributeMainPage isSuperAdmin={true} isITAdmin options={data} />
            )}
            {value === 2 && <RequestsPage isSuperAdmin={true} isITAdmin />}
          </Box>
        </>
      ) : isITAdmin ? (
        <div className={classes.content}>
          {ITAdminStatus === 'Approved' ? (
            <RedirectToOrgDetails history={history} orgId={details.id} />
          ) : (
            <ApprovalPendingPage status={ITAdminStatus} />
          )}
        </div>
      ) : (
        <div className={classes.content}>
          {/* <MuiCard variant="outlined"> */}
          {/* <MuiCardContent> */}
          <UserAffiliates
            history={history}
            allAffiliates={allAffiliates}
            loading={loading}
            loadingAffiliates={loadingAffiliates}
          />
          {/* </MuiCardContent> */}
          {/* </MuiCard> */}
        </div>
      )}
    </Page>
  );
};

export default HomePage;
