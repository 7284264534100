import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';
import { Box, Modal } from '@material-ui/core';
import { SELF_REPORTING_CONFIRMATION_POPUP_TEXT } from '../constants';
import colors from '../colors';

const styles = (theme) => ({
  confirmationPopUpContainer: {
    width: '100vw',
    height: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },
  confirmationPopUpWrapper: {
    width: 560,
    backgroundColor: 'white',
    borderRadius: 16,
    padding: '28px 24px 40px 30px',
    position: 'relative',
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: 24,
    height: 18,
    top: 28,
    right: 24,
    cursor: 'pointer',
  },
  heading: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: colors.BLACK_B500,
    marginTop: 16,
    display: 'block',
  },
  closeIcon: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    width: 24,
    height: 18,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 52,
  },
  yesButton: {
    backgroundColor: '#036FCB',
    color: 'white',
    width: 164,
    borderRadius: 40,
    fontSize: 18,
    height: 48,
  },
  noButton: {
    backgroundColor: 'white',
    border: '1px solid #A0A4A6',
    width: 164,
    height: 48,
    color: '#036FCB',
    borderRadius: 40,
    fontSize: 18,
    marginRight: 24,
  },
});

const ConfirmationModal = (props) => {
  const { classes, children, headerText, handleCancel, handleYes, isOpen, setIsOpen } = props;
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.confirmationPopUpContainer}>
        <div className={classes.confirmationPopUpWrapper}>
          <div className={classes.closeIconContainer}>
            <Close
              className={classes.popUpCloseIcon}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </div>
          <span className={classes.heading}>
            {headerText || SELF_REPORTING_CONFIRMATION_POPUP_TEXT.HEADING_TEXT}
          </span>
          <Box className={classes.btnContainer}>
            <button
              className={classes.noButton}
              onClick={() => setIsOpen(!isOpen)}
            >
            {SELF_REPORTING_CONFIRMATION_POPUP_TEXT.CANCEL_BUTTON_TEXT}
            </button>
            <button
              className={classes.yesButton}
              onClick={handleYes}
            >
              {SELF_REPORTING_CONFIRMATION_POPUP_TEXT.CONFIRMATION_BUTTON_TEXT}
            </button>
          </Box>
        </div>
      </div>
      {/* {children} */}
    </Modal>
  );
};

export default withStyles(styles, { withTheme: true })(ConfirmationModal);
