import React from 'react';
import { Grid, Box, Typography} from '@material-ui/core';
import { IconContainer } from 'components/IconContainer';

const MenuJSON = [
  {
    id: 1,
    icon: 'https://member-portal-resources.s3.amazonaws.com/affiliate2/AppConfig/DocumentUpload/Document+upload.svg',
    category: 'Document upload',
    route: '/documentUpload',
  },
  {
    id: 2,
    icon: 'https://member-portal-resources.s3.amazonaws.com/affiliate2/AppConfig/Support/Support.svg',
    category: 'Support',
    route: '/support',
  },
  {
    id: 3,
    icon: 'https://member-portal-resources.s3.amazonaws.com/affiliate2/AppConfig/Logs/Logs.svg',
    category: 'Logs',
    route: '/logs',
  },
  {
    id: 3,
    icon: 'https://member-portal-resources.s3.amazonaws.com/affiliate2/AppConfig/Dashboard/Dashboards.svg',
    category: 'Reports',
    route: '/reports',
  },
  {
    id: 4,
    icon: 'https://member-portal-resources.s3.amazonaws.com/affiliate2/AppConfig/Image/Image.svg',
    category: 'Images',
    route: '/images',
  },
];

const Configurations = () => {

    return (
      <Box style={{marginLeft: '40px', marginTop: '40px'}}>
        <Typography style={{fontSize: '24px', fontWeight: 600, marginBottom: '24px'}} >Configurations</Typography>

          <Grid
            container
            spacing={3}
            // style={{ justifyContent: 'center'}}
          >
            {MenuJSON.map(item => (
              <Grid item key={item.id}>
                <IconContainer
                  route={item.route}
                  imgUrl={item.icon}
                  caption={item.category}
                />
              </Grid>
            ))}
          </Grid>
      </Box>
    );
}

export default Configurations;