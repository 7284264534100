import { Box, makeStyles } from '@material-ui/core';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import React, { useState } from 'react';
import StepperCustom from 'components/widgets/StepperComponent/NewStepper';
import CreateAttributeOrganization from './CreateAttributeOrganization';
import CreateAttributeDetails from './CreateAttributeDetails';
import { AlertDialog } from 'components/widgets';
import { CLOSE_ALERT_DESCRIPTION } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
  },
  cancelButton: {
    fontSize: '16px',
    borderRadius: '40px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 30px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
}));

const ATTRIBUTE_STEPS = ['Add details', 'Map organizations'];

const AttributePage = ({
  options,
  open,
  handleCloseAttributeCreation,
  refetchAttributes,
  attributeDetails,
  setAttributeDetails,
  attributeOrgs,
  setAttributeOrgs,
  isEdit,
  attributeId,
  attributeTitle,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [closeAlert, setCloseAlert] = useState(false);
  const [editFlag, setEditFlag] = useState(true);
  const [alertFlag, setAlertFlag] = useState(true);
  const [attrType, setAttrType] = useState(attributeDetails?.type || 'FHIR');

  const handleClose = () => {
    if (!isEdit && activeStep === 1) {
      setCloseAlert(true);
    } else if (!editFlag) {
      setCloseAlert(true);
    } else if (!alertFlag) {
      setCloseAlert(true);
    } else {
      handleCloseAttributeCreation();
      setActiveStep(0);
    }
  };

  const closeConfirm = () => {
    handleCloseAttributeCreation();
    setActiveStep(0);
    setEditFlag(true);
    setAlertFlag(true);
  };

  const classes = useStyles();

  const StepperCustomComponent = () => (
    <StepperCustom
      activeStep={activeStep}
      steps={ATTRIBUTE_STEPS}
      style={{ paddingBottom: 0, paddingTop: 0 }}
      activeLabelStyle={classes.active}
    />
  );

  return (
    <Box>
      <FullScreenDialog
        open={open}
        handleClose={handleClose}
        title={isEdit ? 'Edit an attribute' : 'Create an attribute'}>
        {/* <Box sx={{ marginLeft: '20px', mt: '-10px' }}>
          Enter all the details to create a new query
        </Box> */}
        {activeStep === 0 && (
          <CreateAttributeDetails
            setActiveStep={setActiveStep}
            StepperCustomComponent={StepperCustomComponent}
            customStyles={classes}
            attributeDetails={attributeDetails}
            setAttributeDetails={setAttributeDetails}
            isEdit={isEdit}
            attributeTitle={attributeTitle}
            setEditFlag={setEditFlag}
            setAlertFlag={setAlertFlag}
            attrType={attrType}
            setAttrType={setAttrType}
          />
        )}

        {activeStep === 1 && (
          <CreateAttributeOrganization
            StepperCustomComponent={StepperCustomComponent}
            customStyles={classes}
            setActiveStep={setActiveStep}
            attributeDetails={attributeDetails}
            attributeOrgs={attributeOrgs}
            setAttributeOrgs={setAttributeOrgs}
            handleCloseAttributeCreation={handleCloseAttributeCreation}
            options={options}
            refetchAttributes={refetchAttributes}
            isEdit={isEdit}
            attributeId={attributeId}
            attrType={attrType}
            setAttrType={setAttrType}
          />
        )}
      </FullScreenDialog>
      {closeAlert && (
        <AlertDialog
          open={closeAlert}
          onClose={() => setCloseAlert(false)}
          onConfirm={closeConfirm}
          title="Alert"
          description={CLOSE_ALERT_DESCRIPTION}
        />
      )}
    </Box>
  );
};

export default AttributePage;
