import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  BackendSearchTextField,
  PageHeaderTitle,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import Cards from 'containers/Roles/Cards';
import RolesApi from 'services/api/RolesApiNew';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UserManageRole from './UserManageRole';
import PermissionForm from 'containers/ManageUsers/PermissionForm';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#D6EAF8',
    borderRadius: '16px',
    maxWidth: 551,
    width: '100%',
    marginLeft: '20px',
    marginTop: '10px',
  },
  btn: {
    textTransform: 'none',
    color: '#3498DB',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  avatar: {
    width: '32px',
    height: '32px',
    marginRight: '10px',
    padding: '10px',
  },
  solutionContainer: {
    marginTop: '4px',
    fontSize: '14px',
    lineHeight: '16px',
    marginLeft: '10px',
    color: 'gray',
  },
  subcontainer: {
    fontSize: '14px',
    lineHeight: '16px',
    color: 'gray',
    marginTop: '10px',
  },
}));

const styles = {
  TabContainer: {
    backgroundColor: '#F5F5F5',
    position: 'relative',
    marginLeft: 32,
  },

  TabHeading: {
    fontSize: 18,
    fontWeight: 600,
    width: '100%',
    textAlign: 'left',
    textTransform: 'capitalize',
    marginRight: '15px',
  },

  cardContainer: {
    minHeight: '100%',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    paddingTop: 40,
  },
  cardWrapper: { marginBottom: 32, marginRight: 32 },
  TabsWrapper: {
    borderBottom: '2px solid #D9D9D9',
    height: 62,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ManageRolePage = ({ getTabIndexClicked }) => {
  const classes = useStyles();
  const history = useHistory();
  const loadMore = useRef(null);
  const offset = useRef(0);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const TabHeading = ['Users', 'Permissions'];
  const [tabValue, setTabValue] = useState(
    history.location?.state?.selectTabIndex || 0
  );
  const [roleData, setRoleData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRoleValue, setSelectedRoleValue] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);

  const orgId = sessionStorage.getItem('orgId');
  const avatarInitials = name => {
    return name
      ?.split(' ')
      ?.map(item => item[0])
      ?.slice(0, 2)
      ?.join('');
  };

  const getAllRoles = async offset => {
    setLoading(true);
    await RolesApi.listRoles({ orgId, searchValue, offset })
      .then(res => {
        if (searchText || offset === 0) {
          setRoleData(res.data);
        } else {
          setRoleData(prev => [...prev, ...res.data]);
        }
        setTotalItems(res.totalItems);
        setOnloadData(true);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setOnloadData(true);
        console.error(err);
      });
  };

  useEffect(() => {
    getAllRoles(0);
  }, [searchText]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          offset.current += 1;
          totalItems > offset.current * 10 && getAllRoles(offset.current);
        }
      },
      { threshold: 1 }
    );

    if (loadMore.current) {
      observer.observe(loadMore.current);
    }

    return () => {
      if (loadMore.current) {
        observer.unobserve(loadMore.current);
      }
    };
  }, [loadMore.current]);

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    offset.current = 0;
    setSearchValue('');
    setSearchText('');
  };

  const onSearchClick = () => {
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const refetchData = () => {
    setRoleData([]);
    setIsInitialRender(true);
    setSearchValue('');
    setSearchText('');
    if (searchValue === '' && searchText === '') {
      getAllRoles(0);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const permissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'User role management',
    manageOrg: true,
  });

  let readAccess = permissions?.READ;
  let deleteAccess = permissions?.DELETE;

  return (
    <Box
      sx={{
        width: '95%',
        backgroundColor: '#F5F5F5',
        position: 'relative',
        marginLeft: 32,
        marginTop: '20px',
      }}
    >
      {selectedRoleValue ? (
        <>
          <IconButton
            style={{ color: '#3498DB' }}
            onClick={() => setSelectedRoleValue(false)}
          >
            <NavigateBeforeIcon />
            <Typography style={{ fontWeight: '600' }}>Back</Typography>
          </IconButton>
          <Paper elevation={0} className={classes.paper}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Avatar className={classes.avatar}>
                <Typography style={{ fontSize: '20px' }}>
                  {avatarInitials(selectedRoleValue?.roleName)}
                </Typography>
              </Avatar>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    variant="h3"
                    style={{ fontWeight: 600, fontSize: '24px' }}
                  >
                    {selectedRoleValue?.roleName}{' '}
                  </Typography>{' '}
                  {/* <span className={classes.solutionContainer}>1 solutions</span>{' '} */}
                  <span
                    className={classes.solutionContainer}
                  >{`${selectedRoleValue?.usersActive} person`}</span>{' '}
                </div>
              </div>
            </div>{' '}
            <div className={classes.subcontainer}>
              {selectedRoleValue?.description}
            </div>
          </Paper>
          {selectedRoleValue?.hasPermission ? (
            <>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: '100%',
                  marginLeft: '16px',
                }}
                style={styles.TabsWrapper}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className={classes.tabRoot}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#00A0DF',
                      height: 4,
                      borderRadius: '100px 100px 0px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                      marginLeft: '4px',
                      width:
                        tabValue === 0
                          ? '55px'
                          : tabValue === 1
                          ? '115px'
                          : tabValue === 2
                          ? '77px'
                          : '48px',
                    },
                  }}
                  style={{
                    paddingTop: 15,
                    marginLeft: 0,
                    paddingRight: 0,
                    margin: 0,
                  }}
                >
                  {TabHeading &&
                    TabHeading.length > 0 &&
                    TabHeading.map((heading, index) => (
                      <Tab
                        sx={{ width: '70px' }}
                        key={index}
                        onClick={() =>
                          getTabIndexClicked && getTabIndexClicked(index)
                        }
                        label={<span style={styles.TabHeading}>{heading}</span>}
                        {...a11yProps(index)}
                      />
                    ))}
                </Tabs>
              </Box>
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  marginTop: '40px',
                  marginLeft: '20px',
                }}
              >
                {tabValue === 0 && (
                  <UserManageRole
                    selectedRoleValue={selectedRoleValue}
                    isManageRole={true}
                  />
                )}
                {tabValue === 1 && (
                  <PermissionForm
                    isManageRole={true}
                    roleDescription={selectedRoleValue?.description}
                    roleName={selectedRoleValue?.roleName}
                    roleId={selectedRoleValue?.roleId}
                    setSelectedRoleValue={setSelectedRoleValue}
                    isView={selectedRoleValue?.isStatic || !permissions?.CREATE}
                    hideFooter={
                      selectedRoleValue?.isStatic || !permissions?.CREATE
                    }
                  />
                )}
              </Box>
            </>
          ) : (
            <Box>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: '28px',
                  marginBottom: '5px',
                  marginLeft: '24px',
                  marginTop: '20px',
                }}
              >
                Assign permissions to the role
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  marginBottom: '5px',
                  marginLeft: '24px',
                }}
              >
                No permission have been added for this role
              </Typography>
              <PermissionForm
                isManageRole={true}
                roleDescription={selectedRoleValue?.description}
                roleName={selectedRoleValue?.roleName}
                roleId={selectedRoleValue?.roleId}
                setSelectedRoleValue={setSelectedRoleValue}
                isView={selectedRoleValue?.isStatic || !permissions?.CREATE}
                hideFooter={selectedRoleValue?.isStatic || !permissions?.CREATE}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          {readAccess && (
            <div>
              <PageHeaderTitle>Manage roles</PageHeaderTitle>
              <Typography style={{ marginLeft: '18px', marginTop: '-10px' }}>
                Click on any of the below roles to view and edit the users and
                permissions
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '20px',
                  margin: 16,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '280px',
                    marginRight: '16px',
                    height: '40px',
                  }}
                >
                  <BackendSearchTextField
                    label="Search by role name"
                    onChangeText={onChangeText}
                    value={searchValue}
                    clearData={onClearData}
                    onSearchClick={onSearchClick}
                  />
                </div>
                <SecondaryButton
                  style={{ marginLeft: '-8px' }}
                  onClick={onSearchClick}
                  data-testid="search-rule"
                >
                  Search
                </SecondaryButton>
              </div>
              <div>
                <div style={{ marginTop: '-20px', marginLeft: '-10px' }}>
                  <Cards
                    isManagerole={true}
                    selectedRole={setSelectedRoleValue}
                    data={roleData}
                    loading={loading}
                    loadMore={loadMore}
                    isInitialRender={isInitialRender}
                    OnloadData={OnloadData}
                    refetchData={refetchData}
                    deleteAccess={deleteAccess}
                  />
                </div>
              </div>{' '}
            </div>
          )}
        </>
      )}{' '}
      <SimpleBackdrop open={loading} />
    </Box>
  );
};

export default ManageRolePage;
