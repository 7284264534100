import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 36 36" {...props}>
      <path d="M18 0.527344C8.32031 0.527344 0.527344 8.32031 0.527344 18C0.527344 27.6797 8.32031 35.4727 18 35.4727C27.6797 35.4727 35.4727 27.6797 35.4727 18C35.4727 8.32031 27.6797 0.527344 18 0.527344ZM25.5469 25.5469C24.8086 26.2031 23.7422 26.2031 23.0859 25.5469L18 20.4609L12.9141 25.5469C12.2578 26.2031 11.1914 26.2031 10.4531 25.5469C9.79688 24.8086 9.79688 23.7422 10.4531 23.0859L15.5391 18L10.4531 12.9141C9.79688 12.2578 9.79688 11.1914 10.4531 10.4531C11.1914 9.79688 12.2578 9.79688 12.9141 10.4531L18 15.5391L23.0859 10.4531C23.7422 9.79688 24.8086 9.79688 25.5469 10.4531C26.2031 11.1914 26.2031 12.2578 25.5469 12.9141L20.4609 18L25.5469 23.0859C26.2031 23.7422 26.2031 24.8086 25.5469 25.5469Z" />
    </SvgIcon>
  );
};

export default Icon;
