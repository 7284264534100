import React, { useState, useEffect } from 'react';
import {
  BackendSearchTextField,
  PageHeaderTitle,
  SimpleBackdrop,
} from 'components/widgets';
import SecondaryButton from 'components/widgets/Buttons/SecondaryButton';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import FilterDropDown from 'components/FilterDropDown';
import NotFoundPage from 'components/NotFoundPage';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import {
  DeveloperStatusFilterMenuItems,
  INITIAL_RENDER_THIRD_PARTY_USER,
  THIRD_PARTY_USER_EMPTY_STATE_PAGE,
  THIRD_PARTY_DEV,
} from 'shared/constants';
import UsersGrid from './UsersGrid';
import ThirdPartyDevViewDetailsPage from './ThirdPartyDevViewDetailsPage';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { useSelector } from 'react-redux';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    height: 40,
    textTransform: 'none',
    color: '#036FCB',
  },
}));

const ThirdPartyDeveloperPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');

  const [statusFilter, setStatusFilter] = useState('all');

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [OnloadData, setOnloadData] = useState(false);

  const [flyout, setFlyout] = useState({ open: false });

  const [developerDetail, setDeveloperDetail] = useState({});

  const orgId = sessionStorage.getItem('orgId');

  const [filteredDevData, setFilteredDevData] = useState([]);

  const permissions = useHasPermissions({
    featureName: 'Third party developer',
    subFeatureName: 'Third party developer',
  });

  const getListOfUser = async () => {
    setLoading(true);
    try {
      const results = await OrganizationUsersApi.getListOfUsers({
        orgId: orgId,
        search_text: searchValue,
        status: statusFilter === 'all' ? '' : statusFilter ?? '',
        offset: offset,
        searchAffId: '',
        signup_type: 'signup',
        role_Id: '',
        lob_Id: '',
      });
      setFilteredDevData(results.content);
      setTotalElements(results.totalElements);
      setOnloadData(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOnloadData(true);
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    getListOfUser();
  }, [offset, searchText, statusFilter]);

  const onSearchClick = () => {
    offset !== 0 && setOffset(0);
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
  };

  const onToggle = () => {
  setFlyout({ open: false });
  };

  const statusFilterOptions = {
    options: DeveloperStatusFilterMenuItems,
    value: statusFilter,
    changeHandler: ev => {
      setIsInitialRender(false);
      setStatusFilter(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };
  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const onViewClick = data => {
    setFlyout({ open: true });
    setDeveloperDetail(data);
  };

  const handleCreateDeveloper = () => {};

  return (
    <div>
      {permissions?.READ && (
        <>
          <Grid container className={classes.container}>
            <Grid item>
              <PageHeaderTitle data-testid="dev-details">
                {THIRD_PARTY_DEV.PAGE_HEADER_TITLE}
              </PageHeaderTitle>
            </Grid>
            <Grid
              container
              alignItems="center"
              style={{ marginBottom: 16, marginTop: 16 }}
            >
              {' '}
              {(!isInitialRender || filteredDevData?.length > 0) && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        maxWidth: '280px',
                        marginRight: '16px',
                        height: '40px',
                      }}
                    >
                      <BackendSearchTextField
                        label={THIRD_PARTY_DEV.SEARCH_LABEL}
                        onChangeText={onChangeText}
                        value={searchValue}
                        clearData={onClearData}
                        onSearchClick={onSearchClick}
                      />
                    </div>
                    <SecondaryButton
                      style={{ marginLeft: '-8px' }}
                      onClick={onSearchClick}
                      data-testid="search-btn"
                    >
                      Search
                    </SecondaryButton>
                    <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                      <FilterDropDown
                        {...statusFilterOptions}
                        filterType="Status:"
                      />{' '}
                    </div>
                  </div>

                  {filteredDevData?.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: '10px' }}
                      data-testid="dev-details"
                    >
                      <UsersGrid
                        apiLoading={loading}
                        rowData={filteredDevData}
                        onView={onViewClick}
                        hasReadPermission={permissions?.READ}
                      />
                      <PaginationComponent
                        numberOfRowsPerPage={10}
                        count={totalElements}
                        onPageClick={paginationClick}
                        page={offset}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          {filteredDevData?.length === 0 && isInitialRender && OnloadData && (
            <Box style={{ margin: '20px' }}>
              <NotFoundPage
                title={INITIAL_RENDER_THIRD_PARTY_USER.DEV_TITLE}
                titleContent={INITIAL_RENDER_THIRD_PARTY_USER.DEV_TITLE_CONTENT}
                content={INITIAL_RENDER_THIRD_PARTY_USER.CONTENT}
                showButton={false}
                onNotFoundClick={handleCreateDeveloper}
              />
            </Box>
          )}
          {filteredDevData?.length === 0 && !isInitialRender && OnloadData && (
            <Box style={{ margin: '20px' }}>
              <NotFoundPage
                title={THIRD_PARTY_USER_EMPTY_STATE_PAGE.DEV_TITLE}
                content={THIRD_PARTY_USER_EMPTY_STATE_PAGE.CONTENT}
                showButton={false}
              />
            </Box>
          )}
          <SimpleBackdrop open={loading} />
          {flyout.open && (
            <ThirdPartyDevViewDetailsPage
              onToggle={onToggle}
              loading={loading}
              developerDetail={developerDetail}
              getListOfUser={getListOfUser}
              setFlyout={setFlyout}
              {...flyout}
              hasApprovePermission={permissions?.APPROVE}
            />
          )}

          <SimpleBackdrop open={loading} />
        </>
      )}
    </div>
  );
};

export default ThirdPartyDeveloperPage;
