import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Badge as MuiBadge } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  PageHeaderTitle,
  PrimaryButton,
  AlertDialog as ConfirmActionDialog,
  SimpleBackdrop as LoadingBackdrop,
  BackendSearchTextField,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import AppsGrid from './AppsGrid';
import FilterDropDown from 'components/FilterDropDown';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Tab, Tabs } from './Tabs';
import {
  RESOURCE_NAMES,
  APPLICATION_STATUS,
  ApplicationFilterMenuItems,
  INITIAL_RENDER_APPLICATION,
  APPLICATION_EMPTY_STATE_PAGE,
} from 'shared/constants';
import ApplicationApi from 'services/api/ApplicationApi';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'utils/userPermissions';
import NotFoundPage from 'components/NotFoundPage';
import OrganizationApi from 'services/api/OrganizationApi';
import { useHasPermissions } from 'shared/utility';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  createBtnWrap: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  createBtn: {
    textTransform: 'none',
  },
  badge: {
    backgroundColor: '#E4E4E4',
    // marginRight: 5,
    fontWeight: 'bold',
  },
  badgeRoot: {
    paddingBottom: 4,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function Badge({ badgeContent, ...otherProps }) {
  const classes = useStyles();

  return (
    <MuiBadge
      showZero
      badgeContent={badgeContent}
      classes={{
        badge: classes.badge,
        root: classes.badgeRoot,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      {...otherProps}
    />
  );
}

const ApplicationsPage = () => {
  const { state: locationState } = useLocation();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { checkDevPermission,checkSuperAdminPermission } = useUserPermissions();
  const dispatch = useDispatch();
  const { applicationData, totalItems, loading, approvedText, isFlyoutClose } =
    useSelector(state => state.applications);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [hasCrudRights, setHasCrudRights] = useState(false);
  const [fetchingRoles, setFetchingRoles] = useState(true);
  const [confirmationDialog, setConfirmationDialog] = useState({ open: false });
  const orgId = sessionStorage.getItem('orgId');
  const affiliateId = sessionStorage.getItem('affiliateId') ?? 'DEV';

  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [sandBoxFilter, setSandBoxFilter] = useState('all');
  const [productionFilter, setProductionFilter] = useState('all');
  const [searchText, setSearchText] = useState('');

  const isSuperAdmin = checkSuperAdminPermission();
  const [isMPHAdmin, setIsMPHAdmin] = useState(
    JSON.parse(sessionStorage.getItem('MPH_ADMIN') || isSuperAdmin)
  );

  const permissions = useHasPermissions({
    featureName: 'Developer portal',
    subFeatureName: 'Applications',
  });

  const [mphOrgApiLoading, setMphOrgApiLoading] = useState(
    typeof isMPHAdmin !== 'boolean'
  );

  const getApplications = useCallback(
    ({
      _orgId,
      offset,
      limit = 10,
      search,
      sandboxStatus,
      productionStatus,
    }) => {
      dispatch(
        actions.fetchApplications({
          _orgId,
          offset,
          limit,
          search,
          sandboxStatus: isMPHAdmin ? 'Approved' : sandboxStatus,
          productionStatus,
        })
      );
    },
    [dispatch, isMPHAdmin]
  );

  const handleShowCreate = useCallback(
    (item = null, action) => {
      dispatch(
        actions.showSaveApplication(
          orgId,
          affiliateId,
          item,
          action,
          sandBoxFilter
        )
      );
    },
    [dispatch, orgId, affiliateId]
  );

  const handlePagination = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const handleClearSearch = () => {
    if (!searchValue) {
      getApplications({
        _orgId: orgId,
        offset,
        search: searchValue,
        sandboxStatus: sandBoxFilter === 'all' ? '' : sandBoxFilter ?? '',
        productionStatus:
          productionFilter === 'all' ? '' : productionFilter ?? '',
      });
    }
    setSearchValue('');
    setSearchText('');
    offset !== 0 && setOffset(0);
    getApplications({
      _orgId: orgId,
      offset,
      search: '',
      sandboxStatus: sandBoxFilter === 'all' ? '' : sandBoxFilter ?? '',
      productionStatus:
        productionFilter === 'all' ? '' : productionFilter ?? '',
    });
  };

  const handleClickOnSearch = () => {
    if (!searchValue) {
      getApplications({
        _orgId: orgId,
        offset,
        search: searchValue,
        sandboxStatus: sandBoxFilter === 'all' ? '' : sandBoxFilter ?? '',
        productionStatus:
          productionFilter === 'all' ? '' : productionFilter ?? '',
      });
    }
    setIsInitialRender(false);
    setSearchText(searchValue);
    offset !== 0 && setOffset(0);

    getApplications({
      _orgId: orgId,
      offset,
      search: searchValue,
      sandboxStatus: sandBoxFilter === 'all' ? '' : sandBoxFilter ?? '',
      productionStatus:
        productionFilter === 'all' ? '' : productionFilter ?? '',
    });
  };

  const handleChangeSearch = text => {
    setSearchValue(text);
  };

  const productionFilterOptions = {
    options: ApplicationFilterMenuItems,
    value: productionFilter,
    changeHandler: ev => {
      setIsInitialRender(false);

      offset !== 0 && setOffset(0);
      setProductionFilter(ev.target.value);
    },
  };
  const sandBoxstatusFilterOptions = {
    options: ApplicationFilterMenuItems,
    value: sandBoxFilter,
    changeHandler: ev => {
      setIsInitialRender(false);

      setSandBoxFilter(ev.target.value);
      offset !== 0 && setOffset(0);
    },
  };

  useEffect(() => {
    if (isMPHAdmin !== null) {
      getApplications({
        _orgId: orgId,
        offset,
        search: searchValue,
        sandboxStatus: sandBoxFilter === 'all' ? '' : sandBoxFilter ?? '',
        productionStatus:
          productionFilter === 'all' ? '' : productionFilter ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getApplications,
    offset,
    orgId,
    sandBoxFilter,
    productionFilter,
    isFlyoutClose,
  ]);

  useEffect(() => {
    if (orgId && affiliateId) {
      const hasApplicationCrudRights = checkDevPermission(
        orgId,
        affiliateId,
        RESOURCE_NAMES.DEV_APPLICATION_CRUD
      );

      if (isMPHAdmin === null) {
        OrganizationApi.mphOrgCheck(orgId)
          .then(res => {
            const isMphAdmin = res && !hasApplicationCrudRights;
            sessionStorage.setItem('MPH_ADMIN', isMphAdmin);
            setMphOrgApiLoading(false);
            setIsMPHAdmin(isMphAdmin);
            getApplications({
              _orgId: orgId,
              offset,
              search: searchValue,
              sandboxStatus: isMphAdmin
                ? 'Approved'
                : sandBoxFilter === 'all'
                ? ''
                : sandBoxFilter ?? '',
              productionStatus:
                productionFilter === 'all' ? '' : productionFilter ?? '',
            });
          })
          .catch(err => {
            setMphOrgApiLoading(false);
          });
      }

      if (hasApplicationCrudRights && history?.location?.state?.openCreateApp) {
        history.replace({ state: { openCreateApp: false } });
        handleShowCreate();
      }
      setHasCrudRights(hasApplicationCrudRights);
      setFetchingRoles(false);
    }
  }, [orgId, affiliateId]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        padding={2}
      >
        <Typography
          variant="h2"
          style={{ margin: '16 0px', fontWeight: 'bold', fontSize: 28 }}
        >
          Applications
        </Typography>

        {/* {hasCrudRights && ( */}
        <PrimaryButton
          onClick={() => handleShowCreate()}
          size="large"
          data-testid="btn"
          hasPermissions={permissions?.CREATE}
        >
          Create application
        </PrimaryButton>
        {/* )} */}
      </Box>
      {(!isInitialRender || applicationData?.length > 0) && (
        <div style={{ margin: '16px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '24px',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: '280px',
                marginRight: '16px',
                height: '40px',
              }}
            >
              <BackendSearchTextField
                label="Search by application name"
                onChangeText={handleChangeSearch}
                value={searchValue}
                clearData={handleClearSearch}
                onSearchClick={handleClickOnSearch}
              />
            </div>
            <SecondaryButton
              data-testid="search"
              style={{ marginLeft: '-8px' }}
              onClick={handleClickOnSearch}
            >
              Search
            </SecondaryButton>
            {!isMPHAdmin && (
              <div style={{ paddingLeft: '20px', marginTop: '-8px' }}>
                <FilterDropDown
                  {...sandBoxstatusFilterOptions}
                  filterType="Sandbox:"
                />
              </div>
            )}
            <div style={{ paddingLeft: '10px', marginTop: '-8px' }}>
              <FilterDropDown
                {...productionFilterOptions}
                filterType="Production:"
              />
            </div>
          </div>
          {applicationData?.length > 0 && (
            <>
              <AppsGrid
                type={0}
                orgId={orgId}
                data={applicationData}
                loading={loading}
                onView={item => handleShowCreate(item, 'view')}
                readAccess={permissions.READ}
                editAccess={permissions.CREATE}
                isMPHAdmin={isMPHAdmin}
              />
              <PaginationComponent
                numberOfRowsPerPage={10}
                count={totalItems}
                onPageClick={handlePagination}
                page={offset}
              />
            </>
          )}
        </div>
      )}

      {applicationData?.length === 0 &&
        isInitialRender &&
        !loading &&
        !mphOrgApiLoading && (
          <Box style={{ margin: '20px' }}>
            <NotFoundPage
              title={INITIAL_RENDER_APPLICATION.DEV_TITLE}
              titleContent={INITIAL_RENDER_APPLICATION.DEV_TITLE_CONTENT}
              content={INITIAL_RENDER_APPLICATION.CONTENT}
              showButton={permissions?.CREATE}
              onNotFoundClick={() => handleShowCreate()}
            />
          </Box>
        )}
      {applicationData?.length === 0 && !isInitialRender && !loading && (
        <Box style={{ margin: '20px' }}>
          <NotFoundPage
            title={APPLICATION_EMPTY_STATE_PAGE.DEV_TITLE}
            content={APPLICATION_EMPTY_STATE_PAGE.CONTENT}
            showButton={false}
          />
        </Box>
      )}

      <SimpleBackdrop open={!loading && mphOrgApiLoading} />
    </>
  );
};

export default ApplicationsPage;
