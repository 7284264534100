import { useState } from 'react';
import { AlertDialog } from 'components/widgets';
import OrganizationApi from 'services/api/OrganizationApi';
import AddEditAddress from './AddEditAddress';
import {
  makeStyles,
  // Card,
  Grid,
  CardContent,
  // Typography,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
  Chip,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  // Paper,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useUserPermissions } from 'utils/userPermissions';

const useStyles = makeStyles(theme => ({
  addBtn: {
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  paperItem: {
    // minWidth: 280,
    minHeight: 196,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  newPaperItem: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionFooter: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  miniItem: {
    padding: theme.spacing(0.5),
    fontSize: 14,
  },
}));

const CustomPopper = ({ anchorEl, open, handlePopOverClose, ...props }) => {
  return (
    <Popper open={open} anchorEl={anchorEl} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handlePopOverClose}>
              <MenuList autoFocusItem={open} id="menu-list">
                {props.children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default function ManageAddresses({
  orgId,
  getOrgById,
  orgData,
  loading,
  createAccess,
  setLoading,
}) {
  const classes = useStyles();

  const [flyout, setFlayout] = useState({ open: false });
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [openPopover, setOpenPopover] = useState(null);
  const [popOverAnchorEl, setPopOverAnchorEl] = useState(null);
  const { info } = useSelector(state => state.userInfo);

  const { enqueueSnackbar } = useSnackbar();

  const { data: states } = useSelector(state => state.locationStates);
  const { data: addressTypes } = useSelector(state => state.addressTypes);
  const { checkITAdminPermission, checkSuperAdminPermission } =
    useUserPermissions();

  const emptyAddress = {
    orgId: orgId,
    city: '',
    country: 'US',
    line1: '',
    line2: '',
    // name: '',
    state: '',
    type: '',
    zip: '',
    contacts: [
      {
        email: '',
        fax: '',
        phone: '',
        orgId: orgId,
      },
    ],
  };

  function ReadonlyField({ label, value }) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 8,
          marginRight: 24,
          gap: '4px',
        }}
      >
        {label && (
          <span style={{ color: '#667085', fontWeight: '400', fontSize: 14 }}>
            {label}
          </span>
        )}
        <span style={{ fontWeight: 500, fontSize: '16px', color: '#344054' }}>
          {value}
        </span>
      </div>
    );
  }

  const onAddAddress = () => {
    setFlayout({
      open: true,
      address: emptyAddress,
      title: 'Add Address',
    });
  };

  const onEditAddress = address => {
    const updatedAddress =
      !address.contacts || address.contacts.length === 0
        ? {
            ...address,
            contacts: [
              {
                email: '',
                fax: '',
                phone: '',
                orgId: orgId,
              },
            ],
          }
        : address;
    setSelectedAddress(updatedAddress);
    setFlayout({
      open: true,
      address: updatedAddress,
      title: 'Edit address',
    });
  };

  const onAddAddressSubmit = async values => {
    try {
      await OrganizationApi.createOrgAddress(values);
      await getOrgById(orgId);
      setFlayout({ open: false });
      setLoading(false);
      setSelectedAddress(null);
      enqueueSnackbar('Address created successfully', { variant: 'success' });
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const onEditAddressSubmit = async values => {
    try {
      await OrganizationApi.updateOrgAddress(values);
      await getOrgById(orgId);
      setLoading(false);
      setFlayout({ open: false });
      setSelectedAddress(null);
      enqueueSnackbar('Address updated successfully', { variant: 'success' });
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const onAddressSubmit = values => {
    setLoading(true);
    if (values.id) {
      onEditAddressSubmit(values);
    } else {
      onAddAddressSubmit(values);
    }
  };

  const onAddressToggle = () => {
    setFlayout({ open: false });
  };

  const onDeleteAddress = address => {
    setSelectedAddress(address);
    setAlertDialog({
      open: true,
      title: 'Delete Address',
      description: 'Are you sure, you want to delete this address?',
    });
  };

  const onDialogConfirm = async () => {
    try {
      setLoading(true);
      await OrganizationApi.deleteOrgAddress(selectedAddress.id);
      await getOrgById(orgId);
      setSelectedAddress(null);

      enqueueSnackbar('Address deleted successfully', { variant: 'success' });
    } catch (error) {
      console.log('error: ', error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
    setAlertDialog({ open: false });
    setLoading(false);
  };

  const onDialogClose = () => {
    setSelectedAddress(null);
    setAlertDialog({ open: false });
  };

  const isITAdmin = checkITAdminPermission();
  const isSuperAdmin = checkSuperAdminPermission();
  return (
    <>
      {/* <Paper className={classes.paper}> */}
      <div className={classes.header}>
        <div className={classes.item}>
          <Typography
            style={{ fontWeight: 600, fontSize: '18px', color: '#344054' }}
          >
            Addresses
          </Typography>
          {(createAccess || isSuperAdmin) && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="Add"
              onClick={() => onAddAddress()}
            />
          )}
        </div>
      </div>

      <Grid container>
        {orgData && orgData.addresses && orgData.addresses.length > 0 && (
          <>
            {orgData.addresses.map(item => (
              <Grid item xs={12} key={item.id}>
                <CardContent
                  style={{
                    minHeight: 60,
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#F5F7F8',
                    borderRadius: '8px',
                    padding: '8px 12px',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{ width: '690px' }}>
                        <ReadonlyField
                          label={`${item.type} address`}
                          value={`${item.line1} ${item.line2}, ${item.city}, ${item.state}, ${item.zip}`}
                        />
                      </div>
                      <div style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ width: '160px' }}>
                          <ReadonlyField
                            label={`Business contact`}
                            value={`(${item.contacts[0].phone.substr(
                              0,
                              3
                            )})-${item.contacts[0].phone.substr(
                              3,
                              3
                            )}-${item.contacts[0].phone.substr(6)}`}
                          />
                        </div>
                        <div>
                          <ReadonlyField
                            label={`Fax`}
                            value={`(${item.contacts[0].fax.substr(
                              0,
                              3
                            )})-${item.contacts[0].fax.substr(
                              3,
                              3
                            )}-${item.contacts[0].fax.substr(6)}`}
                          />
                        </div>
                      </div>

                      {/* <div className={classes.contactItem}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          component="p">
                          {item.line1}
                        </Typography>
                      </div>
                      <div className={classes.contactItem}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          component="p">
                          {item.line2}
                        </Typography>
                      </div>
                      <div className={classes.contactItem}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          component="p">
                          {item.city}
                        </Typography>
                      </div>
                      <div className={classes.contactItem}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          component="p">
                          {`${item.state}, ${item.zip}`}
                        </Typography>
                      </div> */}
                    </div>
                  </div>
                  {(!isITAdmin || isSuperAdmin) && (
                    <div>
                      {(createAccess ||
                        isSuperAdmin) && (
                        <IconButton
                          onClick={event => {
                            setOpenPopover(true);
                            setPopOverAnchorEl(event.currentTarget);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      <CustomPopper
                        open={openPopover}
                        anchorEl={popOverAnchorEl}
                        handlePopOverClose={() => setOpenPopover(null)}
                      >
                        <MenuItem
                          onClick={() => {
                            onEditAddress(item);
                            setOpenPopover(null);
                          }}
                        >
                          Edit
                        </MenuItem>

                        {orgData.addresses.length > 1 && (
                          <MenuItem
                            onClick={() => {
                              onDeleteAddress(item);
                              setOpenPopover(null);
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </CustomPopper>
                    </div>
                  )}
                </CardContent>
                <CardActions></CardActions>
                {/* </Card>
                 */}
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {/* </Paper> */}
      {flyout.open && (
        <AddEditAddress
          onSubmit={onAddressSubmit}
          onToggle={onAddressToggle}
          loading={loading}
          states={states}
          addressTypes={addressTypes}
          {...flyout}
        />
      )}
      {alertDialog.open && (
        <AlertDialog
          onClose={onDialogClose}
          onConfirm={onDialogConfirm}
          loading={loading}
          {...alertDialog}
        />
      )}
    </>
  );
}
