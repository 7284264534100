import { Component, memo } from 'react';
import { DataGrid, SecondaryButton } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
    marginRight: '5px',
  },
  editbtn: {
    width: '58px',
    height: '24px',
    fontSize: '12px',
  },
  attributeType: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    borderRadius: '16px',
    backgroundColor: '#E2F1FC',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '120px',
    height: '24px',
    border: '1px solid #036FCB',
    padding: '10px',
    fontWeight: '500',
    color: '#036FCB',
  },
}));

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
  onEdit,
  onDeleteClick,
  hasEditPermission,
  hasDeletePermission
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  const onEditClickHandler = event => {
    event.preventDefault();
    onEdit(data);
  };

  const onDeleteClickHandler = event => {
    event.preventDefault();
    onDeleteClick(data);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-between',
          marginTop: '12px',
        }}
      >
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
        >
          View details
        </PrimaryButton>
        <SecondaryButton
          onClick={onEditClickHandler}
          className={classes.editbtn}
          hasPermissions={hasEditPermission}
        >
          Edit
        </SecondaryButton>
        {hasDeletePermission && (
          <IconButton
            style={{ height: '20px', width: '20px' }}
            onClick={onDeleteClickHandler}
          >
            <DeleteIcon color="action" />
          </IconButton>
        )}
      </div>
    </>
  );
});

const CriteriaType = memo(function CriteriaType({ data }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.attributeType}>{data.attrType || '-'}</div>
    </>
  );
});

const CreatedOnCriteria = memo(function CriteriaType({ data }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.attributeType}>{data.createdBy || '-'}</div>
    </>
  );
});

class CriteriaGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Criteria name',
        field: 'name',
        colId: 'NameID',
        minWidth: '200',
      },
      {
        headerName: 'Attributes',
        field: 'attrName',
        cellRenderer: 'attrName',
        minWidth: '200',
      },
      {
        headerName: 'Type of criterion',
        field: 'attrType',
        cellRenderer: 'TypeofCriteria',
        minWidth: '155',
      },
      {
        headerName: 'Updated on',
        field: 'updatedAt',
        cellRenderer: 'DateFormatter',
        minWidth: '125',
      },
      // {
      //   headerName: 'Created by',
      //   field: 'createdBy',
      //   cellRenderer: 'createdBy',
      //   minWidth: '125',
      // },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
        maxWidth: '230',
      },
    ],

    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      ConsentExpiryDateFormatter: rowData => {
        if (rowData.value && rowData.data.consentStatus !== 'Pending') {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      TypeofCriteria: rowData => (
        <CriteriaType {...rowData} onClick={item => this.onItemView(item)} />
      ),
      CreatedBy: rowData => (
        <CreatedOnCriteria
          {...rowData}
          onClick={item => this.onItemView(item)}
        />
      ),

      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onEdit={item => this.onEditView(item)}
          onDeleteClick={item => this.onDeleteButton(item)}
          hasEditPermission={this.props.hasEditPermission}
          hasDeletePermission={this.props.hasDeletePermission}
        />
      ),
      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.props.onView(data);
  }

  onEditView(data) {
    this.props.onEdit(data);
  }

  onDeleteButton(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}>
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh'}}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default CriteriaGrid;
