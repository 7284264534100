import React, { useState, useEffect } from 'react';
import { ADD_DATA, EDIT_DATA } from '../constants';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../mockTemplate';
import SuccessfulModal from '../SuccessfulModal';
import SnackbarToast from '../SnackbarToast';
import FileUpload from '../FileUpload';
import FormBuilder from '../SelfReportingFormBuilder';
import BreadCrumb from '../BreadCrumb';
import Loader from '../Loader';
import colors from '../colors';
import withRouter from '../withRoute';
import objectPath from 'object-path';
import { capitalizeFirstLetter, removeDuplicateValueFromArray } from '../helper';
import RecordsApi from '../RecordsApi';
import axios from 'axios';
import { withStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const styles = (theme) => ({
  root: {
    backgroundColor: colors.BACKGROUND_COLOR,
    padding: theme.spacing(4),
    minHeight: '100%',
  },
  wrapper: {
    boxSizing: 'border-box',
  },
  title: {
    color: colors.BLACK_B900,
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '40px',
    marginBottom: theme.spacing(5),
    // marginTop: theme.spacing(3),
  },
});

const SELF_REPORTING_OBJ = {
  ERROR_DESCRIPTION: (type) =>
    `An error occured while saving your ${
      type ? `${type} ` : ''
    } record. Please retry or come back in some time.`,
  RECORD_SAVED_DESCRIPTION: (type = 'record') => `Saved ${type} successfully`,
  LOADING_DESCRIPTION: (type) => `Saving ${type ? `${type} ` : ''} record, please wait.`,
  MARK_RESTRICTED_DESC: (type) =>
    `Mark the ${
      type || 'record'
    } as restricted if you want to hide it from sharing with your circle of family, providers etc.`,
  CHANGES_APPLIED: 'Your changes have been saved',
};

const getValueFromList = (list, key) => {
  const result = [];

  for (const listItem of list) {
    if (typeof listItem === 'object') {
      result.push(listItem[key]);
    } else {
      result.push(listItem);
    }
  }
  return removeDuplicateValueFromArray(result);
};

const constructDateTime = ({ issuedDate: issDate, issuedTime }) => {
  if (issDate && !isNaN(issDate)) {
    const newDate = new Date(issDate);
    if (issuedTime && !isNaN(issuedTime)) {
      const hours = new Date(issuedTime).getHours();
      const minutes = new Date(issuedTime).getMinutes();
      newDate.setMinutes(minutes);
      newDate.setHours(hours);
    }
    return newDate;
  }
  return null;
};

const validateRange = (val, regex) => {
  if (regex?.test(val)) {
    const splitValues = val.split('-');
    if (
      splitValues.length === 2 &&
      parseFloat(splitValues[0].trim()) < parseFloat(splitValues[1].trim())
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getConvertedValue = (val, type) => {
  const value = val ?? '-';
  switch (type) {
    case 'list':
      return value !== '-' ? [value] : [];
  }
};
const getStartAndEndDateValidStatus = (val, valueObj, element) => {
  if (element?.valueKey === 'startDate') {
    const endDateVal = valueObj?.['endDate'];
    const endDateTs = (endDateVal && new Date(endDateVal).getTime()) || undefined;
    const startDateTs = new Date(val).getTime();
    if (startDateTs <= endDateTs) {
      return true;
    }
  } else if (element?.valueKey === 'endDate') {
    const startDateVal = valueObj?.['startDate'];
    const startDateTs = (startDateVal && new Date(startDateVal).getTime()) || undefined;
    const endDateTs = new Date(val).getTime();
    if (endDateTs >= startDateTs) {
      return true;
    }
  }
  return false;
};

const SelfReporting = (props) => {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [inputList, setInputList] = useState([]);
  const { type = 'add', categoryName } = useParams();
  const [isEdit, setIsEdit] = useState(type === 'edit' ? true : false);
  const [title, setTitle] = useState('');
  const { editProps = {}, markAsRestricted } = (props?.location && props?.location?.state?.state) || [];
  const [createdObj, setCreatedObj] = useState({});
  const [allInputValues, setAllInputValue] = useState({});
  const history = useHistory();
  const orgId = sessionStorage.getItem("orgId");
  const tenant = sessionStorage.getItem("tenant");

  const {
    ERROR_DESCRIPTION,
    RECORD_SAVED_DESCRIPTION,
    LOADING_DESCRIPTION,
    MARK_RESTRICTED_DESC,
    CHANGES_APPLIED,
  } = SELF_REPORTING_OBJ;

  //////////data fields from templates/////////////////
  const { templates } = useSelector((state) => state.templates);

  const SERVICE_TYPE = categoryName;
  const icon = templates?.[SERVICE_TYPE]?.icon || mockTemplates[SERVICE_TYPE]?.icon || '-';
  const resourceName =
    templates?.[SERVICE_TYPE]?.resourceName || mockTemplates[SERVICE_TYPE]?.resourceName;

  const selfReportingSection =
    templates?.[SERVICE_TYPE]?.selfReportingSection ||
    mockTemplates[SERVICE_TYPE]?.selfReportingSection;
  const {
    formElementList = [],
    convertToLowerCaseList = [],
    steps = [],
    resourceNameForSelfReportingFileUpload,
    resourceType,
  } = selfReportingSection || {};

  const [documentsArn, setDocumentsArn] = useState(editProps?.documents || []);
  const [documentReferenceId, setDocumentReferenceId] = useState(editProps?.documentReferenceId);
  const [restricted, setRestricted] = useState(markAsRestricted);

  /////breadCrumbs
  const breadCrumbTitile = `${SERVICE_TYPE?.[0]?.toUpperCase()}${SERVICE_TYPE?.slice(1)?.replaceAll(
    '_',
    ' ',
  )}`;
  const breadCrumbProps = [
    { label: 'Records', route: `/organization/${orgId}/members/records` },
    {
      label: SERVICE_TYPE === 'labs' ? 'Lab results' : breadCrumbTitile,
      route: `/organization/${orgId}/members/records/${SERVICE_TYPE}`,
      state: { createdObj: createdObj },
    },
    { label: isEdit ? EDIT_DATA : ADD_DATA },
  ];
  ////////////////////////

  const createInputList = (inputValues = {}) => {
    const elementList = formElementList?.map((item) => {
      //conversion from submitted payload to actual prefilled value to populate in the respective field
      if (Object.keys(inputValues)?.length > 0 && item?.conversionFromValueToLabel?.length > 0) {
        for (const listItem of item?.conversionFromValueToLabel) {
          const res = item?.['options']?.find((obj) => obj?.value === inputValues?.[listItem]);
          inputValues[listItem] =
            res?.label || capitalizeFirstLetter(inputValues[listItem])?.replaceAll('-', ' ');
        }
      }
      //during edit mapping values from props and returning JSON template for form
      const source = Object.keys(inputValues).length > 0 ? inputValues : editProps;
      const newValue = objectPath.get(source, item?.valueKey);
      const key = item?.valueKey?.slice(-4);
      const keyValue = `${resourceName}${key}`;

      const element = { ...item, regex: item?.regex && new RegExp(item.regex) };
      const updatedValue = { ...element };
      if (['date', 'time'].includes(updatedValue.type)) {
        updatedValue['value'] = newValue !== '-' ? newValue : '';
        updatedValue['verifyInput'] = (val, valueObj) => {
          if (!val) {
            return false;
          }
          const isValid = ['coverage']?.includes(SERVICE_TYPE)
            ? getStartAndEndDateValidStatus(val, valueObj, element)
            : true;
          return isValid;
        };
        updatedValue['formatting'] = function (val) {
          return new Date(val).getTime();
        };
      } else if (updatedValue?.valueKey === 'value') {
        //for labResult
        updatedValue['value'] = newValue !== '-' ? newValue : '';
        updatedValue['verifyInput'] = (val) => element?.regex?.test(val);
      } else if (['referenceRange', 'range']?.includes(updatedValue?.valueKey)) {
        updatedValue['value'] = newValue !== '-' ? newValue : '';
        updatedValue['verifyInput'] = (val) => validateRange(val, element?.regex);
      } else if (
        (keyValue === element.valueKey && keyValue !== 'medicationType') ||
        ['allergicTo', 'testName'].includes(element.valueKey)
      ) {
        updatedValue['value'] = newValue || editProps?.title;
      } else if (element.type === 'dropdown') {
        if (element?.isMappingRequired) {
          updatedValue['value'] =
            updatedValue['options']?.find((item) => 
              (item.label.toLowerCase() === newValue.toLowerCase()) || 
              (item.value?.toLowerCase() === newValue.toLowerCase())
            )?.value || '';
        } else {
          updatedValue['value'] =
            newValue && newValue !== '-' && !/^[0-9]+$/.test(newValue)
              ? convertToLowerCaseList?.includes(element?.valueKey)
                ? newValue?.toLowerCase()
                : newValue
              : Number(newValue) || '';
        }
      } else if (newValue instanceof Array) {
        updatedValue['value'] = getValueFromList(newValue, element.resultKey);
      } else {
        if (element.isConvertionRequired) {
          updatedValue['value'] = getConvertedValue(newValue, element.conversionType);
        } else {
          updatedValue['value'] = newValue && newValue === '-' ? '' : newValue || '';
        }
      }
      return updatedValue;
    });
    return elementList;
  };

  useEffect(() => {
    if (isEdit && !editProps?.id) {
      history.push(`/organization/${orgId}/members/records/${SERVICE_TYPE}`);
    }
    setTitle(isEdit ? 'Edit data' : 'Add data');
    setInputList(createInputList());
    const { onFooterItemClick } = props;
    if (onFooterItemClick) {
      onFooterItemClick(3);
    }
  }, []);

  const affiliateId = sessionStorage.getItem("affiliateId");
  const memberId = sessionStorage.getItem("memberId");

  const onFormValidationSuccess = async (values) => {

    setRestricted(values.markAsRestricted === 'true' ? true : false);
    setIsLoading(true);
    setShowSnackbar(false);
    setSnackbarMessage('');
    if (values['route']) {
      values.route = values?.route?.toString();
    }
    if(values?.['reasonCode']){
      values.reasonCode = values?.reasonCode?.toString();
    }
    const valuesEntered = {
      ...values,
      markAsRestricted: values.markAsRestricted === 'true' ? 'restricted' : 'unrestricted',
      recordedOn: values.recordedOn ? new Date(values.recordedOn).getTime() : undefined,
    };
    ///issuedTime is appended to issuedDate and ,then removed issuedTime form payload(labResult)
    if (values?.issuedTime) {
      const dateTime = constructDateTime(values);
      valuesEntered['issuedDate'] = dateTime ? dateTime.getTime() : undefined;
      delete valuesEntered['issuedTime'];
    }

    try {
      let res = {};
      setLoaderText(LOADING_DESCRIPTION(resourceName));
      res = await RecordsApi.createSelfReportDataRecords({
        data: JSON.stringify(valuesEntered),
        resourceType,
        resourceId: editProps?.id || createdObj?.id,
        type,
        tenant: tenant,
        referenceId: memberId,
      });
      res = res && JSON.parse(res.data.record);
      setCreatedObj(res);
      setShowSnackbar(true);
      setAllInputValue(values);
      setSnackbarMessage(RECORD_SAVED_DESCRIPTION(resourceName));
      setActiveStep(1);
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(ERROR_DESCRIPTION(resourceName));
      console.log(err);
    } finally {
      setIsLoading(false);
      setLoaderText('');
    }
  };

  const handleViewDetails = () => {
    // if (SERVICE_TYPE === 'coverage') {
    //   props.navigate(`/records/${SERVICE_TYPE}`, { state: { createdObj: createdObj } });
    // } else {
      history.push(`/organization/${orgId}/members/records/${SERVICE_TYPE.toLowerCase()}`, { state: { createdObj: createdObj }});
    // }
  };

  const onUploadDocumentSuccess = (res) => {
    const newCreatedObj = {
      ...createdObj,
      documents: res,
    };
    setCreatedObj(isEdit ? createdObj : newCreatedObj);
    if (isEdit) {
      const toastState = {
        message: CHANGES_APPLIED,
        loaderText: LOADING_DESCRIPTION(resourceName),
        isOpen: true,
      };
      // if (SERVICE_TYPE === 'coverage') {
      //   props.navigate(`/records/${SERVICE_TYPE}`, { state: toastState });
      // } else {
        // props.navigate(`/records/new/${SERVICE_TYPE}`, { state: toastState });
        history.push(`/organization/${orgId}/members/records/${SERVICE_TYPE.toLowerCase()}`, { state: toastState});
      // }
    } else {
      setOpenSuccessModal(true);
    }
  };
  const onSkipPress = () => {
    setActiveStep(1);
  };
  const handleBackButtonClick = () => {
    setActiveStep(0);
    setInputList(createInputList(allInputValues));
    if (createdObj?.id) {
      setIsEdit(true);
      setTitle('Edit Data');
    }
  };
  const handleDocumentsSave = (filesDocArn) => {
    setDocumentsArn(filesDocArn);
  };

  const handleFileLoad = (file) => {
    console.log(file);
  };

  return (
    <div className={classes.root}>
      {isLoading && <Loader overlay text={loaderText} />}
      <BreadCrumb breadCrumbProps={breadCrumbProps} />
      <p className={classes.title}>{title}</p>

      <div className={classes.wrapper}>
        {activeStep === 0 && (
          <FormBuilder
            inputList={inputList || []}
            onSubmit={onFormValidationSuccess}
            isEdit={isEdit}
            // showMarkRestricted={true}
            markAsRestricted={restricted}
            restrictedDescription={MARK_RESTRICTED_DESC(resourceName)}
            activeStep={activeStep}
            steps={steps}
            onSkipPress={onSkipPress}
          />
        )}

        {activeStep === 1 && (
          <FileUpload
            isEdit={isEdit}
            handleFileLoad={handleFileLoad}
            activeStep={activeStep}
            handleBack={() => handleBackButtonClick()}
            resourceName={resourceNameForSelfReportingFileUpload}
            docArn={documentsArn}
            handleSave={(filesDocArn) => handleDocumentsSave(filesDocArn)}
            resourceId={editProps?.id?.toString() || createdObj?.id?.toString()}
            steps={steps}
            documentReferenceId={editProps?.documentReferenceId}
            onUploadDocumentSuccess={onUploadDocumentSuccess}
            markAsRestricted={restricted}
          />
        )}
      </div>
      {showSnackbar && (
        <SnackbarToast
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSnackbar}
          alertStyle={{ backgroundColor: '#1F1F1F', borderRadius: 4, color: '1F1F1F' }}
          autoHideDuration={4000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
        />
      )}
      <SuccessfulModal
        setModalOpen={setOpenSuccessModal}
        modalOpen={openSuccessModal}
        handleViewDetails={handleViewDetails}
        resourceName={resourceName}
      />
    </div>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(SelfReporting));
