import { Component, memo } from 'react';
import { DataGrid, SecondaryButton } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import ErrorIcon from '@material-ui/icons/Error';
import Box from '@material-ui/core/Box';
import {
  convertStrToTitleCase,
  formatTimeInHHMMWithComma,
} from 'shared/utility';
import { statusColorMap } from 'shared/utility';
import Tooltip from '@material-ui/core/Tooltip';
import AvatarImg from 'containers/Rules/tab/AttributeTab/attributeDetails/AvatarImg';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
}));

const RulesCreatedBy = memo(function CriteriaType({ data }) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ marginTop: '10px', marginRight: '5px' }}>
          <AvatarImg userName={`${data.createdBy || ''} `} />
        </div>
        <div style={{ marginBottom: '5px' }}>{data.createdBy || '-'}</div>
      </div>{' '}
    </>
  );
});

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
  readAccess,
  isOrgAdmin,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-evenly',
          marginTop: '12px',
        }}
      >
        {isOrgAdmin && (
          <SecondaryButton
            className={classes.viewDetailsButton}
            onClick={onClickHandler}
            hasPermissions={readAccess}
          >
            View details
          </SecondaryButton>
        )}
      </div>
    </>
  );
});

class ExternalUserGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'First name',
        field: 'firstName',
        colId: 'NameID',
        cellRenderer: 'Name',
      },
      {
        headerName: 'Last name',
        field: 'lastName',
        colId: 'NameID',
      },
      {
        headerName: 'Organization',
        field: 'externalOrganizationName',
      },
      {
        headerName: 'Affiliate',
        field: 'affiliate',
        cellRenderer: 'Affiliate',
      },
      {
        headerName: 'Role',
        field: 'roleName',
        cellRenderer: 'Role',
      },
      {
        headerName: 'Status',
        field: 'orgUserStatus',
        cellRenderer: 'StatusRenderer',
      },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 150,
      sortable: false,
      resizable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      Name: rowData => {
        if (rowData.value) {
          return <Box>{convertStrToTitleCase(rowData.value)}</Box>;
        }
      },
      Role: rowData => {
        if (rowData.value) {
          return <span>{rowData.value}</span>;
        } else {
          return 'N/A';
        }
      },
      Affiliate: rowData => {
        if (rowData.value) {
          return (
            <Tooltip title={rowData.value} arrow placement="bottom">
              <span>{rowData.value}</span>
            </Tooltip>
          );
        } else {
          return 'N/A';
        }
      },
      StatusRenderer: rowData => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(statusColorMap)?.includes(
              rowData?.data?.status || rowData?.value
            ) ? (
              <>
                {rowData?.value === 'failed' ? (
                  <>
                    <span
                      style={{
                        backgroundColor:
                          statusColorMap[
                            rowData?.data?.status || rowData?.value
                          ],
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        padding: 4,
                      }}
                    ></span>
                    <span
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {convertStrToTitleCase(
                        rowData.data.status || rowData.value
                      )}
                      <Tooltip title="emails bounced back" arrow>
                        <ErrorIcon
                          style={{
                            color: 'gray',
                            marginLeft: '4px',
                            marginTop: '12px',
                            width: '18px',
                            height: '18px',
                          }}
                        />
                      </Tooltip>
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        backgroundColor:
                          statusColorMap[
                            rowData?.data?.status || rowData?.value
                          ],
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        padding: 4,
                      }}
                    ></span>
                    <span style={{ marginLeft: 8 }}>
                      {convertStrToTitleCase(
                        rowData.data.status || rowData.value
                      )}
                    </span>
                  </>
                )}
              </>
            ) : (
              <span>
                {convertStrToTitleCase(rowData?.data?.status || rowData.value)}
              </span>
            )}
          </Box>
        );
      },

      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDeleteClick={item => this.onDeleteButton(item)}
          readAccess={this.props.readAccess}
          isOrgAdmin={this.props.isOrgAdmin}
        />
      ),

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return 'N/A';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.props.onView(data);
  }

  onDeleteButton(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default ExternalUserGrid;
