import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Typography,
  Pagination,
  Stack,
} from '@mui/material';
import { SecondaryButton } from 'components/widgets';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';

const BoldHeader = styled(TableCell)`
  font-weight: 600;
  width: ${props => props.width};
`;

const CompactTableCell = styled(TableCell)`
  padding: 10px 16px;
`;

export default function CareDashboardTable({
  rows,
  currentPage,
  onPageChange,
  openViewDetails,
}) {
  const startIndex = (currentPage - 1) * 10;
  const rowsToShow = rows.slice(startIndex, startIndex + 10);

  const paginationCount = rows.length <= 10 ? 1 : rows.length / 10;

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: '16px', boxShadow: 'none' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <BoldHeader width={200}>First name</BoldHeader>
            <BoldHeader width={200}>Last name</BoldHeader>
            <BoldHeader>Payer/plan</BoldHeader>
            <BoldHeader width={150}>Next activity date</BoldHeader>
            <BoldHeader width={150}>Due date</BoldHeader>
            <BoldHeader width={170}></BoldHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsToShow.map((row, index) => (
            <TableRow key={row.memberId}>
              <CompactTableCell>{row.firstName}</CompactTableCell>
              <CompactTableCell>{row.lastName}</CompactTableCell>
              <CompactTableCell>{row.planName}</CompactTableCell>
              <CompactTableCell>
                {/* {format(parseISO(row.nextActivityDate), 'MMM dd, yyyy')} */}
                {format(new Date(row.nextActivityDate), 'MMM dd, yyyy')}
              </CompactTableCell>
              <CompactTableCell>
                {/* {format(parseISO(row.dueDate), 'MMM dd, yyyy')} */}
                {format(new Date(row.dueDate), 'MMM dd, yyyy')}
              </CompactTableCell>
              <CompactTableCell>
                <SecondaryButton onClick={() => openViewDetails(row)}>
                  <Typography lineHeight={1} fontSize={'0.75rem'}>
                    View details
                  </Typography>
                </SecondaryButton>
              </CompactTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack p={3} direction="row" justifyContent="center">
        <Pagination
          page={currentPage}
          onChange={onPageChange}
          count={paginationCount}
          color="primary"
        />
      </Stack>
    </TableContainer>
  );
}
