import axios from "axios";
import BaseApiService from "services/api/BaseApiService";

function _getTenant(orgId, affiliateId) {
    return BaseApiService.get(`/organization/demo/${orgId}/getTenant?affiliateId=${affiliateId}`);
}

function _getRecords (variables)  {
     const url = 'https://partner.mpoweredhealth.com/fhir/records/unauth';
    return axios.post(url, JSON.stringify(variables), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
 }

function _getRecordById(resourceType,resourceId, memberId, tenant) {
    const url = `https://partner.mpoweredhealth.com/fhir/records/unauth/${resourceType}?resourceId=${resourceId}&patientId=${memberId}`;
    return axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'tenant': tenant
        },
      });

}

function _createSelfReportDataRecords(data) {
    const url = "https://partner.mpoweredhealth.com/fhir/records/unauth/createSelfReport"
    return axios.post(url, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
}

const _getAssessment = ({ orgId, payload }) => {
  return BaseApiService.post(
      `/assessment/${orgId}/getAllAssessments`,
      null,
      payload
  );
};

const RecordsApi = {
    getTenant: _getTenant,
    getRecords: _getRecords,
    getRecordById: _getRecordById,
    createSelfReportDataRecords: _createSelfReportDataRecords,
    getAssessment: _getAssessment,
  };
  
  export default RecordsApi;