import React, { useState, useEffect } from 'react';
import {
  FlyoutPanel,
  SelectInput,
  SearchTextField,
  PrimaryButton,
  SecondaryButton,
} from 'components/widgets';
import {
  Grid,
  FormControlLabel,
  FormGroup,
  FormControl,
  Checkbox,
  Typography,
  Box,
} from '@material-ui/core';
import Page from 'components/Page';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import StepperCustom from 'components/widgets/StepperComponent/NewStepper';
import DropZoneComponent from './DropZoneComponent';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';

export default function AddPriceList({
  onToggle,
  open,
  title,
  lobs,
  affiliates,
  setInNetError,
  setInNetFlag,
  setInNetworkFailArray,
  setOutNetError,
  setOutNetFlag,
  setOutNetworkFailArray,
  setPrescriptionError,
  setPrescriptionFlag,
  setPrescriptionFailArray,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [filteredLobs, setFilteredLobs] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedLobs, setSelectedLobs] = useState([]);
  const [inNetworkFileloaded, setInNetworkFileLoaded] = useState(0);
  const [inNetworkFile, setInNetworkFile] = useState({});
  const [inNetwork, setInNetwork] = useState(false);
  const [outOfNetworkFileloaded, setOutOfNetworkFileLoaded] = useState(0);
  const [outOfNetworkFile, setOutOfNetworkFile] = useState({});
  const [outOfNetwork, setOutOfNetwork] = useState(false);
  const [prescriptionFileloaded, setPrescriptionFileLoaded] = useState(0);
  const [prescriptionFile, setPrescriptionFile] = useState({});
  const [prescription, setPrescription] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    affiliate: '',
    name: '',
  });

  const steps = ['Select LOB', 'Upload file'];

  const onAppleFilter = (key, value) => {
    setSelectedFilter({ ...selectedFilter, [key]: value });
  };

  const { name, affiliate } = selectedFilter;
  let lobIds = [];

  const onClose = () => {
    setInNetworkFileLoaded(0);
    setOutOfNetworkFileLoaded(0);
    setPrescriptionFileLoaded(0);
    onToggle();
  };

  useEffect(() => {
    if (allChecked) {
      setSelectedLobs([...filteredLobs]);
    } else {
      setSelectedLobs([]);
    }
  }, [allChecked, filteredLobs]);

  useEffect(() => {
    const data = lobs
      .filter(
        fItem =>
          fItem.name.toLowerCase().indexOf(name.trim().toLowerCase()) !== -1
      )
      .filter(aItem => aItem.affiliateIds.includes(affiliate));

    setFilteredLobs(data);
  }, [name, affiliate, lobs]);

  if (selectedLobs.length > 0) {
    selectedLobs.map(item => lobIds.push({ id: item.id, name: item.name }));
  }

  const handleChange = item => {
    const index = selectedLobs.indexOf(item);

    if (index === -1) {
      setSelectedLobs([...selectedLobs, item]);
    } else {
      setSelectedLobs(selectedLobs.filter(lob => lob !== item));
    }
  };

  const handleUploadClick = () => {
    setActiveStep(1);
  };

  const handleBackClick = () => {
    setActiveStep(0);
    setInNetworkFile({});
    setInNetwork(false);
    setOutOfNetworkFile({});
    setOutOfNetwork(false);
    setPrescriptionFile({});
    setPrescription(false);
  };

  const handleAllChecked = () => {
    setAllChecked(!allChecked);
  };

  const inNetworkFileLoader = async () => {
    const otherConfig = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setInNetworkFileLoaded(percent);
        }
      },
    };
    setInNetwork(false);
    const affiliateId = selectedLobs[0].affiliateIds[0];

    const formData = new FormData();
    formData.append('file', inNetworkFile);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    for (let obj in lobIds) {
      let paramObject = {
        orgId: Number(selectedLobs[0].orgId),
        affiliateId: Number(affiliateId),
        lobId: lobIds[obj].id,
        filename: 'in-network-rate',
      };

      try {
        await PartnerLOBApi.fileUpload(
          paramObject,
          formData,
          otherConfig,
          otherHeaders
        );
        setInNetworkFileLoaded(100);
      } catch (e) {
        setInNetError(e.response.data.details);
        setInNetworkFailArray(prevValue => [...prevValue, lobIds[obj].name]);
      }
    }
    setInNetFlag(true);
    onClose();
  };

  const outOfNetworkFileLoader = async () => {
    const otherConfig = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setOutOfNetworkFileLoaded(percent);
        }
      },
    };
    setOutOfNetwork(false);
    const affiliateId = selectedLobs[0].affiliateIds[0];

    const formData = new FormData();
    formData.append('file', outOfNetworkFile);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    for (let obj in lobIds) {
      let paramObject = {
        orgId: Number(selectedLobs[0].orgId),
        affiliateId: Number(affiliateId),
        lobId: lobIds[obj].id,
        filename: 'out-of-network',
      };

      try {
        await PartnerLOBApi.fileUpload(
          paramObject,
          formData,
          otherConfig,
          otherHeaders
        );
        setOutOfNetworkFileLoaded(100);
      } catch (e) {
        setOutNetError(e.response.data.details);
        setOutNetworkFailArray(prevValue => [...prevValue, lobIds[obj].name]);
      }
    }
    setOutNetFlag(true);
    onClose();
  };

  const prescriptionFileLoader = async () => {
    const otherConfig = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setPrescriptionFileLoaded(percent);
        }
      },
    };
    setPrescription(false);
    const affiliateId = selectedLobs[0].affiliateIds[0];

    const formData = new FormData();
    formData.append('file', prescriptionFile);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    for (let obj in lobIds) {
      let paramObject = {
        orgId: Number(selectedLobs[0].orgId),
        affiliateId: Number(affiliateId),
        lobId: lobIds[obj].id,
        filename: 'prescription-drug',
      };

      try {
        await PartnerLOBApi.fileUpload(
          paramObject,
          formData,
          otherConfig,
          otherHeaders
        );
        setPrescriptionFileLoaded(100);
      } catch (e) {
        setPrescriptionError(e.response.data.details);
        setPrescriptionFailArray(prevValue => [...prevValue, lobIds[obj].name]);
      }
    }
    setPrescriptionFlag(true);
    onClose();
  };

  const handleInNetwork = props => {
    if (props) {
      setInNetworkFile(props);
      setInNetwork(true);
    } else {
      setInNetwork(false);
    }
  };

  const handleOutOfNetwork = props => {
    if (props) {
      setOutOfNetworkFile(props);
      setOutOfNetwork(true);
    } else {
      setOutOfNetwork(false);
    }
  };

  const handlePrescription = props => {
    if (props) {
      setPrescriptionFile(props);
      setPrescription(true);
    } else {
      setPrescription(false);
    }
  };

  const handleSaveClick = () => {
    setSubmitting(true);
    inNetwork && inNetworkFileLoader();
    outOfNetwork && outOfNetworkFileLoader();
    prescription && prescriptionFileLoader();
  };

  <StepperCustom
    activeStep={activeStep}
    // style={{marginBottom: '0px', marginTop: '0px'}}
    steps={steps}
    style={{ paddingBottom: 0, paddingTop: 0 }}
  />;

  return (
    <FlyoutPanel title={title} open={open} onToggle={onClose}>
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item>
          <StepperCustom
            activeStep={activeStep}
            // style={{marginBottom: '0px', marginTop: '0px'}}
            steps={steps}
            style={{ paddingBottom: 0, paddingTop: 0 }}
          />
        </Grid>
        {activeStep === 0 ? (
          <>
            <Grid item xs={9}>
              <Typography variant="caption" style={{ fontWeight: 500 }}>
                {LABELS_FIELDS_BUTTONS.SELECT_AFFILIATE}
              </Typography>
              <SelectInput
                value={selectedFilter['affiliate']}
                onChange={val => onAppleFilter('affiliate', val)}
                options={affiliates}
                emptyLabel={LABELS_FIELDS_BUTTONS.SELECT_AFFILIATE}
                optionValueKey="code"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h4">Select LOBs</Typography>
              <div style={{ marginTop: '2px', marginBottom: '6px' }}>
                <Typography variant="caption" style={{ color: '#8c8c8c' }}>
                  Please select the LOBs for which you want to upload the Price
                  list
                </Typography>
              </div>
              <SearchTextField
                label="Search by LOB name"
                onChangeText={val => onAppleFilter('name', val)}
              />
            </Grid>
            <Grid item>
              {filteredLobs.length > 0 ? (
                <Page
                  style={{
                    backgroundColor: '#EBEBEB',
                    padding: '16px',
                    borderRadius: '4px',
                    height: '300px',
                    overflow: 'scroll',
                  }}>
                  <FormControl>
                    <FormGroup>
                      {filteredLobs.length > 1 && (
                        <FormControlLabel
                          label={
                            <span style={{ fontWeight: 600 }}>Select All</span>
                          }
                          control={
                            <Checkbox
                              checked={allChecked}
                              onChange={handleAllChecked}
                            />
                          }
                        />
                      )}
                      {filteredLobs.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            label={item.name}
                            control={
                              <Checkbox
                                checked={selectedLobs.includes(item)}
                                onChange={() => handleChange(item)}
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Page>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h6">
                    Please select a valid affiliate
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item>
              <PrimaryButton
                disabled={selectedLobs.length === 0}
                onClick={handleUploadClick}>
                {LABELS_FIELDS_BUTTONS.UPLOAD_FILE}
              </PrimaryButton>
            </Grid>
          </>
        ) : (
          <Page style={{ maxHeight: '580px', overflow: 'scroll' }}>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 12px',
              }}>
              <Grid item style={{ marginBottom: '32px' }}>
                <Typography
                  variant="h5"
                  style={{ marginBottom: '5px', color: '#00A0DF' }}>
                  In-Network
                </Typography>
                <DropZoneComponent
                  fileloaded={inNetworkFileloaded}
                  handleButtonClick={handleInNetwork}
                />
              </Grid>
              <Grid item style={{ marginBottom: '32px' }}>
                <Typography
                  variant="h5"
                  style={{ marginBottom: '5px', color: '#00A0DF' }}>
                  Out of Network
                </Typography>
                <DropZoneComponent
                  fileloaded={outOfNetworkFileloaded}
                  handleButtonClick={handleOutOfNetwork}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  style={{ marginBottom: '5px', color: '#00A0DF' }}>
                  Prescription Drug
                </Typography>
                <DropZoneComponent
                  fileloaded={prescriptionFileloaded}
                  handleButtonClick={handlePrescription}
                />
              </Grid>
              <Grid item style={{ marginTop: '25px', marginBottom: '12px' }}>
                <Box style={{ display: 'flex' }}>
                  <SecondaryButton
                    onClick={handleBackClick}
                    disabled={submitting}>
                    Go Back
                  </SecondaryButton>
                  <PrimaryButton
                    style={{ marginLeft: '16px' }}
                    disabled={
                      submitting
                        ? submitting
                        : !inNetwork && !outOfNetwork && !prescription
                    }
                    onClick={handleSaveClick}>
                    Save
                  </PrimaryButton>
                </Box>
              </Grid>
            </Grid>
          </Page>
        )}
      </Grid>
    </FlyoutPanel>
  );
}
