import React from 'react';
import { Grid } from '@material-ui/core';
import UsersTab from './UsersTab';

const UsersPage = () => {
  const TabHeading = ['Organization', 'External'];

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid item xs={6} md={6}></Grid>
        </Grid>

        <UsersTab TabHeading={TabHeading} />
      </Grid>
    </>
  );
};

export default UsersPage;
