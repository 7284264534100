export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = value => {
  if (!value) {
    return 'This field is required';
  }

  if (typeof value === 'string') {
    return value.trim() ? undefined : 'This field is required';
  } else if (Array.isArray(value)) {
    return value.length > 0 ? undefined : 'This field is required';
  }

  return undefined;
};

export const validateDomain = domain => value => {
  if(!domain.includes(`@${value.split('@')[1]}`)) {
    return 'This domain is not part of your organization. Please update your domain.';
  }

  return undefined;
}

export const requiredRecaptcha = value => {
  if (!value) {
    return 'Please verify recaptcha';
  }

  if (typeof value === 'string') {
    return value.trim() ? undefined : 'Please verify recaptcha';
  }

  return undefined;
};

export const requiredTerms = value => {
  if (!value) {
    return 'Please select Terms and Conditions to proceed';
  }

  if (typeof value === 'string') {
    return value.trim()
      ? undefined
      : 'Please select Terms and Conditions to proceed';
  }

  return undefined;
};

export const requiredTemplate = value => {
  if (!value) {
    return 'This field is required';
  }

  if (typeof value === 'string') {
    let templateValue = value
      .replace('<html><body>', '')
      .replace('</body></html>', '')
      .replace(/&nbsp;/gi, '');

    if (!templateValue || templateValue.trim() === '') {
      return 'This field is required';
    }
  }

  return undefined;
};

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);
export const minLength10 = minLength(10);

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const strictPassword = value =>
  value && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(value)
    ? 'Password not strong. At least one number, one lowercase and one uppercase letter, minimum six characters'
    : undefined;

export const tooYoung = value =>
  value && value < 13
    ? 'You do not meet the minimum age requirement!'
    : undefined;

export const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined;

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;
export const alphaNumericWithoutSpace = value =>
  value && /[^a-zA-Z0-9]/i.test(value)
    ? 'Only alphanumeric characters without space'
    : undefined;

export const userNameCheck = value =>
  value && /[^a-zA-Z0-9._]/i.test(value)
    ? 'Only alphanumeric characters including . and _'
    : undefined;

export const phoneNumber = value =>
  value && !/^[2-9]\d{2}\d{3}\d{4}$/i.test(value)
    ? 'Invalid number'
    : undefined;

export const phoneNumberUAE = value =>
  value && !/^[2-9]\d{2}\d{3}\d{3}$/i.test(value)
    ? 'Invalid phone number'
    : undefined;

export const zipCode = value =>
  value && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    ? 'Invalid zip code'
    : undefined;

export const einNumber = value =>
  value && !/^\d{9}$/.test(value) ? 'Invalid EIN' : undefined;

export const dbNumber = value =>
  value && !/^\d{9}$/.test(value) ? `Invalid D&B` : undefined;
