import React, { useState, useEffect } from 'react';
import SolutionCard from '../SolutionCard/SolutionCard';
import {
  Box,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@material-ui/core';
import UserDetail from './UserDetail';
import Banner from './Banner';
import { useHistory } from 'react-router-dom';
import NavMenuItems from 'layouts/DashboardLayout/NavBar/_nav';
import SolutionApi from 'services/api/SolutionApi';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import OrganizationApi from 'services/api/OrganizationApi';
import { useUserPermissions } from 'utils/userPermissions';
import CloseIcon from '@material-ui/icons/Close';
import { BUTTON_TITLES } from 'shared/constants';
import ApprovalPending from 'assets/ApprovalPending.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PermissionApi from 'services/api/PermissionApi';
import { solutionAndSubFeatureMapping } from 'shared/utility';
import { saveSolutionPermission } from 'store/actions/userInfo';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '80vh',
  },
  dialogPaper: {
    borderRadius: theme.spacing(3),
    minWidth: '500px',
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    alignItems: 'center',
  },
  closeContainer1: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5),
    alignItems: 'center',
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#101828',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  container: {
    backgroundColor: '#FFFFFF',
    // height: '304px',
    maxWidth: '560px',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '24px',
    padding: '0px 40px 40px',
  },
}));
const DashboardPage = () => {
  const classes = useStyles();
  let history = useHistory();
  const { checkITAdminPermission } = useUserPermissions();

  const isITAdmin = checkITAdminPermission();
  const [solutionList, setSolutionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [flag, setFlag] = useState(false);
  const [requestedSolutionId, setRequestedSolutionId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const { solutionPermissions, info } = useSelector(state => state.userInfo);

  const dispatch = useDispatch();

  const roleId = info?.roleId;

  var orgId = sessionStorage.getItem('orgId');

  useEffect(() => {
    window.localStorage.removeItem('selectedSolutionId');
    window.localStorage.removeItem('manageOrgId');
  }, []);

  const navdetails = NavMenuItems.restrictedAdminItems.map(item => ({
    ...item,
    href: `/organization/${orgId}${item.href}`,
  }));

  function handleClick(id) {
    localStorage.setItem('selectedSolutionId', id);

    let redirectURL = '';

    const selectedSolution = solutionPermissions.get(String(id));

    // check whether solution has org or lob access

    const organizationDetailsPermissions = solutionPermissions
      .get('manage-org')
      ?.features[0].subFeatures?.find(
        subFeature => subFeature.subFeatureName === 'Organization Details'
      )?.selectedPermissions?.length;

    const lobPermissions = solutionPermissions
      .get('manage-org')
      ?.features[0].subFeatures?.find(
        subFeature => subFeature.subFeatureName === 'LOB'
      )?.selectedPermissions?.length;

    if (organizationDetailsPermissions) {
      redirectURL = `/organization/${orgId}/detail`;
    } else if (lobPermissions) {
      redirectURL = `/organization/${orgId}/manage-lobs`;
    } else if (
      selectedSolution?.solutionName === 'connect' ||
      selectedSolution?.solutionName === 'nexus'
    ) {
      redirectURL = `/organization/${orgId}/dashboard`;
    } else {
      // redirect to available feature

      for (let feature of selectedSolution?.features) {
        if (feature.hasAnySubFeaturePermissions) {
          const selectedFeature = NavMenuItems.restrictedAdminItems?.find(
            menu => menu.featureName === feature.featureName
          );

          if (selectedFeature?.subMenu?.length) {
            redirectURL = `/organization/${orgId}${selectedFeature.subMenu[0]?.href}`;
          } else {
            redirectURL = `/organization/${orgId}${selectedFeature.href}`;
          }

          break;
        }
      }
    }

    if (redirectURL === '') {
      enqueueSnackbar(
        `You don't have permission to access ${selectedSolution?.solutionName} `,
        {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      );
    }

    // if (isITAdmin) {
    history.push({
      pathname: redirectURL,
      state: { isDashboard: true },
    });
    // } else {
    //   history.push({
    //     pathname: `/developer`,
    //     state: { isDashboard: true },
    //   });
    // }
  }

  function handleDialogBox(id) {
    setRequestedSolutionId(id);
    setOpenDialog(true);
  }

  function handleCloseOnConfirm() {
    setOpenDialog(false);
    setFlag(false);
  }

  function handleProceedPurchaseClick() {
    requestSolutionAccess();
  }

  const filterSolutionsBasedOnRoleName = list => {
    if (info.accessName === 'Organization Admin') {
      return list;
    } else {
      let visibleCards = list.filter(item => item.isActive === true);
      return visibleCards;
    }
  };

  const getAllSolutions = async () => {
    setLoading(true);
    await SolutionApi.listSolution({ orgId })
      .then(res => {
        setSolutionList(filterSolutionsBasedOnRoleName(res));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  };

  const requestSolutionAccess = async () => {
    setLoading(true);
    await SolutionApi.requestSolutionAccess({ orgId, requestedSolutionId })
      .then(res => {
        setLoading(false);
        getAllSolutions();
        setFlag(true);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getUserCount = () => {
    OrganizationApi.getUserCount(orgId)
      .then(response => {
        setUserCount(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const refetchPermissions = async () => {
    setLoading(true);
    await PermissionApi.getRolePermissionForSolutions({ roleId })
      .then(permissionsResponse => {
        const { solutionPermissionMap, subFeaturePermissionMap } =
          solutionAndSubFeatureMapping(permissionsResponse);
        dispatch(
          saveSolutionPermission(solutionPermissionMap, subFeaturePermissionMap)
        );
        setLoading(false);
      })
      .catch(err => {
        console.error('Permissions API failed', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllSolutions();
    getUserCount();
    refetchPermissions();
  }, []);

  const uniqueCategories = [
    ...new Set(solutionList.map(item => item.category)),
  ].filter(category => category);

  return (
    <Box
      className={classes.root}
      style={{
        height: '100%',
      }}
    >
      {' '}
      <Box component="div" sx={{ bgcolor: '#FFFFFF', padding: '32px' }}>
        <UserDetail />
        {(userCount === 1 || userCount === 0) && <Banner />}
      </Box>
      <Box
        sx={{
          padding: '20px 40px 20px 20px',
        }}
      >
        {uniqueCategories?.map(item => (
          <Box sx={{ mt: '20px' }}>
            <Typography variant="h5">{item}</Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {solutionList?.map((solution, i) => {
                if (solution?.category === item) {
                  return (
                    <SolutionCard
                      id={solution?.solutionId}
                      title={solution?.solutionName}
                      context="Campaign management platform for care plans, surverys & coaching"
                      openCard="Open"
                      handleClick={handleClick}
                      checkPermission={solution?.isActive}
                      description={solution?.description}
                      hasRequested={solution?.hasRequestAccess}
                      handleDialogBox={handleDialogBox}
                    />
                  );
                }
              })}
            </Box>
          </Box>
        ))}
      </Box>
      <Dialog
        open={openDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        onClose={handleCloseOnConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className={!flag ? classes.closeContainer : classes.closeContainer1}
        >
          {!flag && (
            <div
              id="alert-dialog-title"
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                padding: 20,
                display: 'grid',
              }}
            >
              Confirm purchase
            </div>
          )}
          <IconButton
            style={{ marginBottom: 'auto' }}
            onClick={handleCloseOnConfirm}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {!flag ? (
            <Grid item xs={12}>
              <div style={{ margin: 'auto' }}>
                <Grid item sm={12} xs={12}>
                  <Typography className={classes.formTitle}>
                    Are you sure you want to proceed with purchasing this
                    solution?
                  </Typography>
                </Grid>
              </div>
            </Grid>
          ) : (
            <Box className={classes.container}>
              <img
                src={ApprovalPending}
                alt={'Pending state icon'}
                style={{ height: '120px' }}
              />
              <Typography className={classes.title}>
                We will contact you soon!
              </Typography>
              <Typography className={classes.subtitle}>
                We have registered your request. You will receive an email as
                soon as it gets approved by the admin
              </Typography>
            </Box>
          )}
        </DialogContent>
        {!flag && (
          <DialogActions style={{ margin: '6px 20px' }}>
            <SecondaryButton
              onClick={handleCloseOnConfirm}
              data-testid="cancelbtn"
            >
              {BUTTON_TITLES.CANCEL}
            </SecondaryButton>
            <PrimaryButton
              onClick={handleProceedPurchaseClick}
              data-testid="proceedbtn"
            >
              {BUTTON_TITLES.PROCEED}
            </PrimaryButton>
          </DialogActions>
        )}
      </Dialog>
      <SimpleBackdrop open={loading} />
    </Box>
  );
};

export default DashboardPage;
