import {
  Box,
  Chip,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import React, { useState } from 'react';
import { convertStrToTitleCase, statusColorMap } from 'shared/utility';
import PermissionForm from './PermissionForm';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

  fieldLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  fieldValue: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#344054',
    wordWrap: 'break-word',

    //   marginBottom: '2%',
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    fontSize: '18px',
    color: '#002534',
    height: '50px',
    width: '50px',
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  root: {
    flexGrow: 1,
    minHeight: '85vh',
  },
  paper: {
    padding: theme.spacing(1.8),
    borderRadius: '8px',
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#f5f5f5',
    width: '100%',
    marginTop: '20px',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  componentWrapper: {
    fontWeight: 600,
    wordWrap: 'break-word',
    marginTop: '4px',
    marginRight: '8px',
  },
  affiliateBox: {
    background: 'white',
    padding: '10px',
    borderRadius: '10px',
    margin: '10px 10px 0px 0px',
  },
  permissionPaper: {
    borderRadius: '8px',
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#f5f5f5',
    width: '100%',
    marginTop: '20px',
    '& #permission-box': {
      minHeight: 'fit-content !important',
      marginBottom: '0px',
    },
  },
  inviteUsersBtn: {
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
  },
  primaryChip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#E2F1FC',
    borderColor: '#005DB9',
    color: '#005DB9',
    textTransform: 'uppercase',
    height: '16px',
    padding: '5px',
  },
}));

const UserDetailsCards = ({
  usersDetails,
  userInfoPayload,
  setInviteCard,
  setRefetchInternalUsers,
  setRefetchExternalUsers,
  roleId,
  isVerifyDetails,
  changeStep,
  editUserDetails,
  roleData,
  lobs,
  affiliates,
  isExternal,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const orgId = sessionStorage.getItem('orgId');
  const { enqueueSnackbar } = useSnackbar();

  const handleInviteUsers = () => {
    setLoading(true);
    OrganizationUsersApi.createrUserV2(orgId, userInfoPayload)
      .then(res => {
        enqueueSnackbar('Invitation sent successfully', {
          variant: 'success',
          autoHideDuration: 1500,
        });
        setLoading(false);
        setInviteCard(false);
        if(isExternal){
          setRefetchExternalUsers(true)
        }
        else{
          setRefetchInternalUsers(true);
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err.response?.data?.details ||
            'Something went wrong while inviting user',
          {
            variant: 'error',
          }
        );
        setLoading(false);
      });
  };

  let affiliateAndLob = isVerifyDetails
    ? usersDetails?.affiliateLobMap
    : usersDetails?.affiliateLobMapList;

  return (
    <>
      <DrawerBoxComponent
        className={classes.root}
        style={{ minHeight: '78vh', margin: '0px 25px' }}
      >
        <Paper elevation={0} className={classes.paper}>
          <Grid spacing={3} className={classes.container} style={{marginBottom: '12px'}}>
            <Grid item xs={11}>
              <Box style={{ display: 'flex', gap: '5px' }}>
                <Typography className={classes.fieldValue} variant="h6">
                  <b>User details</b>
                </Typography>
                <Chip
                  variant="outlined"
                  size="medium"
                  label={isExternal ? 'External' : 'Organization'}
                  className={classes.primaryChip}
                />
              </Box>
            </Grid>

            <Grid item xs={1} style={{ textAlign: 'right' }}>
              {isVerifyDetails && (
                <SecondaryButton
                  onClick={() => (isVerifyDetails ? editUserDetails(0) : '')}
                >
                  Edit
                </SecondaryButton>
              )}
            </Grid>
          </Grid>
          <Grid spacing={3} className={classes.container}>
            <Grid item xs={2}>
              <Typography className={classes.fieldLabel} variant="h6">
                First name
              </Typography>
              <Typography className={classes.fieldValue} variant="h6">
                {usersDetails?.firstName}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.fieldLabel} variant="h6">
                Last name
              </Typography>

              <Typography className={classes.fieldValue} variant="h6">
                {usersDetails?.lastName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.fieldLabel} variant="h6">
                Email id
              </Typography>
              <Typography className={classes.fieldValue} variant="h6">
                {usersDetails?.emailId}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {usersDetails?.orgUserStatus && (
                <>
                  <Typography className={classes.fieldLabel} variant="h6">
                    Status
                  </Typography>

                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '6%',
                    }}
                  >
                    {Object.keys(statusColorMap).includes(
                      usersDetails?.orgUserStatus
                    ) ? (
                      <>
                        <span
                          style={{
                            backgroundColor:
                              statusColorMap[usersDetails?.orgUserStatus],
                            width: 8,
                            height: 8,
                            borderRadius: 4,
                            padding: 2,
                          }}
                        ></span>

                        <Typography
                          className={classes.fieldValue}
                          variant="h6"
                          style={{ marginLeft: 8 }}
                        >
                          {convertStrToTitleCase(usersDetails?.orgUserStatus)}
                        </Typography>
                      </>
                    ) : (
                      <span>{usersDetails?.orgUserStatus}</span>
                    )}
                  </Box>
                </>
              )}
            </Grid>
          </Grid>

          <Box className={classes.container}>
            <Box>
              <Box style={{ marginRight: '30px', marginTop: '10px' }}>
                <Typography className={classes.fieldLabel} variant="h6">
                  Affiliate
                </Typography>
                <Box style={{ display: 'flex' }}>
                  {affiliateAndLob &&
                    affiliateAndLob.map((item, i) => (
                      <Box className={classes.affiliateBox}>
                        <Typography className={classes.fieldValue} variant="h6">
                          {
                            affiliates.filter(
                              aff => aff.id === item.affiliateId
                            )?.[0]?.name
                          }
                        </Typography>
                        <Box
                          style={{
                            fontSize: '10px',
                            color: '#667085',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {item?.lobIds?.map((data, i) => (
                            <Typography
                              style={{
                                fontWeight: 500,
                                fontSize: '14px',
                                color: '#344054',
                              }}
                              variant="h6"
                            >
                              {i === 0 ? (
                                lobs.filter(lob => lob.id === data)?.[0]?.name
                              ) : (
                                <>
                                  <span
                                    style={{
                                      marginLeft: '8px',
                                      marginRight: '8px',
                                    }}
                                  >
                                    |
                                  </span>
                                  {
                                    lobs.filter(lob => lob.id === data)?.[0]
                                      ?.name
                                  }
                                </>
                              )}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
        {(usersDetails.orgUserStatus === 'Declined' ||
          usersDetails.orgUserStatus === 'Revoked') && (
          <Paper className={classes.paper}>
            <Typography>
              Reason for{' '}
              {usersDetails.orgUserStatus === 'Declined'
                ? 'declining'
                : 'revoking'}{' '}
              access
            </Typography>
            <Typography style={{ fontWeight: 600 }}>
              {usersDetails?.orgUserStatus === 'Declined'
                ? usersDetails?.deniedReason
                : usersDetails?.revokeReason}{' '}
            </Typography>
          </Paper>
        )}

        {/* role box */}
        <Paper elevation={0} className={classes.paper}>
          <Grid spacing={3} className={classes.container} style={{marginBottom: '12px'}}>
            <Grid item xs={11}>
              <Typography className={classes.fieldValue} variant="h6">
                <b>Role</b>
              </Typography>
            </Grid>

            <Grid item xs={1} style={{ textAlign: 'right' }}>
              {isVerifyDetails && (
                <SecondaryButton
                  onClick={() => (isVerifyDetails ? changeStep(1) : '')}
                >
                  Edit
                </SecondaryButton>
              )}
            </Grid>
          </Grid>

          <Box className={classes.container}>
            <Box>
              <Box style={{ marginRight: '30px' }}>
                <Typography className={classes.fieldLabel} variant="h6">
                  Role name
                </Typography>
                <Typography className={classes.fieldValue} variant="h6">
                  {roleData?.roleName || usersDetails?.roleName}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>

        {/* permission box */}
        <Paper elevation={0} className={classes.permissionPaper}>
          <PermissionForm
            isView={true}
            hideBasicInfo={true}
            permissionTitle="Permissions"
            isManageRole={true}
            hideFooter={true}
            roleId={roleId}
          />
        </Paper>

        {isVerifyDetails && (
          <Box className={classes.inviteUsersBtn}>
            <PrimaryButton onClick={handleInviteUsers}>
              Invite Users
            </PrimaryButton>
          </Box>
        )}
      </DrawerBoxComponent>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default UserDetailsCards;
