import React, { useState, useEffect } from 'react';
import { SecondaryButton, PrimaryButton } from 'components/widgets';
import { TextField } from '@material-ui/core';
import DocPopup from 'components/widgets/FlyoutPanel/DocPopup';
import { SimpleBackdrop } from 'components/widgets';
import { useSnackbar } from 'notistack';
import BaseApiService from 'services/api/BaseApiService';
import Autocomplete from '@mui/material/Autocomplete';

const AddCareNavigatorPopUp = ({ open, onClose, orgId, fhirReferenceId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCareManager, setSelectedCareManager] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchCareManagers = async () => {
      try {
        const response = await BaseApiService.get(`/organization/${orgId}/getCareManagers`);
        if (response) {
          setOptions(response);
        } else {
          enqueueSnackbar('Failed to fetch care managers', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch care managers', { variant: 'error' });
      }
    };

    if (open) {
      fetchCareManagers();
    }
  }, [open, orgId, enqueueSnackbar]);

  const handleInputChange = (event, value) => {
    setSearchTerm(value);
    setDisable(value.length === 0);
  };

  const handleChange = (event, value) => {
    setSelectedCareManager(value);
    setDisable(!value);
  };


  const handleApply = async () => {
    if (!selectedCareManager) {
      enqueueSnackbar('Please select a care manager', { variant: 'warning' });
      return;
    }

    const requestData = {
      careManagerKeycloakId: String(selectedCareManager.keycloakId),
      memberKeycloakId: "",
      memberFhirId: String(fhirReferenceId)
    }
  
    setLoading(true);

    try {
      const response = await BaseApiService.post(
        `/organization/${orgId}/assignCareNavigator`,
        null,
        JSON.stringify(requestData)
      );
        console.log(response,'response')
      if (response  === "SUCCESS") {
        enqueueSnackbar('Care Navigator added successfully', { variant: 'success' });
        handleClose();
      } else if (response === "ALREADY_EXISTS" ) {
        enqueueSnackbar('Care Navigator already assigned to this member', { variant: 'error' });
      } else if (response === "UPDATED" ) {
        enqueueSnackbar('Updated Care Navigator successfullyr', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to add Care Navigator', { variant: 'error' });
      }
    } catch (error) {
      if (error.response.status === 409 ) {
        enqueueSnackbar('Care Navigator already assigned to this member', { variant: 'error' });
      } else {
      enqueueSnackbar('Failed to add Care Navigator', { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSearchTerm('');
    setDisable(true);
    setSelectedCareManager(null);
    onClose();
  };

  return (
    <DocPopup open={open} onClose={handleClose} title="Add Care Navigator">
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.fullName}
          onInputChange={handleInputChange}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search by name"
              fullWidth
            />
          )}
        />
        <div style={{ marginTop: '48px', display: 'flex', justifyContent: 'flex-end' }}>
          <SecondaryButton onClick={handleClose} size="large" style={{ marginRight: 10 }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" type="add" disabled={disable} onClick={handleApply}>
            Apply
          </PrimaryButton>
        </div>
      </div>
      <SimpleBackdrop open={loading} />
    </DocPopup>
  );
};

export default AddCareNavigatorPopUp;
