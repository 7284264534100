import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
    alignItems: 'center',
    // marginBottom: theme.spacing(2),
  },
  dialogContainer: {
    borderRadius: 24,
  },
}));

export default function PricePopup(props) {
  const { onClose, open, children } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      className={classes.dialogContainer}>
      <div className={classes.closeContainer}>
        <h1
          onClick={onClose}
          style={{ fontSize: 28, fontWeight: 600, paddingLeft: 20 }}>
          Price list
        </h1>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {children}
    </Dialog>
  );
}

PricePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
