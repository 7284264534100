import { Box, makeStyles } from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import ReusableModal from 'containers/Applications/ReusableModal';
import { DEV, REQUEST_MODAL, REUSABLE_MODAL } from 'shared/constants';
import '../Campaigns/campaigns/CampaignModal.scss';
import { useRef } from 'react';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import UserDetailsCards from './UserDetailsCards';
import './UsersDetailPage.scss';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

  fieldLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  fieldValue: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#344054',
    marginBottom: '2%',
  },
  avatar: {
    backgroundColor: '#00A0DF',
    marginRight: 8,
    fontSize: '18px',
    color: '#002534',
    height: '50px',
    width: '50px',
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  paper: {
    padding: theme.spacing(1.8),
    borderRadius: '8px',
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
    width: '100%',
    marginTop: '20px',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  componentWrapper: {
    fontWeight: 600,
    wordWrap: 'break-word',
    marginTop: '4px',
    marginRight: '8px',
  },
  affiliateBox: {
    background: 'white',
    padding: '10px',
    borderRadius: '10px',
    margin: '10px 10px 0px 0px',
  },
  footer: {
    display: 'flex',
    alignSelf: 'end',
    padding: '20px',
    width: '100%',
    justifyContent: 'end',
    borderTop: '0.5px solid #d9d9d9',
  },
}));

const UsersDetailPage = ({
  pageHeading,
  open,
  onToggle,
  usersDetails,
  setFlyout,
  getListOfUser,
  onEditUsers,
  lobs,
  affiliates,
}) => {
  const classes = useStyles();
  const [handleOpen, setHandleOpen] = useState(open);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchText = useRef('');
  const loaderRef = useRef();
  const [declinePopup, setDeclinePopup] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [approvePopup, setApprovePopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let userId = usersDetails?.userId;
  const orgId = sessionStorage.getItem('orgId');
  const onApproveRequest = () => {
    setApprovePopup(true);
  };

  const onDenyRequest = () => {
    setDeclinePopup(true);
  };

  const onChangeApproval = ({ status = '' }) => {
    setLoading(true);
    let payload = {
      id: userId,
      status: status
        ? status
        : usersDetails?.orgUserStatus?.toLowerCase() === 'pending'
        ? 'Approved'
        : 'declined',
      ...(status?.toLowerCase() === 'declined'
        ? { deniedReason: declineReason }
        : { revokeReason: declineReason }),
      firstName: usersDetails.firstName,
      lastName: usersDetails.lastName,
      emailAddress: usersDetails?.emailId,
    };

    OrganizationUsersApi.getDevApprovedDecline({ orgId, userId, payload })
      .then(response => {
        setLoading(false);
        if (status === 'Revoked') {
          enqueueSnackbar('User request Revoked', {
            variant: 'success',
          });
        } else if (status === 'Approved') {
          enqueueSnackbar('User request approved', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('User request denied', {
            variant: 'success',
          });
        }
        getListOfUser(
          usersDetails?.orgUserStatus === 'Pending',
          usersDetails?.orgUserStatus === 'Declined' ? 'DECLINED' : 'APPROVED'
        );
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar('There were some error performing this action', {
          variant: 'error',
        });
      });
  };

  const checkValidation = () => {
    if (declineReason.length >= 1) {
      return true;
    } else if (usersDetails.orgUserStatus === 'Approved') {
      enqueueSnackbar('Please enter reason for revoking', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return false;
    } else if (usersDetails.orgUserStatus !== 'Approved') {
      enqueueSnackbar('Please enter reason for declining', {
        variant: 'error',
        autoHideDuration: 1500,
      });
      return false;
    }
  };

  const handleConfirmDenied = ({ status }) => {
    if (!checkValidation()) return;
    onChangeApproval({
      status:
        status && status?.toLowerCase() === 'pending' ? 'Declined' : 'Revoked',
    });
    setFlyout({ open: false });
  };

  const handleConfirmApprove = () => {
    onChangeApproval({ status: 'Approved' });
    setLoading(true);
    setFlyout({ open: false });
  };

  const handleCloseConfirmation = () => {
    setDeclinePopup(false);
  };

  const onClose = () => {
    onToggle();
  };

  const handleClose = () => {
    setApprovePopup(false);
  };

  const avatarInitials = name => {
    return name
      ?.split(' ')
      ?.map(item => item[0])
      ?.join('');
  };

  const FooterAction = ({
    saveLabel = 'Save',
    canceLabel = 'Cancel',
    onSave,
    type = 'button',
    onToggle,
    disabled,
    hideSave = false,
    ...otherCommonProps
  }) => {
    return (
      <Box
        sx={{
          background: '#fff',
          boxShadow: '-2px 0px 6px #e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0.8rem 1.4rem',
          gap: '18px',
        }}
      >
        <SecondaryButton
          onClick={onToggle}
          variant="outlined"
          color="primary"
          className={classes.actionButton}
          {...otherCommonProps}
        >
          {canceLabel}
        </SecondaryButton>
        {!hideSave && (
          <PrimaryButton
            onClick={onSave}
            disabled={disabled}
            type={type}
            className={classes.actionButton}
            {...otherCommonProps}
          >
            {saveLabel}
          </PrimaryButton>
        )}
      </Box>
    );
  };

  return (
    <FullScreenDialog
      open={handleOpen}
      handleClose={() => setHandleOpen(false)}
      title="User details"
      onToggle={onClose}
      isAppBarRequired={true}
      appBarChildren={
        ![
          'Revoked',
          'Declined',
          'Expired',
          'Pending',
          'Email_Verification_Failed',
        ].includes(usersDetails.orgUserStatus) && (
          <FooterAction
            hideSave={usersDetails.orgUserStatus === 'Approved'}
            saveLabel="Approve"
            canceLabel={
              usersDetails.orgUserStatus === 'Approved' ? 'Revoke' : 'Decline'
            }
            onSave={onApproveRequest}
            onToggle={onDenyRequest}
            disabled={loading}
          />
        )
      }
      className="user-details-fullScreen"
    >
      <UserDetailsCards
        usersDetails={usersDetails}
        roleId={usersDetails?.roleId}
        lobs={lobs}
        affiliates={affiliates}
        isExternal={usersDetails?.orgUserType === "external"}
      />
      {approvePopup && (
        <ReusableModal
          open={approvePopup}
          handleClose={handleClose}
          onChangeApproval={onChangeApproval}
          title={REUSABLE_MODAL.DIALOG_TITLE}
          context={REUSABLE_MODAL.DIALOG_CONTEXT}
          primarybtn={REUSABLE_MODAL.PRIMARY_BTN}
          secondarybtn={REUSABLE_MODAL.SEC_BTN}
          isShowReason={false}
          handleConfirmApprove={handleConfirmApprove}
        />
      )}
      {declinePopup && (
        <ReusableModal
          status={usersDetails?.orgUserStatus}
          open={declinePopup}
          handleClose={handleCloseConfirmation}
          title={
            usersDetails?.orgUserStatus === 'Approved'
              ? REQUEST_MODAL.REVOKE_DIALOG_TITLE
              : REQUEST_MODAL.DECLINE_DIALOG_TITLE
          }
          context={
            usersDetails?.orgUserStatus === 'Approved'
              ? REQUEST_MODAL.REVOKE_DIALOG_CONTEXT
              : REQUEST_MODAL.DECLINE_DIALOG_CONTEXT
          }
          placeholder={
            usersDetails?.orgUserStatus === 'Approved'
              ? REQUEST_MODAL.PLACEHOLDER_REVOKE
              : REQUEST_MODAL.PLACEHOLDER_DECLINE
          }
          primarybtn={REQUEST_MODAL.PRIMARY_BTN}
          secondarybtn={REQUEST_MODAL.SEC_BTN}
          isShowReason={true}
          handleConfirmDenied={handleConfirmDenied}
          setDeclineReason={setDeclineReason}
          declineReason={declineReason}
        />
      )}
      <SimpleBackdrop open={loading} />
    </FullScreenDialog>
  );
};

export default UsersDetailPage;
