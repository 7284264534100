import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Card as CustomCard } from 'components/widgets';
import TelemetryApi from 'services/api/TelemetryApi';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useCallback, useEffect, useState } from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: theme.spacing(2),
  },
  card: {
    width: 345,
  },
  cardContent: {
    minHeight: 160,
  },
  btn: {
    textTransform: 'none',
  },
  listItem: {
    margin: '8px 0',
  },
  numIcons: {
    display: 'inline',
    padding: '4px',
    marginRight: '5px',
    borderRadius: '50%',
    background: '#ffa400',
  },
  apiCard: {
    marginTop: theme.spacing(2),
    minHeight: 240,
  },
  name: {
    fontWeight: '600',
    fontSize: 28,
    paddingLeft: theme.spacing(2),
  },
  rank: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
    marginRight: 10,
    fontSize: 14,
    fontWeight: '600',
  },
  circularProgress: {
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  }
}));

function AppRankings({ orgId, affiliateId }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [value, setValue] = useState('DAY');

  const handleChange = event => {
    setValue(event.target.value);
  };

  const today = new Date();
  const startDate = new Date(
    today.getFullYear(),
    today.getMonth()-2,
    today.getDate(),
  )
    .toLocaleString('en-US')
    .split(',')
    .join('')
    .slice(0, -3);

  const getRankings = useCallback(() => {
    async function getRanks() {
      try {
        const details = await TelemetryApi.getRankings(
          orgId,
          affiliateId,
          value,
          startDate,
        );
        setData(details);
      } catch (e) {
        console.log(e);
      }
    }
    getRanks();
  }, [startDate, value, affiliateId, orgId]);

  useEffect(() => getRankings(), [getRankings]);

  let top3Rankings = [];

  if(data) {
    for (let item in data) {
      top3Rankings.push({name: item, calls: data[item]});
      top3Rankings = top3Rankings.slice(0,3);
    }
  }

  return (
    <>
      <CustomCard className={classes.apiCard}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography component="b" variant="h5">
              Applications ranking based on API calls
            </Typography>
            <FormControl className={classes.formControl}>
              <Select value={value} onChange={handleChange}>
                <MenuItem value={'DAY'}>Daily</MenuItem>
                <MenuItem value={'WEEK'}>Weekly</MenuItem>
                <MenuItem value={'MONTH'}>Monthly</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {data ? (
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    padding: '16px 2px',
                    height: '100%',
                    alignItems: 'center',
                  }}>
                  <Grid
                    container
                    spacing={2}
                    style={{ fontSize: 12, margin: 8 }}>
                    <Grid item xs={6}>
                      <span style={{ color: '#626262' }}>Rank</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span style={{ color: '#626262' }}>Calls</span>
                    </Grid>
                    {/* <Grid item xs={3}>
                    <span style={{ color: '#626262' }}>Members</span>
                  </Grid> */}

                    <Grid item xs={12}>
                      {top3Rankings
                        .sort((el1, el2) => el2.calls - el1.calls)
                        .map((item, index) => (
                          <Grid container spacing={4} key={index}>
                            <Grid item xs={6}>
                              <Box display="flex" alignItems="center">
                                <span className={classes.rank}>
                                  {index + 1}
                                </span>
                                {/* <img src={item.icon} alt={item.name} /> */}
                                <Typography
                                  style={{ fontSize: 12, paddingLeft: 5 }}>
                                  {item.name}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              {item.calls}
                            </Grid>
                            {/* <Grid item xs={3}>
                            {item.members}
                          </Grid> */}
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.circularProgress}>
              <CircularProgress color="secondary" size={70} />
            </div>
          )}
        </CardContent>
      </CustomCard>
    </>
  );
}

export default AppRankings;
