import React, { useEffect, useMemo, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Grid,
} from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
  AlertDialog,
} from 'components/widgets';
import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CLOSE_ALERT_DESCRIPTION } from 'shared/constants';
import CreateEditCohort from '../cohort/CreateEditCohort';
import CampaignApi from 'services/api/CampaignApi';
import { PaperContainer } from 'components/widgets/PaperContainer';
import { MembersCount } from '../MembersCount';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 1px 16px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#F5F7F8',
    padding: '10px',
    borderRadius: '8px',
  },
  cohortGrpContainer: {
    backgroundColor: '#E4E7EC',
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '10px',
    padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    padding: '0px 16px',
    width: '100%',
  },
  cohortSummaryContainer: {
    backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
  },
  cohortGrpSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  createButton: {
    marginLeft: 'auto',
    border: 'none',
    fontSize: '12px',
    padding: '2px 10px',
  },
}));

export default function CohortForCampaign({
  campaignValues,
  setSavedCohort,
  setEditCohort,
  tabStatus,
  setCampaign,
  hasCreatePermissions
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [cohortOptions, setCohortOptions] = useState([]);
  const [selectedCohort, setSelectedCohort] = useState(
    campaignValues?.cohortId || ''
  );
  const [label, setlabel] = useState(campaignValues?.cohortId || '');
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const orgId = sessionStorage.getItem("orgId");
  const isCohortSavedBefore = useRef(campaignValues?.cohortId ? true : false);
  const [saveCohortStatus, setSaveCohortStatus] = useState(
    campaignValues?.cohortId ? tabStatus?.SAVED : tabStatus?.NOT_SAVED
  );
  const [openCohort, setOpenCohort] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [cohortGrpValue, setcohortGrpValue] = useState(
    campaignValues?.cohortId || 'placeholder'
  );
  const [memberCount, setMemberCount] = useState();
  const [memberCountLoading, setMemberCountLoading] = useState(false);

  const getAllCohortList = useCallback(() => {
    async function getCohort() {
      try {
        const results = await CampaignApi.getAllCohorts({
          orgId: orgId,
          offset: 0,
          limit: 1000,
          searchText: '',
        });
        const arrayValue = [];
        results?.data?.map(a => {
          arrayValue.push({
            id: a.id,
            name: a.name,
          });
        });
        setCohortOptions(arrayValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    }
    getCohort();
  });

  useEffect(() => {
    getAllCohortList();
  }, []);

  const handleCloseCohort = () => {
    if (flag) {
      setCloseAlert(true);
    } else {
      setOpenCohort(false);
      setIsEdit(false);
    }
  };

  const closeOnCohortCreation = () => {
    setOpenCohort(false);
    setIsEdit(false);
  };

  const closeConfirm = () => {
    setCloseAlert(false);
    setOpenCohort(false);
    setIsEdit(false);
    setFlag(false);
  };

  const handlecreateCohort = data => {
    cohortOptions.push({
      id: data.id,
      name: data.name,
    });
  };

  const handleCohortSelection = e => {
    handleCohortStatus();
    setSelectedCohort(e.target.value);
    // console.log(count);
    // setSelectedCohortMemberCount(count);
  };

  const handleClearCohort = () => {
    setSelectedCohort('');
    setlabel('');
    setExpanded(false);
    setEditCohort(false);
    setcohortGrpValue('placeholder');
  };

  const handleCohortStatus = () => {
    if (saveCohortStatus === tabStatus?.SAVED) {
      setSaveCohortStatus(tabStatus?.NOT_SAVED);
      setSavedCohort(false);
    }
  };

  const handleOnchange = val => {
    setlabel(val);
    handleCohortStatus();
    setEditCohort(true);
    setcohortGrpValue(selectedCohort);
  };

  const handleEditCohort = () => {
    setSaveCohortStatus(tabStatus?.NOT_SAVED);
    setSavedCohort(false);
    setEditCohort(true);
    setExpanded(prevValue => !prevValue);
  };

  const closeCohortWithoutSaving = () => {
    setSaveCohortStatus(tabStatus?.SAVED);
    setSavedCohort(true);
    setEditCohort(false);
    setExpanded(prevValue => !prevValue);
    setSelectedCohort(campaignValues?.cohortId);
    setcohortGrpValue(campaignValues?.cohortId);
  };

  const handleCriteriaValidations = () => {
    if (!selectedCohort) {
      enqueueSnackbar('Please select one option', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else {
      return true;
    }
    return false;
  };

  const handleSaveCohort = async () => {
    if (!handleCriteriaValidations()) return;
    setLoading(true);
    setSaveCohortStatus(tabStatus?.SAVING);
    handleOnchange();
    // setcohortGrpValue([
    //     {
    //       actions: [
    //           {
    //               actionId: selectedCohort,
    //               actionName: selectedCohort,
    //               value: campaignValues.ruleDefn.actions[0].value === label ? null : label,
    //               labelCode: campaignValues.ruleDefn.actions[0].labelCode,
    //               parameters: null,
    //               createLabel: true
    //           }
    //       ]
    //     },
    //   ]);
    let checkForEdit = campaignValues.id;

    let payload = {
      ...campaignValues,
      cohortId: selectedCohort,
    };

    let campaignId = payload.id;
    // todo Add Save API
    try {
      let results = await CampaignApi.saveCampaign({
        orgId,
        payload,
        campaignId,
      });
      setCampaign(results);
      setExpanded(false);
      setLoading(false);
      isCohortSavedBefore.current = true;
      setSaveCohortStatus(tabStatus?.SAVED);
      setSavedCohort(true);
      setEditCohort(false);
    } catch {
      setSaveCohortStatus(tabStatus?.REJECTED);
      setSavedCohort(false);
      setEditCohort(true);
      setLoading(false);
    }
  };

  const savedStatusStatus = useMemo(() => {
    if (saveCohortStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
          title="saving"
        />
      );
    } else if (saveCohortStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
          titleAccess="saved"
        />
      );
    } else if (saveCohortStatus === tabStatus?.REJECTED) {
      return (
        <HighlightOffIcon
          style={{ color: 'red', fontSize: '22px' }}
          titleAccess="rejected"
        />
      );
    } else {
      return (
        <CheckCircleOutlineIcon
          style={{ color: 'grey', fontSize: '22px' }}
          titleAccess="not-saved"
        />
      );
    }
  }, [saveCohortStatus]);

  const updateMemberCount = () => {
    setMemberCountLoading(true);
    CampaignApi.getMemberCountByID({ orgId, cohortId: campaignValues.cohortId })
      .then(res => {
        setMemberCount(res?.patientCount);
        setMemberCountLoading(false);
      })
      .catch(err => {
        setMemberCountLoading(false);
        setMemberCount();
      });
  };

  useEffect(() => {
    if (campaignValues.cohortId) {
      updateMemberCount();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignValues.cohortId]);
  return (
    <div className={classes.root}>
      <Accordion
        className={classes.containerBox}
        expanded={expanded}
        // onChange={() => setExpanded(prevValue => !prevValue)}
        data-testid="cohort-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              data-testid="cohort-status-icon">
              {savedStatusStatus}
              <Typography
                data-testid="cohort-title"
                className={classes.heading}>
                Cohort
              </Typography>
              {!expanded && (
                <Box style={{ marginLeft: 'auto' }}>
                  {campaignValues?.status === 'DRAFT' ? (
                    isCohortSavedBefore.current ? (
                      <SecondaryButton
                        onClick={handleEditCohort}
                        data-testid="edit-cohort-btn" hasPermissions={hasCreatePermissions}>
                        Edit
                      </SecondaryButton>
                    ) : (
                      <SecondaryButton
                        onClick={() => setExpanded(prevValue => !prevValue)}
                        data-testid="add-cohort-btn"
                        hasPermissions={hasCreatePermissions}
                        >
                        Add cohort
                      </SecondaryButton>
                    )
                  ) : (
                    ''
                  )}
                </Box>
              )}
            </Box>
            <span style={{ marginLeft: '34px', fontSize: '14px' }}>
              Who are you sending this campaign to?
            </span>
            {saveCohortStatus === 'SAVED' && !expanded && (
              <Box
                className={classes.cohortGrpSummaryContainer}
                style={{ marginLeft: '30px' }}>
                {cohortGrpValue && (
                  <Box display={'flex'} alignItems={'center'}>
                    <Box className={classes.cohortSummaryContainer}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        style={{ gap: '10px' }}>
                        <Typography variant="h6">
                          <b>Cohort name : </b>
                          {cohortOptions.map(i => {
                            if (i.id === cohortGrpValue) {
                              return i.name;
                            }
                          })}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <MembersCount
                        memberCountValue={memberCount}
                        memberCountLoading={memberCountLoading}
                        onRefresh={updateMemberCount}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            <Box
              className={classes.cohortGrpContainer}
              style={{ margin: '10px 0px', display: 'flex' }}>
              <Grid item sm={3} xs={3} className={classes.mt}>
                <Box style={{ display: 'flex' }}>
                  <Typography className={classes.formTitle}>
                    Choose from saved cohorts
                  </Typography>
                  <SecondaryButton
                    className={classes.createButton}
                    onClick={() => setOpenCohort(true)}
                    data-testid="create-cohort-btn">
                    Create new
                  </SecondaryButton>
                </Box>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ maxWidth: '100%', marginTop: '3%' }}>
                  <Select
                    data-testid="cohort-dropdown"
                    style={{ backgroundColor: 'white' }}
                    value={selectedCohort}
                    onChange={e => {
                      handleCohortSelection(e);
                      handleOnchange();
                    }}
                    className={classes.formTextField}
                    margin="dense"
                    placeholder="Select type"
                    IconComponent={ExpandMoreIcon}
                    defaultValue="">
                    {cohortOptions.map(i => {
                      return (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            <Box style={{ margin: '10px 0px' }}>
              <Box style={{ margin: '20px 0px', display: 'flex', gap: '10px' }}>
                <SecondaryButton
                  onClick={
                    isCohortSavedBefore.current
                      ? closeCohortWithoutSaving
                      : handleClearCohort
                  }
                  disabled={
                    saveCohortStatus === tabStatus?.SAVING ||
                    saveCohortStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel-cohort-btn">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    cohortGrpValue === 'placeholder' ||
                    saveCohortStatus === tabStatus?.SAVING ||
                    saveCohortStatus === tabStatus?.SAVED ||
                    selectedCohort === campaignValues?.cohortId
                  }
                  onClick={handleSaveCohort}
                  data-testid="save-cohort-btn">
                  {saveCohortStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
          {openCohort && (
            <CreateEditCohort
              open={openCohort}
              handleClose={handleCloseCohort}
              isEdit={isEdit}
              fromCampaign={true}
              handleCampaignCohort={handlecreateCohort}
              setFlag={setFlag}
              closeOnCohortCreation={closeOnCohortCreation}
            />
          )}
          {closeAlert && (
            <AlertDialog
              open={closeAlert}
              onClose={() => setCloseAlert(false)}
              onConfirm={closeConfirm}
              title=""
              description={CLOSE_ALERT_DESCRIPTION}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <SimpleBackdrop open={loading} />
    </div>
  );
}
