import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 36 36" {...props}>
      <path d="M18 0.527344C8.32031 0.527344 0.527344 8.32031 0.527344 18C0.527344 27.6797 8.32031 35.4727 18 35.4727C27.6797 35.4727 35.4727 27.6797 35.4727 18C35.4727 8.32031 27.6797 0.527344 18 0.527344ZM13.2422 25.5469L7.00781 19.2305C6.26953 18.5742 6.26953 17.4258 7.00781 16.7695C7.66406 16.1133 8.73047 16.1133 9.46875 16.7695L14.4727 21.7734L26.5312 9.79688C27.1875 9.05859 28.3359 9.05859 28.9922 9.79688C29.7305 10.4531 29.7305 11.5195 28.9922 12.2578L15.7031 25.5469C15.0469 26.2031 13.9805 26.2031 13.2422 25.5469Z" />
    </SvgIcon>
  );
};

export default Icon;
