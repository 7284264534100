import { useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import DragFileIcon from '../Icons/DragFileIcon';
import { convertStrToTitleCase, getFileNameAndExtension } from '../helper';
import {
  FILE_UPLOAD_COMPONENT_TEXTS,
  SELF_REPORTING_TEXTS,
  SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES,
} from '../constants';
import LinearStepper from '../LinearStepper';
import colors from '../colors';
import Button from '../inputs/index';
import clsx from 'clsx';
import ClearIcon from '@mui/icons-material/Clear';
import PdfIcon from '../../../assets/pdf.png';
import ImageIcon from '../../../assets/image_icon.png';
// import DocumentService from '../../../service/DocumentService/index.js';
// import { SELF_REPORTED_DOCUMENTS_BUCKET_NAME } from '../../../service/DocumentService/index.js';
import Loader from '../Loader';
import SnackbarToast from '../SnackbarToast';
import ConfirmationModal from '../ConfirmationModal';
import DocumentSuccessIcon from '../../../assets/document_success.png';

const styles = (theme) => ({
  container: {
    backgroundColor: colors.WHITE,
    padding: theme.spacing(4),
    minHeight: 352,
    borderRadius: 8,
  },
  uploadDiv: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 33px',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#8C8C8C',
    borderStyle: 'dashed',
    minHeight: 176,
    transition: 'border .3s ease-in-out',
    marginTop: 40,
  },
  btnWrapper: {
    marginTop: theme.spacing(4),
  },
  btn: {
    width: 'auto',
    height: 48,
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Inter',
    padding: '8px 24px',
    backgroundColor: colors.WHITE,
    borderRadius: 40,
    marginRight: 24,
  },
  backBtn: {
    color: '#036FCB',
    border: `1px solid ${colors.SUBTLE_GRAY}`,
  },
  saveBtn: {
    color: '#FFFFFF',
    backgroundColor: '#036FCB',
    width: 'auto',
  },
  disabledBtn: {
    color: '#FFFFFF',
    backgroundColor: '#8BC5F5',
    padding: '8px 24px',
  },
  acceptStyle: {
    borderColor: '#00e676',
  },
  rejectStyle: {
    borderColor: '#ff1744',
  },
  activeStyle: {
    borderColor: '#2196f3',
  },
  chooseButton: {
    marginTop: 22,
  },
  textWrapper: {
    marginTop: 16,
    fontFamily: 'Inter',
    textAlign: 'center',
    color: '#333333',
    opacity: 0.87,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.5,
  },
  fileType: {
    color: '#333333',
    fontWeight: 500,
    fontSize: 14,
    opacity: 0.5,
    fontFamily: 'Inter',
    lineHeight: 0,
  },
  textDrop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#4B4B4B',
    lineHeight: '0px',
    marginTop: 12,
    textAlign: 'center',
  },
  dropFileIcon: {
    marginTop: 8,
    width: 56,
    height: 56,
    opacity: 0.4,
    fontSize: 56,
    zIndex: 999,
  },
  fileIcon: { position: 'relative' },
  dragFileText: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#000000',
    lineHeight: 0,
    marginTop: 30,
  },
  fileDescriptionIcon: {
    fontSize: 40,
    color: '#036FCB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
  },
  chooseFileButton: {
    backgroundColor: '#fff',
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    width: 130,
    display:"inline-flex",
    alignItems:"center",
    height: 32,
    borderRadius: 40,
    padding: '8px 24px 8px 24px',
    '&:hover': {
      backgroundColor: '#036FCB',
      opacity: '0.6',
      color: 'white',
    },
  },
  fileUploadHeading: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 12,
    color: 'rgba(0,0,0,0.68)',
    lineHeight: 0,
  },
  chooseOtherButton: {
    backgroundColor: '#036FCB',
    border: '1px solid #A0A4A6',
    boxSizing: 'border-box',
    borderRadius: 40,
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#f2f2f2',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    '&:hover': {
      opacity: '0.8',
    },
  },
  chooseAnotherFileButtonFailedCard: {
    backgroundColor: '#036FCB',
    border: '1px solid #A0A4A6',
    boxSizing: 'border-box',
    borderRadius: 40,
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#f2f2f2',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
    '&:hover': {
      opacity: '0.8',
    },
  },
  deleteButton: {
    backgroundColor: '#ffffff',
    border: '1px solid #A0A4A6',
    boxSizing: 'border-box',
    borderRadius: 40,
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#036FCB',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    marginRight: 16,
    '&:hover': {
      backgroundColor: '#036FCB',
      opacity: '0.6',
      color: 'white',
    },
  },
  btnContainer: {
    display: 'flex',
    marginTop: 12,
  },
  failedCardHeading: {
    marginTop: 8,
    color: '#4B4B4B',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  failedCardText: {
    marginTop: 4,
    color: '#4B4B4B',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  failedCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadHeading: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 16,
    color: '#4B4B4B',
    marginBottom: 16,
  },
  fileUploadProgressBarContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chooseAnotherFileContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileCardContainer: {
    width: '100%',
    height: 56,
    border: '1px solid #D0D0D0',
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  fileCardTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#4B4B4B',
  },
  imgIcon: {
    height: 32,
    width: 24,
  },
  closeIcon: { cursor: 'pointer' },
  documentSuccessIcon: { marginLeft: 12 },
});

const { SAVE, SAVE_CONTINUE, SAVE_CHANGES, BACK } = SELF_REPORTING_TEXTS;

function FileUpload({
  classes,
  docArn,
  handleFileLoad,
  activeStep,
  steps,
  buttonText,
  handleBack,
  handleSave,
  resourceId,
  resourceName,
  documentReferenceId,
  onUploadDocumentSuccess,
  isEdit = false,
  markAsRestricted = 'false',
  ...rest
}) {
  const inputFile = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fileDropper, setFileDropper] = useState(false);
  const [files, setFiles] = useState(docArn || []);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [currentStep, setCurrentStep] = useState(Number(activeStep) + 1);

  const isFilePresent = (selectedFile) => {
    if (!selectedFile) {
      setFileDropper(false);
      return;
    }
  };

  useEffect(() => {
    // if(isEdit && files.length === 0 && docArn && docArn.length === 0){
    //   setSaveDisabled(true);
    //   setCurrentStep(activeStep);
    //   return;
    // }
    // if (isEdit && documentReferenceId && files.length === 0) {
    //   setSaveDisabled(false);
    //   setCurrentStep(Number(activeStep) + 1);
    //   return;
    // }
    // if (!files || files.length === 0) {
    //   setSaveDisabled(true);
    //   setCurrentStep(activeStep);
    //   return;
    // }
    // files.forEach((item) => {
    //   if (item.selectedFile) {
    //     // setSaveDisabled(false);
    //     setCurrentStep(Number(activeStep) + 1);
    //   }
    // });
  }, [files.length]);

  const loadFile = (selectedFile) => {
    const fileNameAndExtension = getFileNameAndExtension(selectedFile.name);
    const fileExtension = fileNameAndExtension['extension'];
    if (
      !fileExtension ||
      !FILE_UPLOAD_COMPONENT_TEXTS.FILE_FORMATS_ALLOWED.includes(fileExtension.toUpperCase())
    ) {
      setShowSnackbar(true);
      setSnackbarMessage(FILE_UPLOAD_COMPONENT_TEXTS.UNSUPPORTED_FILE_TYPE);
      return;
    }
    if (selectedFile && selectedFile.size < SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES) {
      const fileNameWithoutExtension = fileNameAndExtension['fileNameWithoutExtension'];
      let fileSizeInBytes = selectedFile && selectedFile.size;
      let i = -1;
      const byteUnits = [' KB', ' MB'];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);
      const fileSize = Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i] || 0;

      const fileExists =
        files &&
        files.filter(
          (file) =>
            file.fileNameWithoutExtension &&
            file.fileNameWithoutExtension === fileNameWithoutExtension,
        );
      if (fileExists && fileExists.length > 0) {
        setFileDropper(false);
        setFiles((prevFile) => [...prevFile]);
        return;
      }
      setFiles((prevFile) => [
        ...prevFile,
        { selectedFile, fileNameWithoutExtension, fileExtension, fileSize },
      ]);
      setSaveDisabled(false);
      setFileDropper(false);
      setShowSnackbar(false);
      setSnackbarMessage('');
      return;
    } else {
      setShowSnackbar(true);
      setSnackbarMessage(FILE_UPLOAD_COMPONENT_TEXTS.MAX_FILE_SIZE_ERROR);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        isFilePresent(file);
        loadFile(file);
      });
    },
    [handleFileLoad, fileDropper],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  // const handleClick = () => {
  //   setFileDropper(true);
  // };

  const fileOpen = (e) => {
    isFilePresent(e.target.files[0]);
    loadFile(e.target.files[0]);
  };

  const openFile = (e) => {
    // handleClick();
    inputFile.current.click();
  };

  const handleBackPress = () => {
    if (handleBack) {
      handleBack();
    }
  };

  const handleSavePress = async () => {
    setLoaderText(SELF_REPORTING_TEXTS.SAVING_DOCUMENTS_LOADER_TEXT);

    if (handleSave) {
      handleSave(files);
    }

    if (isEdit) {
      setOpenConfirmationModal(true);
    } else {
      handleConfirmYes();
    }
  };

  const saveDataToDb = async () => {
    const filesData = new FormData();
    filesData.append('resourceName', resourceName);
    filesData.append('resourceId', resourceId);
    // filesData.append('folder', SELF_REPORTED_DOCUMENTS_BUCKET_NAME);

    const alreadyAddedDocArns = [];
    files.forEach((item, index) => {
      if (item.selectedFile) {
        filesData.append('files', item.selectedFile);
      } else {
        alreadyAddedDocArns.push(item);
      }
    });

    try {
      let uploadRes = [];
      // if (files?.length > 0 && alreadyAddedDocArns?.length < files?.length) {
      //   uploadRes = await DocumentService.uploadSelfReportingDocuments(filesData);
      // }
      // if (!isEdit || (isEdit && !documentReferenceId)) {
      //   // create document reference
      //   await DocumentService.createDocumentReference({
      //     filesList: uploadRes,
      //     resourceName,
      //     resourceId,
      //     markAsRestricted: markAsRestricted ? 'restricted' : 'unrestricted',
      //   });
      // } else {
      //   await DocumentService.editDocumentReference(
      //     {
      //       filesList: [...alreadyAddedDocArns, ...uploadRes],
      //       resourceName,
      //       resourceId,
      //       markAsRestricted: markAsRestricted ? 'restricted' : 'unrestricted',
      //     },
      //     documentReferenceId,
      //   );
      // }
      return uploadRes;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setLoaderText('');
    }
  };

  const handleFileDelete = (id) => {
    const filesAfterRemoving = files.filter((file) => files.indexOf(file) !== id);
    setFiles(filesAfterRemoving);
    setSaveDisabled(false);
  };

  const renderFileView = (file) => {
    let fileInfo = {};
    if (!file.selectedFile) {
      fileInfo = getFileNameAndExtension(file);
    }
    return (
      <div className={classes.fileCardTextWrapper}>
        {file.fileExtension === 'pdf' || fileInfo.extension === 'pdf' ? (
          <img src={PdfIcon} alt="file-icon" style={{ marginRight: 16 }} />
        ) : (
          <img
            src={ImageIcon}
            className={classes.imgIcon}
            alt="file-icon"
            style={{ marginRight: 16 }}
          />
        )}
        <span>
          {' '}
          {file.fileNameWithoutExtension || fileInfo['fileNameWithoutExtension']}
          {file.fileSize && `  (${file.fileSize} ${file.fileExtension})`}
          {fileInfo.extension && `  (${fileInfo.extension})`}
        </span>
        <img className={classes.documentSuccessIcon} src={DocumentSuccessIcon} />
      </div>
    );
  };

  const handleConfirmCancel = async () => {
    setIsLoading(false);
    setOpenConfirmationModal(false);
  };

  const handleConfirmYes = async () => {
    setIsLoading(true);
    setOpenConfirmationModal(false);
    // if (isEdit && documentReferenceId && files.length === 0) {
    //   // setSaveDisabled(false);
    //   setCurrentStep(Number(activeStep) + 1);
    //   saveDataToDb();
    // } else {
    // check if any new file is added or not and call api if added
    const newFileAdded = files.find((obj) => obj.selectedFile && obj.selectedFile !== null);
    if (newFileAdded || isEdit) {
      const res = await saveDataToDb();
      onUploadDocumentSuccess(res);
      setIsLoading(false);
    } else {
      onUploadDocumentSuccess([]);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <Loader overlay text={loaderText} />}
      <div className={classes.container}>
        <div>
          <LinearStepper activeStep={activeStep} steps={steps} />
        </div>
        <div>
          {files?.map((file, idx) => (
            <div className={classes.fileCardContainer} key={file + idx}>
              {renderFileView(file)}
              <ClearIcon onClick={(e) => handleFileDelete(idx)} className={classes.closeIcon} />
            </div>
          ))}
        </div>
        <div {...getRootProps()} className={classes.uploadDiv}>
          <input
            type="file"
            ref={inputFile}
            accept={FILE_UPLOAD_COMPONENT_TEXTS.ACCEPTING_FILES_FOR_UPLOAD}
            onChange={(e) => fileOpen(e)}
            style={{ display: 'none' }}
          />
          {!fileDropper && (
            <div onClick={(e) => openFile(e)}>
              <input {...getInputProps()} />
              <p className={classes.fileType}>
                {FILE_UPLOAD_COMPONENT_TEXTS.CHOOSE_FILE_CONTAINER_TEXT.HEADING}
              </p>
              <p className={classes.textWrapper}>
                {FILE_UPLOAD_COMPONENT_TEXTS.CHOOSE_FILE_CONTAINER_TEXT.SUB_HEADING}
              </p>
              <button type="file" className={classes.chooseFileButton}>
                {FILE_UPLOAD_COMPONENT_TEXTS.CHOOSE_FILE_CONTAINER_TEXT.CHOOSE_FILE_BUTTON_TEXT}
              </button>
            </div>
          )}

          {fileDropper && (
            <div onClick={(e) => openFile(e)}>
              <p className={classes.fileType}>
                {FILE_UPLOAD_COMPONENT_TEXTS.CHOOSE_FILE_CONTAINER_TEXT.HEADING}
              </p>
              <p className={classes.dragFileText}>
                {FILE_UPLOAD_COMPONENT_TEXTS.FILE_DROPER_TEXTS.HEADING}
              </p>
              <DragFileIcon className={classes.dropFileIcon} />
            </div>
          )}

          {/* {!error && file && file.size < SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES && (
            <div className={classes.chooseAnotherFileContainer}>
              <p>
                <FileIcon className={classes.filedescriptionIcon} />
              </p>
              <p className={classes.textDrop}>
                {`${getFileNameAndExtension()} (${getFileSize()}, ${getExtension()})`}
              </p>
              <div className={classes.btnContainer}>
                <button type="button" onClick={handleDeletFile} className={classes.deleteButton}>
                  {FILE_UPLOAD_COMPONENT_TEXTS.OTHER_FILE_SELECTION_TEXTS.DELETE_BUTTON_TEXT}
                </button>
                <button
                  type="file"
                  onClick={(e) => openFile(e)}
                  className={classes.chooseOtherButton}
                >
                  {FILE_UPLOAD_COMPONENT_TEXTS.OTHER_FILE_SELECTION_TEXTS.CHOOSE_ANOTHER_FILE_TEXT}
                </button>
              </div>
            </div>
          )} */}

          {/* {file && !error && file.size < SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES &&  (
            <div className={classes.fileUploadProgressBarContainer}>
              <p className={classes.uploadheading}>
                {fileloaded === 100 ? 'Uploaded' : 'Uploading'}
              </p>
              <p className={classes.textDrop}>
                {`${getFileNameAndExtension()} (${getFileSize()}, ${getExtension()})`}
              </p>
              <FileUploadProgressBar fileloaded={fileloaded} />
            </div>
          )} */}
          {/* {error && (
            <div className={classes.failedCardWrapper} {...getRootProps()}>
              <FailedIcon className={classes.dropfileIcon} />
              <Typography className={classes.failedCardHeading}>
                {FILE_UPLOAD_COMPONENT_TEXTS.FAILED_FILE_UPLOADCARD_TEXTS.HEADING}
              </Typography>
              <Typography className={classes.failedCardText}>
                {FILE_UPLOAD_COMPONENT_TEXTS.FAILED_FILE_UPLOADCARD_TEXTS.SUB_HEADING}
              </Typography>
              <button
                type="file" 
                onClick={openFile}
                className={classes.chooseAnotherFileButtonFailedCard}
              >
                {
                  FILE_UPLOAD_COMPONENT_TEXTS.FAILED_FILE_UPLOADCARD_TEXTS
                    .CHOOSE_ANOTHER_FILE_BUTTON_TEXT
                }
              </button>
            </div>
          )} */}
        </div>
      </div>

      <div className={classes.btnWrapper}>
        <button
          onClick={() => handleBackPress()}
          // disabled={submitDisabled}
          className={clsx(classes.btn, classes.backBtn)}
        >
          {buttonText || BACK}
        </button>
        <button
          onClick={() => handleSavePress()}
          disabled={saveDisabled}
          className={clsx(classes.btn, classes.saveBtn, saveDisabled ? classes.disabledBtn : '')}
        >
          {buttonText || isEdit ? SAVE_CHANGES : SAVE}
        </button>
      </div>

      {showSnackbar && (
        <SnackbarToast
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSnackbar}
          autoHideDuration={4000}
          alertStyle={{ backgroundColor: '#1F1F1F', borderRadius: 4, color: '1F1F1F' }}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
        />
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          isOpen={openConfirmationModal}
          handleCancel={() => handleConfirmCancel()}
          handleYes={() => handleConfirmYes()}
          setIsOpen={setOpenConfirmationModal}
        />
      )}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(FileUpload);
