import {
  BackendSearchTextField,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import RequestGrid from './RequestGrid';
import { useState, useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';
import NotFoundPage from 'components/NotFoundPage';
import OrganizationApi from 'services/api/OrganizationApi';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import ViewRequestsFlyoutContent from '../ViewRequestFlyoutContent';
import moment from 'moment';

const requestStatus = tabIndex => {
  // Pending -> null , Approved -> 1 , Declined -> 0
  if (tabIndex === 0) return '';
  else if (tabIndex === 1) return 1;
  else return 0;
};

const getStatusName = tabIndex => {
  if (tabIndex === 0) return 'PENDING';
  else if (tabIndex === 1) return 'APPROVED';
  else return 'DECLINED';
};

const RequestListingPage = ({ tabIndex }) => {
  const [rowData, setRowData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [offset, setOffset] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [drawerOpenPage, setDrawerOpenPage] = useState(false);

  const [flyout, setFlyout] = useState({ open: false });
  const [organizationDetails, setOrganizationDetails] = useState({});

  const [requestedOn, setRequestOn] = useState('');
  let formattedDate = moment(requestedOn).format('MMM DD, YYYY');

  const isInitialRender = useRef(false);
  const noRequestAvailable = useRef(false);
  const cacheRowData = useRef(new Map());

  const cacheStatus = useRef(
    new Map([
      ['PENDING', false],
      ['APPROVED', false],
      ['DECLINED', false],
    ])
  );

  const fetchRowData = (searchText, offset, statusValue) => {
    if (apiLoading) return;
    setApiLoading(true);
    if (statusValue) {
      cacheStatus.current.set('PENDING', false);
      cacheStatus.current.set(statusValue, false);
    }
    const status = requestStatus(tabIndex);
    const tabName = getStatusName(tabIndex);
    OrganizationApi.getAllOrganizationsRequest({ status, searchText, offset })
      .then(response => {
        if ((!isInitialRender.current && response.data.length === 0) || (statusValue && !cacheStatus.current.get(statusValue) && response.data.length === 0)) {
          noRequestAvailable.current = true;
        }
        isInitialRender.current = true;

        setRowData(response.data);
        setTotalCount(response.totalItems);
        if (
          !cacheRowData.current.has(tabIndex) ||
          !cacheStatus.current.get(tabName)
        ) {
          cacheRowData.current.set(tabIndex, response);
          cacheStatus.current.set(tabName, true);
        }
        setApiLoading(false);
      })
      .catch(err => {
        setApiLoading(false);
        resetData();
      });
  };

  const setCachedRowData = () => {
    if (cacheRowData.current.has(tabIndex)) {
      let rowValue = cacheRowData.current.get(tabIndex);
      if (noRequestAvailable.current && rowValue.data.length) {
        noRequestAvailable.current = false;
      } else if (rowValue.data.length === 0) noRequestAvailable.current = true;

      setRowData(rowValue.data);
      setTotalCount(rowValue.totalItems || 0);
    }
    isInitialRender.current = true;
  };

  const paginationClick = pageNumber => {
    setOffset(pageNumber - 1);
  };

  const resetData = () => {
    setRowData([]);
    setSearchText('');
  };

  const onClearData = () => {
    setSearchText('');
    setOffset(0);
    setCachedRowData();
  };

  const onChangeText = text => {
    setSearchText(text);

    if (text === '' && rowData.length === 0) {
      setCachedRowData();
    }
  };

  useEffect(() => {
    setSearchText('');
    setOffset(0);
    setRowData([]);
    isInitialRender.current = false;
    noRequestAvailable.current = false;
    const tabName = getStatusName(tabIndex);
    if (
      cacheRowData.current.has(tabIndex) &&
      cacheStatus.current.get(tabName)
    ) {
      setCachedRowData();
    } else {
      fetchRowData('', 0);
    }
  }, [tabIndex]);

  useEffect(() => {
    fetchRowData(searchText, offset);
  }, [offset]);

  const onSearchClick = () => {
    setOffset(0);
    fetchRowData(searchText, 0);
    // setIsInitialRender(false);
    // setSearchText(searchValue);
  };

  const onToggle = () => {
    setFlyout({ open: false });
  };

  const handleClose = () => {
    setDrawerOpenPage(false);
    setOrganizationDetails({});
  };

  const getOrgnizationDetails = async orgId => {
    setApiLoading(true);
    try {
      const results = await OrganizationApi.getAllOrganizationsRequestDetail({
        orgId,
      });
      setApiLoading(false);
      return results;
    } catch (error) {
      setApiLoading(false);
      console.log('error: ', error);
    }
  };


  const onViewClick = async (item = null, orgId = null) => {
    const res = await getOrgnizationDetails(item !== null ? item.orgId : orgId);
    setOrganizationDetails(res);

    if(item !== null) {
      setRequestOn(item.requestedOn);
      setDrawerOpenPage(true);
    }

  };

  return (
    <div>
      {!noRequestAvailable.current && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '24px',
          }}>
          <div
            style={{
              width: '100%',
              maxWidth: '280px',
              marginRight: '16px',
              height: '40px',
            }}>
            <BackendSearchTextField
              label="Search by organization name"
              onChangeText={onChangeText}
              value={searchText}
              clearData={onClearData}
              onSearchClick={onSearchClick}
            />
          </div>
          <SecondaryButton
            style={{ marginLeft: '-8px' }}
            onClick={onSearchClick}
            data-testid="search">
            Search
          </SecondaryButton>
        </div>
      )}
      {rowData.length > 0 && (
        <Grid item xs={12} style={{ paddingBottom: '10px' }}>
          <RequestGrid
            rowData={rowData}
            apiLoading={apiLoading}
            tabIndex={tabIndex}
            onView={onViewClick}
          />
          <PaginationComponent
            numberOfRowsPerPage={10}
            count={totalCount}
            onPageClick={paginationClick}
            page={offset}
          />
        </Grid>
      )}

      {drawerOpenPage && (
        <FullScreenDialog open={drawerOpenPage} handleClose={handleClose}>
          <ViewRequestsFlyoutContent
            handleCloseExternalFlyout={handleClose}
            setOffset={setOffset}
            onToggle={onToggle}
            organizationDetails={organizationDetails}
            tabIndex={tabIndex}
            refetchSolutions={onViewClick}
            formattedDate={formattedDate}
            setDrawerOpenPage={setDrawerOpenPage}
            drawerOpenPage={drawerOpenPage}
            fetchRowData={fetchRowData}
            {...flyout}
          />
        </FullScreenDialog>
      )}

      {searchText && rowData.length === 0 && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={'Request not found'}
            content={
              'We did not find the request matching your search, try searching with a different name'
            }
            showButton={false}
          />
        </div>
      )}

      {noRequestAvailable.current && !searchText && (
        <div style={{ marginTop: '32px' }}>
          <NotFoundPage
            title={'No Requests available'}
            content={''}
            showButton={false}
          />
        </div>
      )}

      <SimpleBackdrop open={apiLoading} />
    </div>
  );
};

export default RequestListingPage;
