import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { BUTTON_TITLE } from '../../../shared/constants';
import { PrimaryButton } from 'components/widgets';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginRight: '50px',
    marginLeft: '0px',
    justifyContent: 'flex-end',
  },
  viewButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    marginRight: '10px',
    padding: '6px 24px 6px 24px',
    height: 32,
  },
  exportButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    textTransform: 'none',
    color: '#036FCB',
    padding: '6px 24px 6px 24px',
    height: 32,
  },
}));

const ConsentRecordsButton = ({
  onExportRecordsClick,
  hasExportRecordPermissions,
}) => {
  const classes = useStyles();
  const { EXPORT_RECORD, VIEW_RECORD } = BUTTON_TITLE;
  return (
    <div className={classes.container}>
      {/* <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}>
        {VIEW_RECORD}
      </Button> */}
      <PrimaryButton
        variant="outlined"
        className={classes.exportButton}
        onClick={onExportRecordsClick}
        hasPermissions={hasExportRecordPermissions}
      >
        {EXPORT_RECORD}
      </PrimaryButton>
    </div>
  );
};

export default ConsentRecordsButton;
