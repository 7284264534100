import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path
        d="M18.2969 1.29688C17.6719 0.671875 16.8125 0.359375 15.9531 0.359375H4C2.20312 0.359375 0.640625 1.84375 0.640625 3.64062V30.3594C0.640625 32.1562 2.125 33.6406 4 33.6406H24C25.7969 33.6406 27.3594 32.1562 27.3594 30.3594V11.6875C27.3594 10.8281 26.9688 9.96875 26.3438 9.34375L18.2969 1.29688ZM19 27H9C8.0625 27 7.35938 26.2188 7.35938 25.3594C7.35938 24.4219 8.0625 23.6406 9 23.6406H19C19.9375 23.6406 20.6406 24.4219 20.6406 25.3594C20.6406 26.2188 19.9375 27 19 27ZM19 20.3594H9C8.0625 20.3594 7.35938 19.5781 7.35938 18.6406C7.35938 17.7812 8.0625 17 9 17H19C19.9375 17 20.6406 17.7812 20.6406 18.6406C20.6406 19.5781 19.9375 20.3594 19 20.3594ZM15.6406 10.3594V2.85938L24.8594 12H17.3594C16.4219 12 15.6406 11.2188 15.6406 10.3594Z"
        fill="#036FCB"
      />
    </SvgIcon>
  );
};

export default Icon;
