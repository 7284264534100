import { makeStyles } from '@material-ui/core';
import clsx from 'classnames';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  margin: {
    // margin: theme.spacing(2, 1.5),
  },
  searchControl: {
    backgroundColor: '#fff',
    width: '280px',
  },
}));

function BackendSearchTextField({
  label,
  onChangeText,
  value,
  clearData,
  onSearchClick,
}) {
  const classes = useStyles();

  const handleClear = () => {
    clearData();
  };

  return (
    <FormControl
      size="small"
      fullWidth
      className={clsx(classes.margin, classes.searchControl)}
      variant="outlined">
      <OutlinedInput
        id="outlined-adornment-amount"
        placeholder={label}
        value={value}
        onChange={e => onChangeText(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && onSearchClick()}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          value ? (
            <InputAdornment position="end">
              <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClear} />
            </InputAdornment>
          ) : null
        }
      />
    </FormControl>
  );
}

export default BackendSearchTextField;
