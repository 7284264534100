export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_INVALID_SESSION = 'AUTH_INVALID_SESSION';

export const ORGANIZATIONS_FETCH_START = 'ORGANIZATIONS_FETCH_START';
export const ORGANIZATIONS_FETCH_SUCCESS = 'ORGANIZATIONS_FETCH_SUCCESS';
export const ORGANIZATIONS_FETCH_FAILURE = 'ORGANIZATIONS_FETCH_FAILURE';

export const AFFILIATES_FETCH_SUCCESS = 'AFFILIATES_FETCH_SUCCESS';

export const USER_INFO_FETCH_START = 'USER_INFO_FETCH_START';
export const USER_INFO_FETCH_SUCCESS = 'USER_INFO_FETCH_SUCCESS';
export const USER_INFO_FETCH_FAILURE = 'USER_INFO_FETCH_FAILURE';
export const SAVE_SOLUTION_PERMISSIONS = 'SAVE_SOLUTION_PERMISSIONS';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_PAGE_HEADER_TITLE = 'SET_PAGE_HEADER_TITLE';

export const SHOW_SETUP_ORG = 'SHOW_SETUP_ORG';
export const HIDE_SETUP_ORG = 'HIDE_SETUP_ORG';

export const SHOW_ORG_ALERTS = 'SHOW_ORG_ALERTS';
export const HIDE_ORG_ALERTS = 'HIDE_ORG_ALERTS';

export const SHOW_SAVE_APPLICATION = 'SHOW_SAVE_APPLICATION';
export const HIDE_SAVE_APPLICATION = 'HIDE_SAVE_APPLICATION';

export const SELECTED_ORGANIZATION_FETCH_START =
  'SELECTED_ORGANIZATION_FETCH_START';
export const SELECTED_ORGANIZATION_FETCH_SUCCESS =
  'SELECTED_ORGANIZATION_FETCH_SUCCESS';
export const SELECTED_ORGANIZATION_FETCH_FAILURE =
  'SELECTED_ORGANIZATION_FETCH_FAILURE';

export const STATES_FETCH_START = 'STATES_FETCH_START';
export const STATES_FETCH_SUCCESS = 'STATES_FETCH_SUCCESS';
export const STATES_FETCH_FAILURE = 'STATES_FETCH_FAILURE';

export const ADDRESS_TYPES_FETCH_START = 'ADDRESS_TYPES_FETCH_START';
export const ADDRESS_TYPES_FETCH_SUCCESS = 'ADDRESS_TYPES_FETCH_SUCCESS';
export const ADDRESS_TYPES_FETCH_FAILURE = 'ADDRESS_TYPES_FETCH_FAILURE';

export const APPLICATIONS_FETCH_START = 'APPLICATIONS_FETCH_START';
export const APPLICATIONS_FETCH_SUCCESS = 'APPLICATIONS_FETCH_SUCCESS';
export const APPROVED_APPLICATIONS_FETCH_SUCCESS =
  'APPROVED_APPLICATIONS_FETCH_SUCCESS';
export const DENIED_APPLICATIONS_FETCH_SUCCESS =
  'DENIED_APPLICATIONS_FETCH_SUCCESS';
export const PENDING_APPLICATIONS_FETCH_SUCCESS =
  'PENDING_APPLICATIONS_FETCH_SUCCESS';
export const APPLICATIONS_FETCH_FAILURE = 'APPLICATIONS_FETCH_FAILURE';
export const APPLICATIONS_PENDING_OFFSET = 'APPLICATIONS_PENDING_OFFSET';
export const APPLICATIONS_APPROVED_OFFSET = 'APPLICATIONS_APPROVED_OFFSET';
export const APPLICATIONS_DENIED_OFFSET = 'APPLICATIONS_DENIED_OFFSET';
export const CLOSE_FLYOUT = 'CLOSE_FLYOUT';

export const APPLICATION_APPROVED_SEARCH = 'APPLICATION_APPROVED_SEARCH';
export const APPLICATION_APPROVED_TEXT = 'APPLICATION_APPROVED_TEXT';
export const APPLICATION_DENIED_SEARCH = 'APPLICATION_DENIED_SEARCH';
export const APPLICATION_DENIED_TEXT = 'APPLICATION_DENIED_TEXT';
export const APPLICATION_PENDING_SEARCH = 'APPLICATION_PENDING_SEARCH';
export const APPLICATION_PENDING_TEXT = 'APPLICATION_PENDING_TEXT';

export const MEMBER_DETAILS = 'MEMBER_DETAILS';
