import { makeStyles, Typography, Grid } from '@material-ui/core';
import { SearchTextField, SimpleBackdrop } from 'components/widgets';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import MasterDataApi from 'services/api/MasterDataApi';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import PriceListPagination from './PriceListPagination';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  container: {
    // justifyContent: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const PriceListSummary = ({ history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [lobs, setLobs] = useState([]);
  const [filteredLobs, setFilteredLobs] = useState([]);
  let affiliateId = sessionStorage.getItem('affiliateId'); //Temporarily filtering LOB based on selected affiliate until we get a dedicated API for that ! :P
  const orgId = sessionStorage.getItem('orgId');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState({
    role: '',
    lob: '',
    name: '',
  });
  const numberOfRowsPerPage = 10;

  const getAllLobs = useCallback(
    (pTypes = []) => {
      if (orgId) {
        setLoading(true);
        PartnerLOBApi.getAll(orgId)
          .then(results => {
            const data = results
              .filter(item => {
                let flag = false;
                for (let i = 0; i < item.affiliates.length; i++) {
                  if (item.affiliates[i].id === Number(affiliateId)) {
                    flag = true;
                    break;
                  }
                }
                return flag;
              }) //Temporary filtering. Will be replaced by proper endpoint for fetching only those LOBs with are associated with the particular affiliate. $_$
              .map(item => {
                return {
                  ...item,
                  planType: pTypes.filter(pt => pt.code === item.planTypeId)[0]
                    ?.name,
                };
              });
            setLobs(data);
            setLoading(false);
          })
          .catch(error => {
            // console.log('error: ', error);
            setLoading(false);
            enqueueSnackbar('Something went wrong fetching all LOBs', {
              variant: 'error',
            });
          });
      }
    },
    [orgId, affiliateId, enqueueSnackbar]
  );

  const getPlanTypes = useCallback(() => {
    MasterDataApi.getPlanTypes()
      .then(results => {
        const data = results.map(item => {
          return {
            code: item.id,
            name: item.name,
          };
        });
        getAllLobs(data);
      })
      .catch(err => {
        enqueueSnackbar('Something went wrong fetching plan types', {
          variant: 'error',
        });
      });
  }, [getAllLobs, enqueueSnackbar]);

  useEffect(() => {
    if (orgId) {
      getPlanTypes();
    }
  }, [orgId, getPlanTypes]);

  const onApplyFilter = (key, value) => {
    setSelectedFilter({ ...selectedFilter, [key]: value });
  };

  const { name } = selectedFilter;

  useEffect(() => {
    const data = lobs.filter(
      fItem =>
        fItem.name.toLowerCase().indexOf(name.trim().toLowerCase()) !== -1
    );
    setFilteredLobs(data);
  }, [name, lobs]);

  const onViewPriceList = lob => {
    history.push(
      `/organization/${orgId}/affiliate/${affiliateId}/price-list-summary/lob/${lob.id}/priceListDetails`
    );
  };

  return (
    <>
      <Typography
        variant="h2"
        style={{ margin: 16, fontWeight: 'bold', fontSize: 28 }}
      >
        {`LOBs`}
      </Typography>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <div>
                <SearchTextField
                  label="Search LOB"
                  onChangeText={val => onApplyFilter('name', val)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PriceListPagination
            rowData={filteredLobs}
            onViewPriceList={item => onViewPriceList(item)}
            numberOfRowsPerPage={numberOfRowsPerPage}
          />
        </Grid>
      </Grid>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default PriceListSummary;
