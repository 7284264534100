import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PaperContainer } from 'components/widgets/PaperContainer';
import UtilityIcon from '../../../components/icons/UtilityIcon';
import UtilityCheckIcon from '../../../components/icons/UtilityCheckIcon';
import { useParams } from 'react-router-dom';
import CampaignApi from 'services/api/CampaignApi';
import { CSVLink } from 'react-csv';
import { useRef } from 'react';
import { MembersCount } from '../MembersCount';
import moment from 'moment';
import { formatTimeInHHMMWithComma } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
    paddingBottom: '30px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  Papercontainer: {
    padding: '4px 4px 4px 8px',
    textAlign: 'center',
    backgroundColor: '#E4E7EC',
  },
  root1: { flexGrow: 1 },
}));

const DownloadFile = ({ transactionId }) => {
  const fileDownloadRef = useRef(null);
  const orgId = sessionStorage.getItem("orgId");
  const [reportDetail, setReportDetail] = useState([]);
  const formatteddata = reportDetail.map(reportData => {
    return { report: reportData };
  });

  const headers = [{ label: 'reportData', key: 'report' }];
  const csvReport = {
    data: formatteddata,
    headers: headers,
    filename: 'Error_report.csv',
  };

  const handlFileDownload = async () => {
    try {
      const reportdata = await CampaignApi.failedReport({
        orgId,
        transactionId,
      });
      setReportDetail(reportdata);
    } catch (error) {
      console.log('error: ', error);
    }

    fileDownloadRef.current.link.click();
  };
  return (
    <>
      {' '}
      <CSVLink
        {...csvReport}
        ref={fileDownloadRef}
        style={{ display: 'none' }}
        onClick={e => console.log(e.target['href'])}
      >
        Error_report.csv
      </CSVLink>
      <Typography>Error_report.csv</Typography>
      <GetAppIcon
        onClick={handlFileDownload}
        style={{
          color: '#036FCB',
          cursor: 'pointer',
          marginLeft: '4px',
          fontSize: '16px',
        }}
      />
    </>
  );
};

const CustomPaper = ({
  showUtilityCheckIcon,
  showUtilityIcon,
  title,
  value,
}) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      className={classes.Papercontainer}
      style={{
        width: '280px',
        backgroundColor: '#FFFF',
        padding: '12px',
        borderRadius: '12px',
      }}
    >
      <div style={{ display: 'flex' }}>
        {showUtilityCheckIcon && <UtilityCheckIcon />}
        {showUtilityIcon && <UtilityIcon />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginLeft: '16px',
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h3">{value}</Typography>
        </div>
      </div>
    </Paper>
  );
};

const ReportForCamapign = ({ fetchedData }) => {
  const transactionId = fetchedData.transactionId;
  const classes = useStyles();

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <Typography className={classes.heading} data-testid="report-title">
          Report
        </Typography>
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid container xs={12} spacing={2}>
            <Grid item>
              <MembersCount
                memberCountValue={fetchedData?.totalTransactionMembers}
                hideRefreshBtn={true}
              />
            </Grid>
            <Grid item>
              <PaperContainer
                data-testid="paper"
                title="Sent on:"
                value={
                  moment(fetchedData?.startDate).format('MMM DD YYYY,') +
                  ' ' +
                  formatTimeInHHMMWithComma(fetchedData?.startDate)
                }
              />
            </Grid>{' '}
            {fetchedData?.failureMailCount ? (
              <Grid item>
                <Paper
                  className={classes.Papercontainer}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '8px',
                    backgroundColor: '#E4E7EC',
                  }}
                >
                  <Typography style={{ marginLeft: '4px', marginRight: '4px' }}>
                    <b data-testid="reportfile">File:</b>
                  </Typography>{' '}
                  <DownloadFile transactionId={transactionId} />
                </Paper>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Grid container xs={12} spacing={2}>
            <Grid item style={{ marginLeft: '10px', marginTop: '16px' }}>
              {' '}
              <CustomPaper
                title="Email sent"
                showUtilityCheckIcon={true}
                value={fetchedData?.successMailCount || '-'}
              />
            </Grid>
            <Grid item style={{ marginTop: '16px' }}>
              <CustomPaper
                title="Bounced emails"
                showUtilityIcon={true}
                value={fetchedData?.failureMailCount || '-'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ReportForCamapign;
