import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { SimpleBackdrop } from 'components/widgets';
import {
  Grid,
  FormControl,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Modal,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import campaign from '../../../assets/campaign.svg';
import assessmentIcon from '../../../assets/assessmentIcon.svg';
import { useSnackbar } from 'notistack';
import CampaignApi from 'services/api/CampaignApi';
import { ArrowRight, ArrowRightOutlined, Delete } from '@material-ui/icons';
import ChevronRight from '@material-ui/icons/ChevronRight';
import assessmentInfo from '../../../assets/assessmentInfo.svg';
// import AssessmentIcon from 'components/icons/AssessmentIcon';
import { CampaignModal } from './CampaignModal';
import { AssessmentAPI } from 'services/api/Assessment.api';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '40px',
    marginBottom: '5%',
  },
  mt: {
    marginTop: '3%',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#344054',
  },
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
    borderRadius: '24px',
    minWidth: '60vw',
    maxWidth: '60vw',
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '24px',
    fontStyle: 'normal',
    // fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  assessmentHeading: {
    fontWeight: 600,
    fontSize: '16px',
    display: 'flex',
  },
  assessmentSubHeading: {
    color: '#667085',
    fontSize: '12px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
  assessmentBox: {
    display: 'flex',
    border: '1px solid #E4E7EC',
    borderRadius: '7px',
    justifyContent: 'space-between',
  },
}));

const CampaignStep1 = ({
  changeStep,
  step1Data,
  options,
  data,
  logo,
  campaignValues,
  shouldRefetechData,
  trackUnsavedChangesRef,
  assessmentName,
  assessmentId,
}) => {
  const [campaignTitle, setcampaignTitle] = useState(step1Data?.title || '');
  const [description, setDescription] = useState(step1Data?.description || '');
  const [campaignMode, setCampaignMode] = useState(step1Data?.mode || 'EMAIL');
  const classes = useStyles();
  const orgId = sessionStorage.getItem("orgId");
  const EmptyGridForUI = () => <Grid item sm={9} xs={6} />;
  const { enqueueSnackbar } = useSnackbar();
  const RADIO_OPTIONS = [{ key: 'Email', value: 'EMAIL' }];
  const [loading, setLoading] = useState(false);
  const [campaignModal, setCampaignModal] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState({ name: assessmentName, id: assessmentId });

  let setdata = {
    title: campaignTitle,
    description: description,
    mode: campaignMode,
    assessmentId:selectedAssessment.id || step1Data?.assessmentId,
  };

  const handleTypeSelection = e => {
    setCampaignMode(e.target.value);
  };

  const checkValidation = () => {
    if (campaignTitle && description && campaignMode) {
      return true;
    } else {
      enqueueSnackbar('Please enter all details', {
        variant: 'error',
        autoHideDuration: 1500,
      });

      return false;
    }
  };

  const handleClose = item => {
    if (item?.name && item?.id) {
      setSelectedAssessment({ name: item?.name, id: item.id });
    } else {
      // setSelectedAssessment({ name: null, id: null });
    }
    setCampaignModal(false);
  };

  const openModal = () => {
    setCampaignModal(true);
  };

  useEffect(() => {
    trackUnsavedChangesRef.current = () => {
      let { name, mode } = campaignValues;
      if (campaignTitle || description) {
        if (
          (name !== campaignTitle && campaignTitle) ||
          (campaignValues.description !== description && description) ||
          (mode !== campaignMode && campaignMode)
        ) {
          return true;
        } else return false;
      }

      return false;
    };
  }, [
    campaignMode,
    campaignTitle,
    campaignValues,
    description,
    trackUnsavedChangesRef,
  ]);

  const getAssessmentDetails = async ()=>{
    const assessmentId=step1Data.assessmentId;
    try {
      const results = await AssessmentAPI.getAssessmentDetails({
        orgId,
        assessmentId,
      });
      setSelectedAssessment({name:results.name,id:results.id})
    } catch (error) {
      console.log('error: ', error);
    }
  }

  useEffect(() => {
    if(step1Data.assessmentId)
  {
    getAssessmentDetails();

  }
},[]);

  const handleSaveCampaignDetails = currentValue => {
    let { name, description, mode,assessmentId } = campaignValues;
    if (
      name === currentValue.title &&
      description === currentValue.description &&
      mode === currentValue.mode
    )
      return;

      let payload = {
      ...campaignValues,
      name: currentValue?.title || '',
      description: currentValue?.description || null,
      assessmentId:selectedAssessment.id,
      mode: currentValue?.mode || '',
    };
    shouldRefetechData.current = true;
    CampaignApi.saveCampaign({ orgId, payload, campaignId: campaignValues.id });
  };
  const handleSubmit = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    if (campaignValues.name !== campaignTitle) {
      try {
        let results = await CampaignApi.checkForDuplicate({
          orgId,
          campaignTitle,
        });
        setLoading(false);
        if (results?.id) {
          enqueueSnackbar(
            'Campaign title is already used please give different title',
            {
              variant: 'error',
              autoHideDuration: 1500,
            }
          );
        } else {
          data(setdata);
          if (campaignValues.id !== null) {
            handleSaveCampaignDetails(setdata);
          }
          changeStep(false);
        }
      } catch {
        setLoading(false);
        enqueueSnackbar(
          'Campaign title is already used please give different title',
          {
            variant: 'error',
            autoHideDuration: 1500,
          }
        );
      }
    } else {
      data(setdata);
      if (campaignValues.id !== null) {
        handleSaveCampaignDetails(setdata);
      }
      changeStep(false);
    }
  };

  return (
    <div style={{ overflow: 'hidden', marginTop: '-4%' }}>
      <Grid container spacing={2}>
        <Grid xs={5}>
          <div
            style={{
              background: '#e5f5fc',
              height: '100vh',
              position: 'relative',
            }}>
            {logo && (
              <img style={{ height: 40, margin: 40 }} alt={'Logo'} src={logo} />
            )}

            <img
              alt="Logo"
              src={campaign}
              // style={{ position: 'absolute', bottom: 0 }}
            />
          </div>
        </Grid>
        <Grid item xs={7} style={{ marginTop: '5%' }}>
          <div style={{ margin: 'auto', width: '70%' }}>
            <Typography
              className={classes.heading}
              data-testid="campaign-title">
              Create a Campaign
            </Typography>
            <Grid item sm={10} xs={10} className={classes.mt}>
              <Typography className={classes.formTitle}>
                Campaign title <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                required
                id="campaign-title"
                margin="dense"
                variant="outlined"
                className={classes.formTextField}
                value={campaignTitle}
                onChange={e => setcampaignTitle(e.target.value)}
                autoComplete="off"
                autoFocus
                inputProps={{
                  'data-testid': 'campaign-title-textbox',
                }}
              />
            </Grid>
            <EmptyGridForUI />
            <Grid item sm={10} xs={10} className={classes.mt}>
              <Typography className={classes.formTitle}>
                Description <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                required
                id="outlined-basic"
                margin="dense"
                variant="outlined"
                className={classes.formTextField}
                placeholder="Enter description of the campaign"
                multiline
                value={description}
                onChange={e => setDescription(e.target.value)}
                minRows={4}
                // disabled={isEdit}
                inputProps={{
                  maxLength: 100,
                  'data-testid': 'campaign-description-textbox',
                }}
                helperText={`${description.length}/100`}
                FormHelperTextProps={{
                  style: {
                    // textAlign: 'center',
                    position: 'absolute',
                    bottom: '0%',
                    right: '0%',
                  },
                }}
              />
            </Grid>

            <EmptyGridForUI />
            <Grid item sm={10} xs={10} className={classes.mt}>
              <Typography className={classes.formTitle}>
                Mode of campaign <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  style={{ display: 'flex', flexDirection: 'row' }}
                  aria-label="campaignMode"
                  name="campaignMode"
                  value={campaignMode}
                  // onChange={(e, value) => {
                  //   setcampaignMode(value);
                  //   handleOnchange();
                  // }}
                >
                  {RADIO_OPTIONS.map(val => (
                    <FormControlLabel
                      // disabled={isEdit}
                      key={val.key}
                      value={val.value}
                      control={<Radio color="primary" />}
                      label={val.key}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <EmptyGridForUI />
            <Grid item sm={10} xs={10} className={classes.mt}>
              <Box
                className={classes.assessmentBox}
                onClick={() => openModal()}>
                <div style={{ alignSelf: 'center', paddingLeft: '12px',width:"78%",overflowWrap : "anywhere" }}>
                  {selectedAssessment?.name ? (
                    <>
                      <Typography className={classes.assessmentHeading} style={{wordBreak:"break-all"}}>
                        {selectedAssessment?.name}
                      </Typography>
                      <Typography className={classes.assessmentSubHeading}>
                        Below is the assessment that you have linked with this
                        campaign
                      </Typography>
                      <Box sx={{background: "#F5F7F8", padding: "5px", width: "fit-content", borderRadius: "5px", margin: "3% 0%",wordBreak:"break-all"}}>
                      <img alt="Logo" src={assessmentInfo} style={{marginRight:"5px"}} />
                      {selectedAssessment?.name}
                      <Delete style={{color:"#667085", fontSize:"14px",marginLeft:"10px"}} onClick={(event)=>{event.stopPropagation(); setSelectedAssessment({name:null,id:null})}}/>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography className={classes.assessmentHeading}>
                        Add an assessment with this campaign{' '}
                        <ChevronRight style={{ fontSize: '20px' }} />
                      </Typography>
                      <Typography className={classes.assessmentSubHeading}>
                        Choose from the existing list of published assessments
                      </Typography>
                    </>
                  )}
                </div>
                <img alt="Logo" src={assessmentIcon} style={{}} />
              </Box>
            </Grid>

            <Grid item sm={8} xs={8} className={classes.mt}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                className={classes.searchButton}
                data-testid="proceed-btn">
                Proceed
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {/* {console.log('selected', selectedAssessment)} */}
      <Modal
        open={campaignModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CampaignModal handleClose={handleClose} />
      </Modal>
      <SimpleBackdrop open={loading} />
    </div>
  );
};

export default CampaignStep1;
