import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PartnerApi from 'services/api/PartnerApi';
import { SimpleBackdrop } from 'components/widgets';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';
import {
  Grid,
  Hidden,
  Paper,
  Box,
  Link,
  Typography,
  Button,
  FormLabel,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {
  FormTextField,
  FormEmailField,
  FormSelectField,
} from 'components/widgets';

const useStyles = makeStyles(theme => ({
  formSelect: {
    marginLeft: -8,
  },
  inputHeight: {
    height: 90,
  },
  labelStyle: {
    fontWeight: '400',
    color: '#000',
  },
}));

const DeveloperSignup = props => {
  let initialValues = {
    firstName: '',
    lastName: '',
    affiliateName: '',
    emailAddress: '',
  };
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const orgDnsName = window.location.href.split('/')[3];

  const getConfigurations = useCallback(() => {
    window.localStorage.clear();
    async function getConfig() {
      try {
        const details = await PartnerApi.getConfigurations(orgDnsName);
        setConfig(details);
      } catch (e) {
        console.log(e);
      }
    }
    getConfig();
  }, [orgDnsName]);

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);

  const history = useHistory();

  const classes = useStyles();

  const onSubmit = async values => {
    window.localStorage.clear();
    setLoading(true);
    try {
      await PartnerApi.developerSignup(orgDnsName, values);
      setLoading(false);
      enqueueSnackbar('Invitation sent successfully, please check your mail', {
        variant: 'success',
      });
      history.push('/verify');
    } catch (error) {
      const message = error.response.data.details
        ? error.response.data.details
        : 'Something went wrong while signing up';
      setLoading(false);
      console.log(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const dropdown = [];
  if (config) {
    for (let i = 0; i < config.affiliates.length; i++) {
      dropdown.push({
        code: config.affiliates[i].name,
        name: config.affiliates[i].name,
      });
    }
    if(orgDnsName === "mph")
    {
      initialValues = {
        firstName: '',
        lastName: '',
        affiliateName: config?.affiliates?.[0]?.name,
        emailAddress: '',
      };
    }

    
  }

  return (
    <>
      <Grid container style={{ height: '100vh', marginTop: 0 }}>
        <Grid item xs={12} md={6} component={Paper}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              spacing: 1,
              alignItems: 'center',
              marginTop: '100px',
            }}>
            <Box>
              <Typography variant="h2" style={{ fontSize: '28px' }}>
                Create your account
              </Typography>
              <Typography
                variant="h6"
                style={{ marginBottom: '40px', marginTop: '10px' }}>
                Already have an account?{' '}
                <Link
                  variant="h6"
                  component="button"
                  onClick={() => props.onLogin()}>
                  Log in
                </Link>
              </Typography>
              <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, pristine }) => (
                  <form
                    noValidate
                    onSubmit={event => {
                      handleSubmit(event);
                    }}
                    autoComplete="off">
                    <Grid
                      container
                      style={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid item className={classes.inputHeight}>
                        <FormLabel
                          component="legend"
                          className={classes.labelStyle}>
                          First name
                        </FormLabel>
                        <div className={classes.formSelect}>
                          <FormTextField
                            name="firstName"
                            placeholder="Enter your first name"
                          />
                        </div>
                      </Grid>
                      <Grid item className={classes.inputHeight}>
                        <FormLabel
                          component="legend"
                          className={classes.labelStyle}>
                          Last name
                        </FormLabel>
                        <div className={classes.formSelect}>
                          <FormTextField
                            name="lastName"
                            placeholder="Enter your last name"
                          />
                        </div>
                      </Grid>
                      {orgDnsName !== "mph" &&
                      <Grid item className={classes.inputHeight}>
                        <FormLabel
                          component="legend"
                          className={classes.labelStyle}>
                          {LABELS_FIELDS_BUTTONS.SELECT_AFFILIATE}
                        </FormLabel>
                        <div className={classes.formSelect}>
                          <FormSelectField
                            name="affiliateName"
                            placeholder={LABELS_FIELDS_BUTTONS.SELECT_AFFILIATE}
                            options={dropdown}
                            required
                          />
                        </div>
                      </Grid>
                      }
                      <Grid item className={classes.inputHeight}>
                        <FormLabel
                          component="legend"
                          className={classes.labelStyle}>
                          Email Address
                        </FormLabel>
                        <div className={classes.formSelect}>
                          <FormEmailField
                            name="emailAddress"
                            placeholder="Enter your email"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Button
                      style={{
                        textTransform: 'none',
                        borderRadius: '24px',
                        width: '320px',
                        marginTop: '10px',
                      }}
                      type="submit"
                      disabled={pristine}
                      variant="contained"
                      size="large"
                      color="primary">
                      Verify Email
                    </Button>
                  </form>
                )}
              />
            </Box>
          </Box>
          <Box
            style={{
              marginTop: '120px',
              marginLeft: '40px',
              marginBottom: '40px',
            }}>
            <Typography variant="h6" color="textSecondary">
              Powered by
            </Typography>
            <img height="20px" src="/static/logo.svg" alt="Logo" />
          </Box>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={false} sm={false} md={6}></Grid>
        </Hidden>
      </Grid>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default DeveloperSignup;
