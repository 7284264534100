import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import TelemetryApi from 'services/api/TelemetryApi';
import ResponseCodesChart from 'containers/APIOverview/ResponseCodesChart';
import { Card as CustomCard } from 'components/widgets';
import { useCallback, useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { RESOURCES } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: theme.spacing(2),
  },
  card: {
    width: 345,
  },
  cardContent: {
    minHeight: 160,
  },
  btn: {
    textTransform: 'none',
  },
  listItem: {
    margin: '8px 0',
  },
  numIcons: {
    display: 'inline',
    padding: '4px',
    marginRight: '5px',
    borderRadius: '50%',
    background: '#ffa400',
  },
  apiCard: {
    marginTop: theme.spacing(2),
    minHeight: 240,
  },
  name: {
    fontWeight: '600',
    fontSize: 28,
    paddingLeft: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  circularProgress: {
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function ResponseCodes({orgId, affiliateId, height }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [value, setValue] = useState('All');

  const handleChange = event => {
    setValue(event.target.value);
  };

  let dataArray = [
    { color: '#2D9CDB' },
    { color: '#F2994A' },
    { color: '#9B51E0' },
  ];

  const today = new Date();
  const startDate = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    today.getDate()
  )
    .toLocaleString('en-US')
    .split(',')
    .join('')
    .slice(0, -3);

  const timeInterval = 'WEEK';

  const responseCodes = useCallback(() => {
    async function getResponse() {
      try {
        const details = await TelemetryApi.getResponseCodes(
          orgId,
          affiliateId,
          timeInterval,
          value,
          startDate
        );
        setData(details);
      } catch (e) {
        console.log(e);
      }
    }
    getResponse();
  }, [startDate, timeInterval, value, affiliateId, orgId]);

  useEffect(() => responseCodes(), [responseCodes]);

  if (data) {
    let count = 0;
    for (let item in data) {
      dataArray[count].y = data[item];
      count++;
    }
  }

  return (
    <>
      <CustomCard className={classes.apiCard}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography component="b" variant="h4">
              Response codes
            </Typography>
            <FormControl className={classes.formControl}>
              <Select value={value} onChange={handleChange}>
                <MenuItem value={'All'}>Service: All</MenuItem>
                {RESOURCES.map(item => (
                  <MenuItem key={item} value={item}>
                    Service: {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              {data ? (
                <ResponseCodesChart data={dataArray} height={height} />
              ) : (
                <div className={classes.circularProgress}>
                  <CircularProgress color='secondary' size={70}/>
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </CustomCard>
    </>
  );
}

export default ResponseCodes;
