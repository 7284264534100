import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CSVLink } from 'react-csv';

const TABLE_COLUMNS = {
  user: [
    { id: '1', content: 'Id' },
    { id: '2', content: 'Name' },
    { id: '3', content: 'Email' },
    { id: '4', content: 'Mobile_No' },
    { id: '5', content: 'Address' },
    { id: '6', content: 'Gender' },
    { id: '7', content: 'Date_Of_Birth' },
    { id: '8', content: 'Plan_Name' },
    { id: '9', content: 'Plan_Expiry_Date' },
  ],
  assessment: [
    { id: '1', content: 'Assessment_Id' },
    { id: '2', content: 'Organization_Name' },
    { id: '3', content: 'Start_Date' },
    { id: '4', content: 'Expiry_Date' },
    { id: '6', content: 'Published_Date' },
    { id: '5', content: 'Assessment_Name' },
    { id: '7', content: 'Number_Of_Members' },
  ],
  campaign: [
    { id: '1', content: 'Campaign_Id' },
    { id: '2', content: 'Organization_Name' },
    { id: '3', content: 'Start_Date' },
    { id: '4', content: 'Expiry_Date' },
    { id: '6', content: 'Published_Date' },
    { id: '5', content: 'Campaign_Name' },
    { id: '7', content: 'Number_Of_Members' },
  ],
};

const Dummy_Data = {
  Id: [
    8274, 1938, 4567, 3201, 8923, 6542, 1876, 4098, 5321, 7465, 9183, 2754,
    6032, 8456, 1298, 7346, 5012, 3647, 8921, 1765, 4390, 2087, 6852, 3174,
    9240, 6501, 3847, 5726, 9160, 2438, 7856, 1029, 6347, 5912, 8405, 1763,
    2984, 5372, 4019, 8652, 7134, 2408, 5941, 3675, 8294, 1507, 4683, 6251,
    2364, 4234,
  ],
  Number_Of_Members: [
    8274, 1938, 4567, 3201, 8923, 6542, 1876, 4098, 5321, 7465, 9183, 2754,
    6032, 8456, 1298, 7346, 5012, 3647, 8921, 1765, 4390, 2087, 6852, 3174,
    9240, 6501, 3847, 5726, 9160, 2438, 7856, 1029, 6347, 5912, 8405, 1763,
    2984, 5372, 4019, 8652, 7134, 2408, 5941, 3675, 8294, 1507, 4683, 6251,
    2364, 4234,
  ],
  Name: [
    'Alice',
    'Bob',
    'Charlie',
    'David',
    'Emma',
    'Frank',
    'Grace',
    'Henry',
    'Iris',
    'Jack',
    'Kelly',
    'Liam',
    'Megan',
    'Nathan',
    'Olivia',
    'Peter',
    'Quinn',
    'Rachel',
    'Sam',
    'Tina',
    'Victor',
    'Wendy',
    'Xavier',
    'Yvonne',
    'Zachary',
    'Ava',
    'Benjamin',
    'Chloe',
    'Daniel',
    'Emily',
    'Finn',
    'Gabrielle',
    'Hayden',
    'Isabella',
    'Jacob',
    'Kylie',
    'Lucas',
    'Mia',
    'Noah',
    'Ophelia',
    'Patrick',
    'Quinn',
    'Ruby',
    'Sebastian',
    'Taylor',
    'Ulysses',
    'Veronica',
    'William',
    'Xander',
    'Yasmine',
    'Zoe',
  ],
  Email: [
    'user1@example.com',
    'user2@example.com',
    'user3@example.com',
    'user4@example.com',
    'user5@example.com',
    'user6@example.com',
    'user7@example.com',
    'user8@example.com',
    'user9@example.com',
    'user10@example.com',
    'user11@example.com',
    'user12@example.com',
    'user13@example.com',
    'user14@example.com',
    'user15@example.com',
    'user16@example.com',
    'user17@example.com',
    'user18@example.com',
    'user19@example.com',
    'user20@example.com',
    'user21@example.com',
    'user22@example.com',
    'user23@example.com',
    'user24@example.com',
    'user25@example.com',
    'user26@example.com',
    'user27@example.com',
    'user28@example.com',
    'user29@example.com',
    'user30@example.com',
    'user31@example.com',
    'user32@example.com',
    'user33@example.com',
    'user34@example.com',
    'user35@example.com',
    'user36@example.com',
    'user37@example.com',
    'user38@example.com',
    'user39@example.com',
    'user40@example.com',
    'user41@example.com',
    'user42@example.com',
    'user43@example.com',
    'user44@example.com',
    'user45@example.com',
    'user46@example.com',
    'user47@example.com',
    'user48@example.com',
    'user49@example.com',
    'user50@example.com',
  ],
  Mobile_No: [
    '1234567890',
    '9876543210',
    '5551112233',
    '9988776655',
    '1112223333',
    '4445556666',
    '7778889999',
    '6663331111',
    '9998887777',
    '1231231234',
    '9876543210',
    '5551112233',
    '9988776655',
    '1112223333',
    '4445556666',
    '7778889999',
    '6663331111',
    '9998887777',
    '1231231234',
    '9876543210',
    '5551112233',
    '9988776655',
    '1112223333',
    '4445556666',
    '7778889999',
    '6663331111',
    '9998887777',
    '1231231234',
    '9876543210',
    '5551112233',
    '9988776655',
    '1112223333',
    '4445556666',
    '7778889999',
    '6663331111',
    '9998887777',
    '1231231234',
    '9876543210',
    '5551112233',
    '9988776655',
    '1112223333',
    '4445556666',
    '7778889999',
    '6663331111',
    '9998887777',
    '1231231234',
    '7778889999',
    '6663331111',
    '9998887777',
    '1231231234',
  ],
  Address: [
    '123 Main St, Cityville, State',
    '456 Oak St, Townsville, State',
    '789 Pine St, Villagetown, State',
    '321 Maple St, Hamletville, State',
    '123 Main St, Cityville, State',
    '456 Oak St, Townsville, State',
    '789 Pine St, Villagetown, State',
    '321 Maple St, Hamletville, State',
    '555 Elm St, Countryside, State',
    '777 Birch St, Ruralville, State',
    '999 Cedar St, Suburbia, State',
    '888 Walnut St, Outskirts, State',
    '666 Spruce St, Farmland, State',
    '444 Pine St, Coastal Town, State',
    '123 Main St, Cityville, State',
    '456 Oak St, Townsville, State',
    '789 Pine St, Villagetown, State',
    '321 Maple St, Hamletville, State',
    '555 Elm St, Countryside, State',
    '777 Birch St, Ruralville, State',
    '999 Cedar St, Suburbia, State',
    '888 Walnut St, Outskirts, State',
    '666 Spruce St, Farmland, State',
    '444 Pine St, Coastal Town, State',
    '123 Main St, Cityville, State',
    '456 Oak St, Townsville, State',
    '789 Pine St, Villagetown, State',
    '321 Maple St, Hamletville, State',
    '555 Elm St, Countryside, State',
    '777 Birch St, Ruralville, State',
    '999 Cedar St, Suburbia, State',
    '888 Walnut St, Outskirts, State',
    '666 Spruce St, Farmland, State',
    '444 Pine St, Coastal Town, State',
    '123 Main St, Cityville, State',
    '456 Oak St, Townsville, State',
    '789 Pine St, Villagetown, State',
    '321 Maple St, Hamletville, State',
    '555 Elm St, Countryside, State',
    '777 Birch St, Ruralville, State',
    '999 Cedar St, Suburbia, State',
    '888 Walnut St, Outskirts, State',
    '666 Spruce St, Farmland, State',
    '444 Pine St, Coastal Town, State',
    '123 Main St, Cityville, State',
    '456 Oak St, Townsville, State',
    '789 Pine St, Villagetown, State',
    '321 Maple St, Hamletville, State',
    '555 Elm St, Countryside, State',
  ],
  Gender: [
    'male',
    'female',
    'male',
    'female',
    'male',
    'male',
    'female',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'male',
    'female',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'male',
    'female',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'male',
    'female',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'female',
    'male',
    'male',
    'female',
    'female',
    'male',
    'female',
  ],
  Date_Of_Birth: [
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
    '1994-06-20',
    '1989-10-27',
    '1983-01-14',
    '1991-11-05',
    '1986-04-28',
    '1997-02-02',
    '1984-09-09',
    '1996-03-16',
    '1981-07-23',
    '1999-06-25',
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
    '1994-06-20',
    '1989-10-27',
    '1983-01-14',
    '1991-11-05',
    '1986-04-28',
    '1997-02-02',
    '1984-09-09',
    '1996-03-16',
    '1981-07-23',
    '1999-06-25',
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
  ],
  Plan_Expiry_Date: [
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
    '1994-06-20',
    '1989-10-27',
    '1983-01-14',
    '1991-11-05',
    '1986-04-28',
    '1997-02-02',
    '1984-09-09',
    '1996-03-16',
    '1981-07-23',
    '1999-06-25',
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
    '1994-06-20',
    '1989-10-27',
    '1983-01-14',
    '1991-11-05',
    '1986-04-28',
    '1997-02-02',
    '1984-09-09',
    '1996-03-16',
    '1981-07-23',
    '1999-06-25',
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
  ],
  Published_Date: [
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
    '1994-06-20',
    '1989-10-27',
    '1983-01-14',
    '1991-11-05',
    '1986-04-28',
    '1997-02-02',
    '1984-09-09',
    '1996-03-16',
    '1981-07-23',
    '1999-06-25',
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
    '1994-06-20',
    '1989-10-27',
    '1983-01-14',
    '1991-11-05',
    '1986-04-28',
    '1997-02-02',
    '1984-09-09',
    '1996-03-16',
    '1981-07-23',
    '1999-06-25',
    '1990-01-15',
    '1985-03-22',
    '1992-08-10',
    '1980-05-05',
    '1988-11-30',
    '1995-07-18',
    '1982-02-25',
    '1993-09-12',
    '1987-04-03',
    '1998-12-08',
  ],
  Assessment_Id: [
    '123456',
    '789012',
    '345678',
    '901234',
    '567890',
    '234567',
    '890123',
    '456789',
    '012345',
    '678901',
    '345678',
    '901234',
    '567890',
    '234567',
    '890123',
    '456789',
    '012345',
    '678901',
    '345678',
    '901234',
    '123456',
    '789012',
    '345678',
    '901234',
    '567890',
    '234567',
    '890123',
    '456789',
    '012345',
    '678901',
    '345678',
    '901234',
    '567890',
    '234567',
    '890123',
    '456789',
    '012345',
    '678901',
    '345678',
    '901234',
    '123456',
    '789012',
    '345678',
    '901234',
    '567890',
    '234567',
    '890123',
    '456789',
    '012345',
    '678901',
  ],
  Organization_Name: [
    'ABC Corporation',
    'XYZ Industries',
    'ABC Corporation',
    'ABC Corporation',
    'PQR Solutions',
    'LMN Enterprises',
    'JKL Innovations',
    'EFG Technologies',
    'MNO Services',
    'HIJ Enterprises',
    'UVW Solutions',
    'QRS Corporation',
    'GHI Innovations',
    'TUV Services',
    'XYZ Corporation',
    'ABC Industries',
    'JKL Technologies',
    'PQR Solutions',
    'LMN Enterprises',
    'EFG Innovations',
    'HIJ Technologies',
    'MNO Services',
    'QRS Corporation',
    'UVW Solutions',
    'GHI Innovations',
    'TUV Services',
    'XYZ Corporation',
    'ABC Industries',
    'JKL Technologies',
    'PQR Solutions',
    'LMN Enterprises',
    'EFG Innovations',
    'HIJ Technologies',
    'MNO Services',
    'QRS Corporation',
    'UVW Solutions',
    'GHI Innovations',
    'TUV Services',
    'XYZ Corporation',
    'ABC Industries',
    'JKL Technologies',
    'PQR Solutions',
    'LMN Enterprises',
    'EFG Innovations',
    'HIJ Technologies',
    'MNO Services',
    'QRS Corporation',
    'UVW Solutions',
    'GHI Innovations',
    'TUV Services',
  ],
  Start_Date: [
    '2023-01-01',
    '2023-01-01',
    '2023-01-01',
    '2023-02-15',
    '2023-03-20',
    '2023-04-10',
    '2023-05-05',
    '2023-06-18',
    '2023-07-22',
    '2023-08-05',
    '2023-09-12',
    '2023-10-27',
    '2023-11-15',
    '2023-12-08',
    '2024-01-20',
    '2024-02-28',
    '2024-03-05',
    '2024-04-28',
    '2024-05-02',
    '2024-06-16',
    '2024-07-23',
    '2024-08-25',
    '2024-09-01',
    '2024-10-10',
    '2024-11-18',
    '2024-12-22',
    '2025-01-02',
    '2025-02-15',
    '2025-03-20',
    '2025-04-10',
    '2025-05-05',
    '2025-06-18',
    '2025-07-22',
    '2025-08-05',
    '2025-09-12',
    '2025-10-27',
    '2025-11-15',
    '2025-12-08',
    '2026-01-20',
    '2026-02-28',
    '2026-03-05',
    '2026-04-28',
    '2026-05-02',
    '2026-06-16',
    '2026-07-23',
    '2026-08-25',
    '2026-09-01',
    '2026-10-10',
    '2026-11-18',
    '2026-12-22',
  ],
  Expiry_Date: [
    '2024-01-01',
    '2023-01-01',
    '2024-02-15',
    '2023-01-01',
    '2024-03-20',
    '2024-04-10',
    '2024-05-05',
    '2024-06-18',
    '2024-07-22',
    '2024-08-05',
    '2024-09-12',
    '2024-10-27',
    '2024-11-15',
    '2024-12-08',
    '2025-01-20',
    '2025-02-28',
    '2025-03-05',
    '2025-04-28',
    '2025-05-02',
    '2025-06-16',
    '2025-07-23',
    '2025-08-25',
    '2025-09-01',
    '2025-10-10',
    '2025-11-18',
    '2025-12-22',
    '2026-01-02',
    '2026-02-15',
    '2026-03-20',
    '2026-04-10',
    '2026-05-05',
    '2026-06-18',
    '2026-07-22',
    '2026-08-05',
    '2026-09-12',
    '2026-10-27',
    '2026-11-15',
    '2026-12-08',
    '2027-01-20',
    '2027-02-28',
    '2027-03-05',
    '2027-04-28',
    '2027-05-02',
    '2027-06-16',
    '2027-07-23',
    '2027-08-25',
    '2027-09-01',
    '2027-10-10',
    '2027-11-18',
    '2027-12-22',
  ],
  Assessment_Name: [
    'Assessment 1',
    'Evaluation 2',
    'Test 3',
    'Exam 4',
    'Quiz 5',
    'Assessment 6',
    'Evaluation 7',
    'Test 8',
    'Exam 9',
    'Quiz 10',
    'Assessment 11',
    'Evaluation 12',
    'Test 13',
    'Exam 14',
    'Quiz 15',
    'Assessment 16',
    'Evaluation 17',
    'Test 18',
    'Exam 19',
    'Quiz 20',
    'Assessment 21',
    'Evaluation 22',
    'Test 23',
    'Exam 24',
    'Quiz 25',
    'Assessment 26',
    'Evaluation 27',
    'Test 28',
    'Exam 29',
    'Quiz 30',
    'Assessment 31',
    'Evaluation 32',
    'Test 33',
    'Exam 34',
    'Quiz 35',
    'Assessment 36',
    'Evaluation 37',
    'Test 38',
    'Exam 39',
    'Quiz 40',
    'Assessment 41',
    'Evaluation 42',
    'Test 43',
    'Exam 44',
    'Quiz 45',
    'Assessment 46',
    'Evaluation 47',
    'Test 48',
    'Exam 49',
    'Quiz 50',
  ],
  Campaign_Id: [
    '12345',
    '67890',
    '54321',
    '98765',
    '34567',
    '89012',
    '45678',
    '90123',
    '23456',
    '78901',
    '23456',
    '78901',
    '12345',
    '67890',
    '54321',
    '98765',
    '34567',
    '89012',
    '45678',
    '90123',
    '23456',
    '78901',
    '23456',
    '78901',
    '12345',
    '67890',
    '54321',
    '98765',
    '34567',
    '89012',
    '45678',
    '90123',
    '23456',
    '78901',
    '23456',
    '78901',
    '12345',
    '67890',
    '54321',
    '98765',
    '34567',
    '89012',
    '45678',
    '90123',
    '23456',
    '78901',
    '23456',
    '78901',
    '12345',
    '67890',
  ],
  Campaign_Name: [
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
  ],
  Plan_Name: [
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
    'Healthcare Awareness Drive',
    'Medical Outreach Program',
    'Health and Wellness Fair',
    'Preventive Health Initiative',
    'Healthy Living Campaign',
    'Health Awareness Campaign',
    'Medical Check-up Drive',
    'Wellness Outreach Program',
    'Disease Prevention Initiative',
    'Community Health Campaign',
  ],
  Organiser: [
    'ABC Health Foundation',
    'XYZ Medical Services',
    'PQR Healthcare Solutions',
    'LMN Health Initiatives',
    'JKL Health Systems',
    'EFG Wellness Foundation',
    'MNO Medical Services',
    'HIJ Healthcare Solutions',
    'UVW Health Initiatives',
    'QRS Health Systems',
    'GHI Wellness Foundation',
    'TUV Medical Services',
    'XYZ Health Foundation',
    'ABC Medical Services',
    'JKL Healthcare Solutions',
    'PQR Health Initiatives',
    'LMN Health Systems',
    'EFG Wellness Foundation',
    'HIJ Medical Services',
    'MNO Healthcare Solutions',
    'QRS Health Initiatives',
    'UVW Health Systems',
    'GHI Wellness Foundation',
    'TUV Medical Services',
    'ABC Health Foundation',
    'XYZ Medical Services',
    'PQR Healthcare Solutions',
    'LMN Health Initiatives',
    'JKL Health Systems',
    'EFG Wellness Foundation',
    'MNO Medical Services',
    'HIJ Healthcare Solutions',
    'UVW Health Initiatives',
    'QRS Health Systems',
    'GHI Wellness Foundation',
    'TUV Medical Services',
    'XYZ Health Foundation',
    'ABC Medical Services',
    'JKL Healthcare Solutions',
    'PQR Health Initiatives',
    'LMN Health Systems',
    'EFG Wellness Foundation',
    'HIJ Medical Services',
    'MNO Healthcare Solutions',
    'QRS Health Initiatives',
    'UVW Health Systems',
    'GHI Wellness Foundation',
    'TUV Medical Services',
  ],
  Assossiction_Id: [
    '1234567',
    '7890123',
    '3456789',
    '9012345',
    '5678901',
    '2345678',
    '8901234',
    '4567890',
    '0123456',
    '6789012',
    '3456789',
    '9012345',
    '5678901',
    '2345678',
    '8901234',
    '4567890',
    '0123456',
    '6789012',
    '3456789',
    '9012345',
    '1234567',
    '7890123',
    '3456789',
    '9012345',
    '5678901',
    '2345678',
    '8901234',
    '4567890',
    '0123456',
    '6789012',
    '3456789',
    '9012345',
    '5678901',
    '2345678',
    '8901234',
    '4567890',
    '0123456',
    '6789012',
    '3456789',
    '9012345',
    '3456789',
    '9012345',
    '5678901',
    '2345678',
    '8901234',
    '4567890',
    '0123456',
    '6789012',
    '3456789',
    '9012345',
  ],
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function DragDropComponent(props) {
  const [csvData, setCsvData] = useState([[]]);
  const [fileName, setFileName] = useState();
  const [columns, setColumns] = useState({
    Report: {
      name: 'Table columns',
      items: props?.tableName != '' ? TABLE_COLUMNS[props.tableName] : [],
    },
    Saved: {
      name: 'Report columns',
      items: [],
    },
  });

  useEffect(() => {
    let name = `${props.tableName}_report_${new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, '_')}.csv`;

    setFileName(name);
  }, [props]);

  const generateCsvData = dynamicCsv => {
    let csvList = [];
    csvList.push(dynamicCsv);
    for (let i = 0; i < 49; i++) {
      let tempList = [];
      for (let col of dynamicCsv) {
        tempList.push(Dummy_Data[col][i]);
      }
      csvList.push(tempList);
    }
    setCsvData(csvList);
  };
  const handleDownloadClick = () => {
    let dynamicCsv = columns.Saved.items.map(item => item.content);
    generateCsvData(dynamicCsv);
  };

  useEffect(() => {
    setColumns({
      Report: {
        name: 'Table columns',
        items: props?.tableName != '' ? TABLE_COLUMNS[props.tableName] : [],
      },
      Saved: {
        name: 'Report columns',
        items: [],
      },
    });
  }, [props.tableName]);
  return (
    <div style={{ flexDirection: 'column' }}>
      {props.tableName != '' && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <DragDropContext
              onDragEnd={result => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '50%',
                    }}
                    key={columnId}
                  >
                    <h3>{column.name}</h3>
                    <div style={{ margin: 8, width: '100%', padding: '20px' }}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? 'grey'
                                  : 'lightgrey',
                                padding: 4,
                                // width: 650,
                                minHeight: 500,
                                borderRadius: '5px',
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: 'none',
                                            padding: 16,
                                            margin: '0 10px 10px 10px',
                                            minHeight: '50px',
                                            backgroundColor: snapshot.isDragging
                                              ? '#263B4A'
                                              : '#456C86',
                                            color: 'white',
                                            ...provided.draggableProps.style,
                                            borderRadius: '5px',
                                            marginTop: '10px',
                                          }}
                                        >
                                          {item.content}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '20px',
              marginBottom: '50px',
              marginTop: '20px',
            }}
            onClick={handleDownloadClick}
          >
            <CSVLink
              style={{
                border: '2px',
                padding: '10px',
                backgroundColor: 'lightGrey',
                borderRadius: '5px',
                color: 'black',
              }}
              filename={fileName}
              data={csvData}
            >
              Download Report
            </CSVLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default DragDropComponent;
