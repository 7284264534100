import * as actionTypes from './actionTypes';
import OrganizationApi from 'services/api/OrganizationApi';
import DataPartnersApi from 'services/api/DataPartnersApi';
import AbilityService from 'services/AbilityService';
import { RESOURCE_NAMES } from 'shared/constants';

export const organizationsFetchSuccess = orgs => {
  return {
    type: actionTypes.ORGANIZATIONS_FETCH_SUCCESS,
    orgs,
  };
};

const organizationsFetchFailure = error => {
  return {
    type: actionTypes.ORGANIZATIONS_FETCH_FAILURE,
    error: error,
  };
};

const organizationsFetchStart = () => {
  return {
    type: actionTypes.ORGANIZATIONS_FETCH_START,
  };
};

export const affiliatesFetchSuccess = affiliates => {
  return {
    type: actionTypes.AFFILIATES_FETCH_SUCCESS,
    affiliates,
  };
};

export const fetchOrganizations = () => {
  return dispatch => {
    dispatch(organizationsFetchStart());

    OrganizationApi.getAllOrganizations()
      .then(response => {
        const orgData = response && response.data ? response.data : [];
        dispatch(organizationsFetchSuccess(orgData));

        const devAccess = AbilityService.canViewEither([
          RESOURCE_NAMES.DEV_DASHBOARD,
          RESOURCE_NAMES.DEV_API_OVERVIEW,
          RESOURCE_NAMES.DEV_APPLICATIONS,
          RESOURCE_NAMES.DEV_SANDBOX,
        ]);

        if (devAccess) {
          const orgIds = orgData.map(item => item.id);

          const allPromises = orgIds.map(id =>
            DataPartnersApi.getAllPartners(id)
          );

          Promise.all(allPromises)
            .then(responses => {
              const dataResponses = responses.map(item => item.data);
              let data = [];

              dataResponses.forEach(item => {
                data = data.concat(item);
              });

              dispatch(affiliatesFetchSuccess(data));
            })
            .catch(err => {
              console.log('error: ', err);
            });
        }
      })
      .catch(error => {
        dispatch(organizationsFetchFailure(error));
      });
  };
};
