import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { Avatar, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SolutionPermissionAccordion from './Permissions/SolutionPermissionAccordion';
import FeaturePermissionAccordion from './Permissions/FeaturePermissionAccordion';
import PermissionApi from 'services/api/PermissionApi';
import ManageOrganizationPermission from './Permissions/ManageOrganizationAccordion';
import { PERMISSION_CODES } from 'shared/constants';
import { useSnackbar } from 'notistack';
import CustomizedDialog from 'components/CustomizedDialog';

const useStyles = ({ isManageRole }) =>
  makeStyles(theme => {
    return {
      roleInfoCard: {
        display: 'flex',
        maxWidth: '352px',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '16px',
        backgroundColor: '#E2F1FC',
        textAlign: 'center',
        borderRadius: '10px',
        gap: '10px',
        alignSelf: 'center',
      },
      permissionContainer: {
        backgroundColor: '#f5f5f5',
        margin: !isManageRole ? '0px 5%' : '0px',
        borderRadius: '12px',
        padding: '20px',
        gap: '10px',
      },
      permissionDescription: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      },
      footer: {
        textAlign: 'center',
        minHeight: '80px',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        gap: '10px',
        padding: '10px 30px',
        marginTop: 'auto',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 3,
      },
      permissionBox: {
        marginTop: '0px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: '10px',
        gap: '20px',
        minHeight: isManageRole ? `calc(100vh - 340px)` : 'fit-content',
        marginBottom: isManageRole ? '80px' : '0px',
      },
      domainPopup: {
        minHeight: '250px',
        minWidth: '672px',
        maxWidth: '672px',
      },
    };
  });

const PermissionForm = ({
  incrementStep,
  roleName,
  roleDescription,
  roleId,
  isView = false,
  isManageRole = false,
  userInfoPayload,
  setInviteCard,
  setRefetchInternalUsers,
  hideTitle = false,
  hideBasicInfo = false,
  hideFooter = false,
  permissionTitle = 'Assign permissions',
  setSelectedRoleValue,
}) => {
  const classes = useStyles({ isManageRole })();
  const orgId = sessionStorage.getItem('orgId');
  const [expanded, setExpanded] = useState(false);
  const [permissionState, setPermissionState] = useState();
  const initiaPermissionState = useRef();
  const [permissionCode, setPermissionCode] = useState();
  const [loading, setLoading] = useState(false);
  const [warningPopupOpen, setWarningPopupOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isPermissionSelected = useMemo(() => {
    if (typeof permissionState === 'undefined' || permissionState === null)
      return false;
    return permissionState?.some(solution =>
      Object.values(solution?.permissionLimit || {})?.some(limit => limit !== 0)
    );
  }, [permissionState]);

  useEffect(() => {
    setLoading(true);

    PermissionApi.getRolePermissions({ roleId })
      .then(permResponse => {
        const permissionsResponse = permResponse.filter(
          solution => solution.solutionName === '-' || solution.isActive
        );

        // mapping permissions and adding initial limit and max limit
        initiaPermissionState.current = permissionsResponse;
        permissionsResponse.forEach(solution => {
          solution.selectedPermissions = [];
          let solutionPermissionMaxLimit = {},
            solutionPermissionLimit = {};
          solution.features?.forEach(feature => {
            let featurePermissionLimit = {};
            feature.subFeatures.forEach(subFeature => {
              subFeature.allowedPermissions.forEach(permissionCode => {
                solutionPermissionLimit[permissionCode] =
                  solutionPermissionLimit[permissionCode] || 0;

                featurePermissionLimit[permissionCode] =
                  featurePermissionLimit[permissionCode] || 0;

                solutionPermissionMaxLimit[permissionCode] =
                  solutionPermissionMaxLimit[permissionCode] + 1 || 1;
              });

              subFeature.selectedPermissions?.forEach(permissionCode => {
                featurePermissionLimit[permissionCode] =
                  featurePermissionLimit[permissionCode] + 1 || 0;

                solutionPermissionLimit[permissionCode] =
                  solutionPermissionLimit[permissionCode] + 1 || 0;
              });
            });

            feature.permissionLimit = featurePermissionLimit;
          });

          // for select all in edit feature
          Object.keys(solutionPermissionLimit).forEach(permissionCodeKey => {
            if (
              solutionPermissionLimit[permissionCodeKey] ===
              solutionPermissionMaxLimit[permissionCodeKey]
            ) {
              solution.selectedPermissions.push(permissionCodeKey);
            }
          });

          solution.permissionMaxLimit = solutionPermissionMaxLimit;
          solution.permissionLimit = solutionPermissionLimit;
        });

        PermissionApi.getPermissionsCode()
          .then(res => {
            setPermissionCode(res);
            setPermissionState(permissionsResponse);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
          });
      })
      .catch(err => {
        setLoading(false);
      });
  }, [roleId]);

  const handleSolutionAccordion = panel => type => {
    setExpanded(prev => (prev === panel ? false : panel));
  };

  const handleSubFeaturePermission = (
    solutionIdx,
    featureIdx,
    subFeatureIdx,
    permissionCode,
    type
  ) => {
    // instead of filter, approaching with index
    const clonePermissionState = structuredClone(permissionState);
    const solutionPermission = clonePermissionState[solutionIdx];
    const featurePermission = solutionPermission.features[featureIdx];
    const subFeaturePermission = featurePermission.subFeatures[subFeatureIdx];

    if (type === 'add') {
      subFeaturePermission.selectedPermissions?.push(permissionCode);
      featurePermission.permissionLimit[permissionCode] += 1;
      solutionPermission.permissionLimit[permissionCode] += 1;

      // Read should be selected by default for all permissions
      if (
        permissionCode !== PERMISSION_CODES.READ &&
        !subFeaturePermission.selectedPermissions?.includes(
          PERMISSION_CODES.READ
        ) &&
        subFeaturePermission.allowedPermissions?.includes(PERMISSION_CODES.READ)
      ) {
        subFeaturePermission.selectedPermissions?.push(PERMISSION_CODES.READ);

        featurePermission.permissionLimit[PERMISSION_CODES.READ] += 1;
        solutionPermission.permissionLimit[PERMISSION_CODES.READ] += 1;

        if (
          solutionPermission.permissionLimit[PERMISSION_CODES.READ] ===
            solutionPermission.permissionMaxLimit[PERMISSION_CODES.READ] &&
          !solutionPermission.selectedPermissions?.includes(
            PERMISSION_CODES.READ
          )
        ) {
          solutionPermission.selectedPermissions.push(PERMISSION_CODES.READ);
        }
      }
    } else {
      subFeaturePermission.selectedPermissions =
        subFeaturePermission.selectedPermissions.filter(
          permission => permission !== permissionCode
        );

      featurePermission.permissionLimit[permissionCode] -= 1;
      solutionPermission.permissionLimit[permissionCode] -= 1;
    }

    // select all logic

    const currentSelectedLimit =
        solutionPermission.permissionLimit[permissionCode],
      maxPermissionLimit =
        solutionPermission.permissionMaxLimit[permissionCode];

    if (
      currentSelectedLimit === maxPermissionLimit &&
      !solutionPermission.selectedPermissions?.includes(permissionCode)
    ) {
      solutionPermission.selectedPermissions.push(permissionCode);
    } else if (
      currentSelectedLimit !== maxPermissionLimit &&
      solutionPermission.selectedPermissions?.includes(permissionCode)
    ) {
      solutionPermission.selectedPermissions =
        solutionPermission.selectedPermissions?.filter(
          permission => permission !== permissionCode
        );
    }
    setPermissionState(clonePermissionState);
  };

  const handleSolutionPermission = (permissionCode, type, solutionId) => {
    const clonePermissionState = structuredClone(permissionState);
    clonePermissionState.forEach(solution => {
      // skip other solutions apart from selected
      if (solutionId !== solution.solutionId) return;

      if (type === 'add') {
        solution.selectedPermissions.push(permissionCode);
      } else if (type === 'clear') {
        solution.selectedPermissions = [];
        Object.keys(solution.permissionLimit)?.forEach(permissionLimitKey => {
          solution.permissionLimit[permissionLimitKey] = 0;
        });
      } else {
        solution.selectedPermissions = solution.selectedPermissions?.filter(
          permission => permission !== permissionCode
        );
      }
      solution.features.forEach(feature => {
        feature.subFeatures?.forEach(subFeature => {
          if (
            subFeature.allowedPermissions?.includes(permissionCode) ||
            type === 'clear'
          ) {
            if (type === 'add') {
              if (!subFeature.selectedPermissions.includes(permissionCode)) {
                subFeature.selectedPermissions.push(permissionCode);
                feature.permissionLimit[permissionCode] += 1;
                solution.permissionLimit[permissionCode] += 1;
              }

              // Permissions Hierarchy

              if (
                permissionCode !== PERMISSION_CODES.READ &&
                !subFeature.selectedPermissions?.includes(
                  PERMISSION_CODES.READ
                ) &&
                subFeature.allowedPermissions?.includes(PERMISSION_CODES.READ)
              ) {
                subFeature.selectedPermissions?.push(PERMISSION_CODES.READ);
                feature.permissionLimit[PERMISSION_CODES.READ] += 1;
                solution.permissionLimit[PERMISSION_CODES.READ] += 1;
                solution.selectedPermissions?.push(PERMISSION_CODES.READ);
              }
            } else if (type === 'clear') {
              subFeature.selectedPermissions = [];
            } else {
              // Skip read only permission unselect if other permission is selected for hierarchy
              if (
                permissionCode === PERMISSION_CODES.READ &&
                subFeature.selectedPermissions?.length > 1
              )
                return;
              subFeature.selectedPermissions =
                subFeature.selectedPermissions?.filter(
                  permission => permission !== permissionCode
                );
              if (feature.permissionLimit[permissionCode]) {
                feature.permissionLimit[permissionCode] -= 1;
                solution.permissionLimit[permissionCode] -= 1;
              }
            }
          }
        });

        if (type === 'clear') {
          Object.keys(feature.permissionLimit)?.forEach(permissionLimitKey => {
            feature.permissionLimit[permissionLimitKey] = 0;
          });
        }
      });
    });
    setPermissionState(clonePermissionState);
  };

  const savePermissionsToRole = payload => {
    setLoading(true);
    PermissionApi.updatePermissionToRole({ orgId, roleId, payload })
      .then(res => {
        if (!isManageRole) {
          incrementStep?.();
        } else {
          setLoading(false);
          setInviteCard?.(false);
          setRefetchInternalUsers?.(true);
          setSelectedRoleValue?.();
          enqueueSnackbar('Permissions successfully assigned to the role', {
            variant: 'success',
            autoHideDuration: 1500,
          });
        }
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar(
          err.response?.data?.details ||
            'Something went wrong while saving permissions',
          {
            variant: 'error',
            autoHideDuration: 1500,
          }
        );
      });
  };

  const handleSavePermission = () => {
    if (isView) {
      return incrementStep?.();
    }
    const payload = [];

    const prevSelectedSubFeatures = new Map();

    permissionState?.forEach(solution => {
      solution.features?.forEach(feature => {
        feature.subFeatures?.forEach(subFeature => {
          if (subFeature.selectedPermissions?.length) {
            // Below commented code is for the subFeature id check, before it was mapped to solutionId.
            // if (!prevSelectedSubFeatures.has(subFeature.subFeatureId)) {
            payload.push({
              solutionId: solution?.solutionId,
              subFeatureId: subFeature.subFeatureId,
              selectedPermissions: subFeature.selectedPermissions,
            });

            // prevSelectedSubFeatures.set(
            //   subFeature.subFeatureId,
            //   subFeature.selectedPermissions?.length
            // );
            // }
          }
        });
      });
    });
    savePermissionsToRole(payload);
  };

  const handleResetPermission = () => {
    setPermissionState(initiaPermissionState.current);
  };

  const handleWarningPopup = () => {
    setWarningPopupOpen(prev => !prev);
  };

  return (
    <Box className={classes.permissionBox} id="permission-box">
      {/* Role Info Card */}

      {!isManageRole && !hideBasicInfo && (
        <Box className={classes.roleInfoCard}>
          <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
            <Avatar style={{ backgroundColor: 'red' }}>
              {roleName?.split(' ')?.map((name, idx) => idx < 1 && name[0])}
            </Avatar>
            <Typography style={{ fontWeight: 600, fontSize: '18px' }}>
              {roleName}
            </Typography>
          </Box>
          <Typography
            style={{ fontWeight: 400, fontSize: '14px', color: '#344054' }}
          >
            {roleDescription}
          </Typography>
        </Box>
      )}

      {!isManageRole && !hideBasicInfo && (
        <Box className={classes.permissionDescription}>
          <Typography
            style={{ fontWeight: 600, fontSize: '28px', marginBottom: '5px' }}
          >
            {isView ? 'View permissions' : 'Assign permissions to the role'}
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: '12px', marginBottom: '5px' }}
          >
            {isView
              ? 'View the permissions that you will be granting to the user'
              : 'Assign the role and add permissions for the user you want to invite'}
          </Typography>
        </Box>
      )}

      {/* Permission Container */}
      <Box className={classes.permissionContainer}>
        {!hideTitle && (
          <Typography
            style={{ fontWeight: 600, fontSize: '18px', marginBottom: '5px' }}
          >
            {isView ? "View Permissions" : permissionTitle}
          </Typography>
        )}
        <div style={{ width: '100%' }}>
          {permissionState?.map((solution, solutionIdx) => (
            <SolutionPermissionAccordion
              expanded={expanded === solution.solutionName}
              handleSolutionPermission={handleSolutionPermission}
              permissionCode={permissionCode}
              permissionLimit={solution?.permissionLimit}
              permissionMaxLimit={solution?.permissionMaxLimit}
              selectedPermissions={solution?.selectedPermissions || []}
              setExpanded={handleSolutionAccordion(solution.solutionName)}
              title={
                solution.solutionName === '-'
                  ? 'Manage Organization'
                  : solution?.solutionName
              }
              solutionId={solution.solutionId}
              isView={isView}
              isManageRole={isManageRole}
            >
              {solution.features?.map((feature, featureIdx) => (
                <>
                  <FeaturePermissionAccordion
                    featureIdx={featureIdx}
                    featureTitle={feature.featureName}
                    handleFeaturePermission={handleSubFeaturePermission}
                    key={feature.featureId}
                    permissionCode={permissionCode}
                    permissionLimit={feature?.permissionLimit}
                    solutionIdx={solutionIdx}
                    subFeatures={feature.subFeatures}
                    isView={isView}
                    isManageRole={isManageRole}
                  />
                </>
              ))}
            </SolutionPermissionAccordion>
          ))}
        </div>
      </Box>
      {hideFooter ? null : isManageRole ? (
        <Box className={classes.footer}>
          <SecondaryButton
            onClick={handleResetPermission}
            data-testid="customized-popup-cancel-btn"
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleWarningPopup}
            disabled={!isPermissionSelected}
            data-testid="customized-popup-primary-btn"
          >
            Save
          </PrimaryButton>
        </Box>
      ) : (
        <PrimaryButton
          onClick={handleSavePermission}
          style={{
            maxWidth: 'fit-content',
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: '10px',
          }}
          disabled={!isPermissionSelected}
        >
          Proceed
        </PrimaryButton>
      )}

      <CustomizedDialog
        title={'Save changes'}
        open={warningPopupOpen}
        customStyles={classes.domainPopup}
        handleOnSave={() => {
          handleWarningPopup();
          handleSavePermission();
        }}
        handleClickClose={handleWarningPopup}
      >
        <Typography>{`Modifying permissions for this role will impact the permissions of all users assigned 
to this role. Are you certain you want to proceed with the changes?`}</Typography>
      </CustomizedDialog>

      <SimpleBackdrop open={loading} />
    </Box>
  );
};

export default PermissionForm;
