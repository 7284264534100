import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Box, Typography, Grid, TextField } from '@material-ui/core';
import { PrimaryButton, SecondaryButton, SimpleBackdrop } from 'components/widgets';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    borderRadius: '24px',
    padding: '23px',
    minWidth: '672px',
  },
  mt: { marginTop: '5%' },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
    minWidth: '608px',
    // minHeight: '160px',
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'black',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3" data-testid="dialog-heading">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          data-testid="close-icon"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ReusableModal = ({
  handleClose,
  open,
  title,
  context,
  isShowReason,
  handleConfirmApprove,
  handleConfirmDenied,
  declineReason,
  setDeclineReason,
  placeholder,
  primarybtn,
  secondarybtn,
  status = '',
  shouldCallHandleConfirm = false,
  loading
}) => {
  const classes = useStyles();

  const EmptyGridForUI = () => <Grid item sm={9} xs={6} />;

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={shouldCallHandleConfirm ? handleConfirmApprove : handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{ fontWeight: 600, fontSize: '24px' }}
          id="customized-dialog-title"
          data-testid="modal-heading"
          onClose={shouldCallHandleConfirm ? handleConfirmApprove : handleClose}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ color: '#667085', fontSize: 14, letterSpacing: '-0.05px' }}
          >
            {context}{' '}
          </Typography>
          {isShowReason && (
            <>
              <Grid item sm={8} xs={8} className={classes.mt}>
                <Typography className={classes.formTitle}>
                  Reason <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  required
                  id="outlined-basic"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder={placeholder}
                  multiline
                  value={declineReason}
                  onChange={e => setDeclineReason(e.target.value)}
                  minRows={4}
                  inputProps={{
                    maxLength: 100,
                    'data-testid': 'reason-textbox',
                  }}
                  helperText={`${declineReason?.length}/100`}
                  FormHelperTextProps={{
                    style: {
                      position: 'absolute',
                      bottom: '0%',
                      right: '0%',
                    },
                  }}
                />
              </Grid>
              <EmptyGridForUI />
            </>
          )}
        </DialogContent>
        {isShowReason && (
          <DialogActions>
            <Box sx={{ display: 'flex', mt: '20px' }}>
              <SecondaryButton
                inputProps={{ 'data-testid': 'secondarybtn' }}
                onClick={() => {
                  handleConfirmDenied({ status });
                }}
                style={{ marginRight: '16px', padding: '8px 65px 8px 65px' }}
              >
                Yes
              </SecondaryButton>

              <PrimaryButton
                onClick={handleClose}
                style={{ padding: '8px 65px 8px 65px' }}
              >
                No
              </PrimaryButton>
            </Box>
          </DialogActions>
        )}
        {!isShowReason && (
          <DialogActions>
            <Box sx={{ display: 'flex', mt: '20px' }}>
              <SecondaryButton
                data-testid="secondarybtn"
                onClick={handleConfirmApprove}
                style={{ marginRight: '16px', padding: '8px 65px 8px 65px' }}
              >
                {secondarybtn}
              </SecondaryButton>

              <PrimaryButton
                onClick={handleClose}
                style={{ padding: '8px 65px 8px 65px' }}
              >
                {primarybtn}
              </PrimaryButton>
            </Box>
          </DialogActions>
        )}
        <SimpleBackdrop open={loading} />
      </Dialog>
      
    </div>
  );
};

export default ReusableModal;
