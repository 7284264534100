import React, { useEffect, useState } from 'react';
import Cards from './Cards';
import {
  BackendSearchTextField,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { Box, Typography } from '@material-ui/core';
import RolesApi from 'services/api/RolesApiNew';
import { useRef } from 'react';

const Listing = ({
  isManagerole = false,
  setSelectedRole,
  payload,
  setNewRoleId,
}) => {
  const [selectedRoleValue, setSelectedRoleValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [roleData, setRoleData] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [OnloadData, setOnloadData] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const loadMore = useRef(null);
  const offset = useRef(0);

  const orgId = sessionStorage.getItem('orgId');

  const getAllRoles = async offset => {
    setLoading(true);
    await RolesApi.listRoles({ orgId, searchValue, offset })
      .then(res => {
        if(res.data){
          res.data = res.data?.filter(role => role.roleName !== "Third Party Developer")
        }
        if (searchText || offset === 0) {
          setRoleData(res.data);
        } else {
          setRoleData(prev => [...prev, ...res.data]);
        }
        setTotalItems(res.totalItems);
        setOnloadData(true);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setOnloadData(true);
        console.error(err);
      });
  };

  useEffect(() => {
    getAllRoles(0);
  }, [searchText]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          offset.current += 1;
          totalItems > offset.current * 10 && getAllRoles(offset.current);
        }
      },
      { threshold: 1 }
    );

    if (loadMore.current) {
      observer.observe(loadMore.current);
    }

    return () => {
      if (loadMore.current) {
        observer.unobserve(loadMore.current);
      }
    };
  }, [loadMore.current]);

  const onSearchClick = () => {
    setIsInitialRender(false);
    setSearchText(searchValue);
  };

  const onChangeText = text => {
    setSearchValue(text);
  };

  const onClearData = () => {
    offset.current = 0;
    setSearchValue('');
    setSearchText('');
  };

  const refetchData = () => {
    setSearchValue('');
    setSearchText('');
    offset.current = 0;
    if (searchValue === '' && searchText === '') {
      getAllRoles(0);
    }
  };

  return (
    <div style={{ background: 'white', padding: '20px' }}>
      <Box style={{ textAlignLast: 'center' }}>
        <Typography style={{ fontSize: '28px', fontWeight: 600 }}>
          Assign role
        </Typography>
        <Typography style={{ color: '#344054' }}>
          Assign the role for the user you want to invite
        </Typography>
      </Box>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '3%',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '280px',
            marginRight: '16px',
            height: '40px',
          }}
        >
          <BackendSearchTextField
            label="Search by role name"
            onChangeText={onChangeText}
            value={searchValue}
            clearData={onClearData}
            onSearchClick={onSearchClick}
          />
        </div>
        <SecondaryButton
          style={{ marginLeft: '-8px' }}
          onClick={onSearchClick}
          data-testid="search-rule"
        >
          Search
        </SecondaryButton>
      </div>
      <Cards
        selectedRole={setSelectedRole}
        data={roleData}
        payload={payload}
        refetchData={() => refetchData()}
        loading={loading}
        loadMore={loadMore}
        setNewRoleId={setNewRoleId}
        OnloadData={OnloadData}
        isInitialRender={isInitialRender}
      />

      <SimpleBackdrop open={loading} />
    </div>
  );
};

export default Listing;
