import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import colors from '../../colors';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(() => ({
  pickerRoot: {
    '& .Mui-selected': {
      backgroundColor: '#036FCB!important',
    },
  },
  OKButton: {
    borderRadius: 40,
    padding: '8px 6px',
    cursor: 'pointer',
    fontWeight: 400,
    backgroundColor: colors.BLUE,
    '&:hover': {
      backgroundColor: colors.BLUE_HOVER,
  
    },
    '& .MuiButton-label': {
      color: colors.WHITE,
      fontFamily: 'Inter',
      fontSize: '16px',
      width: 'fit-content',
      backgroundColor: colors.BLUE,
      '&:hover': {
        backgroundColor: colors.BLUE_HOVER,
    
      },
    },
  }
}));

const CustomStaticDatePicker = React.forwardRef((props, ref) => {
  const [value, setValue] = useState();
  const classes = useStyles();
  useEffect(() => {
    setValue(dayjs(props?.customDate));
  }, [props?.customDate]);

  const handleOnClick = () => {
    if (value) {
      props?.handleDateSelect(value);
      ref.current.style.display = 'none';
    }
  };

  return (
    <div
      className={props.rootClass}
      style={{ display: 'none', border: '1px solid rgba(3, 111, 203, 0.08)' }}
      ref={ref}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          disableFuture={true}
          disableHighlightToday={true}
          openTo="day"
          value={value}
          onChange={(newValue)=>{            
            setValue(newValue);
          }}
          autoOk={false}
          closeOnSelect={false}
          className={classes.pickerRoot}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <div style={{ position: 'absolute', bottom: '8px', right: '30px' }}>
        <Button color="secondary" variant="contained" className={classes.OKButton} onClick={handleOnClick}>
          OK
        </Button>
      </div>
    </div>
  );
});

export default CustomStaticDatePicker;
