import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft:"10px"
  },
  closeButton: {
    color: '#000',
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '0px 10px',
    marginTop: 'auto',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = props => {
  const { children, handleClose } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(classes.dialogTitle, classes.root)}>
      <Typography variant="h2" style={{ fontSize: '28px', fontWeight: 600 }}>
        {children}
      </Typography>
      {handleClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          size="small"
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.root}>{children}</Box>;
};

const DialogFooter = ({ children }) => {
  const classes = useStyles();
  return <Box className={clsx(classes.root, classes.footer)}>{children}</Box>;
};

const FullScreenDialog = ({ open = true, handleClose, title, children,isAppBarRequired = false,appBarChildren,onToggle, className }) => {
  return ( 
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onToggle}
        className={className}
        TransitionComponent={Transition}>
        <DialogTitle handleClose={onToggle || handleClose}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {isAppBarRequired && (
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              boxShadow: '-2px 0px 6px #aaa',
            }}
          >
            {appBarChildren}
          </div>
         )}
      </Dialog>
    </div>
  );
};

export default Object.assign(FullScreenDialog, {
  Content: DialogContent,
  Footer: DialogFooter,
});
