import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <g clip-path="url(#clip0_3367_51208)">
        <path
          d="M20 40.0001C31.0458 40.0001 40.0001 31.0458 40.0001 20C40.0001 8.95432 31.0458 0 20 0C8.95432 0 0 8.95432 0 20C0 31.0458 8.95432 40.0001 20 40.0001Z"
          fill="#344054"
        />
        <path
          d="M20.9844 22.0156C20.9844 21.4531 21.4531 20.9844 22.0156 20.9844H28.0156C28.5312 20.9844 29 21.4531 29 22.0156V28.0156C29 28.5312 28.5312 29 28.0156 29H22.0156C21.4531 29 20.9844 28.5312 20.9844 28.0156V22.0156ZM11.9844 29C11.4688 29 11 28.5312 11 28.0156V22.0156C11 21.4531 11.4688 20.9844 11.9844 20.9844H17.9844C18.5469 20.9844 19.0156 21.4531 19.0156 22.0156V28.0156C19.0156 28.5312 18.5469 29 17.9844 29H11.9844ZM11 11.9844C11 11.4688 11.4688 11 11.9844 11H17.9844C18.5469 11 19.0156 11.4688 19.0156 11.9844V17.9844C19.0156 18.5469 18.5469 19.0156 17.9844 19.0156H11.9844C11.4688 19.0156 11 18.5469 11 17.9844V11.9844ZM23.9375 10.3906C24.3594 10.0156 24.9688 10.0156 25.3906 10.3906L29.6094 14.6562C29.9844 15.0312 29.9844 15.6406 29.6094 16.0625L25.3438 20.2812C24.9688 20.7031 24.3594 20.7031 23.9375 20.2812L19.7188 16.0625C19.2969 15.6406 19.2969 15.0312 19.7188 14.6562L23.9375 10.3906Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3367_51208">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Icon;
