import React, { useEffect, useMemo, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Grid,
} from '@material-ui/core';
import {
  PrimaryButton,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RulesEngineApi from 'services/api/RuleEngineApi';
import RulesApi from 'services/api/RulesApi';
import { values } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1px 40px 1px 16px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: 600,
  },
  containerBox: {
    backgroundColor: '#F5F7F8',
    padding: '10px',
    borderRadius: '8px',
  },
  actionGrpContainer: {
    backgroundColor: '#E4E7EC',
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    flexWrap: 'wrap',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    // width: 'fit-content',
    gap: '10px',
    padding: '16px',
  },
  addIconContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
  },
  accordionDetails: {
    // backgroundColor: '#F5F7F8',
    padding: '0px 16px',
    width: '100%',
  },
  actionSummaryContainer: {
    backgroundColor: '#E4E7EC',
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    alignItems: 'center',
  },
  ActionGrpSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '10px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
}));

export default function ActionForRules({
  ruleValues,
  setSavedAction,
  setEditAction,
  tabStatus,
  setRule,
  hasCreatePermissions
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [actionOptions, setActionOptions] = useState([]);
  const [selectedType, setSelectedType] = useState(
    ruleValues?.ruleDefn?.actions?.[0]?.actionId || ''
  );
  const [label, setlabel] = useState(
    ruleValues?.ruleDefn?.actions?.[0]?.value || ''
  );
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [saveAction, setSaveAction] = useState(false); //* This should be props
  const orgId = sessionStorage.getItem("orgId");
  const isActionSavedBefore = useRef(
    ruleValues?.ruleDefn?.actions?.[0]?.actionId ? true : false
  );
  const [saveActionStatus, setSaveActionStatus] = useState(
    ruleValues?.ruleDefn?.actions?.[0]?.actionId
      ? tabStatus?.SAVED
      : tabStatus?.NOT_SAVED
  );

  const [actionGrpValue, setactionGrpValue] = useState([
    {
      actions: [
        {
          actionId:
            ruleValues?.ruleDefn?.actions?.[0]?.actionId || 'placeholder',
          actionName: ruleValues?.ruleDefn?.actions?.[0]?.actionName || null,
          value: ruleValues?.ruleDefn?.actions?.[0]?.value || null,
          labelCode: ruleValues?.ruleDefn?.actions?.[0]?.labelCode || null,
          parameters: ruleValues?.ruleDefn?.actions?.[0]?.parameters || null,
          createLabel: ruleValues?.ruleDefn?.actions?.[0]?.value === label ? false : true,
        },
      ],
    },
  ]);

  const getAllActionList = useCallback(() => {
    async function getAttribute() {
      try {
        const results = await RulesEngineApi.getActionList('');
        const arrayValue = [];
        results?.data?.map(a => {
          arrayValue.push({
            id: a.id,
            name: a.name,
            description: a.description,
            parameters: a.parameters,
          });
        });
        setActionOptions(arrayValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    }
    getAttribute();
  });

  useEffect(() => {
    getAllActionList();
  }, []);

  const handleTypeSelection = e => {
    handleActionStatus();
    setlabel('');
    setSelectedType(e.target.value);
  };

  const handleClearAction = () => {
    setSelectedType('');
    setlabel('');
    setExpanded(false);
    setEditAction(false);
    setactionGrpValue([
      {
        actions: [
          {
            actionId: 'placeholder',
            actionName: null,
            value: null,
            labelCode: null,
            parameters: null,
            createLabel: true,
          },
        ],
      },
    ]);
  };

  const handleActionStatus = () => {
    if (saveActionStatus === tabStatus?.SAVED) {
      setSaveActionStatus(tabStatus?.NOT_SAVED);
      setSavedAction(false);
    }
  };

  const handleOnchange = val => {
    handleActionStatus();
    setEditAction(true);
    //actionOptions.filter(val => val.id === selectedType)?.[0]?.name
    let actnGrpVal = [
      {
        actions: [
          {
            actionId: selectedType,
            actionName: selectedType,
            value:
              ruleValues?.ruleDefn?.actions[0]?.value === label ? null : label,
            labelCode: ruleValues?.ruleDefn?.actions[0]?.labelCode,
            parameters: null,
            createLabel:
              ruleValues?.ruleDefn?.actions[0]?.value === label ? false : true,
          },
        ],
      },
    ];
    setactionGrpValue(actnGrpVal);

    return actnGrpVal;
  };

  const handleEditAction = () => {
    setSaveActionStatus(tabStatus?.NOT_SAVED);
    setSavedAction(false);
    setEditAction(true);
    setExpanded(prevValue => !prevValue);
  };

  const closeWithoutEdit = () => {
    setSelectedType(ruleValues?.ruleDefn?.actions[0]?.actionId);
    setlabel(ruleValues?.ruleDefn?.actions[0]?.value);
    setSaveActionStatus(tabStatus?.SAVED);
    setSavedAction(true);
    setEditAction(false);
    setExpanded(prevValue => !prevValue);
    setactionGrpValue([
      {
        actions: [
          {
            actionId: ruleValues?.ruleDefn?.actions[0]?.actionId,
            actionName: ruleValues?.ruleDefn?.actions[0]?.actionName,
            value: ruleValues?.ruleDefn?.actions[0]?.value,
            labelCode: ruleValues?.ruleDefn?.actions[0]?.labelCode,
            parameters: null,
            createLabel: true,
          },
        ],
      },
    ]);
  };

  const handleCriteriaValidations = () => {
    if (!selectedType && !label) {
      enqueueSnackbar('Please enter value', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!label) {
      enqueueSnackbar('Please enter label', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else if (!selectedType) {
      enqueueSnackbar('Please select action type', {
        variant: 'error',
        autoHideDuration: 1500,
      });
    } else {
      return true;
    }
    return false;
  };

  const handleSaveAction = async () => {
    if (!handleCriteriaValidations()) return;
    setLoading(true);
    setSaveActionStatus(tabStatus?.SAVING);
    let updatedActnGrpValue = handleOnchange();
    // setactionGrpValue([
    //     {
    //       actions: [
    //           {
    //               actionId: selectedType,
    //               actionName: selectedType,
    //               value: ruleValues.ruleDefn.actions[0].value === label ? null : label,
    //               labelCode: ruleValues.ruleDefn.actions[0].labelCode,
    //               parameters: null,
    //               createLabel: true
    //           }
    //       ]
    //     },
    //   ]);
    let checkForEdit = ruleValues?.id;

    let payload = {
      ...ruleValues,
      editBeforeExec: checkForEdit ? true : false,
      ruleDefn: {
        ...ruleValues?.ruleDefn,
        actions: updatedActnGrpValue?.[0]?.actions,
      },
    };

    // todo Add Save API
    try {
      delete payload.ruleDefn?.actions?.[0]?.labelCode;
      let results = await RulesApi.saveRules({ orgId, payload });
      if (
        results.ruleDefn?.actions?.[0]?.labelCode &&
        results.ruleDefn?.actions?.[0]?.createLabel
      ) {
        results.ruleDefn.actions[0].createLabel = false;
      }
      setRule(results);
      setExpanded(false);
      setLoading(false);
      isActionSavedBefore.current = true;
      setSaveActionStatus(tabStatus?.SAVED);
      setSavedAction(true);
      setEditAction(false);
    } catch (err) {
      if (err.response?.data?.details === 'Label already exists') {
        enqueueSnackbar(
          'Label name already exists. Please provide different label name',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
      }
      setSaveActionStatus(tabStatus?.REJECTED);
      setSavedAction(false);
      setEditAction(true);
      setLoading(false);
    }
  };

  const savedStatusStatus = useMemo(() => {
    if (saveActionStatus === tabStatus?.SAVING) {
      return (
        <CircularProgress
          style={{ color: 'green', width: '18px', height: '18px' }}
        />
      );
    } else if (saveActionStatus === tabStatus?.SAVED) {
      return (
        <CheckCircleOutlineIcon
          style={{ color: '#007D32', fontSize: '22px' }}
        />
      );
    } else if (saveActionStatus === tabStatus?.REJECTED) {
      return <HighlightOffIcon style={{ color: 'red', fontSize: '22px' }} />;
    } else {
      return (
        <CheckCircleOutlineIcon style={{ color: 'grey', fontSize: '22px' }} />
      );
    }
  }, [saveActionStatus]);

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.containerBox}
        expanded={expanded}
        // onChange={() => setExpanded(prevValue => !prevValue)}
        data-testid="action-accordion">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {savedStatusStatus}
              <Typography
                data-testid="action-title"
                className={classes.heading}>
                Action
              </Typography>
              {!expanded && (
                <Box style={{ marginLeft: 'auto' }}>
                  {ruleValues?.status === 'DRAFT' ? isActionSavedBefore.current ? (
                    
                      <SecondaryButton onClick={handleEditAction} hasPermissions={hasCreatePermissions}>
                        Edit
                      </SecondaryButton>
                    
                  ) : (
                    <SecondaryButton
                      onClick={() => setExpanded(prevValue => !prevValue)} hasPermissions={hasCreatePermissions}>
                      Add action
                    </SecondaryButton>
                  ) : ""}
                </Box>
              )}
            </Box>
            {saveActionStatus === 'SAVED' && !expanded && (
              <Box className={classes.ActionGrpSummaryContainer}>
                {actionGrpValue?.map(action => {
                  return (
                    <Box display={'flex'} alignItems={'center'}>
                      {action.actions?.map(val => (
                        <>
                          <Box className={classes.actionSummaryContainer}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              style={{ gap: '10px' }}>
                              <Typography variant="h6">
                                <b>Type : </b>
                                {actionOptions.map(i => {
                                  if (
                                    i.id === val.actionName ||
                                    i.id === val.actionId
                                  ) {
                                    return i.name;
                                  }
                                })}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className={classes.actionSummaryContainer}
                            style={{ marginLeft: '16px' }}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              style={{ gap: '10px' }}>
                              <Typography variant="h6">
                                <b>Label : </b> {val.value}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      ))}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionDetails}>
            <Box
              className={classes.actionGrpContainer}
              style={{ display: 'flex' }}>
              <Grid item sm={2} xs={2} className={classes.mt}>
                <Typography className={classes.formTitle}>Type</Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ maxWidth: '100%' }}>
                  <Select
                    style={{
                      backgroundColor: 'white',
                      color: selectedType === '' ? '#667085' : 'initial',
                    }}
                    value={selectedType}
                    onChange={e => {
                      handleTypeSelection(e);
                      handleOnchange();
                    }}
                    className={classes.formTextField}
                    margin="dense"
                    placeholder="Select type"
                    IconComponent={ExpandMoreIcon}
                    defaultValue=""
                    displayEmpty>
                    <MenuItem disabled value="">
                      Select action
                    </MenuItem>
                    {actionOptions.map(i => {
                      return (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={2} xs={2} className={classes.mt}>
                <Typography className={classes.formTitle}>
                  Label name
                </Typography>
                <TextField
                  required
                  id="rule-title"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  placeholder="Enter name of label"
                  value={label}
                  onChange={e => {
                    setlabel(e.target.value);
                    handleOnchange(e.target.value);
                  }}
                  autoComplete="off"
                  autoFocus
                  style={{ background: 'white' }}
                />
              </Grid>
            </Box>
            <Box style={{ margin: '10px 0px' }}>
              <Box style={{ margin: '20px 0px', display: 'flex', gap: '10px' }}>
                <SecondaryButton
                  onClick={
                    isActionSavedBefore.current
                      ? closeWithoutEdit
                      : handleClearAction
                  }
                  disabled={
                    saveActionStatus === tabStatus?.SAVING ||
                    saveActionStatus === tabStatus?.SAVED
                  }
                  data-testid="cancel">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    actionGrpValue[0]?.actions[0]?.actionId === 'placeholder' ||
                    saveActionStatus === tabStatus?.SAVING ||
                    saveActionStatus === tabStatus?.SAVED ||
                    (label === ruleValues?.ruleDefn?.actions[0]?.value &&
                      selectedType ===
                        ruleValues?.ruleDefn?.actions[0]?.actionId)
                  }
                  onClick={handleSaveAction}
                  data-testid="save-criteria">
                  {saveActionStatus !== tabStatus?.SAVING ? (
                    'Save'
                  ) : (
                    <CircularProgress
                      style={{ color: 'green', width: '15px', height: '15px' }}
                    />
                  )}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SimpleBackdrop open={loading} />
    </div>
  );
}
