import React from 'react';
import { Field } from 'react-final-form';
import { required, composeValidators } from 'shared/validators';
import { TextField } from 'components/widgets';

export default function FormTextField({
  name,
  label,
  value,
  disabled,
  placeholder,
  otherValidators = [],
  trim = false,
  dataTestId,
  customClass,
  customInputFieldStyles
}) {
  return (
    <Field
      name={name}
      validate={composeValidators(required, ...otherValidators)}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          trim={trim}
          required
          placeholder={placeholder}
          disabled={disabled}
          label={label}
          value={value}
          dataTestId={dataTestId || ''}
          {...input}
          touched={touched}
          error={error}
          customClass={customClass}
          customInputFieldStyles={customInputFieldStyles}
          ></TextField>
      )}
    </Field>
  );
}
