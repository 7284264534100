import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  PageHeaderTitle,
  PrimaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RulesApi from 'services/api/RulesApi';
import { AccordionSelection } from '../../Accordion/CohortAccordion';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: '600',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  createCriteriaContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  criteriaContainer: {
    backgroundColor: 'white',
    margin: '16px',
    padding: '10px 20px',
    borderRadius: '10px',
  },
  criteriaOptions: {
    margin: '10px 0px',
  },
}));

const criteriaOptions = ['ICD', 'Age', 'Gender'];

const MIN_AGE = 0;
const MAX_AGE = 100;

const genderData = [
  {
    label: 'Male',
    selected: false,
    id: 0,
  },
  {
    label: 'Female',
    selected: false,
    id: 1,
  },
  {
    label: 'Others',
    selected: false,
    id: 2,
  },
];

const icdData = [
  {
    label: 'ICD 9',
    selected: false,
    id: 0,
  },
  {
    label: 'ICD 10',
    selected: false,
    id: 1,
  },
];

const CriteriaCreation = () => {
  const classes = useStyles();
  const [ageRange, setAgeRange] = useState([MIN_AGE, MAX_AGE]);
  const [apiLoading, setAPILoading] = useState(false);
  const [criteriaName, setCriteriaName] = useState('');
  const [selectedCriteria, setSelectedCriteria] = useState('');
  const [icdInfo, setIcdInfo] = useState({
    icdID: '',
    icdFile: '',
  });
  const [errorStatus, setErrorStatus] = useState({
    criteriaName: {
      error: false,
      errorMsg: 'Please enter criteria name',
    },
    criteriaOptions: {
      error: false,
      errorMsg: 'Please select atleast one option',
    },
  });
  const [selectedValues, setSeletedValues] = useState({});
  const history = useHistory();

  const orgId = sessionStorage.getItem("orgId");
  const { enqueueSnackbar } = useSnackbar();

  const handleData = (values, id, type, event) => {
    saveFiltersData(values, id, type, event.target.checked);
  };

  const handleICDData = (icdID, icdFile = '') => {
    setIcdInfo({
      icdID,
      icdFile,
    });
  };

  const handleAgeRange = (event, data) => {
    setAgeRange(data);
  };

  const handleCriteriaSelection = e => {
    setSelectedCriteria(e.target.value);
  };

  const saveFiltersData = (value, id, type, selectedBoolean) => {
    let prevValue = { ...selectedValues };

    if (!prevValue[type]) {
      prevValue[type] = {};
    }

    if (selectedBoolean) {
      prevValue[type] = {
        ...prevValue[type],
        [value[id]?.label]: value[id],
      };
    } else {
      delete prevValue[type][value[id]?.label];
    }

    setSeletedValues(prevValue);
  };

  const handleAppliedFilters = () => {
    let pickedCriteria = selectedCriteria.toLowerCase();

    let appliedFilters = {
      name: '',
      condition: '',
      value: [],
    };

    if (pickedCriteria === 'gender') {
      appliedFilters.name = pickedCriteria;
      appliedFilters.condition = '=';
      Object.values(selectedValues[pickedCriteria])?.forEach(gender => {
        appliedFilters.value.push(gender.label.toLowerCase());
      });

      return [appliedFilters];
    } else if (pickedCriteria === 'age') {
      let secondFilter = { ...appliedFilters };

      appliedFilters.name = 'min_age';
      appliedFilters.condition = '>=';
      appliedFilters.value = [ageRange?.[0] || MIN_AGE];

      secondFilter.name = 'max_age';
      secondFilter.condition = '<=';
      secondFilter.value = [ageRange?.[1] || MAX_AGE];

      return [appliedFilters, secondFilter];
    } else if (pickedCriteria === 'icd') {
      appliedFilters.name = icdInfo.icdID?.replace(/ /g, '');
      appliedFilters.condition = 'contains';
      appliedFilters.value = [];

      return [appliedFilters];
    }
  };
  const validateForm = () => {
    const errorUpdateHelper = (errorPropName, revertPropName) => {
      let errorStats = { ...errorStatus };
      errorStats[errorPropName].error = true;
      errorStats[revertPropName].error = false;
      return errorStats;
    };
    let isError = false;
    if (criteriaName === '') {
      isError = true;
      setErrorStatus(errorUpdateHelper('criteriaName', 'criteriaOptions'));
    } else if (
      selectedCriteria === 'ICD' &&
      (!icdInfo.icdFile || icdInfo.icdID === '')
    ) {
      isError = true;
      enqueueSnackbar(
        icdInfo.icdID === ''
          ? 'Please select ICD Code'
          : 'Please upload ICD file',
        {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      );

      if (errorStatus.criteriaName.error) {
        setErrorStatus(prev => ({
          ...prev,
          criteriaName: {
            ...prev[criteriaName],
            error: false,
          },
        }));
      }
    } else if (
      selectedCriteria !== 'Age' &&
      selectedCriteria !== 'ICD' &&
      Object.keys(selectedValues[selectedCriteria.toLowerCase()] || {})
        ?.length === 0
    ) {
      isError = true;
      setErrorStatus(errorUpdateHelper('criteriaOptions', 'criteriaName'));
    } else {
      let errorStats = { ...errorStatus };
      errorStats.criteriaName.error = false;
      errorStats.criteriaOptions.error = false;
      setErrorStatus(errorStats);
    }

    return isError;
  };
  const handleCreateCriteria = async () => {
    if (validateForm()) return;
    let payload = {
      name: criteriaName,
      description: '', //Optional Param
      value: {
        filters: handleAppliedFilters(),
      },
    };
    let formDataPayload = new FormData();
    if (selectedCriteria === 'ICD') {
      formDataPayload.set('file', icdInfo.icdFile);
    }

    formDataPayload.set('request', JSON.stringify(payload));

    setAPILoading(true);
    try {
      await RulesApi.createCriteria({ orgId, payload: formDataPayload });
      setAPILoading(false);
      enqueueSnackbar(
        'criterion created successfully. Redirecting to Criteria Page',
        {
          variant: 'success',
          autoHideDuration: 1000,
        }
      );

      setTimeout(() => {
        history.push({
          pathname: `/organization/${orgId}/rules`,
          state: {
            selectTabIndex: 0,
          },
        });
      }, 1000);
    } catch (error) {
      setAPILoading(false);
      console.error('Error while creating Criteria', error);
      if (error.response?.status === 409) {
        setCriteriaName('');

        enqueueSnackbar('Please provide different criterion name', {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 2000,
        });
      } else if (error.response?.status === 400) {
        setCriteriaName('');
        enqueueSnackbar(error.response.data, {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar('Error adding criteria', {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 2000,
        });
      }
    }
  };

  const criteriaConf = {
    Gender: {
      data: genderData,
      id: 'gender',
      onChange: (data, rule) => handleData(genderData, data.id, 'gender', rule),
      title: 'Gender',
      detailsFlexDirection: 'row',
    },
    // Location: {
    //   data: locationData,
    //   id: 'location',
    //   onChange: data => handleData(locationData, data.id, 'location'),
    //   title: 'Location',
    // },
    Age: {
      ariaLabel: 'age-range',
      chipLabel: `${ageRange?.[0]} - ${ageRange?.[1]}`,
      data: ageRange,
      detailsType: 'slider',
      onChange: handleAgeRange,
      onChipDelete: () => setAgeRange([MIN_AGE, MAX_AGE]),
      singleChip: true,
      title: 'Age',
    },
    ICD: {
      data: icdData,
      id: 'icd',
      detailsType: 'select',
      onChange: (icdCode, icdFile) => handleICDData(icdCode, icdFile),
      noChip: true,
      title: 'ICD codes',
      detailsFlexDirection: 'row',
    },
  };
  return (
    <>
      <Box>
        <PageHeaderTitle>{'Create criterion'}</PageHeaderTitle>
        <Box className={classes.criteriaContainer}>
          <Box>
            <TextField
              required
              id="outlined-basic"
              label="Criteria name"
              variant="outlined"
              value={criteriaName}
              onChange={e => setCriteriaName(e.target.value)}
              error={errorStatus.criteriaName?.error}
              helperText={
                errorStatus.criteriaName.error &&
                errorStatus.criteriaName.errorMsg
              }
            />
          </Box>
          <Box>
            <FormControl
              className={classes.formControl}
              error={errorStatus.criteriaOptions.error}>
              <InputLabel id="demo-simple-select-label">
                Select Criteria
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCriteria}
                onChange={e => handleCriteriaSelection(e)}>
                {criteriaOptions.map(val => (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
              {errorStatus.criteriaOptions?.error && (
                <FormHelperText>
                  {errorStatus.criteriaOptions.errorMsg}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box className={classes.criteriaOptions}>
            {selectedCriteria && criteriaConf[selectedCriteria] && (
              <AccordionSelection
                {...criteriaConf[selectedCriteria]}
                selectedCriteriaValue={
                  selectedValues?.[selectedCriteria.toLowerCase()]
                }
              />
            )}
          </Box>

          <Box className={classes.createCriteriaContainer}>
            {selectedCriteria && (
              <PrimaryButton onClick={handleCreateCriteria}>
                Create criterion
              </PrimaryButton>
            )}
          </Box>
        </Box>
      </Box>
      <SimpleBackdrop open={apiLoading} />
    </>
  );
};

export default CriteriaCreation;
