import BaseApiService from './BaseApiService';

function _getPatientIds() {
  return BaseApiService.get('/fhir/openapi/dummy-patient-ids');
}

function _getDevLinks({ orgId, affiliateId }) {
  return BaseApiService.get(
    `/organization/${orgId}/developerGuide/${affiliateId}/links`
  );
}

const SandboxPageApi = {
  getPatientIds: _getPatientIds,
  getDevLinks: _getDevLinks,
};

export default SandboxPageApi;
