import BaseApiService from './BaseApiService';

function getImagesList() {
  return BaseApiService.get(
    `https://me.mpoweredhealth.com/homedashboard/v1/service/cardElements`,
    null,
    false,
    {},
    true
  );
}

function updateImages(payload) {
  const otherHeaders = {
    'content-type': 'multipart/form-data',
  };

  return BaseApiService.post(`/partner/organization/demo/uploadImages`, null, payload,false,false,otherHeaders);
}


function getLoginPageTitles() {
  return BaseApiService.get(
    `/organization/demo/getCarouselDetails`);
}

const createLoginTitle = (payload) => {
  return BaseApiService.post(
    `/organization/demo/createCarouselDetails`,
    null,
    payload
  );
};

const updateLoginTitle = (payload) => {
  return BaseApiService.put(
    `/organization/demo/updateCarouselDetails`,
    null,
    payload
  );
};

const deleteLoginTitle = (titleId) => {
  return BaseApiService.delete(
    `/organization/demo/deleteCarouselDetails?id=${titleId}`
  );
};

const ImagesConfigApi = {
  getImagesList,
  updateImages,
  getLoginPageTitles,
  createLoginTitle,
  updateLoginTitle,
  deleteLoginTitle
};

export default ImagesConfigApi;
