import { makeStyles } from '@material-ui/core/styles';
import {
  PageHeaderTitle,
  PrimaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AttributeListingPage from './AttributeListingPage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contained2: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  container: {
    // paddingLeft: 16,
    // paddingRight: 16,
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={Box}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AttributeMainPage = ({ isSuperAdmin, options, getTabIndexClicked }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [createAttributeOpenState , setCreateAttributeOpenState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const TabHeading = ['FHIR', 'Non-FHIR'];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const styles = {
    TabContainer: {
      backgroundColor: '#F5F5F5',
      position: 'relative',
      marginLeft: 22,
    },

    TabHeading: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
    },

    cardContainer: {
      minHeight: '100%',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      paddingTop: 20,
    },
    cardWrapper: { marginBottom: 32, marginRight: 32 },
    TabsWrapper: {
      borderBottom: '2px solid #D9D9D9',
      height: 62,
    },
  };

  const orgId = sessionStorage.getItem("orgId");

  const onCloseCreate=()=>{
    setCreateAttributeOpenState(false);
  }

  const handleCreateAttribute = () => {
    setCreateAttributeOpenState(true);
    // setOpen(true);
    // setSearchText('');
    // setSearchValue('');
    // setAttributeFilter('all');
    // if (
    //   setFilteredAttribute?.length === 0 &&
    //   searchValue === '' &&
    //   searchText === '' &&
    //   setAttributeFilter('all')
    // ) {
    //   getAllAttributes();
    // }
  };

  return (
    <>
     
        {isSuperAdmin && (
          <div style={{ marginBottom: '10px', display: 'flex', marginLeft: '10px' }}>
            <PageHeaderTitle data-testid="attribute-title">
              Attributes
            </PageHeaderTitle>
            {isSuperAdmin && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                  height: 'fit-content',
                  alignSelf: 'center',
                  marginRight:'51px'
                }}>
                <PrimaryButton onClick={handleCreateAttribute}>
                  Create attribute
                </PrimaryButton>
              </div>
            )}
          </div>
        )}
        {/* tabs come here */}
        {isSuperAdmin ? (
          <Box sx={{ width: '95%' }} style={styles.TabContainer}>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
              style={styles.TabsWrapper}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className={classes.tabRoot}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#00A0DF',
                    height: 4,
                    borderRadius: '100px 100px 0px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '4px',
                    width:
                      value === 0 ? '60px' : value === 1 ? '108px' : '67px',
                  },
                }}
                style={{
                  paddingTop: 15,
                  marginLeft: 0,
                  paddingRight: 0,
                  margin: 0,
                  minWidth: '100px',
                }}>
                {TabHeading &&
                  TabHeading.length > 0 &&
                  TabHeading.map((heading, index) => (
                    <Tab
                      sx={{ minWidth: '100px' }}
                      key={index}
                      onClick={() =>
                        getTabIndexClicked && getTabIndexClicked(index)
                      }
                      label={<span style={styles.TabHeading}>{heading}</span>}
                      {...a11yProps(index)}
                    />
                  ))}
              </Tabs>
            </Box>
            <Box style={{ width: '100%', height: '100%', marginTop: '30px' }}>
                     
                 <AttributeListingPage key={value} value={value} isSuperAdmin={isSuperAdmin} options={options} createAttributeOpenState={createAttributeOpenState} onCloseCreate={onCloseCreate}/>
                      
                      
            </Box>
          </Box>
        ) : (
          <AttributeListingPage key={value} value={value} isSuperAdmin={isSuperAdmin} options={options} createAttributeOpenState={createAttributeOpenState} />
        )}
        {/* code end for tabs */}

      <SimpleBackdrop open={loading} />
    </>
  );
};

export default AttributeMainPage;
