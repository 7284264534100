import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M12.9004 19.7217L8.88086 15.75L12.9004 11.7783C13.2832 11.3477 13.2832 10.7256 12.9004 10.3428C12.4697 9.95996 11.8477 9.95996 11.4648 10.3428L6.77539 15.0322C6.34473 15.415 6.34473 16.085 6.77539 16.4678L11.4648 21.1572C11.8477 21.54 12.4697 21.54 12.9004 21.1572C13.2832 20.7744 13.2832 20.1523 12.9004 19.7217ZM19.5996 19.7217L23.6191 15.75L19.5996 11.7783C19.2168 11.3477 19.2168 10.7256 19.5996 10.3428C20.0303 9.95996 20.6523 9.95996 21.0352 10.3428L25.7246 15.0322C26.1553 15.415 26.1553 16.085 25.7246 16.4678L21.0352 21.1572C20.6523 21.54 20.0303 21.54 19.5996 21.1572C19.2168 20.7744 19.2168 20.1523 19.5996 19.7217Z" />
    </SvgIcon>
  );
};

export default Icon;
