import React, { useState } from 'react';
import { SecondaryButton, PrimaryButton } from 'components/widgets';
import { TextField } from '@material-ui/core';
import DocPopup from 'components/widgets/FlyoutPanel/DocPopup';
import { SimpleBackdrop } from 'components/widgets';
import { useSnackbar } from 'notistack';
import BaseApiService from 'services/api/BaseApiService';

const MemberAddActivityPopUp = ({ open, onClose, orgId, givenBy, givenTo }) => {
  const [activityDetails, setActivityDetails] = useState('');
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length <= 500) {
      setActivityDetails(value);
      setDisable(value.length === 0);
    }
  };

  const handleAddActivity = async () => {
    setLoading(true);
    const requestBody = {
      givenBy,
      givenTo,
      notes: activityDetails
    };

    try {
      const response = await BaseApiService.post(`/organization/${orgId}/addNotes/`,
        null,
        JSON.stringify(requestBody)
      );

      if (response) {
        enqueueSnackbar('Note added successfully', { variant: 'success' });
        handleClose();
      } else {
        enqueueSnackbar('Failed to add note', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to add note', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setActivityDetails('');
    setDisable(true);
    onClose();
  };

  return (
    <DocPopup open={open} onClose={handleClose} title="Add Notes">
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <TextField
          label="Note Details"
          variant="outlined"
          multiline
          rows={4}
          value={activityDetails}
          onChange={handleInputChange}
          helperText={`${activityDetails.length}/500`}
          fullWidth
        />
        <div style={{ marginTop: '48px', display: 'flex', justifyContent: 'flex-end' }}>
          <SecondaryButton onClick={handleClose} size="large" style={{ marginRight: 10 }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" type="add" disabled={disable} onClick={handleAddActivity}>
            Add
          </PrimaryButton>
        </div>
      </div>
      <SimpleBackdrop open={loading} />
    </DocPopup>
  );
};

export default MemberAddActivityPopUp;
