import { Typography } from '@material-ui/core';

function PageHeaderTitle({ children }) {
  return (
    <Typography
      variant="h2"
      style={{ margin: 16, fontWeight: 'bold', fontSize: 28 }}>
      {children}
    </Typography>
  );
}

export default PageHeaderTitle;
