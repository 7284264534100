import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '../inputs/index';
import colors from '../colors';
import FieldWrapper from '../FieldWrapper';
// import MarkRestricted from '../MarkRestrictedCard';
import { SELF_REPORTING_TEXTS } from '../constants';
import { SELF_REPORTED_VALUE_KEYS } from '../selfReportingUtils';
import clsx from 'clsx';
import LinearStepper from '../LinearStepper';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const styles = (theme) => ({
  root: {
    margin: 0,
    borderRadius: '5px',
    textAlign: 'justify',
    width: '100%',
  },
  wrapper: {
    padding: '32px 8px 32px 32px',
    backgroundColor: colors.WHITE,
    borderRadius: 16,
    boxSizing: 'border-box',
    minHeight: 466,
    marginBottom: 32,
  },
  inlineRow: {
    width: '50%',
    paddingRight: 24,
    display: 'inline-box',
    boxSizing: 'border-box',
    marginBottom: 16,
  },
  fullWidthRow: {
    width: '100%',
  },
  cancelBtn: {
    fontWeight: 500,
    color: colors.BLUE,
    fontSize: 16,
    fontFamily: 'Inter',
    backgroundColor: colors.WHITE,
    padding: '8px 24px',
    borderRadius: 40,
    border: `1px solid ${colors.SUBTLE_GRAY}`,
    height: 48,
    marginRight: 40,
    letterSpacing: 0,
  },
  submit: {
    fontWeight: 500,
    color: colors.WHITE,
    fontSize: 16,
    fontFamily: 'Inter',
    backgroundColor: colors.BLUE,
    padding: '8px 24px',
    borderRadius: 40,
    height: 48,
    marginRight: 40,
    letterSpacing: 0,
  },
  disabled: {
    backgroundColor: colors.BLUE_DISABLE,
  },
  skip: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Inter',
    backgroundColor: colors.WHITE,
    padding: '8px 24px',
    borderRadius: 40,
    height: 48,
    color: colors.BLUE,
    border: '1px solid #A0A4A6',
    marginRight: 16,
    letterSpacing: 0,
  },
  requiredMsg: {
    display: 'block',
    color: colors.RED,
    margin: '0 0 10px 5px',
    fontWeight: 400,
    fontSize: 14,
  },
});

const SelfReportedFormBuilder = (props) => {
  const {
    classes,
    inputList,
    onSubmit,
    showMarkRestricted,
    isEdit,
    markAsRestricted = false,
    setTimeChange,
    showScan,
    onSkipPress,
    buttonText,
    showReminder,
    headerInfo,
    restrictedDescription,
    activeStep,
    steps,
  } = props;

  const {
    SAVE_CONTINUE,
    CONTINUE,
    CANCEL,
  } = SELF_REPORTING_TEXTS;
  const [inputValues, setInputValues] = useState({});
  const [erroredFields, setErroredFields] = useState({});
  const [restricted, setRestricted] = useState(markAsRestricted);
  const [submitDisabled, setSubmitDisabled] = useState(inputList && inputList.length);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { categoryName } = useParams();
  const SERVICE_TYPE = categoryName;
  // const [requiredFieldsError, setRequiredFieldsError] = useState(false);

  const rootRef = useRef(null);

  const checkForAllRequiredFields = (values = inputValues) => {
    let disabled = false;
    inputList?.forEach((item) => {
      if (item.required && !values[item.valueKey]) {
        disabled = true;
      }
    });
    setSubmitDisabled(disabled);
  };

  useEffect(() => {
    const values = {};
    if (Array.isArray(inputList) && inputList.length) {
      inputList.forEach((item) => {
        if (item && item.valueKey && item.value) {
          values[item.valueKey] = item.formatting ? item.formatting(item.value) : item.value;
        }
      });
      setInputValues(values);
      checkForAllRequiredFields(values);
    }
  }, [inputList]);

  useEffect(() => {
    checkForAllRequiredFields();
  }, [inputValues]);

  const handleFieldChange = (key, val) => {
    // if (key === 'issuedTime' && isEdit) {
    //   setTimeChange(true);
    // }
    const values = {
      ...inputValues,
      [key]: val,
    };
    setInputValues(values);
    setErroredFields({ ...erroredFields, [key]: false });
  };

  const validateInput = () => {
    let valid = true;
    const errorValues = { ...erroredFields };
    inputList?.forEach((item) => {
      const { required, regex, verifyInput, valueKey } = item;
      let currentInputValid = true;
      if (
        (required && !(inputValues[valueKey] && inputValues[valueKey] !== '')) ||
        (regex && inputValues[valueKey] && !inputValues[valueKey].toString().match(regex)) ||
        (verifyInput &&
          typeof verifyInput === 'function' &&
          inputValues[valueKey] &&
          !verifyInput(inputValues[valueKey], inputValues))
      ) {
        currentInputValid = false;
      }

      if (!currentInputValid) {
        errorValues[valueKey] = true;
        valid = currentInputValid;
      }
    });

    setErroredFields(errorValues);
    if (!valid) {
      rootRef.current.scrollIntoView({ behavior: 'smooth' });
      // setRequiredFieldsError(true);
    } else {
      // setRequiredFieldsError(false);
    }
    return valid;
  };

  const cancelBtn = () => {
    if(SERVICE_TYPE === 'coverage'){
      history.push('/records/coverage');
    } else {
      history.push(window.location.pathname.slice(0, window.location.pathname.length - 4));
    }
  };

  const validateForm = (confirm = false) => {
    if (submitDisabled) {
      validateInput();
      return;
    }
    // if (isEdit && !confirm) {
    //   setShowModal(true);
    //   return;
    // }
    // if (!isEdit || (isEdit && confirm)) {
    // setShowModal(false);
    if (!validateInput()) {
      return;
    }

    // if (showMarkRestricted) {
    //   inputValues[SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY] = restricted.toString();
    // }
    // if (showReminder && !inputValues.reminder) {
    //   inputValues.reminder = false;
    // }
    onSubmit(inputValues);
    // }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div ref={rootRef}>
          <LinearStepper activeStep={activeStep} steps={steps} />
        </div>
        {Array.isArray(inputList) && inputList.length
          ? inputList.map((input, index) => {
            const {
              emptyColumn = false,
              width,
              valueKey,
              conditionalRender,
              setTimeChange,
            } = input;
            if (conditionalRender && !conditionalRender(inputValues)) {
              return null;
            }
            if (emptyColumn) {
              return (
                <div
                  className={clsx(
                    classes.inlineRow,
                    width === 'fullWidth' ? classes.fullWidthRow : '',
                  )}
                ></div>
              );
            }
            return (
              <div
                className={clsx(
                  classes.inlineRow,
                  width === 'fullWidth' ? classes.fullWidthRow : '',
                )}
              >
                <FieldWrapper
                  key={index}
                  errored={erroredFields[valueKey] || false}
                  {...input}
                  onChange={(key, val) => handleFieldChange(key, val)}
                />
              </div>
            );
          })
          : null}

        {/* {showMarkRestricted && (
          <MarkRestricted
            enabled={restricted}
            onChange={setRestricted}
            description={restrictedDescription}
          />
        )} */}
      </div>

      {/* {requiredFieldsError && <span className={classes.requiredMsg}>{SELF_REPORTING_TEXTS.REQUIRED_FIELDS_VALUES_MISSING}</span>} */}
      {/* {isEdit && <Button onClick={onSkipPress} className={classes.skip} title="Skip" />} */}
      <button
        onClick={() => cancelBtn(false)}
        className={classes.cancelBtn}
        >
        {buttonText || CANCEL}
        </button>
      <button
        onClick={() => validateForm(false)}
        disabled={submitDisabled}
        className={clsx(classes.submit, submitDisabled ? classes.disabled : '')}
        >
        {buttonText || CONTINUE}
        </button>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SelfReportedFormBuilder);
