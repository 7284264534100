import React, { Component } from 'react';
import { PrimaryButton,SecondaryButton } from 'components/widgets';
import Page from 'components/Page';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// import { auth } from '../../components/Routes';
import { LANDING_PAGE } from 'shared/constants';

const styles = theme => ({
  heading: {
    marginBottom: 24,
    paddingLeft: 8,
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '12%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '6%',
    },
  },
});

class LandingPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Page className={classes.root}>
        <img src="/static/logo.svg" alt="Home" />
        
        <Typography
          variant="h4"
          style={{
            paddingTop: 41,
          }}
          className={classes.heading}>
          {LANDING_PAGE.HEADING}
        </Typography>
        <Typography className={classes.heading}>
          {LANDING_PAGE.SUBHEADING}
        </Typography>
        <div>
        <PrimaryButton
          onClick={() => this.props.onLogin()}
          style={{ paddingLeft: 48, paddingRight: 48 }}>
          {LANDING_PAGE.BTN_TEXT_LOGIN}
        </PrimaryButton>
        <SecondaryButton
          style={{ marginLeft: 20, paddingLeft: 42, paddingRight: 42 ,borderColor:"#036fcb" }}
          onClick={() => this.props.history.push('/signup')}>
          {LANDING_PAGE.BTN_TEXT_SIGNUP}
        </SecondaryButton>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles, { withTheme: true })(LandingPage);
