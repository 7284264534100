import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <rect width="32" height="32" rx="8" fill="#E4E4E4" />
      <path
        d="M20.9395 12.6875H19.3125V7.56738C19.3125 7.04102 18.834 6.5625 18.3076 6.5625H14.1924C13.666 6.5625 13.1875 7.04102 13.1875 7.56738V12.6875H11.5605C10.6514 12.6875 10.1729 13.7881 10.8428 14.4102L15.5322 19.0996C15.915 19.5303 16.585 19.5303 16.9678 19.0996L21.6572 14.4102C22.2793 13.7881 21.8486 12.6875 20.9395 12.6875ZM9.12012 22.8799C9.12012 23.4541 9.55078 23.9326 10.125 23.9326H22.375C22.9492 23.9326 23.3799 23.4541 23.3799 22.8799C23.3799 22.3535 22.9492 21.875 22.375 21.875H10.125C9.55078 21.875 9.12012 22.3535 9.12012 22.8799Z"
        fill="#4B4B4B"
      />
    </SvgIcon>
  );
};

export default Icon;
