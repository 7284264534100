import { Component, memo } from 'react';
import { DataGrid } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import Box from '@material-ui/core/Box';
import AvatarImg from '../AttributeTab/attributeDetails/AvatarImg';
import {
  convertStrToTitleCase,
  formatTimeInHHMMWithComma,
} from 'shared/utility';
import { statusColorMap } from 'shared/utility';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
}));

const RulesCreatedBy = memo(function CriteriaType({ data }) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ marginTop: '10px', marginRight: '5px' }}>
          <AvatarImg userName={`${data.createdBy || ''} `} />
        </div>
        <div style={{ marginBottom: '5px' }}>{data.createdBy || '-'}</div>
      </div>{' '}
    </>
  );
});

const AffiliateDetails = memo(function Affiliates({ data }) {
  return (
    <Tooltip title={data.affiliateNames || ''} arrow placement="bottom">
      {data.affiliateNames || ''}
    </Tooltip>
  );
});

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-evenly',
          marginTop: '12px',
        }}
      >
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
        >
          View details
        </PrimaryButton>
      </div>
    </>
  );
});

class RulesGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'Rule name',
        field: 'name',
        colId: 'NameID',
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'StatusRenderer',
      },
      { headerName: 'Type', field: 'type', cellRenderer: 'Type' },
      {
        headerName: 'Affiliate',
        field: 'affiliateNames',
        cellRenderer: 'Affiliate',
      },
      {
        headerName: 'Last run',
        field: 'lastRunDatetime',
        cellRenderer: 'LastrunValues',
      },

      // {
      //   headerName: 'Created on',
      //   field: 'createdAt',
      //   cellRenderer: 'DateFormatter',
      // },
      // {
      //   headerName: 'Created by',
      //   field: 'createdBy',
      //   cellRenderer: 'CreatedBy',
      // },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 150,
      sortable: false,
      resizable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      LastrunValues: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY -');
          let formattedTime = formatTimeInHHMMWithComma(rowData.value);
          return formattedDate + ' ' + formattedTime;
        } else {
          return '-';
        }
      },
      CreatedBy: rowData => (
        <RulesCreatedBy {...rowData} onClick={item => this.onItemView(item)} />
      ),
      Affiliate: rowData => {
        if (rowData.value) {
          return (
            <Tooltip title={rowData.value.join(', ')} arrow placement="bottom">
              <span>{rowData.value.join(', ')}</span>
            </Tooltip>
          );
        }
      },
      StatusRenderer: rowData => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(statusColorMap).includes(
              rowData.data.status || rowData.value
            ) ? (
              <>
                <span
                  style={{
                    backgroundColor:
                      statusColorMap[rowData.data.status || rowData.value],
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    padding: 4,
                  }}
                ></span>
                <span style={{ marginLeft: 8 }}>
                  {convertStrToTitleCase(rowData.data.status || rowData.value)}
                </span>
              </>
            ) : (
              <span>
                {convertStrToTitleCase(rowData.data.status || rowData.value)}
              </span>
            )}
          </Box>
        );
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDeleteClick={item => this.onDeleteButton(item)}
        />
      ),

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  onItemView(data) {
    this.props.onView(data);
  }

  onDeleteButton(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default RulesGrid;
