import {
  Avatar,
  Chip,
  Dialog,
  IconButton,
  makeStyles,
  Typography,Button
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { ErrorOutlineRounded } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    top: '25%',
    margin: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 10px',
    gap: '20px',
    borderRadius: '25px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
  chipContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chipStyle: {
    marginRight: '1%',
    marginTop: '1%',
    backgroundColor: '#E4E4E4',
    padding: '5px',
  },
  viewButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#000',
  },
}));

const generateColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0');
  return `#${randomColor}`;
};
const ConsentInfoModal = ({ open, handleClose, membersDetails,message,messageVariant,type,totalCount }) => {
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 15 },
      }}
      open={open}
      onClose={handleClose}
      className={classes.dialogBox}>
      <div className={classes.container}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {messageVariant === "success" ?
        
          <CheckCircleRoundedIcon
            style={{ color: 'green', width: '72px', height: '72px' }}
          />
        :
        <ErrorOutlineRounded style={{ color: 'red', width: '72px', height: '72px' }}/>
        }
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {message}
        </Typography>
        {messageVariant === "success" &&
        <>
        <div className={classes.chipContainer}>
          {Array.isArray(membersDetails) &&
            membersDetails.map((member, index) =>
              index < 3 ? (
                <Chip
                 key={index}
                  avatar={
                    <Avatar
                      style={{
                        background: `${generateColor()}`,
                        color: '#000',
                      }}>
                      {member.firstName?.charAt(0)}
                    </Avatar>
                  }
                  label={member.fullName}
                  variant="outlined"
                  className={classes.chipStyle}
                />
              ) : null
            )}
          {Array.isArray(membersDetails) && membersDetails.length > 3 && (
            <Chip
              label={`+${totalCount - 3}`}
              variant="outlined"
              className={classes.chipStyle}
            />
          )}
        </div>
            {type !== "bulk" &&
              <Button
                className={classes.viewButton}
                variant="contained"
                color="primary"
                onClick={handleClose}
                >
                View details
              </Button>
            }
        </>
}
      </div>
    </Dialog>
  );
};

export default ConsentInfoModal;
