import RedIcon from '../assets/status/Red.svg';
import PendingIcon from '../assets/status/pending.svg';
import CompletedIcon from '../assets/status/green.png';
import BlueIcon from '../assets/status/BlueIcon.svg';
import { Box } from '@material-ui/core';
export const STORAGE_KEYS = {
  AUTH_INFO: 'MPOWERED_AUTH_INFO',
  AUTH_TOKEN: 'MPOWERED_AUTH_TOKEN',
  RESOURCE_PERMISSIONS: 'RESOURCE_PERMISSIONS',
  USER_INFO: 'USER_INFO',
  USER_SYSTEM_SETTINGS: 'USER_SYSTEM_SETTINGS',
};

export const APP_ROLES = {
  MPH_ADMIN: 'MPH_ADMIN',
  INTERNAL_DEVELOPER: 'INTERNAL_DEVELOPER',
  EXTERNAL_DEVELOPER: 'EXTERNAL_DEVELOPER',
  AFFILIATE_COMPLIANCE_MANAGER: 'COMPLIANCE_MANAGER',
  DEVELOPER: 'DEVELOPER',
};

export const CLOSE_ALERT_DESCRIPTION =
  'Closing this tab will not save your changes, do you want to proceed?';

export const USER_ROLES = {
  // Super_Admin: 'Super Admin',
  // Admin: 'Admin',
  // IT_Support: 'IT Support',
  // Compliance: 'Compliance',
  // Executive: 'Executive',
  // Billing: 'Billing',
  // Internal_Developer: 'Internal Developer',
  // Third_Party_Developer: 'Third Party Developer',
  // Org_Owner: 'Org Owner',

  // AFFILIATE_COMPLIANCE_MANAGER: 'AFFILIATE_COMPLIANCE_MANAGER',
  // AFFILIATE_SUPPORT: 'AFFILIATE_SUPPORT',
  // AFFILIATE_USER: 'AFFILIATE_USER',
  // EXTERNAL_DEVELOPER: 'EXTERNAL_DEVELOPER',
  // INTERNAL_DEVELOPER: 'INTERNAL_DEVELOPER',
  // ORG_OWNER: 'ORG_OWNER',
  // AFFILIATE_ADMIN: 'AFFILIATE_ADMIN',
  // MPH_SUPPORT: 'MPH_SUPPORT',
  // MPH_ADMIN: 'MPH_ADMIN',

  Super_Admin: 'MPH_ADMIN',
  Admin: 'AFFILIATE_ADMIN',
  IT_Support: 'MPH_SUPPORT',
  Compliance: 'COMPLIANCE_MANAGER',
  Internal_Developer: 'INTERNAL_DEVELOPER',
  External_Developer: 'EXTERNAL_DEVELOPER',
  Third_Party_Developer: 'EXTERNAL_DEVELOPER',
  DEVELOPER: 'DEVELOPER',
  Org_Owner: 'ORG_OWNER',
  IT_Admin: 'IT_ADMIN',
  ORGANIZATION_ADMIN: 'Organization Admin',
  CustomerSupportRepresentative: 'Customer support representative',
  ContentManager: 'Content manager',
};

export const INVITE_USER_ROLES = [
  USER_ROLES.Compliance,
  USER_ROLES.Internal_Developer,
  // USER_ROLES.Third_Party_Developer,
  USER_ROLES.IT_Admin,
  USER_ROLES.ORGANIZATION_ADMIN,
  USER_ROLES.DEVELOPER,
  USER_ROLES.External_Developer,
];

export const DISPLAY_USER_ROLES = {
  [USER_ROLES.Super_Admin]: 'Super Admin',
  [USER_ROLES.Admin]: 'Admin',
  [USER_ROLES.IT_Support]: 'IT Support',
  [USER_ROLES.Compliance]: 'Compliance',
  [USER_ROLES.Internal_Developer]: 'Internal developer',
  [USER_ROLES.External_Developer]: 'External developer',
  [USER_ROLES.DEVELOPER]: 'DEVELOPER',
  // [USER_ROLES.Third_Party_Developer]: 'Third Party Developer',
  [USER_ROLES.Org_Owner]: 'Org Owner',
  [USER_ROLES.IT_Admin]: 'IT admin',
  [USER_ROLES.ORGANIZATION_ADMIN] : 'Organization Admin',
  [USER_ROLES.ContentManager]: 'Content manager',
  [USER_ROLES.CustomerSupportRepresentative]: 'Customer support representative',
};

export const LANDING_PAGE = Object.freeze({
  HEADING: "It's time to take control of your healthcare!",
  SUBHEADING: 'Please log in to continue to MPH portal',
  BTN_TEXT_LOGIN: 'Log in',
  BTN_TEXT_SIGNUP: 'Sign up',
});

export const RESOURCE_NAMES = {
  ORGANIZATIONS: 'ORGANIZATIONS',
  ORG_DETAILS: 'ORG_DETAILS',
  ORG_PARTNERS: 'ORG_PARTNERS',
  ORG_LOBS: 'ORG_LOBS',
  ORG_USERS: 'ORG_USERS',
  ORG_ALERTS: 'ORG_ALERTS',
  ORG_REPORTS: 'ORG_REPORTS',
  ORG_APPROVER: 'ORG_APPROVER',
  ADMIN_SETTINGS: 'ADMIN_SETTINGS',
  DEV_DASHBOARD: 'DEV_DASHBOARD',
  DEV_APPLICATIONS: 'DEV_APPLICATIONS',
  DEV_APPLICATION_CRUD: 'DEV_APPLICATION_CRUD',
  DEV_APPLICATION_APPROVER: 'DEV_APPLICATION_APPROVER',
  MEMBERS: 'MEMBERS',
  DEV_API_OVERVIEW: 'DEV_API_OVERVIEW',
  DEV_SANDBOX: 'DEV_SANDBOX',
  DEV_SETTINGS: 'DEV_SETTINGS',
  APPLICATION_CRUD: 'APPLICATION_CRUD',
  DEV_PRICELIST: 'DEV_PRICELIST',
  MEDICAL_PAGE: 'MEDICAL_PAGE',
  RULES: 'RULES',
  APPLICATION: 'APPLICATION',
  CONFIG: 'CONFIG',
  CMDE: 'CMDE',
};

export const PRICELIST_FILE_NAME = [
  {
    label: 'In-Network Rate',
    value: 'in-network-rate',
  },
  {
    label: 'Out of Network',
    value: 'out-of-network',
  },
  {
    label: 'Prescription Drug',
    value: 'prescription-drug',
  },
];

export const SANDBOX_PAGE = Object.freeze({
  DESCRIPTION:
    'This development Sandbox helps Application developers and vendors register one or multiple applications and provide information about the apps for system administrators and patients who use the apps. Third party apps  can also rack the status of their application registrations. Below are resources that mpowered health recommends for internal or third-party developers to test out their application developed with the Mpowered health APIs in a simulated  sandbox environment . Developers should also review the RESTful API documentation for FHIR here.',
});

export const PRICELIST_PAGE = Object.freeze({
  BUTTON_TEXT: 'View price list',
});

export const PRICELISTDETAILS_PAGE = Object.freeze({
  BREADCRUMBLINK: 'LOBs',
  TITLE: 'Price list details',
  BUTTON_TEXT: 'View price list',
});

export const RESOURCE_TYPES = Object.freeze({
  1: 'Coverage',
  2: 'InsurancePlan',
  3: 'Organization',
  4: 'Practitioner',
  5: 'Patient',
  6: 'Device',
  7: 'Encounter',
  8: 'AllergyIntolerance',
  9: 'CarePlan',
  10: 'CareTeam',
  11: 'Condition',
  12: 'Goal',
  13: 'Procedure',
  14: 'Observation',
  15: 'DiagnosticReport',
  16: 'Claim',
  17: 'ExplanationOfBenefit',
  18: 'Medication',
  19: 'MedicationRequest',
  20: 'MedicationKnowledge',
  21: 'Immunization',
  22: 'Consent',
  23: 'DocumentReference',
});

export const RESOURCES = [
  'Coverage',
  'InsurancePlan',
  'Organization',
  'Practitioner',
  'Patient',
  'Device',
  'Encounter',
  'AllergyIntolerance',
  'CarePlan',
  'CareTeam',
  'Condition',
  'Goal',
  'Procedure',
  'Observation',
  'DiagnosticReport',
  'Claim',
  'ExplanationOfBenefit',
  'Medication',
  'MedicationRequest',
  'MedicationKnowledge',
  'Immunization',
  'Consent',
  'DocumentReference',
];

export const FREQUENCIES = Object.freeze({
  1: 'hourly',
  2: 'daily',
  3: 'weekly',
  4: 'monthly',
  5: 'yearly',
  6: 'none',
});

export const FILE_TYPES = Object.freeze({
  1: 'csv',
  2: 'xml',
});

export const NO_ACCESS_CARD = {
  HEADING: `You don’t have access to the records`,
  CONTENT: `Initiate consent request and get the access`,
  BTN_TEXT: `Initiate consent request`,
};

export const BUTTON_TITLE = {
  EXPORT_RECORD: `Export records`,
  VIEW_RECORD: `View records`,
};

export const CONSENT_FILTER_DATA = [
  { key: 0, value: 'Pending', isChecked: false },
  { key: 1, value: 'Active', isChecked: false },
  { key: 2, value: 'Cancelled', isChecked: false },
  { key: 3, value: 'Not initiated', isChecked: false },
  { key: 4, value: 'Expired', isChecked: false },
  { key: 5, value: 'Terminated', isChecked: false },
];

export const EXPIRE_ON_FILTER_DATA = [
  { key: 0, value: 'Next week', isChecked: false },
  { key: 1, value: 'Next 1 month', isChecked: false },
  { key: 2, value: 'Next 3 month', isChecked: false },
];

export const FILTER_LIST = [
  { key: 0, value: 'Consent status' },
  { key: 1, value: 'Affiliate' },
  { key: 2, value: 'LOB' },
  { key: 3, value: 'Expires on' },
];

export const BUTTON_TITLES = {
  CANCEL: `Cancel`,
  APPLY_FILTERS: `Apply filters`,
  REMOVE: 'Remove',
  CREATE_ROLE: 'Create a new role',
  PROCEED: 'Proceed',
};

export const statusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Active', value: 'Active' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Not initiated', value: 'Notinitiated' },
  { label: 'Expired', value: 'Expired' },
  { label: 'Terminated', value: 'Terminated' },
];

export const CampaignStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'DRAFT', icon: PendingIcon },
  { label: 'Executed', value: 'Executed', icon: CompletedIcon },
  { label: 'Abort', value: 'Abort', icon: RedIcon },
  { label: 'In progress', value: 'In_progress', icon: PendingIcon },
  { label: 'Failed', value: 'Failed', icon: RedIcon },
  { label: 'Published', value: 'PUBLISHED', icon: CompletedIcon },
  {
    label: 'Scheduled',
    value: 'SCHEDULED',
    icon: BlueIcon,
  },
];

export const UsersStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'DRAFT', icon: PendingIcon },
  { label: 'Revoked', value: 'revoked', icon: RedIcon },
  { label: 'Active', value: 'active', icon: CompletedIcon },
  { label: 'Approved', value: 'approved', icon: CompletedIcon },
];

export const DeveloperStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending', icon: PendingIcon },
  { label: 'Revoked', value: 'revoked', icon: RedIcon },
  { label: 'Active', value: 'active', icon: CompletedIcon },
  { label: 'Approved', value: 'approved', icon: CompletedIcon },
  { label: 'Declined', value: 'declined', icon: RedIcon },
  { label: 'Expired', value: 'expired', icon: RedIcon },
];

export const StatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending', icon: PendingIcon },
  { label: 'Revoked', value: 'revoked', icon: RedIcon },
  { label: 'Active', value: 'active', icon: CompletedIcon },
  { label: 'Approved', value: 'approved', icon: CompletedIcon },
  { label: 'Declined', value: 'declined', icon: RedIcon },
  { label: 'Expired', value: 'expired', icon: RedIcon },
  {
    label: 'Email verification failed',
    value: 'Email_Verification_Failed',
    icon: RedIcon,
  },
];

export const CMDEStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending', icon: PendingIcon },
  { label: 'Enabled', value: 'active', icon: CompletedIcon },
  { label: 'Disabled', value: 'disabled', icon: RedIcon },
];

export const RulesStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'Draft', icon: PendingIcon },
  { label: 'Executed', value: 'Executed', icon: CompletedIcon },
  // { label: 'Abort', value: 'Abort', icon: RedIcon },
  { label: 'In progress', value: 'In_progress', icon: PendingIcon },
  { label: 'Execution failed', value: 'EXECUTION_FAILED', icon: RedIcon },
  { label: 'Published', value: 'PUBLISHED', icon: CompletedIcon },
  {
    label: 'Scheduled',
    value: 'SCHEDULED',
    icon: BlueIcon,
  },
  {
    label: 'Query building failed',
    value: 'QUERY_BUILDING_FAILED',
    icon: RedIcon,
  },
  {
    label: 'Result merge failed',
    value: 'RESULT_MERGE_FAILED',
    icon: RedIcon,
  },
];

export const ApplicationFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Approved', value: 'Approved', icon: CompletedIcon },
  { label: 'Pending', value: 'Pending', icon: PendingIcon },
  { label: 'Declined', value: 'Denied', icon: RedIcon },
  { label: 'Revoked', value: 'Revoked', icon: RedIcon },
];

export const TypeofAttributeFilterOptions = [
  { label: 'All', value: 'all' },
  { label: 'FHIR', value: 'fhir' },
  { label: 'INTERNAL', value: 'internal' },
];

const deniedRevokedOptions = option => {
  return (
    <Box display="flex" alignItems="center">
      <span
        style={{
          backgroundColor: '#DB0032',
          width: 8,
          height: 8,
          borderRadius: 4,
          padding: 2,
        }}
      ></span>
      <span style={{ marginLeft: 5 }}>{option}</span>
    </Box>
  );
};
export const APPLICATION_STATUS = [
  { label: 'All', value: 'Denied,Revoked' },
  { label: deniedRevokedOptions('Denied'), value: 'Denied' },
  { label: deniedRevokedOptions('Revoked'), value: 'Revoked' },
];

export const CONFIRMATION_MODAL = {
  BUTTON_NO: 'No',
  BUTTON_YES: 'Yes',
  HEADING: 'Are you sure you want to exit?',
};

export const LABELS_FIELDS_BUTTONS = {
  SELECT_AFFILIATE: 'Select affiliate',
  SEARCH_AFFILIATE: 'Search affiliate',
  ADD_PRICE_LIST: 'Add price list',
  LOB_NAME: 'Lob name',
  PLAN_TYPE: 'Plan type',
  SAVE_CHANGES: 'Save changes',
  UPLOAD_FILE: 'Upload file',
  MEMBERS_TIED: 'Members tied',
  LAST_UPDATED: 'Last updated',
  BUSINESS_CONTACT: 'Business contact',
  EIN_NUMBER: 'EIN',
  CREATED_ON: 'Created on',
  ORGANIZATION: 'Organization type',
};

export const ORG_TYPE = [
  { code: 'prov', name: 'Healthcare Provider' },
  { code: 'dept', name: 'Hospital Department' },
  { code: 'team', name: 'Organizational team' },
  { code: 'govt', name: 'Government' },
  { code: 'ins', name: 'Insurance Company' },
  { code: 'pay', name: 'Payer' },
  { code: 'edu', name: 'Educational Institute' },
  { code: 'reli', name: 'Religious Institution' },
  { code: 'crs', name: 'Clinical Research Sponsor' },
  { code: 'cg', name: 'Community Group' },
  { code: 'bus', name: 'Non-Healthcare Business or Corporation' },
  { code: 'other', name: 'Other' },
];

export const CRITERIA_OPERATOR_OPTIONS = [
  {
    id: 'equal',
    label: 'Equal',
    value: 'eq',
  },
  {
    id: 'not_equal_to',
    label: 'Not equal to',
    value: 'ne',
  },
  {
    id: 'less_than',
    label: 'Less than',
    value: 'lt',
  },
  {
    id: 'greater_than',
    label: 'Greater than',
    value: 'gt',
  },
  {
    id: 'less_than_or_equal_to',
    label: 'Less than or equal to',
    value: 'lte',
  },
  {
    id: 'greater_than_or_equal_to',
    label: 'Greater than or equal to',
    value: 'gte',
  },
  {
    id: 'contains',
    label: 'Contains',
    value: 'contains',
  },
];

export const EMPTY_STATE_PAGE = Object.freeze({
  COHORT_TITLE: 'Cohort not found',
  COHORT_CONTENT:
    'We did not find the cohort matching your search, try searching with a different name',
  CAMPAIGN_TITLE: 'Campaign not found',
  CAMPAIGN_CONTENT: 'We did not find the campaign matching your search',
  ATTRIBUTE_TITLE: 'Attribute not found',
  ATTRIBUTE_CONTENT:
    'We did not find the attribute matching your search, try searching with a different name',
  CRITERIA_TITLE: 'Criterion not found',
  CRITERIA_CONTENT:
    'We did not find a criterion matching your search, try searching with a different filter',
  ACTION_TITLE: 'Action not found',
  ACTION_CONTENT:
    'We did not find an action matching your search, try searching with a different name',
  RULE_TITLE: 'Rule not found',
  RULE_CONTENT:
    'We did not find the rule matching your search, try searching with a different filter',
});

export const INITIAL_RENDER_USERS = Object.freeze({
  TITLE: 'No user invited',
  TITLE_CONTENT: 'Invite users',
  CONTENT:
    'No users have been invited. Click on the button below to start inviting users',
});

export const USERS_EMPTY_STATE_PAGE = Object.freeze({
  TITLE: 'User not found',
  CONTENT:
    'We did not find users matching your search filter. Search by different name',
});

export const INITIAL_RENDER_THIRD_PARTY_USER = Object.freeze({
  DEV_TITLE: 'No third party developer found',
  DEV_TITLE_CONTENT: 'Third party developer',
  CONTENT: 'No one has registered as third party developer.',
});

export const INITIAL_RENDER_ROLE = Object.freeze({
  TITLE: 'Role not found',
  TITLE_CONTENT: 'Role not found',
  CONTENT:
    'The role you are trying to search can not be found. Please look for another role',
});

export const INITIAL_RENDER_APPLICATION = Object.freeze({
  DEV_TITLE: 'No applications available',
  DEV_TITLE_CONTENT: 'Create application',
  CONTENT: 'Please click on the button below to create application',
});

export const THIRD_PARTY_USER_EMPTY_STATE_PAGE = Object.freeze({
  DEV_TITLE: 'Third party developer not found',
  CONTENT:
    'We did not find a third party developer matching your search filter. Search by different name',
});

export const APPLICATION_EMPTY_STATE_PAGE = Object.freeze({
  DEV_TITLE: 'Application not found',
  CONTENT:
    'We did not find a application matching your search filter. Search by different name',
});

export const THIRD_PARTY_DEV = Object.freeze({
  PAGE_HEADER_TITLE: 'Third party developer',
  SEARCH_BTN_TEXT: 'Search',
  SEARCH_LABEL: 'Search by developer name',
});

export const INITIAL_RENDER_NO_DATA_PAGE = Object.freeze({
  ATTRIBUTE_TITLE: 'No attributes available',
  SUPER_ADMIN_ATTRIBUTE_CONTENT:
    'Please click on the button below to create attribute',
  IT_ADMIN_ATTRIBUTE_CONTENT:
    'Contact Mpowered health team for creating attributes',
  CRITERIA_TITLE: 'No criterion added',
  CRITERIA_TITLE_CONTENT: 'Create criterion',
  CRITERIA_CONTENT: 'Please click on the button below to create criterion',
  ACTION_TITLE: 'No action added',
  ACTION_TITLE_CONTENT: 'Create action',
  ACTION_CONTENT: 'Please click on the button below to create action',
  RULE_TITLE: 'No rule added',
  RULE_TITLE_CONTENT: 'Create a rule',
  RULE_CONTENT: 'Please click on the button below to create rule',
  CAMPAIGN_TITLE: 'No campaign added',
  CAMPAIGN_TITLE_CONTENT: 'Create campaign',
  CAMPAIGN_CONTENT: 'Please click on the button below to create campaign',
  COHORT_TITLE: 'No cohort added',
  COHORT_TITLE_CONTENT: 'Create cohort',
  COHORT_CONTENT: 'Please click on the button below to create cohort',
});

export const REUSABLE_MODAL = Object.freeze({
  DIALOG_TITLE: 'Approve request',
  DIALOG_CONTEXT:
    'Are you sure you would like to approve the request to access?',
  PRIMARY_BTN: 'Cancel',
  SEC_BTN: 'Approve',
});

export const REUSABLE_MODAL1 = Object.freeze({
  DIALOG_TITLE: 'Revoke request',
  DIALOG_CONTEXT: 'Are you sure you want to revoke the access?',
  CANCEL: 'Cancel',
  REVOKE: 'Revoke',
});

export const DEVELOPER_SIGNUP_CONSTANTS = Object.freeze({
  LANDING_TITLE: `It’s time to take control of your healthcare!`,
  LANDING_SUBTITLE: `Please login to continue to Mpowered Health portal`,
  SCAN_DEMO_LANDING_SUBTITLE: 'Please login to continue to Scan Health portal',
  APPROVAL_PENDING_TITLE: `Request pending from admin`,
  EXPIRED_TITLE: `Your request has expired`,
  REVOKED_TITLE: 'Access revoked',
  DECLINED_TITLE: 'Access declined',
  EXPIRED_SUBTITLE: `Please contact your admin`,
  APPROVAL_PENDING_SUBTITLE: `We have registered your request. You will receive an email with a link to update your account if it gets approved by the admin`,
  REVOKED_SUTITLE: `Sorry, you can not login since your access has been revoked by the admin.`,
  DECLINED_SUTITLE: `Sorry, you can not login since your access has been declined by the admin.`,
  STEP1_TITLE: 'Developer signup',
  STEP2_TITLE: 'Create an application',
  STEP3_TITLE: 'Sandbox key is generated',
  STEP4_TITLE: 'Production key will be shared',
  STEP1_SUBTITLE:
    'Signup by filling information about your organisation and yourself',
  STEP2_SUBTITLE: 'Developer creates an application in sandbox environment',
  STEP3_SUBTITLE:
    'Access the client ID once the sandbox request is approved by admin',
  STEP4_SUBTITLE:
    'Access the client ID once the production request is approved by admin',
  LEFT_PANE_TITLE: 'How does this work?',
  SESSION_EXPIRED_TITLE: 'The link you clicked has expired.',
  SESSION_EXPIRED_CONTENT:
    'Please contact admin and request that they resend you the event invitation email.',
  ERROR_MESSAGE: 'Something went wrong',
  PASSWORD_FIELD: 'Password',
  CONFIRM_PASSWORD_FIELD: 'Confirm password',
  CONFIRM_EMAIL:
    'Please check your email for confirmation. Click link in email to verify your account',
});

export const REQUEST_MODAL = Object.freeze({
  DECLINE_DIALOG_TITLE: 'Decline request',
  REVOKE_DIALOG_TITLE: 'Revoke request',
  DECLINE_DIALOG_CONTEXT:
    'Are you sure you would like to decline the request to access?',
  REVOKE_DIALOG_CONTEXT: 'Are you sure you would like to revoke access?',
  APPLICATION_REVOKE_CONTEXT:
    'This will revoke both sandbox and production key access. Are you sure you would like to proceed?',
  SANDBOX_APPLICATION_REVOKE_CONTEXT:
    'This will revoke sandbox. Are you sure you would like to proceed?',
  PRIMARY_BTN: 'No',
  SEC_BTN: 'Yes',
  PLACEHOLDER_DECLINE: 'Enter the reason for declining',
  PLACEHOLDER_REVOKE: 'Enter the reason for revoking',
});

export const COLOR_LIST_FOR_STATUS = Object.freeze({
  DRAFT: '#FB8900',
  Draft: '#FB8900',
  Executed: '#288719',
  Published: '#288719',
  PUBLISHED: '#288719',
  Cancelled: '#DB0032',
  Expired: '#DB0032',
  Terminated: `#DB0032`,
  Inprogress: '#FB8900',
  In_progress: '#FB8900',
  IN_PROGRESS: '#FB8900',
  Failed: '#DB0032',
  FAILED: '#DB0032',
  Aborted: 'DB0032',
  completed: '#288719',
  COMPLETED: '#288719',
  EXECUTED: '#288719',
  SCHEDULED: '#036FCB',
  Pending: '#FB8900',
  PENDING: '#FB8900',
  pending: '#FB8900',
  approved: '#288719',
  declined: '#DB0032',
  EXECUTION_FAILED: '#DB0032',
  execution_failed: '#DB0032',
});

export const VIEW_APPLICATION_CONTENT = {
  productionHeader:
    'Raise access for production key once sandbox request has been approved by the admin.',
  sandboxHeader:
    'Sandbox key access has been declined by the admin. Raise a request access again.',
};

export const DEV = {
  PAGE_HEADER: 'Third party developer details',
  REQUEST_ON: 'Requested on',
  ORG_NAME: 'Organization',
  EMAIL: 'Email id:',
  LOB: 'LOB',
  AFFILIATE: 'Affiliate',
};

export const ROLES = {
  NEW_ROLE_TITLE: 'Add new role',
  NEW_ROLE_SUBTITLE: 'Please enter the name of the role you want to create',
  REMOVE_ROLE_TITLE: 'Remove role',
  REMOVE_ROLE_SUBTITLE: 'Are you sure you would like to remove this role?',
};

export const PERMISSION_CODES = {
  READ: 'P004',
  CREATE: 'P001',
  DELETE: 'P002',
  APPROVE: 'P003',
};
