import StatusIcon from '@mui/icons-material/FiberManualRecord';
// import { TableContainer } from '@mui/material';
import { Paper } from '@material-ui/core';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  capitalizeFirstLetter,
  getMultipleObjFromSource,
  getValueNameWithCodeExtension,
  getNameWithCodeField,
  getValueFromSourceList,
  getValueFromOptions,
  getValueFromTransformation,
  getFileNameAndExtension,
  convertStrToTitleCase,
  statusColorFun,
} from '../helper';
import Button from '../inputs/index';
import objectPath from 'object-path';
import {
  DEFAULT_NULL_REPLACEMENT,
  DUMMY_DATE_STUB,
  SNACKBAR_TYPE,
} from '../constants';
import { useLocation } from 'react-router-dom';
import SnackbarToast from '../SnackbarToast';
import colors from '../colors';
import { Table } from 'reactstrap';
import { makeStyles } from '@material-ui/styles';
import DemoApis from 'services/api/DemoApis';
import TrashIcon from '../../../assets/trash.svg';
import DocDownloadIcon from '../../../assets/doc-download.svg';

const PaperWithoutShadow = styled(Paper)({
  boxShadow: 'unset',
});

const useStyles = makeStyles(theme => ({
  head: {
    paddingLeft: 16,
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    opacity: 1,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    height: 48,
    fontFamily:'Inter',
    //minWidth: 100,
    borderBottom: '1px solid black',
  },
  body: {
    paddingLeft: 15,
    paddingRight: 16,
    fontWeight: 'normal',
    fontFamily:'Inter',
    fontSize: 14,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    height: 48,
    //minWidth: 100,
    // overflow: 'hidden',
    maxWidth: 300,
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 150,
    //   paddingRight: 0,
    // },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderBottom: ' 1px solid #E4E4E4',
  },
  iconBackground: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    backgroundColor: '#E4E4E4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  downloadMargin: {
    marginRight: 16,
  },
  downloadLeft: {
    marginLeft: 18,
  },
  iconBackground: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    backgroundColor: '#E4E4E4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  downloadMargin: {
    marginRight: 16,
  },
  downloadLeft: {
    marginLeft: 18,
  },
}));

const TableGrid = forwardRef(
  (
    {
      tableHeading,
      onPayClick,
      selfReportingTemplate,
      data,
      viewDetailsHandler,
      handleViewDetailsInAssessment,
      useResponseGrid = false,
      disableButton = {},
      paginationObj: {
        handlepageNumber,
        loading = false,
        detailsLoading = false,
        hasInfiniteScroll = false,
        infiniteScrollMargin = '100px',
      } = {},
      viewRecordsHandler,
      viewBtnTitle = '',
      onRefresh,
      reloadOnScroll,
      nextPageId,
    },
    ref
  ) => {
    const classes = useStyles();
    const [tableBodyData, setTableData] = useState(data);
    const observor = useRef();
    const location = useLocation();
    const showViewDetailsHandler = location.pathname?.includes('individual');
    const [snackbar, setSnackbar] = useState({ message: null, type: null });
    useEffect(() => {
      setTableData(data);
      if (!hasInfiniteScroll && observor && reloadOnScroll) {
        observor.current?.disconnect();
      }
    }, [data]);

    useEffect(() => {
      if (!hasInfiniteScroll && observor && reloadOnScroll) {
        observor.current?.disconnect();
      }
    }, [hasInfiniteScroll]);

    const lastElementObserver = useCallback(element => {
      if (loading || !element || !reloadOnScroll) {
        return;
      }
      if (observor.current) {
        observor.current.disconnect();
      }
      observor.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            if (hasInfiniteScroll) {
              handlepageNumber();
            }
          }
        },
        {
          rootMargin: infiniteScrollMargin,
        }
      );
      if (element) {
        observor.current.observe(element);
      }
    });

    const displayStatus = status => {
      const color = statusColorFun(status);
      return (
        <div style={{ display: 'flex' }}>
          {color && (
            <span
              style={{ marginRight: 4, display: 'flex', alignItems: 'center' }}
            >
              <StatusIcon htmlColor={color} sx={{ width: 12, height: 12 }} />
            </span>
          )}
          {capitalizeFirstLetter(status) ?? DEFAULT_NULL_REPLACEMENT}
        </div>
      );
    };

    const renderStatusView = (bodyObject, headerObj) => {
      const { filterVals } = headerObj;
      const statusObj = getMultipleObjFromSource(bodyObject, headerObj);
      return (
        <div style={{ display: 'flex' }}>
          {statusObj[filterVals?.[0]] && statusObj[filterVals?.[1]] && (
            <span
              style={{ marginRight: 4, display: 'flex', alignItems: 'center' }}
            >
              <StatusIcon
                htmlColor={statusObj?.[filterVals[0]]}
                sx={{ width: 12, height: 12 }}
              />
            </span>
          )}
          {statusObj[filterVals?.[1]]
            ? capitalizeFirstLetter(statusObj[filterVals?.[1]])
            : '-'}
        </div>
      );
    };

    const renderStatusViewFromDiffLocation = (bodyObject, headerObj) => {
      const statusColor = getValueFromSourceList(bodyObject, headerObj);
      const status = objectPath.get(bodyObject, headerObj?.dataCol);
      return (
        <div style={{ display: 'flex' }}>
          {statusColor && (
            <span
              style={{ marginRight: 4, display: 'flex', alignItems: 'center' }}
            >
              <StatusIcon
                htmlColor={statusColor}
                sx={{ width: 12, height: 12 }}
              />
            </span>
          )}
          {status ? capitalizeFirstLetter(status) : '-'}
        </div>
      );
    };

    const renderDataSourceColumn = (obj, headObj) => {
      let showToolTip = false;
      const dataSource = getValueFromSourceList(obj, headObj);
      if (dataSource >= 15) {
        showToolTip = true;
      }
      const toolTipHtml = partnerName => {
        return (
          <span
            style={{
              background: '#1B1B1B',
              color: '#FFFFFF',
              boxSizing: 'border-box',
              borderRadius: '4px',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: 'center',
              padding: '0px 8px 6px 8px',
              minWidth: 98,
              display: 'block',
              position: 'relative',
              top: 2,
            }}
          >
            {partnerName}
          </span>
        );
      };
      return showToolTip ? (
        <Tooltip
          arrow
          title={toolTipHtml(dataSource)}
          sx={{ 
            padding: 0,
            background: '#1B1B1B',
            minWidth: 120,
            position: 'relative',
            bottom: 5,
            height: 20,
          }}
        >
          <span
            style={{
              background: '#E4E4E4',
              border: '1px solid #C0C0C0',
              boxSizing: 'border-box',
              borderRadius: 40,
              color: '#1F1F1F',
              fontWeight: 500,
              fontSize: '11px',
              lineHeight: '16px',
              textAlign: 'center',
              width: 108,
              padding: '1px 8px',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              position: 'relative',
              top: 3,
            }}
          >
            {dataSource}
          </span>
        </Tooltip>
      ) : (
        <span
          style={{
            background: '#E4E4E4',
            border: '1px solid #C0C0C0',
            boxSizing: 'border-box',
            borderRadius: 40,
            color: '#1F1F1F',
            fontWeight: 500,
            fontSize: '11px',
            lineHeight: '16px',
            textAlign: 'center',
            width: 108,
            padding: '1px 8px',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
            top: 3,
          }}
        >
          {dataSource}
        </span>
      );
    };

    const renderNameColumn = (val, isTitleCase) => {
      let showToolTip = false;
      if (val === DUMMY_DATE_STUB) {
        return '-';
      }
      if (val && val.length > 50) {
        showToolTip = true;
      }
      if (isTitleCase) {
        val = convertStrToTitleCase(val);
      }
      const toolTipHtml = partnerName => {
        return (
          <span
            style={{
              background: '#1B1B1B',
              color: '#FFFFFF',
              boxSizing: 'border-box',
              borderRadius: '4px',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: 'center',
              padding: '0px 8px 6px 8px',
              minWidth: 98,
              display: 'block',
              position: 'relative',
              top: 2,
            }}
          >
            {partnerName}
          </span>
        );
      };

      return showToolTip ? (
        <Tooltip
          arrow
          title={toolTipHtml(val)}
          sx={{ 
            padding: 0,
            background: '#1B1B1B',
            minWidth: 120,
            position: 'relative',
            bottom: 5,
            height: 20,
          }}
        >
          <span>{val}</span>
        </Tooltip>
      ) : (
        <span>{val ?? '-'}</span>
      );
    };

    const getTransformedValue = (bodyObj, headerObj) => {
      const res = getValueFromOptions(
        bodyObj,
        { ...headerObj, key: headerObj.dataCol, label: headerObj.headerName },
        selfReportingTemplate
      );
      return res;
    };

    const handleDocumentDelete = async (document) => {
      const documentReferenceId = document?.id;
      try {
        setSnackbar({ message: 'Deleting Document...', type: SNACKBAR_TYPE.INFO });
        const res = await DemoApis.deleteDocument(documentReferenceId);
        onRefresh();
        setSnackbar({ message: 'Deleted document', type: SNACKBAR_TYPE.SUCCESS });
      } catch (err) {
        console.log(err);
        setSnackbar({
          message: 'Something went wrong while downloading the document',
          type: SNACKBAR_TYPE.ERROR,
        });
      }
    };

    const handleDocumentDownload = async ({ document }) => {
      const docArn = document?.documentUrl;

      setSnackbar({ message: 'Downloading document...', type: SNACKBAR_TYPE.INFO });
      try {
        const res = await DemoApis.downloadDocument(
          docArn,
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        openDocument({ url: url, binaryData: res.data, docArn: docArn });
        setSnackbar({ message: 'Downloaded document successfully', type: SNACKBAR_TYPE.SUCCESS });
      } catch (err) {
        setSnackbar({
          message: 'Something went wrong while downloading the document',
          type: SNACKBAR_TYPE.ERROR,
        });
      }
    };

    const openDocument = async (documentObj) => {
      const fileInfo = getFileNameAndExtension(documentObj.docArn);
      const link = document.createElement('a');
      link.href = documentObj.url;
      link.setAttribute('download', `${fileInfo.fileNameWithoutExtension}.${fileInfo.extension}`);
      document.body.appendChild(link);
      link.click();
    };


    return (
      <React.Fragment>
        <SnackbarToast
          open={!!snackbar.message}
          message={snackbar.message ?? ''}
          duration={4000}
          onClose={() => setSnackbar({ ...snackbar, message: null })}
          type={snackbar.type}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        />
        <Paper
          ref={ref}
          id="scrollableDiv"
          component={PaperWithoutShadow}
          sx={{
            height: 'max-content',
            overflow: 'auto',
            backgroundColor: '#F5F5F5',
            borderRadius: 4,
            width: "100%",
            // minWidth: "1100px",
          }}
        >
          <Table
            style={{
              // borderCollapse: 'inherit',
              backgroundColor: '#ffffff',
              width: "100%",
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
              padding: "0px 8px 0px 8px",
              scrollBehaviour: 'smooth',
              marginBottom: 0,
              '&:last-child': {
                border: ' 1px solid #E4E4E4',
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
              },
            }}
            aria-label="simple table"
          >
            <thead>
              <tr style={{ height: 48 }}>
                {tableHeading?.map((headObject, i) => {
                  if (
                    headObject?.headerName === 'Data source' &&
                    headObject?.hide
                  ) {
                    return null;
                  }
                  return (
                    <td className={classes.head} key={i}>
                      {headObject.headerName}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableBodyData?.map((bodyObject, idx) => {
                let isLastElement = nextPageId ? true : false;
                // if (idx === tableBodyData?.length - 1) {
                //   isLastElement = true;
                // }
                return (
                  <tr
                    key={Math.random()}
                    style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    // style={{ height: 48 }}
                    ref={isLastElement ? lastElementObserver : null}
                  >
                    {tableHeading?.map((headerObj, index) => {
                      if (headerObj.type === 'downloadButton') {
                        return (
                          <td className={classes.body} key={index}>
                            <div style={{ display: 'flex',flexDirection: 'row',}}>
                              <a
                                className={`${classes.iconBackground} ${classes.downloadMargin}`}
                                onClick={() => handleDocumentDownload(bodyObject?.documentResponse)}
                              >
                                <img style={{height: '16px',width: '24px',}} src={DocDownloadIcon} />
                              </a>

                              <a
                                className={classes.iconBackground}
                                onClick={() => handleDocumentDelete(bodyObject)}
                              >
                                <img style={{height: '16px',width: '24px',}} src={TrashIcon} />
                              </a>
                            </div>
                          </td>
                        );
                      } else if (headerObj.type === 'download') {
                        const downloadObj = bodyObject?.extension?.find(
                          (item) => item.url === 'download',
                        );
                        return (
                          <td className={classes.body} key={index}>
                            <div  style={{ display: 'flex',flexDirection: 'row',}}>
                              <a
                                className={`${classes.iconBackground} ${classes.downloadLeft}`}
                                onClick={() => handleDocumentDownload(downloadObj)}
                              >
                                <img style={{height: '16px',width: '24px',}} src={DocDownloadIcon} />
                              </a>
                            </div>
                          </td>
                        );
                      } else if (headerObj.type === 'empty') {
                        return <td className={classes.body} key={index}></td>;
                      } else if (headerObj.type === 'button') {
                        let assessmentButtonTitle = '';
                        let isButtonDisabled = false;
                        if (Object.keys(disableButton)?.length > 0) {
                          let statusValue = getMultipleObjFromSource(
                            bodyObject,
                            disableButton
                          );
                          if (typeof statusValue === 'object') {
                            statusValue = statusValue?.status;
                          }
                          statusValue = statusValue?.toLowerCase();
                          assessmentButtonTitle =
                            statusValue && statusValue !== '-'
                              ? disableButton?.comparatorValues?.includes(
                                  statusValue
                                )
                                ? disableButton?.buttonTitleView
                                : disableButton?.buttonTitleFillNow
                              : '-';
                          isButtonDisabled =
                            assessmentButtonTitle ===
                              disableButton?.buttonTitleView &&
                            statusValue !== 'completed'
                              ? true
                              : false;
                        }

                        return (
                          <td className={classes.body}  key={index}>
                            <Button
                              sx={{
                                height: 24,
                                textTransform: 'none',
                                padding: '2px 8px 0px',
                                borderRadius: 20,
                                minWidth: 90,
                                backgroundColor: colors.BLUE,
                                color: colors.WHITE,
                                '&:hover': {
                                  backgroundColor: colors.BLUE_HOVER,
                                },
                              }}
                              onClick={() =>
                                viewDetailsHandler
                                  ? viewDetailsHandler(bodyObject['id'])
                                  : null
                              }
                              title={viewBtnTitle || 'View details'}
                            />
                          </td>
                        );
                      }
                      if (headerObj.type === 'pillString') {
                        if (
                          headerObj?.headerName === 'Data source' &&
                          headerObj?.hide
                        ) {
                          return null;
                        }
                        return (
                          <td className={classes.body}  key={index}>
                            {renderDataSourceColumn(bodyObject, headerObj) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj.type === 'filter') {
                        return (
                          <td className={classes.body}  key={index}>
                            {getValueFromSourceList(bodyObject, headerObj) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj.type === 'needTransformation') {
                        return (
                          <td className={classes.body} key={index}>
                            {getValueFromTransformation(
                              bodyObject,
                              headerObj
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj?.type === 'dotValue' && headerObj.dataCol) {
                        return (
                          <td className={classes.body}  key={index}>
                            {renderStatusViewFromDiffLocation(
                              bodyObject,
                              headerObj
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj?.type === 'dotValue') {
                        return (
                          <td className={classes.body}  key={index}>
                            {renderStatusView(bodyObject, headerObj) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj?.type === 'status') {
                        return (
                          <td className={classes.body}  key={index}>
                            {displayStatus(bodyObject?.status) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj?.type === 'nameWithCode') {
                        return (
                          <td className={classes.body}  key={index}>
                            {renderNameColumn(
                              getNameWithCodeField(bodyObject, headerObj)
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj?.type === 'requireTransformation') {
                        return (
                          <td className={classes.body}  key={index}>
                            {renderNameColumn(
                              getTransformedValue(bodyObject, headerObj)
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj?.type === 'nameWithCodeExtension') {
                        return (
                          <td className={classes.body}  key={index}>
                            {renderNameColumn(
                              getValueNameWithCodeExtension(
                                bodyObject,
                                headerObj
                              )
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                      if (headerObj.type === 'string') {
                        objectPath.ensureExists(
                          bodyObject,
                          headerObj?.dataCol,
                          DEFAULT_NULL_REPLACEMENT
                        );
                        return (
                          <td className={classes.body}  key={index}>
                            {renderNameColumn(
                              String(
                                objectPath.get(
                                  bodyObject,
                                  headerObj?.dataCol
                                ) || DEFAULT_NULL_REPLACEMENT
                              ),
                              headerObj?.isTitleCase
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {loading && !detailsLoading && (
            <h5
              style={{
                textAlign: 'center',
                background: 'white',
                width: '100%',
                margin: 0,
                padding: '12px 0px',
                lineHeight: '24px',
                borderTop: ' 1px solid #E4E4E4',
                borderBottom: ' 1px solid #E4E4E4',
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
                fontWeight: 600,
                fontFamily: 'Inter',
              }}
            >
              Loading...
            </h5>
          )}
          {!hasInfiniteScroll && (
            <h5
              style={{
                textAlign: 'center',
                background: 'white',
                width: '100%',
                margin: 0,
                padding: '12px 0px',
                lineHeight: '24px',
                borderTop: ' 1px solid #E4E4E4',
                borderBottom: ' 1px solid #E4E4E4',
                fontFamily: 'Inter',
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
                fontWeight: 400,
                fontSize: "14px"
              }}
            >
              Yah! you have seen it all
            </h5>
          )}
        </Paper>
      </React.Fragment>
    );
  }
);
export default TableGrid;
