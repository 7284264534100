import * as React from 'react';
import { Box, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';
import Button from '../inputs/Button';
import { POPUP_MODAL_SUCCESS_ADD_DATA } from '../helper';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    top: '50%',
    marginLeft: '-280px',
    marginTop: '-190px',
    right: 0,
    boxSizing: 'border-box',
    borderRadius: '24px',
    backgroundColor: 'white',
    padding: '28px 24px 24px 24px',
    outline: 'none',
    padding: '38px 24px',
    borderRadius: 16,
    maxWidth: 560,
    height: 376,
  },

  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 500,
    width: 24,
    height: 18,
    cursor: 'pointer',
  },
  iconContainer: {
    height: 56,
    marginTop: 12,
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {},
  heading: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 600,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paraContainer: {
    height: 40,
    marginTop: 16,
  },
  paraText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    margin: '0 auto',
    display: 'block',
    width: 440,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    backgroundColor: '#036FCB',
    borderRadius: 40,
    marginTop: 40,
    height: 48,
    width: 160,
    color: '#F2F2F2',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  popUpRootContainer: {
    padding: '38px 24px',
    borderRadius: 16,
    width: '100%',
    maxWidth: 580,
  },
  popUpIconContainer: {
    margin: 0,
  },
  popUpHeading: {
    fontSize: 20,
    marginTop: 24,
  },
  popUpParagraphContainer: {
    marginTop: 24,
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  popUpParagraphText: {
    fontSize: 16,
    color: '#4b4b4b',
    lineHeight: 1.5,
    width: 440,
  },
  popUpBtn: {
    width: 160,
    '& .MuiButton-label': {
      fontWeight: 600,
    },
  },
  subHeading: {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: '20px',
    color: '#000000',
    marginTop: '12px',
  },
}));

const SuccessfulModal = ({
  modalOpen,
  setModalOpen,
  resourceName,
  category,
  subHeading,
  description,
  buttonText,
  handleViewDetails,
  subHeadingStyle,
  buttonStyle,
  setSelectedCard,
}) => {
  const classes = useStyles();

  const { HEADING_TEXT, IMG_URL, PARA_TEXT, BTN_TEXT } = POPUP_MODAL_SUCCESS_ADD_DATA;
  const unSelectCard = () => {
    if (setSelectedCard) {
      setSelectedCard(null);
    }
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.root}>
          <div className={classes.closeIconContainer}>
            <Close
              className={classes.closeIcon}
              onClick={() => {
                unSelectCard();
                setModalOpen(!modalOpen);
              }}
            />
          </div>
          <div className={classes.iconContainer}>
            <img src={IMG_URL} alt="failedToLoadIcon" className={classes.icon} />
          </div>
          <div className={classes.heading}>{HEADING_TEXT}</div>
          {subHeading && <p className={subHeadingStyle || classes.subHeading}>{subHeading}</p>}
          <div className={classes.paraContainer}>
            <p className={classes.paraText}>
              {category || resourceName ? PARA_TEXT(resourceName, category) : description}
            </p>
          </div>
          <div className={classes.btnContainer}>
            <Button
              color="secondary"
              variant="contained"
              title={BTN_TEXT}
              sx={{marginTop: "32px"}}
              className={buttonStyle || classes.btn}
              onClick={handleViewDetails}
            >
              {buttonText || BTN_TEXT}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessfulModal;
