import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import colors from '../colors';
import { Box, Typography } from '@material-ui/core';
import ListItem from '../ListItem';
import { dataExtracting } from '../helper';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../mockTemplate';
import axios from 'axios';

const styles = (theme) => ({
  detailsBody: {
    marginTop: theme.spacing(2),
  },
  sectionWrapper: {
    marginBottom: theme.spacing(4),
  },
  sectionHeading: {
    color: colors.TITLE_GRAY,
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});


const ListItems = ( props ) => {

  const { classes, responseObj} = props;
  const { categoryName } = useParams();
  const { templates } = useSelector((state) => state.templates);
  
  const labels = templates?.[categoryName]?.details?.list || mockTemplates?.[categoryName]?.details?.list;

  const sections = labels?.map( (label, index) => {
    const obj = dataExtracting(responseObj, label);
    return  {title: label?.title, body: obj};
  });

  return (
    <Box className={classes.detailsBody}>
      {sections?.map((section, index) => (
        <Box className={classes.sectionWrapper} key={index} >
          <Typography className={classes.sectionHeading}>{section?.title}</Typography>
          {section?.body?.length > 0 ? <ListItem items={section?.body} /> : <Typography>-</Typography>}
        </Box>
      ))
      }
    </Box >
  );
};

export default withStyles(styles, { withTheme: true })(ListItems);
