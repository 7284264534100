import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import './Questionnaire.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PartnerApi from 'services/api/PartnerApi';
import { getDomainName } from 'shared/utility';

export const Questionnaire = props => {
  const [questionnaireData, setQuestionnaireData] = useState(null);

  const handleChange = (type, val, question) => {
    switch (type?.toLowerCase()) {
      case 'radio':
        question.answerId = [+val];
        break;

      case 'checkbox':
        if (question?.answerId?.find(el => el === +val)) {
          question?.answerId?.splice(
            question?.answerId?.findIndex(el => el === +val),
            1
          );
        } else {
          if (!question?.answerId) question.answerId = [];
          question?.answerId?.push(+val);
        }
        break;

      case 'longtext':
        if (val?.length <= 500) {
          question.answerValue = val;
        }
        break;

      case 'shorttext':
        if (val?.length <= 100) {
          question.answerValue = val;
        }
        break;

      case 'selectbox':
        question.answerId = [val];
        break;

      default:
        break;
    }
    let newObj = structuredClone(questionnaireData);
    if (props?.questionnaireRef) {
      props.questionnaireRef.current =
        newObj?.applicationQuestionConfig ?? newObj;
    }
    setQuestionnaireData(newObj);
  };
  const getOptions = question => {
    switch (
      question?.questionType?.type?.trim()?.toLowerCase() ??
      'textfield'
    ) {
      case 'shorttext':
      case 'longtext':
        return props?.view ? (
          <div className="textfield-view">{question?.answerValue}</div>
        ) : (
          <TextField
            className="answers-textfield"
            variant="outlined"
            placeholder="Answer"
            fullWidth
            onChange={e =>
              handleChange(
                question?.questionType?.type?.trim()?.toLowerCase(),
                e.target.value,
                question
              )
            }
          />
        );
      case 'selectbox':
        return (
          <FormControl disabled={props?.view}>
            <Select
              className="answers-select"
              disabled={props?.view}
              onChange={e =>
                handleChange('selectbox', e.target.value, question)
              }
              // inputProps={{ readOnly: true }}
              value={+question?.answerId?.[0]}
            >
              {question?.choices?.map(item => {
                return <MenuItem value={item?.id}>{item?.value}</MenuItem>;
              })}
            </Select>
          </FormControl>
        );

      case 'radio':
        return (
          <FormControl component="fieldset">
            <RadioGroup
              value={+question?.answerId?.[0]}
              onChange={e => handleChange('radio', e.target.value, question)}
              disabled={props?.view}
            >
              {question?.choices?.map(item => {
                return (
                  <FormControlLabel
                    value={+item?.id}
                    control={<Radio />}
                    label={item?.value}
                    disabled={props?.view}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );

      case 'checkbox':
        return (
          <FormControl component="fieldset">
            <FormGroup>
              {question?.choices?.map(item => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={question?.answerId?.find(
                          aid => aid?.toString() === item?.id?.toString()
                        )}
                        onChange={e =>
                          handleChange('checkbox', e.target.value, question)
                        }
                        name={item?.value}
                        disabled={props?.view}
                      />
                    }
                    label={item?.value}
                    value={item?.id}
                    disabled={props?.view}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
      default:
        break;
    }
  };

  const renderQuestionAnswer = () => {
    return (
      <>
        <div style={{ ...props?.styles?.card }}>
          {!props?.view && <div className="header">Questionnaire</div>}
          {Array.isArray(questionnaireData?.applicationQuestionConfig) ? (
            questionnaireData?.applicationQuestionConfig?.map((item, index) => {
              return (
                <div
                  className="qa-wrapper accordion-wrapper"
                  style={{ ...props?.styles?.root }}
                >
                  <Accordion className="accordion">
                    <AccordionSummary
                      className="accordion-summary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <div className="questionIndex">{index + 1}</div>
                      <div className="question">{item?.title}</div>
                    </AccordionSummary>
                    <AccordionDetails
                      className="accordion-details"
                      style={{ ...props?.styles?.acc }}
                    >
                      {getOptions(item)}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })
          ) : (
            <div
              className="qa-wrapper accordion-wrapper"
              style={{ ...props?.styles?.root }}
            >
              <Accordion className="accordion">
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div className="questionIndex">1</div>
                  <div className="question">
                    {questionnaireData?.applicationQuestionConfig?.title}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className="accordion-details"
                  style={{ ...props?.styles?.acc }}
                >
                  {getOptions(questionnaireData?.applicationQuestionConfig)}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </>
    );
  };

  const getConfigurations = useCallback(() => {
    async function getConfig() {
      try {
        const domainName = getDomainName(window.location.href);
        const details = await PartnerApi.partnerConfiguration(domainName);
        setQuestionnaireData(details);
        if (props?.questionnaireRef) {
          props.questionnaireRef.current =
            details?.applicationQuestionConfig ?? details;
        }
      } catch (e) {
        console.error(e);
      }
    }
    getConfig();
  }, []);

  useEffect(() => {
    if (!props?.data) {
      getConfigurations();
    } else {
      setQuestionnaireData({ applicationQuestionConfig: props?.data });
      if (props?.questionnaireRef) {
        props.questionnaireRef.current = props?.data;
      }
    }
  }, []);

  return (
    <div id="questionnaire-wrapper" style={{ ...props.styles?.root }}>
      {renderQuestionAnswer()}
    </div>
  );
};
