import { FlyoutPanel } from 'components/widgets';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useUserPermissions } from 'utils/userPermissions';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

function ReadonlyField({ label, value, isLink }) {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ paddingBottom: 5, color: '#828282', fontSize: 14 }}>
        {label}
      </div>
      <div
        style={{ opacity: 0.87, fontSize: 14 }}
        className={isLink ? classes.link : null}
      >
        {value}
      </div>
    </div>
  );
}

function AboutUserContent({ hasCrudRights }) {
  const [userPermissions, setUserPermissions] = useState([]);

  const {
    info: { firstName, lastName, userName, accessName, roleDescription },
    resourcePermissions: permissions,
  } = useSelector(state => state.userInfo);

  const { checkSuperAdminPermission, checkITAdminPermission } =
    useUserPermissions();

  const isSuperAdmin = checkSuperAdminPermission();
  
  useEffect(() => {
    function getResPermissions(uPermissions, isAdmin) {
      const resPermissions = Object.keys(uPermissions).map(devKey => {
        const org_affiliate = isAdmin ? [devKey] : devKey.split('_');

        const convertedKeyMap = Object.keys(uPermissions[devKey]).map(key => {
          const convertedKey = key
            .toLowerCase()
            .split('_')
            .map(
              sItem =>
                sItem[0].toUpperCase() + sItem.substr(1, sItem.length - 1)
            )
            .join(' ');

          return convertedKey;
        });

        return {
          id: `OrgID: ${org_affiliate[0]}${
            isAdmin ? '' : ', AffiliateID: ' + org_affiliate[1]
          }`,
          value: convertedKeyMap,
        };
      });

      return resPermissions;
    }

    const devPerms = getResPermissions(permissions.developer);

    console.log(devPerms);

    setUserPermissions([...devPerms]);
  }, [permissions]);

  return (
    <Grid container spacing={3} style={{ marginBottom: 16 }}>
      <Grid item xs={12}>
        <ReadonlyField label="UserName" value={userName} />
      </Grid>
      <Grid item xs={6}>
        <ReadonlyField label="First name" value={firstName} />
      </Grid>
      <Grid item xs={6}>
        <ReadonlyField label="Last name" value={lastName} />
      </Grid>
      <Grid item xs={12}>
        {/* <b>System Permissions</b> */}
        <b>Role</b>
      </Grid>
      {
        isSuperAdmin ? (
          <Grid item xs={12}>
            Super Admin
          </Grid>
        ) : 
        
        // (
          <Grid item xs={12}>
              {accessName} ({roleDescription})
              </Grid>
        //   <Grid item xs={12}>
        //     {userPermissions.map((item, itemIndex) => (
        //       <Grid container key={itemIndex} spacing={2}>
        //         <Grid item xs={12} style={{ fontSize: 14 }}>
        //           <b>{item.id}</b>
        //         </Grid>
        //         {item.value.map((rItem, index) => (
        //           <Grid key={index} item xs={6} sm={3}>
        //             <ReadonlyField label={rItem} value="Allowed" />
        //           </Grid>
        //         ))}
        //       </Grid>
        //     ))}
        //   </Grid>
        // )
      }
    </Grid>
  );
}

export default function AboutUserFlyout({
  onToggle,
  title,
  open,
  hasCrudRights,
  ...otherProps
}) {
  return (
    <FlyoutPanel title={title} open={open} onToggle={() => onToggle()}>
      {open ? (
        <AboutUserContent
          onToggle={onToggle}
          {...otherProps}
          hasCrudRights={hasCrudRights}
        />
      ) : null}
    </FlyoutPanel>
  );
}
