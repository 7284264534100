import { memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const ActionsCellTemplate = memo(function ActionsCellTemplate({
  data,
  // onEdit,
  onDelete,
}) {
  const onClickHandler = (event, action) => {
    event.preventDefault();

    if (action === 'delete') {
      onDelete(data);
    } 
    // else if (action === 'edit') {
    //   onEdit(data);
    // }
  };

  return (
    <span>
      {/* <IconButton
        size="small"
        style={{ marginRight: 10 }}
        onClick={event => onClickHandler(event, 'edit')}>
        <EditIcon />
      </IconButton> */}
      <IconButton
        size="small"
        onClick={event => onClickHandler(event, 'delete')}>
        <DeleteIcon />
      </IconButton>
    </span>
  );
});

export default ActionsCellTemplate;
