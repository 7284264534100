import BaseApiService from './BaseApiService';

export function getCareTeam(orgId) {
  return BaseApiService.get(`/organization/${orgId}/getCareTeam`);
}

export function getCareMemberDetails(orgId, memberId) {
  return BaseApiService.get(
    `/organization/${orgId}/getCareMemberDetails/${memberId}`
  );
}
