import { NewFlyoutPanel } from 'components/widgets';
import NewUserFlyoutContent from './NewUserFlyoutContent';
import { InviteUserLandingPage } from './InviteUserLandingPage';

export default function NewUserFlyout({
  onToggle,
  title,
  open,
  onUserUpdated,
  ...otherProps
}) {
  return (
    <NewFlyoutPanel title={title} open={open} onToggle={() => onToggle()}>
      {open ? (
        // <NewUserFlyoutContent
        //   onToggle={onToggle}
        //   onUserUpdated={onUserUpdated}
        //   {...otherProps}
        // />
        <InviteUserLandingPage
          onToggle={onToggle}
          onUserUpdated={onUserUpdated}
          {...otherProps}
        />
      ) : null}
    </NewFlyoutPanel>
  );
}
