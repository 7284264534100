import React from 'react';
import {
  FormSelectField,
  SecondaryButton,
  PrimaryButton,
} from 'components/widgets';
import { Grid, FormLabel, Box } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, makeValidate, makeRequired } from 'mui-rff';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  formSelect: {
    marginLeft: -8,
    marginRight: 8,
  },
  gridContainer: {
    height: 80,
  },
  sequenceTitle: {
    marginBottom: 20,
  },
  frequencyGrid: {
    marginRight: 10,
    height: 90,
  },
  resourcesGrid: {
    height: 80,
  },
  addResourceButton: {
    marginTop: 5,
  },
  box: {
    marginTop: 100,
  },
  dateLabel: {
    marginTop: 8,
  },
  firstRow: {
    marginBottom: 10,
  },
  firstIcon: {
    marginTop : 15,
  }
}));

const resourceTypes = [
  { code: '1', name: 'Coverage' },
  { code: '2', name: 'Insurance Plan' },
  { code: '3', name: 'Organization' },
  { code: '4', name: 'Practitioner' },
  { code: '5', name: 'Patient' },
  { code: '6', name: 'Device' },
  { code: '7', name: 'Encounter' },
  { code: '8', name: 'Allergy Intolerance' },
  { code: '9', name: 'Care Plan' },
  { code: '10', name: 'Care Team' },
  { code: '11', name: 'Condition' },
  { code: '12', name: 'Goal' },
  { code: '13', name: 'Procedure' },
  { code: '14', name: 'Observation' },
  { code: '15', name: 'Diagnostic Report' },
  { code: '16', name: 'Claim' },
  { code: '17', name: 'Explanation Of Benefit' },
  { code: '18', name: 'Medication' },
  { code: '19', name: 'Medication Request' },
  { code: '20', name: 'Medication Knowledge' },
  { code: '21', name: 'Immunization' },
  { code: '22', name: 'Consent' },
  { code: '23', name: 'Document Reference' },
];

const FileSensedScheduleType = ({
  onToggle,
  onSubmit,
  dataLoadType,
  orgId,
  lobId,
  affId,
  prePopulateData,
}) => {
  let flag;
  const classes = useStyles();

  const schema = Yup.object().shape({
    resources: Yup.array().of(
      Yup.object().shape({
        resourceStartDate: Yup.date().required().typeError('This field is required')
      })
    )
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const emptyOptions = {
    resource: '',
    fileType: '',
    resourceStartDate: '',
    scheduleType: dataLoadType,
    orgId: orgId,
    lobId: lobId,
    affId: affId,
    lastRun: '',
    status: '',
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...prePopulateData }}
      mutators={{ ...arrayMutators }}
      validate={validate}
      render={({
        handleSubmit,
        pristine,
        form: {
          mutators: { push, remove },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <FormLabel className={classes.sequenceTitle} component="legend">
            List of Resources
          </FormLabel>

          <FieldArray name="resources">
            {({ fields }) => (
              <>
                {fields.length > 0 ? flag=true : flag=false}
                {fields.length > 0 && (
                  <>
                    {fields.map((resources, index) => (
                      <div className={index === 0 && classes.firstRow}>
                        <Grid
                          container
                          key={index}
                          className={classes.gridContainer}>
                          <Grid item xs={4} className={classes.resourcesGrid}>
                            {index === 0 && (
                              <FormLabel component="legend">
                                Resource Type
                              </FormLabel>
                            )}
                            <div className={classes.formSelect}>
                              <FormSelectField
                                name={`${resources}.resource`}
                                options={resourceTypes}
                                required
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3} className={classes.resourcesGrid}>
                            {index === 0 && (
                              <FormLabel component="legend">
                                File type
                              </FormLabel>
                            )}
                            <div className={classes.formSelect}>
                              <FormSelectField
                                name={`${resources}.fileType`}
                                placeholder="File type"
                                options={[
                                  { code: '1', name: 'CSV' },
                                  { code: '2', name: 'XML' },
                                ]}
                                required
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3} className={classes.ingestionGrid}>
                            {index === 0 && (
                              <FormLabel component="legend">
                                Start Date
                              </FormLabel>
                            )}
                            <div className={classes.dateLabel}>
                              <KeyboardDatePicker
                                name={`${resources}.resourceStartDate`}
                                inputVariant="outlined"
                                variant="inline"
                                disablePast
                                size="small"
                                format="MM/dd/yyyy"
                                required={required.resources}
                                dateFunsUtils={DateFnsUtils}
                              />
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              height: 0,
                              marginTop: 10,
                              marginLeft: 10,
                            }}>
                            <div className={index === 0 && classes.firstIcon}>
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => fields.remove(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </>
                )}

                <SecondaryButton
                  className={classes.addResourceButton}
                  size="small"
                  onClick={() => push('resources', emptyOptions)}>
                  Add Resource
                </SecondaryButton>
              </>
            )}
          </FieldArray>

          <Box
            display="flex"
            justifyContent="flex-start"
            className={classes.box}
            marginTop={4}>
            <SecondaryButton onClick={onToggle} style={{ marginRight: 10 }}>
              Cancel
            </SecondaryButton>
            {!pristine && 
              <PrimaryButton
              type="submit"
              onClick={handleSubmit}
              disabled={!flag}>
                Add
              </PrimaryButton>
            }
          </Box>
        </form>
      )}
    />
  );
};

export default FileSensedScheduleType;
