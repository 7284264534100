import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const APIChart = ({ data = {} }) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartOptions(data);
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default APIChart;
