import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
  },
}));

const CriteriaDropdown = ({
  options,
  title,
  required,
  value,
  handleChange,
  disabled,
  dataTestId,
  dataTestIdForOptions,
  optionValueKey,
  optionLabelKey,
  multiple,
  placeHolderText,
  id,
}) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" fullWidth style={{ maxWidth: '100%' }}>
      {title && (
        <Typography className={classes.formTitle}>
          {title} {required && <span style={{ color: 'red' }}>*</span>}
        </Typography>
      )}
      <Select
        style={{ backgroundColor: 'white',color:value==='placeholder' ? '#667085' : 'initial' }}
        id={id || undefined}
        value={value}
        onChange={handleChange}
        className={classes.formTextField}
        disabled={disabled}
        margin="dense"
        IconComponent={ExpandMoreIcon}
        data-testid={dataTestId || undefined}
        defaultValue=""
        multiple={multiple || false}>
        {placeHolderText && (
          <MenuItem value="placeholder" disabled>
            {placeHolderText}
          </MenuItem>
        )}
        {options &&
          options.map(option => (
            <MenuItem
              key={option.key || option.value}
              value={optionValueKey ? option[optionValueKey] : option}
              data-testid={dataTestIdForOptions || undefined}>
              {optionLabelKey ? option[optionLabelKey] : option}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CriteriaDropdown;
