import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M16.25 5.55762C10.6035 5.55762 6.05762 10.1035 6.05762 15.75C6.05762 21.3965 10.6035 25.9424 16.25 25.9424C21.8965 25.9424 26.4424 21.3965 26.4424 15.75C26.4424 10.1035 21.8965 5.55762 16.25 5.55762ZM23.332 11.6826H20.3174C19.9824 10.3906 19.5039 9.14648 18.8818 8.0459C20.7959 8.66797 22.3271 9.95996 23.332 11.6826ZM16.25 7.61523C17.1113 8.85938 17.7812 10.1992 18.2119 11.6826H14.2881C14.7188 10.1992 15.3887 8.85938 16.25 7.61523ZM8.35449 17.8076C8.16309 17.1377 8.06738 16.4678 8.06738 15.75C8.06738 15.0322 8.16309 14.3623 8.35449 13.6924H11.7998C11.7041 14.3623 11.6562 15.0322 11.6562 15.75C11.6562 16.4678 11.7041 17.1377 11.7998 17.8076H8.35449ZM9.16797 19.8174H12.1826C12.5176 21.1094 12.9961 22.3535 13.6182 23.4541C11.7041 22.832 10.1729 21.54 9.16797 19.8174ZM12.1826 11.6826H9.16797C10.1729 9.95996 11.7041 8.66797 13.6182 8.0459C12.9961 9.14648 12.5176 10.3906 12.1826 11.6826ZM16.25 23.8848C15.3887 22.6406 14.7188 21.3008 14.2881 19.8174H18.2119C17.7812 21.3008 17.1113 22.6406 16.25 23.8848ZM18.6426 17.8076H13.8574C13.7617 17.1377 13.7139 16.4678 13.7139 15.75C13.7139 15.0322 13.7617 14.3623 13.8574 13.6924H18.6426C18.7383 14.3623 18.7861 15.0322 18.7861 15.75C18.7861 16.4678 18.7383 17.1377 18.6426 17.8076ZM18.8818 23.4541C19.5039 22.3535 19.9824 21.1094 20.3174 19.8174H23.332C22.3271 21.54 20.7959 22.832 18.8818 23.4541ZM20.7002 17.8076C20.7959 17.1377 20.8438 16.4678 20.8438 15.75C20.8438 15.0322 20.7959 14.3623 20.7002 13.6924H24.1455C24.3369 14.3623 24.4326 15.0322 24.4326 15.75C24.4326 16.4678 24.3369 17.1377 24.1455 17.8076H20.7002Z" />
    </SvgIcon>
  );
};

export default Icon;
