import { useState, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  chart: {
    type: 'bar',
  },
  title: {
    style: { display: 'none' },
  },

  xAxis: {
    categories: ['Success', 'Warnings', 'Failures'],
  },
  yAxis: {
    min: 0,
    labels: {
      overflow: 'justify',
    },
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: 'Total',
      data: [],
    },
  ],
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
};

const ResponseCodesChart = ( {data,height} ) => {
    const [chartOptions, setChartOptions] = useState(options);

    useEffect(() => {
      
      const updatedOptions = {
        ...options,
        chart: {
          ...options.chart,
          height: height,
        },
        series: [
          {
            ...options.series[0],
            data: data,
          },
        ],
      };

      setChartOptions(updatedOptions);
    }, [data,height]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default ResponseCodesChart;
