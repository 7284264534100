import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <rect width="32" height="32" rx="8" fill="#4FBB6B" />
      <path
        d="M13.6013 18.441L10.7888 15.6285C10.4888 15.3285 10.0013 15.3285 9.6638 15.6285C9.3638 15.9285 9.3638 16.416 9.6638 16.7535L13.0388 20.091C13.3388 20.4285 13.8638 20.4285 14.1638 20.091L22.6388 11.616C22.9388 11.316 22.9388 10.8285 22.6388 10.491C22.3388 10.191 21.8513 10.191 21.5138 10.491L13.6013 18.441Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Icon;
