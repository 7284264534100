import { Component, memo, useState } from 'react';
import { DataGrid, SimpleBackdrop } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import Box from '@material-ui/core/Box';
import { statusColorMap } from 'shared/utility';
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomizedDialog from 'components/CustomizedDialog';
import MemberDocUploadPopUp from 'containers/Members/MemberDocUploadPopUp';
import MemberAddActivityPopUp from 'containers/Members/MemberAddActivityPopUp';
import AddCareNavigatorPopUp from 'containers/Members/AddCareNavigatorPopUp';
import { useSelector } from 'react-redux';
import BasicTabs from 'containers/DynamicRenderOfRecords/ServicesTabs';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom';
import SendAssessmentPopup from 'containers/Assessments/SendAssessmentPopup';
import BaseApiService from 'services/api/BaseApiService';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
    padding:'10px'
  },
  domainPopup: {
    minHeight: '250px',
    minWidth: '732px',
  },
}));

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  orgId,
  onClick,
  handleSelectedMembers,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDocUpload, setOpenDocUpload] = useState(false);
  // const [openAddActivity, setOpenAddActivity] = useState(false);
  const [openAddCareNavigator, setOpenAddCareNavigator] = useState(false);
  const userInfo = useSelector(state => state.userInfo);
  const [openAssessmentPopup, setOpenAssessmentPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCarePlanClick = () => {
    sessionStorage.setItem('patientId',data.fhirReferenceId)
    history.push(`/organization/${orgId}/createCarePlan`)
  };

  const handleMenuFunctions = type => {
    if (type === 'Upload document') {
      setOpenDocUpload(true);
    } else if (type === 'Care Navigator') {
      setOpenAddCareNavigator(true);
    } else if (type === 'Send Assessment') {
      setOpenAssessmentPopup(true);
    } else if (type === 'Care Plan') {
      handleAddCarePlanClick();
      // } else if (type === 'Add activity') {
    //   setOpenAddActivity(true);
    } else {
      handleSelectedMembers(data, type);
      handleClose();
    }
  };

  const closeDocUpload = () => {
    setOpenDocUpload(false);
    handleClose();
  };

  const handleCloseAssessmentPopup = () => {
    setOpenAssessmentPopup(false);
  };

  const handleSendAssessment = async (assessment, expiryDate) => {
  
    const patientId = data.fhirReferenceId;
    const assessmentId = assessment.id; 
  
    const payload = {
      patientId: patientId,
      assessmentId: assessmentId,
      instanceExpiryDate: expiryDate,
    };
  
    try {
      const response = await BaseApiService.post(
        `/member/${orgId}/sendAssessment`,
        null,
        payload
      );
      if (response === 'SUCCESS') {
        enqueueSnackbar('Assessment sent successfully!', { variant: 'success',autoHideDuration: 10000 });
      } else {
        enqueueSnackbar('Failed to send assessment. Please try again.', { variant: 'error',autoHideDuration: 10000 });
      }
    } catch (error) {
      enqueueSnackbar('Error occurred while sending assessment.', { variant: 'error',autoHideDuration: 10000 });
    }
  
    handleCloseAssessmentPopup();
  };

  // const closeAddActivity = () => {
  //   setOpenAddActivity(false);
  //   handleClose();
  // };

  const closeAddCareNavigator = () => {
    setOpenAddCareNavigator(false);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const activeMenuProp = data?.memberEnabled ? 'Disable' : 'Enable';
  const activeMenuTitle = data?.memberEnabled
    ? 'Disable account'
    : 'Enable account';

  const readOnlyViewTitle = data?.readOnly
    ? 'Disable readonly'
    : 'Enable readonly';

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '200px',
        }}
      >
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
        >
          View details
        </PrimaryButton>
        <IconButton onClick={handleClick}>
          <MoreVertIcon style={{ fontSize: '20px' }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuList autoFocusItem={open} id="menu-list">
            <MenuItem onClick={() => handleMenuFunctions(activeMenuProp)}>
              {activeMenuTitle}
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Read only view')}>
              {readOnlyViewTitle}
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Communication Preferences')}>
              Set preferences
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Change Password')}>
              Change password
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Upload document')}>
              Upload document
            </MenuItem>
            {(userInfo.info.accessName === "Organization Admin" || userInfo.info.accessName === "Care supervisor") && 
            <MenuItem onClick={() => handleMenuFunctions('Care Navigator')}>
              Assign Care Manager
            </MenuItem>}
            <MenuItem onClick={() => handleMenuFunctions('Care Plan')}>
              Add Care Plan
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Add activity')}>
              Add activity
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Send Assessment')}>
              Send assessment
            </MenuItem>
            <MenuItem onClick={() => handleMenuFunctions('Book Appointment')}>
              Book appointment
            </MenuItem>
          </MenuList>
        </Popover>
      </div>
      <MemberDocUploadPopUp open={openDocUpload} onClose={closeDocUpload} />
      {/* <MemberAddActivityPopUp open={openAddActivity} onClose={closeAddActivity} /> */}
      <AddCareNavigatorPopUp open={openAddCareNavigator} onClose={closeAddCareNavigator} orgId={orgId} fhirReferenceId={data.fhirReferenceId}/>
      <SendAssessmentPopup
        orgId={orgId}
        onSend={handleSendAssessment}
        open={openAssessmentPopup}
        onClose={handleCloseAssessmentPopup}
      />
    </>
  );
});

const MemberActions = props => {
  switch (props.type) {
    case 'Change Password':
      return <ChangePassword {...props} />;
    case 'Enable':
    case 'Disable':
      return <EnableDisableLogin {...props} />;
    case 'Read only view':
      return <ReadOnlyView {...props} />;
    case 'Communication Preferences':
      return <CommunicationPreference {...props} />;
    default:
      return null;
  }
};

const ChangePassword = ({
  closePopup,
  data,
  handleMembersAction,
  open,
  type,
}) => {
  const classes = useStyles();

  const updateMembersAction = () => {
    closePopup();
    handleMembersAction(
      data,
      'changePassword',
      'Password changed successfully'
    );
  };

  return (
    <CustomizedDialog
      title={'Change password'}
      open={open}
      customStyles={classes.domainPopup}
      primaryBtnTitle="Change password"
      handleOnSave={updateMembersAction}
      handleClickClose={closePopup}
    >
      <Typography>{`Are you sure you would like to change password?`}</Typography>
    </CustomizedDialog>
  );
};

const EnableDisableLogin = ({
  closePopup,
  data,
  handleMembersAction,
  open,
  type,
}) => {
  const classes = useStyles();
  const updateMembersAction = () => {
    closePopup();
    handleMembersAction(
      data,
      'activate',
      data?.memberEnabled
        ? 'Successfully disabled account'
        : 'Successfully enabled account',
      'memberEnabled'
    );
  };
  return (
    <CustomizedDialog
      title={`${type} account`}
      open={open}
      customStyles={classes.domainPopup}
      primaryBtnTitle={type}
      handleOnSave={updateMembersAction}
      handleClickClose={closePopup}
    >
      <Typography>{`Are you sure you would like to ${type?.toLowerCase()}?`}</Typography>
    </CustomizedDialog>
  );
};

const ReadOnlyView = ({
  closePopup,
  data,
  handleMembersAction,
  open,
  type,
}) => {
  const classes = useStyles();
  const readyOnlyViewTitle = !data?.readOnly
    ? 'Enable readonly'
    : 'Disable readonly';

  const updateMembersAction = () => {
    closePopup();
    handleMembersAction(
      data,
      'isUserReadOnly',
      !data?.readOnly
        ? 'Successfully enabled read only'
        : 'Successfully disabled read only',
      'readOnly'
    );
  };

  return (
    <CustomizedDialog
      title={readyOnlyViewTitle}
      open={open}
      customStyles={classes.domainPopup}
      primaryBtnTitle={'Confirm'}
      handleOnSave={updateMembersAction}
      handleClickClose={closePopup}
    >
      <Typography>{`Are you sure you would like to ${readyOnlyViewTitle?.toLowerCase()}?`}</Typography>
    </CustomizedDialog>
  );
};

const CommunicationPreference = ({
  closePopup,
  data,
  handleMembersAction,
  open,
  type,
}) => {
  const classes = useStyles();

  const [state, setState] = useState({
    Email: data?.communicationPreferences?.includes('Email'),
    SMS: data?.communicationPreferences?.includes('SMS'),
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const resetData = () => {
    closePopup();
    setState({ Email: true, SMS: false });
  };

  const updateMembersAction = () => {
    closePopup();
    const selectedPreferences = [];
    Object.keys(state).forEach(key => {
      if (state[key]) selectedPreferences.push(key);
    });
    data.communicationPreferences = selectedPreferences;
    handleMembersAction(
      data,
      'communicationPreferences',
      'Successfully updated communication preferences'
    );
  };

  return (
    <CustomizedDialog
      title={'Set preferences'}
      open={open}
      customStyles={classes.domainPopup}
      primaryBtnTitle={'Confirm'}
      handleOnSave={updateMembersAction}
      handleClickClose={resetData}
    >
      <Box
        display={'flex'}
        gridGap={'20px'}
        flexDirection={'column'}
        margin={'15px 0px'}
      >
        <Typography variant="h4">Communication preferences</Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                name="Email"
                color="primary"
                checked={state.Email}
                onChange={handleChange}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.SMS}
                onChange={handleChange}
                name="SMS"
                color="primary"
              />
            }
            label="SMS"
          />
        </FormGroup>
      </Box>

      <Box
        display={'flex'}
        gridGap={'20px'}
        flexDirection={'column'}
        margin={'15px 0px'}
      >
        <Typography variant="h4">Print preferences</Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                name="Braille"
                color="primary"
                // checked={state.Email}
                // onChange={handleChange}
              />
            }
            label="Braille"
          />
          <FormControlLabel
            control={
              <Switch
                // checked={state.SMS}
                // onChange={handleChange}
                name="Large Print"
                color="primary"
              />
            }
            label="Large Print"
          />
        </FormGroup>
      </Box>
    </CustomizedDialog>
  );
};

class CMDEDataGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'First name',
        field: 'firstName',
        width: 20,
        cellRenderer: 'FirstNameRenderer'
      },
      {
        headerName: 'Last name',
        field: 'lastName',
        cellRenderer: 'FirstNameRenderer'
      },
      { headerName: 'Email', field: 'email' },
      { headerName: 'Phone no', field: 'phoneNumber' },
      { headerName: 'Affiliate name', field: 'affiliateName' },
      { headerName: 'Status', field: 'status', cellRenderer: 'StatusRenderer' },
      {
        field: '',
        cellRenderer: 'DetailsRenderer'
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 160,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      ConsentExpiryDateFormatter: rowData => {
        if (rowData.value && rowData.data.consentStatus !== 'Pending') {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      StatusRenderer: rowData => {
        let status = rowData.value;
        if (rowData?.value === 'Active') {
          if (rowData?.data?.memberEnabled) {
            status = 'Enabled';
          } else {
            status = 'Disabled';
          }
        } else if (rowData?.value === 'Pending') {
          if (!rowData?.data?.memberEnabled) status = 'Disabled';
        }
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(statusColorMap).includes(status) ? (
              <>
                <span
                  style={{
                    backgroundColor: statusColorMap[status],
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    padding: 2,
                  }}
                ></span>
                <span style={{ marginLeft: 8 }}>{status}</span>
              </>
            ) : (
              <span>{status}</span>
            )}
          </Box>
        );
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      FirstNameRenderer: (rowData) => (
        <a href="#" style={{color:"#036FCB"}} onClick={() => this.handleFirstNameClick(rowData)}>
          {rowData.value}
        </a>
          ),
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          orgId={this.props.orgId}
          onClick={item => this.onItemView(item)}
          handleSelectedMembers={(data, type) =>
            this.handleSelectedMembers(data, type)
          }
        />
      ),

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
    selectedMembers: undefined,
    popupDetails: {
      open: false,
      type: '',
    },
    actionsApiLoading: false,
    redirectToRecords: false,
  };

  handleFirstNameClick = (rowData) => {
    const memberId = rowData.data.fhirReferenceId;
    sessionStorage.setItem("memberId",memberId)
    this.setState({ redirectToRecords: true });
  };

  handleSelectedMembers(data, type) {
    this.setState({
      selectedMembers: data,
      popupDetails: { open: true, type },
    });
  }

  onItemView(data) {
    this.props.onView(data);
  }

  onDownloadButton(data) {
    this.props.onDownload(data);
  }

  onRowDataChanged = event => {
    let prevSelectedNodes =
      this.props.selectedRows?.map(val => val.patientId) || [];
    event.api?.forEachNode(node => {
      if (prevSelectedNodes.includes(node.data?.patientId)) {
        node.setSelected(true);
      }
    });
  };

  closePopup = () => {
    this.setState({
      selectedMembers: undefined,
      popupDetails: { open: false, type: '' },
    });
  };

  render() {
    const {
      defaultColDef,
      columnDefs,
      frameworkComponents,
      selectedMembers,
      popupDetails,
      actionsApiLoading,
      redirectToRecords,
    } = this.state;
    const { rowData, selectTableData, onRowSelected, classes } = this.props;
    const rowSelectionType = 'multiple';

    if (redirectToRecords) {
      const path = `/organization/${this.props.orgId}/members/records`;
      return <Redirect to={path} />;
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 500,
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            membersPage={true}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '50vh', height: 'calc(100vh - 270px)' }}
            hideHeaderActions
            rowSelection={rowSelectionType}
            rowMultiSelectWithClick={true}
            suppressRowClickSelection={true}
            onRowSelected={onRowSelected}
            onRowDataChanged={this.onRowDataChanged}
          />
          <MemberActions
            open={popupDetails?.open}
            closePopup={this.closePopup}
            type={popupDetails?.type}
            data={selectedMembers}
            handleMembersAction={this.props.handleMembersAction}
          />
        </div>
        <SimpleBackdrop open={actionsApiLoading} />
      </div>
    );
  }
}

export default CMDEDataGrid;
