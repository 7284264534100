import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M17.2549 16.7549C16.6807 17.3291 15.7715 17.3291 15.2451 16.8027V16.7549C14.6709 16.2285 14.6709 15.3193 15.2451 14.7451C15.7715 14.1709 16.6807 14.1709 17.2549 14.7451C17.8291 15.2715 17.8291 16.2285 17.2549 16.7549ZM16.25 9.625L18.4033 11.7783L20.9873 9.24219L17.6855 5.98828C16.9199 5.1748 15.6279 5.1748 14.8145 5.98828L11.5605 9.24219L14.0967 11.7783L16.25 9.625ZM10.125 15.75L12.2783 13.5967L9.74219 11.0127L6.48828 14.3145C5.6748 15.0801 5.6748 16.3721 6.48828 17.1855L9.74219 20.4395L12.2783 17.9033L10.125 15.75ZM22.375 15.75L20.2217 17.9033L22.7578 20.4873L26.0117 17.1855C26.8252 16.4199 26.8252 15.1279 26.0117 14.3145L22.7578 11.0605L20.2217 13.5967L22.375 15.75ZM16.25 21.875L14.0967 19.7217L11.5127 22.2578L14.8145 25.5117C15.5801 26.3252 16.8721 26.3252 17.6855 25.5117L20.9395 22.2578L18.4033 19.7217L16.25 21.875Z" />
    </SvgIcon>
  );
};

export default Icon;
