import React from 'react';
import { Box, Paper, Typography, Button } from '@material-ui/core';

const VerifyPage = (props) => {
  return (
    <Paper style={{ height: '100vh' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <img
          src="/static/sammy-49 1.png"
          alt="Sammy"
          style={{ marginTop: '120px', marginBottom: '16px' }}
        />
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          Confirm your email
        </Typography>
        <Typography varaint="h6" style={{ marginTop: '16px' }}>
          Please check your email for confirmation mail. Click link in email to
          verify your account
        </Typography>
        <Typography
          varaint="h6"
          style={{ marginTop: '64px', marginBottom: '16px' }}>
          Verified email and setup your password?
        </Typography>
        <Button
          style={{
            textTransform: 'none',
            borderRadius: '24px',
            width: '230px',
            color: '#036FCB',
            backgroundColor: 'white',
          }}
          variant="contained"
          onClick={()=>props.onLogin()}
          >
          Login
        </Button>
      </Box>
    </Paper>
  );
};

export default VerifyPage;
