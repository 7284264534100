import { Box, Typography } from '@material-ui/core';
import React from 'react';
import nodata from '../assets/ruleEngine/nodata.svg';
import { PrimaryButton } from './widgets';

const NotFoundPage = ({
  title,
  showButton,
  content,
  onNotFoundClick,
  titleContent,
  styles,
}) => {
  return (
    <Box
      style={{
        width: '100%',
        background: '#FFFFFF',
        display: 'flex',
        padding: '64px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        boxSizing: 'border-box', ...styles
      }}>
      <img data-testid="img" src={nodata} alt="icon" />
      <Box
        style={{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginTop: '24px',
        }}>
        {title && (
          <Typography
            data-testid="title"
            variant="h4"
            style={{ marginTop: '16px' }}>{`${title}`}</Typography>
        )}
        <Typography
          style={{ marginTop: '16px', maxWidth: '444px' }}
          data-testid="maincontent">
          {content}
        </Typography>
        {showButton && (
          <PrimaryButton
            onClick={onNotFoundClick}
            data-testid="content"
            fullWidth
            style={{
              marginTop: '48px',
              maxWidth: '399px',
              width: '100%',
            }}>
            {titleContent}
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
};

export default NotFoundPage;
