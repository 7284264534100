import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import {
  Typography,
  Box,
  Grid,
  Stack,
  ListItem,
  ListItemAvatar,
  Avatar,
  List,
  ListItemText,
  Divider,
} from '@mui/material';
import FullScreenDialog from 'components/widgets/FullScreenDialog/FullScreenDialog';
import { PrimaryButton, SimpleBackdrop } from 'components/widgets';
import { format, isBefore, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { getCareMemberDetails } from 'services/api/CareDashboardService';
import { IconButton, Tab, Tabs } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MemberAddActivityPopUp from 'containers/Members/MemberAddActivityPopUp';
import MemberDocUploadPopUp from 'containers/Members/MemberDocUploadPopUp';
import DownloadIcon from '@mui/icons-material/Download';
import DemoApis from 'services/api/DemoApis';
import SendAssessmentPopup from 'containers/Assessments/SendAssessmentPopup';
import BaseApiService from 'services/api/BaseApiService';
import { useSnackbar } from 'notistack';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function CareDashboardDetails({
  open,
  title,
  handleClose,
  orgId,
  fhirReferenceId,
  keycloakUserId,
}) {
  const [details, setDetails] = useState({
    assessmentList: [],
    carePlanList: [],
    documentList: [],
    memberNotes: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openDocUpload, setOpenDocUpload] = useState(false);
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [openAssessmentPopup, setOpenAssessmentPopup] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (orgId && fhirReferenceId) {
      (async () => {
        setIsLoading(true);
        const result = await getCareMemberDetails(orgId, fhirReferenceId);
        setDetails(result);
        setIsLoading(false);
      })();
    }
  }, [orgId, fhirReferenceId]);

  function clearAndClose() {
    setDetails({
      assessmentList: [],
      carePlanList: [],
      documentList: [],
      memberNotes: [],
    });
    handleClose();
  }

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleOpenDocUpload = () => {
    setOpenDocUpload(true);
  };

  const handleOpenAddActivity = () => {
    setOpenAddActivity(true);
  };

  const handleCloseDocUpload = () => {
    setOpenDocUpload(false);
  };

  const handleCloseAddActivity = async () => {
    setOpenAddActivity(false);
    if (orgId && fhirReferenceId) {
      setIsLoading(true);
      const result = await getCareMemberDetails(orgId, fhirReferenceId);
      setDetails(result);
      setTabIndex(3);
      setIsLoading(false);
    }
  };

  const handleOpenAssessmentPopup = () => {
    setOpenAssessmentPopup(true);
  };

  const handleCloseAssessmentPopup = () => {
    setOpenAssessmentPopup(false);
  };

  const handleSendAssessment = async (assessment, expiryDate) => {
  
    const patientId = fhirReferenceId;
    const assessmentId = assessment.id; 
  
    const payload = {
      patientId: patientId,
      assessmentId: assessmentId,
      instanceExpiryDate: expiryDate,
    };
  
    try {
      const response = await BaseApiService.post(
        `/member/${orgId}/sendAssessment`,
        null,
        payload
      );
      if (response === 'SUCCESS') {
        enqueueSnackbar('Assessment sent successfully!', { variant: 'success',autoHideDuration: 10000 });
      } else {
        enqueueSnackbar('Failed to send assessment. Please try again.', { variant: 'error',autoHideDuration: 10000 });
      }
    } catch (error) {
      enqueueSnackbar('Error occurred while sending assessment.', { variant: 'error',autoHideDuration: 10000 });
    }
  
    handleCloseAssessmentPopup();
  };

  return (
    <FullScreenDialog open={open} title={title} handleClose={clearAndClose}>
      <DrawerBoxComponent>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="tabs"
            >
              <Tab label="Assessments" />
              <Tab label="Care Plans" />
              <Tab label="Documents" />
              <Tab label="Notes" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <AssessmentSection assessments={details.assessmentList} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <CarePlanSection carePlans={details.carePlanList} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <DocumentSection documents={details.documentList} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <NotesSection notes={details.memberNotes} />
            </TabPanel>
          </Grid>
          <Grid item xs={3}>
            <Stack spacing={3} px={7}>
              <PrimaryButton>Add appointment</PrimaryButton>
              <PrimaryButton onClick={handleOpenAddActivity}>Add notes</PrimaryButton>
              <PrimaryButton>Send message</PrimaryButton>
              <PrimaryButton>Obtain consent</PrimaryButton>
              <PrimaryButton onClick={handleOpenDocUpload}>Upload document</PrimaryButton>
              <PrimaryButton onClick={handleOpenAssessmentPopup}>Start assessment</PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
      </DrawerBoxComponent>
      <SimpleBackdrop open={isLoading} />
      <MemberDocUploadPopUp open={openDocUpload} onClose={handleCloseDocUpload} />
      <MemberAddActivityPopUp
        open={openAddActivity}
        onClose={handleCloseAddActivity}
        orgId={orgId}
        givenBy={`${userInfo.info.firstName} ${userInfo.info.lastName}`}
        givenTo={keycloakUserId}
      />
      <SendAssessmentPopup
        orgId={orgId}
        onSend={handleSendAssessment}
        open={openAssessmentPopup}
        onClose={handleCloseAssessmentPopup}
      />
    </FullScreenDialog>
  );
}

function AssessmentSection({ assessments }) {
  return (
    <div>
      {/* <Typography fontWeight={600} variant="h6">
        Assessments
      </Typography> */}
      <List
        sx={{
          width: '100%',
        }}
      >
        {assessments.map((assessment, index) => {
          const parsedExpiryDate = parseISO(assessment.expiryDate);
          return (
            <>
              <ListItem alignItems="flex-start" key={assessment.id}>
                <ListItemText
                  primary={assessment.assessmentName}
                  secondary={
                    <Stack direction="row" gap={2}>
                      <Typography
                        sx={{ display: 'inline-flex' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        <Typography variant="body2" fontWeight={600}>
                          Assigned:&nbsp;
                        </Typography>
                        {format(
                          parseISO(assessment.assignedDate),
                          'MMM dd, yyyy'
                        )}
                      </Typography>
                      <Typography
                        sx={{ display: 'inline-flex' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        <Typography variant="body2" fontWeight={600}>
                          {isBefore(parsedExpiryDate, new Date())
                            ? 'Expired:'
                            : 'Expiry:'}{' '}
                          &nbsp;
                        </Typography>
                        {format(parsedExpiryDate, 'MMM dd, yyyy')}
                      </Typography>
                      <Typography
                        sx={{ display: 'inline-flex' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        <Typography variant="body2" fontWeight={600}>
                          Status: &nbsp;
                        </Typography>
                        {assessment.status}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItem>
              {index != assessments.length - 1 && <Divider component="li" />}
            </>
          );
        })}
      </List>
    </div>
  );
}

function CarePlanSection({ carePlans }) {
  return (
    <div>
      {/* <Typography fontWeight={600} variant="h6">
        Care plans
      </Typography> */}
      <List
        sx={{
          width: '100%',
        }}
      >
        {carePlans.map((carePlan, index) => (
          <>
            <ListItem alignItems="flex-start" key={carePlan.id}>
              {/* <ListItemAvatar>
                <Avatar alt="E" src="" />
              </ListItemAvatar> */}
              <ListItemText
                primary={carePlan.planName}
                secondary={
                  <Stack direction="row" gap={2}>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Assigned:&nbsp;
                      </Typography>
                      {format(parseISO(carePlan.assignedDate), 'MMM dd, yyyy')}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Due:&nbsp;
                      </Typography>
                      {format(parseISO(carePlan.dueDate), 'MMM dd, yyyy')}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Status: &nbsp;
                      </Typography>
                      {carePlan.status}
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
            {index != carePlans.length - 1 && <Divider component="li" />}
          </>
        ))}
      </List>
    </div>
  );
}


function NotesSection({ notes }) {
  return (
    <div>
      {/* <Typography fontWeight={600} variant="h6">
        Notes
      </Typography> */}
      <List
        sx={{
          width: '100%',
        }}
      >
        {notes.map((note, index) => (
          <>
            <ListItem alignItems="flex-start" key={note.id}>
              {/* <ListItemAvatar>
                <Avatar alt="E" src="" />
              </ListItemAvatar> */}
              <ListItemText
                // primary={note.Name}
                secondary={
                  <Stack direction="row" gap={2}>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Created on:&nbsp;
                      </Typography>
                      {format(parseISO(note.createdAt), 'MMM dd, yyyy')}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Created by:&nbsp;
                      </Typography>
                      {note.givenBy}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline-flex', width:550 }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Notes:&nbsp;
                      </Typography >
                      {note.notes}
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
            {index != notes.length - 1 && <Divider component="li" />}
          </>
        ))}
      </List>
    </div>
  );
}

function DocumentSection({ documents }) {
  const userInfo = useSelector(state => state.userInfo);

  const handleDownloadClick = async (documentUrl) => {
  const response = await DemoApis.downloadDocument(documentUrl);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = window.document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${documentUrl.split('/')[4]}`);
  window.document.body.appendChild(link);
  link.click();
  }

  return (
    <div>
      {/* <Typography fontWeight={600} variant="h6">
        Documents
      </Typography> */}
      <List
        sx={{
          width: '100%',
        }}
      >
        {documents?.map((document, index) => (
          <>
            <ListItem alignItems="flex-start" key={document.id}>
              <ListItemText
                primary={document.documentName}
                secondary={
                  <Stack direction="row" gap={2}>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Uploaded on:&nbsp;
                      </Typography>
                      
                      {format(parseISO(document.updatedAt), 'MMM dd, yyyy')}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Uploaded by:&nbsp;
                      </Typography>
                      {document.updatedBy || (`${userInfo.info.firstName} ${userInfo.info.lastName}`)}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline-flex' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Typography variant="body2" fontWeight={600}>
                        Category:&nbsp;
                      </Typography>
                      {document.category}
                    </Typography>
                    <IconButton
                      onClick={() => handleDownloadClick(document.documentUrl)}
                      style={{padding: "0px",marginLeft:"20px"}}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Stack>
                }
              />
            </ListItem>
            {index != documents.length - 1 && <Divider component="li" />}
          </>
        ))}
      </List>
    </div>
  );
}

