import { Button, Chip } from '@material-ui/core';
import moment from 'moment/moment';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';

const fhirAppStatus = (productionSts, mphApprovalSts, sandboxSts) => {
  const productionStatus = productionSts?.toLowerCase(),
    mphApprovalStatus = mphApprovalSts?.toLowerCase(),
    sandboxStatus = sandboxSts?.toLowerCase();
  let helperText = '',
    isError = false,
    fhirStatus = 'pending';
  if (sandboxStatus === 'pending' || typeof productionStatus === 'undefined') {
    fhirStatus = undefined;
  } else if (productionStatus === 'applied' || productionStatus === 'pending') {
    helperText = 'pending from Organization admin';
  } else if (productionStatus === 'denied' || productionStatus === 'revoked') {
    fhirStatus = productionStatus;
    helperText = `${productionStatus} from Organization Admin`;
    isError = true;
  } else if (
    mphApprovalStatus === 'pending' ||
    mphApprovalStatus === 'applied'
  ) {
    helperText = 'pending from MPH Admin';
  } else if (
    mphApprovalStatus === 'denied' ||
    mphApprovalStatus === 'revoked'
  ) {
    helperText = `${
      mphApprovalStatus === 'denied' ? 'Declined' : mphApprovalStatus
    } from MPH Admin`;
    isError = true;
    fhirStatus = mphApprovalStatus;
  } else if (
    mphApprovalStatus === 'approved' &&
    productionStatus === 'approved'
  ) {
    fhirStatus = 'approved';
  }
  return { helperText, isError, fhirStatus };
};

const FhirAppChip = ({ helperText, isError }) => {
  return (
    helperText && (
      <Chip
        variant="outlined"
        size="small"
        label={helperText}
        style={{
          color: isError ? 'red' : '#005DB9',
          fontWeight: 500,
          fontSize: '11px',
          textTransform: 'uppercase',
          marginTop: '10px',
          borderColor: isError ? 'red' : '#005DB9',
          display: 'flex',
          width: 'fit-content',
        }}
      />
    )
  );
};

export const StatusContainer = ({
  appStatus,
  deniedOn,
  approvedOn,
  clientId,
  containerName,
  requestedOn,
  handleProdAccess,
  sandboxStatus,
  onDenyRequest,
  productionStatus,
  apiKey,
  isSandbox,
  declinedReason,
  applicationEnvId,
  revokeReason,
  mphApprovalStatus,
  isFhirApp,
  permissions,
}) => {
  const getStatus = status => {
    switch (status?.toLowerCase()) {
      case 'applied':
      case 'pending':
        return 'Pending';
      case 'revoked':
        return 'Revoked';
      case 'denied':
      case 'declined':
        return 'Declined';
      case 'approved':
        return 'Approved';
      default:
        break;
    }
  };

  const { helperText, isError, fhirStatus } = fhirAppStatus(
    appStatus,
    mphApprovalStatus,
    sandboxStatus
  );

  const { enqueueSnackbar } = useSnackbar();

  const status = isFhirApp && !isSandbox ? fhirStatus : appStatus;

  const checkRevoke = () => {
    return permissions.APPROVE && isSandbox
      ? productionStatus
        ? productionStatus?.toLowerCase() !== 'approved'
        : true
      : status?.toLowerCase() !== 'approved';
  };

  const copyToClipboard = (content, type) => {
    navigator.clipboard.writeText(content);
    enqueueSnackbar(`${type} copied!`, { variant: 'success' });
  };

  return (
    <div className={`${containerName}`}>
      <div>
        <div>
          <span className="title">
            {containerName?.includes('sandbox') ? 'Sandbox' : 'Production'} key
          </span>
          {status && (
            <>
              <span
                style={{
                  borderRadius: '50%',
                  height: '6px',
                  width: '6px',
                  backgroundColor:
                    status?.toLowerCase() === 'approved'
                      ? '#288719'
                      : status?.toLowerCase() === 'revoked' ||
                        status?.toLowerCase() === 'denied'
                      ? '#DB0032'
                      : '#FB8900',
                  display: 'inline-block',
                  marginRight: '8px',
                  marginBottom: '3px',
                }}
              ></span>
              <span className="status-title">{getStatus(status)}</span>
            </>
          )}
        </div>
        <div>
          {requestedOn && (
            <>
              <span className="date-header">Requested on:</span>{' '}
              <span className="date">
                {' '}
                {moment(requestedOn).format('MM/DD/YYYY')}
              </span>
            </>
          )}
          {status?.toLowerCase() === 'revoked' ||
            status?.toLowerCase() === 'denied' ||
            (status?.toLowerCase() === 'approved' && (
              <>
                <span className="date-header approved">
                  {status?.toLowerCase() === 'denied'
                    ? 'Declined on'
                    : status?.toLowerCase() === 'revoked'
                    ? 'Revoked on'
                    : 'Approved on:'}{' '}
                </span>
                <span className="date">
                  {moment(
                    status?.toLowerCase() === 'revoked' ||
                      status?.toLowerCase() === 'denied'
                      ? deniedOn
                      : approvedOn
                  ).format('MM/DD/YYYY')}
                </span>
              </>
            ))}
        </div>
      </div>
      <div className="subfields">
        <div className="left-panel">
          {!status && containerName?.includes('production') && (
            <>
              <div className="no-status">
                {permissions.APPROVE
                  ? `${
                      containerName?.includes('sandbox')
                        ? 'Sandbox'
                        : 'Production'
                    } client ID will be generated once you approve the request`
                  : `Raise access for production key once sandbox request has been
                approved by the admin`}
              </div>
            </>
          )}
          {(status?.toLowerCase() === 'pending' ||
            status?.toLowerCase() === 'applied') && (
            <>
              <div className="applied-header">
                {!permissions.APPROVE
                  ? `You can access the client ID once the application is approved by the admin`
                  : `${
                      containerName?.includes('sandbox')
                        ? 'Sandbox'
                        : 'Production'
                    }  client ID will be generated once you approve the request`}
              </div>
            </>
          )}
          {(status?.toLowerCase() === 'revoked' ||
            status?.toLowerCase() === 'denied') && (
            <>
              <div className="denied-header">
                {
                  declinedReason
                    ?.filter(
                      item => item?.applicationEnvDetailsId === applicationEnvId
                    )
                    ?.at(-1)?.reason
                }
                {
                  revokeReason?.filter(
                    item => item?.applicationEnvDetailsId === applicationEnvId
                  )?.[0]?.reason
                }
              </div>
              {permissions.CREATE &&
                status?.toLowerCase() === 'denied' &&
                sandboxStatus?.toLowerCase() !== 'revoked' && (
                  <Button
                    className="req-btn"
                    onClick={() =>
                      handleProdAccess(isSandbox ? 'sandbox' : 'prod')
                    }
                  >
                    Reinitiate access
                  </Button>
                )}
            </>
          )}
          {!isSandbox &&
            isFhirApp &&
            requestedOn &&
            status?.toLowerCase() !== 'revoked' && (
              <FhirAppChip
                productionStatus={appStatus}
                mphApprovalStatus={mphApprovalStatus}
                helperText={helperText}
                isError={isError}
              />
            )}

          {!requestedOn && permissions.CREATE && (
            <>
              <Button
                className="req-btn"
                onClick={
                  !['applied', 'pending', 'revoked', 'denied'].includes(
                    sandboxStatus?.toLowerCase()
                  )
                    ? () => handleProdAccess('prod')
                    : null
                }
                disabled={['applied', 'pending', 'revoked', 'denied'].includes(
                  sandboxStatus?.toLowerCase()
                )}
              >
                Request access
              </Button>
            </>
          )}
          {status?.toLowerCase() === 'approved' && (
            <>
              {(clientId || apiKey) && (
                <>
                  <div className="clientid">
                    {clientId ? 'Client ID' : 'API Key'}
                    <IconButton
                      onClick={() =>
                        copyToClipboard(
                          clientId || apiKey,
                          clientId ? 'Client ID' : 'API Key'
                        )
                      }
                      style={{
                        height: '32px',
                        width: '32px',
                      }}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <div className="id">{clientId ?? apiKey}</div>
                </>
              )}
              {checkRevoke() && (
                <Button
                  className="req-btn"
                  onClick={() => onDenyRequest(containerName)}
                  disabled={
                    isSandbox && productionStatus?.toLowerCase() === 'pending'
                  }
                >
                  Revoke access
                </Button>
              )}
            </>
          )}
        </div>
        <div className="right-panel">
          <img
            className="notepad-img"
            src={
              status?.toLowerCase() === 'revoked' ||
              status?.toLowerCase() === 'denied'
                ? 'https://mpowered-assets.s3.us-east-2.amazonaws.com/notepad.svg'
                : status?.toLowerCase() === 'approved'
                ? 'https://mpowered-assets.s3.us-east-2.amazonaws.com/Image+-+Recommended.svg'
                : 'https://mpowered-assets.s3.us-east-2.amazonaws.com/hourglass.svg'
            }
            alt="status-icon"
          />
        </div>
      </div>
    </div>
  );
};
