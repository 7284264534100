import React, { useState, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import BottomDrawerComponent from '../MembersTab/BottomDrawerComponent';
import { graphqlAPI,DDUIHost } from '../../../config/apiConfig';
import ConfirmationModal from './ConfirmationModal';
import moment from 'moment';

const ConsentTrigger=({data,exitDDUI, ...props})=>{
const uploadLink = graphqlAPI + '/graphql';
const iframeRef = useRef(null);
const [drawer, setDrawer] = useState(true);
const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

const onCloseDrawer = () => {
  setOpenConfirmationModal(true);
};


  let granteeArray =[];

  if(data[data.length-1]?.workflow === "CMDEViewConsent")
 {  
  // let formattedDate = moment(data[data.length-1]?.data[0]?.consentRequestedOn).format('MMM DD, YYYY');
  granteeArray = [
    { key: 'Member name', value: data[data.length-1]?.data[0]?.fullName },
    { key: 'Requested on', value: moment(data[data.length-1]?.data[0]?.consentRequestedOn).format('MMM DD, YYYY')},
    {
      key: 'Email ID',
      value: data[data.length-1]?.data[0]?.email,
    },
    { key: 'Phone number', value: data[data.length-1]?.data[0]?.phoneNumber || "-" },
    { key: 'Type of consent', value: 'Consent to share' },
    { key: 'Status', value: data[data.length-1]?.data[0]?.consentStatus },
    // { key: 'Decline Message', value: data[data.length-1]?.data[0]?.declineMessage },
  ];
}
 
const sendMessage = () => {
    const token = localStorage.getItem("MPOWERED_AUTH_TOKEN");
    const tokenObject = {
      jwt: token,
      url: uploadLink,
      flowType: 'CMDEInitiateConsent', //ConsentUserToUser    -   CMDEInitiateConsent   -  GiveConsent
      InitiatorId: '',
      ConsentId: '',
      GranteeInfo: JSON.stringify(granteeArray),
      data:{
        data:data,
      },
      // GranteeInfo: JSON.stringify(granteeArray),
    };
    const iframeWindow = iframeRef.current;
    if (!iframeWindow) {
      return;
    }
    iframeWindow.sendMessage(tokenObject);
  };

  const fetchFormData = () => {
    sendMessage();
  };

return (
  <>
    <BottomDrawerComponent open={drawer} onClose={onCloseDrawer}>
      
      <IframeResizer
        forwardRef={iframeRef}
        src={DDUIHost} //Provide the port number where ddui repo is running either local or remote
        style={{ width: '1px', minWidth: '100%', border: 'none' }}
        onLoad={fetchFormData}
      />

    </BottomDrawerComponent>

{openConfirmationModal && (
  <ConfirmationModal
    openConfirmationModal={openConfirmationModal}
    exitDDUI={() => exitDDUI()}
    setOpenConfirmationModal={setOpenConfirmationModal}
  />
)}
</>
);
}

export default ConsentTrigger;