import BaseApiService from './BaseApiService';

function _uploadLogo(data, orgName, otherHeaders) {
  return BaseApiService.post(
    `/organization/upload/${orgName}`,
    null,
    data,
    otherHeaders
  );
}

function _editLogo(data, orgName, otherHeaders) {
  return BaseApiService.put(
    `/organization/editImage/${orgName}`,
    null,
    data,
    otherHeaders
  );
}

function _getLogo(orgName) {
  return BaseApiService.get(`/organization/getImage/${orgName}`);
}

function _getLogoUnauth(orgId) {
  return BaseApiService.get(`/unauth/organization/getImage/${orgId}`);
}

function _createOrganization(data) {
  return BaseApiService.post('/organization', null, data);
}

function _getOrganizationById(orgId) {
  return BaseApiService.get(`/organization/${orgId}`);
}

function _getAllOrganizations(data) {
  return BaseApiService.get('/organization');
}

function _getUserCount(orgId){
  return BaseApiService.get(`/organization/${orgId}/count-user`);
}

function _searchOrganizations(params) {
  return BaseApiService.get('/organization/search', params);
}

function _updateOrganization(data) {
  return BaseApiService.put('/organization', null, data);
}

function _deleteOrganization(orgId) {
  return BaseApiService.delete(`/organization/${orgId}`);
}

function _createOrgAddress(data) {
  return BaseApiService.post('/organization/address', null, data);
}

function _updateOrgAddress(data) {
  return BaseApiService.put('/organization/address', null, data);
}

function _deleteOrgAddress(addressId) {
  return BaseApiService.delete(`/organization/address/${addressId}`);
}

function _createOrgContact(data) {
  return BaseApiService.post('/organization/contact', null, data);
}

function _updateOrgContact(data) {
  return BaseApiService.put('/organization/contact', null, data);
}

function _deleteOrgContact(contactId) {
  return BaseApiService.delete(`/organization/contact/${contactId}`);
}

function _approveOrganization(orgId) {
  return BaseApiService.put(`/organization/approve/${orgId}`);
}

function _mphOrgCheck(orgId) {
  return BaseApiService.get(`/organization/check-org-id/${orgId}`);
}

function _getAllOrganizationsRequest({
  status,
  searchText = '',
  offset,
  limit = 10,
}) {
  return BaseApiService.get(
    `/organization/requests?approved=${status}&searchText=${searchText}&offset=${offset}&limit=${limit}`
  );
}

function _getAllOrganizationsRequestDetail({ orgId }) {
  return BaseApiService.get(`/organization/requests/${orgId}`);
}

function _getApproveOrganizationRequest({
  approve,
  organizationDetails,
  userInfo,
}) {
  const { orgId } = organizationDetails;
  const { userId } = userInfo;

  const payload = {
    id: organizationDetails?.orgId,
    typeCode: organizationDetails?.typeCode || '',
    name: organizationDetails?.orgName || '',
    ein: organizationDetails?.ein || '',
    db: organizationDetails?.db || '',
    fhirResourceUrl: organizationDetails?.fhirResourceUrl || '',
    fhirOrgId: organizationDetails?.fhirOrgId || '',
    imgUrl: organizationDetails?.imgUrl || '',
    isActive: approve || 0,
    isDeleted: 0,
    isApproved: approve || 0,
    userId: String(organizationDetails?.orgAdminId),
  };


  return BaseApiService.put(
    `/organization/requests/${orgId}/${approve}`,
    null,
    payload
  );
}

function _getAllDomains({ orgId }) {
  return BaseApiService.get(`/organization/${orgId}/domain`);
}

function _createDomain({ orgId, payload }) {
  return BaseApiService.post(`/organization/${orgId}/domain/`, null, payload);
}

function _updateDomain({ orgId, payload, domainId }) {
  return BaseApiService.put(
    `/organization/${orgId}/domain/${domainId}`,
    null,
    payload
  );
}

function _deleteDomain({ orgId, domainId }) {
  return BaseApiService.delete(`/organization/${orgId}/domain/${domainId}`);
}

function _getOrganizationAndDeveloperDetails({orgId,appId}){
  return BaseApiService.get(`/organization/${orgId}/application/developer/${appId}`);
}

const OrganizationApi = {
  uploadLogo: _uploadLogo,
  editLogo: _editLogo,
  getLogo: _getLogo,
  getLogoUnauth: _getLogoUnauth,
  getUserCount: _getUserCount,
  createOrganization: _createOrganization,
  getOrganizationById: _getOrganizationById,
  getAllOrganizations: _getAllOrganizations,
  updateOrganization: _updateOrganization,
  deleteOrganization: _deleteOrganization,
  createOrgAddress: _createOrgAddress,
  updateOrgAddress: _updateOrgAddress,
  deleteOrgAddress: _deleteOrgAddress,
  createOrgContact: _createOrgContact,
  updateOrgContact: _updateOrgContact,
  deleteOrgContact: _deleteOrgContact,
  searchOrganizations: _searchOrganizations,
  approveOrganization: _approveOrganization,
  mphOrgCheck: _mphOrgCheck,
  getAllOrganizationsRequest: _getAllOrganizationsRequest,
  getAllOrganizationsRequestDetail: _getAllOrganizationsRequestDetail,
  getApproveOrganizationRequest: _getApproveOrganizationRequest,
  getAllDomains: _getAllDomains,
  createDomain: _createDomain,
  updateDomain: _updateDomain,
  deleteDomain: _deleteDomain,
  getOrganizationAndDeveloperDetails:_getOrganizationAndDeveloperDetails
};

export default OrganizationApi;
