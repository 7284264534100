import { useRef, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import GridHeaderActions from './GridHeaderActions';
import Card from '../Card/Card';

function DataGrid({
  style = {},
  renderHeader,
  onGridReady,
  selectTableData,
  rowData,
  hideHeaderActions = false,
  apiLoading = false,
  onRowSelected = () => {},
  onRowDataChanged = () => {},
  ...otherProps
}) {
  // const [selectedRows, setSelectedRows] = useState([]);
  // const selectedRowCount = selectedRows.length;

  const _gridApi = useRef();
  const nextPageSelected = useRef();
  const _gridColumnApi = useRef();

  const selectAllFlag = useRef(false);

  const handleSelectAll = () => {
    let parentDiv = document.querySelector('[col-id="NameID"]');
    let childDiv = parentDiv?.querySelector('input[type=checkbox]');

    childDiv?.addEventListener('click', () => {
      if(selectAllFlag.current){
        onRowSelected([], nextPageSelected.current, 'DESELECT_ALL');
      }
      let prevValue = selectAllFlag.current;
      selectAllFlag.current = !prevValue;
    });
  };

  const onGridReadyHandler = params => {
    _gridApi.current = params.api;
    _gridColumnApi.current = params.columnApi;

    if (otherProps.membersPage) {
      handleSelectAll();
    }

    if (onGridReady) {
      onGridReady(params);
    }
  };

  useEffect(() => {
    if (selectAllFlag.current && !apiLoading) {
      setTimeout(() => _gridApi.current?.selectAll(), 0);
    }
  }, [apiLoading]);

  const onSelectionChangedHandler = () => {
    const rows = _gridApi.current.getSelectedRows();
    // setSelectedRows(rows);
    if (!otherProps.membersPage && selectTableData) {
      selectTableData(rows);
    }

    if (
      selectAllFlag.current &&
      rows.length !== rowData.length &&
      otherProps.membersPage
    ) {
      selectAllFlag.current = false;
    }

    // if (onSelectionChanged) {
    //   onSelectionChanged();
    // }
  };

  const onRowDataChangedHandler = event => {
    nextPageSelected.current = true;
    if (onRowDataChanged) {
      onRowDataChanged(event);
      setTimeout(() => {
        nextPageSelected.current = false;
      }, 0);
    }
  };

  const onRowSelectedHandler = event => {
    if (onRowSelected) {
      const rows = _gridApi.current.getSelectedRows();
      if (
        selectAllFlag.current &&
        rows.length !== rowData.length &&
        otherProps.membersPage
      ) {
        selectAllFlag.current = false;
      }
      if (selectAllFlag.current && otherProps.membersPage) {
        const rows = _gridApi.current.getSelectedRows();
        onRowSelected(rows, nextPageSelected.current, 'SELECT_ALL');
        return;
      }
      onRowSelected(event, nextPageSelected.current);
    }
  };

  return (
    <Card style={{ padding: '4px 8px', margin: 0, marginBottom: 20 }}>
      {!hideHeaderActions && (
        <GridHeaderActions>
          {/* {selectedRowCount > 0 && renderHeader ? (
            <>{renderHeader({ selectedRows })}</>
          ) : (
            <div></div>
          )} */}
          <div
            style={{
              fontSize: 14,
            }}>
            {rowData.length > 0 ? `Showing ${rowData.length} items` : ''}
          </div>
        </GridHeaderActions>
      )}

      <div
        className="ag-theme-custom-react"
        // className="ag-theme-custom-react"
        style={{
          height: '75vh',
          width: '100%',
          minHeight: 100,
          // flex: 1,
          ...style,
        }}>
        <AgGridReact
          onGridReady={onGridReadyHandler}
          onSelectionChanged={onSelectionChangedHandler}
          rowData={rowData}
          headerHeight={46}
          onRowDataChanged={onRowDataChangedHandler}
          onRowSelected={onRowSelectedHandler}
          {...otherProps}></AgGridReact>
      </div>
    </Card>
  );
}

export default DataGrid;
