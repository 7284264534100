import VerifyPage from 'containers/VerifyPage';
import NotFoundView from 'containers/errors/NotFoundView';
import { Redirect, Route, Switch } from 'react-router-dom';
// import LoginView from 'containers/Auth/LoginView';
import DeveloperView from 'containers/Auth/DeveloperView';
import Dashboard from 'containers/Dashboard/DashboardPage';
import Applications from 'containers/Applications/ApplicationsPage';
import APIOverview from 'containers/APIOverview/APIOverviewPage';
import Sandbox from 'containers/Sandbox/SandboxPage';
import Documentation from 'containers/Documentation/DocumentationPage';
import PriceListSummary from 'containers/PriceListSummary/PriceListSummary';
import PriceListDetails from 'containers/PriceListSummary/PriceListDetails';
// import DevSettingsPage from 'containers/Settings/DevSettingsPage';
// import AdminSettingsPage from 'containers/Settings/AdminSettingsPage';
import OrganizationDetailPage from 'containers/ManageOrg/OrganizationDetailPage';
import QuestionBank from 'containers/Assessments/QuestionBank';
import AllAssessments from 'containers/Assessments/AllAssessments';
import HomePage from 'containers/Home/HomePage';
import ManageUsersPage from 'containers/ManageUsers/ManageUsers';
import ManageLobPage from 'containers/ManageLOB/ManageLobPage';
import MembersPage from 'containers/Members/MembersPage';
import MemberDetailPage from 'containers/Members/MemberDetailPage';
import LandingPage from 'containers/Auth/LandingPage';
import AddEditLobPage from 'containers/ManageLOB/AddEditLobPage';
import Configurations from 'containers/Configurations/Configurations';
import DocumentUpload from 'containers/Configurations/DocumentUpload';
import Images from 'containers/Configurations/Images';
import Logs from 'containers/Configurations/Logs';
import Support from 'containers/Configurations/Support';
import { useKeycloak } from '@react-keycloak/web';
import { RESOURCE_NAMES, APP_ROLES, PERMISSION_CODES } from 'shared/constants';
import { useUserPermissions } from 'utils/userPermissions';
import PartnerApi from 'services/api/PartnerApi';
import { useState, useCallback, useEffect } from 'react';
import RulesDetailsPage from 'containers/Rules/RulesDetailPage';
import CriteriaCreation from 'containers/Rules/tab/CriteriaTabComponent/CriteriaCreation';
// import RulesCreation from 'containers/Rules/tab/RulesTabComponent/RulesCreation';
import CreateRuleModal from 'containers/Rules/tab/RulesTabComponent/CreateRuleModal';
import TextEditor from 'containers/Rules/tab/textEditor';
import CampaignsPage from 'containers/Campaigns/CampaignsPage';
import Index from 'containers/SignUp';
import DemoDashboard from 'containers/DemoDashboard/DemoDashboard';
import DevSignupLanding from 'containers/DeveloperSignup/DevSignupLanding';
import UsersPage from 'containers/ManageUsers/UsersPage';
import ThirdPartyDeveloperPage from 'containers/ThirdPartyDev/ThirdPartyDeveloperPage';
import UpdatePassword from 'containers/Auth/updatePassword';
import DashboardPage from 'containers/SolutionDashboard/Dashboard/DashboardPage';
import ManageRolePage from 'containers/SolutionDashboard/ManageRole/ManageRolePage';
import UserPage from 'containers/SolutionDashboard/Users/UserPage';
import Listing from 'containers/Roles/Listing';
import CMDE from 'containers/CMDE/CMDE';
import CustomReport from 'containers/DemoDashboard/CustomReport';
import { useDispatch, useSelector } from 'react-redux';
import { useGetManageOrgSolutionId } from 'shared/utility';
import CareDashboard from 'containers/CareDashboard';
import CarePlan from 'containers/CarePlan/CarePlan';
import BasicTabs from 'containers/DynamicRenderOfRecords/ServicesTabs';
import ReusableCategoriesContainer from 'containers/DynamicRenderOfRecords/ReusableCategoriesContainer';
import Records from 'containers/DynamicRenderOfRecords/Records';
import SelfReporting from 'containers/DynamicRenderOfRecords/SelfReporting';
import axios from 'axios';
import { updateTemplate } from 'containers/DynamicRenderOfRecords/dynamicTemplate';
import CarePlanForm from 'containers/CarePlan/CarePlanForm';

function AdminRoute({ render, isAuthenticated, roles, ...rest }) {
  const { keycloak } = useKeycloak();

  const isAuthorized = (appRoles = []) => {
    if (keycloak && appRoles) {
      if (appRoles.length === 0) {
        return true;
      }
      return appRoles.some(r => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && isAuthorized(roles) ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function DeveloperRoute({ render, isAuthenticated, resources, ...rest }) {
  const [data, setData] = useState(null);

  const {
    computedMatch: {
      params: { orgId },
    },
  } = rest;

  const getMenuItems = useCallback(() => {
    async function getMenu() {
      try {
        const details = await PartnerApi.getMenuItems(orgId);
        const hrefArray = [];
        for (let x in details) {
          let href = details[x].href.slice(1);
          hrefArray.push(href);
        }
        setData(hrefArray);
      } catch (e) {
        console.log(e);
      }
    }
    getMenu();
  }, [orgId]);

  useEffect(() => getMenuItems(), [getMenuItems]);

  const { checkDevPermission } = useUserPermissions();

  let affiliateId = 'DEV';

  const hasResourcePermissions = (appResources = []) => {
    if (appResources && appResources.length > 0) {
      return appResources.some(r => {
        const devPermissions = checkDevPermission(orgId, affiliateId, r);

        return devPermissions;
      });
    }

    return true;
  };

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && hasResourcePermissions(resources) ? (
          data &&
          (data.length > 0 ? (
            data.includes(rest.path.split('/')[6]) ? (
              render(props)
            ) : (
              <Redirect to="/notfound" />
            )
          ) : (
            render(props)
          ))
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function ComplianceManagerRoute({
  render,
  isAuthenticated,
  resources,
  ...rest
}) {
  const [data, setData] = useState(null);

  const {
    computedMatch: {
      params: { orgId, affiliateId },
    },
  } = rest;

  const getMenuItems = useCallback(() => {
    async function getMenu() {
      try {
        const details = await PartnerApi.getMenuItems(orgId);
        const hrefArray = [];
        for (let x in details) {
          let href = details[x].href.slice(1);
          hrefArray.push(href);
        }
        setData(hrefArray);
      } catch (e) {
        console.log(e);
      }
    }
    getMenu();
  }, [orgId]);

  useEffect(() => getMenuItems(), [getMenuItems]);

  const { checkDevPermission } = useUserPermissions();

  const hasResourcePermissions = (appResources = []) => {
    if (appResources && appResources.length > 0) {
      return appResources.some(r => {
        const devPermissions = checkDevPermission(orgId, affiliateId, r);

        return devPermissions;
      });
    }

    return true;
  };

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && hasResourcePermissions(resources) ? (
          data &&
          (data.length > 0 ? (
            data.includes(rest.path.split('/')[6]) ? (
              render(props)
            ) : (
              <Redirect to="/notfound" />
            )
          ) : (
            render(props)
          ))
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function ITAdminRoute({ render, isAuthenticated, ...rest }) {
  const { checkITAdminPermission } = useUserPermissions();
  const hasITAdminPermission = () => {
    return checkITAdminPermission();
  };
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && hasITAdminPermission() ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function ProtectedRoute({ render, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function PublicOnlyRoute({
  render,
  isAuthenticated,
  roles,
  path,
  isDeveloper,
  onLogin,
  ...rest
}) {
  if (isDeveloper === 'dev' && path === '/login') {
    return (
      <Route
        {...rest}
        render={props =>
          !isAuthenticated ? (
            <DevSignupLanding {...props} onLogin={onLogin} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  } else if (isDeveloper === 'other' && path === '/register') {
    return (
      <Route
        {...rest}
        render={props =>
          !isAuthenticated ? (
            <LandingPage {...props} onLogin={onLogin} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function RootRoute({ isAuthenticated, onLogin, roles }) {
  const dispatch = useDispatch();
  const getTemplateForDynamicCategories = useCallback(() => {
    try {
      axios.
      get("https://mph-marketplace.s3.us-west-2.amazonaws.com/services/partner-portal-config.json")
      .then((res) => {
        dispatch(updateTemplate(res?.data));
      })
    } catch (error) {
      console.log(
        '\n url',
        process.env.CDN_URL_FOR_TEMPLATE,
        error,
      );
    }
  }, []);

  useEffect(() => {
    getTemplateForDynamicCategories();
  }, [getTemplateForDynamicCategories]);

  return (
    <Switch>
      <PublicOnlyRoute
        exact
        path="/login"
        isAuthenticated={isAuthenticated}
        onLogin={onLogin}
        isDeveloper={
          window.location.href.includes('developer') ? 'dev' : 'other'
        }
        render={props => <LandingPage {...props} onLogin={onLogin} />}
        // render={props => <DeveloperView {...props} onLogin={onLogin}/>}
      />
      <PublicOnlyRoute
        exact
        path="/:dnsname/developersignup"
        isAuthenticated={isAuthenticated}
        render={props => <DeveloperView {...props} onLogin={onLogin} />}
      />
      <PublicOnlyRoute
        exact
        path="/:dnsname/developersignup"
        isAuthenticated={isAuthenticated}
        render={props => <DeveloperView {...props} onLogin={onLogin} />}
      />
      <PublicOnlyRoute
        exact
        path="/verify"
        isAuthenticated={isAuthenticated}
        render={props => <VerifyPage {...props} onLogin={onLogin} />}
      />
      <PublicOnlyRoute
        exact
        path="/register"
        isAuthenticated={isAuthenticated}
        onLogin={onLogin}
        isDeveloper={
          window.location.href.includes('developer') ? 'dev' : 'other'
        }
        render={props => <DevSignupLanding {...props} onLogin={onLogin} />}
      />
      <PublicOnlyRoute
        exact
        path="/update-password"
        isAuthenticated={isAuthenticated}
        render={props => <UpdatePassword {...props} />}
      />
      <Route exact path={'/signup'} component={Index} />
      {/* <Route
        exact
        path={'/login'}
        render={props => <LandingPage {...props} onLogin={onLogin} />}
      /> */}
      {/* <Route
        exact
        path={'/register'}
        render={props => <DevSignupLanding {...props} onLogin={onLogin} />}
      /> */}
      <Route exact path={'/notfound'} component={NotFoundView} />
      <ProtectedRoute
        exact
        path="/"
        isAuthenticated={isAuthenticated}
        render={props => <HomePage {...props} />}
      />
      <ProtectedRoute
        exact
        path="/developer"
        isAuthenticated={isAuthenticated}
        render={props => <Dashboard {...props} />}
      />
      <ProtectedRoute
        exact
        path="/dashboard"
        isAuthenticated={isAuthenticated}
        render={props => <DashboardPage {...props} />}
      />
      <ProtectedRoute
        exact
        path="/managerole"
        isAuthenticated={isAuthenticated}
        render={props => <ManageRolePage {...props} />}
      />{' '}
      <ProtectedRoute
        exact
        path="/organization/:orgId/user"
        isAuthenticated={isAuthenticated}
        render={props => <UserPage {...props} />}
      />
      <ProtectedRoute
        exact
        path="/list/:orgId/users"
        isAuthenticated={isAuthenticated}
        render={props => <UserPage {...props} />}
      />
      <AdminRoute
        exact
        path="/admin/organization/:orgId/detail"
        isAuthenticated={isAuthenticated}
        render={props => <OrganizationDetailPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/detail"
        isAuthenticated={isAuthenticated}
        render={props => <OrganizationDetailPage {...props} />}
      />
      {/* <AdminRoute
        exact
        path="/admin/organization/:orgId/:userType/manage-users"
        isAuthenticated={isAuthenticated}
        render={props => <ManageUsersPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      /> */}
      <ITAdminRoute
        exact
        path="/organization/:orgId/third-party-developer"
        isAuthenticated={isAuthenticated}
        render={props => <ThirdPartyDeveloperPage {...props} />}
      />{' '}
      <AdminRoute
        exact
        path="/admin/organization/:orgId/third-party-developer"
        isAuthenticated={isAuthenticated}
        render={props => <ThirdPartyDeveloperPage {...props} />}
      />
      <AdminRoute
        exact
        path="/admin/organization/:orgId/manage-users"
        isAuthenticated={isAuthenticated}
        render={props => <UsersPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/manage-users"
        isAuthenticated={isAuthenticated}
        render={props => <UsersPage {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/:userType/manage-users"
        isAuthenticated={isAuthenticated}
        render={props => <ManageUsersPage {...props} />}
      />
      <AdminRoute
        exact
        path="/admin/organization/:orgId/manage-lobs"
        isAuthenticated={isAuthenticated}
        render={props => <ManageLobPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/manage-lobs"
        isAuthenticated={isAuthenticated}
        render={props => <ManageLobPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <AdminRoute
        exact
        path="/admin/organization/:orgId/manage-lobs/lob/:lobId"
        isAuthenticated={isAuthenticated}
        render={props => <AddEditLobPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/manage-lobs/lob/:lobId"
        isAuthenticated={isAuthenticated}
        render={props => <AddEditLobPage {...props} />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <AdminRoute
        exact
        path="/admin/organization/:orgId/manage-lobs/lob/:lobId/view"
        isAuthenticated={isAuthenticated}
        render={props => <AddEditLobPage {...props} isViewOnly />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/manage-lobs/lob/:lobId/view"
        isAuthenticated={isAuthenticated}
        render={props => <AddEditLobPage {...props} isViewOnly />}
        roles={[APP_ROLES.MPH_ADMIN]}
      />
      {/* old member functionality is replcaed by name CMDE so the route is only
      changes but the unctionaliry wise no change its same as member tab */}
      <ITAdminRoute
        exact
        path="/organization/:orgId/cmde"
        isAuthenticated={isAuthenticated}
        render={props => <MembersPage {...props} />}
      />
      {/* for the route member we are rendering CMDE component which is derived from
      original MembersPage component */}
      <ITAdminRoute
        exact
        path="/organization/:orgId/members"
        isAuthenticated={isAuthenticated}
        render={props => <CMDE {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/members/records"
        isAuthenticated={isAuthenticated}
        render={props => <BasicTabs {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/members/records/:categoryName"
        isAuthenticated={isAuthenticated}
        render={props => <Records {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/members/records/:categoryName/:type"
        isAuthenticated={isAuthenticated}
        render={props => <SelfReporting {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/members/member/:memberId/:memberName"
        isAuthenticated={isAuthenticated}
        render={props => <MemberDetailPage {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/rules"
        isAuthenticated={isAuthenticated}
        render={props => <RulesDetailsPage {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/campaigns"
        isAuthenticated={isAuthenticated}
        render={props => <CampaignsPage {...props} />}
      />
      <ITAdminRoute
        path="/organization/:orgId/assessment/*"
        isAuthenticated={isAuthenticated}
        render={props => <QuestionBank {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/configurations"
        isAuthenticated={isAuthenticated}
        render={props => <Configurations {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/care_dashboard"
        isAuthenticated={isAuthenticated}
        render={props => <CareDashboard {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/configurations/documentUpload"
        isAuthenticated={isAuthenticated}
        render={props => <DocumentUpload {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/configurations/support"
        isAuthenticated={isAuthenticated}
        render={props => <Support {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/configurations/logs"
        isAuthenticated={isAuthenticated}
        render={props => <Logs {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/configurations/reports"
        isAuthenticated={isAuthenticated}
        render={props => <DemoDashboard {...props} />}
      />
      <ITAdminRoute
        path="/organization/:orgId/customReport"
        isAuthenticated={isAuthenticated}
        render={props => <CustomReport {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/configurations/images"
        isAuthenticated={isAuthenticated}
        render={props => <Images {...props} />}
      />
      <ITAdminRoute
        exact
        path="/assessment/*"
        isAuthenticated={isAuthenticated}
        render={props => <QuestionBank {...props} />}
      />
      <ITAdminRoute
        exact
        path="/allAssessments/*"
        isAuthenticated={isAuthenticated}
        render={props => <AllAssessments {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/allCareplans"
        isAuthenticated={isAuthenticated}
        render={props => <CarePlan {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/createCarePlan"
        isAuthenticated={isAuthenticated}
        render={props => <CarePlanForm {...props} />}
      />
      <ITAdminRoute
        exact
        path="/allAssessments"
        isAuthenticated={isAuthenticated}
        render={props => <AllAssessments {...props} />}
      />
      <ITAdminRoute
        exact
        path="/allAssessment"
        isAuthenticated={isAuthenticated}
        render={props => <AllAssessments {...props} />}
      />
      <ITAdminRoute
        exact
        path="/viewDetails/:orgId/*"
        isAuthenticated={isAuthenticated}
        render={props => <AllAssessments {...props} />}
      />
      <ITAdminRoute
        exact
        path="/create"
        isAuthenticated={isAuthenticated}
        render={props => <AllAssessments {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/allAssessments/*"
        isAuthenticated={isAuthenticated}
        render={props => <AllAssessments {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/rules/create-criteria"
        isAuthenticated={isAuthenticated}
        render={props => <CriteriaCreation {...props} />}
      />
      <ITAdminRoute
        exact
        path="/organization/:orgId/rules/create-rules"
        isAuthenticated={isAuthenticated}
        render={props => <CreateRuleModal {...props} />}
      />
      <ITAdminRoute
        exact
        path="/roles"
        isAuthenticated={isAuthenticated}
        render={props => <Listing {...props} />}
      />
      <AdminRoute
        exact
        path="/organization/texteditor"
        isAuthenticated={isAuthenticated}
        render={props => <TextEditor {...props} />}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/dashboard"
        isAuthenticated={isAuthenticated}
        render={props => <Dashboard {...props} />}
        resources={[RESOURCE_NAMES.DEV_DASHBOARD]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/dashboard"
        isAuthenticated={isAuthenticated}
        render={props => <Dashboard {...props} />}
        resources={[RESOURCE_NAMES.DEV_DASHBOARD]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/applications"
        isAuthenticated={isAuthenticated}
        render={props => <Applications {...props} />}
        resources={[RESOURCE_NAMES.DEV_APPLICATIONS]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/applications"
        isAuthenticated={isAuthenticated}
        render={props => <Applications {...props} />}
        resources={[RESOURCE_NAMES.DEV_APPLICATIONS]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/api-overview"
        isAuthenticated={isAuthenticated}
        render={props => <APIOverview {...props} />}
        resources={[RESOURCE_NAMES.DEV_API_OVERVIEW]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/api-overview"
        isAuthenticated={isAuthenticated}
        render={props => <APIOverview {...props} />}
        resources={[RESOURCE_NAMES.DEV_API_OVERVIEW]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/sandbox"
        isAuthenticated={isAuthenticated}
        render={props => <Sandbox {...props} />}
        resources={[RESOURCE_NAMES.DEV_SANDBOX]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/sandbox"
        isAuthenticated={isAuthenticated}
        render={props => <Sandbox {...props} />}
        resources={[RESOURCE_NAMES.DEV_SANDBOX]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/members"
        isAuthenticated={isAuthenticated}
        render={props => <MembersPage {...props} />}
        resources={[RESOURCE_NAMES.MEMBERS]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/members/member/:memberId/:memberName"
        isAuthenticated={isAuthenticated}
        render={props => <MemberDetailPage {...props} />}
        resources={[RESOURCE_NAMES.MEMBERS]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/documentation"
        isAuthenticated={isAuthenticated}
        render={props => <Documentation {...props} />}
        resources={[RESOURCE_NAMES.DEV_DASHBOARD]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/price-list-summary"
        isAuthenticated={isAuthenticated}
        render={props => <PriceListSummary {...props} />}
        resources={[RESOURCE_NAMES.DEV_PRICELIST]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/price-list-summary"
        isAuthenticated={isAuthenticated}
        render={props => <PriceListSummary {...props} />}
        resources={[RESOURCE_NAMES.DEV_PRICELIST]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/affiliate/:affiliateId/price-list-summary/lob/:lobId/priceListDetails"
        isAuthenticated={isAuthenticated}
        render={props => <PriceListDetails {...props} />}
        resources={[RESOURCE_NAMES.DEV_PRICELIST]}
      />
      <ProtectedRoute
        exact
        path="/organization/:orgId/price-list-summary/lob/:lobId/priceListDetails"
        isAuthenticated={isAuthenticated}
        render={props => <PriceListDetails {...props} />}
        resources={[RESOURCE_NAMES.DEV_PRICELIST]}
      />
      <Route
        exact
        path="/"
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
      <Redirect to="/notfound" />
    </Switch>
  );
}

export default RootRoute;
