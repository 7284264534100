import * as actionTypes from './actionTypes';
import ApplicationApi from 'services/api/ApplicationApi';
import { hasDevPermission } from 'utils/userPermissions';
import { RESOURCE_NAMES } from 'shared/constants';
const approvedApplicationsFetchSuccess = (data, totalItems) => {
  return {
    type: actionTypes.APPROVED_APPLICATIONS_FETCH_SUCCESS,
    data,
    totalItems,
  };
};

const deniedApplicationsFetchSuccess = (data, totalItems) => {
  return {
    type: actionTypes.DENIED_APPLICATIONS_FETCH_SUCCESS,
    data,
    totalItems,
  };
};

const pendingApplicationsFetchSuccess = (data, totalItems) => {
  return {
    type: actionTypes.PENDING_APPLICATIONS_FETCH_SUCCESS,
    data,
    totalItems,
  };
};

const applicationsFetchSuccess = (data, totalItems) => {
  return {
    type: actionTypes.APPLICATIONS_FETCH_SUCCESS,
    data,
    totalItems,
  };
};

const applicationsFetchFailure = error => {
  return {
    type: actionTypes.APPLICATIONS_FETCH_FAILURE,
    error: error,
  };
};

const applicationsFetchStart = () => {
  return {
    type: actionTypes.APPLICATIONS_FETCH_START,
  };
};

export const applicationPendingOffset = data => {
  return {
    type: actionTypes.APPLICATIONS_PENDING_OFFSET,
    data,
  };
};

export const applicationApprovedOffset = data => {
  return {
    type: actionTypes.APPLICATIONS_APPROVED_OFFSET,
    data,
  };
};

export const applicationDeniedOffset = data => {
  return {
    type: actionTypes.APPLICATIONS_DENIED_OFFSET,
    data,
  };
};

export const applicationPendingText = data => {
  return {
    type: actionTypes.APPLICATION_PENDING_TEXT,
    data,
  };
};

export const applicationPendingSearch = data => {
  return {
    type: actionTypes.APPLICATION_PENDING_SEARCH,
    data,
  };
};

export const applicationDeniedText = data => {
  return {
    type: actionTypes.APPLICATION_DENIED_TEXT,
    data,
  };
};

export const applicationDeniedSearch = data => {
  return {
    type: actionTypes.APPLICATION_DENIED_SEARCH,
    data,
  };
};

export const applicationApprovedText = data => {
  return {
    type: actionTypes.APPLICATION_APPROVED_TEXT,
    data,
  };
};

export const applicationApprovedSearch = data => {
  return {
    type: actionTypes.APPLICATION_APPROVED_SEARCH,
    data,
  };
};

// ApplicationApi.getAllApplications(orgId)
//   .then(response => {
//     const responseData = response.data
//       ? response.data.filter(item => item.isDeleted !== 1)
//       : [];

//     const isAppApprover = hasDevPermission(
//       permissions,
//       orgId,
//       affiliateId,
//       RESOURCE_NAMES.DEV_APPLICATION_APPROVER
//     );

//     const data = responseData
//       .filter(item => item.userId === userId || isAppApprover)
//       .map(fItem => ({
//         ...fItem,
//         isOwner: fItem.userId === userId,
//       }));

//     const approvedData = data.filter(
//       item => item.approvedOn !== null && item.isActive === 1
//     );
//     const deniedData = data.filter(item => item.deniedOn !== null);
//     const pendingData = data.filter(
//       item => item.approvedOn === null && item.deniedOn === null
//     );

//     dispatch(
//       applicationsFetchSuccess({
//         pending: pendingData,
//         approved: approvedData,
//         denied: deniedData,
//       })
//     );
//   })
//   .catch(error => {
//     dispatch(applicationsFetchFailure(error));
//   });

export const fetchApprovedApplications = (
  orgId,
  affiliateId,
  offset,
  limit,
  searchText
) => {
  return (dispatch, getState) => {
    dispatch(applicationsFetchStart());

    const state = getState();
    const {
      resourcePermissions: permissions,
      info: { userId },
    } = state.userInfo;

    ApplicationApi.getApprovedApplications(orgId, offset, limit, searchText)
      .then(response => {
        const responseData = response.data
          ? response.data.filter(item => item.isDeleted !== 1)
          : [];

        const isAppApprover = hasDevPermission(
          permissions,
          orgId,
          affiliateId,
          RESOURCE_NAMES.DEV_APPLICATION_APPROVER
        );

        const data = responseData
          .filter(item => item.userId === userId || isAppApprover)
          .map(fItem => ({
            ...fItem,
            isOwner: fItem.userId === userId,
          }));

        const totalItems = response.totalItems;

        dispatch(approvedApplicationsFetchSuccess(data, totalItems));
      })
      .catch(error => {
        dispatch(applicationsFetchFailure(error));
      });
  };
};

export const fetchDeniedApplications = (
  orgId,
  affiliateId,
  offset,
  limit,
  searchText,
  appStatus
) => {
  return (dispatch, getState) => {
    dispatch(applicationsFetchStart());

    const state = getState();
    const {
      resourcePermissions: permissions,
      info: { userId },
    } = state.userInfo;

    ApplicationApi.getDeniedApplications(
      orgId,
      offset,
      limit,
      searchText,
      appStatus
    )
      .then(response => {
        const responseData = response.data
          ? response.data.filter(item => item.isDeleted !== 1)
          : [];

        const isAppApprover = hasDevPermission(
          permissions,
          orgId,
          affiliateId,
          RESOURCE_NAMES.DEV_APPLICATION_APPROVER
        );

        const data = responseData
          .filter(item => item.userId === userId || isAppApprover)
          .map(fItem => ({
            ...fItem,
            isOwner: fItem.userId === userId,
          }));

        const totalItems = response.totalItems;

        dispatch(deniedApplicationsFetchSuccess(data, totalItems));
      })
      .catch(error => {
        dispatch(applicationsFetchFailure(error));
      });
  };
};

export const fetchPendingApplications = (
  orgId,
  affiliateId,
  offset,
  limit,
  searchText
) => {
  return (dispatch, getState) => {
    dispatch(applicationsFetchStart());
    const state = getState();
    const {
      resourcePermissions: permissions,
      info: { userId },
    } = state.userInfo;

    ApplicationApi.getPendingApplications(orgId, offset, limit, searchText)
      .then(response => {
        const responseData = response.data
          ? response.data.filter(item => item.isDeleted !== 1)
          : [];

        const isAppApprover = hasDevPermission(
          permissions,
          orgId,
          affiliateId,
          RESOURCE_NAMES.DEV_APPLICATION_APPROVER
        );

        const data = responseData
          .filter(item => item.userId === userId || isAppApprover)
          .map(fItem => ({
            ...fItem,
            isOwner: fItem.userId === userId,
          }));

        const totalItems = response.totalItems;

        dispatch(pendingApplicationsFetchSuccess(data, totalItems));
      })
      .catch(error => {
        dispatch(applicationsFetchFailure(error));
      });
  };
};

export const fetchApplications = ({
  _orgId,
  offset,
  limit,
  search,
  sandboxStatus,
  productionStatus,
}) => {
  return (dispatch, getState) => {
    dispatch(applicationsFetchStart());
    const state = getState();
    const {
      resourcePermissions: permissions,
      info: { userId },
    } = state.userInfo;

    ApplicationApi.getApplicationListing({
      _orgId,
      offset,
      limit,
      search,
      sandboxStatus,
      productionStatus,
    })
      .then(response => {
        // const responseData = response.data
        //   ? response.data.filter(item => item.isDeleted !== 1)
        //   : [];

        // const isAppApprover = hasDevPermission(
        //   permissions,
        //   orgId,
        //   affiliateId,
        //   RESOURCE_NAMES.DEV_APPLICATION_APPROVER
        // );

        // const data = responseData
        //   .filter(item => item.userId === userId || isAppApprover)
        //   .map(fItem => ({
        //     ...fItem,
        //     isOwner: fItem.userId === userId,
        //   }));

        dispatch(applicationsFetchSuccess(response?.data, response.totalItems));
      })
      .catch(error => {
        dispatch(applicationsFetchFailure(error));
      });
  };
};
