import React, { useState, useEffect } from 'react';
import { DrawerBoxComponent } from 'components/DrawerBoxComponent';
import { Typography, Paper, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton, SimpleBackdrop } from 'components/widgets';
import { useSnackbar } from 'notistack';
import AdminDetails from './AdminDetails';
import OrganisationDetails from './OrganisationDetails';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#F5F7F8',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  executeButton: {
    borderRadius: '30px',
    textTransform: 'none',
  },
  abortButton: {
    color: 'red',
    borderColor: 'red!important',
  },
  editButton: {
    // display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10px',
  },

  cancelButton: {
    fontSize: '16px',
    borderRadius: '40px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 40px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
  lastRunBox: {
    background: '#E2F1FC',
    width: 'fit-content',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const SignUpStep1 = ({
  changeStep,
  tabStatus,
  signUpValues,
  setSignUp,
  shouldRefetechData,
  trackUnsavedChangesRef,
  setOrgLogo,
  logoDetails,
}) => {
  const classes = useStyles();
  const [savedAdmin, setSavedAdmin] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [savedOrg, setSavedOrg] = useState(false);
  const [editOrg, setEditOrg] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const checkStatus = () => {
    if (!editAdmin && !editOrg) {
      saveDetails();
      shouldRefetechData.current = false;
    } else if (editAdmin) {
      enqueueSnackbar('Please save user details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    } else if (editOrg) {
      enqueueSnackbar('Please save organization details', {
        variant: 'warning',
        autoHideDuration: 1500,
      });
    }
  };

  const saveDetails = async () => {
    setLoading(true);
    let payload = {
      ...signUpValues,
    };
    setSignUp(payload);
    changeStep(2);
  };

  useEffect(() => {
    if (trackUnsavedChangesRef) {
      trackUnsavedChangesRef.current = () => {
        if (editAdmin || editOrg) {
          return true;
        } else {
          return false;
        }
      };
    }
  }, [editAdmin, editOrg, trackUnsavedChangesRef]);

  useEffect(() => {
    if (signUpValues) {
      if (
        signUpValues?.organization?.name &&
        signUpValues?.orgAdminDetails?.firstName
      ) {
        setSavedAdmin(true);
        setSavedOrg(true);
      }
    }
  }, [signUpValues]);

  return (
    <>
      <DrawerBoxComponent style={{ minHeight: '78vh' }}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ background: 'transparent', border: '0px' }}>
          <Box className={classes.container}>
            <Box style={{ display: 'flex' }}>
              <Box style={{ marginRight: '30px' }}>
                <Typography
                  style={{ marginBottom: '15px', color: '#344054' }}
                  variant="h3">
                  <b>Welcome to Mpowered Health!</b>
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '6%',
                  }}>
                  <span>
                    Let’s get started by adding all the information below!
                  </span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>

        {/* Admin section */}
        <AdminDetails
          className={classes.paper}
          setEditAdmin={setEditAdmin}
          setSavedAdmin={setSavedAdmin}
          tabStatus={tabStatus}
          signUpValues={signUpValues}
          setSignUp={setSignUp}
        />
        {/* organisation section */}
        <OrganisationDetails
          className={classes.paper}
          setEditOrg={setEditOrg}
          setSavedOrg={setSavedOrg}
          tabStatus={tabStatus}
          signUpValues={signUpValues}
          setSignUp={setSignUp}
          setOrgLogo={setOrgLogo}
          logoDetails={logoDetails}
        />
      </DrawerBoxComponent>
      {/* Footer */}
      <Box className={classes?.footer || ''}>
        {/* <Box className={classes.editButton}>
          <SecondaryButton onClick={() => checkStatus()}>Close</SecondaryButton>
        </Box> */}

        <Button
          // onClick={onProceed}
          color="primary"
          className={classes.executeButton}
          variant="contained"
          data-test-id="add-organization-button"
          onClick={() => {
            checkStatus();
          }}
          disabled={savedAdmin && savedOrg ? false : true}>
          Proceed
        </Button>
      </Box>
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default SignUpStep1;
