import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import OrganizationApi from 'services/api/OrganizationApi';
import { SimpleBackdrop } from 'components/widgets';
import CreateOrganization from 'containers/SetupOrganization/CreateOrganization';
import * as actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useKeycloak } from '@react-keycloak/web';

function NewOrgContent({ orgId, onToggle, isAddingNew }) {
  //   const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [disable, setDisable] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();
  const { info: userInfo } = useSelector(state => state.userInfo);

  const handleButtonClick = props => {
    if (props) {
      setFile(props);
      setDisable(false);
    }
  };

  const CreateOrg = async data => {
    const formData = new FormData();
    formData.append('org_img', file);

    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      setLoading(true);
      const results = await OrganizationApi.createOrganization(data);
      sessionStorage.setItem('orgId', results?.id);
      await OrganizationApi.uploadLogo(formData, results.id, otherHeaders);
      dispatch(actions.fetchUserInfo(keycloak));
      dispatch(actions.selectedOrgFetchSuccess(results));

      history.push(`/admin/organization/${results.id}/detail`);
      setLoading(false);
      onToggle();
      enqueueSnackbar('Organization created successfully', {
        variant: 'success',
      });
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while creating the organization';
      setLoading(false);
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const UpdateOrg = async data => {
    try {
      setLoading(true);
      const results = await OrganizationApi.updateOrganization(data);
      dispatch(actions.fetchUserInfo(keycloak));
      dispatch(actions.selectedOrgFetchSuccess(results));
    
      // history.push(`/admin/organization/${data.id}/detail`);

      setLoading(false);
      onToggle(true);

      enqueueSnackbar('Organization updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      setLoading(false);

      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'Something went wrong while updating the organization';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const onCreateUpdateOrg = async values => {
    if (!orgId) {
      const { userId } = userInfo;

      const payload = {
        name: values?.name,
        db: values?.db,
        ein: values?.ein,
        typeCode: values?.typeCode,
        addresses: values?.addresses,
        adminDetails: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          emailId: values?.emailId,
          phoneNumber: values?.phoneNumber,
        },
        userId,
      };

      await CreateOrg(payload);
    } else {
      // const { addresses, ...otherValues } = values;
      await UpdateOrg(values);
    }
  };

  return (
    <div>
      <CreateOrganization
        onSubmit={onCreateUpdateOrg}
        orgId={orgId}
        isAddingNew={isAddingNew}
        handleButtonClick={handleButtonClick}
        disable={disable}
      />
      <SimpleBackdrop open={loading} />
    </div>
  );
}

export default NewOrgContent;
