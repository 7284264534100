import BaseApiService from './BaseApiService';

function _getRankings(orgId, affiliateId, timeInterval, startDate) {
  return BaseApiService.get(`/organization/${orgId}/affiliate/${affiliateId}/dashboard/apps/${timeInterval}?startDate=${startDate}&endDate`);
}

function _getResponseCodes(orgId, affiliateId, timeInterval, services, startDate) {
  return BaseApiService.get(`/organization/${orgId}/affiliate/${affiliateId}/dashboard/response-codes/${timeInterval}/${services}?startDate=${startDate}&endDate`);
}

function _getTopFive(orgId, affiliateId, startDate) {
  return BaseApiService.get(`/organization/${orgId}/affiliate/${affiliateId}/dashboard/services?startDate=${startDate}&endDate`);
}

function _getInsights(orgId, affiliateId, timeInterval, application, startDate) {
  return BaseApiService.get(`/organization/${orgId}/affiliate/${affiliateId}/dashboard/api-insights/${timeInterval}/${application}?startDate=${startDate}&endDate`);
}

const TelemetryApi = {
  getRankings: _getRankings,
  getResponseCodes: _getResponseCodes,
  getTopFive: _getTopFive,
  getInsights: _getInsights,
};

export default TelemetryApi;
