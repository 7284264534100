import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseSharp from '@material-ui/icons/CloseSharp';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    width: '95vw',
    [theme.breakpoints.down('sm')]: {
      '&.smallPanel': {
        width: '100vw',
      },
      '&.mediumPanel': {
        width: '100vw',
      },
      '&.largePanel': {
        width: '100vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&.smallPanel': {
        width: '50vw',
      },
      '&.mediumPanel': {
        width: '70vw',
      },
      '&.largePanel': {
        width: '90vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&.smallPanel': {
        width: '40vw',
      },
      '&.mediumPanel': {
        width: '60vw',
      },
      '&.largePanel': {
        width: '80vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '&.smallPanel': {
        width: '25vw',
      },
      '&.mediumPanel': {
        width: '50vw',
      },
      '&.largePanel': {
        width: '75vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
  },
  verticalContainer: {
    height: '95vh',
    [theme.breakpoints.down('sm')]: {
      '&.smallPanel': {
        width: '75vw',
      },
      '&.mediumPanel': {
        width: '85vw',
      },
      '&.largePanel': {
        width: '95vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&.smallPanel': {
        width: '50vw',
      },
      '&.mediumPanel': {
        width: '70vw',
      },
      '&.largePanel': {
        width: '90vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&.smallPanel': {
        width: '40vw',
      },
      '&.mediumPanel': {
        width: '60vw',
      },
      '&.largePanel': {
        width: '80vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '&.smallPanel': {
        width: '25vw',
      },
      '&.mediumPanel': {
        width: '50vw',
      },
      '&.largePanel': {
        width: '75vw',
      },
      '&.fullScreen': {
        width: '100vw',
      },
    },
  },
  headerTitleContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: '16px',
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 2,
  },
}));

const CustomDrawer = withStyles(theme => ({
  paper: {
    backgroundColor: '#E5E5E5',
  },
}))(Drawer);

function SlideOutPanel({
  onToggle,
  open,
  children,
  title,
  containerClassName,
  size,
  position,
}) {
  const classes = useStyles();
  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    onToggle(open);
  };

  const contentClassName = clsx(
    position === 'left' || position === 'right'
      ? classes.container
      : classes.verticalContainer,
    containerClassName,
    `${size === 'small' ? 'smallPanel' : ''}`,
    `${size === 'medium' ? 'mediumPanel' : ''}`,
    `${size === 'large' ? 'largePanel' : ''}`,
    `${size === 'fullScreen' ? 'fullScreen' : ''}`
  );
  return (
    <CustomDrawer anchor={position} open={open} onClose={toggleDrawer(false)}>
      <div className={contentClassName}>
        <div className={classes.headerTitleContainer}>
          <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
            {title}
          </Typography>
          <IconButton
            onClick={toggleDrawer(false)}
            aria-label="close filter"
            component="span">
            <CloseSharp />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          {children}
        </div>
      </div>
    </CustomDrawer>
  );
}

SlideOutPanel.defaultProps = {
  size: 'medium',
  position: 'right',
};

SlideOutPanel.propTypes = {
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullScreen']),
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
};

export default SlideOutPanel;
