import React, { useState, useEffect, useCallback } from 'react';
import {
  SecondaryButton,
  SearchTextField,
  PrimaryButton,
} from 'components/widgets';
import { Typography, Grid, makeStyles, TextField } from '@material-ui/core';
import DocPopup from 'components/widgets/FlyoutPanel/DocPopup';
import { useSnackbar } from 'notistack';
import { SimpleBackdrop } from 'components/widgets';
import DemoApis from 'services/api/DemoApis';
import ImagesGrid from './ImagesGrid';
import ImageDropzone from 'containers/SetupOrganization/ImageDropzone';
import FilterDropDown from 'components/FilterDropDown';
import ImagesConfigApi from 'services/api/ImagesConfigApi';
import CustomizedDialog from 'components/CustomizedDialog';

const useStyles = makeStyles(theme => ({
  bannerImg: {
    // '&:hover': {
    //   position: 'absolute',
    //   top: '0px',
    //   left: '0px',
    //   transition: 'top 1s linear',
    //   cursor: 'pointer',
    // },
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
    minWidth: '100%',
    // minHeight: '160px',
  },
  domainPopup: {
    minHeight: '250px',
    minWidth: '732px',
  },
}));

const Images = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [docCategoryValue, setDocCategoryValue] = useState('Home-page banner');
  const [webRoute, setWebRoute] = useState('');
  const [imgTitle, setImgTitle] = useState('');
  const [type, setType] = useState('');
  const [selectedImg, setSelectedImg] = useState();
  const [clearWarningPopupOpen, setClearWarningPopupOpen] = useState(false);
  const [loginPageTitle, setLoginPageTitle] = useState('');

  const classes = useStyles();

  const onToggle = () => {
    setShow(!show);
    setDisable(true);

    if (loginPageTitle && show) {
      setLoginPageTitle('');
    }

    setDocCategoryValue('Home-page banner');
  };

  const handleUploadImgPopup = () => {
    setShow(true);
    setWebRoute('');
    setImgTitle('');
    setType('upload');
    setSelectedImg();
  };

  const handleDeleteImage = data => {
    setType('delete');
    setSelectedImg(data);
    setShow(false);
    setClearWarningPopupOpen(true);
  };

  const handleEdit = data => {
    if (!data?.imageUrl) {
      setDocCategoryValue('Login-page banner');
      setLoginPageTitle(data?.title);
    } else {
      setDocCategoryValue('Home-page banner');
    }
    setShow(true);
    setWebRoute(data?.webRoute);
    setImgTitle(data?.title);
    setType('update');
    setSelectedImg(data);
  };

  const getListing = useCallback(() => {
    async function listing() {
      setLoading(true);
      let data = [];
      await Promise.allSettled([
        ImagesConfigApi.getImagesList(),
        ImagesConfigApi.getLoginPageTitles(),
      ]).then(res => {
        res.forEach(response => {
          if (response.status === 'fulfilled') {
            data.push(...response.value);
          }
        });
      });
      setFilteredData(data);
      setLoading(false);
    }
    listing();
  }, []);

  useEffect(() => {
    getListing();
  }, [getListing]);

  const uploadImage = async (selectedItem, selectedType) => {
    if (!isHomePageBanner) {
      return handleTitleActions(type || selectedType);
    }
    const formData = new FormData();

    formData.append('action', type);
    formData.append('id', selectedImg?.id || '');
    formData.append('imageUrl', selectedImg?.imageUrl || '');

    if (type === 'update' || type === 'upload') {
      formData.append('file', image);
      formData.append('webRoute', webRoute);
      formData.append('title', image?.name || imgTitle);
    }
    try {
      if (selectedType !== 'delete') {
        onToggle();
      }

      if (clearWarningPopupOpen) {
        setClearWarningPopupOpen(false);
      }
      setLoading(true);
      await ImagesConfigApi.updateImages(formData);
      setLoading(false);
      enqueueSnackbar(
        `Image ${type === 'delete' ? 'deleted' : 'updated'} successfully`,
        {
          variant: 'success',
        }
      );
      getListing();
    } catch (error) {
      if (selectedType !== 'delete') {
        onToggle();
      }
      if (clearWarningPopupOpen) {
        setClearWarningPopupOpen(false);
      }
      setLoading(false);
      enqueueSnackbar(error.response?.data?.details || `Something went wrong`, {
        variant: 'error',
      });
    }
  };

  const handleTitleActions = type => {
    const handleTitleSuccess = msg => {
      setLoading(false);
      enqueueSnackbar(`Title ${msg} successfully`, {
        variant: 'success',
      });
      getListing();
      if (type !== 'delete') {
        onToggle();
      }
      if (clearWarningPopupOpen) {
        setClearWarningPopupOpen(false);
      }
    };

    const handleTitleError = error => {
      setLoading(false);
      enqueueSnackbar(error.response?.data?.details || `Something went wrong`, {
        variant: 'error',
      });
    };

    setLoading(true);
    if (clearWarningPopupOpen) {
      setClearWarningPopupOpen(false);
    }
    let payload = {
      title: loginPageTitle,
      id: selectedImg?.id,
    };
    if (type === 'upload') {
      ImagesConfigApi.createLoginTitle(payload)
        .then(res => {
          handleTitleSuccess('created');
        })
        .catch(err => handleTitleError(err));
    } else if (type === 'update') {
      ImagesConfigApi.updateLoginTitle(payload)
        .then(res => {
          handleTitleSuccess('updated');
        })
        .catch(err => handleTitleError(err));
    } else if (type === 'delete') {
      ImagesConfigApi.deleteLoginTitle(selectedImg?.id)
        .then(res => {
          handleTitleSuccess('deleted');
        })
        .catch(err => handleTitleError(err));
    }
  };

  const handleButtonClick = props => {
    if (props) {
      setImage(props);
      setDisable(false);
    }
  };

  const uploadStatusFilterOptions = {
    options: [
      { label: 'Home-page banner', value: 'Home-page banner' },
      { label: 'Login-page banner', value: 'Login-page banner' },
    ],
    value: docCategoryValue,
    changeHandler: ev => {
      setDocCategoryValue(ev.target.value);
    },
  };

  const isHomePageBanner = docCategoryValue === 'Home-page banner';

  return (
    <div style={{ marginLeft: '16px' }}>
      <Typography
        variant="h2"
        style={{ margin: '24px 16px', fontWeight: 'bold', fontSize: 28 }}
      >
        {`Images / Text`}
      </Typography>
      <Grid container style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              {/* <div>
                <SearchTextField
                  label="Search"
                  onChangeText={val => console.log(val)}
                />
              </div> */}
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              {/* <SelectInput
                className="affiliate-select"
                emptyLabel="Affiliate: All"
                optionValueKey="code"
              /> */}
            </Grid>

            <Grid item xs={12} md={7} lg={5}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <SecondaryButton onClick={handleUploadImgPopup}>
                  Upload
                </SecondaryButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ImagesGrid
            rowData={filteredData}
            onDelete={handleDeleteImage}
            onEdit={handleEdit}
            imgStyles={classes.bannerImg}
          />
        </Grid>
      </Grid>

      <DocPopup
        onClose={onToggle}
        open={show}
        title={isHomePageBanner ? 'Upload image' : 'Upload Text'}
        subtitle={isHomePageBanner ? 'Home dashboard banner images' : ''}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ maxWidth: '350px' }}>
            <FilterDropDown
              {...uploadStatusFilterOptions}
              filterType="Select category: "
            />
          </div>

          {docCategoryValue === 'Login-page banner' && (
            <>
              <div style={{ maxWidth: '100%', marginTop: '10px' }}>
                <Typography className={classes.formTitle}>Text</Typography>
                <TextField
                  required
                  id="outlined-basic"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  autoComplete="off"
                  value={loginPageTitle}
                  onChange={e => {
                    setLoginPageTitle(e.target.value);
                  }}
                  placeholder="Please enter the text"
                />
              </div>
            </>
          )}

          {isHomePageBanner && (
            <>
              <div style={{ maxWidth: '100%', marginTop: '10px' }}>
                <Typography className={classes.formTitle}>
                  Redirect URL
                </Typography>
                <TextField
                  required
                  id="outlined-basic"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  autoComplete="off"
                  value={webRoute}
                  onChange={e => {
                    setWebRoute(e.target.value);
                  }}
                  placeholder="Redirect URL"
                />
              </div>
              <div style={{ width: '560px', marginTop: '24px' }}>
                <ImageDropzone
                  handleButtonClick={handleButtonClick}
                  titleText={'Drag and drop your image or'}
                />
              </div>
            </>
          )}
          <div style={{ marginTop: '48px' }}>
            {
              <React.Fragment>
                <SecondaryButton
                  onClick={onToggle}
                  size="large"
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  type="add"
                  disabled={isHomePageBanner ? disable : loginPageTitle === ''}
                  onClick={uploadImage}
                >
                  Add
                </PrimaryButton>
              </React.Fragment>
            }
          </div>
        </div>
      </DocPopup>

      <CustomizedDialog
        title={`Delete ${selectedImg?.imageUrl ? 'Image' : 'Title'}`}
        open={clearWarningPopupOpen}
        customStyles={classes.domainPopup}
        primaryBtnTitle="Delete"
        handleOnSave={() => {
          selectedImg?.imageUrl
            ? uploadImage(selectedImg, 'delete')
            : handleTitleActions('delete');
        }}
        handleClickClose={() => {
          setClearWarningPopupOpen(false);
        }}
      >
        <Typography>{`Are you sure you would like to delete ${
          selectedImg?.imageUrl ? 'image' : 'title'
        }?`}</Typography>
      </CustomizedDialog>
      <SimpleBackdrop open={loading} />
    </div>
  );
};

export default Images;
