import React, { useRef, useEffect } from 'react';
import { CONSENT_FILTER_DATA } from '../../shared/constants';

function useOutsideAlerter(ref, setSubMenu, setActive, menuRef, setShowChip) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setSubMenu(() => ({
          consentStatus: false,
          expireOn: false,
          affiliate: false,
          lob: false,
        }));
        setActive(false);
        setShowChip(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, menuRef]);
}

const ConsentStatusFilter = ({
  setSubMenu,
  setActive,
  handleSelectedData,
  consentStatus: consentStatusFromParent,
  menuRef,
  setShowChip,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setSubMenu, setActive, menuRef, setShowChip);

  const [consentStatus, setConsentStatus] = React.useState(CONSENT_FILTER_DATA);

  const handleChange = (e, item) => {
    const { checked } = e.target;
    if (checked) {
      const newValue = consentStatus.map((val, i) => {
        return val.key === item.key ? { ...val, isChecked: true } : val;
      });
      setConsentStatus(newValue);
      const data = { values: newValue, type: 'consentStatus' };
      handleSelectedData(data);
    } else {
      const newValue = consentStatus.map((val, i) => {
        return val.key === item.key ? { ...val, isChecked: false } : val;
      });
      setConsentStatus(newValue);
      const data = { values: newValue, type: 'consentStatus' };
      handleSelectedData(data);
    }
  };

  useEffect(() => {
    if (consentStatusFromParent?.length) {
      setConsentStatus(consentStatusFromParent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#fff',
        width: 234,
        borderRadius: 4,
        boxShadow:
          '0px 0.5px 1.5px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
        transform: 'translateY(2px)',
        transition: 'all 0.8s infinite',
        padding: 4,
      }}
      ref={wrapperRef}>
      {consentStatus.map(item => {
        return (
          <p
            key={item.key}
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              padding: 10,
              width: '100%',
            }}>
            <input
              id={item.key}
              name={item.name}
              checked={item.isChecked}
              value={item.value}
              type="checkbox"
              onChange={e => handleChange(e, item)}
              style={{ width: '18px', height: '18px', marginRight: '10px' }}
            />
            <span style={{ marginTop: '-4px' }}>{item.value}</span>
          </p>
        );
      })}
    </div>
  );
};

export default ConsentStatusFilter;
