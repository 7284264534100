import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from 'components/widgets';

const useStyles = makeStyles({
  root: {
    maxWidth: 440,
    width: '100%',
    minHeight: 184,
    height: '100%',
    borderRadius: '16px',
    marginTop: '14px',
    marginRight: '20px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SolutionCard({
  id,
  title,
  context,
  openCard,
  handleClick,
  checkPermission,
  description,
  hasRequested,
  handleDialogBox,
}) {
  const classes = useStyles();

  const descriptionDetails = JSON.parse(description);

  const handleCardClick = id => {
    checkPermission ? handleClick(id) : handleDialogBox(id);
  };

  return (
    <Card className={classes.root} variant="outlined" disabled>
      <CardContent style={{ height: '150px', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', margin: '2%' }}>
          <img
            style={{ marginRight: '5px' }}
            src={descriptionDetails?.logo}
            alt="mphlogo"
          />
          <Typography
            variant="h3"
            style={{ marginLeft: '3px', marginBottom: '5px' }}
          >
            {title}{' '}
          </Typography>
        </Box>
        <Typography>{descriptionDetails?.productDescription} </Typography>
      </CardContent>

      <div
        style={{
          flex: 1,
          height: '1px',
          backgroundColor: '#e0e0e0',
          marginTop: '16px',
        }}
      />

      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '10px',
        }}
      >
        <CardActions>
          {checkPermission ? (
            <PrimaryButton
              disabled={checkPermission ? false : hasRequested ? true : false}
              onClick={() => handleCardClick(id)}
            >
              {openCard}
            </PrimaryButton>
          ) : (
            <SecondaryButton
              disabled={hasRequested ? true : false}
              onClick={() => handleCardClick(id)}
              data-testid="accessrequired"
            >
              {hasRequested ? 'Access requested' : 'Buy now'}
            </SecondaryButton>
          )}
        </CardActions>
      </Box>
    </Card>
  );
}
