import { Dialog, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container: {
    top: '25%',
    height: '100%',
    margin: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    gap: '20px',
    borderRadius: '25px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
  closeButton: {
    color: '#000',
  },
  contentTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    border: '2px solid #EBEBEB',
    flexDirection: 'column',
    borderRadius: '10px',
    padding: '15px',
    gap: '10px',
  },
  individualCards: {
    display: 'flex',
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  statusIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    padding: 2,
    marginRight: 8,
  },
  consentInfo: {
    fontWeight: 400,
    color: '#666',
  },
}));

const FlyoutModal = ({
  handleClose,
  open,
  paramsValue,
  patientRecord,
  statusColorMap,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialogBox}
      open={open}
      PaperProps={{
        style: { borderRadius: 15, height: '100vh', margin: '15px' },
      }}
    >
      <div className={classes.container}>
        <div className={classes.contentTitle}>
          <Typography variant="h2" style={{ fontWeight: 600 }} display="inline">
            {'Consent Details'}
          </Typography>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
        </div>
        <div className={classes.cardContainer}>
          {paramsValue?.map(dataValue => {
            let propValue = patientRecord?.[dataValue.property];
            return propValue ? (
              <div className={classes.individualCards} key={dataValue.property}>
                <Typography
                  className={classes.cardContent}
                  variant="h5"
                  style={{ fontWeight: 400 }}
                >
                  {dataValue.title}
                </Typography>
                <Typography
                  className={classes.cardContent}
                  variant="h5"
                  style={{ fontWeight: 500 }}
                >
                  {dataValue.property === 'consentStatus' && (
                    <span
                      className={classes.statusIndicator}
                      style={{
                        backgroundColor: statusColorMap[propValue],
                      }}
                    ></span>
                  )}
                  {dataValue.property === 'consentRequestedOn'
                    ? moment(propValue).format('MMM DD, YYYY')
                    : propValue}
                </Typography>
              </div>
            ) : null;
          })}
        </div>
        <div>
          <p className={classes.consentInfo}>
            {`The consent given by user has been ${patientRecord.consentStatus}. We request you to send the consent request again in case you want to view the user records.`}
          </p>
        </div>
      </div>
    </Dialog>
  );
};

FlyoutModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  paramsValue: PropTypes.array.isRequired,
  patientRecord: PropTypes.object.isRequired,
  statusColorMap: PropTypes.object.isRequired,
};

export default FlyoutModal;
