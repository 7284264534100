import { makeStyles } from '@material-ui/styles';
import { Container, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import colors from '../../colors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    maxWidth: '100%',
  },
  textFieldLabel: {
    fontFamily: 'Inter',
    letterSpacing: 0,
    fontSize: "18px",
    fontWeight: 400,
    marginBottom: '16px',
    color: colors.PRIMARY_GRAY,
    lineHeight: '16px',
    boxSizing: 'border-box',
    height: theme.spacing(2),
  },
  textField: {
    letterSpacing: 0,
    '& ::placeholder': {
      letterSpacing: '0px',
    },
    '& .MuiOutlinedInput-input': {
      color: colors.PRIMARY_GRAY,
      fontFamily: 'Inter',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      padding: '0px 12px',
      height: theme.spacing(5),
      boxSizing: 'border-box',
      opacity: 0.87,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.GRAY_SECONDARY}`,
    },

    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: colors.SUBTLE_GRAY,
    },
    '& .MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.BLUE}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.SUBTLE_GRAY}`,
    },
    '& .MuiInputBase-multiline': {
      padding: '12px 0px',
      height: '80px'
    },
  },
  textFieldRoot: {
    width: '100%',
    boxSizing: 'border-box',
  },
  required: {
    color: 'red',
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginLeft: theme.spacing(0.25),
  },
}));

const SelfReportingTextField = (props) => {
  const {
    variant,
    fullWidth,
    color,
    label,
    multiline,
    placeHolder,
    numberOfLines,
    value,
    onChange,
    required = false,
    onFocus,
    onBlur,
    error,
    disabled,
    ...rest
  } = props;
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(value);
  const changeHandler = (val) => {
    onChange(val);
    setInputValue(val);
  };

  return (
    <Container className={classes.root}>
      {label && (
        <p className={classes.textFieldLabel}>
          {label}
          <span className={classes.required}>{required ? '*' : ''}</span>
        </p>
      )}
      <TextField
        classes={{
          root: classes.textFieldRoot,
        }}
        variant={variant || 'outlined'}
        fullWidth={fullWidth}
        color={color || 'secondary'}
        value={inputValue}
        multiline={multiline}
        rows={numberOfLines}
        onChange={(e) => changeHandler(e.target.value)}
        placeholder={placeHolder}
        className={classes.textField}
        onFocus={() => onFocus && onFocus()}
        onBlur={() => onBlur && onBlur()}
        error={error}
        disabled={disabled}
        {...rest}
      />
    </Container>
  );
};

export default SelfReportingTextField;
