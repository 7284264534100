import React, { useState, useEffect, useCallback } from 'react';
import UsersGrid from './UsersGrid';
import PaginationComponent from 'components/widgets/Pagination/PaginationComponent';

const UserPagination = props => {
  const { numberOfRowsPerPage, rowData, onEdit, onDelete, isInternalUser } =
    props;
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState(rowData);

  const onPageClick = useCallback(pageNumber => {
    const lastIndex = numberOfRowsPerPage * pageNumber;
    const initialIndex = lastIndex - numberOfRowsPerPage;
    const tableInput = rowData.slice(initialIndex, lastIndex);
    const paginatedData = tableInput ? tableInput : rowData;
    setTableData(paginatedData);
    setPageNumber(pageNumber - 1);
  },[numberOfRowsPerPage, rowData]);

  useEffect(() => {
    onPageClick(1);
  }, [onPageClick]);

  return (
    <>
      <UsersGrid
        rowData={tableData}
        onEdit={onEdit}
        onDelete={onDelete}
        isInternalUser={isInternalUser}
      />
      {rowData.length > numberOfRowsPerPage && (
        <PaginationComponent
          numberOfRowsPerPage={numberOfRowsPerPage}
          count={rowData.length}
          onPageClick={onPageClick}
          page={pageNumber}
        />
      )}
    </>
  );
};

export default UserPagination;
