import { Component, memo } from 'react';
import { DataGrid, SecondaryButton } from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { convertStrToTitleCase } from 'shared/utility';
import { statusColorMap } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
  },
}));

const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
  hasReadPermission,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-evenly',
          marginTop: '12px',
        }}
      >
        <SecondaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
          data-testid="dev-details"
          hasPermissions={hasReadPermission}
        >
          View details
        </SecondaryButton>
      </div>
    </>
  );
});

class UsersGrid extends Component {
  state = {
    columnDefs: [
      {
        headerName: 'First name',
        field: 'firstName',
        colId: 'Name',
        cellRenderer: 'Name',
      },
      {
        headerName: 'Last name',
        field: 'lastName',
        colId: 'Name',
        cellRenderer: 'Name',
      },
      {
        headerName: 'Status',
        field: 'orgUserStatus',
        cellRenderer: 'StatusRenderer',
      },
      {
        headerName: 'Organization',
        field: 'externalOrganizationName',
        cellRenderer: 'Name',
      },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      minWidth: 150,
      sortable: false,
      resizable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      Name: rowData => {
        if (rowData.value) {
          return <Box>{convertStrToTitleCase(rowData.value)}</Box>;
        } else {
          return '-';
        }
      },
      StatusRenderer: rowData => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(statusColorMap).includes(
              rowData.data.orgUserStatus || rowData.value
            ) ? (
              <>
                <span
                  style={{
                    backgroundColor:
                      statusColorMap[
                        rowData.data.orgUserStatus || rowData.value
                      ],
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    padding: 4,
                  }}
                ></span>
                <span style={{ marginLeft: 8 }}>
                  {convertStrToTitleCase(
                    rowData.data.orgUserStatus || rowData.value
                  )}
                </span>
              </>
            ) : (
              <span>
                {convertStrToTitleCase(rowData.data.status || rowData.value)}
              </span>
            )}
          </Box>
        );
      },
      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDeleteClick={item => this.onDeleteButton(item)}
          hasReadPermission={this.props?.hasReadPermission}
        />
      ),

      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    context: { componentParent: this },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps?.hasReadPermission !== this.props.hasReadPermission) {
      this.setState({
        ...this.state,
        frameworkComponents: {
          ...this.state.frameworkComponents,
          DetailsRenderer: rowData => (
            <ViewButtonCellRenderer
              {...rowData}
              onClick={item => this.onItemView(item)}
              onDeleteClick={item => this.onDeleteButton(item)}
              hasReadPermission={nextProps?.hasReadPermission}
            />
          ),
        },
      });
    }
  }

  onItemView(data) {
    this.props.onView(data);
  }

  onDeleteButton(data) {
    this.props.onDelete(data);
  }

  render() {
    const { defaultColDef, columnDefs, frameworkComponents } = this.state;
    const { rowData, selectTableData } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  }
}

export default UsersGrid;
