import React, { useEffect, useCallback, useRef } from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import RuleStep1 from './RuleStep1';
import RuleStep2 from './RuleStep2';
import DataPartnersApi from 'services/api/DataPartnersApi';
import OrganizationApi from 'services/api/OrganizationApi';

const CreateRuleModal = ({
  open,
  handleCloseRuleCreation,
  isCreateRule,
  setIsCreateRule,
  rulesId,
  refetchData,
  isEdit,
  handleClose,
  fetchedData,
  trackUnsavedChangesRef,
  lastRunDate,
  ruleTransactionId,
  executionStatus,
  criteriaHistoryForRules,
  hasCreatePermissions
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [Step1, setStep1] = useState(fetchedData ? false : true);
  const [Step1Data, setStep1Data] = useState({
    title: fetchedData?.name || null,
    description: fetchedData?.description || null,
    type: fetchedData?.type || null,
    affiliate: fetchedData?.affiliateIds || null,
    affiliateName: null,
    category: fetchedData?.category || null,
  });
  const [allAffiliates, setAllAffiliates] = useState([]);
  const [logo, setLogo] = useState();
  const shouldRefetechData = useRef(false);
  const orgId = sessionStorage.getItem("orgId");
  const [ruleValues, setRuleValues] = useState({
    id: fetchedData?.id || null,
    orgId: fetchedData?.orgId || orgId,
    name: fetchedData?.name || Step1Data?.title || '',
    description: fetchedData?.description || Step1Data?.description || null,
    type: fetchedData?.type || Step1Data?.type || '',
    category: fetchedData?.category || Step1Data?.category || '',
    version: fetchedData?.version || 1,
    isActive: fetchedData.isActive,
    affiliateIds: fetchedData?.affiliateIds || Step1Data?.affiliate || [],
    status: fetchedData?.status || 'DRAFT',
    ruleDefn: {
      criteria: fetchedData?.ruleDefn?.criteria || [],
      schedule: fetchedData?.ruleDefn?.schedule || {
        oneTime: false,
        startTime: null,
        frequency: null,
        startDate: null,
        endDate: null,
      },
      actions: fetchedData?.ruleDefn?.actions || [],
    },
    editBeforeExec: false,
  });

  const tabStatus = {
    SAVED: 'SAVED',
    NOT_SAVED: 'NOT_SAVED',
    REJECTED: 'REJECTED',
    SAVING: 'SAVING',
  };

  const getAllAffiliates = useCallback(
    async _orgId => {
      try {
        const results = await DataPartnersApi.getAllPartners(_orgId);
        const data = results.filter(item => item.isDeleted !== 1);
        const options = data.map(fItem => ({ id: fItem.id, name: fItem.name }));
        setAllAffiliates(options);
      } catch (error) {
        enqueueSnackbar('Something went wrong while fetching affiliates', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const getOrgLogo = useCallback(() => {
    async function getLogo() {
      try {
        const logoUrl = await OrganizationApi.getLogo(orgId);
        setLogo(logoUrl);
      } catch (e) {
        enqueueSnackbar('Unable to fetch Organization Logo', {
          variant: 'error',
        });
      }
    }
    getLogo();
  }, [enqueueSnackbar, orgId]);

  useEffect(() => {
    if (orgId) {
      getAllAffiliates(orgId);
      getOrgLogo();
    }
  }, [getAllAffiliates, getOrgLogo, orgId]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (shouldRefetechData.current) {
        refetchData();
      }
    };
  }, []);

  const handleStep = val => {
    setStep1(val);
  };

  const setRulePayloadValues = data => {
    // console.log("data",data);
    setRuleValues(data);
  };
  const step1Value = val => {
    // console.log('val', val);
    setStep1Data(val);

    let payload = {
      ...ruleValues,
      name: val?.title || '',
      description: val?.description || null,
      type: val?.type || '',
      affiliateIds: val?.affiliate || [],
      category: val?.category || null,
    };
    setRuleValues(payload);
  };

  return (
    <div>
      {Step1 ? (
        <RuleStep1
          changeStep={handleStep}
          ruleValues={ruleValues}
          step1Data={Step1Data}
          options={allAffiliates}
          data={step1Value}
          logo={logo}
          shouldRefetechData={shouldRefetechData}
          trackUnsavedChangesRef={trackUnsavedChangesRef}
          hasCreatePermissions={hasCreatePermissions}
        />
      ) : (
        <RuleStep2
          changeStep={handleStep}
          step1Data={Step1Data}
          tabStatus={tabStatus}
          ruleValues={ruleValues}
          setRule={setRulePayloadValues}
          handleClose={handleClose}
          isCreateRule={isCreateRule}
          setIsCreateRule={setIsCreateRule}
          orgId={orgId}
          rulesId={rulesId}
          allAffiliates={allAffiliates}
          refetchData={refetchData}
          shouldRefetechData={shouldRefetechData}
          options={allAffiliates}
          trackUnsavedChangesRef={trackUnsavedChangesRef}
          lastRunDate={lastRunDate}
          ruleTransactionId={ruleTransactionId}
          executionStatus={executionStatus}
          criteriaHistoryForRules={criteriaHistoryForRules}
          hasCreatePermissions={hasCreatePermissions}
        />
      )}
    </div>
  );
};

export default CreateRuleModal;
