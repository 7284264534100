import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CustomDialogTitle from './CustomDialogTitle';
import CustomDialog from './CustomDialog';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.object,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  open: false,
  children: null,
  title: null,
};

function NewFlyoutPanel({
  onToggle,
  open,
  children,
  title,
  maxWidth = 'sm',
  isAppBarRequired = false,
  appBarChildren,
  ...props
}) {
  return (
    <div>
      <CustomDialog
        maxWidth={maxWidth}
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onToggle}
        aria-labelledby="flyout-panel-dialog-slide-title"
        aria-describedby="flyout-panel-dialog-slide-description"
      >
        <CustomDialogTitle
          style={{padding: '20px 32px'}}
          id="flyout-panel-dialog-slide-title"
          onClose={() => onToggle()}
        >
          <b style={{ fontSize: 24 }}>{title}</b>
        </CustomDialogTitle>
        <DialogContent style={{padding: '0px'}}>{children}</DialogContent>
        {isAppBarRequired && (
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              boxShadow: '-2px 0px 6px #aaa',
            }}
          >
            {appBarChildren}
          </div>
        )}
      </CustomDialog>
    </div>
  );
}

NewFlyoutPanel.propTypes = propTypes;
NewFlyoutPanel.defaultProps = defaultProps;

export default NewFlyoutPanel;
