import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PricePopup from '../../components/widgets/FlyoutPanel/PricePopup';
import DropZoneComponent from './DropZoneComponent';
import FilterDropDown from 'containers/Medical/FilterDropDown';
import {
  PRICELIST_FILE_NAME,
  RESOURCE_TYPES,
  FREQUENCIES,
  FILE_TYPES,
  LABELS_FIELDS_BUTTONS,
} from '../../shared/constants';
import {
  PrimaryButton,
  SimpleBackdrop as LoadingBackdrop,
  Card,
  FormTextField,
  FormSelectField,
  FormMultiSelectField,
  SecondaryButton,
  AlertDialog,
} from 'components/widgets';
import PartnerLOBApi from 'services/api/PartnerLOBApi';
import DataPartnersApi from 'services/api/DataPartnersApi';
import MasterDataApi from 'services/api/MasterDataApi';
import {
  Typography,
  Grid,
  Box,
  Breadcrumbs,
  Link,
  LinearProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import PriceGrid from './PriceGrid';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import LobDetails from './LobDetails';
import DataLoadConfigGrid from './DataLoadConfigGrid';
import DataLoadConfig from './DataLoadConfig';
import { useSnackbar } from 'notistack';
import { constructNameForS3, useHasPermissions } from 'shared/utility';
import { useSelector } from 'react-redux';
import { useUserPermissions } from 'utils/userPermissions';
import moment from 'moment';
// import axios from 'axios';

const emptyLOB = {
  name: '',
  planId: '',
  planTypeId: '',
  affiliates: [],

  planDataTypes: [
    // {
    //   ...emptyPlanDataType,
    // },
  ],
};

const DataLoadConfiguration = {
  headerData: [],
  tableColumnDefs: [],
  tableData: [],
};

const styles = makeStyles(theme =>
  createStyles({
    popUpContainer: {
      width: 431,
      margin: 0,
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    selectionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 400,
    },
    selectionWrapper: {
      display: 'flex',
      flex: 0.5,
    },
    selectionBox: {
      width: 180,
    },
    fileInputContainer: {
      width: 400,
      marginTop: 24,
    },
    selectionText: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Inter',
      marginBottom: 4,
    },
    buttonWrapper: {
      marginTop: 48,
    },
    dropDownWrapper: {
      width: 20,
    },
    titleFlex: {
      marginLeft: 32,
      marginTop: 16,
      marginRight: 32,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    subTitleFlex: {
      marginTop: 40,
      marginLeft: 32,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);

export default function AddEditLobPage({ history, isViewOnly }) {
  const classes = styles();
  const [lob, setLob] = useState(emptyLOB);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [allAffiliates, setAllAffiliates] = useState([]);
  const [formAffiliates, setFormAffiliates] = useState([]);
  const [isDLCEdit, setDLCEdit] = useState({
    manual: false,
    file: false,
    time: false,
  });
  // const [loadTypes, setLoadTypes] = useState([]);
  // const [frequencies, setFrequencies] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [flyout, setFlyout] = useState({ open: false });
  const [alertDialog, setAlertDialog] = useState({ open: false });
  // const [dataLoadType, setDataLoadType] = useState();
  const [open, setOpen] = useState(false);
  const [fileloaded, setLoaded] = useState(0);
  const [file, setFile] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const [hideButton, setHideButton] = useState(false);
  const [priceList, setPriceList] = useState([]);
  const [disableFileSelection, setDisableFileSelection] = useState(false);
  const [manualDataLoadConfigurationData, setManualDataLoadConfigurationData] =
    useState(DataLoadConfiguration);
  const [
    fileSensedDataLoadConfigurationData,
    setFileSensedDataLoadConfigurationData,
  ] = useState(DataLoadConfiguration);
  const [
    timeBasedDataLoadConfigurationData,
    setTimeBasedDataLoadConfigurationData,
  ] = useState(DataLoadConfiguration);
  const [
    manualDataLoadConfigurationDataEdit,
    setManualDataLoadConfigurationDataEdit,
  ] = useState({});
  const [
    fileSensedDataLoadConfigurationDataEdit,
    setFileSensedDataLoadConfigurationDataEdit,
  ] = useState({});
  const [
    timeBasedDataLoadConfigurationDataEdit,
    setTimeBasedDataLoadConfigurationDataEdit,
  ] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const { lobId } = useParams();
  const orgId = sessionStorage.getItem('orgId');
  const selectedOrg = useSelector(state => state.selectedOrganization);
  const { info } = useSelector(state => state.userInfo);
  const { details } = selectedOrg;

  const isAddLOB = lobId === 'add';

  const permissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'LOB',
    manageOrg: true,
  });


  const { checkITAdminPermission, checkSuperAdminPermission } =
    useUserPermissions();
  const isITAdmin = checkITAdminPermission();
  let isSuperAdmin = checkSuperAdminPermission();

  let createAccess = permissions?.CREATE || isSuperAdmin;
  let editAccess = permissions?.CREATE;
  let readAccess = permissions?.READ;

  const manageLobsLink = isSuperAdmin ? `/admin/organization/${orgId}/manage-lobs` :`/organization/${orgId}/manage-lobs`;

  const [filename, setFileName] = useState('in-network-rate');

  const FileFilterOption = {
    options: PRICELIST_FILE_NAME,
    value: filename,
    changeHandler: ev => {
      const filterVal = ev.target.value;
      setFileName(filterVal);
    },
    rootContainer: classes.rootContainer,
    disabled: false,
  };

  const constructDataLoadSource = useCallback(
    (resource, lob) => {
      return `s3://${constructNameForS3(details.name)}-${constructNameForS3(
        lob.affiliates[0]['name']
      )}-${constructNameForS3(
        lob.name
      )}-data-ingestion/raw/${constructNameForS3(resource)}/`;
    },
    [details]
  );

  const getKeyFromObjectValue = useCallback((value, object) => {
    return Object.keys(object).find(key => object[key] === value);
  }, []);

  const formatDataLoadConfigurationDataForEdit = useCallback(
    data => {
      const manualEdit = {
        id: null,
        batchScheduleTime: null,
        batchStartDate: null,
        fileType: null,
        frequency: null,
        resources: [],
      };
      const fileSensedEdit = {
        id: null,
        resources: [],
      };
      const timeBasedEdit = {
        id: null,
        resources: [],
      };
      for (const item of data['data']) {
        item.resources.forEach((resource, index) => {
          switch (resource['scheduleType']) {
            case 'manual':
              manualEdit.id = item['id'];
              manualEdit.batchScheduleTime = new Date(
                null,
                null,
                null,
                item['batchScheduleTime'].split(':')[0],
                item['batchScheduleTime'].split(':')[1]
              );
              manualEdit.batchStartDate = new Date(item['batchStartDate']);
              manualEdit.fileType = getKeyFromObjectValue(
                resource['fileType'],
                FILE_TYPES
              );
              manualEdit.frequency = getKeyFromObjectValue(
                resource['frequency'],
                FREQUENCIES
              );
              manualEdit.resources.push({
                id: resource['id'],
                orgId: resource['orgId'],
                affId: resource['affId'],
                lobId: resource['lobId'],
                lastRun: resource['lastRun'],
                status: resource['status'],
                resource: getKeyFromObjectValue(
                  resource['resource'],
                  RESOURCE_TYPES
                ),
                resourceSequence: resource['resourceSequence'],
                scheduleType: resource['scheduleType'],
                batchId: resource['batchId'],
              });
              break;
            case 'file sensed':
              fileSensedEdit.id = item['id'];
              fileSensedEdit.resources.push({
                id: resource['id'],
                orgId: resource['orgId'],
                affId: resource['affId'],
                lobId: resource['lobId'],
                lastRun: resource['lastRun'],
                status: resource['status'],
                fileType: getKeyFromObjectValue(
                  resource['fileType'],
                  FILE_TYPES
                ),
                resource: getKeyFromObjectValue(
                  resource['resource'],
                  RESOURCE_TYPES
                ),
                resourceStartDate: new Date(resource['resourceStartDate']),
                scheduleType: resource['scheduleType'],
                batchId: resource['batchId'],
              });
              break;
            case 'time based':
              timeBasedEdit.id = item['id'];
              timeBasedEdit.resources.push({
                id: resource['id'],
                orgId: resource['orgId'],
                affId: resource['affId'],
                lobId: resource['lobId'],
                lastRun: resource['lastRun'],
                status: resource['status'],
                fileType: getKeyFromObjectValue(
                  resource['fileType'],
                  FILE_TYPES
                ),
                frequency: getKeyFromObjectValue(
                  resource['frequency'],
                  FREQUENCIES
                ),
                resource: getKeyFromObjectValue(
                  resource['resource'],
                  RESOURCE_TYPES
                ),
                resourceStartDate: new Date(resource['resourceStartDate']),
                scheduleTime: new Date(
                  null,
                  null,
                  null,
                  resource['scheduleTime'].split(':')[0],
                  resource['scheduleTime'].split(':')[1]
                ),
                scheduleType: resource['scheduleType'],
                batchId: resource['batchId'],
              });
              break;
            default:
              throw new Error('Invalid File Name');
          }
        });
      }
      return [manualEdit, fileSensedEdit, timeBasedEdit];
    },
    [getKeyFromObjectValue]
  );

  const formatDataLoadConfigurationData = useCallback(
    (data, lob) => {
      const manual = {
        headerData: [],
        tableColumnDefs: [
          { field: 'id', hide: true },
          { field: 'resourceSequence', headerName: 'Sequence of Ingestion' },
          { field: 'resource', headerName: 'Resource List' },
          {
            field: 'status',
            headerName: 'Status',
            cellRenderer: 'StatusRenderer',
          },
          {
            field: 'lastRun',
            headerName: 'Last Run',
            cellRenderer: 'DateFormatter',
          },
          { field: 'source', headerName: 'Source' },
        ],
        tableData: [],
      };
      const fileSensed = {
        headerData: [],
        tableColumnDefs: [
          { field: 'id', hide: true },
          { field: 'resource', headerName: 'Resource list' },
          { field: 'resourceStartDate', headerName: 'Resource start date' },
          { field: 'fileType', headerName: 'File type' },
          {
            field: 'status',
            headerName: 'Status',
            cellRenderer: 'StatusRenderer',
          },
          {
            field: 'lastRun',
            headerName: 'Last run',
            cellRenderer: 'DateFormatter',
          },
          { field: 'source', headerName: 'Source' },
        ],
        tableData: [],
      };
      const timeBased = {
        headerData: [],
        tableColumnDefs: [
          { field: 'id', hide: true },
          { field: 'resource', headerName: 'Resource list' },
          { field: 'frequency', headerName: 'Frequency' },
          { field: 'resourceStartDate', headerName: 'Resource start date' },
          { field: 'scheduleTime', headerName: 'Schedule time' },
          { field: 'fileType', headerName: 'File type' },
          {
            field: 'status',
            headerName: 'Status',
            cellRenderer: 'StatusRenderer',
          },
          {
            field: 'lastRun',
            headerName: 'Last run',
            cellRenderer: 'DateFormatter',
          },
          { field: 'source', headerName: 'Source' },
        ],
        tableData: [],
      };
      for (const item of data['data']) {
        item.resources.forEach((resource, index) => {
          switch (resource['scheduleType']) {
            case 'manual':
              manual.headerData[0] = {
                id: '1',
                label: 'Batch frequency',
                value: resource['frequency'],
              };
              manual.headerData[1] = {
                id: '2',
                label: 'Batch start date',
                value: item['batchStartDate'],
              };
              manual.headerData[2] = {
                id: '3',
                label: 'Schedule time',
                value: item['batchScheduleTime'],
              };
              manual.headerData[3] = {
                id: '4',
                label: 'File type',
                value: resource['fileType'],
              };
              manual.tableData.push({
                id: index,
                resourceSequence: resource['resourceSequence'],
                resource: resource['resource'],
                status:
                  resource['status'] && resource['status'].length > 0
                    ? resource['status']
                    : 'Not started',
                lastRun:
                  resource['lastRun'] && resource['lastRun'].length > 0
                    ? resource['lastRun']
                    : null,
                source: constructDataLoadSource(resource['resource'], lob),
              });
              break;
            case 'file sensed':
              fileSensed.tableData.push({
                id: index,
                resource: resource['resource'],
                resourceStartDate: resource['resourceStartDate'],
                fileType: resource['fileType'],
                status:
                  resource['status'] && resource['status'].length > 0
                    ? resource['status']
                    : 'Not Started',
                lastRun:
                  resource['lastRun'] && resource['lastRun'].length > 0
                    ? resource['lastRun']
                    : null,
                source: constructDataLoadSource(resource['resource'], lob),
              });
              break;
            case 'time based':
              timeBased.tableData.push({
                id: index,
                resource: resource['resource'],
                frequency: resource['frequency'],
                resourceStartDate: resource['resourceStartDate'],
                scheduleTime: resource['scheduleTime'],
                fileType: resource['fileType'],
                status:
                  resource['status'] && resource['status'].length > 0
                    ? resource['status']
                    : 'Not Started',
                lastRun:
                  resource['lastRun'] && resource['lastRun'].length > 0
                    ? resource['lastRun']
                    : null,
                source: constructDataLoadSource(resource['resource'], lob),
              });
              break;
            default:
              throw new Error('Invalid file name');
          }
        });
      }
      return [manual, fileSensed, timeBased];
    },
    [constructDataLoadSource]
  );

  const setAffiliatesBasedOnSelectedAffiliate = value => {
    if (value.length === 0) {
      setFormAffiliates(allAffiliates);
    } else {
      setFormAffiliates([]);
    }
  };

  const getAllAffiliates = useCallback(
    async _orgId => {
      try {
        const results = await DataPartnersApi.getAllPartners(_orgId);
        const data = results.filter(item => item.isDeleted !== 1);
        // .map(fItem => ({ code: fItem.id, name: fItem.name }));
        setAllAffiliates(data);
        setFormAffiliates(data);
      } catch (error) {
        enqueueSnackbar('Something went wrong while fetching affiliates', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  // const getLoadTypes = useCallback(() => {
  //   MasterDataApi.getLoadType()
  //     .then(results => {
  //       const data = results.map(item => {
  //         return {
  //           code: item.id,
  //           name: item.name,
  //         };
  //       });
  //       setLoadTypes(data);
  //     })
  //     .catch(err => {
  //       enqueueSnackbar('Something went wrong while fetching data load types', {
  //         variant: 'error',
  //       });
  //     });
  // }, [enqueueSnackbar]);

  // const getFrequency = useCallback(() => {
  //   MasterDataApi.getFrequency()
  //     .then(results => {
  //       const data = results.map(item => {
  //         return {
  //           code: item.id,
  //           name: item.name,
  //         };
  //       });
  //       setFrequencies(data);
  //     })
  //     .catch(err => {
  //       // console.log('Error', err);
  //       enqueueSnackbar('Something went wrong while fetching frequency', {
  //         variant: 'error',
  //       });
  //     });
  // }, [enqueueSnackbar]);

  const getPlanTypes = useCallback(() => {
    MasterDataApi.getPlanTypes()
      .then(results => {
        const data = results.map(item => {
          return {
            code: item.id,
            name: item.name,
          };
        });
        setPlanTypes(data);
      })
      .catch(err => {
        // console.log('Error', err);
        enqueueSnackbar('Something went wrong while fetching plan types', {
          variant: 'error',
        });
      });
  }, [enqueueSnackbar]);

  const getFileDisplayName = fileName => {
    for (let i = 0; i < PRICELIST_FILE_NAME.length; i++) {
      if (PRICELIST_FILE_NAME[i].value === fileName)
        return PRICELIST_FILE_NAME[i].label;
    }
  };

  const getFileUploadedDate = date => {
    return moment(date).format('MM/DD/YYYY');
  };

  const formatPriceListData = useCallback(data => {
    return data.map(file => ({
      ...file,
      fileUploadedDate: getFileUploadedDate(file.lobDocModel.createdAt),
      fileDisplayName: getFileDisplayName(file.lobDocModel.fileName),
      fileDisplayType: file.lobDocModel.fileType,
    }));
  }, []);

  const getPriceList = useCallback(
    async lob => {
      setLoading(true);
      try {
        const affiliateId = lob.affiliates[0]['id'];
        const priceListData = await PartnerLOBApi.getPriceListByLOB(
          Number(orgId),
          Number(affiliateId),
          Number(lobId)
        );
        const formattedPriceListData = formatPriceListData(priceListData);
        setPriceList(formattedPriceListData);
        setLoading(false);
      } catch (e) {
        enqueueSnackbar('Unable to fetch price list', {
          variant: 'error',
        });
        setLoading(false);
        setInitLoading(false);
      }
    },
    [enqueueSnackbar, orgId, lobId, formatPriceListData]
  );

  const getDataLoadConfig = useCallback(
    async lob => {
      setLoading(true);
      try {
        const dataLoadConfigData = await PartnerLOBApi.getDataLoadConfigData(
          lobId
        );
        const [manual, fileSensed, timeBased] = formatDataLoadConfigurationData(
          dataLoadConfigData,
          lob
        );
        manual.tableData.length > 0
          ? setDLCEdit(prev => ({ ...prev, manual: true }))
          : setDLCEdit(prev => ({ ...prev, manual: false }));
        fileSensed.tableData.length > 0
          ? setDLCEdit(prev => ({ ...prev, file: true }))
          : setDLCEdit(prev => ({ ...prev, file: false }));
        timeBased.tableData.length > 0
          ? setDLCEdit(prev => ({ ...prev, time: true }))
          : setDLCEdit(prev => ({ ...prev, time: false }));
        setManualDataLoadConfigurationData(manual);
        setFileSensedDataLoadConfigurationData(fileSensed);
        setTimeBasedDataLoadConfigurationData(timeBased);
        const [manualEdit, fileSensedEdit, timeBasedEdit] =
          formatDataLoadConfigurationDataForEdit(dataLoadConfigData);
        setManualDataLoadConfigurationDataEdit(manualEdit);
        setFileSensedDataLoadConfigurationDataEdit(fileSensedEdit);
        setTimeBasedDataLoadConfigurationDataEdit(timeBasedEdit);
        setLoading(false);
      } catch (e) {
        enqueueSnackbar('Unable to fetch Data Load Configurations', {
          variant: 'error',
        });
        setLoading(false);
        setInitLoading(false);
      }
    },
    [
      enqueueSnackbar,
      lobId,
      formatDataLoadConfigurationData,
      formatDataLoadConfigurationDataForEdit,
    ]
  );

  useEffect(() => {
    if (orgId && lobId) {
      if (lobId === 'add') {
        setInitLoading(false);
      } else {
        setInitLoading(true);

        PartnerLOBApi.getById(orgId, lobId)
          .then(response => {
            setLob(response);
            getPriceList(response);
            getDataLoadConfig(response);
          })
          .catch(err => {
            enqueueSnackbar('Errors while getting the LOB', {
              variant: 'error',
            });
          })
          .finally(() => {
            setInitLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, lobId, enqueueSnackbar]);

  useEffect(() => {
    if (orgId) {
      getPlanTypes();
      getAllAffiliates(orgId);
      // getLoadTypes();
      // getFrequency();
    }
  }, [getAllAffiliates, orgId, getPlanTypes]);

  const onCancel = () => {
    history.goBack();
  };
  const onAddLOBSubmit = async values => {
    try {
      await PartnerLOBApi.create(orgId, values);
      history.push(manageLobsLink);

      enqueueSnackbar('LOB created successfully', {
        variant: 'success',
      });
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details
          ? error.response.data.details
          : 'LOB Name Already Exist';
      setLoading(false);
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const onEditLOBSubmit = async values => {
    try {
      await PartnerLOBApi.update(orgId, values);
      history.push(manageLobsLink);

      enqueueSnackbar('LOB updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong while updating LOB', {
        variant: 'error',
      });
    }
  };

  const onSubmit = async values => {
    const {
      name,
      affiliates,
      planDataTypes,
      planId,
      planTypeId,
      orgId: planOrgId,
    } = values;

    const data = {
      name,
      affiliates,
      planDataTypes,
      planId,
      planTypeId,
      orgId: values.id ? planOrgId : orgId,
    };
    setLoading(true);
    if (values.id) {
      await onEditLOBSubmit(values);
    } else {
      await onAddLOBSubmit(data);
    }
  };

  const onBreadcrumbClick = (event, link) => {
    event.preventDefault();
    history.push(link);
  };

  const onEditLobClick = () => {
     history.push(isSuperAdmin ? `/admin/organization/${orgId}/manage-lobs/lob/${lobId}` : `/organization/${orgId}/manage-lobs/lob/${lobId}`);
  };

  const onAddressToggle = () => {
    setFlyout({ open: false });
  };

  const DLCManualOnClick = () => {
    setFlyout({
      open: true,
      title: ' Manual Data Load Configuration',
      dataLoadType: 'manual',
      prePopulateData: isDLCEdit['manual']
        ? manualDataLoadConfigurationDataEdit
        : null,
    });
  };

  const DLCFileSensedOnClick = () => {
    setFlyout({
      open: true,
      title: 'File Sensed Data Load Configuration',
      dataLoadType: 'file sensed',
      prePopulateData: isDLCEdit['file']
        ? fileSensedDataLoadConfigurationDataEdit
        : null,
    });
  };

  const DLCTimeBasedOnClick = () => {
    setFlyout({
      open: true,
      title: 'Time Based Load Configuration',
      dataLoadType: 'time based',
      prePopulateData: isDLCEdit['time']
        ? timeBasedDataLoadConfigurationDataEdit
        : null,
    });
  };

  const onToggle = () => {
    setHideButton(false);
    setLoaded(0);
    setOpen(!open);
  };

  const fileLoader = async () => {
    const otherConfig = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        if (percent < 100) {
          setLoaded(percent);
        }
      },
    };
    setHideButton(false);
    const affiliateId = lob.affiliates[0]['id'];
    const paramObject = {
      orgId: Number(orgId),
      affiliateId: Number(affiliateId),
      lobId: Number(lobId),
      filename,
    };
    const formData = new FormData();
    formData.append('file', file);
    const otherHeaders = {
      'content-type': 'multipart/form-data',
    };

    try {
      await PartnerLOBApi.fileUpload(
        paramObject,
        formData,
        otherConfig,
        otherHeaders
      );
      await getPriceList(lob);
      setLoaded(100);
      setOpen(false);
      setHideButton(true);
      enqueueSnackbar('File added successfully', { variant: 'success' });
    } catch (e) {
      setOpen(false);
      setHideButton(true);
      let message = e.response.data.details;
      enqueueSnackbar(
        message || 'Something went wrong while uploading price list',
        {
          variant: 'error',
        }
      );
    }
  };

  // const fileloader = async () => {
  //   const options = {
  //     onUploadProgress: progressEvent => {
  //       const { loaded, total } = progressEvent;
  //       let percent = Math.floor((loaded * 100) / total);
  //       console.log(`${loaded}kb of ${total}kb | ${percent}%`);
  //       if (percent <= 100) {
  //         setLoaded(prev => prev + percent);
  //       }
  //     },
  //   };
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   axios
  //     .post(
  //       'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //       formData,
  //       options
  //     )
  //     .then(response => {
  //       setOpen(!open);
  //       setTimeout(() => {
  //         setLoaded(0);
  //       }, 2000);
  //     });
  // };

  const handleButtonClick = props => {
    if (props) {
      setHideButton(true);
      setFile(props);
    } else {
      setHideButton(false);
    }
  };

  const addDataLoadConfig = async (data, values) => {
    const postRequest = { ...data, ...values };
    try {
      setFlyout({ open: false });
      await PartnerLOBApi.dataLoadConfig(lobId, postRequest);
      enqueueSnackbar('Data load configuration added', {
        variant: 'success',
      });
      getDataLoadConfig(lob);
    } catch (error) {
      setFlyout({ open: false, title: '' });
      setLoading(false);
      enqueueSnackbar(
        'Something went wrong while adding data load configuration',
        {
          variant: 'error',
        }
      );
    }
  };

  const editDataLoadConfig = async (data, values) => {
    const putRequest = { ...data, ...values };
    try {
      setFlyout({ open: false });
      await PartnerLOBApi.dataLoadConfigEdit(lobId, putRequest);
      enqueueSnackbar('Data load configuration edited', {
        variant: 'success',
      });
      getDataLoadConfig(lob);
    } catch (error) {
      setFlyout({ open: false, title: '' });
      setLoading(false);
      enqueueSnackbar(
        'Something went wrong while editing data load configuration',
        {
          variant: 'error',
        }
      );
    }
  };

  const onDataLoadSubmit = values => {
    setLoading(true);
    const data = {
      orgId: Number(orgId),
      lobId: Number(lobId),
      affId: lob.affiliates[0]['id'],
      orgName: details.name,
      lobName: lob.name,
      affName: lob.affiliates[0]['name'],
      lobUpdate: values.id ? 1 : 0,
      status: values.id ? '' : 'started',
      tenantName: lob.affiliates[0]['name'],
    };

    values.batchStartDate = values.batchStartDate
      ? moment(values.batchStartDate).format('MM/DD/YYYY')
      : null;
    values.batchScheduleTime = values.batchScheduleTime
      ? moment(values.batchScheduleTime).format('HH:mm')
      : null;

    // if (values.batchStartDate) {
    //   values.batchStartDate = moment(values.batchStartDate).format('MM/DD/YYYY');
    //   values.batchScheduleTime = moment(values.batchScheduleTime).format('HH:mm');
    // }

    if (values.frequency) {
      values.resources = values.resources.map(obj => ({
        ...obj,
        frequency: values.frequency,
        fileType: values.fileType,
      }));
    }
    values.resources = values.resources.map(obj => ({
      ...obj,
      resource: RESOURCE_TYPES[obj.resource],
      frequency: FREQUENCIES[obj.frequency],
      fileType: FILE_TYPES[obj.fileType],
      resourceStartDate: obj.resourceStartDate
        ? moment(obj.resourceStartDate).format('MM/DD/YYYY')
        : null,
      scheduleTime: obj.scheduleTime
        ? moment(obj.scheduleTime).format('HH:mm')
        : null,
      ...(values.id && { batchId: values.id }),
    }));
    if (values.id) editDataLoadConfig(data, values);
    else addDataLoadConfig(data, values);
  };

  const onEditFile = file => {
    setFileName(file.lobDocModel.fileName);
    setDisableFileSelection(true);
    onToggle();
  };

  const onOpenUploadFilePopup = () => {
    setDisableFileSelection(false);
    onToggle();
  };

  const onDeleteFile = file => {
    setSelectedFile(file);
    setAlertDialog({
      open: true,
      title: 'Delete File',
      description: 'Are you sure, you want to delete this file?',
    });
  };

  const onDownloadFile = async file => {
    try {
      setLoading(true);
      const { lobDocModel } = file;
      const response = await PartnerLOBApi.downloadFile(lobDocModel.documentId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${lobDocModel.fileName}.${lobDocModel.fileType}`
      );
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch {
      enqueueSnackbar('Unable to Download File', {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const onDialogClose = () => {
    setSelectedFile(null);
    setAlertDialog({ open: false });
  };

  const onDialogConfirm = async () => {
    setLoading(true);
    try {
      const { lobDocModel } = selectedFile;
      await PartnerLOBApi.deleteFile(lobDocModel.documentId);
      await getPriceList(lob);
      setAlertDialog({ open: false });
      setSelectedFile(null);
      enqueueSnackbar('File deleted successfully', { variant: 'success' });
      setLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
      setAlertDialog({ open: false });
      enqueueSnackbar('Something went wrong while deleting file', {
        variant: 'error',
      });
    }
  };

  if (initLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginLeft: 32, marginTop: 16, marginBottom: 16 }}
      >
        <Link
          color="inherit"
          href={manageLobsLink}
          onClick={event => onBreadcrumbClick(event, manageLobsLink)}
        >
          LOBs
        </Link>

        <Typography color="textPrimary">
          {isViewOnly ? lob.name : `${isAddLOB ? 'Add' : 'Edit'} LOB`}
        </Typography>
      </Breadcrumbs>
      {(readAccess || isSuperAdmin) && (
        <div className={classes.titleFlex}>
          <Typography variant="h2" style={{ fontWeight: 'bold', fontSize: 28 }}>
            {isViewOnly ? 'LOB Details' : `${isAddLOB ? 'Add' : 'Edit'} LOB`}
          </Typography>

          {isViewOnly && (
            <PrimaryButton
              hasPermissions={editAccess || isSuperAdmin}
              onClick={onEditLobClick}
            >
              Edit LOB
            </PrimaryButton>
          )}
        </div>
      )}

      {isViewOnly ? (
        <>
          {(readAccess ||
            isSuperAdmin) && (
            <>
              <LobDetails lob={lob} planTypes={planTypes} />

              <div className={classes.subTitleFlex}>
                <Typography
                  variant="h3"
                  style={{ fontSize: 24, fontWeight: 'bold' }}
                >
                  Data load configuration
                </Typography>
              </div>
              <DataLoadConfigGrid
                gridType="Batch"
                DataLoadConfigOnClick={DLCManualOnClick}
                buttonTitle={
                  isDLCEdit['manual'] ? 'Edit config' : 'Add new config'
                }
                data={manualDataLoadConfigurationData}
                isSuperAdmin={
                  isSuperAdmin || readAccess
                }
                createAccess={createAccess}
              />

              <DataLoadConfigGrid
                gridType="File sensed"
                DataLoadConfigOnClick={DLCFileSensedOnClick}
                buttonTitle={
                  isDLCEdit['file'] ? 'Edit config' : 'Add new config'
                }
                data={fileSensedDataLoadConfigurationData}
                isSuperAdmin={
                  isSuperAdmin ||
                  readAccess
                }
                createAccess={createAccess}
              />
              <DataLoadConfigGrid
                gridType="Time based"
                DataLoadConfigOnClick={DLCTimeBasedOnClick}
                buttonTitle={
                  isDLCEdit['time'] ? 'Edit config' : 'Add new config'
                }
                data={timeBasedDataLoadConfigurationData}
                isSuperAdmin={
                  isSuperAdmin ||
                  readAccess
                }
                createAccess={createAccess}
              />
              <PriceGrid
                gridType="Price list"
                rowData={priceList}
                PriceListOnClick={onOpenUploadFilePopup}
                buttonTitle={LABELS_FIELDS_BUTTONS.ADD_PRICE_LIST}
                onEdit={onEditFile}
                onDelete={onDeleteFile}
                onDownload={onDownloadFile}
                isSuperAdmin={isSuperAdmin}
                createAccess={createAccess}
              />
            </>
          )}
          <PricePopup onClose={onToggle} open={open}>
            <div className={classes.popUpContainer}>
              <div className={classes.selectionsContainer}>
                <div>
                  <p className={classes.selectionText}>File Name</p>
                  <FilterDropDown
                    {...FileFilterOption}
                    disabled={disableFileSelection}
                  />
                </div>
              </div>
              <div className={classes.fileInputContainer}>
                <p style={{ marginBottom: 4 }}>File</p>
                <DropZoneComponent
                  fileloaded={fileloaded}
                  handleButtonClick={handleButtonClick}
                />
              </div>
              <div className={classes.buttonWrapper}>
                {fileloaded !== 100 && (
                  <React.Fragment>
                    <SecondaryButton
                      onClick={onToggle}
                      size="large"
                      style={{ marginRight: 10 }}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      size="large"
                      type="add"
                      disabled={!hideButton}
                      onClick={fileLoader}
                    >
                      Add
                    </PrimaryButton>
                  </React.Fragment>
                )}
              </div>
            </div>
          </PricePopup>
        </>
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...lob }}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            pristine,
            form: {
              mutators: { push, remove },
            },
          }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Card>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <FormTextField
                      disabled={!isAddLOB && true}
                      name="name"
                      label={LABELS_FIELDS_BUTTONS.LOB_NAME}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormTextField name="planId" label="Plan id" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormSelectField
                      options={planTypes}
                      name="planTypeId"
                      label={LABELS_FIELDS_BUTTONS.PLAN_TYPE}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} lg={6}>
                    <FormMultiSelectField
                      options={formAffiliates}
                      name="affiliates"
                      label="Affiliates"
                      multiple
                      required
                      disabled={!isAddLOB && true}
                      setRoleOptionsBasedOnSelectedRole={
                        setAffiliatesBasedOnSelectedAffiliate
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
              <Box
                display="flex"
                justifyContent="flex-end"
                margin={2}
                marginTop={4}
              >
                <SecondaryButton
                  onClick={onCancel}
                  size="large"
                  style={{ marginRight: 10 }}
                >
                  Go back
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  type="submit"
                  disabled={pristine || loading}
                >
                  {LABELS_FIELDS_BUTTONS.SAVE_CHANGES}
                </PrimaryButton>
              </Box>
            </form>
          )}
        />
      )}
      {flyout.open && (
        <DataLoadConfig
          onToggle={onAddressToggle}
          onSubmit={onDataLoadSubmit}
          orgId={Number(orgId)}
          lobId={Number(lobId)}
          affId={lob.affiliates[0]['id']}
          {...flyout}
        />
      )}
      {alertDialog.open && (
        <AlertDialog
          onClose={onDialogClose}
          onConfirm={onDialogConfirm}
          loading={loading}
          {...alertDialog}
        />
      )}
      <LoadingBackdrop open={loading} />
    </>
  );
}
