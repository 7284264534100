import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ApplicationFullScreenDialog from 'components/widgets/FullScreenDialog/ApplicationFullScreenDialog';
import { Grid, Typography, Box, Paper, Chip } from '@material-ui/core';
import {
  FooterActions,
  SecondaryButton,
  SimpleBackdrop,
} from 'components/widgets';
import {
  APP_ROLES,
  COLOR_LIST_FOR_STATUS,
  ORG_TYPE,
  REQUEST_MODAL,
  REUSABLE_MODAL,
} from 'shared/constants';
import { useSnackbar } from 'notistack';
import { convertStrToTitleCase } from 'shared/utility';
import SelectSolutions from 'containers/SignUp/SelectSolutions';
import OrganizationApi from 'services/api/OrganizationApi';
import ModalPopup from './ModalPopup';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import { getResourcePermissions } from 'services/AbilityService';
import {
  affiliatesFetchSuccess,
  organizationsFetchSuccess,
} from 'store/actions/organizations';
import UserRolesApiService from 'services/api/UserRolesApiService';
import { userInfoFetchSuccess } from 'store/actions/userInfo';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: '8px',
    margin: 16,
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#FFFFFF',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  executeButton: {
    borderRadius: '30px',
    textTransform: 'none',
  },
  abortButton: {
    color: 'red',
    borderColor: 'red!important',
  },
  editButton: {
    justifyContent: 'flex-end',
    marginRight: '10px',
  },

  cancelButton: {
    fontSize: '16px',
    borderRadius: '40px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 40px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
  lastRunBox: {
    background: '#E2F1FC',
    width: 'fit-content',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    flexGrow: 1,
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const ReusableFields = ({ title, data }) => {
  return (
    <>
      <Box>
        <Typography
          variant="h6"
          style={{ marginBottom: '7px', color: '#667085' }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            overflowWrap: 'break-word',
          }}
        >
          {data || '-'}{' '}
        </Typography>
      </Box>
    </>
  );
};

const ViewRequestsFlyoutContent = ({
  organizationDetails,
  tabIndex,
  formattedDate,
  refetchSolutions,
  setDrawerOpenPage,
  fetchRowData,
  setOffset,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [declinePopup, setDeclinePopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { info: userInfo } = useSelector(state => state.userInfo);

  const { data } = useSelector(state => state.locationStates);

  const formatState = data.find(
    stateName => stateName.code === organizationDetails.state
  );

  const formatOrgType = ORG_TYPE.find(
    OrgType => OrgType.code === organizationDetails.typeCode
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchLocationStates('US'));
  }, []);

  const handleClosePopup = () => {
    setDrawerOpen(false);
    setDeclinePopup(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const STATUS_LIST_BASED_ON_TABINDEX_VALUE = {
    0: 'pending',
    1: 'approved',
    2: 'declined',
  };
  const status = STATUS_LIST_BASED_ON_TABINDEX_VALUE[tabIndex];
  const classes = useStyles();

  const updateOrganizationData = () => {
    const userPermissions = {
      admin: {},
      developer: {},
      isSuperAdmin: true,
      isITAdmin: false,
    };
    OrganizationApi.getAllOrganizations().then(response => {
      const orgData = response ? response : [];

      orgData.forEach(item => {
        const adminResources = UserRolesApiService.getPermissionsByRole([
          APP_ROLES.MPH_ADMIN,
        ]);

        userPermissions.admin[item.id] = getResourcePermissions(adminResources);
      });

      dispatch(userInfoFetchSuccess(userInfo, userPermissions));

      dispatch(organizationsFetchSuccess(orgData));
      dispatch(affiliatesFetchSuccess([]));
    });
  };

  const onChangeApproval = ({ approve }) => {
    setLoading(true);

    OrganizationApi.getApproveOrganizationRequest({
      approve,
      organizationDetails,
      userInfo,
    })
      .then(response => {
        setLoading(false);
        if (approve === 1) {
          updateOrganizationData();
          enqueueSnackbar('Organization request approved', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Organization request denied', {
            variant: 'success',
          });
        }
        fetchRowData('', 0, approve === 0 ? 'DECLINED' : 'APPROVED');
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar('There were some error performing this action', {
          variant: 'error',
        });
      });
  };

  const handleConfirmDenied = () => {
    onChangeApproval({ approve: 0 });
    setDeclinePopup(false);
    setLoading(true);
    setDrawerOpenPage(false);
  };

  const handleConfirmApprove = () => {
    onChangeApproval({ approve: 1 });
    setApprovePopup(false);
    setLoading(true);
    setDrawerOpenPage(false);
  };

  const onApproveRequest = () => {
    setApprovePopup(true);
  };
  const onDenyRequest = () => {
    setDeclinePopup(true);
  };

  const handleCloseConfirmation = () => {
    setDeclinePopup(false);
  };

  const handleClose = () => {
    setApprovePopup(false);
  };

  return (
    <>
      <ApplicationFullScreenDialog.Content>
        {' '}
        <Box>
          <Paper elevation={0} className={classes.paper}>
            <Typography style={{ marginBottom: '15px' }} variant="h4">
              <b>User details</b>
            </Typography>{' '}
            <Grid container spacing={5}>
              <Grid item xs={2}>
                <ReusableFields
                  title="First name"
                  data={organizationDetails?.firstName}
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Last name"
                  data={organizationDetails?.lastName}
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Email id"
                  data={organizationDetails?.emailId}
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Phone number"
                  data={
                    organizationDetails?.phoneNumber?.length > 0
                      ? `(${organizationDetails?.phoneNumber?.substr(
                          0,
                          3
                        )})-${organizationDetails?.phoneNumber?.substr(
                          3,
                          3
                        )}-${organizationDetails?.phoneNumber?.substr(6)}`
                      : '-'
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={0} className={classes.paper}>
            <Typography style={{ marginBottom: '15px' }} variant="h4">
              <b>Organization details</b>
            </Typography>{' '}
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <ReusableFields
                  title="Organization name"
                  data={organizationDetails?.orgName}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" style={{ color: '#667085' }}>
                  Status
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      backgroundColor: COLOR_LIST_FOR_STATUS[status],
                      width: '8px',
                      height: '8px',
                      borderRadius: '4px',
                    }}
                  />
                  <span
                    style={{
                      marginLeft: 4,
                      fontWeight: 500,
                      fontSize: '14px',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {convertStrToTitleCase(status) || '-'}
                  </span>{' '}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Organization type"
                  data={formatOrgType?.name}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: '20px' }}>
              <Grid item xs={2}>
                <ReusableFields
                  title="D&B"
                  data={
                    organizationDetails?.db?.length > 0
                      ? `${organizationDetails?.db?.substr(
                          0,
                          2
                        )}-${organizationDetails?.db?.substr(
                          2,
                          3
                        )}-${organizationDetails?.db?.substr(5)}`
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="EIN"
                  data={
                    organizationDetails?.ein?.length > 0
                      ? `${organizationDetails?.ein?.substr(
                          0,
                          2
                        )}-${organizationDetails?.ein?.substr(2, 8)}`
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Business contact"
                  data={
                    organizationDetails?.phone?.length > 0
                      ? `(${organizationDetails?.phone?.substr(
                          0,
                          3
                        )})-${organizationDetails?.phone?.substr(
                          3,
                          3
                        )}-${organizationDetails?.phone?.substr(6)}`
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Fax number"
                  data={
                    organizationDetails?.fax?.length > 0
                      ? `(${organizationDetails?.fax?.substr(
                          0,
                          3
                        )})-${organizationDetails?.fax?.substr(
                          3,
                          3
                        )}-${organizationDetails?.fax?.substr(6)}`
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  style={{ marginBottom: '0px', color: '#667085' }}
                >
                  Organization logo
                </Typography>
                {organizationDetails?.imgUrl?.length > 0 ? (
                  <a
                    href={organizationDetails?.imgUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '16px',
                      overflowWrap: 'break-word',
                      color: '#1589FF',
                    }}
                  >
                    Logo (1MB).jpg
                  </a>
                ) : (
                  '-'
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: '20px' }}>
              <Grid item xs={2}>
                <ReusableFields
                  title="Address type"
                  data={organizationDetails?.addressType}
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Apt/Suite"
                  data={organizationDetails?.line1}
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields
                  title="Street address"
                  data={organizationDetails?.line2}
                />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields title="City" data={organizationDetails?.city} />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields title="State" data={formatState?.name} />
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: '20px' }}>
              <Grid item xs={2}>
                <ReusableFields title="Zip" data={organizationDetails?.zip} />
              </Grid>
              <Grid item xs={2}>
                <ReusableFields title="Requested on" data={formattedDate} />
              </Grid>
            </Grid>
          </Paper>
          {/* {solution.length > 0 && ( */}
          <Paper elevation={0} className={classes.paper}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ marginBottom: '20px' }}
            >
              <Typography
                style={{ marginBottom: '7px', fontWeight: 600 }}
                variant="h4"
              >
                Solutions
              </Typography>
              {tabIndex === 0 && (
                <Box className={classes.editButton}>
                  <SecondaryButton
                    style={{ padding: '2px' }}
                    onClick={() => setDrawerOpen(true)}
                  >
                    Edit
                  </SecondaryButton>
                </Box>
              )}
            </Box>

            <div>
              {organizationDetails?.solutionNames
                ?.filter(item => item !== '-')
                ?.map(val => (
                  <Chip
                    size="medium"
                    label={val}
                    style={{
                      backgroundColor: '#FAFDFF',
                      borderColor: '#036FCB',
                      margin: '0px 5px 0px 5px',
                    }}
                    variant="outlined"
                  />
                ))}
            </div>
          </Paper>
          {/* )} */}

          {drawerOpen && (
            <ApplicationFullScreenDialog
              open={drawerOpen}
              onToggle={handleClosePopup}
            >
              <ApplicationFullScreenDialog.Content solutionBox={true}>
                <SelectSolutions
                  edit={true}
                  solutionNames={organizationDetails?.solutionNames}
                  handleClosePopup={handleClosePopup}
                  refetchData={refetchSolutions}
                  refetchGridData={fetchRowData}
                  orgId={organizationDetails.orgId}
                  setOffset={setOffset}
                />
              </ApplicationFullScreenDialog.Content>
            </ApplicationFullScreenDialog>
          )}
          {/* {tabIndex === 0 && (
          <Paper elevation={0} className={classes.paper}>
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginBottom: '20px' }}>
              <Typography
                style={{
                  marginBottom: '7px',
                  color: '#667085',
                }}
                variant="h6">
                Reason for declining access
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '18px',
                }}>
                widgets The request was declined because no resources have been
                selected
              </Typography>{' '}
            </Box>
          </Paper>
        )} */}
        </Box>
      </ApplicationFullScreenDialog.Content>
      {tabIndex === 0 && (
        <ApplicationFullScreenDialog.Footer>
          <FooterActions
            saveLabel="Create organization"
            canceLabel="Decline"
            onSave={onApproveRequest}
            onToggle={onDenyRequest}
            disabled={loading}
            size="large"
          />
        </ApplicationFullScreenDialog.Footer>
      )}

      {declinePopup && (
        <ModalPopup
          open={declinePopup}
          handleClose={handleCloseConfirmation}
          title="Decline request"
          context="Are you sure you would like to decline the request?"
          primarybtn={REQUEST_MODAL.PRIMARY_BTN}
          secondarybtn={REQUEST_MODAL.SEC_BTN}
          isShowReason={true}
          handleConfirmDenied={handleConfirmDenied}
        />
      )}

      {approvePopup && (
        <ModalPopup
          open={approvePopup}
          handleClose={handleClose}
          onChangeApproval={onChangeApproval}
          title="Approve request"
          context="Are you sure you would like to approve the request?"
          primarybtn={REUSABLE_MODAL.PRIMARY_BTN}
          secondarybtn={REUSABLE_MODAL.SEC_BTN}
          isShowReason={false}
          handleConfirmApprove={handleConfirmApprove}
        />
      )}
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default ViewRequestsFlyoutContent;
