import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { getLabels } from '../helper';
import colors from '../colors';
import { mockTemplates } from '../mockTemplate';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const styles = (theme) => ({
  container: {
    width: '100%',
    marginTop: '6px',
  },
  conditionContainer: {
    backgroundColor: colors.BACKGROUND_COLOR_NEW,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  conditionLabel: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: theme.spacing(1.75),
    lineHeight: '20px',
    color: colors.GRAY_SECONDARY,
  },
  conditionValue: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.spacing(2),
    lineHeight: '24px',
    color: colors.PRIMARY_GRAY,
  },
  columnContainer: {
    marginTop: theme.spacing(3),
  },

  list: {
    marginTop: theme.spacing(0.5),
    padding: '0px 0px 0px 22px',
  },
  listItem: {
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.PRIMARY_GRAY,
  },
  conditionListWrapper: { display: 'flex', flex: 1 },
  labelWrapper: { flex: 0.5 },
});

const ValueAndDateTable = (props) => {
  const { classes, conditions, reasonForHistory, note } = props;
  const { categoryName } = useParams();
  const { templates } = useSelector((state) => state.templates);
  
  const cardLabels =
    templates[categoryName]?.details?.detailsTable?.valueAndDateTable ||
    mockTemplates[categoryName]?.details?.detailsTable?.valueAndDateTable;
  const colLabels = cardLabels && getLabels(cardLabels);
  const getConditionList = conditions?.map((item, index) => {
    try {
      const parsedValueString = JSON.parse(item.valueString);
      return item.url === 'conditions' && parsedValueString
        ? parsedValueString.map((valueItem) => ({
            id: `${index}`,
            conditionLabel: colLabels?.conditionLabel,
            conditionValue: valueItem?.conditionName || '-',
            dateLabel: colLabels?.dateLabel,
            dateValue:
              (item?.onsetPeriod?.start && item?.onsetPeriod?.start) ||
              (item?.onsetString && item?.onsetString) ||
              '-',
          }))
        : [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }).flat();
  
  const getReasonForHistoryList = reasonForHistory?.map((item, index) => {
    return {
      id: index,
      reasonValue: item?.coding?.[0]?.display || item?.text || item?.coding?.[0]?.code || '-',
    };
  });

  return (
    <Box className={classes.container}>
      {getConditionList?.length ? (
        <Box className={classes.conditionContainer}>
          {getConditionList?.map((item) => {
            return (
              <Box className={classes.conditionListWrapper} key={item?.id}>
                <Box className={classes.labelWrapper}>
                  <Typography className={classes.conditionLabel}>{item.conditionLabel}</Typography>
                  <Typography className={classes.conditionValue}>{item?.conditionValue}</Typography>
                </Box>
                <Box className={classes.labelWrapper}>
                  <Typography className={classes.conditionLabel}>{item.dateLabel}</Typography>
                  <Typography className={classes.conditionValue}>{item?.dateValue}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        ''
      )}
      <Box className={classes.columnContainer}>
        <Typography className={classes.conditionLabel}>
          {colLabels?.reasonForHistoryLabel}
        </Typography>
        {getReasonForHistoryList?.length > 0 ? (
          <ul className={classes.list}>
            {getReasonForHistoryList?.map((item) => {
              return (
                <li className={classes.listItem} key={item?.id}>
                  {item?.reasonValue}
                </li>
              );
            })}
          </ul>
        ) : (
          '-'
        )}
      </Box>
      <Box className={classes.columnContainer}>
        <Typography className={classes.conditionLabel}>{colLabels?.noteLabel}</Typography>
        <Typography className={classes.listItem}>
          {(note != '-' &&
            note.length > 0 &&
            note?.map((item) => {
              return (
                <li className={classes.listItem} key={item?.id}>
                  {item?.text}
                </li>
              );
            })) ||
            note}
        </Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(ValueAndDateTable);
