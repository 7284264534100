import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M24.0156 7.98438H7.98438C6.90625 7.98438 6.01562 8.92188 6.01562 10V22C6.01562 23.0781 6.90625 24.0156 7.98438 24.0156H24.0156C25.0938 24.0156 25.9844 23.0781 25.9844 22V10C25.9844 8.92188 25.0938 7.98438 24.0156 7.98438ZM23.5938 12.25L17.0781 16.3281C16.4219 16.75 15.5781 16.75 14.9219 16.3281L8.40625 12.25C8.17188 12.1094 7.98438 11.8281 7.98438 11.5469C7.98438 10.8438 8.73438 10.4688 9.29688 10.7969L16 15.0156L22.7031 10.7969C23.2656 10.4688 24.0156 10.8438 24.0156 11.5469C24.0156 11.8281 23.8281 12.1094 23.5938 12.25Z" />
    </SvgIcon>
  );
};
export default Icon;
