import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import {
  Card as CustomCard,
  SecondaryButton,
  PageHeaderTitle,
  PrimaryButton,
} from 'components/widgets';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from 'store/actions';
import { useCallback, useEffect, useState } from 'react';
import StatusCard from './StatusCard';
import { RESOURCE_NAMES } from 'shared/constants';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import TopAPIs from 'containers/APIOverview/TopAPIs';
import Insights from 'containers/APIOverview/Insights';
import AppRankings from 'containers/APIOverview/AppRankings';
import ResponseCodes from 'containers/APIOverview/ResponseCodes';
import { useUserPermissions } from 'utils/userPermissions';
import ApplicationApi from 'services/api/ApplicationApi';
import DataPartnersApi from 'services/api/DataPartnersApi';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    margin: theme.spacing(2),
  },
  card: {
    width: 345,
  },
  cardContent: {
    minHeight: 160,
  },
  btn: {
    textTransform: 'none',
  },
  listItem: {
    margin: '8px 0',
  },
  numIcons: {
    display: 'inline',
    padding: '4px',
    marginRight: '5px',
    borderRadius: '50%',
    background: '#ffa400',
  },
  apiCard: {
    marginTop: theme.spacing(2),
    minHeight: 240,
  },
  name: {
    fontWeight: '600',
    fontSize: 28,
    paddingLeft: theme.spacing(2),
  },
}));

const DashboardPage = ({ title, history }) => {
  const [hasAppCrudRights, setHasAppCrudRights] = useState(false);
  const [hasMembersAccess, setHasMembersAccess] = useState(false);
  const [prodAppCount, setProdAppCount] = useState({
    Approved: 0,
    Denied: 0,
    Pending: 0,
  });
  const [loadingCount, setLoadingCount] = useState(true);
  const [membersCount, setMembersCount] = useState(null);
  const [affiliates, setAffiliates] = useState(null);
  const classes = useStyles();
  const affiliateId = sessionStorage.getItem('affiliateId') ?? 'DEV';
  const orgId = sessionStorage.getItem('orgId');
  const [developerAffiliateId, setDeveloperAffiliateId] = useState(null);

  const handleChange = event => {
    setDeveloperAffiliateId(event.target.value);
  };

  const dispatch = useDispatch();

  const { info: userInfo } = useSelector(state => state.userInfo);

  const {
    pendingApps,
    approvedApps,
    deniedApps,
    loading: loadingApplications,
  } = useSelector(state => state.applications);

  const { checkDevPermission } = useUserPermissions();

  const getCountOfApps = useCallback(() => {
    const getCount = async () => {
      try {
        let response = await ApplicationApi.getApplicationCount(orgId);
        let result = response.filter(
          item => item.environment === 'production' && item.status !== null
        );
        setProdAppCount(prevValue => ({
          ...prevValue,
          Approved:
            result.filter(item => item.status === 'Approved')[0]?.count ?? 0,
          Denied:
            result.filter(item => item.status === 'Denied')[0]?.count ?? 0,
          Pending:
            result.filter(item => item.status === 'Pending')[0]?.count ?? 0,
        }));
      } catch (e) {
        console.log(e);
      }
      setLoadingCount(false);
    };
    getCount();
  }, [orgId]);

  const getAllAffiliates = useCallback(() => {
    async function getAffiliates() {
      try {
        const results = await DataPartnersApi.getAllPartners(orgId);
        const response = results.filter(item => item.isDeleted !== 1);
        const data = response.map(fItem => ({
          id: fItem.id,
          name: fItem.name,
        }));
        setAffiliates(data);
        setDeveloperAffiliateId(data[0].id);
      } catch (error) {
        console.log(error);
      }
    }
    getAffiliates();
  }, [orgId]);

  useEffect(() => {
    if (orgId && affiliateId) {
      const rights = checkDevPermission(
        orgId,
        affiliateId,
        RESOURCE_NAMES.DEV_APPLICATION_CRUD
      );
      const memberRights = checkDevPermission(
        orgId,
        affiliateId,
        RESOURCE_NAMES.MEMBERS
      );

      setHasAppCrudRights(rights);
      setHasMembersAccess(memberRights);
    }
  }, [orgId, affiliateId, checkDevPermission]);

  useEffect(() => {
    if (hasMembersAccess) {
      OrganizationUsersApi.getAllMembers().then(response => {
        setMembersCount(response.data.length);
      });
    }
  }, [hasMembersAccess]);

  useEffect(() => {
    if (orgId && affiliateId) {
      dispatch(actions.fetchApprovedApplications(orgId, affiliateId));
      dispatch(actions.fetchDeniedApplications(orgId, affiliateId));
      dispatch(actions.fetchPendingApplications(orgId, affiliateId));
    }
  }, [dispatch, orgId, affiliateId]);

  useEffect(() => {
    if (orgId) {
      getCountOfApps();
      getAllAffiliates();
    }
  }, [orgId, getCountOfApps, getAllAffiliates]);

  const handleShowCreate = useCallback(() => {
    dispatch(actions.showSaveApplication(orgId, affiliateId));
  }, [dispatch, orgId, affiliateId]);

  const onStatusCardClick = () => {
    // { pathname="/first-page" state: { name: "First person"}}
    if (affiliateId === 'DEV') {
      history.push({
        pathname: `/organization/${orgId}/applications`,
      });
    } else {
      history.push({
        pathname: `/organization/${orgId}/affiliate/${affiliateId}/applications`,
      });
    }
  };

  const onViewMembers = () => {
    history.push(`/organization/${orgId}/affiliate/${affiliateId}/members`);
  };

  return (
    <>
      <div className={classes.content}>
        <PageHeaderTitle>{`Welcome ${userInfo.firstName} ${userInfo.lastName}`}</PageHeaderTitle>
        <Grid container spacing={1}>
          <Grid item xs={hasMembersAccess ? 8 : 12}>
            <CustomCard>
              <Grid container>
                <Grid item xs={6}>
                  <Typography component="span">
                    <b>Applications (production)</b>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  {hasAppCrudRights && (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <SecondaryButton
                        color="primary"
                        size="small"
                        onClick={handleShowCreate}
                      >
                        Create application
                      </SecondaryButton>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StatusCard
                    status="pending"
                    statusText="Pending"
                    count={prodAppCount['Pending']}
                    loading={loadingCount}
                    onClick={() => onStatusCardClick()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StatusCard
                    status="approved"
                    statusText="Approved"
                    count={prodAppCount['Approved']}
                    loading={loadingCount}
                    onClick={() => onStatusCardClick()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StatusCard
                    status="denied"
                    statusText="Denied"
                    count={prodAppCount['Denied']}
                    loading={loadingCount}
                    onClick={() => onStatusCardClick()}
                  />
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>

          {hasMembersAccess && (
            <Grid item xs={4}>
              <CustomCard>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography component="span" style={{ marginBottom: 10 }}>
                      <b>Members using Consent Service</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      flex={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      margin={2}
                    >
                      <Typography variant="h2" style={{ marginBottom: 10 }}>
                        {membersCount}
                      </Typography>
                      <PrimaryButton onClick={() => onViewMembers()}>
                        View Members
                      </PrimaryButton>
                    </Box>
                  </Grid>
                </Grid>
              </CustomCard>
            </Grid>
          )}

          {hasMembersAccess ? (
            <>
              <Grid item xs={12} lg={5}>
                <AppRankings
                  history={history}
                  orgId={orgId}
                  affiliateId={affiliateId}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <ResponseCodes
                  history={history}
                  orgId={orgId}
                  affiliateId={affiliateId}
                  height={210}
                />
              </Grid>

              <Grid item xs={12} lg={5}>
                <TopAPIs
                  history={history}
                  orgId={orgId}
                  affiliateId={affiliateId}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Insights
                  history={history}
                  orgId={orgId}
                  affiliateId={affiliateId}
                />
              </Grid>
            </>
          ) : (
            developerAffiliateId !== null && (
              <>
                <Grid item xs={12}>
                  <Box sx={{ marginLeft: '20px' }}>
                    <Typography variant="h6" style={{ marginBottom: '5px' }}>
                      Select an affiliate
                    </Typography>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <Select
                        style={{ backgroundColor: '#FFF' }}
                        value={developerAffiliateId}
                        onChange={handleChange}
                      >
                        {affiliates.map((item, index) => (
                          <MenuItem value={item.id} index={index}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <TopAPIs
                    history={history}
                    orgId={orgId}
                    affiliateId={developerAffiliateId}
                    devFlag={true}
                  />
                </Grid>

                <Grid item xs={12} lg={7}>
                  <ResponseCodes
                    history={history}
                    orgId={orgId}
                    affiliateId={developerAffiliateId}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Insights
                    history={history}
                    orgId={orgId}
                    affiliateId={developerAffiliateId}
                  />
                </Grid>
              </>
            )
          )}
        </Grid>
      </div>
    </>
  );
};

export default DashboardPage;
