import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ConfigurationsIcon = props => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path
        d="M10.35 16L10.05 14.5C9.85 14.4167 9.6625 14.3292 9.4875 14.2375C9.3125 14.1458 9.13333 14.0333 8.95 13.9L7.5 14.35L6.5 12.65L7.65 11.65C7.61667 11.4333 7.6 11.2167 7.6 11C7.6 10.7833 7.61667 10.5667 7.65 10.35L6.5 9.35L7.5 7.65L8.95 8.1C9.13333 7.96667 9.3125 7.85417 9.4875 7.7625C9.6625 7.67083 9.85 7.58333 10.05 7.5L10.35 6H12.35L12.65 7.5C12.85 7.58333 13.0375 7.67083 13.2125 7.7625C13.3875 7.85417 13.5667 7.96667 13.75 8.1L15.2 7.65L16.2 9.35L15.05 10.35C15.0833 10.5667 15.1 10.7833 15.1 11C15.1 11.2167 15.0833 11.4333 15.05 11.65L16.2 12.65L15.2 14.35L13.75 13.9C13.5667 14.0333 13.3875 14.1458 13.2125 14.2375C13.0375 14.3292 12.85 14.4167 12.65 14.5L12.35 16H10.35ZM11.35 13C11.9 13 12.3708 12.8042 12.7625 12.4125C13.1542 12.0208 13.35 11.55 13.35 11C13.35 10.45 13.1542 9.97917 12.7625 9.5875C12.3708 9.19583 11.9 9 11.35 9C10.8 9 10.3292 9.19583 9.9375 9.5875C9.54583 9.97917 9.35 10.45 9.35 11C9.35 11.55 9.54583 12.0208 9.9375 12.4125C10.3292 12.8042 10.8 13 11.35 13ZM19.3 27L18.85 24.9C18.5667 24.8 18.3042 24.6792 18.0625 24.5375C17.8208 24.3958 17.5833 24.2333 17.35 24.05L15.35 24.7L13.95 22.3L15.55 20.9C15.5167 20.6 15.5 20.3 15.5 20C15.5 19.7 15.5167 19.4 15.55 19.1L13.95 17.7L15.35 15.3L17.35 15.95C17.5833 15.7667 17.8208 15.6042 18.0625 15.4625C18.3042 15.3208 18.5667 15.2 18.85 15.1L19.3 13H22.1L22.55 15.1C22.8333 15.2 23.0958 15.3208 23.3375 15.4625C23.5792 15.6042 23.8167 15.7667 24.05 15.95L26.05 15.3L27.45 17.7L25.85 19.1C25.8833 19.4 25.9 19.7 25.9 20C25.9 20.3 25.8833 20.6 25.85 20.9L27.45 22.3L26.05 24.7L24.05 24.05C23.8167 24.2333 23.5792 24.3958 23.3375 24.5375C23.0958 24.6792 22.8333 24.8 22.55 24.9L22.1 27H19.3ZM20.7 23C21.5333 23 22.2417 22.7083 22.825 22.125C23.4083 21.5417 23.7 20.8333 23.7 20C23.7 19.1667 23.4083 18.4583 22.825 17.875C22.2417 17.2917 21.5333 17 20.7 17C19.8667 17 19.1583 17.2917 18.575 17.875C17.9917 18.4583 17.7 19.1667 17.7 20C17.7 20.8333 17.9917 21.5417 18.575 22.125C19.1583 22.7083 19.8667 23 20.7 23Z"
        // fill="#BDBDBD"
      />
    </SvgIcon>
  );
};

export default ConfigurationsIcon;


