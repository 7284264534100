

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Icon = props => {
  return (
    <SvgIcon width="10" height="10" viewBox="0 0 25 20" {...props}>
     <path d="M5.12634 3.23332C3.76634 4.75999 2.83301 6.95332 2.74634 7.15999L0.333008 6.12666L3.03301 3.42666C3.34634 3.11332 3.79967 2.97332 4.23967 3.05999L5.12634 3.23332ZM6.44634 10.3333C6.44634 10.3333 8.93967 9.29999 10.373 7.86666C13.973 4.26666 13.373 1.45332 13.1797 0.81999C12.5463 0.61999 9.73301 0.0266567 6.13301 3.62666C4.69967 5.05999 3.66634 7.55332 3.66634 7.55332L6.44634 10.3333ZM10.7663 8.87332C9.23967 10.2333 7.04634 11.1667 6.83967 11.2533L7.87301 13.6667L10.573 10.9667C10.8863 10.6533 11.0263 10.2 10.9397 9.75999L10.7663 8.87332ZM4.99967 11C4.99967 11.5533 4.77301 12.0533 4.41301 12.4133C3.62634 13.2 0.333008 13.6667 0.333008 13.6667C0.333008 13.6667 0.799675 10.3733 1.58634 9.58666C1.94634 9.22666 2.44634 8.99999 2.99967 8.99999C4.10634 8.99999 4.99967 9.89332 4.99967 11ZM7.66634 4.99999C7.66634 4.26666 8.26634 3.66666 8.99967 3.66666C9.73301 3.66666 10.333 4.26666 10.333 4.99999C10.333 5.73332 9.73301 6.33332 8.99967 6.33332C8.26634 6.33332 7.66634 5.73332 7.66634 4.99999Z" fill="black"/>
    </SvgIcon>
  );
};

export default Icon;
