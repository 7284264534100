import { Grid, makeStyles } from '@material-ui/core';
import { ReadonlyField, Card } from 'components/widgets';
import { useEffect, useState } from 'react';
import { LABELS_FIELDS_BUTTONS } from 'shared/constants';

const useStyles = makeStyles(theme => ({
  detailsFlex: {
    marginLeft: '32px',
    marginRight: '32px',
  },
}));

function LobDetails({
  lob: { name, planId, planTypeId, affiliates = [] },
  planTypes,
}) {
  const classes = useStyles();
  const [planTypeName, setPlanTypeName] = useState(null);
  const [affiliateNames, setAffiliateNames] = useState('Loading...');
  useEffect(() => {
    if (planTypeId && planTypes && planTypes.length > 0) {
      const index = planTypes.findIndex(item => item.code === planTypeId);

      if (index !== -1) {
        setPlanTypeName(planTypes[index].name);
      }
    }
  }, [planTypes, planTypeId]);

  useEffect(() => {
    if (affiliates && affiliates.length > 0) {
      const names = affiliates.map(item => item.name).join(', ');
      setAffiliateNames(names);
    }
  }, [affiliates]);

  return (
    <Card className={classes.detailsFlex}>
      <Grid container spacing={10}>
        <Grid item>
          <ReadonlyField label={LABELS_FIELDS_BUTTONS.LOB_NAME} value={name} />
        </Grid>
        <Grid item>
          <ReadonlyField label="Plan id" value={planId} />
        </Grid>
        <Grid item>
          <ReadonlyField
            label={LABELS_FIELDS_BUTTONS.PLAN_TYPE}
            value={planTypeName ? planTypeName : 'Loading...'}
          />
        </Grid>
        <Grid item>
          <ReadonlyField label="Affiliates" value={affiliateNames} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default LobDetails;
