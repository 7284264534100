import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
    alignItems: 'center',
    // marginBottom: theme.spacing(2),
  },
  dialogContainer: {
    borderRadius: '24px',
  },
}));

export default function DocPopup(props) {
  const { onClose, open, children, title, subtitle} = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      className={classes.dialogContainer}
    >
      <div className={classes.closeContainer}>
        <div >
        <h1
          style={{ fontSize: 24, fontWeight: 600, paddingLeft: 20, paddingTop: 12}}
          >
          {title}
        </h1>
        <h5
          style={{fontSize: 14, fontWeight: 400, paddingLeft: 20, paddingTop: 5, color: 'grey'}}
          >
          {subtitle}  
        </h5>
          </div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {children}
    </Dialog>
  );
}

DocPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
