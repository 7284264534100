import React, { useState } from 'react';
import NavBar from './NavBar';
import { useLocation } from 'react-router-dom';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import DashboardIcon from 'components/icons/DashboardIcon';
import ManageRoleIcon from 'components/icons/ManageRoleIcon';
import UsersIconDashboard from 'components/icons/UsersIconDashboard';
import { useParams } from 'react-router-dom';
import { useUserPermissions } from 'utils/userPermissions';
import { useSelector } from 'react-redux';
import { useHasPermissions } from 'shared/utility';

const useStyles = makeStyles(theme => ({
  button: {
    // color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    alignItems: 'center',
    letterSpacing: 0,
    padding: theme.spacing(2, 1),
    textTransform: 'none',
    width: '100%',
    paddingLeft: theme.spacing(2),
  },
  icon: {
    color: '#BDBDBD',
    fontSize: 32,
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
    // color: '#fff',
  },
  active: {
    // color: theme.palette.primary.main,
    borderLeftColor: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
}));

const NavItems = ({
  className,
  href,
  icon: Icon,
  title,
  history,
  showOnlyIcon,
  isActive = false,
  loading,
  subMenu = null,
  onDesktopNavMinifiedToggled,
  openMobile,
  loadingSelectedOrg,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesktopNavMinified, setDesktopNavMinified] = useState(false);
  const { info: userInfo } = useSelector(state => state.userInfo);

  const { checkITAdminPermission, checkSuperAdminPermission } =
    useUserPermissions();

  const isITAdmin = checkITAdminPermission();
  const isSuperAdmin = checkSuperAdminPermission();

  var orgId = sessionStorage.getItem('orgId');
  sessionStorage.setItem('orgId', orgId);

  const permissions = useHasPermissions({
    featureName: 'Manage organization',
    subFeatureName: 'User role management',
    manageOrg: true,
  });

  const onMobileClose = () => {
    setMobileNavOpen(false);
  };

  function handleClick(href) {
    onMobileClose();
    history.push(href);
  }

  const TabHeading = [
    {
      href: isSuperAdmin ? '/' : '/dashboard',
      icon: DashboardIcon,
      title: 'Dashboard',
      resourceName: 'Dashboard',
    },
  ];

  if (permissions?.READ) {
    TabHeading.push(
      {
        href: `/list/${orgId}/users`,
        icon: UsersIconDashboard,
        title: 'Users',
        resourceName: 'Users',
      },
      {
        href: '/managerole',
        icon: ManageRoleIcon,
        title: 'Manage roles',
        resourceName: 'Manage roles',
      }
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {TabHeading.map((item, index) => (
        <NavBar
          href={item.href}
          subMenu={item.subMenu}
          key={index}
          title={item.title}
          icon={item.icon}
          history={history}
          showOnlyIcon={isDesktopNavMinified && !openMobile}
          isActive={location.pathname.indexOf(item.href) !== -1}
          onMobileClose={onMobileClose}
          loading={loadingSelectedOrg}
        />
      ))}
    </div>
  );
};

export default NavItems;
