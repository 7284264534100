import React from 'react';
// import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
// import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const styles = (theme) => ({
  link: {
    color: '#036FCB',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  container: { height: 20 },
  lastLink: { color: '#1F1F1F', fontSize: 16, fontFamily: 'Inter', fontWeight: 400 },
  linkName: { color: 'black', padding: '0px 8px' },
});

const BreadCrumb = ({ breadCrumbProps, classes }) => {
  // const navigate = useNavigate();
  const history = useHistory();

  const handleLinkClick = (obj) => {
    if (obj.redirectTo) {
      obj.redirectTo();
    } else if (obj.route) {
      history.push(obj.route,{ state: obj.state });
      // navigate(obj.route, { state: obj.state });
    }
  };

  return (
    <div className={classes.container}>
      {Array.isArray(breadCrumbProps) &&
        breadCrumbProps.map((obj, index) => {
          const last = index === breadCrumbProps.length - 1;
          return last === true ? (
            <span className={classes.lastLink} key={index}>
              {obj.label}
            </span>
          ) : (
            <span onClick={() => handleLinkClick(obj)} key={index} className={classes.link}>
              {obj.label}
              <span className={classes.linkName}>/</span>
            </span>
            // <Link to={{pathname: obj.route, state: obj.state }} key={index}  className={classes.link}>
            //   {obj.label}
            //   <span className={classes.linkName}>/</span>
            // </Link>
          );
        })}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BreadCrumb);
