import React, { useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SecondaryButton, SimpleBackdrop } from 'components/widgets';
import CampaignApi from 'services/api/CampaignApi';
import { DownloadRawFile } from 'shared/utility';
import {
  Box,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import CSVIcon from 'assets/consent/CSV.svg';
import { useSnackbar } from 'notistack';
import ApprovedPersonIcon from '../../../assets/ruleEngine/ApprovedPersonIcon.svg';
import RefreshIcon from '@material-ui/icons/Refresh';

const FIVE_MB_IN_BYTES = 5000000;

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#344054',
  },
  dialogPaper: {
    minHeight: '85vh',
    maxHeight: '85vh',
    borderRadius: '24px',
    minWidth: '60vw',
    maxWidth: '60vw',
  },
  searchButton: {
    borderRadius: '40px',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    height: 40,
    padding: '8px 24px 8px 24px',
  },
  formTitle: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '-0.24px',
  },
  formTextField: {
    margin: 0,
    display: 'flex',
    width: 400,
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    border: '1px dashed #8C8C8C',
    borderRadius: '8px',
    minHeight: '144px',
    textAlign: 'center',
    padding: '15px',
  },
  accordionSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    gap: '10px',
  },
  accordionContent: {
    flexGrow: 0,
  },
  accordionContainer: {
    boxShadow: 'none',
  },
  footer: {
    textAlign: 'center',
    minHeight: '90px',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 30px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    left: 0,
  },
  memberCountContainer: {
    width: '100%',
    backgroundColor: '#F5F7F8',
    border: '1px solid #E4E7EC',
    display: 'flex',
    padding: '16px',
    gap: '8px',
    borderRadius: '8px',
    alignItems: 'center',
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: '10px',
    borderTop: 'none',
    marginRight: '10px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '10px 20px',
  },
}))(MuiDialogActions);

const DialogTitle = props => {
  const { children, onClose } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2" style={{ fontSize: '24px', fontWeight: 600 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const Dropzone = ({ myFiles, setMyFiles }) => {
  const onDrop = acceptedFiles => {
    setMyFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: '.csv',
    onDrop,
    maxFiles: 1,
    maxSize: FIVE_MB_IN_BYTES, //Max Size allowed is 5MB
  });

  const files = myFiles?.map(file => {
    let BYTES_TO_MB = 0.000001;
    let sizeInMB = file.size * BYTES_TO_MB;
    return (
      <Box
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        style={{ gap: '10px' }}>
        <img src={CSVIcon} alt="csv-icon" />
        <Typography
          key={file.path}
          style={{ fontWeight: 400, fontSize: '14px', color: '#101828' }}>
          {file.name} (
          {sizeInMB > 0.1
            ? `${sizeInMB.toFixed(2)} MB)`
            : `${file.size} bytes)`}
          )
        </Typography>
      </Box>
    );
  });
  const classes = useStyles();
  const remove = () => {
    setMyFiles([]);
  };
  return (
    <Box>
      <Box className={classes.dropzoneContainer}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} data-testid="bulk-upload-file-upload" />

          <Typography
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#667085',
              marginBottom: '10px',
            }}>
            CSV
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#667085',
              marginBottom: '5px',
            }}>
            Drag and drop your scan here or
          </Typography>
          <SecondaryButton onClick={open}>Choose file</SecondaryButton>
        </div>
      </Box>
      {myFiles?.length > 0 && (
        <Box
          style={{
            border: '1px solid #8C8C8C',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            padding: '10px',
            alignItems: 'center',
          }}
          data-testid="list-uploaded-file">
          {files}
          <CloseIcon
            style={{ fontSize: '22px', cursor: 'pointer' }}
            onClick={remove}
          />
        </Box>
      )}
    </Box>
  );
};

const CreateEditCohort = ({
  open,
  handleClose,
  isEdit,
  fromCampaign = false,
  handleCampaignCohort,
  cohortData,
  disable,
  setDisable,
  setFlag,
  refetchCohorts,
  closeOnCohortCreation,
}) => {
  const [loading, setLoading] = useState(false);
  const [cohortTitle, setCohortTitle] = useState(
    cohortData?.name ? cohortData.name : ''
  );
  const [cohortType, setCohortType] = useState(
    cohortData?.fileName ? 'file' : 'label'
  );
  const [selectedLabels, setSelectedLabels] = useState(
    cohortData?.labelIds ? cohortData.labelIds : []
  );
  const [tempState, setTempState] = useState([]);
  const [bulkUploadFile, setBulkUploadFile] = useState([]);
  const [labels, setLabels] = useState([]);
  const [memberOperatorValue, setMemberOperatorValue] = useState(
    cohortData?.operator || ''
  );
  const [memberCount, setMemberCount] = useState(0);
  const [memberCountLoading, setMemberCountLoading] = useState(false);
  const fetchLastestMemberCount = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  const orgId = sessionStorage.getItem("orgId");

  const getAllLabels = async () => {
    const results = await CampaignApi.getLabels(orgId);
    setLabels(results);
  };

  useEffect(() => {
    getAllLabels();
  }, [orgId]);

  useEffect(() => {
    if (cohortTitle !== '') {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [cohortTitle]);

  useEffect(() => {
    if (cohortData) {
      if (cohortType === 'file') {
        if (cohortData.name !== cohortTitle || bulkUploadFile[0]) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      } else if (cohortType === 'label') {
        if (
          cohortData.name !== cohortTitle ||
          JSON.stringify(cohortData.labelIds.sort()) !==
            JSON.stringify(selectedLabels.sort()) ||
          memberOperatorValue !== cohortData?.operator
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      }
    }
  }, [
    cohortData,
    cohortTitle,
    bulkUploadFile,
    selectedLabels,
    memberOperatorValue,
  ]);

  useEffect(() => {
    if (
      cohortData?.labelIds &&
      cohortData.labelIds[0] !== null &&
      labels.length !== 0
    ) {
      let idArray = [];
      for (let x of cohortData.labelIds) {
        let comp = labels.map(item => item.id).indexOf(Number(x));
        idArray.push(comp);
      }
      let defaultValueArray = idArray.map(item => labels[item]);
      setTempState(prev => [...prev, ...defaultValueArray]);
    }
  }, [cohortData, labels]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const classes = useStyles();

  const handleClickClose = () => {
    handleClose();
  };

  const handleChange = (event, value) => {
    fetchLastestMemberCount.current = false;
    setMemberCount(0);

    if (value.length > 0) {
      setTempState(value);
      let temp = value.map(item => item.id.toString());
      setSelectedLabels(temp);
    } else {
      setTempState([]);
      isEdit ? setSelectedLabels([null]) : setSelectedLabels([]);
    }

    if (value.length === 1) {
      fetchLastestMemberCount.current = true;
      setMemberCount(value[0]?.patientCount);
    }
  };

  const handleCreateCohort = async () => {
    if (cohortType === 'label' && tempState.length === 0) {
      enqueueSnackbar('Please select at least one label from the dropdown', {
        variant: 'error',
        autoHideDuration: 1000,
      });
      return;
    } else if (cohortType === 'label' && memberCount === 0) {
      if (fetchLastestMemberCount.current) {
        enqueueSnackbar(
          'The member count is currently zero. Please update label / operator for creating cohort.',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        return;
      } else {
        updateMemberCount({ operatorValue: memberOperatorValue });

        enqueueSnackbar('Fetching member count. Please wait', {
          variant: 'info',
          autoHideDuration: 1500,
        });
        return;
      }
    }
    if (cohortType === 'file' && !bulkUploadFile[0]) {
      enqueueSnackbar('Please upload a file', {
        variant: 'error',
        autoHideDuration: 1000,
      });
      return;
    }

    await CampaignApi.checkExistingCohort(orgId, cohortTitle)
      .then(val => {
        if (val.length !== 0) {
          enqueueSnackbar(
            'This cohort already exists in the system. Please enter a different title',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          );
          setLoading(false);
          return;
        }

        let cohortPayload = {
          name: cohortTitle.trim(),
          labelIds:
            cohortType === 'label' ? tempState.map(item => item.id) : [],
          operator:
            cohortType !== 'label' ||
            (cohortType === 'label' && tempState.length === 1)
              ? null
              : memberOperatorValue,
        };
        const payload = new FormData();
        payload.append('cohortJson', JSON.stringify(cohortPayload));

        cohortType === 'file' && payload.append('file', bulkUploadFile[0]);
        CampaignApi.createCohort(orgId, payload)
          .then(res => {
            enqueueSnackbar('Cohort created successfully', {
              variant: 'success',
              autoHideDuration: 1000,
            });
            fromCampaign && handleCampaignCohort(res);
            closeOnCohortCreation();
            setLoading(false);
            fromCampaign === false && refetchCohorts();
          })
          .catch(err => {
            enqueueSnackbar(err?.response?.data?.details, { variant: 'error' });
            setLoading(false);
          });
      })
      .catch(err => {
        console.log(err);
      });
    return;
  };

  const handleUpdateCohort = async () => {
    if (cohortType === 'label' && tempState.length === 0) {
      enqueueSnackbar('Please select at least one label from the dropdown', {
        variant: 'error',
        autoHideDuration: 1000,
      });
      return;
    } else if (cohortType === 'label' && memberCount === 0) {
      if (fetchLastestMemberCount.current) {
        enqueueSnackbar(
          'The member count is currently zero. Please update label / operator for creating cohort.',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        return;
      } else {
        updateMemberCount({ operatorValue: memberOperatorValue });

        enqueueSnackbar('Fetching member count. Please wait', {
          variant: 'info',
          autoHideDuration: 1500,
        });
        return;
      }
    }

    setLoading(true);

    await CampaignApi.checkExistingCohort(orgId, cohortTitle)
      .then(val => {
        if (cohortTitle !== cohortData.name && val.length !== 0) {
          enqueueSnackbar(
            'This cohort already exists in the system. Please enter a different title',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          );
          setLoading(false);
          return;
        }

        let editCohortPayload = {
          name: cohortTitle.trim(),
          labelIds:
            cohortType === 'label' ? tempState.map(item => item.id) : [],
          id: cohortData.id,
          operator:
          cohortType !== 'label' ||
          (cohortType === 'label' && tempState.length === 1)
            ? null
            : memberOperatorValue
        };
        const payload = new FormData();
        payload.append('cohortJson', JSON.stringify(editCohortPayload));

        cohortType === 'file'
          ? bulkUploadFile[0] && payload.append('file', bulkUploadFile[0])
          : payload.append('file', '');

        CampaignApi.editCohort(orgId, cohortData.id, payload)
          .then(res => {
            enqueueSnackbar('Cohort edited successfully', {
              variant: 'success',
              autoHideDuration: 1000,
            });
            closeOnCohortCreation();
            setLoading(false);
            refetchCohorts();
          })
          .catch(err => {
            enqueueSnackbar(err?.response?.data?.details, { variant: 'error' });
            setLoading(false);
          });
      })
      .catch(err => {
        console.log(err);
      });
    return;
  };

  const getLink = async () => {
    setLoading(true);
    try {
      const results = await CampaignApi.getUploadedCohortFile(
        orgId,
        cohortData.id
      );
      DownloadRawFile(results, cohortData.fileName);
      setLoading(false);
    } catch (err) {
      enqueueSnackbar('Something went wrong downloading the file', {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const updateMemberCount = ({ operatorValue }) => {
    if (selectedLabels?.length === 0 || selectedLabels[0] === null) {
      setMemberCount(0);
      return;
    } else if (selectedLabels?.length === 1) {
      let membCount = labels?.filter(
        label => label.id === Number(selectedLabels[0])
      )?.[0]?.patientCount;
      fetchLastestMemberCount.current = true;
      setMemberCount(membCount);

      return;
    } else if (!operatorValue && !memberOperatorValue) {
      enqueueSnackbar('Please select members who should be part of cohort', {
        variant: 'error',
        autoHideDuration: 1000,
      });
      return;
    }

    setMemberCountLoading(true);
    let payload = {
      labelIds: selectedLabels,
      operator: operatorValue || memberOperatorValue,
    };
    CampaignApi.getMemberCount({ orgId, payload })
      .then(res => {
        fetchLastestMemberCount.current = true;
        setMemberCount(res?.patientCount);
        setMemberCountLoading(false);
      })
      .catch(err => {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          autoHideDuration: 1000,
        });
        setMemberCountLoading(false);
      });
  };

  const handleMemberOperatorChange = val => {
    if (val && selectedLabels?.length > 1) {
      updateMemberCount({ operatorValue: val });
    }

    setMemberOperatorValue(val);
  };

  return (
    <div>
      <Dialog
        customStyle
        classes={{ paper: classes.dialogPaper }}
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <Box style={{ padding: '10px', flex: 1 }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClickClose}>
            {isEdit ? 'Edit a cohort' : 'Create a cohort'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.formTitle}>
                  Cohort title <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  required
                  id="outlined-basic"
                  margin="dense"
                  variant="outlined"
                  className={classes.formTextField}
                  value={cohortTitle}
                  onChange={e => setCohortTitle(e.target.value)}
                  inputProps={{ 'data-testid': 'cohort-title-textbox' }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ color: '#000' }}>
                    <Typography variant="h5" style={{ fontWeight: 600 }}>
                      Add recipients
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    style={{ display: 'flex', flexDirection: 'row' }}
                    aria-label="gender"
                    name="gender1"
                    value={cohortType}
                    onChange={(e, value) => setCohortType(value)}>
                    <FormControlLabel
                      key={'label'}
                      value={'label'}
                      control={<Radio color="primary" />}
                      label={'Choose from labels'}
                    />
                    <FormControlLabel
                      key={'file'}
                      value={'file'}
                      control={<Radio color="primary" />}
                      label={'Upload file'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12}>
                {cohortType == 'file' &&
                  (!cohortData?.fileName ? (
                    <Typography style={{ color: '#667085', fontWeight: 400 }}>
                      Please upload the file with the list of recipients. For
                      reference you can download the{' '}
                      <a href="https://mpowered-assets.s3.us-east-2.amazonaws.com/partner-portal-assets/cohort/sample+file+for+cohort.csv">
                        sample file.
                      </a>
                    </Typography>
                  ) : (
                    <Typography style={{ color: '#667085', fontWeight: 400 }}>
                      Uploaded file :{' '}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={getLink}
                        data-testid="cohort-uploaded-file">
                        {cohortData.fileName}
                      </Link>
                    </Typography>
                  ))}
              </Grid>

              <Grid item xs={6}>
                {cohortType === 'label' && (
                  <>
                    <Typography style={{ fontSize: '12px', fontWeight: 500 }}>
                      Labels
                    </Typography>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={labels}
                      value={tempState}
                      onChange={handleChange}
                      data-testid="cohort-dropdown"
                      disableCloseOnSelect
                      getOptionLabel={option => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      style={{ width: 400 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Choose a label"
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'cohort-dropdown-textbox',
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {cohortType === 'file' && (
                  <>
                    <Typography style={{ fontSize: '13px', fontWeight: 500 }}>
                      Recipient file <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Dropzone
                      myFiles={bulkUploadFile}
                      setMyFiles={setBulkUploadFile}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6}></Grid>
              {cohortType === 'label' && selectedLabels?.length > 1 && (
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="radio-title">
                      <Typography
                        style={{ fontSize: '12px', fontWeight: 500 }}
                        data-testid="member-operator-label">
                        Select members who are part of?{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      aria-label="member-operator"
                      name="member-operator"
                      value={memberOperatorValue}
                      onChange={e =>
                        handleMemberOperatorChange(e.target.value)
                      }>
                      <FormControlLabel
                        value="AND"
                        control={<Radio />}
                        label="AND (part of all these labels)"
                      />
                      <FormControlLabel
                        value="OR"
                        control={<Radio />}
                        label="OR (part of any of these labels)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              {cohortType === 'label' && selectedLabels?.length > 1 && (
                <Grid item xs={6}></Grid>
              )}

              {cohortType === 'label' && selectedLabels?.length >= 1 && (
                <Grid item xs={6}>
                  <Box className={classes.memberCountContainer}>
                    <img
                      src={ApprovedPersonIcon}
                      alt="member-count"
                      srcset=""
                    />
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 600 }}
                      data-testid="member-count-label">
                      Member count:
                    </Typography>
                    {memberCountLoading ? (
                      <CircularProgress color="secondary" size={10} />
                    ) : (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        style={{ gap: '5px' }}>
                        <RefreshIcon
                          style={{
                            color: '#667085',
                            fontSize: '14px',
                            cursor: 'pointer',
                          }}
                          onClick={updateMemberCount}
                        />
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                          data-testid="member-count-value">
                          {memberCount}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {!fetchLastestMemberCount.current && !memberCountLoading && (
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        marginTop: '10px',
                      }}>
                      Please click refresh button to get the latest member count
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Box>
        <DialogActions className={classes.footer}>
          <SecondaryButton onClick={handleClickClose} data-testid='cohort-cancel-btn'>Cancel</SecondaryButton>
          <Button
            onClick={isEdit ? handleUpdateCohort : handleCreateCohort}
            color="primary"
            variant="contained"
            disabled={
              cohortTitle === '' ||
              (isEdit && disable) ||
              (cohortType === 'label' &&
                selectedLabels?.length > 1 &&
                memberOperatorValue === '')
            }
            className={classes.searchButton}>
            {!isEdit ? 'Create' : 'Save changes'}
          </Button>
        </DialogActions>
        <SimpleBackdrop open={loading} />
      </Dialog>
    </div>
  );
};

export default CreateEditCohort;
