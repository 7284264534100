import React from 'react';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import clsx from 'clsx';
import { fetchStatusObj } from '../helper';
import { Box, Typography } from '@material-ui/core';
import colors from '../colors';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  listCustom: {
    border: `1px solid ${colors.SUBTLE_GRAY}`,
    borderRadius: 2,
  },
  listItemWrapper: {
    padding: '12px 12px 0px 12px',
    borderBottom: `1px solid ${colors.SUBTLE_GRAY}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  listItem: {
    fontFamily: 'Inter',
    fontSize: theme.spacing(1.75),
    color: colors.PRIMARY_GRAY,
    lineHeight: '20px',
    paddingBottom: theme.spacing(1.5),
  },
  statusContainer: { display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1.5) },
  statusColumn: { display: 'flex', alignItems: 'center', marginRight: theme.spacing(.5) },
  column_value: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    color: colors.PRIMARY_GRAY,
    flex: 'none',
    margin: '4px 0px',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  status_value: {
    boxSizing: 'border-box',
    fontSize: theme.spacing(1.75),
    lineHeight: '0px',
  },
  statusIcon: { width: 1, height: 1 },
});


const ListItem = ({ items, classes }) => {

  const showStatus = (status, color, classes) => {
    const statusObj =
      status && status !== '-' ? fetchStatusObj(status, color) : { status: '-', color: null };
    return (
      <Box className={classes.statusContainer}>
        {statusObj.color && (
          <span className={classes.statusColumn}>
            <StatusIcon htmlColor={statusObj?.color} className={classes.statusIcon} />
          </span>
        )}
        <Typography className={clsx([classes.column_value, classes.status_value])}>{statusObj?.status}</Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.listCustom}>
      {items?.map((item) => {
        return (
          <Box key={item?.id} className={classes.listItemWrapper}>
            {item?.value && <Typography className={classes.listItem}>{item.value}</Typography>}
            {item?.status && showStatus(item?.status, item?.color, classes)}
          </Box>
        );
      })}
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(ListItem);
