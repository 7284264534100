import { useState, useEffect, useCallback } from 'react';
import { PageHeaderTitle, SimpleBackdrop } from 'components/widgets';
import OrganizationUsersApi from 'services/api/OrganizationUsersApi';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Breadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import MembersTabs from '../Members/MembersTab/MemberTabs';
import ConsentRecordsButton from '../Members/MembersTab/ConsentRecordsButton';
import { PERMISSION_CODES, statusFilterMenuItems } from 'shared/constants';
import { singleMemberDownload } from 'shared/utility';

const TABHEADING = {
  IT_ADMIN: {
    id: 0,
    defaultTabIndex: 1,
    availableTabs: ['Consents'],
  },
  AFFILIATE_COMPLIANCE_MANAGER: {
    id: 1,
    defaultTabIndex: 1,
    availableTabs: ['Applications', 'Consents', 'Outreach'],
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const MemberDetailPage = ({ title, history }) => {
  const classes = useStyles();

  const { solutionPermissions } = useSelector(state => state.userInfo);

  const [membersPermissions, setMembersPermissions] = useState(new Map());

  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState([]);
  const [query, setQuery] = useState('');

  const [allData, setAllData] = useState([]);
  const [consentStatus, setConsentStatus] = useState('all');

  const { isITAdmin } = useSelector(
    state => state.userInfo.resourcePermissions
  );
  const TabHeading = isITAdmin
    ? TABHEADING.IT_ADMIN
    : TABHEADING.AFFILIATE_COMPLIANCE_MANAGER;
  const [tabIndex, setTabIndex] = useState(TabHeading.defaultTabIndex);
  const location = useLocation();

  const handleTabIndex = index => {
    setTabIndex(index);
  };

  const dateFilterOptions = {
    options: statusFilterMenuItems,
    value: consentStatus,
    changeHandler: ev => {
      const filterVal = ev.target.value;
      setConsentStatus(filterVal);
    },
  }; //UI filter

  const searchValue = value => {
    setTimeout(() => {
      setQuery(value?.toLowerCase());
    }, 1000);
  };

  const handleStatus = (selectedValue, listOfConsent) => {
    if (selectedValue === 'all') {
      return listOfConsent;
    }
    return listOfConsent.filter(
      listItem => listItem.consentStatus === selectedValue
    );
  };

  const handleSearch = (key, listOfConsent) => {
    if (key === '') {
      return listOfConsent;
    }

    const result = listOfConsent.filter(val =>
      ['typeOfRecords'].some(obj => val[obj]?.toLowerCase().includes(query))
    );
    return result || '';
  };

  const onExportRecordsClick = () => {
    const data = JSON.parse(localStorage.getItem('memberData'));

    singleMemberDownload(
      data.affiliateId.toString(),
      data.universalId.toString(),
      `${data.fullName.split(' ').join('_')}.json`,
      enqueueSnackbar
    );
  };

  useEffect(() => {
    if (consentStatus === 'all' && query === '') {
      setMember(allData);
    } else {
      const consentResult = handleStatus(consentStatus, allData);
      const res = handleSearch(query, consentResult);
      setMember(res);
    }
  }, [query, consentStatus, allData]);

  useEffect(() => {
    const selectedSolution = solutionPermissions?.get(
      localStorage.getItem('selectedSolutionId')
    );

    const selectedAssessment = selectedSolution?.features?.find(
      feature => feature?.featureName === 'CMDE'
    )?.subFeatures;

    const tempMembersPermissions = new Map();

    selectedAssessment?.forEach(subFeature => {
      const subFeaturePermission = {
        READ: false,
        CREATE: false,
        DELETE: false,
        APPROVE: false,
      };
      const availablePermissionsMap = new Set(subFeature.selectedPermissions);
      Object.keys(PERMISSION_CODES).forEach(permissionKey => {
        if (availablePermissionsMap.has(PERMISSION_CODES[permissionKey])) {
          subFeaturePermission[permissionKey] = true;
        }
      });

      tempMembersPermissions.set(
        subFeature.subFeatureName,
        subFeaturePermission
      );
    });

    setMembersPermissions(tempMembersPermissions || []);
  }, [solutionPermissions]);

  const { memberId, memberName } = useParams();
  const affiliateId = sessionStorage.getItem('affiliateId');
  const orgId = sessionStorage.getItem('orgId');
  const { enqueueSnackbar } = useSnackbar();

  const getAllMemberConsents = useCallback(
    async (_orgId, _memberId) => {
      setLoading(true);

      try {
        const memberRecord = await OrganizationUsersApi.getAllConsents(
          _orgId,
          _memberId
        );
        memberRecord.forEach(
          item =>
            // (item.typeOfRecords = JSON.parse(item.typeOfRecords).toString())
            (item.typeOfRecords = 'Consent request template')
        );

        setMember(memberRecord);
        setAllData(memberRecord);

        setLoading(false);
      } catch (error) {
        console.log('error: ', error);
        setLoading(false);
        enqueueSnackbar('Something went wrong fetching consents', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (orgId && memberId) {
      getAllMemberConsents(orgId, memberId);
    }
  }, [orgId, memberId, getAllMemberConsents]);

  const onBreadcrumbClick = event => {
    event.preventDefault();
    if (location.pathname?.startsWith('/dev')) {
      history.push(`/organization/${orgId}/affiliate/${affiliateId}/members`);
    } else {
      history.push(`/organization/${orgId}/cmde`);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  const consentViewDetails = () => {};

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ margin: '16px 16px 16px 32px' }}
      >
        <Link
          color="inherit"
          style={{ color: '#036FCB', cursor: 'pointer' }}
          onClick={event => onBreadcrumbClick(event)}
        >
          Members
        </Link>
        <Typography color="textPrimary">{memberName}</Typography>
      </Breadcrumbs>

      <Grid
        container
        alignItems="center"
        style={{
          marginBottom: 0,
          marginTop: 0,
          paddingRight: 10,
          paddingLeft: 14,
        }}
      >
        <Grid item xs={7}>
          <PageHeaderTitle>{memberName}</PageHeaderTitle>
        </Grid>

        <Grid item xs={5}>
          <ConsentRecordsButton
            onExportRecordsClick={onExportRecordsClick}
            hasExportRecordPermissions={
              membersPermissions?.get('Download/Export Records')?.READ
            }
          />
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {/* <ConsentedAppsGrid
            // rowData={member.consentedApps}
            onView={onViewClick}
          /> */}
          <MembersTabs
            handleTabIndex={handleTabIndex}
            TabHeading={TabHeading}
            member={member}
            consentViewDetails={consentViewDetails}
            setMember={setMember}
            query={query}
            searchValue={searchValue}
            dateFilterOptions={dateFilterOptions}
            tabIndex={tabIndex}
            getAllMemberConsents={getAllMemberConsents}
            hasInitiateConsentPermission={
              membersPermissions?.get('Initiate consent')?.CREATE
            }
          />
        </Grid>
      </Grid>

      <SimpleBackdrop open={loading} />
    </>
  );
};

export default MemberDetailPage;
