import { makeStyles, Container, Box, Typography } from '@material-ui/core';
import Page from 'components/Page';
import PropTypes from 'prop-types';
import { PageHeaderTitle } from 'components/widgets';
import { useState } from 'react';
import { Tab, Tabs } from './Tabs';
import SwaggerContainer from './SwaggerContainer';
import { SANDBOX_PAGE } from 'shared/constants';
import Provider from 'assets/swagger-files/provider.json';
import Patient from 'assets/swagger-files/patient.json';
import { useEffect, useCallback} from 'react';
import { openAPIHost } from 'config/apiConfig';
import SandboxPageApi from 'services/api/SandboxPageApi';
import DeveloperGuide from './DeveloperGuide';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    paddingLeft: 40,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const SANDBOX_URL = window.location.host?.includes('localhost') ? openAPIHost : window.location.host

const SandboxPage = ({ title }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [isURLChanged, setIsURLChanged] = useState(false);
  const [patientIds, setPatientIds] = useState();
  const description = SANDBOX_PAGE.DESCRIPTION;
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTestPatientIds = useCallback(async () => {
    let response = await SandboxPageApi.getPatientIds();
    setPatientIds(response);
  }, [])

  useEffect(() => {
    (function (...apis) {
      apis.forEach(api => {
        api.host = `${SANDBOX_URL}/api/v1/R4/internal/fhir/`;
      });
      setIsURLChanged(true);
    })(Provider, Patient);

    getTestPatientIds();
  }, []);

  return (
    <Page className={classes.root} title={title}>
      <Container >
        <PageHeaderTitle className={classes.header}>Sandbox</PageHeaderTitle>
        <div>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="application tabs">
            <Tab
              label={
                <span>
                  <span>Patient access</span>
                </span>
              }
              {...a11yProps(0)}
            />

            {/* Provider Directory */}

            {/* <Tab
              label={
                <span>
                  <span>Provider directory</span>
                </span>
              }
              {...a11yProps(1)}
            /> */}
            <Tab
              label={
                <span>
                  <span>Developer guide</span>
                </span>
              }
              {...a11yProps(2)}
            />
          </Tabs>
          {isURLChanged && (
            <>
              <TabPanel value={tabValue} index={0}>
                <SwaggerContainer
                  tabValue={tabValue}
                  description={description}
                  spec={Patient}
                  testIds={patientIds}
                />
              </TabPanel>
              {/* <TabPanel value={tabValue} index={1}>
                <SwaggerContainer
                  tabValue={tabValue}
                  description={description}
                  spec={Provider}
                />
              </TabPanel> */}
              <TabPanel value={tabValue} index={1}>
                <DeveloperGuide
                  tabValue={tabValue}
                  description={description}
                  spec={Provider}
                  tabChange={()=>handleTabChange('',0)}
                />
              </TabPanel>

            </>
          )}
        </div>
      </Container>
    </Page>
  );
};

export default SandboxPage;
