import { Component, memo } from 'react';
import {
  DataGrid,
  FlyoutPanel,
  FooterActions,
  SecondaryButton,
  TextField,
} from 'components/widgets';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from 'components/widgets';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { convertStrToTitleCase, statusColorMap } from 'shared/utility';
import DemoApis from 'services/api/DemoApis';
import DownloadIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      textDecorationLine: 'underLine',
    },
  },
  viewDetailsButton: {
    width: '104px',
    height: '24px',
    fontSize: '12px',
    marginRight: '5px',
  },
  editbtn: {
    width: '58px',
    height: '24px',
    fontSize: '12px',
  },
  attributeType: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    borderRadius: '16px',
    backgroundColor: '#E2F1FC',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '120px',
    height: '24px',
    border: '1px solid #036FCB',
    padding: '10px',
    fontWeight: '500',
    color: '#036FCB',
  },
  fieldLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  fieldValue: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#344054',
    //   marginBottom: '2%',
  },
  paper: {
    // padding: theme.spacing(1.8),
    borderRadius: '8px',
    marginRight: '40px',
    border: '1px solid #E4E7EC',
    background: '#f5f5f5',
    width: '100%',
    marginTop: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const FooterAction = ({
  saveLabel = 'Save',
  canceLabel = 'Cancel',
  onSave,
  type = 'button',
  onToggle,
  disabled,
  hideSave = false,
  ...otherCommonProps
}) => {
  return (
    <Box
      sx={{
        background: '#fff',
        boxShadow: '-2px 0px 6px #e0e0e0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.8rem 1.4rem',
        gap: '18px',
      }}
    >
      <SecondaryButton
        onClick={onToggle}
        variant="outlined"
        color="primary"
        // className={classes.actionButton}
        {...otherCommonProps}
      >
        {canceLabel}
      </SecondaryButton>
      {!hideSave && (
        <PrimaryButton
          onClick={onSave}
          disabled={disabled}
          type={type}
          // className={classes.actionButton}
          {...otherCommonProps}
        >
          {saveLabel}
        </PrimaryButton>
      )}
    </Box>
  );
};
const ViewButtonCellRenderer = memo(function ViewButtonCellRenderer({
  data,
  onClick,
}) {
  const classes = useStyles();
  const onClickHandler = event => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alginItem: 'center',
          justifyContent: 'space-evenly',
          marginTop: '12px',
        }}
      >
        <PrimaryButton
          className={classes.viewDetailsButton}
          onClick={onClickHandler}
        >
          View details
        </PrimaryButton>
      </div>
    </>
  );
});

const CriteriaType = memo(function CriteriaType({ data }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.attributeType}>{data.attrType || '-'}</div>
    </>
  );
});

const CreatedOnCriteria = memo(function CriteriaType({ data }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.attributeType}>{data.createdBy || '-'}</div>
    </>
  );
});

class SupportGrid extends Component {
  state = {
    openFlyout: false,
    flyoutData: {},
    comment: '',
    columnDefs: [
      {
        headerName: 'Request id',
        field: 'id',
        // colId: 'NameID',
        // minWidth: '100',
      },
      // {
      //   headerName: 'User text',
      //   field: 'userText',
      //   // cellRenderer: 'attrName',
      //   minWidth: '100',
      // },
      // {
      //   headerName: 'S3 url',
      //   field: 's3Url',
      //   // cellRenderer: 'TypeofCriteria',
      //   minWidth: '100',
      // },
      {
        headerName: 'Request type',
        field: 'category',
        // cellRenderer: 'attrName',
        minWidth: '100',
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'StatusRenderer',
        minWidth: '100',
      },
      {
        headerName: 'Resolved by',
        field: 'resolvedBy',
        // cellRenderer : 'resolvedByRenderer',
        minWidth: '100',
      },
      {
        headerName: 'Created on',
        field: 'createdAt',
        cellRenderer: 'DateFormatter',
        minWidth: '100',
      },
      {
        field: '',
        cellRenderer: 'DetailsRenderer',
      },
    ],

    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
    },
    frameworkComponents: {
      DateFormatter: rowData => {
        if (rowData.value) {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },
      ConsentExpiryDateFormatter: rowData => {
        if (rowData.value && rowData.data.consentStatus !== 'Pending') {
          let formattedDate = moment(rowData.value).format('MMM DD, YYYY');
          return formattedDate;
        } else {
          return '-';
        }
      },

      SetRenderer: rowData => {
        return !rowData.value
          ? ''
          : rowData.value?.map(item => item.name).join(', ');
      },
      resolvedByRenderer: rowData =>{
        return rowData.value ? "Admin" : "-"
      },
      StatusRenderer: rowData => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(statusColorMap).includes(rowData.data.status || '') ? (
              <>
                <span
                  style={{
                    backgroundColor: statusColorMap[rowData.data.status || ''],
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    padding: 4,
                  }}
                ></span>
                <span style={{ marginLeft: 8 }}>
                  {convertStrToTitleCase(rowData.data.status || '-')}
                </span>
              </>
            ) : (
              <span>{convertStrToTitleCase(rowData.data.status || '-')}</span>
            )}
          </Box>
        );
      },
      DetailsRenderer: rowData => (
        <ViewButtonCellRenderer
          {...rowData}
          onClick={item => this.onItemView(item)}
          onDeleteClick={item => this.onDeleteButton(item)}
        />
      ),
      AppRenderer: rowData => {
        if (!rowData.value || rowData.value.length === 0) {
          return '-';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
            {rowData.value.map((item, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <img
                  src={item.icon}
                  alt=""
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    display: 'inline-block',
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
    },

    context: { componentParent: this },
  };

  onItemView(data) {
    console.log(data);
    this.setState({
      flyoutData: data,
      openFlyout: true,
    });
  }

  async onResolve(data) {
    try {
      const payload = {
        id: this.state.flyoutData.id,
        comments: this.state.comment,
        status: 'RESOLVED',
      };
      const results = await DemoApis.putSupport(payload);
      this.setState({
        openFlyout: false,
        comment: '',
      });
      this.props.setReinitiate(results);
    } catch (e) {
      console.log(e);
    }
  }

  onToggle() {
    this.setState({
      comment: '',
      openFlyout: false,
    });
  }
  onCommentChange(event) {
    this.setState({ comment: event });
  }

  onEditView(data) {
    this.props.onEdit(data);
  }

  normalizeDate(dateString) {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  onDeleteButton(data) {
    this.props.onDelete(data);
  }

  render() {
    const {
      defaultColDef,
      columnDefs,
      frameworkComponents,
      openFlyout,
      flyoutData,
    } = this.state;
    const { rowData, selectTableData } = this.props;

    const downloadFile = url => {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${url.split('/')?.at(-1)}`);
      link.click();
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <DataGrid
            apiLoading={this.props.apiLoading}
            defaultColDef={defaultColDef}
            selectTableData={selectTableData}
            columnDefs={columnDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            context={this.state.context}
            style={{ minHeight: '10vh' }}
            hideHeaderActions
            suppressRowClickSelection={true}
          />
        </div>
        <FlyoutPanel
          style={{ overFlowY: 'hidden' }}
          title={'Support'}
          open={this.state.openFlyout}
          // open={true}
          onToggle={() => this.onToggle()}
          handleClose={() => this.onToggle()}
          isAppBarRequired={flyoutData?.status === 'RESOLVED' ? false : true}
          appBarChildren={
            <>
              <FooterAction
                // hideSave={developerDetail?.orgUserStatus === 'Approved'}
                saveLabel="Resolve"
                canceLabel="Cancel"
                onSave={() => this.onResolve()}
                onToggle={() => this.onToggle()}
                // disabled={loading}
              />
            </>
          }
        >
          <Paper
            elevation={0}
            style={{
              padding: '10px',
              borderRadius: '8px',
              border: '1px solid #E4E7EC',
              background: '#f5f5f5',
              width: '100%',
              marginBottom: '19px',
            }}
          >
            <Grid
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '10px',
              }}
            >
              <Grid item xs={4}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                >
                  Status
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ paddingTop: '7px', paddingRight: '15px' }}>
                    {Object.keys(statusColorMap).includes(
                      flyoutData?.status || 'N/A'
                    ) ? (
                      <span
                        style={{
                          position: 'absolute',
                          backgroundColor:
                            statusColorMap[flyoutData?.status || ''],
                          width: 8,
                          height: 8,
                          borderRadius: 4,
                          padding: 4,
                        }}
                      ></span>
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#344054',
                    }}
                    variant="h6"
                  >
                    {convertStrToTitleCase(flyoutData?.status) || 'N/A'}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                >
                  Document name
                  <IconButton
                    size="small"
                    onClick={() => downloadFile(flyoutData?.s3Url)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#344054',
                    wordWrap: 'break-word',
                  }}
                  variant="h6"
                >
                  {decodeURI(flyoutData?.s3Url?.split('/')?.at(-1)) || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '10px',
              }}
            >
              <Grid item xs={4}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                >
                  Support type
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#344054',
                  }}
                  variant="h6"
                >
                  {flyoutData?.category || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                >
                  Description
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#344054',
                    wordWrap: 'break-word',
                  }}
                  variant="h6"
                >
                  {/* comments : null createdAt : "2023-09-04T09:36:42.348+00:00"
                  emailStatus : false id : 213 resolvedBy : null s3Url :
                  "b37001d3-d949-4561-8906-fd86435a0c5a" status : null
                  supportType : "SUPPORT" userId : "1507479" userText : "jkb" */}
                  {flyoutData?.userText || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '10px',
              }}
            >
              <Grid item xs={4}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                  variant="h6"
                >
                  Resolved by
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#344054',
                  }}
                  variant="h6"
                >
                  {flyoutData?.resolvedBy ? "Admin" : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    wordWrap: 'break-word',
                  }}
                  variant="h6"
                >
                  Comment
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#344054',
                    wordWrap: 'break-word',
                  }}
                  variant="h6"
                >
                  {flyoutData?.comments || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '10px',
              }}
            ></Grid>
          </Paper>
          {flyoutData?.status === 'RESOLVED' ? null : (
            <Grid
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '10px',
                marginBottom: '19px',
              }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    paddingLeft: '5px',
                  }}
                >
                  Add comment
                </Typography>
                <TextField
                  placeholder={'Enter a comment'}
                  margin="dense"
                  variant="outlined"
                  style={{
                    margin: 0,
                    display: 'flex',
                    marginTop: '5px',
                    marginLeft: '5px',
                  }}
                  value={this.state.comment}
                  // variant=""
                  onChange={event => this.onCommentChange(event)}
                  multiline
                  minRows={4}
                  // disabled={isEdit}
                  FormHelperTextProps={{
                    style: {
                      // textAlign: 'center',
                      position: 'absolute',
                      bottom: '0%',
                      right: '0%',
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
          )}
        </FlyoutPanel>
      </div>
    );
  }
}

export default SupportGrid;
